import { add, differenceInCalendarDays } from "date-fns";
import { createServerData$, redirect } from "solid-start/server";
import { setSidebarList } from "~/components/theme/Sidebar";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import {
  getRandomColorWithProperContrast,
  setUserIcons,
  userIcons,
} from "~/store/global.store";
import { setTrialLeft } from "~/store/trial";
import {
  setJwtToken,
  setLoginUserName,
  setTotalStorageUsed,
  setTotalUserLicences,
  setUnReadNotificationCount,
  setUserAvatar,
  setUserId,
  setUserNotifications,
  setUserRole,
  setUserSubscription,
  setUserSubscriptionDate,
  setUserSubscriptionStatus,
  unReadNotificationCount,
} from "~/store/user-form-role";
export async function validateUser() {
  const res = createServerData$(async (_, { request }) => {
    const user = await getUser(request, false, true, true);
    let jwt_token: any = "";
    if (user == null || user.statusCode == 401) {
      if (new URL(request.url).pathname != "/") {
        return redirect("/logout");
      }
    } else {
      if (
        user.data.role.role_name != "super_admin" &&
        (!user.data?.subscription ||
          user.data?.subscription?.state == "cancelled")
      ) {
        let protectedPaths: any = ["/checkout", "/pricing"];
        const currentPath = new URL(request.url).pathname;
        if (!protectedPaths.includes(currentPath)) {
          throw redirect(protectedPaths[0]);
        }
      }
      jwt_token = await getAuthToken(request);
      user.jwt_token = jwt_token;
      let protectedPaths: any = [
        "/form/list",
        "/my-profile",
        "/change-password",
        "/update-password",
        "/notifications/history",
      ];
      switch (user.data.role.role_name) {
        case "admin":
          protectedPaths = [
            ...protectedPaths,
            "/form/library",
            "/user-settings",
            "/form/builder",
            "/manage-licences",
            "/card-update",
            "/form/archived/list",
            "/form/import",
          ];
          if (user.data.subscription?.state == "active") {
            protectedPaths = [...protectedPaths, "/billing-payments"];
          }
          break;

        case "sub_admin":
        case "designer":
          protectedPaths = [
            ...protectedPaths,
            "/form/library",
            "/form/builder",
            "/form/archived/list",
          ];
          break;

        case "super_admin":
          protectedPaths = [
            "/admin/dashboard",
            "/admin/users",
            "/admin/library-form/builder",
            "/admin/search-forms",
            "/admin/user-notifications",
            "/admin/custom-pricing",
            "/admin/app-versions",
            "/admin/server-logs",
            "/admin/expiring-card-users",
          ];
          const apiParams = {
            method: "POST" as string,
            url: `${import.meta.env.VITE_API_URL}/admin/users/list`,
            jwt_token: jwt_token as string,
            body: {
              search_string: "",
              sort_string: "",
            },
          };
          let list = await fetchApi(apiParams);
          if (list.statusCode == 401) {
            return redirect("/logout");
          }
          list.data.subscribersList.forEach((user: { id: string }) => {
            protectedPaths = [
              ...protectedPaths,
              `/admin/user/${user.id}/details`,
            ];
          });
          break;

        default:
          break;
      }

      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/list`,
        jwt_token: jwt_token as string,
        body: { user_id: user?.data.id },
      };
      let formsList = await fetchApi(apiParams);
      if (formsList.statusCode == 401) {
        return redirect("/logout");
      }
      switch (user.data.role.role_name) {
        case "admin":
        case "sub_admin":
        case "designer":
          if (formsList.status) {
            formsList.data.forEach((item: { id: string }) => {
              protectedPaths = [
                ...protectedPaths,
                `/form/builder/${item.id}`,
                `/form/render/${item.id}`,
              ];
            });
          }
          break;

        case "filler":
        case "viewer":
          if (formsList.status) {
            formsList.data.forEach((item: { id: string }) => {
              protectedPaths = [...protectedPaths, `/form/render/${item.id}`];
            });
          }
          break;

        default:
          break;
      }

      switch (user.data.role.role_name) {
        case "admin":
        case "sub_admin":
          if (
            user !== null &&
            user.data?.subscription &&
            user.data?.subscription?.plan_name !== "" &&
            user.data?.subscription?.state !== "cancelled"
          ) {
            protectedPaths = [
              ...protectedPaths,
              "/users",
              "/groups",
              "/user/add",
              "/group/add",
            ];
            const usersListApiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/user/list`,
              jwt_token: jwt_token as string,
              body: {
                user_id: user?.data.id,
                search_string: "",
                sort_string: "",
              },
            };
            let users = await fetchApi(usersListApiParams);
            if (users.statusCode == 401) {
              return redirect("/logout");
            }
            if (users.status) {
              users.data.usersList.users.forEach((user: { id: string }) => {
                protectedPaths = [...protectedPaths, `/user/${user.id}/edit`];
              });
            }
            const groupsListApiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/group/list`,
              jwt_token: jwt_token as string,
              body: {
                user_id: user?.data.id,
                search_string: "",
                sort_string: "",
              },
            };
            let groups = await fetchApi(groupsListApiParams);
            if (groups.statusCode == 401) {
              return redirect("/logout");
            }
            if (groups.status) {
              groups.data.groupsList.forEach((group: { id: string }) => {
                protectedPaths = [...protectedPaths, `/group/${group.id}/edit`];
              });
            }
          }
          break;

        default:
          break;
      }

      const currentPath = new URL(request.url).pathname;
      if (!protectedPaths.includes(currentPath)) {
        throw redirect(protectedPaths[0]);
      }

      return user;
    }
  });
  if (res()?.status) {
    setTrialLeft(0);
    setJwtToken(res().jwt_token);
    setUserId(res().data?.id);
    setUserRole(res().data?.role.role_name);
    setUserSubscriptionStatus(res().data?.subscription?.state);
    setUserSubscriptionDate(res().data?.subscription?.created_at);
    setUserAvatar(res().data?.avatar);
    setTotalUserLicences(res().data?.subscription?.licences);
    setTotalStorageUsed(res().data?.subscription?.user?.storage_used);
    setLoginUserName(res().data?.username);
    setUserNotifications(res().data?.notifications);
    setUnReadNotificationCount(res().data?.unReadNotificationCount);
    const sidebarArr: any = [
      {
        label: "Form Management",
        icon: "list",
        link: "/form/list",
        subList: [
          {
            label: "Forms",
            icon: "files",
            link: "/form/list",
          },
          res().data?.role.role_name != "filler" &&
          res().data?.role.role_name != "viewer"
            ? {
                label: "Create Form",
                icon: "formAdd",
                popup: "formFolderAdd",
              }
            : {},
          {
            label: "Create Folder",
            icon: "folderAdd",
            popup: "formFolderAdd",
          },
          res().data?.role.role_name != "filler" &&
          res().data?.role.role_name != "viewer"
            ? {
                label: "Sample Templates",
                icon: "template",
                link: "/form/library",
              }
            : {},
          res().data?.role.role_name != "filler" &&
          res().data?.role.role_name != "viewer"
            ? {
                label: "Archived",
                icon: "trash",
                link: "/form/archived/list",
              }
            : {},
          res().data?.role.role_name == "admin" ||
          res().data?.role.role_name == "sub_admin"
            ? {
                label: "Import",
                icon: "importIco",
                link: "/form/import",
              }
            : {},
          {
            label: "Notifications",
            icon: "notification",
            link: "/notifications/history",
          },
        ].filter((subItem: any) => subItem),
      },
      {
        label: "Manage Account",
        icon: "userManagement",
        link:
          res().data?.role.role_name == "admin" ||
          res().data?.role.role_name == "sub_admin"
            ? "/users"
            : "/update-password",
        subList: [
          res().data?.role.role_name == "admin" ||
          res().data?.role.role_name == "sub_admin"
            ? {
                label: "Manage Users",
                icon: "user",
                link: "/users",
              }
            : {},
          res().data?.role.role_name == "admin" ||
          res().data?.role.role_name == "sub_admin"
            ? {
                label: "Manage Teams",
                icon: "users",
                link: "/groups",
              }
            : {},
          res().data?.role.role_name == "admin" &&
          res().data?.subscription?.state == "active"
            ? {
                label: "Licensing",
                icon: "card",
                link: "/billing-payments",
              }
            : {},
          {
            label: "Change Password",
            icon: "key",
            link: "/update-password",
          },
        ].filter((subItem: any) => subItem),
      },
      {
        label: "Help",
        icon: "help",
        link: "",
        subList: [
          {
            label: "Help Documentation",
            icon: "helpDocument",
            link: "https://formconnections.com/wp-content/uploads/2025/02/FormConnect-ProMax-Help-Documentation-V3.4-compressed.pdf",
          },
          {
            label: "Tips",
            icon: "tip",
            link: "https://www.formconnections.com/category/blog/",
          },
          {
            label: "Contact Support",
            icon: "contact",
            link: "https://formconnections.com/contact/",
          },
        ],
      },
    ].filter((item: any) => item);
    setSidebarList(
      sidebarArr
        .filter((obj: any) => Object.keys(obj)?.length != 0)
        .map((obj: any) => {
          if (obj.subList) {
            obj.subList = obj.subList.filter(
              (subObj: any) => Object.keys(subObj).length != 0
            );
          }
          return obj;
        })
    );

    if (
      userIcons()?.length == 0 &&
      res() !== null &&
      res().data?.id &&
      res().data?.role.role_name != "super_admin"
    ) {
      const usersListApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/user/list`,
        jwt_token: res().jwt_token as string,
        body: {
          user_id: res().data.id,
          search_string: "",
          sort_string: "",
        },
      };
      let users = await fetchApi(usersListApiParams);
      if (users?.statusCode == 401) {
        window.location.href = "/logout";
      }
      if (users && users.status) {
        setUserIcons([
          {
            name:
              users.data.adminUser.first_name +
              " " +
              users.data.adminUser.last_name,
            logo:
              users.data.adminUser.first_name.charAt(0) +
              users.data.adminUser.last_name.charAt(0),
            theme: getRandomColorWithProperContrast("#ffffff"),
          },
          ...users.data.usersList.users.map((user: any) => ({
            name: user.first_name + " " + user.last_name,
            logo: user.first_name.charAt(0) + user.last_name.charAt(0),
            theme: getRandomColorWithProperContrast("#ffffff"),
          })),
        ]);
      }
    }
    if (
      res() !== null &&
      res().data?.subscription &&
      res().data?.subscription?.plan_name !== "" &&
      res().data?.subscription?.state !== "cancelled"
    ) {
      setUserSubscription("Team");
    } else {
      setUserSubscription("");
    }
    if (res().data?.subscription?.state == "trialing") {
      const timeDiff = differenceInCalendarDays(
        add(new Date(res().data.subscription?.created_at), {
          days: import.meta.env.VITE_PADDLE_TRIAL_DAYS,
        }),
        new Date()
      );
      setTrialLeft(timeDiff);
    }
  }
  return res;
}
