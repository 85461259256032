import { A } from "@solidjs/router";
import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/admin.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { format } from "date-fns";
import { notify } from "~/components/global/Notification";
import messages from "~/helpers/message";
import { currentPage, setCurrentPage } from "~/components/global/Pagination";
import Search from "~/components/global/Search";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import {
  alertMessage,
  customerSubscriptionTypes,
  popupQueue,
  removePopup,
  selectedDropDown,
  setAlertMessage,
  setPopup,
} from "~/store/global.store";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import ResyncFormPopup from "~/components/popup/ResynFormPopup";
import Dropdown from "~/components/global/Dropdown";
import Popover from "~/components/global/Popover";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/users/list`,
      jwt_token: jwt_token as string,
      body: {
        skip: 0,
        take: 20,
        search_string: "",
        sort_string: "",
        status: "active",
      },
    };
    let list = await fetchApi(apiParams);
    if (list.statusCode == 401) {
      throw redirect("/logout");
    }
    let subscribersList = list.data.subscribersList;
    let totalItems = list.data.totalItems;
    let totalPages: any = list.data.totalPages;
    return { subscribersList, jwt_token, totalItems, totalPages };
  });
}

const dashboard: Component = () => {
  setPageTitle(`${SITE_TITLE} - Customers`);
  const data: any = useRouteData<typeof routeData>();
  const [getsubscribersList, setSubscribersList] = createSignal(
    data()?.subscribersList as any
  );
  const [totalPages, setTotalPages] = createSignal(0 as number);
  const [searchString, setSearchString] = createSignal("" as any);
  const [sortString, setSortString] = createSignal("" as any);
  const [getTotalItems, setTotalItems] = createSignal(
    data()?.totalItems as any
  );
  const [userIdToDelete, setUserIdToDelete] = createSignal("" as string);
  const [isListLoader, setIsListLoader] = createSignal(false as boolean);
  const [currentUser, setCurrentUser] = createSignal({} as any);
  const [currentPopup, setCurrentPopup] = createSignal("" as string);
  const [subscrioptionStatus, setSubscrioptionStatus] = createSignal(
    "active" as string
  );
  const [totalCustomers, setTotalCustomers] = createSignal(0 as number);
  const [isMoveToTopVisible, setIsMoveToTopVisible] = createSignal(
    false as boolean
  );

  createEffect(() => {
    if (data()?.totalItems) {
      setTotalCustomers(data()?.totalItems);
    }
  });

  onMount(() => {
    const debouncedScrollHandler = debounce(handleScroll, 200);
    const element: any = document.querySelector(".main-content");
    element.addEventListener("scroll", debouncedScrollHandler);
  });

  function getApiParams(jwt_token: string, skip: number) {
    return {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/users/list`,
      jwt_token,
      body: {
        skip: skip > 0 ? skip : 0,
        take: 20,
        search_string: searchString() ? searchString() : "",
        sort_string: sortString() ? sortString() : "",
        status: subscrioptionStatus(),
      },
    };
  }

  const debounce = (func: any, delay: any) => {
    let timeoutId: any;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleScroll = async (event: any) => {
    setIsMoveToTopVisible(event.target.scrollTop > 300 ? true : false);
    const scrollableHeight =
      event.target.scrollHeight - event.target.clientHeight;
    const currentPosition = event.target.scrollTop;
    if (
      currentPosition >= scrollableHeight &&
      (getsubscribersList()
        ? getsubscribersList().length != getTotalItems()
        : data()?.subscribersList.length != data()?.totalItems) &&
      !isListLoader()
    ) {
      setIsListLoader(true);
      const apiParams = getApiParams(
        data()?.jwt_token,
        20 * Math.ceil(getsubscribersList().length / 20)
      );
      const list = await fetchApi(apiParams);
      if (list?.statusCode == 401) {
        window.location.href = "/logout";
      }
      if (list) {
        setSubscribersList([
          ...getsubscribersList(),
          ...list.data.subscribersList,
        ]);
        setIsListLoader(false);
      }
    }
  };

  async function handlePageChange(page: any, jwt_token: any) {
    let skip: number = 0;
    if (page != 1) {
      skip = (page - 1) * Number(20);
    }
    const apiParams = getApiParams(jwt_token, skip);
    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    }
    setCurrentPage(page);
    setSubscribersList(list.data.subscribersList);
  }

  async function deleteAdminUser(user_id: string, jwt_token: string) {
    try {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/admin/user/delete`,
        jwt_token: jwt_token as string,
        body: {
          user_id,
        },
      };
      const deleteAdminUser = await fetchApi(apiParams);
      if (deleteAdminUser?.statusCode == 401) {
        window.location.href = "/logout";
      }
      let message: any;
      let theme: any = "success";
      if (deleteAdminUser && deleteAdminUser.status) {
        let skip: number = 0;
        if (currentPage() != 1) {
          skip = (currentPage() - 1) * Number(20);
        }
        skip = skip > 0 ? skip : 0;
        const apiParams = getApiParams(jwt_token, skip);
        let list = await fetchApi(apiParams);
        if (list?.statusCode == 401) {
          window.location.href = "/logout";
        }
        setTotalPages(list.data.totalPages);
        setSubscribersList(list.data.subscribersList);
        setTotalCustomers(list.data.totalItems);
        setTotalItems(list.data.totalItems);
        message = messages.user.deleted;
      } else {
        message = messages.error.user_delete;
        theme = "error";
      }
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    } catch (err) {
      return err;
    }
  }

  async function deleteAdminUserData(user_id: string, jwt_token: string) {
    try {
      setSubscribersList(
        getsubscribersList().map((item: any) => {
          if (item.id == user_id) {
            return {
              ...item,
              web_forms_count: 0,
            };
          } else {
            return item;
          }
        })
      );
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/admin/user/data/delete`,
        jwt_token: jwt_token as string,
        body: {
          user_id,
        },
      };
      const deleteAdminUserData = await fetchApi(apiParams);
      if (deleteAdminUserData?.statusCode == 401) {
        window.location.href = "/logout";
      }
      let message: any = messages.error.data_deleted;
      let theme: any = "error";
      if (deleteAdminUserData && deleteAdminUserData.status) {
        message = messages.user.data_deleted;
        theme = "success";
      }
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    } catch (err) {
      return err;
    }
  }

  async function searchAdminUsers(searchString: any, jwt_token: string) {
    setSearchString(searchString);
    let skip: number = 0;
    const apiParams = getApiParams(jwt_token, skip);

    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    }
    setSubscribersList(list.data.subscribersList);
    setTotalPages(list.data.totalPages);
    setCurrentPage(1);
  }

  async function filterAdminUsers(filterString: any, jwt_token: string) {
    setSortString(filterString);
    let skip: number = 0;
    if (currentPage() != 1) {
      skip = (currentPage() - 1) * Number(20);
    }
    const apiParams = getApiParams(jwt_token, skip);
    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    }
    setSubscribersList(list.data.subscribersList);
  }

  return (
    <main>
      <Show when={isMoveToTopVisible()}>
        <button
          class="btn sm move-to-top"
          onclick={() => {
            const element: any = document.querySelector(".main-content");
            element.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <Icons name="arrowUp" stroke="#2e61a3" width={30} height={30}></Icons>
        </button>
      </Show>
      <ConfirmPopup
        message={alertMessage()}
        name={currentPopup()}
        onconfirm={(status: boolean) => {
          removePopup(currentPopup());
          if (status) {
            if (currentPopup() == "confirmAdminDataRemove") {
              deleteAdminUserData(userIdToDelete(), data()?.jwt_token);
            } else {
              deleteAdminUser(userIdToDelete(), data()?.jwt_token);
            }
          }
          setUserIdToDelete("");
        }}
      ></ConfirmPopup>
      <Show when={popupQueue.includes("resyncForm")}>
        <ResyncFormPopup
          jwt_token={data()?.jwt_token}
          data={currentUser()}
          setValue={(data: any) => {
            setSubscribersList(
              getsubscribersList().map((item: any) => {
                if (item.id == data) {
                  return {
                    ...item,
                    force_resync: item.force_resync ? false : true,
                  };
                } else {
                  return item;
                }
              })
            );
            removePopup("resyncForm");
          }}
        ></ResyncFormPopup>
      </Show>
      <Show when={isListLoader()}>
        <div class="form-list-loader">
          <span>Loading</span>
          <Icons name="loader"></Icons>
        </div>
      </Show>
      <SystemLayout>
        <div class="center p-r">
          <div class="flex space-between">
            <h3 class="title color">Customers ({totalCustomers()})</h3>
            <div class="flex gap">
              <div class="pr w-250">
                <div
                  id="form_template"
                  class="user-role-dropdown cur-p br-unset"
                >
                  <span class="dropdown-div lbl" id="form_template">
                    {
                      customerSubscriptionTypes.find(
                        (item: any) => item.value == subscrioptionStatus()
                      )?.label
                    }
                  </span>
                  <Icons name="chevronDown" stroke="#6c6e6f"></Icons>
                </div>
                <Show when={selectedDropDown() == "form_template"}>
                  <Dropdown
                    list={customerSubscriptionTypes}
                    title="label"
                    link="link"
                    class="w-100 t-34"
                    selectedValue={subscrioptionStatus()}
                    isFormDropdown={true}
                    setValue={async (val: any) => {
                      setSubscrioptionStatus(val);
                      const apiParams = getApiParams(data()?.jwt_token, 0);
                      let list = await fetchApi(apiParams);
                      if (list?.statusCode == 401) {
                        window.location.href = "/logout";
                      }
                      setCurrentPage(0);
                      setSubscribersList(list.data.subscribersList);
                      setTotalCustomers(list.data.totalItems);
                    }}
                  />
                </Show>
              </div>
              <div class="w-150">
                <Search
                  onEnter={(search: any) => {
                    searchAdminUsers(search, data()?.jwt_token);
                  }}
                  placeholder="Search Customer"
                ></Search>
              </div>
              <button
                class="btn primary sm"
                onclick={async () => {
                  const apiParams = {
                    method: "POST" as string,
                    url: `${
                      import.meta.env.VITE_API_URL
                    }/admin/customers/download`,
                    jwt_token: data()?.jwt_token as string,
                    body: {
                      skip: 0,
                      take: 20,
                      search_string: "",
                      sort_string: "",
                      status: "active",
                    },
                  };
                  let result = await fetchApi(apiParams);
                  if (result.statusCode == 401) {
                    throw redirect("/logout");
                  } else if (result?.statusCode == 403) {
                    location.reload();
                  }
                  const link = document.createElement("a");
                  link.href = `${import.meta.env.VITE_API_URL}/uploads/${
                    result.data.fileName
                  }`;
                  link.download = result.data.fileName;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  // After all downloads have completed, then remove the files
                  const removeFilesApiParams = {
                    method: "POST" as string,
                    url: `${
                      import.meta.env.VITE_API_URL
                    }/form/export/files/remove`,
                    jwt_token: data()?.jwt_token,
                    body: {
                      export_files: result.data.fileName,
                    },
                  };
                  const fileRemove = await fetchApi(removeFilesApiParams);
                  if (fileRemove?.statusCode == 401) {
                    window.location.href = "/logout";
                  } else if (fileRemove?.statusCode == 403) {
                    location.reload();
                  }
                }}
              >
                <Popover content="Download XLS" left={100} top={-40}>
                  <Icons name="download" stroke="#fff"></Icons>
                </Popover>
              </button>
            </div>
          </div>
          <div>
            <div class="flex space-between flex-items">
              <div class="w-100">
                <table class="table w-100">
                  <thead class="user-select-none">
                    <tr>
                      <th class="w-183p">
                        Customer
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" ||
                                sortString() == "asc_company"
                                ? "desc_company"
                                : "asc_company",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th class="w-183p">
                        <span>User Name</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" || sortString() == "asc_name"
                                ? "desc_name"
                                : "asc_name",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th>
                        <span>Email</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" || sortString() == "asc_email"
                                ? "desc_email"
                                : "asc_email",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th class="w-125p">
                        <span>Total Licences</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" ||
                                sortString() == "asc_licences"
                                ? "desc_licences"
                                : "asc_licences",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th class="w-120p">
                        <span>Total Forms</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" || sortString() == "asc_forms"
                                ? "desc_forms"
                                : "asc_forms",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th class="w-125p">
                        <span>Storage Used</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" ||
                                sortString() == "asc_storage"
                                ? "desc_storage"
                                : "asc_storage",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th class="w-183p">
                        <span>Created At</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterAdminUsers(
                              sortString() == "" ||
                                sortString() == "asc_created"
                                ? "desc_created"
                                : "asc_created",
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th class="w-290">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Show
                      when={
                        getsubscribersList()
                          ? getsubscribersList().length == 0
                          : data()?.subscribersList.length == 0
                      }
                    >
                      <tr class="text-center">
                        <td colSpan="8">
                          <small>
                            <b>No Data Found !!</b>
                          </small>
                        </td>
                      </tr>
                    </Show>
                    <For
                      each={
                        getsubscribersList()
                          ? getsubscribersList()
                          : data()?.subscribersList
                      }
                    >
                      {(user) => (
                        <tr>
                          <td>{user.company_name}</td>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td class="text-center">{user.purchased_licences}</td>
                          <td class="text-center">{user.web_forms_count}</td>
                          <td
                            class={
                              subscrioptionStatus() == "active" &&
                              Number(user.storage_used) >
                                Number(
                                  import.meta.env.VITE_FREE_PER_USER_STORAGE
                                ) *
                                  Number(user.purchased_licences)
                                ? "alert-text fw-700 cur-p"
                                : subscrioptionStatus() == "active" &&
                                  Number(user.storage_used) >
                                    Number(
                                      import.meta.env.VITE_FREE_PER_USER_STORAGE
                                    ) *
                                      0.7 *
                                      Number(user.purchased_licences)
                                ? "info-text fw-700 cur-p"
                                : ""
                            }
                            onclick={() => {
                              if (
                                subscrioptionStatus() == "active" &&
                                Number(user.storage_used) >
                                  Number(
                                    import.meta.env.VITE_FREE_PER_USER_STORAGE
                                  ) *
                                    Number(user.purchased_licences)
                              ) {
                              } else if (
                                subscrioptionStatus() == "active" &&
                                Number(user.storage_used) >
                                  Number(
                                    import.meta.env.VITE_FREE_PER_USER_STORAGE
                                  ) *
                                    0.7 *
                                    Number(user.purchased_licences)
                              ) {
                              } else {
                              }
                            }}
                          >
                            <Show
                              when={
                                subscrioptionStatus() == "active" &&
                                Number(user.storage_used) >
                                  Number(
                                    import.meta.env.VITE_FREE_PER_USER_STORAGE
                                  ) *
                                    0.7 *
                                    Number(user.purchased_licences) &&
                                Number(user.storage_used) <=
                                  Number(
                                    import.meta.env.VITE_FREE_PER_USER_STORAGE
                                  ) *
                                    Number(user.purchased_licences)
                              }
                            >
                              <Popover
                                content="Storage is Nearing Completion"
                                top={-55}
                                left={170}
                              >
                                <span>{user.storage_used.toFixed(2)} MB</span>
                              </Popover>
                            </Show>
                            <Show
                              when={
                                subscrioptionStatus() == "active" &&
                                Number(user.storage_used) >
                                  Number(
                                    import.meta.env.VITE_FREE_PER_USER_STORAGE
                                  ) *
                                    Number(user.purchased_licences)
                              }
                            >
                              <Popover
                                content="Storage Capacity Reached"
                                top={-55}
                                left={150}
                              >
                                <span>{user.storage_used.toFixed(2)} MB</span>
                              </Popover>
                            </Show>
                            <Show
                              when={
                                subscrioptionStatus() != "active" ||
                                Number(user.storage_used) <=
                                  Number(
                                    import.meta.env.VITE_FREE_PER_USER_STORAGE
                                  ) *
                                    0.7 *
                                    Number(user.purchased_licences)
                              }
                            >
                              <span>{user.storage_used.toFixed(2)} MB</span>
                            </Show>
                          </td>
                          <td>
                            {format(new Date(user.created_at), "MMMM,dd yyyy")}
                          </td>
                          <td>
                            <A
                              class="btn primary sm"
                              href={`/admin/user/${user.id}/details`}
                            >
                              <Icons
                                name="eye"
                                stroke="#fff"
                                width={18}
                                height={18}
                              />
                              &nbsp;View
                            </A>
                            &nbsp;
                            <button
                              class="btn danger sm mt-4"
                              onclick={() => {
                                setUserIdToDelete(user.id);
                                setAlertMessage(
                                  "<b>Removing this user will delete all forms, records, and users associated with the company.<br/>Are you sure you want to proceed?</b>"
                                );
                                setCurrentPopup("confirmAdminUserRemove");
                                setPopup("confirmAdminUserRemove");
                              }}
                            >
                              <Icons
                                name="x"
                                stroke="#fff"
                                width={18}
                                height={18}
                              />
                              &nbsp;Delete
                            </button>
                            &nbsp;
                            <button
                              class={`btn sm mt-4 ${
                                user.force_resync ? "secondary" : "gray"
                              }`}
                              onclick={async () => {
                                setCurrentUser(user);
                                setPopup("resyncForm");
                              }}
                            >
                              <Icons
                                name="resync"
                                stroke="#fff"
                                width={18}
                                height={18}
                              />
                              <span>
                                &nbsp;
                                {!user.force_resync ? "" : "Cancel"} Resync
                              </span>
                            </button>
                            &nbsp;
                            <button
                              class="btn secondary sm mt-4"
                              onclick={() => {
                                setUserIdToDelete(user.id);
                                setAlertMessage(
                                  "<b>Wipe this user data will delete all forms and records associated with the company.<br/>Are you sure you want to proceed?</b>"
                                );
                                setCurrentPopup("confirmAdminDataRemove");
                                setPopup("confirmAdminDataRemove");
                              }}
                            >
                              <Icons
                                name="trash"
                                stroke="#fff"
                                width={18}
                                height={18}
                              />
                              &nbsp;Wipe Data
                            </button>
                          </td>
                        </tr>
                      )}
                    </For>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default dashboard;
