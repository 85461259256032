import { Component, Show, createEffect, createSignal } from "solid-js";
import { updateNumbersForField } from "~/store/global.store";
interface Props {
  type?: string;
  step?: number;
  min: number;
  max: number;
  value: number;
  label: string;
  setValue: Function;
}
const RangeSlider: Component<Props> = (props) => {
  const [backgroundSize, setBackgroundSize] = createSignal("");
  createEffect(() => {
    let bg_size = ((props.value - props.min) * 100) / (props.max - props.min);
    bg_size < 0
      ? (bg_size = 0)
      : props.value >= Number(props.max)
      ? (bg_size = 100)
      : "";
    setBackgroundSize(bg_size + "% 100%");
  });
  return (
    <div class="form-group">
      <Show when={props.label != ""}>
        <label>{props.label}</label>
      </Show>
      <div class="range-slider">
        <input
          type="range"
          class="range-slider__range"
          step={props.step}
          min={props.min}
          max={props.max}
          value={props.value}
          onInput={(e) => props.setValue(e.currentTarget.value)}
          style={`--slider-bg-size: ${backgroundSize()}`}
        />
        <Show when={props.label != ""}>
          <input
            type="number"
            class="range-slider__input input"
            value={props.value}
            step={props.step}
            min={props.min}
            max={props.max}
            onKeyUp={(e: any) => {
              if (
                [
                  "0",
                  "1",
                  "2",
                  "3",
                  "4",
                  "5",
                  "6",
                  "7",
                  "8",
                  "9",
                  ".",
                  "ArrowUp",
                  "ArrowDown",
                ].includes(e.key)
              ) {
                if (e.currentTarget.value >= props.min) {
                  props.setValue(
                    updateNumbersForField(
                      e.currentTarget.value,
                      props.min,
                      props.max
                    )
                  );
                }
                if (e.currentTarget.value < 1) {
                  e.currentTarget.value = 1;
                }
                if (e.currentTarget.value > props.max) {
                  e.currentTarget.value = props.max;
                }
              }
            }}
          />
        </Show>
      </div>
    </div>
  );
};

export default RangeSlider;
