import { Component, Show, createSignal, useTransition } from "solid-js";
import { trialLeft } from "~/store/trial";
import { userAvatar, userRole } from "~/store/user-form-role";
import { Transition } from "solid-transition-group";
import Dropdown from "../global/Dropdown";
import Icons from "../icons/Icons";
import Popover from "../global/Popover";
import {
  currentFormId,
  isFormUpdated,
  setClientSettings,
  setIsFormUpdated,
  setPopup,
  setSettingButtons,
  settingButtons,
} from "~/store/global.store";
import { useNavigate } from "solid-start";
import { saveFormSettings } from "../form/formSettings";
import { isFormJsonUpdated, setIsFormJsonUpdated } from "~/store/form.store";

interface Props {
  fieldsType: string;
  jwt_token: string;
}

const [isSidebarVisible, setIsSidebarVisible] = createSignal(true);
const [isDropdownOpen, setIsDropdownOpen] = createSignal(false as boolean);

const FormLeftBar: Component<Props> = (props: any) => {
  const [pending, start] = useTransition();
  const navigate = useNavigate();

  const profileItems = [
    { title: "Dashboard", link: "/form/list" },
    { title: "Profile", link: "/my-profile" },
    { title: "Settings", link: "/settings" },
    { title: "Log out", link: "/logout" },
  ];

  return (
    <div class={`form-rightbar ${isSidebarVisible() ? "" : "hidden"}`}>
      <div class="header">
        <Show when={trialLeft() > 0}>
          <div class="badge">Trial Ends in {trialLeft()} Days</div>
        </Show>
        <Show
          when={
            props.fieldsType == "render" &&
            userRole() != "filler" &&
            userRole() != "viewer"
          }
        >
          <Show when={settingButtons()}>
            <Popover content="Share Form" top={-52} left={80}>
              <button
                class="btn sm primary"
                onclick={() => {
                  setClientSettings(true);
                  setSettingButtons(false);
                }}
              >
                <Icons name="profile" stroke="#fff" />
              </button>
            </Popover>
          </Show>
          <Popover content="Edit Form" top={-52} left={75}>
            <button
              class="btn sm primary"
              onclick={() =>
                userRole() != "filler" && userRole() != "viewer"
                  ? navigate(`/form/builder/${currentFormId()}`)
                  : ""
              }
            >
              <Icons name="edit" stroke="#fff"></Icons>
            </button>
          </Popover>
        </Show>
        <Show when={props.fieldsType != "render"}>
          <button
            class="btn sm primary"
            onclick={() => {
              saveFormSettings(props.jwt_token, currentFormId());
              setIsFormUpdated(true);
              setIsFormJsonUpdated(false);
            }}
          >
            Save
          </button>
          <button
            class="btn sm primary"
            onclick={() => {
              if (isFormJsonUpdated() && !isFormUpdated()) {
                setPopup("confirmFormChanges");
              } else {
                navigate(`/form/render/${currentFormId()}`);
              }
            }}
          >
            Close
          </button>
        </Show>
        <Icons
          name="notification"
          stroke="hsl(214, 56%, 41%)"
          width={27}
          height={27}
        ></Icons>
        <div class="profile-avatar">
          <img
            src={userAvatar() ? userAvatar() : "/user.png"}
            onClick={() => {
              start(() => setIsDropdownOpen(isDropdownOpen() ? false : true));
            }}
            class="profile-pic"
            classList={{ pending: pending() }}
            tabIndex={0}
          />
          <Transition name="slide-fade">
            <Show when={isDropdownOpen()}>
              <Dropdown list={profileItems} title="title" link="link" />
            </Show>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export { isSidebarVisible, setIsSidebarVisible, isDropdownOpen };
export default FormLeftBar;
