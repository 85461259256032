import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { useNavigate, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import ShareForm from "~/components/popup/ShareForm";
import { notify } from "~/components/global/Notification";
import Popover from "~/components/global/Popover";
import Search from "~/components/global/Search";

import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/system.layout";
import AssigneeList from "~/components/popup/AssigneeListPopup";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import messages from "~/helpers/message";
import fetchApi from "~/lib/api";
import { setPageTitle } from "~/root";
import {
  adjustFormFolderNames,
  alertMessage,
  assigneeAvatarsList,
  assigneeList,
  clearFormSearch,
  confirmDeleteForm,
  currentformName,
  foldersList,
  formFieldsToLink,
  formRenameValue,
  formSharedUsers,
  formsList,
  getRandomColorWithProperContrast,
  getSignatureInitials,
  isPageLoader,
  lastOpenedForm,
  linkedFormValues,
  linkedLinkValues,
  linkedParentValues,
  popupQueue,
  removePopup,
  renameForm,
  selectedDropDown,
  selectedFolderData,
  selectedFormType,
  selectedFormUuid,
  selectedForms,
  setAlertMessage,
  setAssigneeAvatarsList,
  setAssigneeList,
  setClearFormSearch,
  setConfirmDeleteForm,
  setCurrentFormId,
  setCurrentformName,
  setExportFormSettings,
  setFoldersList,
  setFormRenameValue,
  setFormSettingsBar,
  setFormSharedUsers,
  setFormValues,
  setFormsList,
  setIsNewFolder,
  setIsPageLoader,
  setLastOpenedForm,
  setLinkedField,
  setLinkedFormValues,
  setLinkedLinkValues,
  setLinkedParentValues,
  setPopup,
  setRenameForm,
  setSelectedDropDown,
  setSelectedFolderData,
  setSelectedForm,
  setSelectedFormType,
  setSelectedFormUuid,
  setSelectedForms,
  setSelectedRecords,
  userIcons,
} from "~/store/global.store";
import Checkbox from "~/components/global/Checkbox";
import { userRole } from "~/store/user-form-role";
import FolderForms from "~/components/popup/FolderFormsPopup";
import { validateUser } from "../api/validateUser";
import FormPreview from "~/components/popup/FormPreview";
import FormSettingsPopup from "~/components/popup/FormSettingsPopup";
import {
  formJson,
  isFolderForm,
  setFormJson,
  setIsFolderForm,
  setOffset,
  setTotalForms,
  totalForms,
} from "~/store/form.store";
import {
  activeTheme,
  setActiveTheme,
  setEmbedImageEnabled,
  setFontSize,
  setKioskModeEnabled,
  setPaperSize,
  setPdfRecordsEnabled,
  setPdfTextBoxEnabled,
  setRecordSortingDisabled,
} from "~/components/form/formSettings";
import FieldLinkerPopup, {
  formFieldLinkType,
  formFieldRemoveValue,
} from "~/components/popup/FieldLinkerPopup";
import LinkFieldPopup from "~/components/popup/LinkFieldPopup";
import Dropdown from "~/components/global/Dropdown";
import { folderEvents, formEvents } from "~/store/field-editor.store";
import {
  fetchBoxFoldersList,
  fetchDropboxFoldersList,
  fetchGoogleDriveFoldersList,
  fetchOneDriveFoldersList,
  getBoxCurrentAccountDetails,
  getDropBoxCurrentAccountDetails,
  getGoogleDriveCurrentAccountDetails,
  getOneDriveCurrentAccountDetails,
  updateServiceToken,
} from "~/store/export-files.store";
import ExportFilesPopup from "~/components/popup/ExportFilesPopup";
import {
  setFormRecords,
  setRecordsJson,
  setRecordsList,
  setSelectedRecordId,
  setTotalRecordsCount,
  setTotalRecordsShowCount,
} from "~/store/records.store";
import FoldersList from "~/components/popup/FoldersListPopup";
import { setPages } from "~/components/global/FormNavbar";
import { A } from "@solidjs/router";
import {
  openFolder,
  setOpenFolder,
} from "~/components/fields/ScriptButtonField";
import { importRecordFile, setImportRecordFile } from "./import";
import { getMessaging, getToken } from "firebase/messaging";
import { setFireBaseToken } from "~/components/global/Login";

const [selectedFormUsers, setSelectedFormUsers] = createSignal([] as any);
const [selectedFormTeams, setSelectedFormTeams] = createSignal([] as any);
const [selectFormsToShare, setSelectFormsToShare] = createSignal(
  false as boolean
);
const [isFormSelected, setIsFormSelected] = createSignal(false as boolean);
const [selectedFolderId, setSelectedFolderId] = createSignal("" as string);
const [folderUsers, setFolderUsers] = createSignal([] as any);
const [searchString, setSearchString] = createSignal("" as string);

export async function renameFormStructureName(
  user_id: string,
  jwt_token: string,
  form_id: string,
  form_name: string,
  type: string
) {
  let message: string =
    type == "folder"
      ? messages.error.rename_folder
      : messages.error.rename_form;
  let theme: string = "danger";
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/${
      type == "folder" ? "folder/rename" : "rename"
    }`,
    jwt_token,
    body: {
      user_id,
      form_id: form_id,
      form_name,
      type,
      device_type: "web",
    },
  };
  const renameForm = await fetchApi(apiParams);
  if (renameForm?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (renameForm?.statusCode == 403) {
    location.reload();
  }
  if (renameForm.status) {
    theme = "success";
    message =
      type == "folder"
        ? messages.form.rename_folder
        : messages.form.rename_form;
    updateFormsList(user_id, jwt_token);
  }
  setRenameForm("");
  notify({
    isNotified: true,
    message,
    theme,
    placement: "top",
    timeout: 2500,
  });
}

export async function cloneFormStructure(
  user_id: string,
  jwt_token: string,
  form_id: string,
  type: string
) {
  let message: string =
    type == "folder" ? messages.error.clone_folder : messages.error.clone_form;
  let theme: string = "danger";
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/${
      type == "folder" ? "folder/clone" : "clone"
    }`,
    jwt_token,
    body: {
      user_id,
      form_id: form_id,
      type,
      device_type: "web",
    },
  };
  const cloneForm = await fetchApi(apiParams);
  if (cloneForm?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (cloneForm?.statusCode == 403) {
    location.reload();
  }
  if (cloneForm.status) {
    if (type == "form") {
      const userFormRightsApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/user/add/rights`,
        jwt_token,
        body: { shared_users: user_id, form_id: cloneForm.data.id },
      };
      const formAdminRight = await fetchApi(userFormRightsApiParams);
      if (formAdminRight?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (formAdminRight?.statusCode == 403) {
        location.reload();
      }
      if (formAdminRight && formAdminRight.status) {
        theme = "success";
        message = messages.form.clone_form;
        updateFormsList(user_id, jwt_token);
      }
    } else {
      theme = "success";
      message = messages.form.clone_folder;
      updateFormsList(user_id, jwt_token);
    }
  }
  notify({
    isNotified: true,
    message,
    theme,
    placement: "top",
    timeout: 2500,
  });
}

export async function deleteFormStructure(
  user_id: string,
  jwt_token: string,
  form_id: string,
  type: string
) {
  if (confirmDeleteForm()) {
    let message: string =
      type == "folder"
        ? messages.error.delete_folder
        : messages.error.delete_form;
    let theme: string = "danger";
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/${
        type == "folder" ? "folder/delete" : "delete"
      }`,
      jwt_token,
      body: { user_id, form_id, type, device_type: "web" },
    };
    const deleteForm = await fetchApi(apiParams);
    if (deleteForm?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (deleteForm?.statusCode == 403) {
      location.reload();
    }
    if (deleteForm.status) {
      if (type == "form") {
        setTotalForms(totalForms() - 1);
      } else {
        setFoldersList(
          foldersList().filter((folder: any) => folder.id != form_id)
        );
      }
      theme = "success";
      message =
        type == "folder"
          ? messages.form.delete_folder
          : messages.form.delete_form;
      updateFormsList(user_id, jwt_token);
    }
    notify({
      isNotified: true,
      message,
      theme,
      placement: "top",
      timeout: 2500,
    });
  } else {
    setPopup("confirmDeleteForm");
    setAlertMessage(
      `<b>Removing this ${
        type == "folder"
          ? "folder will detach all forms "
          : "form will remove all records "
      } associated with it.<br/><br/> Are you sure ?</b>`
    );
  }
}

export async function toggleFormPin(
  user_id: string,
  form_id: string,
  jwt_token: string,
  isFolderForms?: boolean
) {
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/toggle/pin`,
    jwt_token,
    body: {
      user_id,
      form_id,
    },
  };
  let formPinned = await fetchApi(apiParams);
  if (formPinned?.statusCode == 401 || formPinned?.statusCode == 403) {
    window.location.href = "/logout";
  }
  if (formPinned && formPinned.status) {
    setSelectedDropDown("");
    let forms: any = isFolderForms ? selectedFolderData().forms : formsList();
    forms = forms
      .map((form: any) =>
        form.form_id == form_id
          ? { ...form, is_pinned: form.is_pinned ? false : true }
          : form
      )
      .sort((a: any, b: any) => {
        if (a.theme === "folder" && b.theme !== "folder") return -1;
        if (a.theme !== "folder" && b.theme === "folder") return 1;
        if (a.is_pinned && !b.is_pinned) return -1;
        if (!a.is_pinned && b.is_pinned) return 1;
        return a.name.localeCompare(b.name);
      });
    if (isFolderForms) {
      setSelectedFolderData({
        ...selectedFolderData(),
        forms,
      });
    } else {
      setFormsList(forms);
    }
  }
  adjustFormFolderNames();
}

export async function attachFormToFolder(
  jwt_token: string,
  user_id: string,
  form_id: string,
  folder_id: string,
  isFolderForm?: boolean
) {
  if (isFolderForm) {
    setSelectedFolderData({
      ...selectedFolderData(),
      forms: selectedFolderData().forms.filter(
        (form: any) => form.id != form_id
      ),
    });
  } else {
    setFormsList(
      formsList().filter((form: any) => !form_id.split(",").includes(form.id))
    );
    adjustFormFolderNames();
  }
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/folder/attach`,
    jwt_token,
    body: {
      user_id,
      form_id,
      folder_id,
    },
  };
  const attachFolder = await fetchApi(apiParams);
  if (attachFolder?.statusCode == 401 || attachFolder?.statusCode == 403) {
    window.location.href = "/logout";
  }
  updateFormsList(user_id, jwt_token);
}

export async function updateFormsList(user_id: any, jwt_token: any) {
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/list`,
    jwt_token: jwt_token as string,
    body: {
      user_id,
      isListing: true,
      offset: 0,
      limit: 48,
      search_string: searchString(),
    },
  };
  const formsList = await fetchApi(apiParams);
  if (formsList?.statusCode == 401 || formsList?.statusCode == 403) {
    window.location.href = "/logout";
  }
  setFormsList(formsList.data.forms);
  if (selectedFolderData()?.id) {
    setSelectedFolderData(
      formsList.data.forms.filter(
        (form: any) => form.id == selectedFolderData().id
      )[0]
    );
  }
  adjustFormFolderNames();
}

export async function shareForm(
  user_id: string,
  jwt_token: string,
  form_id: any,
  isForm: boolean
) {
  if (isForm) {
    const formUsersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/user/list`,
      jwt_token,
      body: {
        user_id,
        form_id: isForm ? form_id : form_id[0],
        search_string: "",
      },
    };
    let list = await fetchApi(formUsersListApiParams);
    if (list?.statusCode == 401 || list?.statusCode == 403) {
      window.location.href = "/logout";
    }
    let formUsers = list.data?.formUsers;
    let formTeams = list.data?.formTeams;
    const result = formUsers
      .reduce((acc: any[], item: any) => {
        const existing = acc.find(
          (entry: any) => entry.user_id === item.user_id
        );
        if (item.type === "user" && !existing)
          acc.push({ ...item, name: item.name || "" });
        else if (item.type === "team") {
          if (existing) {
            existing.name += existing.name
              ? `|${item.name || ""}`
              : item.name || "";
          } else {
            acc.push({ ...item, name: item.name || "" });
          }
        }
        return acc;
      }, [])
      .reduce((acc: any[], item: any) => {
        const existing = acc.find(
          (entry: any) => entry.user_id === item.user_id
        );
        if (!existing) acc.push(item);
        else if (item.type === "team" && existing.type === "user") {
          existing.type = "team";
          existing.name = item.name || existing.name;
        }
        return acc;
      }, []);
    setFormSharedUsers([
      { form_id: form_id, users: formUsers.map((user: any) => user.user_id) },
    ]);
    setSelectedFormTeams(formTeams);
    setSelectedFormUsers(result);
  }
  setIsFormSelected(isForm);
  setPopup("shareForm");
}

export async function formSettings(
  user_id: string,
  form_id: string,
  jwt_token: string
) {
  {
    const formStructureApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/view`,
      jwt_token,
      body: { form_id, user_id },
    };
    let formStructure = await fetchApi(formStructureApiParams);
    if (formStructure?.statusCode == 401 || formStructure?.statusCode == 403) {
      window.location.href = "/logout";
    }
    if (formStructure.status && formStructure.data != null) {
      formStructure = JSON.parse(formStructure.data.structure);
    }
    setFormJson({
      root: { formStructure },
    });
    setFormJson({
      pages: formJson.root?.formStructure.pages,
    });
    if (formStructure.theme) {
      setActiveTheme(formStructure.theme);
    }
    if (formStructure.isPDFTextBoxEnabled) {
      setPdfTextBoxEnabled(formStructure.isPDFTextBoxEnabled);
    }
    if (formStructure.isPDFRecordsEnabled) {
      setPdfRecordsEnabled(formStructure.isPDFRecordsEnabled);
    }
    if (formStructure.isEmbedImageEnabled) {
      setEmbedImageEnabled(formStructure.isEmbedImageEnabled);
    }
    if (formStructure.isKioskModeEnabled) {
      setKioskModeEnabled(formStructure.isKioskModeEnabled);
    }
    if (formStructure.isRecordSortingDisabled) {
      setRecordSortingDisabled(formStructure.isRecordSortingDisabled);
    }
    if (formStructure.defaultFontSize) {
      setFontSize(formStructure.defaultFontSize);
    }
    if (formStructure.paperSize) {
      setPaperSize(formStructure.paperSize);
    }
    setPopup("formSettings");
  }
}

export async function formFieldLinker(
  user_id: string,
  form_id: string,
  jwt_token: string
) {
  {
    const formStructureApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/view`,
      jwt_token,
      body: { form_id, user_id },
    };
    let formStructure = await fetchApi(formStructureApiParams);
    if (formStructure?.statusCode == 401 || formStructure?.statusCode == 403) {
      window.location.href = "/logout";
    }
    if (formStructure.status && formStructure.data != null) {
      formStructure = JSON.parse(formStructure.data.structure);
    }
    setFormJson({
      root: { formStructure },
    });
    setFormJson({
      pages: formJson.root?.formStructure.pages,
    });
    if (formStructure.theme) {
      setActiveTheme(formStructure.theme);
    }
    setPopup("FieldLinker");
  }
}

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        isListing: true,
        search_string: searchString(),
        offset: 0,
        limit: 48,
      },
    };
    let formsList = await fetchApi(apiParams);
    if (formsList?.statusCode == 401 || formsList?.statusCode == 403) {
      throw redirect("/logout");
    }

    const totalFolders = formsList.data.totalFolders;
    const totalForms = formsList.data.totalForms;
    const totalFolderForms = formsList.data.totalFolderForms;
    formsList = formsList.data.forms;

    const groupsListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/group/list`,
      jwt_token: jwt_token as string,
      body: { user_id, search_string: "", sort_string: "asc_name" },
    };
    let groups = await fetchApi(groupsListApiParams);
    if (groups?.statusCode == 401) {
      throw redirect("/logout");
    }

    groups = groups.data?.groupsList;
    const usersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        search_string: "",
        sort_string: "",
      },
    };
    let users = await fetchApi(usersListApiParams);
    if (users?.statusCode == 401 || users?.statusCode == 403) {
      throw redirect("/logout");
    }
    users = users.data?.usersList.users;

    return {
      userDetails,
      formsList,
      totalFolders,
      totalForms,
      totalFolderForms,
      user_id,
      jwt_token,
      groups,
      users,
    };
  });
}

const index: Component = () => {
  setIsPageLoader(true);
  setPageTitle(`${SITE_TITLE} - All Forms`);
  const data = useRouteData<typeof routeData>();
  const navigate = useNavigate();
  const [formViewType, setFormViewType] = createSignal("" as string);
  const [isSwapStart, setIsSwapStart] = createSignal("" as string);
  const [isDroppableDiv, setIsDroppableDiv] = createSignal("" as string);
  const [selectedFormId, setSelectedFormId] = createSignal("" as string);
  const [allFormsSelected, setAllFormsSelected] = createSignal(
    false as boolean
  );
  const [isListLoader, setIsListLoader] = createSignal(false as boolean);
  const [totalFormFolders, setTotalFormFolders] = createSignal(0 as number);
  const [formMoveId, setFormMoveId] = createSignal("" as string);
  const [isMoveToTopVisible, setIsMoveToTopVisible] = createSignal(
    false as boolean
  );

  let previousSearch: string = "";

  createEffect(() => {
    setTimeout(() => {
      adjustFormFolderNames();
    }, 0);
  });

  createEffect(() => {
    if (data()?.user_id && clearFormSearch()) {
      setClearFormSearch(false);
      setSearchString("");
      updateFormsList(data()?.user_id, data()?.jwt_token);
    }
  });

  createEffect(async () => {
    if (data()?.formsList && data()?.formsList.length > 0) {
      setIsPageLoader(false);
      setFoldersList(
        foldersList()?.length == 0
          ? data()?.formsList.filter(
              (item: any) =>
                item.theme == "folder" &&
                !(userRole() != "admin" && item.shared_users?.length > 1)
            )
          : foldersList()
      );
    } else {
      setIsPageLoader(false);
    }
    if (data()?.totalFolders && data()?.totalForms) {
      setTotalFormFolders(data()?.totalFolders + data()?.totalForms);
    }
    if (data()?.totalForms) {
      if (data()?.totalFolderForms) {
        setTotalForms(data()?.totalForms + data()?.totalFolderForms);
      } else {
        setTotalForms(data()?.totalForms);
      }
    }
    if (searchString() && searchString() !== previousSearch) {
      const newUrl = `/form/list?search=${encodeURIComponent(searchString())}`;
      window.history.pushState({ search: searchString() }, "", newUrl);
      debouncedHandleSearch(searchString());
      previousSearch = searchString();
    }
    // setTimeout(async () => {
    //   if (searchString()) {
    //     const newUrl = `/form/list?search=${encodeURIComponent(
    //       searchString()
    //     )}`;
    //     window.history.pushState({ search: searchString() }, "", newUrl);
    //   }
    //   var urlString = new URLSearchParams(window.location.search);
    //   let search: any = urlString.get("search");
    //   if (search) {
    //     setSearchString(search);
    //     const apiParams = {
    //       method: "POST" as string,
    //       url: `${import.meta.env.VITE_API_URL}/form/list`,
    //       jwt_token: data()?.jwt_token as string,
    //       body: {
    //         user_id: data()?.user_id,
    //         search_string: search.trim(),
    //         isListing: true,
    //         offset: 0,
    //         limit: 48,
    //       },
    //     };
    //     let formsList = await fetchApi(apiParams);
    //     if (formsList?.statusCode == 401 || formsList?.statusCode == 403) {
    //       window.location.href = "/logout";
    //     }
    //     setFormsList(formsList.data.forms);
    //     setTotalFormFolders(
    //       formsList.data.totalFolders + formsList.data.totalForms
    //     );
    //     adjustFormFolderNames();
    //   }
    // }, 0);
    if (!popupQueue.includes("shareForm")) {
      setSelectedForms([]);
      setSelectFormsToShare(false);
      setAllFormsSelected(false);
    }
    if (openFolder()) {
      setPopup("folderForms");
      setOpenFolder(false);
    }
  });

  createEffect(() => {
    const urlString = new URLSearchParams(window.location.search);
    const search = urlString.get("search");
    if (search) {
      setSearchString(search);
      debouncedHandleSearch(search.trim());
    }
  });

  createEffect(() => {
    setTimeout(() => {
      const urlParams = new URLSearchParams(window.location.search);
      let folder: any = urlParams.get("folder");
      if (folder) {
        setCurrentformName(
          formsList().find(
            (obj: any) => obj.theme == "folder" && obj.name == folder
          )?.name
        );
        setLastOpenedForm(
          formsList().find(
            (obj: any) => obj.theme == "folder" && obj.name == folder
          )?.id
        );
        setSelectedFolderData(
          formsList().find(
            (obj: any) => obj.theme == "folder" && obj.name == folder
          )
        );
        setPopup("folderForms");
      }
    }, 0);
  });

  onMount(async () => {
    if (lastOpenedForm()) {
      setTimeout(() => {
        const scrollElem: any = document.querySelector(".main-content");
        const elm: any = document.getElementById(
          `${formViewType()}_form_${lastOpenedForm()}`
        );
        if (elm) {
          const rect = elm.getBoundingClientRect();
          const y = rect.top + window.scrollY;
          scrollElem.scroll({
            top: formViewType() == "list" ? y - 130 : y - 140,
            behavior: "smooth",
          });
        }
      }, 0);
    }
    if (isFolderForm()) {
      setPopup("folderForms");
      setIsFolderForm(false);
    } else {
      setSelectedFolderData([]);
    }
    setFormRecords([]);
    setRecordsList([]);
    if (formsList().length == 0) {
      setFormsList(data()?.formsList);
    }
    setExportFormSettings(false);
    setFormSettingsBar(false);
    setSelectedRecords([]);
    setCurrentformName("");
    setOffset(0);
    setRecordsJson({
      currentRecord: {},
      multipleRecords: [],
    });
    setSelectedRecordId("");
    setFormJson({
      root: {},
      pages: [],
      currentPage: 0,
    });
    setPages([]);
    if (importRecordFile() && importRecordFile()?.name) {
      setSelectFormsToShare(true);
    }
    if (userRole() == "" && localStorage.getItem("fc_first_login") != "true") {
      localStorage.setItem("user_id", data()?.userDetails.data.id);
      window.localStorage.setItem(
        "logged_in_at",
        data()?.userDetails.data.login_logs[0].logged_in_at
      );
      window.location.reload();
    } else {
      checkNotificationPermission();
    }
    if (localStorage.getItem("fc_first_login") == "true") {
      setTimeout(() => {
        localStorage.setItem("user_id", data()?.userDetails.data.id);
        window.localStorage.setItem(
          "logged_in_at",
          data()?.userDetails.data.login_logs[0].logged_in_at
        );
        window.location.reload();
      }, 100);
      localStorage.removeItem("fc_first_login");
    }
    if (userRole() == "admin" || userRole() == "sub_admin") {
      if (window.localStorage?.hasOwnProperty("fc_form_view")) {
        setFormViewType(window.localStorage?.getItem("fc_form_view"));
      } else {
        setFormViewType("icons");
      }
    } else if (
      userRole() != "" &&
      userRole() != "admin" &&
      userRole() != "sub_admin"
    ) {
      setFormViewType("icons");
    }
    const urlParams = new URLSearchParams(window.location.search);
    const code: any = urlParams.get("code");
    const token: any = new URLSearchParams(
      window.location.hash.substring(1)
    ).get("access_token");
    if (code || token) {
      const newUrl = window.location.href.split("?")[0];
      try {
        let response: any;
        let resData: any;
        switch (localStorage.getItem("fc_export_type")) {
          case "dropbox":
            response = await fetch("https://api.dropboxapi.com/oauth2/token", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                code,
                grant_type: "authorization_code",
                client_id: `${import.meta.env.VITE_DROPBOX_APP_KEY}`,
                client_secret: `${import.meta.env.VITE_DROPBOX_APP_SECRET}`,
                redirect_uri: `${import.meta.env.VITE_APP_URL}/form/list`,
              }),
            });
            resData = await response.json();
            if (response.ok) {
              updateServiceToken(
                data()?.user_id,
                data()?.jwt_token,
                "dropbox",
                resData.access_token
              );
              getDropBoxCurrentAccountDetails(resData.access_token).then(() => {
                fetchDropboxFoldersList(resData.access_token, "").then(() => {
                  setPopup("exportFiles");
                });
              });
              window.history.replaceState({}, document.title, newUrl);
            } else {
              window.history.replaceState({}, document.title, newUrl);
              localStorage.removeItem("fc_export_files");
              localStorage.removeItem("fc_export_type");
              console.error("Error in token exchange:", resData);
            }
            break;
          case "box":
            response = await fetch("https://api.box.com/oauth2/token", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                code,
                grant_type: "authorization_code",
                client_id: `${import.meta.env.VITE_BOX_CLIENT_ID}`,
                client_secret: `${import.meta.env.VITE_BOX_CLIENT_SECRET}`,
                redirect_uri: `${import.meta.env.VITE_APP_URL}/form/list`,
              }),
            });
            resData = await response.json();
            if (response.ok) {
              updateServiceToken(
                data()?.user_id,
                data()?.jwt_token,
                "box",
                resData.access_token
              );
              getBoxCurrentAccountDetails(
                data()?.jwt_token,
                resData.access_token
              ).then(() => {
                fetchBoxFoldersList(
                  data()?.jwt_token,
                  resData.access_token,
                  "0"
                ).then(() => {
                  setPopup("exportFiles");
                });
              });
              window.history.replaceState({}, document.title, newUrl);
            } else {
              window.history.replaceState({}, document.title, newUrl);
              localStorage.removeItem("fc_export_files");
              localStorage.removeItem("fc_export_type");
              console.error("Error in token exchange:", resData);
            }
            break;
          case "googledrive":
            response = await fetch("https://oauth2.googleapis.com/token", {
              method: "POST",
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
              body: new URLSearchParams({
                code,
                grant_type: "authorization_code",
                client_id: `${import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_ID}`,
                client_secret: `${
                  import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_SECRET
                }`,
                redirect_uri: `${import.meta.env.VITE_APP_URL}/form/list`,
              }),
            });
            resData = await response.json();
            if (response.ok) {
              updateServiceToken(
                data()?.user_id,
                data()?.jwt_token,
                "googledrive",
                resData.access_token
              );
              getGoogleDriveCurrentAccountDetails(resData.access_token).then(
                () => {
                  fetchGoogleDriveFoldersList(resData.access_token, "").then(
                    () => {
                      setPopup("exportFiles");
                    }
                  );
                }
              );
              window.history.replaceState({}, document.title, newUrl);
            } else {
              window.history.replaceState({}, document.title, newUrl);
              localStorage.removeItem("fc_export_files");
              localStorage.removeItem("fc_export_type");
              console.error("Error in token exchange:", resData);
            }
            break;
          case "onedrive":
            updateServiceToken(
              data()?.user_id,
              data()?.jwt_token,
              "onedrive",
              token
            );
            getOneDriveCurrentAccountDetails(token).then(() => {
              fetchOneDriveFoldersList(token, "").then(() => {
                setPopup("exportFiles");
              });
            });
            let url: string = window.location.href.split("#")[0];
            window.history.replaceState({}, document.title, url);
            break;
          default:
            break;
        }
      } catch (error) {
        window.history.replaceState({}, document.title, newUrl);
        localStorage.removeItem("fc_export_files");
        localStorage.removeItem("fc_export_type");
        console.error("Error exchanging code for token:", error);
      }
    }
    const debouncedScrollHandler = debounce(handleScroll, 200);
    const element: any = document.querySelector(".main-content");
    element.addEventListener("scroll", debouncedScrollHandler);
    localStorage.removeItem("fc_export_files");
  });

  const fetchForms = async (search: string) => {
    setIsPageLoader(true);
    const apiParams = {
      method: "POST",
      url: `${import.meta.env.VITE_API_URL}/form/list`,
      body: {
        user_id: data()?.user_id,
        search_string: search.trim(),
        isListing: true,
        offset: 0,
        limit: 48,
      },
      jwt_token: data()?.jwt_token,
    };
    const response = await fetchApi(apiParams);
    setIsPageLoader(false);
    if (response?.statusCode === 401 || response?.statusCode === 403) {
      window.location.href = "/logout";
      return;
    }
    setFormsList(response.data.forms || []);
    setTotalFormFolders(response.data.totalFolders + response.data.totalForms);
    adjustFormFolderNames();
  };

  const handleSearch = (newSearch: string) => {
    if (newSearch && newSearch !== previousSearch) {
      fetchForms(newSearch);
    }
  };

  function checkNotificationPermission() {
    if (typeof Notification == "function") {
      if (
        Notification.permission === "default" ||
        Notification.permission === "denied"
      ) {
        if (
          !navigator.userAgent.includes("Chrome") &&
          navigator.userAgent.includes("Safari")
        ) {
          if (localStorage.getItem("notificationPermission") != "true") {
            setPopup("confirmPermission");
            setAlertMessage("<b>Give access to show Notifications.</b>");
          }
        } else {
          Notification.requestPermission().then((permission: any) => {
            if (permission == "granted") {
              requestNotificationPermission();
            } else {
              if (localStorage.getItem("notificationPermission") != "true") {
                setPopup("confirmPermission");
                setAlertMessage("<b>Give access to show Notifications.</b>");
              }
            }
          });
        }
      } else {
        requestNotificationPermission();
      }
    }
  }

  function accessPermission() {
    localStorage.setItem("notificationPermission", "true");
    Notification.requestPermission().then((permission: any) => {
      if (permission == "granted") {
        requestNotificationPermission();
      }
    });
    removePopup("confirmPermission");
  }

  const requestNotificationPermission = async () => {
    const messaging = getMessaging();
    try {
      getToken(messaging, {
        vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
      })
        .then((currentToken) => {
          if (currentToken) {
            setFireBaseToken(currentToken);
          } else {
            console.error(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.error("An error occurred while retrieving token. ", err);
        });
    } catch (error) {
      console.error("Error requesting permission:", error);
    }
  };

  const debounce = (func: any, delay: any) => {
    let timeoutId: any;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const debouncedHandleSearch = debounce(handleSearch, 200);

  const handleScroll = async (event: any) => {
    setIsMoveToTopVisible(event.target.scrollTop > 300 ? true : false);
    const scrollableHeight =
      event.target.scrollHeight - event.target.clientHeight;
    const currentPosition = event.target.scrollTop;
    if (
      currentPosition >= scrollableHeight - 10 &&
      formsList().length != totalFormFolders() &&
      !isListLoader()
    ) {
      setIsListLoader(true);
      const apiParams = {
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/form/list`,
        jwt_token: data()?.jwt_token,
        body: {
          user_id: data()?.user_id,
          isListing: true,
          search_string: searchString(),
          offset: 24 * Math.ceil(formsList().length / 24),
          limit: 48,
        },
      };
      const list = await fetchApi(apiParams);
      if (list?.statusCode == 401 || list?.statusCode == 403) {
        window.location.href = "/logout";
      }
      if (list) {
        setFormsList([...formsList(), ...list.data.forms]);
        adjustFormFolderNames();
        setFoldersList(
          formsList().filter(
            (item: any) =>
              item.theme == "folder" &&
              !(userRole() != "admin" && item.shared_users?.length > 1)
          )
        );
        setIsListLoader(false);
      }
    }
  };

  function toggleFormViewType(type: string) {
    setFormViewType(type);
    window.localStorage.setItem("fc_form_view", type);
    adjustFormFolderNames();
  }

  async function exportForm(form_id: string) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/export`,
      jwt_token: data()?.jwt_token as string,
      body: {
        form_id,
      },
    };
    let exportForm = await fetchApi(apiParams);
    if (exportForm?.statusCode == 401) {
      window.location.href = "/logout";
    }
    let message: any = messages.error.error;
    let theme: any = "danger";
    if (exportForm && exportForm.status) {
      const blob = new Blob([exportForm.data.encryptJSON], {
        type: "application/octet-stream",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = exportForm.data.fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      theme = "success";
      message = messages.form.export_form;
    }
    notify({
      isNotified: true,
      message,
      theme,
      placement: "top",
      timeout: 2500,
    });
  }

  return (
    <main>
      <Show when={isMoveToTopVisible()}>
        <button
          class="btn sm move-to-top"
          onclick={() => {
            const element: any = document.querySelector(".main-content");
            element.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <Icons name="arrowUp" stroke="#2e61a3" width={30} height={30}></Icons>
        </button>
      </Show>
      <Show when={isListLoader()}>
        <div class="form-list-loader">
          <span>Loading</span>
          <Icons name="loader"></Icons>
        </div>
      </Show>
      <ExportFilesPopup
        user_id={data()?.user_id}
        jwt_token={data()?.jwt_token}
        isListPage={true}
      ></ExportFilesPopup>
      <Show when={popupQueue.includes("shareForm")}>
        <ShareForm
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          users={data()?.users}
          groups={data()?.groups}
          form_id={selectedForms().join(",")}
          formUsers={selectedFormUsers()}
          formTeams={selectedFormTeams()}
          isForm={isFormSelected()}
          folder_id={selectedFolderId()}
          folderUsers={folderUsers()}
          setValue={(val: any) => {
            if (val) {
              setSelectFormsToShare(false);
              setSelectedForms([]);
              setAllFormsSelected(false);
              removePopup("shareForm");
              updateFormsList(data()?.user_id, data()?.jwt_token);
            }
          }}
        ></ShareForm>
      </Show>
      <Show when={popupQueue.includes("assigneeList")}>
        <AssigneeList
          list={assigneeList()}
          avatarList={assigneeAvatarsList()}
          heading={currentformName()}
          isFormList={true}
        ></AssigneeList>
      </Show>
      <Show when={popupQueue.includes("folderForms")}>
        <FolderForms
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></FolderForms>
      </Show>
      <Show when={popupQueue.includes("FormPreview")}>
        <FormPreview
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          setValue={(val: boolean) => {
            if (val) updateFormsList(data()?.user_id, data()?.jwt_token);
          }}
        ></FormPreview>
      </Show>
      <Show when={popupQueue.includes("FieldLinker")}>
        <FieldLinkerPopup
          jwt_token={data()?.jwt_token}
          user_id={data()?.user_id}
          form_id={selectedFormId()}
        ></FieldLinkerPopup>
      </Show>
      <Show when={popupQueue.includes("formLinkField")}>
        <LinkFieldPopup
          name="formLinkField"
          data={formFieldsToLink()}
          type={formFieldLinkType()}
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></LinkFieldPopup>
      </Show>
      <Show when={popupQueue.includes("foldersList")}>
        <FoldersList
          setValue={(folder_id: string) => {
            removePopup("foldersList");
            attachFormToFolder(
              data()?.jwt_token,
              data()?.user_id,
              formMoveId(),
              folder_id
            );
          }}
        ></FoldersList>
      </Show>
      <ConfirmPopup
        message={alertMessage()}
        name={
          popupQueue.includes("warning")
            ? "warning"
            : popupQueue.includes("alert")
            ? "alert"
            : popupQueue.includes("importAlert")
            ? "importAlert"
            : popupQueue.includes("confirmPermission")
            ? "confirmPermission"
            : "confirmDeleteForm"
        }
        onconfirm={(status: boolean) => {
          if (popupQueue.includes("warning")) {
            if (status) {
              let parentFieldArr: any = linkedParentValues().filter(
                (obj: any) => {
                  return obj.id !== formFieldRemoveValue();
                }
              );
              setLinkedParentValues(parentFieldArr);
              let childFormArr = linkedFormValues().filter((obj: any) => {
                return obj.id !== formFieldRemoveValue();
              });
              childFormArr = childFormArr.filter((obj: any) => {
                return obj.parentField !== formFieldRemoveValue();
              });
              setLinkedFormValues(childFormArr);
              let uniqueChildFormArr = childFormArr.filter(
                (item: any, index: any, self: any) =>
                  index === self.findIndex((t: any) => t.id === item.id)
              );
              setFormValues(uniqueChildFormArr);
              let childFieldArr = linkedLinkValues().filter((obj: any) => {
                return obj.childForm !== formFieldRemoveValue();
              });
              childFieldArr = childFieldArr.filter((obj: any) => {
                return obj.parentField !== formFieldRemoveValue();
              });
              setLinkedLinkValues(childFieldArr);
            }
            setLinkedField("");
            removePopup("warning");
          } else if (popupQueue.includes("alert")) {
            removePopup("alert");
          } else if (popupQueue.includes("importAlert")) {
            removePopup("importAlert");
          } else if (popupQueue.includes("confirmPermission")) {
            accessPermission();
          } else {
            removePopup("confirmDeleteForm");
            if (status) {
              setConfirmDeleteForm(true);
              deleteFormStructure(
                data()?.user_id,
                data()?.jwt_token,
                selectedFormUuid(),
                selectedFormType() == "folder" ? "folder" : "form"
              );
              removePopup("folderForms");
            }
          }
        }}
        warning={popupQueue.includes("warning") ? true : false}
        alert={
          popupQueue.includes("alert") || popupQueue.includes("importAlert")
            ? true
            : false
        }
      ></ConfirmPopup>
      <Show when={popupQueue.includes("formSettings")}>
        <FormSettingsPopup
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          form_id={selectedFormId()}
        ></FormSettingsPopup>
      </Show>
      <SystemLayout>
        <div class="search-bar mb-5">
          <input
            type="hidden"
            name="totalForms"
            value={data()?.totalForms + data()?.totalFolderForms}
          />
          <h2 class="title color">Forms ({totalForms()})</h2>
          <Show when={selectFormsToShare()}>
            <div>
              <span class="lbl badge selected">
                {importRecordFile() && importRecordFile()?.name
                  ? "Select which form you want to import this file into."
                  : "Select Forms To Share With Users."}
              </span>
              <div class="underline"></div>
            </div>
          </Show>
          <div class="flex app-flex gap align-center">
            <div class="app-w-50">
              <Search
                value={searchString()}
                onEnter={async (search: any) => {
                  const apiParams = {
                    method: "POST" as string,
                    url: `${import.meta.env.VITE_API_URL}/form/list`,
                    jwt_token: data()?.jwt_token as string,
                    body: {
                      user_id: data()?.user_id,
                      search_string: search.trim(),
                      isListing: true,
                      offset: 0,
                      limit: 48,
                    },
                  };
                  let formsList = await fetchApi(apiParams);
                  if (
                    formsList?.statusCode == 401 ||
                    formsList?.statusCode == 403
                  ) {
                    window.location.href = "/logout";
                  }
                  setFormsList(formsList.data.forms);
                  setTotalFormFolders(
                    formsList.data.totalFolders + formsList.data.totalForms
                  );
                  const newUrl = `/form/list?search=${encodeURIComponent(
                    search
                  )}`;
                  window.history.pushState({ search: search }, "", newUrl);
                  setSearchString(search);
                  adjustFormFolderNames();
                }}
                placeholder="Search Form"
                isNotGlobalSearch={true}
              ></Search>
            </div>
            {/* <Show
              when={
                userRole() == "admin" ||
                userRole() == "sub_admin" ||
                userRole() == "designer"
              }
            >
              <input
                type="file"
                class="d-n"
                id="import_form"
                accept=".fmcx,.json"
                onChange={async (event: any) => {
                  const file: any = event.target.files[0];
                  if (file) {
                    const formData: any = new FormData();
                    formData.append("user_id", data()?.user_id);
                    formData.append("structure", file);
                    const response = await fetch(
                      `${import.meta.env.VITE_API_URL}/form/import`,
                      {
                        method: "POST",
                        headers: {
                          Authorization: `Bearer ${data()?.jwt_token}`,
                        },
                        body: formData,
                      }
                    ).then((res: any) => res.json());
                    if (response.statusCode == 401) {
                      location.href = "/logout";
                    } else {
                      let message: any = "";
                      let theme: any = "danger";
                      if (response && response.status) {
                        theme = "success";
                        message = messages.form.design;
                      } else {
                        message = response.message;
                      }
                      notify({
                        isNotified: true,
                        message,
                        theme,
                        placement: "top",
                        timeout: 2500,
                      });
                    }
                  }
                  event.target.value = null;
                  updateFormsList(data()?.user_id, data()?.jwt_token);
                }}
              />
              <label class="btn sm primary" for="import_form">
                Import Form
              </label>
            </Show> */}
            <Show
              when={
                formsList()?.length > 0 &&
                (userRole() == "admin" || userRole() == "sub_admin")
              }
            >
              <Show
                when={
                  userRole() == "admin" &&
                  selectFormsToShare() &&
                  selectedForms().length > 0 &&
                  importRecordFile() &&
                  importRecordFile()?.name
                }
              >
                <button
                  class="btn sm primary"
                  onclick={async () => {
                    const formData: any = new FormData();
                    formData.append("user_id", data()?.user_id);
                    formData.append("form_id", selectedForms()[0]);
                    formData.append("structure", importRecordFile());
                    const response = await fetch(
                      `${import.meta.env.VITE_API_URL}/form/records/import`,
                      {
                        method: "POST",
                        headers: {
                          Authorization: `Bearer ${data()?.jwt_token}`,
                        },
                        body: formData,
                      }
                    ).then((res: any) => res.json());
                    if (response.statusCode == 401) {
                      location.href = "/logout";
                    } else {
                      setSelectFormsToShare(false);
                      setSelectedForms([]);
                      setImportRecordFile({});
                      if (
                        response.message == "IMPORT_FILE_SUCCESS" ||
                        response.message == "IMPORT_FILE_ERROR"
                      ) {
                        if (response.message == "IMPORT_FILE_SUCCESS") {
                          setFormsList(
                            formsList().map((form: any) => {
                              if (form.id === response.data.formId) {
                                return {
                                  ...form,
                                  totalRecords:
                                    form.totalRecords +
                                    response.data.recordsAdded,
                                };
                              }
                              return form;
                            })
                          );
                          adjustFormFolderNames();
                          navigate(`/form/render/${response.data.formId}`);
                        }
                        notify({
                          isNotified: true,
                          message: messages.form[response.message],
                          theme:
                            response.message == "IMPORT_FILE_SUCCESS"
                              ? "success"
                              : "danger",
                          placement: "top",
                          timeout: 2500,
                        });
                      } else {
                        setAlertMessage(
                          [
                            "INVALID_JSON_FILE",
                            "INVALID_XML_FILE",
                            "INVALID_CSV_FILE",
                            "IMPORT_CSV_ERROR",
                            "IMPORT_JSON_ERROR",
                            "IMPORT_XML_ERROR",
                          ].includes(response.message)
                            ? messages.form[response.message]
                            : `<div class="fs-18 fw-600 text-center">${response.message}</div>`
                        );
                        setPopup("importAlert");
                      }
                    }
                  }}
                >
                  Import
                </button>
              </Show>
              <Show
                when={
                  selectFormsToShare() &&
                  selectedForms().length > 0 &&
                  !importRecordFile()?.name
                }
              >
                <button
                  class="btn primary sm"
                  onclick={async () => {
                    if (selectedForms()?.length > 0) {
                      selectedForms().forEach(
                        async (form_id: string, key: number) => {
                          const formUsersListApiParams = {
                            method: "POST" as string,
                            url: `${
                              import.meta.env.VITE_API_URL
                            }/form/user/list`,
                            jwt_token: data()?.jwt_token,
                            body: {
                              user_id: data()?.user_id,
                              form_id,
                              search_string: "",
                            },
                          };
                          let list = await fetchApi(formUsersListApiParams);
                          if (list?.statusCode == 401) {
                            window.location.href = "/logout";
                          }
                          if (list.status) {
                            setFormSharedUsers([
                              ...formSharedUsers(),
                              { form_id, users: list.data?.formUsers },
                            ]);
                            if (key == selectedForms().length - 1) {
                              setSelectedFormUsers([]);
                              setIsFormSelected(true);
                              setPopup("shareForm");
                            }
                          }
                        }
                      );
                    }
                  }}
                >
                  Share Forms
                </button>
              </Show>
              <button
                class={`btn sm ${selectFormsToShare() ? "danger" : "primary"}`}
                onclick={() => {
                  if (selectFormsToShare()) {
                    setSelectFormsToShare(false);
                    setSelectedForms([]);
                    setAllFormsSelected(false);
                    if (importRecordFile() && importRecordFile()?.name) {
                      setImportRecordFile({});
                    }
                  } else {
                    setSelectFormsToShare(true);
                  }
                }}
              >
                {!selectFormsToShare() ? "Select Forms" : "Cancel"}
              </button>
              <div class="switch-wrapper toggle-list mb-0">
                <input
                  id="users"
                  type="radio"
                  name="switch"
                  onchange={() => toggleFormViewType("list")}
                  checked={formViewType() == "list"}
                />
                <input
                  id="groups"
                  type="radio"
                  name="switch"
                  onchange={() => toggleFormViewType("icons")}
                  checked={formViewType() == "icons"}
                />
                <label
                  class="flex align-center justify-center toggle-label p-5"
                  for="users"
                >
                  <Popover content="List view" left={40} top={-40}>
                    <Icons
                      name="list"
                      stroke={
                        formViewType() == "list"
                          ? "hsl(214, 56%, 50%)"
                          : "#525657"
                      }
                    ></Icons>
                  </Popover>
                </label>
                <label
                  class="flex align-center justify-center toggle-label"
                  for="groups"
                >
                  <Popover content="Grid view" left={80} top={-40}>
                    <Icons
                      name="icons"
                      stroke={
                        formViewType() == "icons"
                          ? "hsl(214, 56%, 50%)"
                          : "#525657"
                      }
                    ></Icons>
                  </Popover>
                </label>
                <span class="highlighter"></span>
              </div>
            </Show>
          </div>
        </div>
        <br />
        <Show when={formViewType() != ""}>
          <Show when={formViewType() == "list"}>
            <table class="table form-listing w-100">
              <thead>
                <tr>
                  <Show when={selectFormsToShare()}>
                    <th class="w-32p">
                      <Show
                        when={importRecordFile() && !importRecordFile()?.name}
                      >
                        <Checkbox
                          label=""
                          name="shareALLForms"
                          value={allFormsSelected()}
                          setValue={(val: any) => {
                            let tempArr: any = [];
                            if (val) {
                              tempArr = formsList()
                                .filter((obj: any) => obj.theme != "folder")
                                .map((obj: any) => obj.id);
                            }
                            setSelectedForms(tempArr);
                            setAllFormsSelected(true);
                          }}
                        ></Checkbox>
                      </Show>
                    </th>
                  </Show>
                  <th>Form Name</th>
                  <th class="text-center w-250">Forms Shared</th>
                  <th class="w-250">Action</th>
                </tr>
              </thead>
              <tbody>
                <For each={formsList()}>
                  {(form: any) => {
                    const combinedAssignees = [
                      ...(Array.isArray(form.assignee) ? form.assignee : []),
                      ...(Array.isArray(form.assigneeGroupNames)
                        ? form.assigneeGroupNames
                        : []),
                    ];
                    const combinedAvatars = [
                      ...(Array.isArray(form.assigneeAvatar)
                        ? form.assigneeAvatar
                        : []),
                      ...(Array.isArray(form.assigneeGroupNames)
                        ? form.assigneeGroupNames
                        : []),
                    ];
                    const uniqueAssignees = new Map();
                    combinedAssignees.forEach((username, index) => {
                      if (!uniqueAssignees.has(username)) {
                        uniqueAssignees.set(username, combinedAvatars[index]);
                      }
                    });
                    const deduplicatedAssignees = [...uniqueAssignees.keys()];
                    const deduplicatedAvatars = [...uniqueAssignees.values()];
                    return (
                      <>
                        <tr id={"list_form_" + form.id}>
                          <Show when={selectFormsToShare()}>
                            <td>
                              <Show when={form.theme != "folder"}>
                                <Checkbox
                                  label=""
                                  name="shareForms"
                                  value={
                                    selectedForms().filter(
                                      (item: any) => item == form.id
                                    ).length > 0
                                      ? true
                                      : false
                                  }
                                  setValue={(val: any) => {
                                    if (
                                      importRecordFile() &&
                                      importRecordFile()?.name
                                    ) {
                                      setSelectedForms([form.id]);
                                    } else {
                                      const tempArr: any = [...selectedForms()];
                                      const index: any = tempArr.findIndex(
                                        (item: any) => item == form.id
                                      );
                                      if (index === -1) {
                                        tempArr.push(form.id);
                                      } else {
                                        tempArr.splice(index, 1);
                                      }
                                      setSelectedForms(tempArr);
                                    }
                                  }}
                                ></Checkbox>
                              </Show>
                            </td>
                          </Show>
                          <td>
                            <div class="flex app-flex gap align-center">
                              <div class="po-r">
                                <img
                                  src={`/form_themes/${
                                    selectedFormId() == form.id
                                      ? activeTheme()
                                      : form.theme
                                  }.png`}
                                  class="form-table-image"
                                  onclick={() => {
                                    setCurrentformName(form.name);
                                    if (form.theme == "folder") {
                                      setLastOpenedForm(form.id);
                                      setSelectedFolderData(form);
                                      setPopup("folderForms");
                                    } else {
                                      setTotalRecordsCount(form.totalRecords);
                                      setTotalRecordsShowCount(
                                        form.totalRecords
                                      );
                                      setLastOpenedForm(form.id);
                                      navigate(`/form/render/${form.id}`);
                                    }
                                  }}
                                />
                                <Show
                                  when={
                                    userRole() != "admin" &&
                                    form.theme == "folder" &&
                                    form.shared_users?.length > 1
                                  }
                                >
                                  <span class="shared-folder">
                                    <Icons
                                      name="users"
                                      width={20}
                                      height={20}
                                    ></Icons>
                                  </span>
                                </Show>
                              </div>
                              <Show when={renameForm() != form.id}>
                                <span
                                  class="flex align-center cur-p"
                                  onclick={() => {
                                    setCurrentformName(form.name);
                                    if (form.theme == "folder") {
                                      setLastOpenedForm(form.id);
                                      setSelectedFolderData(form);
                                      setPopup("folderForms");
                                    } else {
                                      setTotalRecordsCount(form.totalRecords);
                                      setTotalRecordsShowCount(
                                        form.totalRecords
                                      );
                                      setLastOpenedForm(form.id);
                                      navigate(`/form/render/${form.id}`);
                                    }
                                  }}
                                  onmouseover={() => {
                                    setCurrentFormId(form.id);
                                  }}
                                  onmouseleave={() => {
                                    setCurrentFormId("");
                                  }}
                                >
                                  <span>
                                    {form.name}{" "}
                                    <Show
                                      when={
                                        form.theme != "folder" &&
                                        form.totalRecords > 0
                                      }
                                    >
                                      <small>
                                        {"(" +
                                          form.totalRecords +
                                          (form.totalRecords == 1
                                            ? " Record"
                                            : " Records") +
                                          ")"}
                                      </small>
                                    </Show>
                                  </span>
                                </span>
                              </Show>
                              <Show when={renameForm() == form.id}>
                                <div class="flex">
                                  <input
                                    id="rename_list"
                                    type="text"
                                    class="input w-160p"
                                    value={form.name}
                                    onblur={(e: any) => {
                                      setFormRenameValue(e.target.value);
                                    }}
                                    autofocus={true}
                                  />
                                  &nbsp;
                                  <Popover content="Save" top={-55} left={53}>
                                    <button
                                      class="btn sm btn-ico"
                                      onclick={() =>
                                        formRenameValue() != ""
                                          ? renameFormStructureName(
                                              data()?.user_id,
                                              data()?.jwt_token,
                                              form.theme != "folder"
                                                ? form.form_id
                                                : form.id,
                                              formRenameValue(),
                                              form.theme == "folder"
                                                ? "folder"
                                                : "form"
                                            )
                                          : ""
                                      }
                                    >
                                      <Icons name="check" width={20}></Icons>
                                    </button>
                                  </Popover>
                                  <Popover content="Close" top={-55} left={57}>
                                    <button
                                      class="btn sm btn-ico"
                                      onclick={() => {
                                        setRenameForm("");
                                        adjustFormFolderNames();
                                      }}
                                    >
                                      <Icons name="x" width={20}></Icons>
                                    </button>
                                  </Popover>
                                </div>
                              </Show>
                            </div>
                          </td>
                          <td>
                            <div class="form-users__group">
                              <For each={deduplicatedAssignees}>
                                {(assignee: any, key: any) => (
                                  <>
                                    <Show when={key() < 8}>
                                      <div
                                        class="form-user"
                                        style={{
                                          "--z-index":
                                            deduplicatedAssignees?.length -
                                            key(),
                                          "--background-color":
                                            deduplicatedAvatars[key()] != "" &&
                                            deduplicatedAvatars[key()].includes(
                                              "http"
                                            )
                                              ? "transparant"
                                              : userIcons().find(
                                                  (user: any) =>
                                                    user.name == assignee
                                                )?.theme
                                              ? userIcons().find(
                                                  (user: any) =>
                                                    user.name == assignee
                                                )?.theme
                                              : getRandomColorWithProperContrast(
                                                  "#ffffff"
                                                ),
                                          border:
                                            deduplicatedAvatars[key()] != "" &&
                                            deduplicatedAvatars[key()].includes(
                                              "http"
                                            )
                                              ? "none"
                                              : userIcons().find(
                                                  (user: any) =>
                                                    user.name == assignee
                                                )?.theme
                                              ? "none"
                                              : "2px solid",
                                        }}
                                        data-title={assignee}
                                      >
                                        <Show
                                          when={
                                            deduplicatedAvatars[key()] != "" &&
                                            deduplicatedAvatars[key()].includes(
                                              "http"
                                            )
                                          }
                                        >
                                          <img
                                            class="form-user"
                                            src={deduplicatedAvatars[key()]}
                                          ></img>
                                        </Show>
                                        <Show
                                          when={
                                            deduplicatedAvatars[key()] == "" ||
                                            !deduplicatedAvatars[
                                              key()
                                            ].includes("http")
                                          }
                                        >
                                          {userIcons().find(
                                            (user: any) => user.name == assignee
                                          )?.logo
                                            ? userIcons().find(
                                                (user: any) =>
                                                  user.name == assignee
                                              )?.logo
                                            : getSignatureInitials(assignee)}
                                        </Show>
                                      </div>
                                    </Show>
                                    <Show
                                      when={
                                        deduplicatedAssignees?.length > 8 &&
                                        deduplicatedAssignees?.length - 1 ==
                                          key()
                                      }
                                    >
                                      <div
                                        class="form-user"
                                        style={{
                                          "--z-index":
                                            deduplicatedAssignees?.length -
                                            key(),
                                          "--background-color": "#000000",
                                        }}
                                        data-title="more"
                                        onclick={() => {
                                          setCurrentformName(form.name);
                                          setAssigneeList({
                                            assignee: form.assignee,
                                            assigneeTeams:
                                              form.assigneeGroupNames,
                                          });
                                          setAssigneeAvatarsList({
                                            assigneeAvatar: form.assigneeAvatar,
                                          });
                                          setPopup("assigneeList");
                                        }}
                                      >
                                        <Icons
                                          name="plus"
                                          stroke="#ffffff"
                                        ></Icons>
                                      </div>
                                    </Show>
                                  </>
                                )}
                              </For>
                            </div>
                          </td>
                          <td class="w-250">
                            <div class="flex gap-0_5 w-100">
                              <Show
                                when={
                                  form.theme != "folder" ||
                                  (form.theme == "folder" &&
                                    userRole() == "admin")
                                  // !(
                                  //   userRole() != "admin" &&
                                  //   form.shared_users?.length > 1
                                  // )
                                }
                              >
                                <Popover
                                  content={`Share ${
                                    form.theme != "folder" ? "Form" : "Folder"
                                  }`}
                                  top={-55}
                                  left={78}
                                >
                                  <button
                                    class="btn sm btn-ico"
                                    onclick={() => {
                                      if (
                                        form.theme == "folder" &&
                                        form.forms?.length == 0
                                      ) {
                                        notify({
                                          isNotified: true,
                                          message:
                                            messages.form.no_folder_forms,
                                          theme: "warm",
                                          placement: "top",
                                          timeout: 2500,
                                        });
                                        return;
                                      }
                                      if (form.theme == "folder") {
                                        setFolderUsers(form.shared_users);
                                        setSelectedFolderId(form.id);
                                      }
                                      shareForm(
                                        data()?.user_id,
                                        data()?.jwt_token,
                                        form.theme == "folder"
                                          ? form.forms.map((f: any) => f.id)
                                          : form.id,
                                        form.theme != "folder"
                                      );
                                      if (form.theme != "folder") {
                                        setSelectedForms([form.id]);
                                      } else {
                                        setSelectedForms(
                                          form.forms.map((f: any) => f.id)
                                        );
                                      }
                                    }}
                                  >
                                    <Icons name="share" width={20}></Icons>
                                  </button>
                                </Popover>
                              </Show>
                              <Popover
                                content={`Rename ${
                                  form.theme == "folder" ? "Folder" : "Form"
                                }`}
                                top={-55}
                                left={form.theme == "folder" ? 92 : 88}
                              >
                                <button
                                  class="btn sm btn-ico"
                                  onclick={() => {
                                    setRenameForm(form.id);
                                    setCurrentFormId("");
                                    const element: any =
                                      document.getElementById("rename_list");
                                    element?.focus();
                                  }}
                                >
                                  <Icons name="inputField" width={20}></Icons>
                                </button>
                              </Popover>
                              <Popover
                                content={`Clone ${
                                  form.theme == "folder" ? "Folder" : "Form"
                                }`}
                                top={-55}
                                left={form.theme == "folder" ? 85 : 80}
                              >
                                <button
                                  class="btn sm btn-ico"
                                  onclick={() => {
                                    cloneFormStructure(
                                      data()?.user_id,
                                      data()?.jwt_token,
                                      form.theme != "folder"
                                        ? form.form_id
                                        : form.id,
                                      form.theme == "folder" ? "folder" : "form"
                                    );
                                  }}
                                >
                                  <Icons name="copy" width={20}></Icons>
                                </button>
                              </Popover>
                              <Popover
                                content={`Remove  ${
                                  form.theme == "folder" ? "Folder" : "Form"
                                }`}
                                top={-55}
                                left={form.theme == "folder" ? 95 : 85}
                              >
                                <button
                                  class="btn sm btn-ico"
                                  onclick={() => {
                                    setSelectedForm(form.id);
                                    setSelectedFormUuid(
                                      form.theme != "folder"
                                        ? form.form_id
                                        : form.id
                                    );
                                    setSelectedFormType(form.theme);
                                    setConfirmDeleteForm(false);
                                    deleteFormStructure(
                                      data()?.user_id,
                                      data()?.jwt_token,
                                      form.theme != "folder"
                                        ? form.form_id
                                        : form.id,
                                      form.theme == "folder" ? "folder" : "form"
                                    );
                                  }}
                                >
                                  <Icons name="trash" width={20}></Icons>
                                </button>
                              </Popover>
                              <Show when={form.theme != "folder"}>
                                <Popover
                                  content="Move To Folder"
                                  top={-55}
                                  left={95}
                                >
                                  <button
                                    class="btn sm btn-ico"
                                    onclick={() => {
                                      setFormMoveId(form.id);
                                      setPopup("foldersList");
                                    }}
                                  >
                                    <Icons
                                      name="folderAttach"
                                      width={20}
                                    ></Icons>
                                  </button>
                                </Popover>
                                <Popover
                                  content={
                                    form.is_pinned ? "Unpin Form" : "pin Form"
                                  }
                                  top={-55}
                                  left={form.is_pinned ? 80 : 70}
                                >
                                  <button
                                    class="btn sm btn-ico"
                                    onclick={() => {
                                      toggleFormPin(
                                        data()?.user_id,
                                        form.form_id,
                                        data()?.jwt_token
                                      );
                                    }}
                                  >
                                    <Icons
                                      name={
                                        form.is_pinned ? "filledPin" : "pin"
                                      }
                                      width={20}
                                    ></Icons>
                                  </button>
                                </Popover>
                                <Show
                                  when={
                                    userRole() == "admin" ||
                                    userRole() == "sub_admin" ||
                                    userRole() == "designer"
                                  }
                                >
                                  <Popover
                                    content="Export Form"
                                    top={-55}
                                    left={95}
                                  >
                                    <button
                                      class="btn sm btn-ico"
                                      onclick={() => exportForm(form.id)}
                                    >
                                      <Icons name="download" width={20}></Icons>
                                    </button>
                                  </Popover>
                                </Show>
                              </Show>
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  }}
                </For>
                <Show when={formsList()?.length == 0}>
                  <tr class="text-center">
                    <td colSpan="4">
                      <small>
                        <b>No Form Data Found !!</b>
                      </small>
                    </td>
                  </tr>
                </Show>
              </tbody>
            </table>
          </Show>
          <Show when={formViewType() == "icons"}>
            <div class="form-container">
              <Show when={isPageLoader()}>
                <For each={Array.from({ length: 18 }, (_, i) => i + 1)}>
                  {() => <div class="skeleton-loading form-icons"></div>}
                </For>
              </Show>
              <For each={formsList()}>
                {(form) => {
                  const combinedAssignees = [
                    ...(Array.isArray(form.assignee) ? form.assignee : []),
                    ...(Array.isArray(form.assigneeGroupNames)
                      ? form.assigneeGroupNames
                      : []),
                  ];
                  const combinedAvatars = [
                    ...(Array.isArray(form.assigneeAvatar)
                      ? form.assigneeAvatar
                      : []),
                    ...(Array.isArray(form.assigneeGroupNames)
                      ? form.assigneeGroupNames
                      : []),
                  ];
                  const uniqueAssignees = new Map();
                  combinedAssignees.forEach((username, index) => {
                    if (!uniqueAssignees.has(username)) {
                      uniqueAssignees.set(username, combinedAvatars[index]);
                    }
                  });
                  const deduplicatedAssignees = [...uniqueAssignees.keys()];
                  const deduplicatedAvatars = [...uniqueAssignees.values()];
                  return (
                    <>
                      <div
                        class={`pr cur-p ${
                          isSwapStart() == form.id ? "opacity-5" : ""
                        } ${isDroppableDiv() == form.id ? "droppable" : ""}`}
                        onclick={(e: any) => {
                          setCurrentformName(form.name);
                          if (
                            (e.shiftKey || selectFormsToShare()) &&
                            form.theme != "folder"
                          ) {
                            if (
                              importRecordFile() &&
                              importRecordFile()?.name
                            ) {
                              setSelectedForms([form.id]);
                            } else {
                              const tempForms: any = [...selectedForms()];
                              const index: number = tempForms.findIndex(
                                (item: any) => item == form.id
                              );
                              if (index == -1) {
                                tempForms.push(form.id);
                              } else {
                                tempForms.splice(index, 1);
                              }
                              setSelectedForms(tempForms);
                            }
                            e.preventDefault();
                          } else {
                            if (
                              !(e.shiftKey || selectFormsToShare()) &&
                              form.theme == "folder" &&
                              e.target.id == form.id
                            ) {
                              setLastOpenedForm(form.id);
                              setSelectedFolderData(form);
                              setPopup("folderForms");
                            }
                          }
                        }}
                        draggable={true}
                        onDragStart={(e: DragEvent) => {
                          if (form.theme == "folder") {
                            e.preventDefault();
                          } else {
                            setIsSwapStart(form.id);
                            if (selectedForms().length > 0) {
                              e.dataTransfer.effectAllowed = "move";
                            } else {
                              e.dataTransfer.effectAllowed = "move";
                              e.dataTransfer.setData("text/plain", form.id);
                            }
                          }
                        }}
                        onDragOver={(e: DragEvent) => {
                          if (
                            formsList().find(
                              (val: any) => val.id === e.target.id
                            )?.theme == "folder"
                          ) {
                            setIsDroppableDiv(e.target.id);
                          } else {
                            setIsDroppableDiv("");
                          }
                          e.dataTransfer.dropEffect = "move";
                          e.preventDefault();
                        }}
                        onDrop={async (e: DragEvent) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setIsSwapStart("");
                          setIsDroppableDiv("");
                          const droppedFormId =
                            e.dataTransfer.getData("text/plain");

                          const draggedForm = formsList().find(
                            (val: any) => val.id === droppedFormId
                          );
                          const targetFormIndex = formsList().findIndex(
                            (val: any) => val.id === form.id
                          );
                          let updatedForms: any = [...formsList()];
                          if (
                            updatedForms[targetFormIndex].theme == "folder" &&
                            !(
                              userRole() != "admin" &&
                              updatedForms[targetFormIndex].shared_users
                                ?.length > 1
                            )
                          ) {
                            attachFormToFolder(
                              data()?.jwt_token,
                              data()?.user_id,
                              selectedForms().length > 0
                                ? selectedForms().join(",")
                                : draggedForm.id,
                              updatedForms[targetFormIndex].id
                            );
                          }
                          setSelectedForms([]);
                        }}
                        onmouseover={() => {
                          setSelectedForm(form.id);
                          setSelectedFormUuid(
                            form.theme != "folder" ? form.form_id : form.id
                          );
                          setSelectedFormType(form.theme);
                        }}
                      >
                        <div class="w-160p po-r">
                          <Show
                            when={
                              !selectFormsToShare()
                              // &&
                              // (userRole() == "admin" ||
                              //   userRole() == "sub_admin" ||
                              //   userRole() == "designer" ||
                              //   form.theme == "folder")
                            }
                          >
                            <button
                              id={`formDropDown_${form.id}`}
                              class="non-btn pa t--22 r-0 cur-p"
                              onclick={(e) => {
                                e.preventDefault();
                                setSelectedDropDown(form.id);
                              }}
                            >
                              <Icons name="infoDots" stroke="#6c6e6f"></Icons>
                            </button>
                          </Show>
                          <Show when={form.is_pinned}>
                            <div class="pinned-form">
                              <Icons name="filledPin"></Icons>
                            </div>
                          </Show>
                          <Show when={form.theme != "folder"}>
                            <div
                              class={`form-assignee form-users__group ${
                                form.assignee.length > 3 ? "has-multiple" : ""
                              }`}
                              onclick={(e: any) => {
                                const element: any = document.getElementById(
                                  `${form.id}_more_btn`
                                );
                                if (!element?.contains(e.target)) {
                                  setLastOpenedForm(form.id);
                                  navigate(`/form/render/${form.id}`);
                                }
                              }}
                            >
                              <For each={deduplicatedAssignees}>
                                {(assignee: any, key: any) => (
                                  <>
                                    <Show when={key() < 3}>
                                      <div
                                        class="form-user"
                                        style={{
                                          "--z-index":
                                            deduplicatedAssignees?.length -
                                            key(),
                                          "--background-color":
                                            deduplicatedAvatars[key()] != "" &&
                                            deduplicatedAvatars[key()].includes(
                                              "http"
                                            )
                                              ? "transparant"
                                              : userIcons().find(
                                                  (user: any) =>
                                                    user.name == assignee
                                                )?.theme
                                              ? userIcons().find(
                                                  (user: any) =>
                                                    user.name == assignee
                                                )?.theme
                                              : getRandomColorWithProperContrast(
                                                  "#ffffff"
                                                ),
                                          border:
                                            deduplicatedAvatars[key()] != "" &&
                                            deduplicatedAvatars[key()].includes(
                                              "http"
                                            )
                                              ? "none"
                                              : userIcons().find(
                                                  (user: any) =>
                                                    user.name == assignee
                                                )?.theme
                                              ? "none"
                                              : "2px solid",
                                        }}
                                        data-title={assignee}
                                      >
                                        <Show
                                          when={
                                            deduplicatedAvatars[key()] != "" &&
                                            deduplicatedAvatars[key()].includes(
                                              "http"
                                            )
                                          }
                                        >
                                          <img
                                            src={deduplicatedAvatars[key()]}
                                          ></img>
                                        </Show>
                                        <Show
                                          when={
                                            deduplicatedAvatars[key()] == "" ||
                                            !deduplicatedAvatars[
                                              key()
                                            ].includes("http")
                                          }
                                        >
                                          {userIcons().find(
                                            (user: any) => user.name == assignee
                                          )?.logo
                                            ? userIcons().find(
                                                (user: any) =>
                                                  user.name == assignee
                                              )?.logo
                                            : getSignatureInitials(assignee)}
                                        </Show>
                                      </div>
                                    </Show>
                                    <Show
                                      when={
                                        deduplicatedAssignees?.length > 3 &&
                                        deduplicatedAssignees?.length - 1 ==
                                          key()
                                      }
                                    >
                                      <div
                                        id={form.id + "_more_btn"}
                                        class="form-user"
                                        style={{
                                          "--z-index":
                                            deduplicatedAssignees?.length -
                                            key(),
                                          "--background-color": "#000",
                                        }}
                                        data-title="more"
                                        onclick={() => {
                                          setAssigneeList({
                                            assignee: form.assignee,
                                            assigneeTeams:
                                              form.assigneeGroupNames,
                                          });
                                          setAssigneeAvatarsList({
                                            assigneeAvatar: form.assigneeAvatar,
                                          });
                                          setPopup("assigneeList");
                                        }}
                                      >
                                        <Icons
                                          name="plus"
                                          stroke="#ffffff"
                                        ></Icons>
                                      </div>
                                    </Show>
                                  </>
                                )}
                              </For>
                            </div>
                          </Show>
                          <Show when={form.totalRecords}>
                            <div class="records-count">{form.totalRecords}</div>
                          </Show>
                        </div>
                        <Show when={selectedDropDown() == form.id}>
                          <Dropdown
                            list={
                              form.theme == "folder"
                                ? userRole() == "admin"
                                  ? // (userRole() == "admin" ||
                                    //     userRole() == "sub_admin") &&
                                    //   !(
                                    //     userRole() != "admin" &&
                                    //     form.shared_users?.length > 1
                                    //   )
                                    [
                                      {
                                        label: "Share Folder",
                                        icon: "share",
                                        value: "share_folder",
                                      },
                                      ...folderEvents,
                                    ]
                                  : [...folderEvents]
                                : form.is_pinned
                                ? [
                                    ...(userRole() == "designer"
                                      ? formEvents.filter(
                                          (item: any) =>
                                            item.value != "share_form"
                                        )
                                      : userRole() == "filler" ||
                                        userRole() == "viewer"
                                      ? []
                                      : formEvents),
                                    {
                                      label: "Move To Folder",
                                      icon: "folderAttach",
                                      value: "move_to_folder",
                                    },
                                    {
                                      label: "Unpin Form",
                                      icon: "filledPin",
                                      value: "pin_form",
                                    },
                                    {
                                      label: "Export Form",
                                      icon: "download",
                                      value: "export_form",
                                    },
                                  ]
                                : [
                                    ...(userRole() == "designer"
                                      ? formEvents.filter(
                                          (item: any) =>
                                            item.value != "share_form"
                                        )
                                      : userRole() == "filler" ||
                                        userRole() == "viewer"
                                      ? []
                                      : formEvents),
                                    {
                                      label: "Move To Folder",
                                      icon: "folderAttach",
                                      value: "move_to_folder",
                                    },
                                    {
                                      label: "Pin Form",
                                      icon: "pin",
                                      value: "pin_form",
                                    },
                                    {
                                      label: "Export Form",
                                      icon: "download",
                                      value: "export_form",
                                    },
                                  ]
                            }
                            title="label"
                            form={true}
                            setValue={(val: string) => {
                              switch (val) {
                                case "shared_forms":
                                  setAssigneeList(form.assignee);
                                  setAssigneeAvatarsList(form.assigneeAvatar);
                                  setPopup("assigneeList");
                                  break;
                                case "rename_form_folder":
                                  setRenameForm(form.id);
                                  const element: any =
                                    document.getElementById("rename_icons");
                                  element?.focus();
                                  adjustFormFolderNames();
                                  break;
                                case "form_builder":
                                  navigate(`/form/builder/${form.id}`);
                                  break;
                                case "share_form":
                                  setSelectedForms([form.id]);
                                  shareForm(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    form.id,
                                    true
                                  );
                                  break;
                                case "share_folder":
                                  if (
                                    form.theme == "folder" &&
                                    form.forms?.length == 0
                                  ) {
                                    notify({
                                      isNotified: true,
                                      message: messages.form.no_folder_forms,
                                      theme: "warm",
                                      placement: "top",
                                      timeout: 2500,
                                    });
                                    return;
                                  }
                                  setFolderUsers(form.shared_users);
                                  setSelectedFolderId(form.id);
                                  setSelectedForms(
                                    form.forms.map((f: any) => f.id)
                                  );
                                  shareForm(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    form.forms.map((f: any) => f.id),
                                    false
                                  );
                                  break;
                                case "clone_form_folder":
                                  cloneFormStructure(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    form.theme != "folder"
                                      ? form.form_id
                                      : form.id,
                                    form.theme == "folder" ? "folder" : "form"
                                  );
                                  break;
                                case "form_settings":
                                  formSettings(
                                    data()?.user_id,
                                    form.id,
                                    data()?.jwt_token
                                  );
                                  setSelectedFormId(form.id);
                                  break;
                                case "field_linker":
                                  formFieldLinker(
                                    data()?.user_id,
                                    form.id,
                                    data()?.jwt_token
                                  );
                                  setSelectedFormId(form.id);
                                  break;
                                case "remove_form_folder":
                                  setConfirmDeleteForm(false);
                                  deleteFormStructure(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    form.theme != "folder"
                                      ? form.form_id
                                      : form.id,
                                    form.theme == "folder" ? "folder" : "form"
                                  );
                                  break;
                                case "move_to_folder":
                                  setFormMoveId(form.id);
                                  setPopup("foldersList");
                                  break;
                                case "pin_form":
                                  toggleFormPin(
                                    data()?.user_id,
                                    form.form_id,
                                    data()?.jwt_token
                                  );
                                  break;
                                case "export_form":
                                  exportForm(form.id);
                                  break;
                                default:
                                  break;
                              }
                            }}
                            class="r-10"
                          ></Dropdown>
                        </Show>
                        <div
                          onclick={(e: any) => {
                            setTotalRecordsCount(form.totalRecords);
                            setTotalRecordsShowCount(form.totalRecords);
                            if (form.theme != "folder") {
                              !e.shiftKey &&
                              !selectFormsToShare() &&
                              renameForm() != form.id
                                ? (setLastOpenedForm(form.id),
                                  navigate(`/form/render/${form.id}`))
                                : "";
                            } else {
                              if (renameForm() != form.id) {
                                setLastOpenedForm(form.id);
                                setSelectedFolderData(form);
                                setPopup("folderForms");
                              }
                            }
                          }}
                          draggable={false}
                        >
                          <div
                            class={`pa flex app-flex align-center justify-center w-160p h-100p form-actions ${
                              selectedForms().includes(form.id) ? "active" : ""
                            }`}
                            id={"icons_form_" + form.id}
                          >
                            <Show when={renameForm() == form.id}>
                              <div class="list-options active">
                                <button
                                  class="list-option__btn"
                                  onclick={(e: any) => {
                                    e.preventDefault();
                                    formRenameValue() != ""
                                      ? renameFormStructureName(
                                          data()?.user_id,
                                          data()?.jwt_token,
                                          form.theme != "folder"
                                            ? form.form_id
                                            : form.id,
                                          formRenameValue(),
                                          form.theme == "folder"
                                            ? "folder"
                                            : "form"
                                        )
                                      : "";
                                  }}
                                >
                                  <Icons name="check" stroke="#ffffff" />
                                </button>
                                <button
                                  class="list-option__btn"
                                  onclick={(e: any) => {
                                    e.preventDefault();
                                    setRenameForm("");
                                    adjustFormFolderNames();
                                  }}
                                >
                                  <Icons name="x" stroke="#ffffff" />
                                </button>
                              </div>
                            </Show>
                            <Show when={renameForm() != form.id}>
                              <label
                                class={`form-list-label ${form.theme}`}
                                id={form.id}
                                style={{
                                  "font-size":
                                    form.name?.length > 120
                                      ? "0.7rem"
                                      : form.name?.length < 120 &&
                                        form.name?.length > 60
                                      ? "0.8rem"
                                      : form.name?.length < 60 &&
                                        form.name?.length > 30
                                      ? "0.9rem"
                                      : "1rem",
                                }}
                              >
                                {form.name?.slice(0, 200)}{" "}
                                {form.name?.length > 200 ? "..." : ""}
                              </label>
                            </Show>
                            <Show when={renameForm() == form.id}>
                              <input
                                id="rename_icons"
                                type="text"
                                class="input w-68"
                                onclick={(e: any) => e.preventDefault()}
                                onblur={(e: any) => {
                                  e.preventDefault();
                                  setFormRenameValue(e.target.value);
                                }}
                                value={form.name}
                                autofocus={true}
                              />
                            </Show>
                            <span class="selected">
                              <Icons name="checkmark" stroke="#fff"></Icons>
                            </span>
                          </div>
                          <img
                            src={`/form_themes/${form.theme}.png`}
                            class="form-list-image"
                          />
                          <Show
                            when={
                              userRole() != "admin" &&
                              form.theme == "folder" &&
                              form.shared_users?.length > 1
                            }
                          >
                            <span class="shared-folder grid">
                              <Icons name="users"></Icons>
                            </span>
                          </Show>
                        </div>
                      </div>
                    </>
                  );
                }}
              </For>
            </div>
          </Show>
        </Show>
        <Show
          when={
            formViewType() == "icons" &&
            formsList()?.length == 0 &&
            totalForms() == 0
          }
        >
          <br />
          <div class="text-center">
            <img src="/presentation.png" class="w-200"></img>
            <h3>
              {totalForms() > 0
                ? "NO FORMS FOUND"
                : "YOU HAVEN'T CREATED ANY FORMS YET"}
            </h3>
            <Show when={totalForms() == 0}>
              <div class="lbl">
                {userRole() == "filler" || userRole() == "viewer"
                  ? "Forms shared with you will appear here."
                  : "Your forms will appear here."}
              </div>
            </Show>
          </div>
          <Show when={userRole() != "filler" && userRole() != "viewer"}>
            <br />
            <br />
            <div class="flex gap flex-column align-center">
              <button
                class="btn primary"
                onclick={() => {
                  setIsNewFolder(false);
                  setPopup("formFolderAdd");
                }}
              >
                Create Form
              </button>
              <div class="flex gap-0_5 space-around">
                <div class="border-middle"></div>
                <label class="lbl">OR</label>
                <div class="border-middle"></div>
              </div>
              <A href="/form/library" class="btn primary">
                Retrieve From Template Library
              </A>
            </div>
          </Show>
        </Show>
      </SystemLayout>
    </main>
  );
};

export default index;
