import { Component, createEffect, createSignal, Show } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import LibraryTemplates from "~/components/global/LibraryTemplates";
import SystemLayout from "~/components/layouts/system.layout";
import FormFolderAdd from "~/components/popup/FormFolderAddPopup";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { setPageTitle } from "~/root";
import {
  adjustFormFolderNames,
  formTemplateCategories,
  isNewFolder,
  popupQueue,
  removePopup,
  selectedDropDown,
  selectedDropDownValue,
  setSelectedDropDown,
  setSelectedDropDownValue,
} from "~/store/global.store";
import { validateUser } from "../api/validateUser";
import FormPreview from "~/components/popup/FormPreview";
import Icons from "~/components/icons/Icons";
import Dropdown from "~/components/global/Dropdown";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;

    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/library/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
      },
    };
    let libraryForms = await fetchApi(apiParams);
    if (libraryForms.statusCode == 401) {
      throw redirect("/logout");
    }
    libraryForms = libraryForms?.data;
    return { user_id, jwt_token, libraryForms };
  });
}

const library: Component = () => {
  setPageTitle(`${SITE_TITLE} - Create New Form`);
  const data = useRouteData<typeof routeData>();

  const [libraryForms, setLibraryForms] = createSignal(
    data()?.libraryForms as any
  );

  createEffect(() => {
    setTimeout(() => {
      adjustFormFolderNames();
    }, 0);
  });

  createEffect(() => {
    if (data()?.libraryForms) {
      setLibraryForms(data()?.libraryForms);
    } else {
      location.reload();
    }
  });

  async function updateList(user_id: string, jwt_token: string) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/library/list`,
      jwt_token,
      body: {
        user_id,
        category: selectedDropDownValue(),
      },
    };
    let libraryForms = await fetchApi(apiParams);
    if (libraryForms?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (libraryForms?.statusCode == 403) {
      location.reload();
    }
    libraryForms = libraryForms.data;
    setLibraryForms(libraryForms);
    adjustFormFolderNames();
  }

  return (
    <main>
      <Show when={popupQueue.includes("formFolderAdd")}>
        <FormFolderAdd
          isFolder={isNewFolder()}
          setValue={(val: boolean) => {
            if (val) {
              removePopup("formFolderAdd");
              document.getElementById("add_form_btn")?.click();
            }
          }}
        ></FormFolderAdd>
      </Show>
      <Show when={popupQueue.includes("FormPreview")}>
        <FormPreview
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></FormPreview>
      </Show>
      <SystemLayout>
        <div class="flex space-between">
          <h2 class="title color">Sample Templates</h2>
          <div class="pr w-250">
            <div
              id="form_template"
              class="user-role-dropdown cur-p br-unset"
              onclick={() =>
                selectedDropDown() == ""
                  ? setSelectedDropDown("form_template")
                  : setSelectedDropDown("")
              }
            >
              <span class="dropdown-div lbl" id="form_template">
                {selectedDropDownValue() ? selectedDropDownValue() : "ALL"}
              </span>
              <Icons name="chevronDown" stroke="#6c6e6f"></Icons>
            </div>
            <Show when={selectedDropDown() == "form_template"}>
              <Dropdown
                list={[{ label: "ALL", value: "" }, ...formTemplateCategories]}
                title="label"
                link="link"
                class="w-100 t-34"
                selectedValue={selectedDropDownValue()}
                isFormDropdown={true}
                setValue={(val: any) => {
                  setSelectedDropDownValue(val);
                  updateList(data()?.user_id, data()?.jwt_token);
                }}
              />
            </Show>
          </div>
        </div>

        <Show when={libraryForms()?.length > 0}>
          <LibraryTemplates
            user_id={data()?.user_id}
            jwt_token={data()?.jwt_token}
            libraryForms={libraryForms()}
          ></LibraryTemplates>
        </Show>
        <Show when={libraryForms()?.length == 0}>
          <div class="h-150p flex gap align-center justify-center">
            <span class="lbl">No Templates Found !!</span>
          </div>
        </Show>
        <input type="hidden" value={data()?.user_id} />
      </SystemLayout>
    </main>
  );
};

export default library;
