import {
  Component,
  Show,
  createEffect,
  createSignal,
  onCleanup,
} from "solid-js";
import { createStore } from "solid-js/store";
import { assignFieldProperties } from "~/helpers/form.helper";
import generateCSS from "~/helpers/generator";
import {
  addContectMenu,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  activeField,
  isMouseDown,
  multipleSelectField,
  setActiveField,
  setAlertMessage,
  setIsMouseDown,
  setPopup,
} from "~/store/global.store";
import { Loader } from "@googlemaps/js-api-loader";
import convert from "~/helpers/convertor";
import Icons from "../icons/Icons";
import {
  additionalData,
  preLoadData,
  recordsJson,
  setAdditionalData,
  updateFormRecords,
} from "~/store/records.store";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
import { FRAME_WIDTH } from "~/helpers/constant";

interface Props {
  properties: any;
  fieldsType: string;
}

const [isLocationUpdated, setIsLocationUpdated] = createSignal(
  false as boolean
);

const LocationField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [map, setMap] = createSignal(null as any);
  const [currentLocationMarker, setCurrentLocationMarker] = createSignal(
    null as any
  );
  const [selectedLocationMarker, setSelectedLocationMarker] = createSignal(
    null as any
  );
  const [markers, setMarkers] = createSignal([] as any);
  const [selectedLocation, setSelectedLocation] = createSignal({
    lat: 0,
    lng: 0,
  });
  const [currentLocation, setCurrentLocation] = createSignal({
    lat: 0,
    lng: 0,
  });
  const [GPSCoordinates, setGPSCoordinates] = createSignal("" as string);

  createEffect(() => {
    if (recordsJson.currentRecord && props.fieldsType != "preview") {
      setGPSCoordinates("");
      const loader: any = new Loader({
        apiKey: import.meta.env.VITE_GOOGLE_PLACE_API_KEY,
        version: "weekly",
        libraries: ["places"],
      });

      loader.load().then(() => {
        // Initialize Map
        const mapContainer = document.getElementById(
          `locationContainer_${props.properties.dataFieldSync.uid}`
        ) as HTMLElement;
        const mapInstance = new google.maps.Map(mapContainer, {
          center: { lat: 33.5283, lng: -117.7052 },
          zoom: 12,
          gestureHandling: props.fieldsType == "render" ? "auto" : "none",
          keyboardShortcuts: props.fieldsType == "render" ? true : false,
          fullscreenControl: props.fieldsType === "render",
        });
        setMap(mapInstance);

        const input = document.getElementById(
          `locationSearch_${props.properties.dataFieldSync?.uid}`
        ) as HTMLInputElement;
        const searchBox = new google.maps.places.SearchBox(input);

        // Select Location
        if (props.fieldsType == "render") {
          mapInstance.addListener("click", (event: any) => {
            setIsLocationUpdated(true);
            if (selectedLocationMarker()) {
              selectedLocationMarker().setMap(null);
              setSelectedLocationMarker(null);
            }
            const clickedLatLng = {
              lat: event.latLng.lat(),
              lng: event.latLng.lng(),
            };
            setSelectedLocationMarker(
              new google.maps.Marker({
                position: clickedLatLng,
                map: mapInstance,
              })
            );
            setSelectedLocation(clickedLatLng);
            if (currentLocationMarker()) {
              currentLocationMarker().setMap(null);
              setCurrentLocationMarker(null);
            }
            const selectedLat = selectedLocation().lat
              ? selectedLocation().lat
              : currentLocation().lat;
            const selectedLng = selectedLocation().lng
              ? selectedLocation().lng
              : currentLocation().lng;

            let result: string = "";
            if (props.properties.locationFormat === "decimal") {
              result = `${selectedLat.toFixed(7)} ${selectedLng.toFixed(7)}`;
            } else if (props.properties.locationFormat === "deg_min_sec") {
              result = `${decimalToDegMinSec(
                Math.abs(selectedLat),
                selectedLat >= 0 ? "N" : "S"
              )} ${decimalToDegMinSec(
                Math.abs(selectedLng),
                selectedLng >= 0 ? "E" : "W"
              )}`;
            } else if (props.properties.locationFormat === "decimal_min") {
              result = `${decimalToDecimalMin(
                Math.abs(selectedLat),
                selectedLat >= 0 ? "N" : "S"
              )} ${decimalToDecimalMin(
                Math.abs(selectedLng),
                selectedLng >= 0 ? "E" : "W"
              )}`;
            }
            const value: any = {
              display_value: result,
            };
            const dataArr: any = { ...additionalData() };
            dataArr[props.properties.dataFieldSync.uid] = value;
            setAdditionalData(dataArr);
            updateFormRecords(
              props.properties.dataFieldSync.uid,
              `${selectedLat.toFixed(7)} ${selectedLng.toFixed(7)}`,
              false,
              false,
              value
            );
          });
        }

        // For each place, get the icon, name and location.
        searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();
          if (places.length === 0) {
            return;
          }

          // Clear out the old markers.
          currentLocationMarker() && currentLocationMarker().setMap(null);
          selectedLocationMarker() && selectedLocationMarker().setMap(null);
          setCurrentLocationMarker(null);
          setSelectedLocationMarker(null);
          markers().forEach((marker: any) => {
            marker.setMap(null);
          });
          setMarkers([]);

          const bounds = new google.maps.LatLngBounds();
          const tempArr: any = [];
          places.forEach((place: any) => {
            if (!place.geometry || !place.geometry.location) {
              return;
            }
            const icon = {
              url: place.icon as string,
              size: new google.maps.Size(71, 71),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(17, 34),
              scaledSize: new google.maps.Size(25, 25),
            };
            const marker = new google.maps.Marker({
              map: mapInstance,
              icon,
              title: place.name,
              position: place.geometry.location,
            });
            tempArr.push(marker);
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport);
            } else {
              bounds.extend(place.geometry.location);
            }
          });
          setMarkers(tempArr);
          mapInstance.fitBounds(bounds);
        });

        // Clean up when the component unmounts
        onCleanup(() => {
          mapInstance.dispose();
        });
      });
    }
  });

  const repositioningSearch = (
    el: HTMLElement,
    data: { frame: (string | number)[][] }
  ) => {
    const left = Number(convert(+data.frame[0][0], "in-px")) - 21;
    const top =
      Number(convert(+data.frame[0][1], "in-px")) +
      Number(convert(+data.frame[1][1], "in-px")) -
      Number(
        convert(
          Math.round(
            (props.properties.dataFieldSync.fontSize / 72) * 4 + 0.49
          ) /
            4 +
            0.125,
          "in-px"
        )
      );
    const width = convert(+data.frame[1][0], "in-px");
    const height = convert(
      Math.round((props.properties.dataFieldSync.fontSize / 72) * 4 + 0.49) /
        4 +
        0.125,
      "in-px"
    );
    setTimeout(() => {
      el.style.left = `${left}px`;
      el.style.top = `${top}px`;
      el.style.setProperty("width", `${width}px`, "important");
      el.style.height = `${height}px`;
    });
  };

  const decimalToDegMinSec = (decimal: any, direction: any) => {
    const degrees = Math.floor(decimal);
    const minutes = Math.floor((decimal - degrees) * 60);
    const seconds = ((decimal - degrees - minutes / 60) * 3600).toFixed(2);
    return `${
      (direction == "S" || direction == "W" ? -1 : 1) * Number(degrees)
    }° ${minutes}' ${seconds}"`;
  };

  // Convert decimal degrees to degrees, decimal minutes format
  const decimalToDecimalMin = (decimal: any, direction: any) => {
    const degrees = Math.floor(decimal);
    const minutes = ((decimal - degrees) * 60).toFixed(3);
    return `${direction}${degrees} ${minutes}`;
  };

  const degMinSecToDecimal = (degrees: any, minutes: any, seconds: any) => {
    let decimal = Math.abs(degrees) + minutes / 60 + seconds / 3600;
    if (degrees < 0) {
      decimal = -decimal;
    }
    return decimal;
  };

  const degMinToDecimal = (degrees: any, minutes: any, direction: any) => {
    let decimal = Math.abs(degrees) + minutes / 60;
    if (direction === "S" || direction === "W") {
      decimal = -decimal;
    }
    return decimal;
  };

  function updateLocationManually(e: any) {
    if (selectedLocationMarker()) {
      selectedLocationMarker().setMap(null);
      setSelectedLocationMarker(null);
    }
    setGPSCoordinates(e.target.value);
    let result: any;
    if (props.properties.locationFormat === "decimal") {
      result = e.target.value;
    } else if (props.properties.locationFormat === "deg_min_sec") {
      const coordinates = e.target.value
        .replace(/([NSEW])(\d+(\.\d+)?)/g, "$1 $2")
        .split(" ");
      if (coordinates.length > 6) {
        result = `${degMinSecToDecimal(
          Number(coordinates[0]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[1]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[2]?.match(/-?[0-9.]+/)[0])
        ).toFixed(7)} ${degMinSecToDecimal(
          Number(coordinates[4]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[5]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[6]?.match(/-?[0-9.]+/)[0])
        ).toFixed(7)}`;
      } else {
        result = `${degMinSecToDecimal(
          Number(coordinates[0]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[1]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[2]?.match(/-?[0-9.]+/)[0])
        ).toFixed(7)} ${degMinSecToDecimal(
          Number(coordinates[3]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[4]?.match(/-?[0-9.]+/)[0]),
          Number(coordinates[5]?.match(/-?[0-9.]+/)[0])
        ).toFixed(7)}`;
      }
    } else if (props.properties.locationFormat === "decimal_min") {
      const coordinates = e.target.value
        .replace(/([NSEW])(\d+(\.\d+)?)/g, "$1 $2")
        .split(" ");
      result = `${degMinToDecimal(
        Number(coordinates[1]),
        Number(coordinates[2]?.match(/[0-9.]+/)[0]),
        coordinates[0]
      )} ${degMinToDecimal(
        Number(coordinates[4]),
        Number(coordinates[5]?.match(/[0-9.]+/)[0]),
        coordinates[3]
      )}`;
    }
    const value: any = {
      display_value: e.target.value,
    };
    const dataArr: any = { ...additionalData() };
    dataArr[props.properties.dataFieldSync.uid] = value;
    setAdditionalData(dataArr);
    updateFormRecords(
      props.properties.dataFieldSync.uid,
      result,
      false,
      false,
      value
    );
  }

  function physicalAddress(lat: any, lng: any) {
    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${
      import.meta.env.VITE_GOOGLE_PLACE_API_KEY
    }`;
    fetch(geocodeUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK" && data.results.length > 0) {
          const address = data.results[0].formatted_address;
          setGPSCoordinates(address);
          const value: any = {
            display_value: address,
          };
          const dataArr: any = { ...additionalData() };
          dataArr[props.properties.dataFieldSync.uid] = value;
          setAdditionalData(dataArr);
          lat = Number(lat).toFixed(7);
          lng = Number(lng).toFixed(7);
          updateFormRecords(
            props.properties.dataFieldSync.uid,
            `${lat} ${lng}`,
            false,
            false,
            value
          );
        } else {
          console.error("Failed to translate coordinates to address.");
        }
      })
      .catch((error) => {});
  }

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
    if (props.properties?.dataFieldSync.frame) {
      if (
        document.getElementById(
          `${props.properties?.dataFieldSync.uid}_location`
        )
      ) {
        repositioningSearch(
          document.getElementById(
            `${props.properties?.dataFieldSync.uid}_location`
          ),
          props.properties?.dataFieldSync
        );
      }
    }
    if (preLoadData()[props.properties.dataFieldSync.uid]) {
      // Calculate selected location values
      const selectedLat = selectedLocation().lat
        ? selectedLocation().lat
        : currentLocation().lat;
      const selectedLng = selectedLocation().lng
        ? selectedLocation().lng
        : currentLocation().lng;

      // Convert selected location to different formats
      const selectedDecimal = `${selectedLat.toFixed(7)} ${selectedLng.toFixed(
        7
      )}`;
      const selectedDegMinSec = `${decimalToDegMinSec(
        Math.abs(selectedLat),
        selectedLat >= 0 ? "N" : "S"
      )} ${decimalToDegMinSec(
        Math.abs(selectedLng),
        selectedLng >= 0 ? "E" : "W"
      )}`;
      const selectedDecimalMin = `${decimalToDecimalMin(
        Math.abs(selectedLat),
        selectedLat >= 0 ? "N" : "S"
      )} ${decimalToDecimalMin(
        Math.abs(selectedLng),
        selectedLng >= 0 ? "E" : "W"
      )}`;

      // Display formatted values based on selected GPS unite
      if (
        props.fieldsType == "render" &&
        preLoadData()[props.properties.dataFieldSync.uid] &&
        !isLocationUpdated()
      ) {
        if (currentLocationMarker()) {
          currentLocationMarker().setMap(null);
          setCurrentLocationMarker(null);
        }
        const coordinates = preLoadData()
          [props.properties.dataFieldSync.uid].replace(
            /([NSEW])(\d+(\.\d+)?)/g,
            "$1 $2"
          )
          .split(" ");
        if (coordinates.length > 1) {
          let lat: any, lng: any;
          lat = parseFloat(coordinates[0].replace(",", ".")).toFixed(7);
          lng = parseFloat(coordinates[1].replace(",", ".")).toFixed(7);
          if (!isNaN(lat) && !isNaN(lng)) {
            let result: string = "";
            if (props.properties.locationFormat === "decimal") {
              result = `${lat} ${lng}`;
            } else if (props.properties.locationFormat === "deg_min_sec") {
              result = `${decimalToDegMinSec(
                Math.abs(lat),
                lat >= 0 ? "N" : "S"
              )} ${decimalToDegMinSec(Math.abs(lng), lng >= 0 ? "E" : "W")}`;
            } else if (props.properties.locationFormat === "decimal_min") {
              result = `${decimalToDecimalMin(
                Math.abs(lat),
                lat >= 0 ? "N" : "S"
              )} ${decimalToDecimalMin(Math.abs(lng), lng >= 0 ? "E" : "W")}`;
            }
            setGPSCoordinates(result);
            if (map()) {
              const newLatLng = new google.maps.LatLng(lat, lng);
              const newMarker = new google.maps.Marker({
                position: newLatLng,
                map: map(),
              });
              setSelectedLocationMarker(newMarker);
              map().setCenter(newLatLng);
            }
          }
        }
      } else {
        if (props.properties.locationFormat === "decimal") {
          setGPSCoordinates(selectedDecimal);
        } else if (props.properties.locationFormat === "deg_min_sec") {
          setGPSCoordinates(selectedDegMinSec);
        } else if (props.properties.locationFormat === "decimal_min") {
          setGPSCoordinates(selectedDecimalMin);
        }
      }
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  const updateCurrentLocation = () => {
    if (selectedLocationMarker()) {
      selectedLocationMarker().setMap(null);
      setSelectedLocationMarker(null);
    }
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLatLng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setSelectedLocation(userLatLng);
          const selectedLat = selectedLocation().lat
            ? selectedLocation().lat
            : currentLocation().lat;
          const selectedLng = selectedLocation().lng
            ? selectedLocation().lng
            : currentLocation().lng;

          let result: string = "";
          if (props.properties.locationFormat === "decimal") {
            result = `${selectedLat.toFixed(7)} ${selectedLng.toFixed(7)}`;
          } else if (props.properties.locationFormat === "deg_min_sec") {
            result = `${decimalToDegMinSec(
              Math.abs(selectedLat),
              selectedLat >= 0 ? "N" : "S"
            )} ${decimalToDegMinSec(
              Math.abs(selectedLng),
              selectedLng >= 0 ? "E" : "W"
            )}`;
          } else if (props.properties.locationFormat === "decimal_min") {
            result = `${decimalToDecimalMin(
              Math.abs(selectedLat),
              selectedLat >= 0 ? "N" : "S"
            )} ${decimalToDecimalMin(
              Math.abs(selectedLng),
              selectedLng >= 0 ? "E" : "W"
            )}`;
          }
          const value: any = {
            display_value: result,
          };
          const dataArr: any = { ...additionalData() };
          dataArr[props.properties.dataFieldSync.uid] = value;
          setAdditionalData(dataArr);
          updateFormRecords(
            props.properties.dataFieldSync.uid,
            `${selectedLat.toFixed(7)} ${selectedLng.toFixed(7)}`,
            false,
            false,
            value
          );
        },
        (error) => {
          console.error("Error getting user's location:", error);
        }
      );
    } else {
      console.warn("Geolocation is not available in this browser.");
    }
  };

  return (
    <>
      <div
        class={`location-container ${
          props.fieldsType == "designer" ? "builder-field-border" : ""
        } ${
          props.fieldsType == "preview" || props.fieldsType == "locked"
            ? "pointer-none"
            : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields()?.includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        } ${props.properties?.dataFieldSync?.frame[1][1] > 0.375 ? "" : "d-n"}`}
        style={generateCSS(fieldProperties)}
        id={`locationContainer_${props.properties.dataFieldSync.uid}`}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(e: any) => {
          if (props.fieldsType != "render") {
            e.preventDefault();
            multipleSelectField() || e.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
          }
        }}
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      ></div>
      <div
        id={`${props.properties?.dataFieldSync?.uid}_location`}
        class={`pa app-flex flex gap-0_5 align-center ${
          props.fieldsType == "designer" ? "builder-field-border" : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        }`}
        ref={(el) => repositioningSearch(el, props.properties?.dataFieldSync)}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(e: any) => {
          if (props.fieldsType != "render") {
            e.preventDefault();
            multipleSelectField() || e.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
          }
        }}
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      >
        <div class="w-90 pr app-flex flex align-center h-100p">
          <input
            type="text"
            id={`locationSearch_${props.properties.dataFieldSync?.uid}`}
            class={`controls input box location-search ${
              props.fieldsType == "preview" || props.fieldsType == "locked"
                ? "pointer-none"
                : ""
            }`}
            style={{
              "padding-right": "30px !important",
              "font-size":
                (Math.round(
                  (Number(props.properties.dataFieldSync.fontSize) / 72) *
                    84 *
                    4
                ) /
                  4 /
                  84) *
                  96 +
                "px",
            }}
            placeholder=""
            value={
              props.fieldsType == "render"
                ? additionalData()[props.properties.dataFieldSync.uid]
                    ?.display_value ?? ""
                : ""
            }
            onclick={() =>
              props.fieldsType == "render"
                ? setActiveField(
                    `location_${props.properties.dataFieldSync.uid}`
                  )
                : ""
            }
            onkeyup={(e: any) => {
              if (props.fieldsType == "render" && e.key == "Enter") {
                updateLocationManually(e);
              }
            }}
            onblur={(e: any) => {
              if (props.fieldsType == "render") {
                updateLocationManually(e);
              }
            }}
            readonly={props.fieldsType != "render" ? true : false}
          />
          <Show
            when={
              props.fieldsType != "preview" &&
              activeField() ==
                `location_${props.properties.dataFieldSync.uid}` &&
              GPSCoordinates() != ""
            }
          >
            <span
              class="pa z-index-1 cur-p r-5"
              onclick={() => {
                const locationSearchInput = document.getElementById(
                  `locationSearch_${props.properties.dataFieldSync?.uid}`
                ) as HTMLInputElement;
                locationSearchInput.value = "";
                setGPSCoordinates("");
                updateFormRecords(props.properties.dataFieldSync.uid, "");
              }}
            >
              <Icons name="circleX" width={20} height={20}></Icons>
            </span>
          </Show>
        </div>
        <div
          class={`cur-p ${
            props.fieldsType == "preview" || props.fieldsType == "locked"
              ? "pointer-none"
              : ""
          }`}
          onclick={() => {
            if (props.fieldsType == "render") {
              if ("geolocation" in navigator) {
                const isSafari: boolean =
                  /safari/i.test(navigator.userAgent) &&
                  !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(
                    navigator.userAgent
                  );
                console.log("isSafari :", isSafari);
                navigator.permissions
                  .query({ name: "geolocation" })
                  .then((result: any) => {
                    console.log("result :", result.state);
                    const state =
                      result.state == "prompt" && isSafari
                        ? "denied"
                        : result.state;
                    switch (state) {
                      case "granted":
                      case "prompt":
                        updateCurrentLocation();
                        break;
                      default:
                        setAlertMessage(
                          `<b>Location access is denied. Please enable location permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
                            isSafari
                              ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Location &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                              : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Location &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                          }</span>`
                        );
                        setPopup("alert");
                        break;
                    }
                    result.onchange = () => {
                      if (state === "granted" || state === "prompt") {
                        updateCurrentLocation();
                      } else if (state === "denied") {
                        setAlertMessage(
                          `<b>Location access is denied. Please enable location permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
                            isSafari
                              ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Location &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                              : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Location &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                          }</span>`
                        );
                        setPopup("alert");
                      }
                    };
                  });
              }
            }
          }}
        >
          <span
            data-title="Current Location"
            class={`notification-badge gps ${
              Number(props.properties.dataFieldSync.frame[0][0]) +
                Number(props.properties.dataFieldSync.frame[1][0]) >
              FRAME_WIDTH - 0.1
                ? "left"
                : ""
            }`}
          >
            <Icons name="location" width={20}></Icons>
          </span>
        </div>
        {/* <div
          class={`cur-p ${
            props.fieldsType == "preview" || props.fieldsType == "locked"
              ? "pointer-none"
              : ""
          }`}
          onclick={() => {
            if (props.fieldsType == "render") {
              if (preLoadData()[props.properties.dataFieldSync.uid]) {
                physicalAddress(
                  preLoadData()[props.properties.dataFieldSync.uid].split(
                    " "
                  )[0],
                  preLoadData()[props.properties.dataFieldSync.uid].split(
                    " "
                  )[1]
                );
              } else {
              }
            }
          }}
        >
          <span
            data-title="Physical Address"
            class={`notification-badge gps ${
              Number(props.properties.dataFieldSync.frame[0][0]) +
                Number(props.properties.dataFieldSync.frame[1][0]) >
              FRAME_WIDTH - 0.2
                ? "left"
                : ""
            }`}
          >
            <Icons name="physicalLocation" width={20}></Icons>
          </span>
        </div>
        <Show
          when={
            props.fieldsType == "render" &&
            preLoadData()[props.properties.dataFieldSync.uid]
          }
        >
          <div
            class={`cur-p ${
              props.fieldsType == "preview" || props.fieldsType == "locked"
                ? "pointer-none"
                : ""
            }`}
            onclick={() => {
              let origin: any = "";
              if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition((position) => {
                  origin = `${position.coords.latitude},${position.coords.longitude}`;
                  const destination = `${
                    preLoadData()[props.properties.dataFieldSync.uid]?.split(
                      " "
                    )[0]
                  },${
                    preLoadData()[props.properties.dataFieldSync.uid]?.split(
                      " "
                    )[1]
                  }`;
                  const mapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
                  window.open(mapsUrl, "_blank");
                });
              }
            }}
          >
            <span
              data-title="Directions"
              class={`notification-badge gps ${
                Number(props.properties.dataFieldSync.frame[0][0]) +
                  Number(props.properties.dataFieldSync.frame[1][0]) >
                FRAME_WIDTH - 0.1
                  ? "left"
                  : ""
              }`}
            >
              <Icons name="direction" width={20}></Icons>
            </span>
          </div>
        </Show> */}
      </div>
    </>
  );
};

export { setIsLocationUpdated };
export default LocationField;
