import { Component, Show, createSignal } from "solid-js";
import {
  foldersList,
  isNewFolder,
  newFormFolderName,
  setFoldersList,
  setFormsList,
  setNewFormFolderName,
} from "~/store/global.store";
import { notify } from "./Notification";
import messages from "~/helpers/message";
import fetchApi from "~/lib/api";
import { jsonFormStructure } from "~/store/field-editor.store";
import { useNavigate } from "solid-start";
import Icons from "../icons/Icons";
import { updateFormsList } from "~/routes/form/list";
import {
  totalStorageUsed,
  totalUserLicences,
  userRole,
} from "~/store/user-form-role";

interface Props {
  user_id: string;
  jwt_token?: string;
  isBuilder: boolean;
}

const AddFormFolder: Component<Props> = (props) => {
  const navigate = useNavigate();

  const [uploadedFormFile, setUploadedFormFile] = createSignal({} as any);
  const [uploadedFormFileName, setUploadedFormFileName] = createSignal(
    "" as string
  );
  const [uploadedRecordFile, setUploadedRecordFile] = createSignal({} as any);
  const [uploadedRecordFileName, setUploadedRecordFileName] = createSignal(
    "" as string
  );
  const [formId, setFormId] = createSignal("" as string);
  const [formName, setFormName] = createSignal("" as string);
  const [isDataSaving, setIsDataSaving] = createSignal(false as boolean);

  async function addFormStructure() {
    if (
      totalStorageUsed() >
      totalUserLicences() * Number(import.meta.env.VITE_FREE_PER_USER_STORAGE)
    ) {
      notify({
        isNotified: true,
        message: `<div>${messages.warn.storage_used}. ${
          userRole() === "admin"
            ? "&nbsp;&nbsp;<A class='btn sm primary' href='/billing-payments'>Check Storage Plans</A>"
            : "Contact Your Admin."
        }</div>`,
        theme: "warm",
        placement: "top",
        timeout: 4000,
      });
      return;
    }
    setIsDataSaving(true);
    const jwtToken = props?.jwt_token;
    const user_id = props?.user_id;
    const type = isNewFolder() ? "folder" : "form";
    const form_name = newFormFolderName();
    const is_builder: any = props.isBuilder;
    const form_id = formId();
    let structure: any;
    if (form_name) {
      structure = {
        ...jsonFormStructure,
        name: form_name,
        pages: [
          {
            id: 1,
            fields: [],
          },
        ],
        uuid: crypto.randomUUID().toUpperCase(),
      };
      structure.lastSaved = new Date().toISOString();
      structure = JSON.stringify(structure);
    } else {
      structure = uploadedFormFile();
    }
    let records: any = uploadedRecordFile();
    let fields: any;
    let baseUrl: any;
    let formData: any = new FormData();
    if (type == "folder") {
      fields = {
        name: form_name,
        user_id,
      };
      baseUrl = "form/folder/add";
    } else {
      if (is_builder) {
        formData.append("user_id", user_id);
        formData.append("form_id", form_id);
        formData.append("device_type", "web");
      } else {
        fields = {
          description: "",
          structure,
          admin_user_id: user_id,
          user_id,
          form_id,
          device_type: "web",
        };
      }
      baseUrl = is_builder ? "form/structure/upload" : "form/structure/add";
    }
    let designForm: any;
    if (is_builder) {
      designForm = await uploadFormStructureChunk(
        user_id,
        form_id,
        baseUrl,
        jwtToken,
        structure
      );
    } else {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/${baseUrl}`,
        jwt_token: jwtToken as string,
        body: fields,
      };
      designForm = await fetchApi(apiParams);
      if (designForm?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (designForm?.statusCode == 403) {
        location.reload();
      }
    }
    if (type == "folder") {
      if (designForm && designForm.status) {
        setFoldersList([
          ...foldersList(),
          {
            ...designForm.data.folder,
            theme: "folder",
            auto_launch_form_ids: "",
            forms: [],
          },
        ]);
        notify({
          isNotified: true,
          message: messages.folder.added,
          theme: "success",
          placement: "top",
          timeout: 2500,
        });
        setIsDataSaving(false);
        setTimeout(() => {
          navigate("/form/list");
          updateFormsList(props.user_id, props.jwt_token);
        }, 100);
      } else {
        setIsDataSaving(false);
        notify({
          isNotified: true,
          message: messages.error.folder_add,
          theme: "danger",
          placement: "top",
          timeout: 2500,
        });
      }
    } else {
      let formAdminRight: any;
      if (is_builder) {
        formAdminRight = {
          status: true,
        };
      } else {
        const userFormRightsApiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/user/add/rights`,
          jwt_token: jwtToken as string,
          body: { shared_users: user_id, form_id: designForm.data.id },
        };
        formAdminRight = await fetchApi(userFormRightsApiParams);
        if (formAdminRight?.statusCode == 401) {
          window.location.href = "/logout";
        } else if (formAdminRight?.statusCode == 403) {
          location.reload();
        }
      }
      if (formAdminRight && formAdminRight.status) {
        if (records) {
          const recordsData: any = await uploadFormRecordsChunk(
            user_id,
            designForm.data.id,
            jwtToken,
            records
          );
        }
        setIsDataSaving(false);
        notify({
          isNotified: true,
          message: messages.form.design,
          theme: "success",
          placement: "top",
          timeout: 2500,
        });
        if (newFormFolderName() && !isNewFolder()) {
          setTimeout(() => {
            setFormsList([]);
            navigate(`/form/builder/${designForm.data.id}`);
          }, 500);
        } else {
          setTimeout(() => {
            navigate("/form/list");
            updateFormsList(props.user_id, props.jwt_token);
          }, 100);
        }
      } else {
        setIsDataSaving(false);
        notify({
          isNotified: true,
          message: messages.error.form_design,
          theme: "danger",
          placement: "top",
          timeout: 2500,
        });
      }
    }
    setTimeout(() => {
      setNewFormFolderName("");
    }, 2500);
  }

  async function uploadFormStructureChunk(
    user_id: any,
    form_id: any,
    baseUrl: string,
    jwtToken: any,
    chunk: any
  ) {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("form_id", form_id);
    formData.append("structure", chunk);
    const response: any = await fetch(
      `${import.meta.env.VITE_API_URL}/${baseUrl}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: formData,
      }
    ).then((res: any) => res.json());
    return response;
  }

  async function uploadFormRecordsChunk(
    user_id: any,
    form_id: any,
    jwtToken: any,
    chunk: any
  ) {
    const recordsData: any = new FormData();
    recordsData.append("user_id", user_id);
    recordsData.append("form_id", form_id);
    recordsData.append("structure", chunk);
    recordsData.append("device_type", "web");
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/form/records/multiple/upload`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
        body: recordsData,
      }
    ).then((res: any) => res.json());
    if (response?.statusCode == 401 || response?.statusCode == 403) {
      window.location.href = "/logout";
    }
    return response;
  }

  return (
    <div>
      <input type="hidden" name="is_builder" value={String(props.isBuilder)} />
      <input type="hidden" name="form_id" value={formId()} />
      <Show when={props.isBuilder}>
        <div>
          <Icons name="required" width={10} height={10} stroke="red"></Icons>
          &nbsp;
          <strong class="fs-12 alert-text">Required</strong>
        </div>
        <div class="flex app-flex gap">
          <label for="form_structure" class="btn sm btn-ico bg-light">
            Upload Form Structure Data
          </label>
          <input
            class="d-n"
            id="form_structure"
            type="file"
            name="structure"
            accept="application/json"
            onchange={(event: any) => {
              const file: any = event.target.files[0];
              if (file) {
                setUploadedFormFile(file);
                setUploadedFormFileName(file.name);
                const reader = new FileReader();
                reader.onload = function (e: any) {
                  const fileContent = e.target.result;
                  const jsonData = JSON.parse(fileContent);
                  setFormId(jsonData.uuid);
                  setFormName(jsonData.name);
                };
                reader.readAsText(file);
              }
            }}
          />
          <Show when={uploadedFormFileName() != ""}>
            <div class="uploaded-files">
              <span
                class="uploaded-file"
                onclick={() => {
                  setUploadedFormFileName("");
                }}
              >
                <label class="lbl">{uploadedFormFileName()}</label>
                <Icons name="x" width={22}></Icons>
              </span>
            </div>
          </Show>
        </div>
        <br />
        <div class="flex app-flex gap">
          <label for="records_structure" class="btn sm btn-ico bg-light">
            Upload Records Structure Data
          </label>
          <input
            class="d-n"
            id="records_structure"
            type="file"
            name="records"
            accept="application/json"
            onchange={(event: any) => {
              const files: any = event.target.files[0];
              setUploadedRecordFile(files);
              setUploadedRecordFileName(files.name);
            }}
          />
          <Show when={uploadedRecordFileName() != ""}>
            <div class="uploaded-files">
              <span
                class="uploaded-file"
                onclick={() => {
                  setUploadedRecordFileName("");
                }}
              >
                <label class="lbl">{uploadedRecordFileName()}</label>
                <Icons name="x" width={22}></Icons>
              </span>
            </div>
          </Show>
        </div>
        <br />
      </Show>
      <div>
        <Show when={newFormFolderName()}>
          <input type="hidden" name="form_name" value={newFormFolderName()} />
        </Show>
        <input type="hidden" name="user_id" value={props.user_id} />
        <input
          type="hidden"
          name="type"
          value={isNewFolder() ? "folder" : "form"}
        />
        <button
          class={`btn primary ${!props.isBuilder ? "d-n" : ""} ${
            isDataSaving() ? "disabled" : ""
          }`}
          id="add_form_btn"
          onclick={() =>
            setTimeout(() => {
              if (newFormFolderName().trim() != "" || props.isBuilder) {
                addFormStructure();
              }
            }, 100)
          }
        >
          <Show when={!isDataSaving()}>Add Form</Show>
          <Show when={isDataSaving()}>
            Adding Form&nbsp;<Icons name="loader"></Icons>
          </Show>
        </button>
      </div>
    </div>
  );
};

export default AddFormFolder;
