import { Component, For, Show, createSignal } from "solid-js";
import Icons from "../icons/Icons";
import { setPopup } from "~/store/global.store";
import fetchApi from "~/lib/api";
import Popover from "./Popover";

interface Props {
  user_id: any;
  jwt_token: any;
  libraryForms: any;
  adminPanel?: boolean;
  updateList?: any;
  deleteForm?: any;
}

const [selectedPreviewTemplate, setSelectedPreviewTemplate] = createSignal(
  "" as any
);
const [selectedFormRecords, setSelectedFormRecords] = createSignal("" as any);

const LibraryTemplates: Component<Props> = (props) => {
  const [selectedLibraryForm, setSelectedLibraryForm] = createSignal(
    "" as string
  );

  async function fetchFormSampleTemplate(form_id: any) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/template/view`,
      jwt_token: props.jwt_token as string,
      body: {
        form_id,
      },
    };
    let sampleTemplate = await fetchApi(apiParams);
    if (
      sampleTemplate?.statusCode == 401 ||
      sampleTemplate?.statusCode == 403
    ) {
      window.location.href = "/logout";
    }
    if (sampleTemplate && sampleTemplate.status) {
      setPopup("FormPreview");
      setSelectedLibraryForm(form_id);
      setSelectedPreviewTemplate(sampleTemplate.data.structure);
      setSelectedFormRecords(sampleTemplate.data.records);
    }
  }

  return (
    <>
      <br />
      <Show when={props.libraryForms?.length > 0}>
        <div class="form-container">
          <For each={props.libraryForms}>
            {(form: any) => (
              <div class="pr cur-p h-fit w-160p">
                <div
                  class={`pa flex app-flex align-center justify-center w-160p h-100p form-actions ${
                    selectedLibraryForm() == form.id ? "active" : ""
                  }`}
                  onclick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    fetchFormSampleTemplate(form.id);
                  }}
                >
                  <Show when={props?.adminPanel}>
                    <Show when={!form.is_published}>
                      <div class="pinned-form">
                        <Icons name="unpublish"></Icons>
                      </div>
                    </Show>
                    <div class="list-options">
                      <Popover
                        content={form.is_published ? "Unpublish" : "Publish"}
                        top={-55}
                        left={form.is_published ? 70 : 60}
                      >
                        <button
                          class="list-option__btn z-index-1"
                          onclick={async (e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            const apiParams = {
                              method: "POST" as string,
                              url: `${
                                import.meta.env.VITE_API_URL
                              }/form/library/publication/toggle`,
                              jwt_token: props.jwt_token as string,
                              body: {
                                form_id: form.id,
                              },
                            };
                            let publication = await fetchApi(apiParams);
                            if (
                              publication?.statusCode == 401 ||
                              publication?.statusCode == 403
                            ) {
                              window.location.href = "/logout";
                            }
                            if (publication && publication.status) {
                              props?.updateList(form.id);
                            }
                          }}
                        >
                          <Icons
                            name={form.is_published ? "unpublish" : "publish"}
                            stroke="#ffffff"
                            width={20}
                            height={20}
                          />
                        </button>
                      </Popover>
                      <Popover content="Delete" top={-55} left={57}>
                        <button
                          class="list-option__btn z-index-1"
                          onclick={async (e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            props?.deleteForm(form.id);
                          }}
                        >
                          <Icons
                            name="trash"
                            stroke="#ffffff"
                            width={20}
                            height={20}
                          />
                        </button>
                      </Popover>
                    </div>
                  </Show>
                  <label
                    class={`form-list-label ${form.form_theme} pr`}
                    id={`form_${form.id}`}
                    style={{
                      "font-size":
                        form.form_name?.length > 120
                          ? "0.7rem"
                          : form.form_name?.length < 120 &&
                            form.form_name?.length > 60
                          ? "0.8rem"
                          : form.form_name?.length < 60 &&
                            form.form_name?.length > 30
                          ? "0.9rem"
                          : "1rem",
                    }}
                  >
                    {form.form_name?.slice(0, 80)}{" "}
                    {form.form_name?.length > 80 ? "..." : ""}
                  </label>
                  <span class="selected">
                    <Icons name="checkmark" stroke="#fff"></Icons>
                  </span>
                </div>
                <Show when={form.records_count}>
                  <div
                    class="records-count template"
                    onclick={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      fetchFormSampleTemplate(form.id);
                    }}
                  >
                    {form.records_count}
                  </div>
                </Show>
                <img
                  src={`/form_themes/${form.form_theme}.png`}
                  class="form-list-image"
                />
              </div>
            )}
          </For>
        </div>
      </Show>
      <br />
    </>
  );
};

export {
  selectedPreviewTemplate,
  selectedFormRecords,
  setSelectedPreviewTemplate,
};
export default LibraryTemplates;
