import { Component, Show, createEffect, createSignal, onMount } from "solid-js";
import { createStore } from "solid-js/store";
import generateCSS from "~/helpers/generator";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  calculateTextLines,
  currenFormSavedFields,
  formJson,
  updatedCurrentField,
  updatedMultipleFields,
  updateFormJson,
} from "~/store/form.store";
import {
  isFormDropDownOpen,
  isMouseDown,
  labelFieldTop,
  multipleSelectField,
  parseHtml,
  setIsMouseDown,
} from "~/store/global.store";
import {
  fieldMovement,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
import convert from "~/helpers/convertor";
import { findKeyByValue } from "~/store/records.store";
import { mapping } from "~/store/field-editor.store";

interface Props {
  properties: any;
  fieldsType: string;
}
export const [selectedTableLabel, setSelectedTableLabel] = createSignal(
  "" as string
);

const LabelField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [multiLineLabels, setMultiLineLabels] = createSignal([] as any);
  const [multiLinesFields, setMultiLinesFields] = createSignal([] as any);

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  onMount(() => {
    const element: any = document.getElementById(
      `label_${props.properties.dataFieldSync.uid}`
    );
    if (element) {
      const originalWhiteSpace = element.style.whiteSpace;
      element.style.whiteSpace = "nowrap";
      const singleLineWidth = element.scrollWidth;
      element.style.whiteSpace = originalWhiteSpace;
      if (element.childNodes.length > 1) {
        setMultiLinesFields([
          ...new Set([
            ...multiLinesFields(),
            props.properties.dataFieldSync.uid,
          ]),
        ]);
      }
      if (singleLineWidth > element.clientWidth) {
        setMultiLineLabels([
          ...new Set([
            ...multiLineLabels(),
            props.properties.dataFieldSync.uid,
          ]),
        ]);
      }
    }
  });

  return (
    <>
      <Show
        when={
          props.fieldsType == "render" ||
          props.fieldsType == "preview" ||
          props.fieldsType == "locked" ||
          String(props.properties.dataFieldSync.parentTableId) == "0" ||
          !props.properties.dataFieldSync.tableCellAddress.includes(0)
        }
      >
        <Show
          when={String(props.properties.dataFieldSync.parentTableId) == "0"}
        >
          <div
            id={`label_${props.properties.dataFieldSync.uid}`}
            class={`field z-index-0 flex flex-column label-data ${
              multiLinesFields()?.includes(props.properties.dataFieldSync.uid)
                ? "editor"
                : ""
            } ${
              !currenFormSavedFields().includes(
                props.properties.dataFieldSync.uid
              ) && props.properties.dataFieldSync.label != ""
                ? ""
                : ""
            } ${props.fieldsType == "designer" ? "builder-field-border" : ""} ${
              props.fieldsType == "preview" ? "user-select-none" : ""
            } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
            style={`${generateCSS(fieldProperties)} --lineHeight: ${Math.ceil(
              (Math.round(
                (Number(props.properties.dataFieldSync.fontSize) / 72) * 84 * 4
              ) /
                4 /
                84) *
                96
            )}px;`}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseMove={() => {
              if (isMouseDown() && fieldMovement() == "") {
                updatedCurrentField(props.properties.dataFieldSync.uid);
                setIsGuidline(true);
                setFieldMovement("move");
              }
            }}
            onMouseUp={() => setIsMouseDown(false)}
            onclick={(event: any) =>
              multipleSelectField() || event.shiftKey
                ? String(props.properties.dataFieldSync.parentTableId) == "0"
                  ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                  : ""
                : updatedCurrentField(props.properties.dataFieldSync.uid)
            }
            oncontextmenu={(event: any) => {
              multipleSelectField() || event.shiftKey
                ? String(props.properties.dataFieldSync.parentTableId) == "0"
                  ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                  : ""
                : updatedCurrentField(props.properties.dataFieldSync.uid);
              addContectMenu(event);
            }}
            innerHTML={
              props.properties.attributedStringHTML
                ? parseHtml(
                    props.properties.attributedStringHTML,
                    props.properties.dataFieldSync.fontSize,
                    props.properties.color,
                    // props.properties.font == 0
                    //   ? "Times New Roman"
                    //   : props.properties.font == 1
                    //   ? "Helvetica"
                    //   : "Courier New",
                    props.fieldsType
                  )
                  ? parseHtml(
                      props.properties.attributedStringHTML,
                      props.properties.dataFieldSync.fontSize,
                      props.properties.color,
                      // props.properties.font == 0
                      //   ? "Times New Roman"
                      //   : props.properties.font == 1
                      //   ? "Helvetica"
                      //   : "Courier New",
                      props.fieldsType
                    )
                  : props.properties.dataFieldSync.label
                : props.properties.dataFieldSync.label
            }
          ></div>
        </Show>
        <Show
          when={String(props.properties.dataFieldSync.parentTableId) != "0"}
        >
          <div
            id={`label_${props.properties.dataFieldSync.uid}`}
            class={`field z-index-1 flex flex-column label-data ${
              multiLinesFields()?.includes(props.properties.dataFieldSync.uid)
                ? "editor"
                : ""
            } ${
              !currenFormSavedFields().includes(
                props.properties.dataFieldSync.uid
              ) && props.properties.dataFieldSync.label != ""
                ? ""
                : ""
            } ${
              props.fieldsType == "designer" &&
              String(props.properties.dataFieldSync.parentTableId) != "0" &&
              !props.properties.dataFieldSync.tableCellAddress.includes(0)
                ? "cur-p z-index-1"
                : ""
            } ${props.fieldsType == "designer" ? "builder-field-border" : ""} ${
              props.fieldsType == "preview" ? "user-select-none" : ""
            } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
            style={generateCSS(fieldProperties)}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseMove={() => {
              if (isMouseDown() && fieldMovement() == "") {
                updatedCurrentField(props.properties.dataFieldSync.uid);
                setIsGuidline(true);
                setFieldMovement("move");
              }
            }}
            onMouseUp={() => setIsMouseDown(false)}
            onclick={(event: any) =>
              multipleSelectField() || event.shiftKey
                ? String(props.properties.dataFieldSync.parentTableId) == "0"
                  ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                  : ""
                : updatedCurrentField(props.properties.dataFieldSync.uid)
            }
          >
            <div
              style={
                calculateTextLines(
                  props.properties.dataFieldSync.label,
                  Number(
                    convert(props.properties.dataFieldSync.frame[1][0], "in-px")
                  ),
                  (Math.round(
                    (Number(props.properties.dataFieldSync.fontSize) / 72) *
                      84 *
                      4
                  ) /
                    4 /
                    84) *
                    96,
                  props.properties.font == 0
                    ? "Times New Roman"
                    : props.properties.font == 1
                    ? "Helvetica"
                    : "Courier New"
                ) > 1 || props.properties.dataFieldSync.frame[1][1] > 0.375
                  ? `padding-top: ${
                      labelFieldTop[props.properties.dataFieldSync.fontSize]
                    }px; display: -webkit-box; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: ${Math.floor(
                      (Number(
                        convert(
                          props.properties.dataFieldSync.frame[1][1],
                          "in-px"
                        )
                      ) -
                        labelFieldTop[
                          props.properties.dataFieldSync.fontSize
                        ]) /
                        ((Math.round(
                          (Number(props.properties.dataFieldSync.fontSize) /
                            72) *
                            84 *
                            4
                        ) /
                          4 /
                          84) *
                          96 *
                          1.2)
                    )}`
                  : `height: 100%; display: flex; align-items: center; justify-content: ${
                      props.properties.dataFieldSync?.alignment.split(".")[0]
                    }`
              }
              innerHTML={
                props.properties.attributedStringHTML
                  ? parseHtml(
                      props.properties.attributedStringHTML,
                      props.properties.dataFieldSync.fontSize,
                      props.properties.color,
                      // props.properties.font == 0
                      //   ? "Times New Roman"
                      //   : props.properties.font == 1
                      //   ? "Helvetica"
                      //   : "Courier New",
                      props.fieldsType
                    )
                    ? parseHtml(
                        props.properties.attributedStringHTML,
                        props.properties.dataFieldSync.fontSize,
                        props.properties.color,
                        // props.properties.font == 0
                        //   ? "Times New Roman"
                        //   : props.properties.font == 1
                        //   ? "Helvetica"
                        //   : "Courier New",
                        props.fieldsType
                      )
                    : props.properties.dataFieldSync.label
                  : props.properties.dataFieldSync.label
              }
            ></div>
          </div>
        </Show>
      </Show>
      <Show
        when={
          props.properties.dataFieldSync.tableCellAddress.includes(0) &&
          props.fieldsType == "designer" &&
          props.properties.dataFieldSync.tableCellAddress.includes(0)
        }
      >
        <label
          class={`tableLabelHeader field ${
            props.fieldsType == "designer" ? "cur-p builder-field" : ""
          }`}
          style={generateCSS(fieldProperties)}
          onclick={() => {
            updatedCurrentField(props.properties.dataFieldSync.parentTableId);
            setSelectedTableLabel(
              selectedTableLabel() != props.properties.dataFieldSync.uid
                ? props.properties.dataFieldSync.uid
                : ""
            );
          }}
        >
          <div
            style={
              calculateTextLines(
                props.properties.dataFieldSync.label,
                Number(
                  convert(props.properties.dataFieldSync.frame[1][0], "in-px")
                ),
                (Math.round(
                  (Number(props.properties.dataFieldSync.fontSize) / 72) *
                    84 *
                    4
                ) /
                  4 /
                  84) *
                  96,
                props.properties.font == 0
                  ? "Times New Roman"
                  : props.properties.font == 1
                  ? "Helvetica"
                  : "Courier New"
              ) > 1 || props.properties.dataFieldSync.frame[1][1] > 0.375
                ? `padding-top: ${
                    labelFieldTop[props.properties.dataFieldSync.fontSize]
                  }px; display: -webkit-box; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: ${Math.floor(
                    (Number(
                      convert(
                        props.properties.dataFieldSync.frame[1][1],
                        "in-px"
                      )
                    ) -
                      labelFieldTop[props.properties.dataFieldSync.fontSize]) /
                      ((Math.round(
                        (Number(props.properties.dataFieldSync.fontSize) / 72) *
                          84 *
                          4
                      ) /
                        4 /
                        84) *
                        96 *
                        1.2)
                  )}`
                : `height: 100%; display: flex; align-items: center; justify-content: ${
                    props.properties.dataFieldSync?.alignment.split(".")[0]
                  }`
            }
            innerHTML={
              props.properties.attributedStringHTML
                ? parseHtml(
                    props.properties.attributedStringHTML,
                    props.properties.dataFieldSync.fontSize,
                    props.properties.color,
                    // props.properties.font == 0
                    //   ? "Times New Roman"
                    //   : props.properties.font == 1
                    //   ? "Helvetica"
                    //   : "Courier New",
                    props.fieldsType
                  )
                  ? parseHtml(
                      props.properties.attributedStringHTML,
                      props.properties.dataFieldSync.fontSize,
                      props.properties.color,
                      // props.properties.font == 0
                      //   ? "Times New Roman"
                      //   : props.properties.font == 1
                      //   ? "Helvetica"
                      //   : "Courier New",
                      props.fieldsType
                    )
                  : props.properties.dataFieldSync.label
                : props.properties.dataFieldSync.label
            }
          ></div>
        </label>
        <Show when={selectedTableLabel() == props.properties.dataFieldSync.uid}>
          <div
            class="table-dropdown"
            style={{
              "--left":
                Number(
                  convert(props.properties.dataFieldSync.frame[0][0], "in-px")
                ) -
                21 +
                "px",
              "--top":
                Number(
                  convert(props.properties.dataFieldSync.frame[0][1], "in-px")
                ) +
                36 +
                "px",
            }}
          >
            <span class="dropdown-caret"></span>
            <ul class="dropdown-list">
              <li
                class="dropdown-items"
                onclick={() => {
                  setSelectedTableLabel("");
                  updatedCurrentField(props.properties.dataFieldSync.uid);
                }}
              >
                Edit Column Title
              </li>
              <li
                class="dropdown-items"
                onclick={() => {
                  setSelectedTableLabel("");
                  updatedCurrentField(
                    props.properties.dataFieldSync.parentTableId
                  );
                  updateFormJson(
                    "",
                    "columns",
                    1,
                    false,
                    0,
                    findKeyByValue(
                      mapping,
                      props.properties.dataFieldSync.tableCellAddress?.split(
                        ""
                      )[0]
                    ),
                    "before"
                  );
                }}
              >
                Add Column Before
              </li>
              <li
                class="dropdown-items"
                onclick={() => {
                  setSelectedTableLabel("");
                  updatedCurrentField(
                    props.properties.dataFieldSync.parentTableId
                  );
                  updateFormJson(
                    "",
                    "columns",
                    1,
                    false,
                    0,
                    Number(
                      findKeyByValue(
                        mapping,
                        props.properties.dataFieldSync.tableCellAddress?.split(
                          ""
                        )[0]
                      )
                    ) + 1,
                    "after"
                  );
                }}
              >
                Add Column After
              </li>
              <Show
                when={
                  formJson.currentField?.column_sizes?.split(",")?.length > 1
                }
              >
                <li
                  class="dropdown-items"
                  onclick={() => {
                    setSelectedTableLabel("");
                    updatedCurrentField(
                      props.properties.dataFieldSync.parentTableId
                    );
                    updateFormJson(
                      "",
                      "columns",
                      -1,
                      false,
                      0,
                      Number(
                        findKeyByValue(
                          mapping,
                          props.properties.dataFieldSync.tableCellAddress?.split(
                            ""
                          )[0]
                        )
                      ) + 1,
                      "delete"
                    );
                  }}
                >
                  Delete Column
                </li>
              </Show>
            </ul>
          </div>
        </Show>
      </Show>
    </>
  );
};

export default LabelField;
