const messages: any = {
  user: {
    added: "User added successfully.",
    edited: "User updated successfully.",
    deleted: "User deleted successfully.",
    data_deleted: "User form and records data deleted successfully.",
    profile_update: "User profile updated successfully.",
    role_update: "User role updated successfully.",
    USER_PASSWORD_UPDATE_SUCCESS: "User password changed successfully.",
    USER_PASSWORD_RESET_MAIL_SENT_SUCCESS:
      "User password reset mail sent successfully.",
    USER_PASSWORD_RESET_SUCCESS: "User password reset successfully.",
    password_regenerate: "User password regenerated successfully.",
    force_resync: "Force Resync is applied for USER in app",
    cancel_resync: "Force Resync is canceled for USER in app",
    update_user_settings: "User Settings updated successfully.",
    USER_NOTIFICATIONS_ADD_SUCCESS: "User notification added successfully.",
    USER_NOTIFICATIONS_UPDATE_SUCCESS:
      "User notification updated successfully.",
    USER_NOTIFICATION_DELETE_SUCCESS: "User notification deleted successfully.",
  },
  group: {
    added: "Group added successfully.",
    edited: "Group updated successfully.",
    deleted: "Group deleted successfully.",
  },
  form: {
    design: "Form design added successfully.",
    FORM_ADMIN: "Form Admin",
    FORM_DESIGNER: "Form Designer",
    FORM_FILLER: "Form Filler",
    FORM_COLLABORATOR: "Form Collaborator",
    FORM_VIEWER: "Form Viewer",
    update_form_layout: "Form Layout updated successfully.",
    update_form_settings: "Form Settings updated successfully.",
    clone_form: "Form Structure cloned successfully.",
    clone_folder: "Folder cloned successfully.",
    delete_form: "Form Structure deleted successfully.",
    restore_form: "Form Structure restored successfully.",
    delete_folder: "Folder deleted successfully.",
    rename_form: "Form Structure renamed successfully.",
    rename_folder: "Folder renamed successfully.",
    share_form: "Form shared successfully.",
    share_folder: "Folder shared successfully.",
    share_record: "Form Record shared successfully.",
    remove_share_form: "Form shared rights removed successfully.",
    team_share_form: "Forms shared with team successfully.",
    library_form: "Library Form design added successfully.",
    export_form: "Form exported successfully.",
    no_folder_forms: "No forms inside folder to share with users.",
    FORM_AND_RECORDS_DROPBOX_EXPORT_SUCCESS:
      "Files exported to DropBox successfully.",
    FORM_AND_RECORDS_BOX_EXPORT_SUCCESS: "Files exported to Box successfully.",
    FORM_AND_RECORDS_MAIL_EXPORT_SUCCESS:
      "Your files have been exported successfully. The email is now in queue and will be sent shortly.",
    FORM_AND_RECORDS_GOOGLE_DRIVE_EXPORT_SUCCESS:
      "Files exported to Google Drive successfully.",
    FORM_AND_RECORDS_ONE_DRIVE_EXPORT_SUCCESS:
      "Files exported to One Drive successfully.",
    IMPORT_FILE_SUCCESS: "File imported successfully.",
    IMPORT_FILE_ERROR: "File import error!",
    INVALID_CSV_FILE: `<div class="text-center">
        <div class="mb-5"><strong class="fs-20">Invalid CSV file.</strong></div>
        <span class="lbl">The file must contain at least one row of field names and data values with commas separating each field.</span><br/><br/>
        <span class="lbl">For an example of a valid CSV file, export one or more records from an existing form.</span>
      </div>`,
    INVALID_JSON_FILE: `<div class="text-center">
        <div class="mb-5"><strong class="fs-20">Invalid JSON file.</strong></div>
        <span class="lbl">No records were found in this JSON.</span><br/><br/>
        <span class="lbl">For an example of a valid JSON file, export one or more records from an existing form.</span>
      </div>`,
    INVALID_XML_FILE: `<div class="text-center">
        <div class="mb-5"><strong class="fs-20">Invalid XML file.</strong></div>
        <span class="lbl">No records were found in this XML.</span><br/><br/>
        <span class="lbl">For an example of a valid XML file, export one or more records from an existing form.</span>
      </div>`,
    IMPORT_CSV_ERROR: `<div class="text-center">
        <div class="mb-5"><strong class="fs-20">Error</strong></div>
        <span class="lbl">None of the fields in this CSV file match any fields in the form. Please make sure you are importing to the correct form.</span><br/><br/>
        <span class="lbl">For an example of a valid CSV file, export one or more existing records from this form. Note that the field names must match exactly.</span>
      </div>`,
    IMPORT_JSON_ERROR: `<div class="text-center">
        <div class="mb-5"><strong class="fs-20">Error</strong></div>
        <span class="lbl">None of the fields in this JSON file match any fields in the form. Please make sure you are importing to the correct form.</span><br/><br/>
        <span class="lbl">For an example of a valid JSON file, export one or more existing records from this form. Note that the field names must match exactly.</span>
      </div>`,
    IMPORT_XML_ERROR: `<div class="text-center">
        <div class="mb-5"><strong class="fs-20">Error</strong></div>
        <span class="lbl">None of the fields in this XML file match any fields in the form. Please make sure you are importing to the correct form.</span><br/><br/>
        <span class="lbl">For an example of a valid XML file, export one or more existing records from this form. Note that the field names must match exactly.</span>
      </div>`,
  },
  folder: {
    added: "Folder added successfully.",
    auto_launch: "Folder forms auto launched successfully.",
  },
  formDescription: {
    FORM_ADMIN:
      "Can Design, Share Form & Fill, Save, View, Export Form Records.",
    FORM_DESIGNER: "Can Design Form & View, Fill Form Records.",
    FORM_FILLER: "Can View, Fill, Save Form Records.",
    FORM_COLLABORATOR:
      "Grant the User the Ability to Collaborate with other Team Members and View/Edit Their Records.",
    FORM_VIEWER: "Can View Form Records.",
    form_edit: "Edit Form",
    form_settings: "Form Settings",
    add_page: "Add Page",
    field_linker: "Field Linker",
    add_record: "Add Record",
  },
  subscription: {
    trialing: "In Trial",
    active: "Active",
    plan_upgrade: "Subscription plan upgraded successfully.",
    purchase_licences: "Licences purchased successfully.",
    cancel_subcription: "User subcription canceled successfully.",
    update_card: "Card details updated successfully.",
  },
  appVersion: {
    added: "App version added successfully.",
  },
  error: {
    user_add: "Error while adding user",
    user_edit: "Error while updating user",
    user_delete: "Error while deleting user",
    update_user_settings: "Error while updating user settings",
    data_deleted: "Error while deleting user form and records data",
    profile_update: "Error while updating user profile",
    role_update: "Error while updating user role",
    group_add: "Error while adding group",
    group_edit: "Error while updating group",
    group_delete: "Error while deleting group",
    form_design: "Error while adding form design",
    plan_upgrade: "Error while upgrading subscription Plan",
    purchase_licences: "Error while purchasing cicences",
    cancel_subcription: "Error while cancelling user subscription",
    update_card: "Error while updating card details",
    update_form_layout: "Error while updating layout",
    update_form_settings: "Error while updating form settings",
    clone_form: "Error while cloning form structure",
    clone_folder: "Error while cloning folder",
    delete_form: "Error while deleting form structure",
    restore_form: "Error while restoring form structure",
    delete_folder: "Error while deleting folder",
    rename_form: "Error while renaming form structure",
    rename_folder: "Error while renaming folder",
    share_form: "Error while sharing form.",
    share_folder: "Error while sharing folder.",
    share_record: "Error while sharing form record.",
    USER_PASSWORD_UPDATE_ERROR: "Error while changing user password.",
    USER_PASSWORD_RESET_MAIL_SENT_ERROR:
      "Error while sending mail for reset password.",
    USER_PASSWORD_RESET_ERROR: "Error while reseting user password.",
    folder_add: "Error while adding folder",
    library_form: "Error while adding library form design",
    password_regenerate: "Error while regenerating user password",
    error: "Error!",
    app_version_added: "Error while adding app version",
  },
  warn: {
    user_add: "You have no licence remaining for new user !!",
    email_required: "Email field can not be left blank !!",
    group_add_edit: "At least one user must be assigned to the team !!",
    USER_ALREADY_EXIST: "Please use another email. User already exists !!",
    update_form_layout: "No changes done to update !!",
    INVALID_OLD_PASSWORD: "Invalid old password !!",
    USER_NEW_PASSWORD_MUST_BE_DIFFERENT:
      "Old password must not be same as original password !!",
    USER_NOT_VALID: "User not found. Please enter valid email !!",
    plan_upgrade_in_trial:
      "Plan changes can not be made while the customer is in their trial period !!",
    plan_upgrade: "You can't upgrade plan from Team to Basic !!",
    transcation_invoice_in_trial:
      "Can not view invoice while subscription is in their trial period !!",
    storage_used:
      "You have no storage remaining for new Form / Folder / Record",
    autologout: "You have been logged out due to session timeout !!",
    subscriptionCancelLogout:
      "You have been logged out because your subscription was canceled !!",
    otherSessionlogout:
      "You have been logged out because new session started on another device !!",
  },
  info: {
    password: `
    <div>
      <span>Passwords must contain the following:</span>
      <ul class="list-items">
        <li>
          A <strong>lowercase</strong> letter
        </li>
        <li>
          A <strong>capital(uppercase)</strong> letter
        </li>
        <li>
          A <strong>special characters</strong>
        </li>
        <li>
          A <strong>number</strong>
        </li>
        <li>
          Minimum <strong>8 characters</strong>
        </li>
      </ul>
    </div>`,
  },
};
export default messages;
