import { Show, createEffect, createSignal, onMount } from "solid-js";
import { A, useParams, useRouteData, useSearchParams } from "solid-start";
import { FormError } from "solid-start/data";
import {
  createServerData$,
  createServerAction$,
  redirect,
} from "solid-start/server";
import { createUserSession, getUser, register } from "~/db/session";

import AuthLayout from "~/components/layouts/auth.layout";
import Icons from "~/components/icons/Icons";
import {
  validateConfirmPassword,
  validateEmail,
  validatePassword,
} from "~/helpers/validators";
import fetchApi from "~/lib/api";
import PricingPopup from "~/components/popup/PricingPopup";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import Popover from "~/components/global/Popover";
import messages from "~/helpers/message";
import { notify } from "~/components/global/Notification";
import blurInput from "~/directives/blur-input.directive";
import SelectOne from "~/components/global/SelectOne";
import { createStore } from "solid-js/store";
import {
  ANNUAL_SUBSCRIPTION_PRICE,
  CURRENCY_SYMBOL,
  MONTHLY_SUBSCRIPTION_PRICE,
} from "~/helpers/constant";

const inputLbl = blurInput;

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    if (userDetails) {
      if (userDetails.data.role.role_name == "admin") {
        throw redirect("/form/list");
      } else if (userDetails.data.role.role_name == "super_admin") {
        throw redirect("/admin/dashboard");
      } else {
        if (userDetails.data.has_set_password) {
          return redirect("/form/list");
        } else {
          return redirect("/change-password");
        }
      }
    }
    const planApiParams = {
      method: "GET" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/pricing`,
    };
    let plans = await fetchApi(planApiParams);
    plans = plans.data;

    const PADDLE_ENDPOINT = process.env.PADDLE_ENDPOINT;
    const PADDLE_AUTH = process.env.PADDLE_VENDOR_AUTH_CODE;
    const TeamPlanId = process.env.PADDLE_TEAM_PLAN_ID;
    const TeamAnuualPlanId = process.env.PADDLE_TEAM_ANNUAL_PLAN_ID;

    return {
      plans,
      PADDLE_ENDPOINT,
      PADDLE_AUTH,
      TeamPlanId,
      TeamAnuualPlanId,
    };
  });
}

export default function Register() {
  setPageTitle(`${SITE_TITLE} - Registration`);
  const data: any = useRouteData<typeof routeData>();
  const params = useParams();
  const [planName, setPlanName] = createSignal("Team" as string);
  const [customPlan, setCustomPlan] = createSignal([] as any);
  const [discountCoupon, setDiscountCoupon] = createSignal("" as string);
  const [mode, setMode] = createSignal("web" as string);
  const [planQuantity, setPlanQuantity] = createSignal(1 as number);
  const [searchParams, setSearchParams] = useSearchParams();
  const [discountPrice, setDiscountPrice] = createStore({
    month: `${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE}`,
    year: `${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}`,
  }) as any;

  onMount(async () => {
    const { discount } = { ...searchParams } as { discount: string };
    if (discount) {
      setDiscountPrice({
        month: `${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE}`,
        year: `${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}`,
      });
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/subscription/discount/data`,
        body: {
          transaction_id: discount,
        },
      };
      let discountData = await fetchApi(apiParams);
      if (discountData?.statusCode == 401) {
        window.location.href = "/logout";
      }
      setDiscountPrice({
        month:
          discountData.data.restrict_to?.includes(data()?.TeamPlanId) ||
          !discountData.data.restrict_to
            ? discountData.data.type == "percentage"
              ? `<span class="line-through">${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  MONTHLY_SUBSCRIPTION_PRICE -
                  (MONTHLY_SUBSCRIPTION_PRICE * discountData.data.amount) / 100
                }</span>`
              : `<span class="line-through">${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  MONTHLY_SUBSCRIPTION_PRICE -
                  Number(discountData.data.amount) / 100
                }</span>`
            : `${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE}`,
        year:
          discountData.data.restrict_to?.includes(data()?.TeamAnuualPlanId) ||
          !discountData.data.restrict_to
            ? discountData.data.type == "percentage"
              ? `<span class="line-through">${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  ANNUAL_SUBSCRIPTION_PRICE -
                  (ANNUAL_SUBSCRIPTION_PRICE * discountData.data.amount) / 100
                }</span>`
              : `<span class="line-through">${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  ANNUAL_SUBSCRIPTION_PRICE -
                  Number(discountData.data.amount) / 100
                }</span>`
            : `${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}`,
      });
      setDiscountCoupon(discountData.data.code);
    }
    const { plan } = { ...searchParams } as { plan: string };
    if (plan && plan != "Team" && plan != "Team Annual") {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/subscription/plan/data`,
        body: {
          transaction_id: plan,
        },
      };
      let planData = await fetchApi(apiParams);
      if (planData?.statusCode == 401) {
        window.location.href = "/logout";
      }
      setCustomPlan([
        {
          name: planData.data.id,
          display: `<div class="fs-24">${CURRENCY_SYMBOL}${
            planData.data.unit_price.amount / 100
          }</div><div class="pt-10">${
            planData.data.billing_cycle.interval == "year"
              ? "per Year"
              : "per Month"
          }</div>`,
        },
      ]);
    }
  });

  createEffect(() => {
    if ({ ...searchParams }) {
      const { plan } = { ...searchParams } as { plan: string };
      if (plan) {
        setPlanName(plan);
      }
      const { mode } = { ...searchParams } as { mode: string };
      if (mode) {
        setMode(mode);
      }
      const { email } = { ...searchParams } as { email: string };
      if (email) {
        const element: any = document.getElementById("email-input");
        element.value = email;
      }
      const { quantity } = { ...searchParams } as { quantity: string };
      if (quantity) {
        setPlanQuantity(Number(quantity));
      }
    }
  });

  const [registering, { Form }] = createServerAction$(
    async (form: FormData) => {
      const email = form.get("email") as string;
      const password = form.get("password") as string;
      const confirm_password = form.get("confirm_password") as string;
      const first_name = form.get("first_name") as string;
      const last_name = form.get("last_name") as string;
      const company_name = form.get("company_name") as string;
      const register_mode = form.get("register_mode") as string;
      const planName = form.get("planName") as string;
      const planQuantity = form.get("planQuantity") as number | null;
      const discount = form.get("discount") as string | null;
      const fields = {
        first_name,
        last_name,
        email,
        password,
        company_name,
        register_mode,
      };
      let fieldErrors: any = {
        email: validateEmail(email),
        password: validatePassword(password),
        confirm: validateConfirmPassword(password, confirm_password),
      };
      if (Object.values(fieldErrors).some(Boolean)) {
        if (fieldErrors?.password) {
          fieldErrors.password = fieldErrors?.password["t"];
          throw new FormError("", { fieldErrors, fields });
        } else {
          throw new FormError("Fields invalid", { fieldErrors, fields });
        }
      } else {
        const user = await register(fields);
        if (user?.message == "USER_ALREADY_EXIST") {
          return "USER_ALREADY_EXIST";
        }
        if (user) {
          return createUserSession(
            user.id,
            user.Authorization,
            `/pricing?plan=${
              planName +
              (planQuantity ? "&quantity=" + planQuantity : "") +
              (discount ? "&discount=" + discount : "")
            }`
          );
        } else {
          throw new FormError("Something went wrong!");
        }
      }
    }
  );

  createEffect(() => {
    if (registering.result) {
      notify({
        isNotified: true,
        message: messages.warn.USER_ALREADY_EXIST as string,
        theme: "warm" as string,
        placement: "top",
        timeout: 4000,
      });
    }
  });

  return (
    <main>
      <PricingPopup plans={data()?.plans} />
      <AuthLayout>
        <Form class="auth-form">
          <input
            type="hidden"
            name="redirectTo"
            value={params.redirectTo ?? "/"}
          />
          <div class="text-center auth-form__title">
            <h2 class="title">
              {import.meta.env.VITE_PADDLE_TRIAL_DAYS
                ? `Start your ${
                    import.meta.env.VITE_PADDLE_TRIAL_DAYS
                  } day free Trial`
                : "Start your free Trial"}
            </h2>
          </div>
          <div class="clearix"></div>
          <br />
          <div class="flex gap">
            <div class="form-group icon-group app-gap">
              <span class="form-group__icon">
                <Icons name="user" />
              </span>
              <div class="form-element">
                <input
                  id="firstname-input"
                  name="first_name"
                  placeholder="First Name"
                  class="input"
                  use:inputLbl
                />
              </div>
            </div>
            <div class="form-group icon-group app-gap">
              <span class="form-group__icon">
                <Icons name="user" />
              </span>
              <div class="form-element">
                <input
                  id="lastname-input"
                  name="last_name"
                  placeholder="Last Name"
                  class="input"
                  use:inputLbl
                />
              </div>
            </div>
          </div>
          <br />
          <div class="form-group icon-group">
            <span class="form-group__icon">
              <Icons name="email" />
            </span>
            <div class="form-element">
              <input
                id="email-input"
                name="email"
                placeholder="Email Address"
                class={`input ${
                  registering.error?.fieldErrors?.email ? "error-input" : ""
                }`}
                use:inputLbl
              />
            </div>
          </div>
          <Show when={registering.error?.fieldErrors?.email}>
            <p role="alert" class="error-message">
              {registering.error.fieldErrors.email}
            </p>
          </Show>
          <div class="clearfix"></div>
          <br />
          <div class="form-group icon-group">
            <span class="form-group__icon">
              <Icons name="building" />
            </span>
            <div class="form-element">
              <input
                id="company-input"
                class="input"
                name="company_name"
                type="text"
                placeholder="Company"
                use:inputLbl
              />
            </div>
          </div>
          <div class="clearfix"></div>
          <br />
          <div class="flex gap">
            <div class="form-group icon-group app-gap">
              <span class="form-group__icon">
                <Icons name="lock" />
              </span>
              <div class="form-element">
                <input
                  id="password-input"
                  class={`input pr-30 ${
                    registering.error?.fieldErrors?.password
                      ? "error-input"
                      : ""
                  }`}
                  name="password"
                  type="password"
                  placeholder="Password"
                  use:inputLbl
                />
              </div>
              <Popover content={messages.info.password}>
                <span class="form-group__info-icon">
                  <Icons name="info"></Icons>
                </span>
              </Popover>
            </div>
            <div class="form-group icon-group">
              <span class="form-group__icon">
                <Icons name="key" />
              </span>
              <div class="form-element">
                <input
                  id="confirm_password-input"
                  class={`input ${
                    registering.error?.fieldErrors?.password
                      ? "error-input"
                      : ""
                  }`}
                  name="confirm_password"
                  type="password"
                  placeholder="Confirm Password"
                  use:inputLbl
                />
              </div>
            </div>
          </div>
          <br />
          <Show when={registering.error?.fieldErrors?.password}>
            <div
              role="alert"
              class="error-message"
              innerHTML={registering.error.fieldErrors.password}
            ></div>
          </Show>
          <Show when={registering.error?.fieldErrors?.confirm}>
            <div
              role="alert"
              class="error-message"
              innerHTML={registering.error.fieldErrors.confirm}
            ></div>
          </Show>
          <div class="flex align-center gap-2">
            <input type="hidden" name="discount" value={discountCoupon()} />
            <input type="hidden" name="planQuantity" value={planQuantity()} />
            <input type="hidden" name="planName" value={planName()} />
            <input type="hidden" name="register_mode" value={mode()} />
            {/* <Show when={planName() == "Team" || planName() == "Team Annual"}>
              <div class="w-100 pr">
                <div
                  class={
                    planName() == "Team" ? "left-selected" : "right-selected"
                  }
                >
                  <Icons name="checkmark"></Icons>
                </div>
                <SelectOne
                  class="full-width"
                  set={[
                    {
                      display: `<div class="fs-24">${discountPrice.month}</div><div class="pt-10">per Month</div>`,
                      name: "Team",
                    },
                    {
                      display: `<div class="fs-24">${discountPrice.year}</div><div class="pt-10">per Year</div>`,
                      name: "Team Annual",
                    },
                  ]}
                  active={planName()}
                  setActive={(val: string) => {
                    setPlanName(val);
                    setSearchParams({ plan: val });
                  }}
                ></SelectOne>
              </div>
            </Show> */}
            {/* </div> */}
            {/* <div class="w-50 app-text-center">
              <button
                type="button"
                class="btn primary"
                onclick={() => {
                  setPopup("pricing");
                }}
              >
                Change Plan
              </button>
            </div> */}
          </div>
          <Show when={discountCoupon()}>
            <div class="text-right">
              <span class="lbl primary fs-12">*Discount coupon applied</span>
            </div>
          </Show>
          {/* <Show when={planName() != "Team" && planName() != "Team Annual"}>
            <div class="w-100 pr">
              <div class="left-selected">
                <Icons name="checkmark"></Icons>
              </div>
              <SelectOne
                class="full-width"
                set={customPlan()}
                isSingle={true}
                active={planName()}
                setActive={(val: string) => {}}
              ></SelectOne>
            </div>
            <div class="text-right">
              <span class="lbl primary fs-12">*Custom plan applied</span>
            </div>
            <div class="text-right">
              <span class="lbl primary fs-12">
                *Min {planQuantity()} quantity
              </span>
            </div>
          </Show> */}
          <br />
          <div class="app-text-center text-center">
            <Show when={!registering.pending}>
              <button class="btn primary" type="submit">
                {data() ? "Register" : ""}
              </button>
            </Show>
            <Show when={registering.pending}>
              <button class="btn disabled">Loading...</button>
            </Show>
          </div>
          <div class="clearfix"></div>
          <br />
          <p class="text-gray fw-500">
            Already have an account?{" "}
            <A class="a-link" href="/login">
              Login
            </A>
          </p>
        </Form>
      </AuthLayout>
    </main>
  );
}
