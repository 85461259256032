import { Component, onMount } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import DefaultLayout from "~/components/layouts/default.layout";
import SystemLayout from "~/components/layouts/system.layout";
import { getUser } from "~/db/session";
import { validateUser } from "./api/validateUser";

export function routeData() {
  return validateUser();
}

const landing: Component = () => {
  useRouteData<typeof routeData>();

  return (
    <div>
      <SystemLayout>
        <h2>Welcome to FormConnect ProMax</h2>
        Lorem ipsum dolor sit amet consectetur, adipisicing elit. Minus
        reprehenderit explicabo quibusdam, omnis ullam, fugiat tenetur mollitia
        placeat autem quis modi, ad sapiente quo temporibus quae quasi! Dolore,
        at debitis!
      </SystemLayout>
    </div>
  );
};

export default landing;
