import {
  Component,
  JSX,
  Show,
  children,
  createEffect,
  onMount,
} from "solid-js";
import Navbar, { isDropdownOpen, setIsDropdownOpen } from "../theme/Navbar";
import "~/assets/styles/style.scss";
import Sidebar, { sidebarList } from "../theme/Sidebar";
import Notification from "../global/Notification";
import {
  removeLastFomrPopup,
  selectedDropDown,
  setIsFormDropDownOpen,
  setSelectedDropDown,
  setSidebarOpen,
} from "~/store/global.store";

interface Props {
  children: JSX.Element;
  subscription?: any;
  contentClass?: string;
}

const systemLayout: Component<Props> = (props) => {
  createEffect(() => {
    setSidebarOpen(false);
  });
  onMount(() => {
    // Close popup/popover on esc
    document.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        removeLastFomrPopup();
      }
    });
  });
  const slot = children(() => props.children);
  return (
    <div
      onclick={(e: any) => {
        const element: any = document.getElementById(
          `formDropDown_${selectedDropDown()}`
        );
        const element2: any = document.getElementById("navbar_profile");
        const element4: any = document.getElementById("form_template");
        if (
          !element?.contains(e.target) &&
          !element2?.contains(e.target) &&
          !element4?.contains(e.target) &&
          selectedDropDown() != "user_add_edit" &&
          selectedDropDown() != "notification_types"
        ) {
          setSelectedDropDown("");
          setIsFormDropDownOpen(false);
        }
      }}
    >
      <Show when={sidebarList()?.length > 0}>
        <Navbar />
        <div class="main-body">
          <Sidebar />
          <div
            class={`main-content ${
              props.contentClass ? props.contentClass : ""
            }`}
          >
            {slot}
          </div>
        </div>
        <Notification />
      </Show>
    </div>
  );
};

export default systemLayout;
