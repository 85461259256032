import { Component, createEffect, createSignal, onMount, Show } from "solid-js";
import { useNavigate, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import UserForm from "./_form";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import {
  popupQueue,
  removePopup,
  setremainingLicences,
  setSelectedDropDown,
} from "~/store/global.store";
import { validateUser } from "../api/validateUser";
import fetchApi from "~/lib/api";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import { userSubscriptionStatus } from "~/store/user-form-role";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);
    const usersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        search_string: "",
        sort_string: "",
        user_skip: 0,
        user_take: 10,
      },
    };
    let users = await fetchApi(usersListApiParams);
    if (users?.statusCode == 401 || users?.statusCode == 403) {
      throw redirect("/logout");
    }
    let assignedLicences: any = users.data.assignedLicences;

    const subscriptionDetailsApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/details`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
      },
    };
    const subscriptionDetails = await fetchApi(subscriptionDetailsApiParams);
    if (subscriptionDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    let totalSubscriptionQuantity: any =
      subscriptionDetails.data?.purchasedLicences;

    const remainingLicences = assignedLicences < totalSubscriptionQuantity;
    return { user_id, jwt_token, remainingLicences };
  });
}

const userAdd: Component = () => {
  setPageTitle(`${SITE_TITLE} - Create New User`);
  const data: any = useRouteData<typeof routeData>();

  const navigate = useNavigate();

  createEffect(() => {
    if (data()?.remainingLicences) {
      setremainingLicences(data()?.remainingLicences);
    } else {
      setremainingLicences(
        userSubscriptionStatus() == "trialing" ? true : false
      );
    }
    if (data()?.subscriptionDetails?.statusCode == 403) {
      location.reload();
    }
  });

  return (
    <main>
      <Show when={popupQueue.includes("userAdd")}>
        <ConfirmPopup
          message="<b>User account created successfully. An email was sent to the user with their login credentials.</b>"
          name="userAdd"
          alert={true}
          onconfirm={(status: boolean) => {
            removePopup("userAdd");
            navigate("/users");
          }}
        ></ConfirmPopup>
      </Show>
      <SystemLayout>
        <div
          class="center h-100"
          onclick={(e: any) => {
            if (e.target.id != "user_role_dropdown") {
              setSelectedDropDown("");
            }
          }}
        >
          <div class="flex space-between">
            <h3 class="title color">Add User</h3>
          </div>
          <div class="mw-800">
            <UserForm
              user_id={data()?.user_id}
              admin_user_id={data()?.user_id}
              type="add"
              api_url={`${import.meta.env.VITE_API_URL}/user/add/new`}
              mode="web"
            />
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default userAdd;
