import { Component } from "solid-js";
import { createServerData$, redirect } from "solid-start/server";
import "~/assets/styles/landing.scss";
import { getUser } from "~/db/session";
import { validateUser } from "./api/validateUser";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import LoginComponent from "~/components/global/Login";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (
      userDetails !== null &&
      userDetails.data?.subscription &&
      userDetails.data?.subscription?.plan_name !== "" &&
      userDetails.data?.subscription?.state !== "cancelled"
    ) {
      return redirect("/form/list");
    } else {
      return redirect("/login");
    }
    return {};
  });
}

const features: Array<object> = [
  {
    title: "Intelligent Form Design",
    info: "Design once and deploy on multiple platforms (iPhone, iPad, and Mac devices).",
    image: "formDesign",
  },
  {
    title: "Form Sharing",
    info: "Share and export forms with other users.",
    image: "formSharing",
  },
  {
    title: "Photo Capture",
    info: "Take photos and insert them onto your form.",
    image: "photoCapture",
  },
  {
    title: "Annotate",
    info: "Draw and annotate on images added to your form.",
    image: "annotate",
  },
  {
    title: "Signature Signing",
    info: "Add signatures boxes to your form for customers to sign.",
    image: "signature",
  },
  {
    title: "Field Calculation",
    info: "Enter formulas to perform calculations.",
    image: "calculation",
  },
  {
    title: "Index Tabs",
    info: "Navigate between pages by placing tabs on the form.",
    image: "indexTabs",
  },
  {
    title: "Clipboard",
    info: "Store text for later insertion into a field.",
    image: "clipboard",
  },
  {
    title: "GPS",
    info: "Insert GPS latitude and longitude coordinates.",
    image: "gps",
  },
  {
    title: "Form Linking",
    info: "Link multiple forms together so one form can update another.",
    image: "formLinking",
  },
  {
    title: "Form Tabs",
    info: "View multiple forms via browser tabs.",
    image: "formTabs",
  },
  {
    title: "Cloud Syncing",
    info: "Sync your forms between your iPad, iPhone and Mac.",
    image: "cloudSyncing",
  },
];

const landing: Component = () => {
  setPageTitle(`${SITE_TITLE} - Login`);

  return (
    <>
      <LoginComponent></LoginComponent>
    </>
  );
};

export default landing;
