import { Component, Show, createEffect, createSignal } from "solid-js";
import Icons from "../icons/Icons";
import { useNavigate } from "solid-start";
import { setSubSettingPanel } from "../popup/FormSettingsPopup";
import messages from "~/helpers/message";
import fetchApi from "~/lib/api";
import { notify } from "../global/Notification";
import {
  selectedDropDown,
  setAlertMessage,
  setPopup,
  setSelectedDropDown,
} from "~/store/global.store";
import {
  formJson,
  isFormJsonUpdated,
  locationFormatUpdate,
  removedPages,
  setIsFormJsonUpdated,
  setLocationFormatUpdate,
  setRemovedPages,
} from "~/store/form.store";
import { formPageEvents } from "~/store/field-editor.store";
import { setIsDataSaving } from "../global/FormNavbar";
import {
  formRecords,
  setIsFormChangesCanceled,
  setRecordsList,
} from "~/store/records.store";
import {
  totalStorageUsed,
  totalUserLicences,
  userRole,
} from "~/store/user-form-role";

interface Props {
  jwtToken: string;
  formId: string;
  fieldsType: string;
  formData: any;
}

const [activeTheme, setActiveTheme] = createSignal("" as string);
const [pdfTextBoxEnabled, setPdfTextBoxEnabled] = createSignal(
  false as boolean
);
const [pdfRecordsEnabled, setPdfRecordsEnabled] = createSignal(
  false as boolean
);
const [embedImageEnabled, setEmbedImageEnabled] = createSignal(
  false as boolean
);
const [kioskModeEnabled, setKioskModeEnabled] = createSignal(false as boolean);
const [recordSortingDisabled, setRecordSortingDisabled] = createSignal(
  false as boolean
);
const [fontSize, setFontSize] = createSignal("14.5" as string);
const [paperSize, setPaperSize] = createSignal("" as string);
const [pageEvents, setPageEvents] = createSignal([] as any);

async function saveFormSettings(
  user_id: string,
  jwt_token: string,
  form_id: string
) {
  if (
    totalStorageUsed() >
    totalUserLicences() * Number(import.meta.env.VITE_FREE_PER_USER_STORAGE)
  ) {
    setIsFormChangesCanceled(true);
    setIsFormJsonUpdated(false);
    notify({
      isNotified: true,
      message: `<div>${messages.warn.storage_used}. ${
        userRole() === "admin"
          ? "&nbsp;&nbsp;<A class='btn sm primary' href='/billing-payments'>Check Storage Plans</A>"
          : "Contact Your Admin."
      }</div>`,
      theme: "warm",
      placement: "top",
      timeout: 4000,
    });
    return;
  }
  let structure: any = {
    ...formJson.root.formStructure,
    pages: formJson.root.formStructure.pages.filter(
      (page: any) => Object.keys(page).length > 0
    ),
  };
  const fields: any = formJson.pages.flatMap((page: any) => page.fields);
  let index: any = fields.findIndex((field: any) => field.type == "TextField");
  if (index == -1) {
    index = fields.findIndex(
      (field: any) =>
        field.type == "TextField" ||
        field.type == "DateField" ||
        field.type == "DropdownField" ||
        field.type == "LocationField" ||
        field.type == "BarcodeField" ||
        field.type == "RadioField"
    );
  }
  if (index != -1) {
    if (formJson.root.formStructure.primaryFields.length == 0) {
      structure = {
        ...structure,
        primaryFields: [fields[index].properties.dataFieldSync.uid],
      };
      setIsFormJsonUpdated(true);
    }
    if (
      formJson.root.formStructure?.sortFields &&
      formJson.root.formStructure?.sortFields?.length == 0
    ) {
      structure = {
        ...structure,
        sortFields: [fields[index].properties.dataFieldSync.uid],
      };
    }
  }
  structure.lastSaved = new Date().toISOString();
  let message: string = messages.warn.update_form_layout;
  let theme: string = "warm";
  if (isFormJsonUpdated()) {
    setIsDataSaving(true);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/structure/update`,
      jwt_token,
      body: {
        user_id,
        form_id: structure?.uuid,
        structure,
        device_type: "web",
        pages_update: true,
        removed_pages: removedPages().join(","),
      },
    };
    let data = await fetchApi(apiParams);
    if (data?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (data?.statusCode == 403) {
      location.reload();
    }
    if (data.status) {
      if (Object.keys(locationFormatUpdate()).length > 0) {
        const apiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/location/records/update`,
          jwt_token,
          body: {
            user_id,
            form_id: structure?.uuid,
            field_id: locationFormatUpdate().field_id,
            old_format: locationFormatUpdate().old_format,
            format: locationFormatUpdate().format,
          },
        };
        let data = await fetchApi(apiParams);
        if (data?.statusCode == 401 || data?.statusCode == 403) {
          window.location.href = "/logout";
        }
        if (data && data.status) {
          setLocationFormatUpdate({});
          setRecordsList([]);
        }
      }
      setRemovedPages([]);
      theme = "success";
      message = messages.form.update_form_layout;
      setTimeout(() => {
        setIsDataSaving(false);
      }, 100);
    } else {
      theme = "error";
      message = messages.error.update_form_layout;
    }
  }
  setIsFormJsonUpdated(false);
  notify({
    isNotified: true,
    message,
    theme,
    placement: "top",
    timeout: 2500,
  });
}

const FormSettings: Component<Props> = (props) => {
  const navigate = useNavigate();

  createEffect(() => {
    if (props.formData.theme) {
      setActiveTheme(props.formData.theme);
    }
    if (props.formData.isPDFTextBoxEnabled) {
      setPdfTextBoxEnabled(props.formData.isPDFTextBoxEnabled);
    }
    if (props.formData.isPDFRecordsEnabled) {
      setPdfRecordsEnabled(props.formData.isPDFRecordsEnabled);
    }
    if (props.formData.isEmbedImageEnabled) {
      setEmbedImageEnabled(props.formData.isEmbedImageEnabled);
    }
    if (props.formData.isKioskModeEnabled) {
      setKioskModeEnabled(props.formData.isKioskModeEnabled);
    }
    if (props.formData.isRecordSortingDisabled) {
      setRecordSortingDisabled(props.formData.isRecordSortingDisabled);
    }
    if (props.formData.defaultFontSize) {
      setFontSize(props.formData.defaultFontSize);
    }
    if (props.formData.paperSize) {
      setPaperSize(props.formData.paperSize);
    }
    if (formJson.pages.length > 1) {
      setPageEvents(formPageEvents);
    } else {
      setPageEvents(
        formPageEvents.filter((obj: any) => obj.value != "remove_page")
      );
    }
  });

  function addFormRecords() {
    let requiredFieldAlert: string = "";
    let minMaxFieldAlert: string = "";
    formJson.pages.forEach((page: any) => {
      page.fields?.forEach((field: any) => {
        if (
          field.properties.dataFieldSync.isRequired &&
          formRecords()[field.properties.dataFieldSync.uid] == ""
        ) {
          requiredFieldAlert +=
            "<li>" +
            field.properties.dataFieldSync.label.replace(/:$/, "") +
            "</li>";
        }
        if (
          field.properties.dataFieldSync?.minimum &&
          formRecords()[field.properties.dataFieldSync.uid] != "" &&
          (formRecords()[field.properties.dataFieldSync.uid] <
            Number(field.properties.dataFieldSync?.minimum) ||
            formRecords()[field.properties.dataFieldSync.uid] >
              Number(field.properties.dataFieldSync?.maximum))
        ) {
          minMaxFieldAlert = `<b>Incorrect field value:</b><br/>${field.properties.dataFieldSync.label.replace(
            /:$/,
            ""
          )} must be between ${field.properties.dataFieldSync?.minimum} and ${
            field.properties.dataFieldSync?.maximum
          }`;
        }
      });
    });
    if (requiredFieldAlert != "") {
      setPopup("alert");
      setAlertMessage(
        `<b>Fields are required:</b><ul class="mb-0">${requiredFieldAlert}</ul>`
      );
    }

    if (minMaxFieldAlert != "") {
      setPopup("alert");
      setAlertMessage(minMaxFieldAlert);
    }
  }

  return (
    <>
      <Show when={props.fieldsType == "render"}>
        <div class="form-settings-bar">
          <div class="flex gap flex-column align-center">
            <Show
              when={
                props.formData?.enabledToolbarIcons[0]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[0]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="share"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Export</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[1]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[1]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="clipboard"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Clipboard</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[3]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[3]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="analyticsFile"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Report Writer</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[5]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[5]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="lock"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Record Lock</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[6]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[6]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="tag"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Tag</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[7]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[7]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="trash"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Delete Record</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[8]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[8]?.isEnabled == true
              }
            >
              <div class="form-settings-bar-icons disabled">
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="files"
                    stroke="white"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="text-white">Clone Record</span>
                </div>
              </div>
            </Show>
            <Show
              when={
                props.formData?.enabledToolbarIcons[9]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[9]?.isEnabled == true
              }
            >
              <div
                class="form-settings-bar-icons"
                onclick={() => addFormRecords()}
              >
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="plus"
                    stroke="hsl(214, 56%, 41%)"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="color lbl">Add Record</span>
                </div>
              </div>
            </Show>
          </div>
        </div>
      </Show>
      <Show when={props.fieldsType == "designer"}>
        <div class="form-settings-bar">
          <div class="flex gap flex-column align-center">
            <Show
              when={
                props.formData?.enabledToolbarIcons[10]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[10]?.isEnabled == true
              }
            >
              <div
                class="form-settings-bar-icons"
                onclick={() => {
                  setPopup("FieldLinker");
                }}
              >
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="link"
                    stroke="hsl(214, 56%, 41%)"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="color lbl">Field Linker</span>
                </div>
              </div>
            </Show>
            <div
              class="form-settings-bar-icons"
              onclick={() => {
                setPopup("formSettings");
                setSubSettingPanel("");
              }}
            >
              <div class="flex gap-0_5 align-center">
                <Icons
                  name="settings"
                  stroke="hsl(214, 56%, 41%)"
                  width={25}
                  height={25}
                ></Icons>
                <span class="color lbl">Form Settings</span>
              </div>
            </div>
            <Show
              when={
                props.formData?.enabledToolbarIcons[12]?.isEnabled ==
                  undefined ||
                props.formData?.enabledToolbarIcons[12]?.isEnabled == true
              }
            >
              <div
                class="form-settings-bar-icons"
                onclick={() =>
                  selectedDropDown() == ""
                    ? setSelectedDropDown("add_page")
                    : setSelectedDropDown("")
                }
              >
                <div class="flex gap-0_5 align-center">
                  <Icons
                    name="file"
                    stroke="hsl(214, 56%, 41%)"
                    width={25}
                    height={25}
                  ></Icons>
                  <span class="color lbl">Add/Remove Page</span>
                </div>
              </div>
            </Show>
          </div>
        </div>
      </Show>
    </>
  );
};

export {
  activeTheme,
  setActiveTheme,
  pdfTextBoxEnabled,
  setPdfTextBoxEnabled,
  pdfRecordsEnabled,
  setPdfRecordsEnabled,
  embedImageEnabled,
  setEmbedImageEnabled,
  kioskModeEnabled,
  setKioskModeEnabled,
  recordSortingDisabled,
  setRecordSortingDisabled,
  fontSize,
  setFontSize,
  paperSize,
  setPaperSize,
  saveFormSettings,
};
export default FormSettings;
