import { mount, StartClient } from "solid-start/entry-client";
import "firebase/compat/messaging";
import { getMessaging, onMessage } from "firebase/messaging";
import * as Sentry from "@sentry/solid";

if (process.env.VITE_ENABLE_SENTRY == "true") {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

if ("serviceWorker" in navigator) {
  const messaging = getMessaging();
  onMessage(messaging, (payload: any) => {
    const noteTitle = payload.notification.title;
    const noteOption = {
      body: payload.notification.body,
      icon: payload.notification.image,
    };
    new Notification(noteTitle, noteOption);
  });
}

mount(() => {
  return <StartClient />;
}, document);
