import { Component, createSignal, For, onMount, Show } from "solid-js";
import Popup from "../global/Popup";
import FormField from "../fields/FormField";
import {
  selectedFormRecords,
  selectedPreviewTemplate,
} from "../global/LibraryTemplates";
import fetchApi from "~/lib/api";
import messages from "~/helpers/message";
import { notify } from "../global/Notification";
import { useNavigate } from "solid-start";
import {
  isBuilderPageDropdownOpen,
  removePopup,
  setFormsList,
  setIsBuilderPageDropdownOpen,
} from "~/store/global.store";
import convert from "~/helpers/convertor";
import { FRAME_HEIGHT, FRAME_WIDTH } from "~/helpers/constant";
import Icons from "../icons/Icons";
import { isDataSaving, setIsDataSaving } from "../global/FormNavbar";
import {
  totalStorageUsed,
  totalUserLicences,
  userRole,
} from "~/store/user-form-role";

interface Props {
  user_id: string;
  jwt_token: string;
  setValue?: any;
  adminPanel?: boolean;
}

const FormPreviewPopup: Component<Props> = (props) => {
  const navigate = useNavigate();

  const [previewCurrentPage, setPreviewCurrentPage] = createSignal(1 as number);

  async function cloneTemplate() {
    if (
      totalStorageUsed() >
      totalUserLicences() * Number(import.meta.env.VITE_FREE_PER_USER_STORAGE)
    ) {
      notify({
        isNotified: true,
        message: `<div>${messages.warn.storage_used}. ${
          userRole() === "admin"
            ? "&nbsp;&nbsp;<A class='btn sm primary' href='/billing-payments'>Check Storage Plans</A>"
            : "Contact Your Admin."
        }</div>`,
        theme: "warm",
        placement: "top",
        timeout: 4000,
      });
      removePopup("FormPreview");
      return;
    }
    setIsDataSaving(true);
    const element: any = document.getElementById("add_form_library");
    element?.blur();
    const structure: any = JSON.parse(selectedPreviewTemplate());
    structure.lastSaved = new Date().toISOString();
    const records: any = selectedFormRecords();
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/structure/add`,
      jwt_token: props.jwt_token as string,
      body: {
        description: "",
        structure: JSON.stringify(structure),
        admin_user_id: props.user_id,
        user_id: props.user_id,
        form_id: structure.uuid,
        device_type: "web",
      },
    };
    const designForm = await fetchApi(apiParams);
    if (designForm?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (designForm?.statusCode == 403) {
      location.reload();
    }
    let message: any = messages.error.form_design;
    let theme: any = "danger";
    if (designForm.status) {
      const userFormRightsApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/user/add/rights`,
        jwt_token: props.jwt_token as string,
        body: { shared_users: props.user_id, form_id: designForm.data.id },
      };
      const formAdminRight = await fetchApi(userFormRightsApiParams);
      if (formAdminRight?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (formAdminRight?.statusCode == 403) {
        location.reload();
      }
      if (formAdminRight.status) {
        if (records) {
          const apiParams = {
            method: "POST" as string,
            url: `${import.meta.env.VITE_API_URL}/form/records/add/update`,
            jwt_token: props.jwt_token as string,
            body: {
              structure: records,
              user_id: props.user_id,
              form_id: structure.uuid,
              device_type: "web",
            },
          };
          const formRecords = await fetchApi(apiParams);
          if (formRecords?.statusCode == 401) {
            window.location.href = "/logout";
          } else if (formRecords?.statusCode == 403) {
            location.reload();
          }
          if (formRecords.status) {
            setFormsList([]);
            removePopup("FormPreview");
            message = messages.form.design;
            theme = "success";
            navigate("/form/list");
            setIsDataSaving(false);
          }
        } else {
          setFormsList([]);
          removePopup("FormPreview");
          message = messages.form.design;
          theme = "success";
          navigate("/form/list");
          setIsDataSaving(false);
        }
      }
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    }
  }

  return (
    <>
      <Show when={selectedPreviewTemplate()}>
        <Popup
          heading={`Form Preview - ${
            JSON.parse(selectedPreviewTemplate())?.name
          }`}
          headerPosition="center"
          classes="medium"
          name="FormPreview"
        >
          <div>
            <div
              class="form-frame preview"
              style={{
                "--width": Number(convert(FRAME_WIDTH, "in-px")) + "px",
                "--height": Number(convert(FRAME_HEIGHT, "in-px")) + "px",
              }}
            >
              <Show when={selectedPreviewTemplate()}>
                <For
                  each={
                    JSON.parse(selectedPreviewTemplate()).pages[
                      previewCurrentPage() - 1
                    ].fields
                  }
                >
                  {(field) => (
                    <>
                      <FormField
                        data={field}
                        fieldsType="preview"
                        jwt_token={props.jwt_token}
                      />
                    </>
                  )}
                </For>
              </Show>
            </div>
            <br />
            <div class="preview-form-pagination">
              <div class="flex align-center gap">
                <Show when={previewCurrentPage() != 1}>
                  <span
                    class="mt-4 cur-p"
                    onclick={() => {
                      setPreviewCurrentPage(previewCurrentPage() - 1);
                    }}
                  >
                    <Icons
                      name="boldArrowLeft"
                      stroke="#525657"
                      width={26}
                      height={26}
                    ></Icons>
                  </span>
                </Show>
                <span class="pr">
                  <label
                    class="lbl fs-14 cur-p user-select-none builder-pagination-dropdown"
                    onclick={() => {
                      setIsBuilderPageDropdownOpen(
                        isBuilderPageDropdownOpen() ? false : true
                      );
                    }}
                  >
                    Page {previewCurrentPage()}
                  </label>
                  <Show when={isBuilderPageDropdownOpen()}>
                    <div class="dropdown builder-pagination">
                      <span class="dropdown-caret"></span>
                      <ul class="dropdown-list">
                        <For
                          each={JSON.parse(selectedPreviewTemplate()).pages.map(
                            (page: any, key: number) => key + 1
                          )}
                        >
                          {(item: any) => (
                            <li
                              class={`dropdown-items ${
                                item == previewCurrentPage() ? "active" : ""
                              }`}
                            >
                              <a
                                onclick={() => {
                                  setPreviewCurrentPage(item);
                                  setIsBuilderPageDropdownOpen(false);
                                }}
                              >{`Page ${item}`}</a>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </Show>
                </span>
                <Show
                  when={
                    previewCurrentPage() !=
                    JSON.parse(selectedPreviewTemplate()).pages.length
                  }
                >
                  <span
                    class="mt-4 cur-p"
                    onclick={() => {
                      setPreviewCurrentPage(previewCurrentPage() + 1);
                    }}
                  >
                    <Icons
                      name="boldArrowRight"
                      stroke="#525657"
                      width={26}
                      height={26}
                    ></Icons>
                  </span>
                </Show>
              </div>
              <Show when={!props.adminPanel}>
                <div class="text-center form-preview-btn">
                  <button
                    id="add_form_library"
                    class={`btn primary ${isDataSaving() ? "disabled" : ""}`}
                    onclick={() => cloneTemplate()}
                  >
                    <Show when={!isDataSaving()}>Add to Forms Library</Show>
                    <Show when={isDataSaving()}>
                      Adding to my forms&nbsp;<Icons name="loader"></Icons>
                    </Show>
                  </button>
                </div>
              </Show>
            </div>
          </div>
        </Popup>
      </Show>
    </>
  );
};

export default FormPreviewPopup;
