import { Component, Show, createEffect } from "solid-js";
import { createStore } from "solid-js/store";
import { Transition } from "solid-transition-group";
import { NotificationType } from "~/types/definition";

const [notificationStack, setNotificationStack] = createStore({
  isNotified: false,
  message: "",
  theme: "success",
  placement: "bottom",
  timeout: 1500,
} as NotificationType);

function notify(notificationObj: NotificationType) {
  setNotificationStack({
    isNotified: notificationObj.isNotified,
    message: notificationObj.message,
    theme: notificationObj.theme ? notificationObj.theme : "success",
    placement: notificationObj.placement ? notificationObj.placement : "bottom",
    timeout: notificationObj.timeout ? notificationObj.timeout : 1500,
  });
}

const Notification: Component = () => {
  createEffect(() => {
    if (notificationStack.isNotified) {
      setTimeout(() => {
        setNotificationStack({
          isNotified: false,
          message: "",
        });
      }, notificationStack.timeout);
    }
  });

  return (
    <Transition name="slide-fade">
      <Show when={notificationStack.isNotified}>
        <div
          class={`notification ${notificationStack?.placement} ${notificationStack?.theme}`}
        >
          <span
            class="notification-text"
            innerHTML={notificationStack?.message}
          ></span>
        </div>
      </Show>
    </Transition>
  );
};

export { notify };
export default Notification;
