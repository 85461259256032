import { Component, For, Show, createEffect, createSignal } from "solid-js";
import { A, useNavigate, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import fetchApi from "~/lib/api";
import { getAuthToken, getUser } from "~/db/session";
import SystemLayout from "~/components/layouts/system.layout";
import { validateUser } from "../api/validateUser";
import Dropdown from "~/components/global/Dropdown";
import {
  getInitails,
  notificationCategories,
  notificationTypes,
} from "~/store/global.store";
import Icons from "~/components/icons/Icons";
import { format } from "date-fns";
import Pagination, {
  currentPage,
  setCurrentPage,
} from "~/components/global/Pagination";
import { notify } from "~/components/global/Notification";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import {
  setUnReadNotificationCount,
  unReadNotificationCount,
} from "~/store/user-form-role";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);
    const notificationApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/notification/list`,
      jwt_token,
      body: {
        user_id,
        status: "Unread",
        category: "",
        skip: 0,
        take: 10,
      },
    };
    let notificationData = await fetchApi(notificationApiParams);
    if (
      notificationData?.statusCode == 401 ||
      notificationData?.statusCode == 403
    ) {
      throw redirect("/logout");
    }
    notificationData = notificationData.data;
    return { user_id, jwt_token, notificationData };
  });
}

const landing: Component = () => {
  setPageTitle(`${SITE_TITLE} - Notifications`);
  const data = useRouteData<typeof routeData>();
  const navigate = useNavigate();

  const [notificationsHistoryList, setNotificationsHistoryList] = createSignal(
    [] as any
  );
  const [notificationsHistoryTotalCount, setNotificationsHistoryTotalCount] =
    createSignal(0 as number);
  const [skip, setSkip] = createSignal(0 as number);

  const [openDropdownName, setOpenDropdownName] = createSignal("" as any);
  const [typeDropdownValue, setTypeDropdownValue] = createSignal(
    "Unread" as any
  );
  const [categoryDropdownValue, setCategoryDropdownValue] = createSignal(
    "" as any
  );
  const [selectedNotificationDate, setSelectedNotificationDate] = createSignal(
    "" as string
  );

  createEffect(() => {
    if (data()?.notificationData) {
      setNotificationsHistoryList(data()?.notificationData.notifications);
      setNotificationsHistoryTotalCount(
        data()?.notificationData.notificationCount
      );
    }
  });

  async function fetchNotificationData(user_id: string, jwt_token: string) {
    const notificationApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/notification/list`,
      jwt_token,
      body: {
        user_id,
        status: typeDropdownValue(),
        // category: categoryDropdownValue(),
        category: "",
        skip: skip(),
        take: 10,
      },
    };
    let notificationData = await fetchApi(notificationApiParams);
    if (notificationData?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (notificationData?.statusCode == 403) {
      location.reload();
    }
    if (notificationData && notificationData.status) {
      setNotificationsHistoryList(notificationData.data.notifications);
      setNotificationsHistoryTotalCount(
        notificationData.data.notificationCount
      );
    }
  }

  async function readUserNotification(
    jwt_token: string,
    notification_id: string,
    notification_action: string
  ) {
    const notificationReadApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/notification/read`,
      jwt_token,
      body: {
        notification_id,
      },
    };
    let notificationRead = await fetchApi(notificationReadApiParams);
    if (notificationRead && notificationRead.status) {
      setUnReadNotificationCount(unReadNotificationCount() - 1);
      if (typeDropdownValue() == "Unread") {
        setNotificationsHistoryList(
          notificationsHistoryList().filter(
            (notification: any) => notification.id != notification_id
          )
        );
      } else {
        setNotificationsHistoryList(
          notificationsHistoryList().map((notification: any) =>
            notification.id == notification_id
              ? { ...notification, is_read: true }
              : notification
          )
        );
      }
      if (notification_action) {
        navigate(`/${notification_action}`);
      }
    }
    if (
      notificationRead?.statusCode == 401 ||
      notificationRead?.statusCode == 403
    ) {
      window.location.href = "/logout";
    }
  }

  async function deleteUserNotification(
    jwt_token: string,
    notification_id: string
  ) {
    const notificationDeleteApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/notification/delete`,
      jwt_token,
      body: {
        notification_id,
      },
    };
    let notificationDelete = await fetchApi(notificationDeleteApiParams);
    if (notificationDelete && notificationDelete.status) {
      setNotificationsHistoryList(
        notificationsHistoryList().filter(
          (notification: any) => notification.id != notification_id
        )
      );
    }
    if (
      notificationDelete?.statusCode == 401 ||
      notificationDelete?.statusCode == 403
    ) {
      window.location.href = "/logout";
    }
  }

  async function readAllUserNotifications(user_id: string, jwt_token: string) {
    const notificationReadApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/notification/read-all`,
      jwt_token,
      body: {
        user_id,
      },
    };
    let allNotificationRead = await fetchApi(notificationReadApiParams);
    if (allNotificationRead?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (allNotificationRead?.statusCode == 403) {
      location.reload();
    }
    let message: string = "Error";
    let theme: string = "error";
    if (allNotificationRead && allNotificationRead.status) {
      setUnReadNotificationCount(
        unReadNotificationCount() - notificationsHistoryList().length
      );
      fetchNotificationData(user_id, jwt_token);
      message = "Success";
      theme = "success";
    }
    notify({
      isNotified: true,
      message,
      theme,
      placement: "top",
      timeout: 2500,
    });
  }

  return (
    <main>
      <SystemLayout>
        <div
          onclick={(e: any) => {
            if (
              e.target.id !== "notification_type_dropdown" &&
              e.target.id !== "notification_category_dropdown"
            ) {
              setOpenDropdownName("");
            }
          }}
        >
          <input type="hidden" name="data" value={data()?.user_id} />
          <div class="flex space-between">
            <h2 class="title color">Notifications</h2>
            <div class="app-flex flex gap align-center w-75 justify-end">
              {/* <Show when={typeDropdownValue() != "Read"}>
                <button
                  class="btn success app-w-50"
                  onclick={() =>
                    readAllUserNotifications(data()?.user_id, data()?.jwt_token)
                  }
                >
                  <Icons name="readNotification" stroke="#fff"></Icons>&nbsp;
                  Mark all as read
                </button>
              </Show> */}
              {/* <div class="flex gap-0_5 align-center w-28">
                <label class="lbl">Category:</label>
                <div class="pr w-100">
                  <div
                    class="user-role-dropdown cur-p br-unset user-select-none"
                    id="notification_category_dropdown"
                    onclick={() => {
                      openDropdownName() != "notification_catrgories"
                        ? setOpenDropdownName("notification_catrgories")
                        : setOpenDropdownName("");
                    }}
                  >
                    <span
                      class="dropdown-div lbl"
                      id="notification_category_dropdown"
                    >
                      {categoryDropdownValue()
                        ? categoryDropdownValue()
                        : "ALL"}
                    </span>
                    <Icons
                      id="notification_category_dropdown"
                      name="chevronDown"
                      stroke="#6c6e6f"
                    ></Icons>
                  </div>
                  <Show when={openDropdownName() == "notification_catrgories"}>
                    <div class="pr">
                      <Dropdown
                        list={notificationCategories}
                        title="label"
                        link="link"
                        class="w-100"
                        selectedValue={
                          categoryDropdownValue()
                            ? categoryDropdownValue()
                            : "ALL"
                        }
                        isFormDropdown={true}
                        setValue={(val: any) => {
                          setOpenDropdownName("");
                          setCategoryDropdownValue(val);
                          setCurrentPage(1);
                          fetchNotificationData(
                            data()?.user_id,
                            data()?.jwt_token
                          );
                        }}
                      />
                    </div>
                  </Show>
                </div>
              </div> */}
              <div class="flex gap-0_5 align-center w-28">
                {/* <label class="lbl">Type:</label> */}
                {/* <div class="pr w-100">
                  <div
                    class="user-role-dropdown cur-p br-unset user-select-none"
                    id="notification_type_dropdown"
                    onclick={() => {
                      openDropdownName() != "notification_types"
                        ? setOpenDropdownName("notification_types")
                        : setOpenDropdownName("");
                    }}
                  >
                    <span
                      class="dropdown-div lbl"
                      id="notification_type_dropdown"
                    >
                      {typeDropdownValue() == "Unread"
                        ? "New Notifications"
                        : "Viewed Notifications"}
                    </span>
                    <Icons
                      id="notification_type_dropdown"
                      name="chevronDown"
                      stroke="#6c6e6f"
                    ></Icons>
                  </div>
                  <Show when={openDropdownName() == "notification_types"}>
                    <div class="pr">
                      <Dropdown
                        list={notificationTypes}
                        title="label"
                        link="link"
                        class="w-100"
                        selectedValue={
                          typeDropdownValue() ? typeDropdownValue() : "ALL"
                        }
                        isFormDropdown={true}
                        setValue={(val: any) => {
                          setOpenDropdownName("");
                          setTypeDropdownValue(val);
                          setCurrentPage(1);
                          fetchNotificationData(
                            data()?.user_id,
                            data()?.jwt_token
                          );
                        }}
                      />
                    </div>
                  </Show>
                </div> */}
              </div>
            </div>
          </div>
          <div class="mt-4">
            <table class="table notifications-table w-100">
              {/* <thead>
                <tr>
                  <th class="main">Detail</th>
                  <th class="w-12">Action</th>
                </tr>
              </thead> */}
              <tbody>
                <For each={notificationsHistoryList()}>
                  {(notification: any) => (
                    <tr
                      class={notification.is_read ? "read" : ""}
                      onclick={async () => {
                        if (!notification.is_read) {
                          await readUserNotification(
                            data()?.jwt_token,
                            notification.id,
                            notification.action
                          );
                        }
                      }}
                    >
                      <td class="main">
                        <div class="app-flex flex gap align-center">
                          <div class="w-10">
                            <Show when={notification.avatar}>
                              <img
                                src={notification.avatar}
                                class="notification-history-logo"
                              />
                            </Show>
                            <Show when={!notification.avatar}>
                              <div class="notification-history-logo">
                                {getInitails(
                                  notification.sender.user_profile[0]
                                    ?.first_name +
                                    " " +
                                    notification.sender.user_profile[0]
                                      ?.last_name
                                )}
                              </div>
                            </Show>
                          </div>
                          <div class="w-100">
                            {/* <div class="flex gap">
                              <h3
                                class={`lbl m-0 p-0 ${
                                  notification.is_read ? "read" : ""
                                }`}
                              >
                                {notification.sender.user_profile[0]
                                  ?.first_name +
                                  " " +
                                  notification.sender.user_profile[0]
                                    ?.last_name}
                              </h3>
                              <span
                                class={`badge ${
                                  notification.is_read ? "gray" : "primary"
                                }`}
                              >
                                {format(
                                  new Date(notification.created_at),
                                  "dd MMMM yyyy | HH:mm"
                                )}
                              </span>
                            </div> */}
                            <div class="flex space-between w-100">
                              <div
                                class={`w-75 ${
                                  notification.is_read ? "read" : ""
                                }`}
                                innerHTML={notification.message}
                              ></div>
                              <div
                                class={`badge md h-fit ${
                                  notification.is_read ? "gray" : "selected"
                                }`}
                                // onmouseover={() =>
                                //   setSelectedNotificationDate(notification.id)
                                // }
                                // onmouseout={() =>
                                //   setSelectedNotificationDate("")
                                // }
                              >
                                {/* <Show
                                  when={
                                    selectedNotificationDate() ==
                                    notification.id
                                  }
                                > */}
                                <div
                                  class="notification-badge"
                                  data-title={format(
                                    new Date(notification.created_at),
                                    "dd MMMM yyyy | HH:mm"
                                  )}
                                >
                                  {format(
                                    new Date(notification.created_at),
                                    "d MMMM yyyy | h:m a"
                                  )}
                                </div>
                                {/* </Show>
                                <Show
                                  when={
                                    selectedNotificationDate() !=
                                    notification.id
                                  }
                                >
                                  {format(
                                    new Date(notification.created_at),
                                    "dd MMMM yyyy"
                                  )}
                                </Show> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="flex gap-0_5">
                          {/* <Show when={!notification.is_read}>
                            <button
                              class="btn sm success"
                              onclick={async () => {
                                await readUserNotification(
                                  data()?.jwt_token,
                                  notification.id
                                );
                              }}
                            >
                              <Icons
                                name="readNotification"
                                stroke="#fff"
                              ></Icons>
                            </button>
                          </Show> */}
                          <Show when={notification.action}>
                            <A
                              href={`${import.meta.env.VITE_APP_URL}/${
                                notification.action
                              }`}
                              class="btn sm primary"
                            >
                              <Icons
                                name="eye"
                                stroke="#fff"
                                width={20}
                                height={20}
                              ></Icons>
                              &nbsp;Open
                            </A>
                          </Show>
                          <Show when={notification.is_read}>
                            <button
                              class="btn sm danger"
                              onclick={async () => {
                                await deleteUserNotification(
                                  data()?.jwt_token,
                                  notification.id
                                );
                              }}
                            >
                              <Icons name="trash" stroke="#fff"></Icons>
                            </button>
                          </Show>
                        </div>
                      </td>
                    </tr>
                  )}
                </For>
                <Show when={notificationsHistoryList().length == 0}>
                  <tr>
                    <td colspan={2}>
                      <div class="lbl text-center">
                        No Notification Data Found !!
                      </div>
                    </td>
                  </tr>
                </Show>
              </tbody>
            </table>
          </div>
          <Show when={notificationsHistoryList().length != 0}>
            <Pagination
              currentPage={currentPage()}
              totalPages={Math.ceil(notificationsHistoryTotalCount() / 10)}
              onPageChange={(page: any) => {
                setCurrentPage(page);
                setSkip((page - 1) * 10);
                fetchNotificationData(data()?.user_id, data()?.jwt_token);
              }}
              itemsPerPage="10"
              totalItems=""
              itemPerPageFilter={true}
            ></Pagination>
          </Show>
        </div>
      </SystemLayout>
    </main>
  );
};

export default landing;
