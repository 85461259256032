import { Component, Show, createEffect, createSignal, onMount } from "solid-js";
import { FormError, useRouteData } from "solid-start";
import {
  createServerAction$,
  createServerData$,
  redirect,
} from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser, storage } from "~/db/session";
import fetchApi from "~/lib/api";
import blurInput from "~/directives/blur-input.directive";
import messages from "~/helpers/message";
import { notify } from "~/components/global/Notification";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import {
  setJwtToken,
  setUserAvatar,
  userAvatar,
  userRole,
} from "~/store/user-form-role";
import Icons from "~/components/icons/Icons";
import { validateUser } from "./api/validateUser";
import { getInitails, removePopup, setPopup } from "~/store/global.store";
import { validateEmail } from "~/helpers/validators";
import ConfirmPopup from "~/components/popup/ConfirmPopup";

const inputLbl = blurInput;

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    if (userDetails && userDetails?.status) {
      const user_id = userDetails.data.id;
      const jwt_token: any = await getAuthToken(request);
      const userProfileApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/admin/user/profile`,
        jwt_token: jwt_token as string,
        body: { user_id: user_id, is_just_profile: true },
      };
      let userInfo = await fetchApi(userProfileApiParams);
      if (userInfo?.statusCode == 401 || userInfo?.statusCode == 403) {
        throw redirect("/logout");
      }
      userInfo = userInfo.data;
      return { user_id, jwt_token, userInfo };
    } else {
      const oldSession = await storage.getSession(
        request.headers.get("Cookie")
      );
      return redirect("/login", {
        headers: {
          "Set-Cookie": await storage.destroySession(oldSession),
        },
      });
    }
  });
}

const profile: Component = () => {
  setPageTitle(`${SITE_TITLE} - Profile`);
  const data: any = useRouteData<typeof routeData>();
  const [selectedFile, setSelectedFile] = createSignal(null as any);
  const [currentEmail, setCurrentEmail] = createSignal("" as string);

  createEffect(() => {
    if (data()?.userInfo?.avatar != "") {
      setSelectedFile(data()?.userInfo?.avatar);
    }
    if (data()?.userInfo?.email) {
      setCurrentEmail(data()?.userInfo?.email);
    }
  });

  const [profileUpdate, { Form }] = createServerAction$(
    async (form: FormData, { request }) => {
      const email: any = form.get("email");
      form.delete("email");
      let fieldErrors: any = {
        email: validateEmail(email),
      };
      const fields: any = { email };
      if (Object.values(fieldErrors).some(Boolean)) {
        throw new FormError("Fields invalid", { fieldErrors, fields });
      } else {
        const jwtToken = await getAuthToken(request);
        const updateProfile = await fetch(
          `${import.meta.env.VITE_API_URL}/admin/user/profile/update`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
            credentials: "include",
            body: form,
          }
        );
        if (
          updateProfile &&
          updateProfile.status &&
          updateProfile.status != 401
        ) {
          if (form.get("current_email") != email) {
            form.delete("avatar");
            form.delete("first_name");
            form.delete("last_name");
            form.delete("current_email");
            form.delete("company_name");
            const updateEmail = await fetch(
              `${import.meta.env.VITE_API_URL}/auth/email/update`,
              {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                  user_id: form.get("user_id"),
                  email: email,
                  device_type: "web",
                }),
              }
            )
              .then((res) => res.json())
              .catch((err) => {
                window.location.href = "/logout";
              });
            if (updateEmail && updateEmail?.status) {
              const paddleApiEndPoint: any = process.env.PADDLE_ENDPOINT;
              const vendor_auth_code: any = process.env.PADDLE_VENDOR_AUTH_CODE;
              let url = `${paddleApiEndPoint}/customers/${form.get(
                "customer_id"
              )}`;
              let options: any = {
                method: "PATCH",
                headers: {
                  Authorization: `Bearer ${vendor_auth_code}`,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  email,
                }),
              };
              const profileUpdateResponse = await fetch(url, options);
              const profile = await profileUpdateResponse.json();
              return updateEmail.data.token;
            } else {
              return "error";
            }
          }
          return "success";
        } else {
          return "error";
        }
      }
    }
  );

  createEffect(() => {
    if (profileUpdate.result) {
      const predictResult = {
        success: {
          res: messages.user.profile_update,
          theme: "success",
        },
        error: { res: messages.error.profile_update, theme: "danger" },
      };
      notify({
        isNotified: true,
        message: predictResult[
          profileUpdate.result != "error" ? "success" : "error"
        ].res as string,
        theme: predictResult[
          profileUpdate.result != "error" ? "success" : "error"
        ].theme as string,
        placement: "top",
        timeout: 2500,
      });
      if (
        profileUpdate.result != "success" ||
        profileUpdate.result != "error"
      ) {
        setJwtToken(profileUpdate.result);
      }
    }
  });

  return (
    <main>
      <ConfirmPopup
        message={`<strong>Image file size must be ${
          import.meta.env.VITE_AVATAR_IMAGE_SIZE
            ? import.meta.env.VITE_AVATAR_IMAGE_SIZE
            : 2
        }MB or less.</strong>`}
        name="avatarAlert"
        onconfirm={(status: boolean) => removePopup("avatarAlert")}
        alert={true}
      ></ConfirmPopup>
      <SystemLayout>
        <div class="mw-800">
          <div>
            <h2 class="title color">User Profile</h2>
          </div>
          <div>
            <Form class="form" enctype="multipart/form-data">
              <input
                type="hidden"
                name="customer_id"
                value={data()?.userInfo?.paddle_user_id}
              />
              <div class="form-element text-center">
                <input
                  id="user_profile_image"
                  class="d-n"
                  name="avatar"
                  type="file"
                  accept="image/png, image/jpg, image/jpeg, image/webp"
                  onChange={(event: any) => {
                    const file: any = event.target.files[0];
                    if (file) {
                      const maxSize =
                        (import.meta.env.VITE_AVATAR_IMAGE_SIZE
                          ? Number(import.meta.env.VITE_AVATAR_IMAGE_SIZE)
                          : 2) *
                        1024 *
                        1024;
                      if (file && file.size > maxSize) {
                        setPopup("avatarAlert");
                        event.target.value = "";
                        if (selectedFile() != data()?.userInfo?.avatar) {
                          if (data()?.userInfo?.avatar) {
                            setSelectedFile(data()?.userInfo?.avatar);
                            setUserAvatar(data()?.userInfo?.avatar);
                          } else {
                            setSelectedFile(null);
                            setUserAvatar("");
                          }
                        }
                      } else {
                        const reader = new FileReader();
                        reader.onload = (e: any) => {
                          setSelectedFile(e.target.result as string);
                          setUserAvatar(e.target.result);
                        };
                        reader.readAsDataURL(file);
                      }
                    }
                  }}
                />
                <label for="user_profile_image" class="profile-image-container">
                  <Show when={selectedFile()}>
                    <img
                      class="profile-image"
                      src={selectedFile()}
                      alt="Profile Picture"
                    />
                  </Show>
                  <Show when={!selectedFile()}>
                    <div class="profile-image initials">
                      {getInitails(
                        data()?.userInfo?.first_name +
                          " " +
                          data()?.userInfo?.last_name
                      )}
                    </div>
                  </Show>
                  <div class="edit-image">
                    <Icons name="camara" width={50} height={35}></Icons>
                  </div>
                </label>
              </div>
              <div class="flex gap">
                <input type="hidden" name="user_id" value={data()?.user_id} />
                <div class="form-element app-gap">
                  <input
                    type="text"
                    class="input"
                    name="first_name"
                    placeholder="First Name"
                    value={data()?.userInfo?.first_name}
                    use:inputLbl
                  />
                </div>
                <div class="form-element app-gap">
                  <input
                    type="text"
                    class="input"
                    name="last_name"
                    placeholder="Last Name"
                    value={data()?.userInfo?.last_name}
                    use:inputLbl
                  />
                </div>
              </div>
              <br />
              <div class="form-element">
                <input
                  type="hidden"
                  name="current_email"
                  value={currentEmail()}
                />
                <input
                  type="text"
                  class={`input ${
                    profileUpdate.error?.fieldErrors?.email ? "error-input" : ""
                  }`}
                  name="email"
                  placeholder="Email"
                  value={data()?.userInfo?.email}
                  use:inputLbl
                />
                <Show when={profileUpdate.error?.fieldErrors?.email}>
                  <p role="alert" class="error-message">
                    {profileUpdate.error.fieldErrors.email}
                  </p>
                </Show>
              </div>
              <br />
              <Show when={userRole() == "admin"}>
                <div class="form-element">
                  <input
                    type="text"
                    class="input"
                    name="company_name"
                    placeholder="Company Name"
                    value={
                      data()?.userInfo?.company_name
                        ? data()?.userInfo.company_name
                        : ""
                    }
                    use:inputLbl
                  />
                </div>
                <br />
              </Show>
              <button type="submit" class="btn primary">
                Update Profile
              </button>
            </Form>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default profile;
