import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import Popup from "../global/Popup";
import Icons from "../icons/Icons";
import {
  additionalData,
  lockRecord,
  preLoadData,
  recordsJson,
  setAdditionalData,
  updateFormRecords,
} from "~/store/records.store";
import { getSignatureInitials, removePopup } from "~/store/global.store";
import { formJson } from "~/store/form.store";
import opentype from "opentype.js";
import convert from "~/helpers/convertor";

interface Props {
  data: any;
}

export const [isSignature, setIsSignature] = createSignal(false as boolean);

const SignaturePopup: Component<Props> = (props) => {
  const [canvasContext, setCanvasContext] = createSignal(
    {} as CanvasRenderingContext2D
  );
  const [offsetX, setOffsetX] = createSignal(0 as number);
  const [offsetY, setOffsetY] = createSignal(0 as number);
  const [drawing, setDrawing] = createSignal(false as boolean);
  const [activeTool, setActiveTool] = createSignal("pencil" as string);
  const [isLockedSign, setIsLockedSign] = createSignal(false as boolean);
  const [signaturePoints, setSignaturePoints] = createSignal([] as any);
  const [svgSignatures, setSvgSignatures] = createSignal([] as any);
  const [signatureValue, setSignatureValue] = createSignal("" as string);
  const [formViewType, setFormViewType] = createSignal("sign" as string);
  const [signatureSvg, setSignatureSvg] = createSignal({} as any);

  let debounceTimeout: any;

  // Debounced createSignature function
  const debouncedCreateSignature = (text: string) => {
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      createSignature(text);
    }, 300);
  };

  // Function to handle input and trigger debounced signature creation
  const handleInput = (e: any) => {
    const value = e.target.value;
    setSignatureValue(value);
    debouncedCreateSignature(value);
  };

  let signatureRef: any;
  createEffect(() => {
    if (preLoadData()[props.data.dataFieldSync?.uid]) {
      setSignaturePoints(
        JSON.parse(preLoadData()[props.data.dataFieldSync?.uid]).points
      );
    } else {
      setSignaturePoints([]);
    }
    if (
      additionalData()[props.data.dataFieldSync?.uid] &&
      additionalData()[props.data.dataFieldSync?.uid]?.display_value
    ) {
      setSignatureSvg(
        additionalData()[props.data.dataFieldSync?.uid]?.display_value
      );
    }
    if (formViewType()) {
      previewSign();
    }
  });

  onMount(() => {
    let signatureString: string = "";
    const arr: any = formJson.pages.flatMap((page: any) =>
      page.fields
        ?.filter((field: any) =>
          field.properties.dataFieldSync.label.toLowerCase().includes("name")
        )
        .map((field: any) => ({
          label: field.properties.dataFieldSync.label,
          value: preLoadData()[field.properties.dataFieldSync.uid],
        }))
    );
    arr.forEach((item: any) => {
      if (item?.label.toLowerCase().includes("full name")) {
        if (item.value != "") {
          signatureString = item.value;
          return;
        }
      }
      if (
        item?.label.toLowerCase().includes("first name") &&
        !signatureString?.includes(item.value)
      ) {
        signatureString += (signatureString ? " " : "") + item.value;
      }
      if (
        item?.label.toLowerCase().includes("middle name") &&
        !signatureString?.includes(item.value)
      ) {
        signatureString += (signatureString ? " " : "") + item.value;
      }
      if (
        item?.label.toLowerCase().includes("last name") &&
        !signatureString?.includes(item.value)
      ) {
        signatureString += (signatureString ? " " : "") + item.value;
      }
      if (
        item?.label.toLowerCase().includes("company name") &&
        !signatureString?.includes(item.value)
      ) {
        signatureString = item.value;
      }
      if (
        item?.label.toLowerCase().includes("customer name") &&
        !signatureString?.includes(item.value)
      ) {
        signatureString = item.value;
      }
      if (
        item?.label != "Unnamed Field:" &&
        item?.label.toLowerCase().includes("name") &&
        !signatureString?.includes(item.value)
      ) {
        signatureString = item.value;
      }
    });
    setSignatureValue(signatureString);
    if (signatureValue() && signatureValue() != "") {
      setTimeout(() => {
        createSignature(signatureValue());
      }, 0);
    }
  });

  function previewSign() {
    setTimeout(() => {
      const canvas: any = signatureRef;
      const element: any = document.querySelector(".signature-canvas");
      if (!element || !canvas) return;
      canvas.height = element.getBoundingClientRect().height;
      canvas.width = element.getBoundingClientRect().width;
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      setCanvasContext(ctx);

      if (!preLoadData()[props.data.dataFieldSync?.uid]) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        return;
      }

      if (signatureSvg() && Object.keys(signatureSvg())?.length > 0) {
        const svgString = signatureSvg();
        const completeSvgString = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
          ${
            svgString.includes('xmlns="http://www.w3.org/2000/svg"')
              ? svgString
              : svgString.replace(
                  "<svg",
                  '<svg xmlns="http://www.w3.org/2000/svg"'
                )
          }`;
        const fillInkColor = props.data?.inkFieldSync?.inkColor;
        const parser = new DOMParser();
        const svgDoc = parser.parseFromString(
          completeSvgString,
          "image/svg+xml"
        );
        const elementsToColor = svgDoc.querySelectorAll("path, rect, circle");
        elementsToColor.forEach((element) => {
          element.setAttribute("fill", fillInkColor);
          element.setAttribute("stroke", fillInkColor);
        });
        const serializer = new XMLSerializer();
        const updatedSvgString = serializer.serializeToString(
          svgDoc.documentElement
        );

        const svgBlob = new Blob([updatedSvgString], {
          type: "image/svg+xml;charset=utf-8",
        });
        const url = URL.createObjectURL(svgBlob);
        const img = new Image();
        const cleanup = () => {
          URL.revokeObjectURL(url);
        };

        img.onload = () => {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(img, 0, 20, canvas.width, canvas.height);
          cleanup();
        };

        img.onerror = (error) => {
          console.error("Error loading SVG:", error);
          cleanup();
          renderFromPoints(ctx, canvas);
        };

        img.crossOrigin = "anonymous";
        img.src = url;
      } else {
        renderFromPoints(ctx, canvas);
      }
    }, 100);
  }

  // Separated points rendering logic for better organization
  function renderFromPoints(
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement
  ) {
    const signatureJSON = {
      points: JSON.parse(preLoadData()[props.data.dataFieldSync.uid]).points,
    };

    let signatureArr: any = [];
    let newSet: any = [];

    signatureJSON.points.forEach((p: any, i: number) => {
      let x = p.split("{")[1].split(",")[0];
      let y = p.split("{")[1].split(",")[1].split("}")[0];
      if (x !== "nan") {
        newSet.push({ x, y });
      }
      if (x === "nan" && newSet.length) {
        signatureArr.push(newSet);
        newSet = [];
      }
      if (i === signatureJSON.points.length - 1 && newSet.length) {
        signatureArr.push(newSet);
      }
    });

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const inkColor = props.data.inkFieldSync?.inkColor || "#000";
    signatureArr.forEach((field: { x: any; y: any }[]) => {
      if (field.length === 0) return;

      ctx.beginPath();
      ctx.strokeStyle = inkColor;
      ctx.fillStyle = inkColor;

      field.forEach((val: { x: any; y: any }, i: number) => {
        const scaledX =
          val.x *
          (canvas.width /
            Number(convert(props.data.dataFieldSync.frame[1][0], "in-px")));
        const scaledY =
          val.y *
          (canvas.height /
            Number(convert(props.data.dataFieldSync.frame[1][1], "in-px")));

        if (i === 0) {
          ctx.moveTo(scaledX, scaledY);
        } else {
          ctx.lineTo(scaledX, scaledY);
        }
      });

      ctx.stroke();
      ctx.closePath();
    });
  }

  // Function to create an SVG signature from text
  async function createSignature(text: string) {
    const textSvg = `${text}`;
    const initialSvg = `${getSignatureInitials(text)}`;
    const fontFamilies = [
      "Roboto.ttf",
      "Satisfy.ttf",
      "AppleChancery.ttf",
      "DancingScript.ttf",
      "GreatVibes.otf",
      "Ephesis.ttf",
      "Italianno.ttf",
      "LearningCurve.ttf",
      "Windsong.ttf",
    ];
    setSvgSignatures([]);
    const svgSignaturesPromises = fontFamilies.map(
      (fontFile) =>
        new Promise<{ svgText: string; svgInitial: string; font: string }>(
          (resolve, reject) => {
            const fontUrl = `/fonts/${fontFile}`;
            const fontSize = 50;
            const fillColor = props.data?.inkFieldSync?.inkColor;
            opentype.load(fontUrl, (err: any, font: any) => {
              if (err) {
                console.error("Error loading font:", fontUrl, err);
                return reject(err);
              }
              const paths = font.getPaths(textSvg, 10, 45, fontSize);
              let svgText = "<svg width='415px' height='80px'>";
              for (const path of paths) {
                svgText += `<path d="${path.toPathData()}" fill="${fillColor}" />`;
              }
              svgText += "</svg>";
              const initialPaths = font.getPaths(initialSvg, 10, 45, fontSize);
              let svgInitial = "<svg width='150px' height='80px'>";
              for (const path of initialPaths) {
                svgInitial += `<path d="${path.toPathData()}" fill="${fillColor}" />`;
              }
              svgInitial += "</svg>";
              resolve({ svgText, svgInitial, font: fontFile.split(".")[0] });
            });
          }
        )
    );
    try {
      const svgSignatures = await Promise.all(svgSignaturesPromises);
      setSvgSignatures(svgSignatures);
    } catch (error) {
      console.error("Error creating SVG signatures:", error);
    }
  }

  function drawSign(newX: number, newY: number) {
    const strokeStyle = props.data.inkFieldSync.inkColor;
    canvasContext().beginPath();
    canvasContext().strokeStyle = strokeStyle;
    if (activeTool() === "eraser") {
      canvasContext().lineWidth = 50;
      canvasContext().globalCompositeOperation = "destination-out";
      canvasContext().lineCap = "round";
      canvasContext().lineJoin = "round";
      canvasContext().strokeStyle = "rgba(0,0,0,1)";
    } else {
      canvasContext().lineWidth = 2;
      canvasContext().globalCompositeOperation = "source-over";
      canvasContext().lineCap = "round";
      canvasContext().lineJoin = "round";
      canvasContext().strokeStyle = strokeStyle;
    }
    canvasContext().moveTo(offsetX(), offsetY());
    canvasContext().lineTo(newX, newY);
    canvasContext().stroke();
    setOffsetX(newX);
    setOffsetY(newY);
  }

  // function generatePointsFromSvg(current_svg_xml: any, width: number) {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(current_svg_xml, "application/xml");
  //   const paths: any = doc.getElementsByTagName("path");
  //   const tempArr: any = [];

  //   for (let i = 0; i < paths.length; i++) {
  //     if (paths[i].getAttribute("d") != "") {
  //       const path = document.createElementNS(
  //         "http://www.w3.org/2000/svg",
  //         "path"
  //       );
  //       path.setAttribute("d", paths[i].getAttribute("d"));

  //       // Get only the first subpath (outer path) from the SVG path data
  //       const d = paths[i].getAttribute("d");

  //       const firstSubpath = d.split("M")[1]?.split("M")[0]; // Get only first 'M' command and its data

  //       if (firstSubpath) {
  //         path.setAttribute("d", "M" + firstSubpath);
  //         const pathLength = path.getTotalLength();
  //         const points = [];

  //         // Generate fewer points along the path
  //         const numPoints = 100; // Reduced number of points
  //         const step = pathLength / numPoints;

  //         for (let j = 0; j <= pathLength; j += step) {
  //           const point = path.getPointAtLength(j);
  //           // Add some randomness to point placement to avoid too perfect lines
  //           const jitter = 0.2; // Adjust this value to control randomness
  //           const randomX = point.x + (Math.random() - 0.5) * jitter;
  //           const randomY = point.y + (Math.random() - 0.5) * jitter;

  //           points.push(
  //             `{${
  //               randomX *
  //               (Number(
  //                 convert(props.data.dataFieldSync.frame[1][0], "in-px")
  //               ) /
  //                 width)
  //             }, ${
  //               randomY *
  //                 (Number(
  //                   convert(props.data.dataFieldSync.frame[1][1], "in-px")
  //                 ) /
  //                   120) +
  //               Number(convert(props.data.dataFieldSync.frame[1][1], "in-px")) /
  //                 6
  //             }}`
  //           );
  //         }

  //         if (points.length > 0) {
  //           tempArr.push(points);
  //         }
  //       }
  //     }
  //   }

  //   let finalArr: any = [];
  //   tempArr.forEach((arr: any) => {
  //     finalArr = [...finalArr, "{nan, nan}", ...arr];
  //   });

  //   updateFormRecords(
  //     props.data.dataFieldSync.uid,
  //     JSON.stringify({ nPoints: finalArr.length, points: finalArr })
  //   );
  // }

  function generatePointsFromSvg(current_svg_xml: any, width: number) {
    setSignatureSvg(current_svg_xml);
    var parser = new DOMParser();
    var doc = parser.parseFromString(current_svg_xml, "application/xml");
    var paths: any = doc.getElementsByTagName("path");
    const tempArr: any = [];
    for (var i = 0; i < paths.length; i++) {
      if (paths[i].getAttribute("d") != "") {
        var numPoints = 100;
        var path = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "path"
        );
        path.setAttribute("d", paths[i].getAttribute("d"));
        var pathLength = path.getTotalLength();
        var points = [];
        for (var j = 0; j < numPoints; j++) {
          var p = path.getPointAtLength((j * pathLength) / numPoints);
          points.push(
            `{${
              p.x *
              (Number(convert(props.data.dataFieldSync?.frame[1][0], "in-px")) /
                width)
            }, ${
              p.y *
                (Number(
                  convert(props.data.dataFieldSync?.frame[1][1], "in-px")
                ) /
                  120) +
              Number(convert(props.data.dataFieldSync?.frame[1][1], "in-px")) /
                6
            }}`
          );
        }
        tempArr.push(points);
      }
    }
    let finalArr: any = [];
    tempArr.forEach((arr: any) => {
      finalArr = [...finalArr, "{nan, nan}", ...arr];
    });
    updateFormRecords(
      props.data.dataFieldSync?.uid,
      JSON.stringify({ nPoints: finalArr.length, points: finalArr }),
      false,
      false,
      {
        display_value: signatureSvg(),
      }
    );
    setIsSignature(true);
  }

  function generatePointsFromCanvas(arrays: any) {
    let result = [];
    for (let i = 0; i < arrays.length; i++) {
      let currentArray = arrays[i];
      let uniqueArray = [];
      for (let j = 0; j < currentArray.length; j++) {
        let currentElement = currentArray[j];
        let isUnique = true;
        for (let k = 0; k < i; k++) {
          if (
            arrays[k].some(
              (item: any) =>
                item.x === currentElement.x && item.y === currentElement.y
            )
          ) {
            isUnique = false;
            break;
          }
        }
        if (isUnique) {
          uniqueArray.push(currentElement);
        }
      }
      uniqueArray = uniqueArray.map(
        (point: any) =>
          `{${
            point.x *
            (Number(convert(props.data.dataFieldSync.frame[1][0], "in-px")) /
              1148)
          }, ${
            point.y *
            (Number(convert(props.data.dataFieldSync.frame[1][1], "in-px")) /
              400)
          }}`
      );
      uniqueArray.unshift("{nan,nan}");
      result.push(uniqueArray);
    }
    return result.flat();
  }

  function updateSignatureRecord() {
    let points: any;
    if (typeof signaturePoints()[0] == "string") {
      points = signaturePoints();
    } else {
      if (signaturePoints().length > 0) {
        points = generatePointsFromCanvas(
          signaturePoints()
            .map((currentArray: any) => {
              let subArrays = [];
              let currentSubArray = [];
              currentArray.sort((a: any, b: any) => a.x - b.x || a.y - b.y);
              for (let i = 0; i < currentArray.length; i++) {
                let point = currentArray[i];
                let prevPoint = currentArray[i - 1];
                if (
                  !prevPoint ||
                  Math.abs(point.x - prevPoint.x) > 8 ||
                  Math.abs(point.y - prevPoint.y) > 8
                ) {
                  if (currentSubArray.length > 0) {
                    subArrays.push(currentSubArray);
                  }
                  currentSubArray = [point];
                } else {
                  currentSubArray.push(point);
                }
              }
              if (currentSubArray.length > 0) {
                subArrays.push(currentSubArray);
              }
              return subArrays;
            })
            .flat()
        );
      } else {
        points = [];
      }
    }
    const result = {
      nPoints: points.length,
      points,
    };
    updateFormRecords(
      props.data.dataFieldSync.uid,
      JSON.stringify(result),
      false,
      false,
      {
        display_value: "",
      }
    );
    removePopup("signature");
  }

  function toggleFormViewType(type: string) {
    setFormViewType(type);
  }

  return (
    <Popup heading={"Signature"} classes="large" name="signature">
      <div class="app-flex flex space-between align-center app-w-100vh">
        <div class="switch-wrapper toggle-signature mb-0">
          <input
            id="users"
            type="radio"
            name="switch"
            onchange={() => toggleFormViewType("sign")}
            checked={formViewType() == "sign"}
          />
          <input
            id="groups"
            type="radio"
            name="switch"
            onchange={() => toggleFormViewType("draw")}
            checked={formViewType() == "draw"}
          />
          <label
            class="app-flex flex align-center justify-center toggle-label p-5"
            for="users"
          >
            Generate
          </label>
          <label
            class="app-flex flex align-center justify-center toggle-label"
            for="groups"
          >
            Sign
          </label>
          <span class="highlighter"></span>
        </div>
        <Show when={formViewType() == "draw"}>
          <button
            class="btn primary mr-10"
            onclick={() => updateSignatureRecord()}
          >
            Done
          </button>
        </Show>
        <Show when={formViewType() == "sign"}>
          <div class="w-50 text-center lbl">Choose Generated Signature</div>
        </Show>
      </div>
      <div class="signature-container app-w-100vh">
        <div class={`left-block form-group ${formViewType()}`}>
          <Show when={formViewType() == "sign"}>
            <div>
              <label for="signature-label">Signature Text:</label>
              <input
                type="text"
                class="input"
                id="signature-label"
                value={signatureValue() ? signatureValue() : ""}
                oninput={(e: any) => handleInput(e)}
              />
            </div>
          </Show>
          <br />
          <div>
            <div class="app-flex flex space-between">
              <Show when={formViewType() == "sign"}>
                <div>Preview:</div>
                <div class="app-flex flex gap pr-5">
                  <Show
                    when={
                      preLoadData()[props.data.dataFieldSync?.uid] &&
                      JSON.parse(preLoadData()[props.data.dataFieldSync?.uid])
                        ?.nPoints > 0
                    }
                  >
                    <div
                      class="cur-p"
                      onclick={() => {
                        lockRecord();
                        removePopup("signature");
                      }}
                    >
                      <Icons name="lock" width={22} height={22}></Icons>
                    </div>
                  </Show>
                  <div
                    class="cur-p"
                    onclick={() => {
                      setSignatureSvg({});
                      updateFormRecords(
                        props.data.dataFieldSync.uid,
                        "",
                        false,
                        false,
                        {
                          display_value: "",
                        }
                      );
                    }}
                  >
                    <Icons name="clear" width={22} height={22}></Icons>
                  </div>
                </div>
              </Show>
              <Show when={formViewType() == "draw" && !isLockedSign()}>
                <div></div>
                <div class="cur-p">
                  <div class="app-flex flex gap">
                    <Show when={signaturePoints()[0]?.length > 0}>
                      <div
                        onclick={() => {
                          setIsLockedSign(true);
                          if (
                            Object.keys(recordsJson.currentRecord).length > 0
                          ) {
                            lockRecord();
                            removePopup("signature");
                          } else {
                            updateSignatureRecord();
                            setTimeout(() => {
                              lockRecord();
                            }, 100);
                          }
                        }}
                      >
                        <Icons name="lock" width={22} height={22}></Icons>
                      </div>
                    </Show>
                    <div onclick={() => setActiveTool("eraser")}>
                      <Icons
                        name="eraser"
                        stroke={activeTool() == "eraser" ? "#86BDFB" : ""}
                      ></Icons>
                    </div>
                    <div onclick={() => setActiveTool("pencil")}>
                      <Icons
                        name="pencil"
                        stroke={activeTool() == "pencil" ? "#86BDFB" : ""}
                      ></Icons>
                    </div>
                  </div>
                </div>
              </Show>
            </div>
            <div class="signature-canvas">
              <canvas
                class="preview-canvas"
                width={formViewType() == "sign" ? "565px" : "1148px"}
                height={formViewType() == "sign" ? "300px" : "400px"}
                ref={signatureRef}
                style={{
                  cursor:
                    formViewType() == "draw"
                      ? activeTool() === "eraser"
                        ? "cell"
                        : "crosshair"
                      : "auto",
                  "max-height": formViewType() == "sign" ? "300px" : "400px",
                }}
                onmousedown={(e: any) => {
                  setOffsetX(e.offsetX);
                  setOffsetY(e.offsetY);
                  setDrawing(true);
                }}
                onmousemove={(e: any) => {
                  if (drawing() && formViewType() == "draw") {
                    drawSign(e.offsetX, e.offsetY);
                  }
                }}
                onmouseup={() => {
                  setDrawing(false);
                  const canvas = signatureRef;
                  const ctx = canvas.getContext("2d");
                  const imageData = ctx.getImageData(
                    0,
                    0,
                    canvas.width,
                    canvas.height
                  ).data;
                  const points: any = [];
                  // Iterate through pixel data
                  for (let y = 0; y < canvas.height; y++) {
                    for (let x = 0; x < canvas.width; x++) {
                      const index = (y * canvas.width + x) * 4;
                      // Check if the pixel is not transparent
                      if (imageData[index + 3] !== 0) {
                        points.push({ x, y });
                      }
                    }
                  }
                  if (preLoadData()[props.data.dataFieldSync?.uid]) {
                    setSignaturePoints([points]);
                  } else {
                    if (points.length > 0) {
                      setSignaturePoints([points]);
                    } else {
                      setSignaturePoints([]);
                    }
                  }
                }}
                onmouseleave={() => setDrawing(false)}
              ></canvas>
            </div>
            <Show when={formViewType() == "sign"}>
              <br />
              <div class="text-right">
                <button
                  class="btn primary mr-10"
                  onclick={() => removePopup("signature")}
                >
                  Done
                </button>
              </div>
            </Show>
          </div>
        </div>
        <Show when={formViewType() == "sign"}>
          <div class="right-block">
            <div class="app-flex flex space-between">
              <div class="lbl color w-50 text-center">Full Sign</div>
              <div style={{ "border-right": "2px solid #27538B" }}></div>
              <div class="lbl w-50 text-center color">Initial</div>
            </div>
            <br />
            <For each={svgSignatures()}>
              {(signature: any) => (
                <div class="app-flex flex">
                  <div
                    class="cur-p"
                    innerHTML={signature.svgText}
                    onclick={() =>
                      generatePointsFromSvg(signature.svgText, 415)
                    }
                  ></div>
                  <div
                    class="cur-p"
                    innerHTML={signature.svgInitial}
                    onclick={() =>
                      generatePointsFromSvg(signature.svgInitial, 150)
                    }
                  ></div>
                </div>
              )}
            </For>
          </div>
        </Show>
      </div>
    </Popup>
  );
};

export default SignaturePopup;
