import { Component, For, Show, createEffect } from "solid-js";
import {
  formJson,
  isFieldJustSelected,
  setFormJson,
  setIsFieldJustSelected,
  setIsFormJsonUpdated,
  updateFormJson,
} from "~/store/form.store";
import Icons from "../icons/Icons";
import {
  cloudServices,
  exportFormats,
  scriptBtnCommands,
  scriptParameter,
} from "~/store/field-editor.store";
import Checkbox from "../global/Checkbox";
import {
  activeField,
  selectedScript,
  setActiveField,
  setChosenItems,
  setFieldsToLink,
  setPopup,
  setSelectedScript,
  setSelectedScriptId,
  setSelectedScriptItem,
} from "~/store/global.store";
import fetchApi from "~/lib/api";
import { produce } from "solid-js/store";
import { selectedFields } from "./Guidline";
import {
  isNotScriptButtonEmail,
  setIsNotScriptButtonEmail,
} from "../popup/EmailSettings";

interface Props {
  user_id: string;
  jwt_token: string;
}

export function updateScriptItemData() {
  setFormJson(
    "pages",
    produce((pages: any) => {
      pages.forEach((page: any) => {
        page.fields?.forEach((item: any) => {
          if (
            formJson.currentField.dataFieldSync?.uid ==
            item.properties.dataFieldSync?.uid
          ) {
            item.properties.actionString = JSON.stringify(selectedScript());
            setIsFormJsonUpdated(true);
          }
        });
      });
    })
  );
  setFormJson({
    currentField: {
      ...formJson.currentField,
      actionString: JSON.stringify(selectedScript()),
    },
  });
}

const ScriptButtonEditor: Component<Props> = (props: any) => {
  let nameInputRef: any;

  createEffect(() => {
    if (!isNotScriptButtonEmail() && isFieldJustSelected()) {
      if (formJson.currentField && selectedFields().length == 0) {
        setTimeout(() => {
          nameInputRef?.focus();
        }, 0);
      } else {
        nameInputRef?.blur();
      }
      if (formJson.currentField.actionString != "") {
        setSelectedScript(JSON.parse(formJson.currentField.actionString));
      }
    }
  });

  function addScript(command: string) {
    let tempArr: Array<object> = [...selectedScript()];
    const tempObj: any = {
      command_type: command,
      uuid_string: crypto.randomUUID().toUpperCase(),
      parameters: [],
    };
    let parameterObj: any = { ...scriptParameter };
    switch (command) {
      case "OpenForm":
        parameterObj.parameter_name = "Form Name";
        parameterObj.parameter_type = {
          FormName: {},
        };
        tempObj.parameters.push(parameterObj);
        break;

      case "ExportRecord":
        parameterObj.parameter_name = "Cloud Service";
        parameterObj.parameter_type = {
          CloudService: {},
        };
        tempObj.parameters.push(parameterObj);
        parameterObj = { ...scriptParameter };
        parameterObj.parameter_name = "Formats";
        parameterObj.parameter_selection_style = "Multiple";
        parameterObj.parameter_type = {
          Format: {},
        };
        tempObj.parameters.push(parameterObj);
        break;

      case "OpenFormsInNewTab":
        parameterObj.parameter_name = "Form Name";
        parameterObj.parameter_selection_style = "Multiple";
        parameterObj.parameter_type = {
          FormName: {},
        };
        tempObj.parameters.push(parameterObj);
        break;

      case "GoToPage":
        parameterObj.parameter_name = "Page Number";
        parameterObj.parameter_type = {
          PageNumber: {},
        };
        tempObj.parameters.push(parameterObj);
        break;

      case "OpenFolder":
        parameterObj.parameter_name = "Folder Name";
        parameterObj.parameter_type = {
          FolderName: {},
        };
        tempObj.parameters.push(parameterObj);
        break;

      case "FindRecord":
        parameterObj.parameter_name = "Record Name";
        parameterObj.parameter_selection_style = "None";
        parameterObj.parameter_type = {
          RecordNameInput: {},
        };
        tempObj.parameters.push(parameterObj);
        break;

      default:
        break;
    }
    if (tempArr.filter((obj: any) => obj.command_type == command).length == 0) {
      tempArr.push(tempObj);
      setSelectedScript(tempArr);
    }
    const selectedElement = document.querySelector(".selected-scripts-div");
    if (selectedElement) {
      selectedElement.scrollTo({
        top: selectedElement.scrollHeight,
        behavior: "smooth",
      });
    }
    updateScriptItemData();
  }

  function removeScript(script: string) {
    let tempArr: Array<object> = [...selectedScript()];
    tempArr = tempArr.filter((obj: any) => obj.command_type != script);
    setSelectedScript(tempArr);
    updateScriptItemData();
  }

  async function setScriptItems(
    param: string,
    command: string,
    uid: string,
    value: any
  ) {
    let tempArr: any = [];
    switch (param) {
      case "Form Name":
      case "Folder Name":
        const apiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/list`,
          jwt_token: props.jwt_token as string,
          body: { user_id: props.user_id, isListing: true },
        };
        const formsList = await fetchApi(apiParams);
        if (formsList?.statusCode == 401 || formsList?.statusCode == 403) {
          window.location.href = "/logout";
        }
        if (formsList && formsList.status) {
          if (param == "Form Name") {
            tempArr = formsList.data
              .filter((obj: any) => obj.theme != "folder")
              .map((obj: any) => ({
                label: obj.name,
                value: obj.name,
              }));
          } else {
            tempArr = formsList.data
              .filter((obj: any) => obj.theme == "folder")
              .map((obj: any) => ({
                label: obj.name,
                value: obj.name,
              }));
          }
        }
        break;

      case "Cloud Service":
        tempArr = [...cloudServices];
        break;

      case "Formats":
        tempArr = [...exportFormats];
        break;

      case "Page Number":
        formJson.pages.forEach((page: any, key: any) => {
          tempArr.push({ label: `Page ${key + 1}`, value: key + 1 });
        });
        break;

      default:
        break;
    }
    setFieldsToLink(tempArr);
    setSelectedScriptItem(param == "Cloud Service" ? "Cloud Service" : command);
    setSelectedScriptId(uid);
    setChosenItems(value);
    setPopup("scriptItems");
    updateScriptItemData();
  }

  return (
    <>
      <div class="form-group">
        <label for="control_btn_name">Button Name:</label>
        <div class="flex app-flex align-center space-between pr">
          <input
            ref={nameInputRef}
            type="text"
            id="control_btn_name"
            class="input"
            value={
              formJson.currentField.dataFieldSync?.label
                ? formJson.currentField.dataFieldSync?.label
                : ""
            }
            onclick={() => {
              setActiveField("control_btn_name");
            }}
            onfocus={() => {
              setIsFieldJustSelected(true);
            }}
            onInput={(event) => {
              updateFormJson(
                "dataFieldSync",
                "label",
                event.currentTarget.value
              );
            }}
            onkeydown={(event: any) => {
              if (event.key == "Tab") {
                setIsNotScriptButtonEmail(false);
                setIsFieldJustSelected(false);
              }
            }}
            onblur={() => {
              setIsNotScriptButtonEmail(false);
              setIsFieldJustSelected(false);
            }}
          />
          <Show when={activeField() == "control_btn_name"}>
            <span
              class="pa z-index-1 cur-p r-5"
              onclick={() => updateFormJson("dataFieldSync", "label", "")}
            >
              <Icons name="circleX" width={20} height={20}></Icons>
            </span>
          </Show>
        </div>
      </div>
      <div class="clearfix"></div>
      <hr />
      <div class="form-group">
        <label class="w-100 lbl text-center">Commands</label>
        <div class="script-btn-editor-div form-group">
          <ul>
            <For each={scriptBtnCommands}>
              {(command: any) => (
                <li>
                  <div class="flex app-flex space-between align-center">
                    <span>{command.label}</span>
                    <span
                      class="cur-p"
                      onclick={() => addScript(command.value)}
                    >
                      <Icons name="circlePlus" width={20} height={20}></Icons>
                    </span>
                  </div>
                </li>
              )}
            </For>
          </ul>
        </div>
      </div>
      <div class="clearfix"></div>
      <hr />
      <div class="form-group">
        <div class="flex app-flex">
          <label class="w-100 lbl text-center">Script Editor</label>
        </div>
        <div class="form-group selected-scripts-div">
          <ul>
            <For each={selectedScript()}>
              {(script: any) => (
                <li
                  draggable={true}
                  ondragstart={(e: any) => {
                    e.dataTransfer.effectAllowed = "move";
                    e.dataTransfer?.setData("text/plain", script.uuid_string);
                  }}
                  ondragover={(e: any) => {
                    e.dataTransfer.dropEffect = "move";
                    e.preventDefault();
                  }}
                  ondrop={(e: any) => {
                    e.stopPropagation();
                    e.preventDefault();
                    let index1 = -1;
                    let index2 = -1;
                    const scripts: any = [...selectedScript()];
                    scripts.forEach((object: any, index: any) => {
                      if (
                        object.uuid_string ===
                        e.dataTransfer?.getData("text/plain")
                      ) {
                        index1 = index;
                      } else if (object.uuid_string === script.uuid_string) {
                        index2 = index;
                      }
                    });
                    if (index1 !== -1 && index2 !== -1) {
                      const tempObject = scripts[index1];
                      scripts[index1] = scripts[index2];
                      scripts[index2] = tempObject;
                      setSelectedScript(scripts);
                      updateScriptItemData();
                    }
                  }}
                >
                  <div class="flex app-flex space-between align-center">
                    <div class="flex app-flex space-between align-center w-85">
                      <span>
                        {script.command_type == "OpenFormsInNewTab"
                          ? "Open Form"
                          : scriptBtnCommands.filter(
                              (obj: any) => obj.value == script.command_type
                            )[0]?.label}
                        <Show
                          when={
                            script.command_type == "OpenForm" ||
                            script.command_type == "GoToPage" ||
                            script.command_type == "OpenFolder"
                          }
                        >
                          <span
                            class="script-selection"
                            onclick={() =>
                              setScriptItems(
                                script.parameters[0].parameter_name,
                                script.command_type,
                                script.uuid_string,
                                script.parameters[0].parameter_values[0]
                                  .param_value_display
                              )
                            }
                          >
                            {" "}
                            {script.parameters[0].parameter_values[0]
                              .param_value_display
                              ? script.parameters[0].parameter_values[0]
                                  .param_value_display
                              : "<" +
                                script.parameters[0].parameter_name +
                                ">"}{" "}
                          </span>
                        </Show>
                        <Show when={script.command_type == "ExportRecord"}>
                          {" " + "via"}
                          <span
                            class="script-selection"
                            onclick={() =>
                              setScriptItems(
                                script.parameters[0].parameter_name,
                                script.command_type,
                                script.uuid_string,
                                script.parameters[0].parameter_values[0]
                                  .param_value_display
                              )
                            }
                          >
                            {" "}
                            {script.parameters[0].parameter_values[0]
                              .param_value_display
                              ? cloudServices.filter(
                                  (service: any) =>
                                    service.value ==
                                    script.parameters[0].parameter_values[0]
                                      .param_value_display
                                )[0]?.label
                              : "<Service> "}{" "}
                          </span>
                          in
                          <span
                            class="script-selection"
                            onclick={() =>
                              setScriptItems(
                                script.parameters[1].parameter_name,
                                script.command_type,
                                script.uuid_string,
                                script.parameters[1].parameter_values[0]
                                  .param_value_display
                              )
                            }
                          >
                            {" "}
                            {script.parameters[1].parameter_values[0]
                              .param_value_display
                              ? script.parameters[1].parameter_values[0]
                                  .param_value_display
                              : "<Format>"}{" "}
                          </span>
                          <Show
                            when={
                              script.parameters[0].parameter_values[0]
                                .param_value_display == "email"
                            }
                          >
                            {" with "}
                            <span
                              class="script-selection"
                              onclick={() => {
                                setSelectedScriptId(script.uuid_string);
                                setPopup("emailSettings");
                              }}
                            >
                              Settings
                            </span>
                          </Show>
                        </Show>
                        <Show when={script.command_type == "OpenFormsInNewTab"}>
                          <span
                            class="script-selection"
                            onclick={() =>
                              setScriptItems(
                                script.parameters[0].parameter_name,
                                script.command_type,
                                script.uuid_string,
                                script.parameters[0].parameter_values[0]
                                  .param_value_display
                              )
                            }
                          >
                            {" "}
                            {script.parameters[0].parameter_values[0]
                              .param_value_display
                              ? script.parameters[0].parameter_values[0]
                                  .param_value_display
                              : "<" +
                                script.parameters[0].parameter_name +
                                ">"}{" "}
                          </span>
                          in New Tab
                        </Show>
                        <Show when={script.command_type == "FindRecord"}>
                          <input
                            type="text"
                            class="script-btn-input"
                            placeholder="<Enter Record Name>"
                            value={
                              script.parameters[0].parameter_values[0]
                                .param_value_display
                            }
                            onfocus={() => {
                              setIsNotScriptButtonEmail(true);
                            }}
                            onchange={(e: any) => {
                              const tempScript = selectedScript().map(
                                (obj: any) => {
                                  if (obj.uuid_string === script.uuid_string) {
                                    return {
                                      ...obj,
                                      parameters: obj.parameters.map(
                                        (param: any) => {
                                          if (
                                            param.parameter_name ===
                                            "Record Name"
                                          ) {
                                            return {
                                              ...param,
                                              parameter_values:
                                                param.parameter_values.map(
                                                  (value: any) => {
                                                    return {
                                                      ...value,
                                                      param_value_display:
                                                        e.target.value,
                                                      param_value_additinal_info:
                                                        {},
                                                    };
                                                  }
                                                ),
                                            };
                                          }
                                          return param;
                                        }
                                      ),
                                    };
                                  }
                                  return obj;
                                }
                              );
                              setSelectedScript(tempScript);
                              updateScriptItemData();
                            }}
                          />
                        </Show>
                      </span>
                      <span
                        class="cur-p"
                        onclick={() => removeScript(script.command_type)}
                      >
                        <Icons name="circleX" width={20} height={20}></Icons>
                      </span>
                    </div>
                    <div>
                      <Icons name="swap"></Icons>
                    </div>
                  </div>
                </li>
              )}
            </For>
          </ul>
        </div>
      </div>
      <div class="clearfix"></div>
      <hr />
      <div class="float-right">
        <Checkbox
          label="Run in Debug Mode"
          name="run_in_debug"
          value={formJson.currentField?.isDebugModeActive as boolean}
          setValue={(val: boolean) =>
            updateFormJson("", "isDebugModeActive", val)
          }
        />
      </div>
    </>
  );
};

export default ScriptButtonEditor;
