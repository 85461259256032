import { Component, onMount } from "solid-js";
import Popup from "../global/Popup";
import { removePopup, setNewFormFolderName } from "~/store/global.store";

interface Props {
  isFolder: boolean;
  setValue: Function;
}

const FormFolderAdd: Component<Props> = (props: any) => {
  let formFolderInputRef: any;

  onMount(() => {
    setTimeout(() => {
      formFolderInputRef?.focus();
    }, 0);
  });

  return (
    <Popup
      heading={`Create New ${props.isFolder ? "Folder" : "Form"}`}
      classes="xsmall"
      name="formFolderAdd"
    >
      <div>
        <input
          ref={formFolderInputRef}
          type="text"
          class="input"
          placeholder={`${props.isFolder ? "Folder" : "Form"} Name`}
          oninput={(e: any) => setNewFormFolderName(e.target.value)}
          onkeydown={(e: any) => {
            if (e.key == "Enter") {
              props.setValue(true);
            }
          }}
        />
        <div class="flex app-flex space-between align-center mt-10">
          <button
            class="btn sm danger"
            onclick={() => removePopup("formFolderAdd")}
          >
            Cancel
          </button>
          <button
            class="btn sm primary"
            onclick={() => {
              props.setValue(true);
            }}
          >
            OK
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default FormFolderAdd;
