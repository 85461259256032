import { Component, createSignal, For, Show } from "solid-js";
import { useRouteData } from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import SelectOne from "~/components/global/SelectOne";
import Icons from "~/components/icons/Icons";
import Popover from "~/components/global/Popover";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);

    let url = `${process.env.PADDLE_ENDPOINT}/prices`;
    let options: any = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.PADDLE_VENDOR_AUTH_CODE}`,
      },
    };
    let products: any = await fetch(url, options);
    products = await products.json();
    products = products.data;

    url = `${process.env.PADDLE_ENDPOINT}/discounts`;
    options = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.PADDLE_VENDOR_AUTH_CODE}`,
      },
    };
    let discounts: any = await fetch(url, options);
    discounts = await discounts.json();
    discounts = discounts.data;

    const TeamPlanId = process.env.PADDLE_TEAM_PLAN_ID;
    const TeamAnuualPlanId = process.env.PADDLE_TEAM_ANNUAL_PLAN_ID;

    return { products, discounts, jwt_token, TeamPlanId, TeamAnuualPlanId };
  });
}

const customPricing: Component = () => {
  setPageTitle(`${SITE_TITLE} - Custom Pricing`);
  const data = useRouteData<typeof routeData>();

  const [selectedType, setSelectedType] = createSignal(
    "custom_pricing" as string
  );

  return (
    <main>
      <SystemLayout>
        <div class="flex space-between align-center">
          <h3 class="title color">Custom Pricing</h3>
          <div class="w-33">
            <SelectOne
              set={[
                {
                  display: "Custom Pricing",
                  name: "custom_pricing",
                },
                { display: "Discount Coupon", name: "discount_coupon" },
              ]}
              active={selectedType()}
              setActive={(val: string) => {
                setSelectedType(val);
              }}
              class="full-width"
            ></SelectOne>
          </div>
        </div>
        <br />
        <br />
        <div>
          <Show when={selectedType() == "custom_pricing"}>
            <table class="table w-100">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Price / Interval</th>
                  <th class="w-125p">Register URL</th>
                </tr>
              </thead>
              <tbody>
                <For each={data()?.products}>
                  {(product: any) => (
                    <tr>
                      <td>{product.name}</td>
                      <td>{product.description}</td>
                      <td>
                        {(product.unit_price.amount / 100).toFixed(2)}$ /{" "}
                        {product.billing_cycle.interval
                          .charAt(0)
                          .toUpperCase() +
                          product.billing_cycle.interval.slice(1)}
                      </td>
                      <td>
                        <div class="text-center">
                          <span
                            class="cur-p pr"
                            onclick={() => {
                              const url = `${
                                import.meta.env.VITE_APP_URL
                              }/register?plan=${
                                product.id == data()?.TeamPlanId
                                  ? "Team"
                                  : product.id == data()?.TeamAnuualPlanId
                                  ? "Team Annual"
                                  : product.id
                              }${
                                product.quantity.minimum > 1
                                  ? "&quantity=" + product.quantity.minimum
                                  : ""
                              }`;
                              navigator.clipboard.writeText(url);
                            }}
                          >
                            <Popover
                              content="Copy to clipboard"
                              left={95}
                              top={-35}
                            >
                              <Icons name="copy" stroke="#2e61a3"></Icons>
                            </Popover>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </For>
              </tbody>
            </table>
          </Show>
          <Show when={selectedType() == "discount_coupon"}>
            <table class="table w-100">
              <thead>
                <tr>
                  <th>Coupon Name</th>
                  <th>Description</th>
                  <th>Discount</th>
                  <th>Available On</th>
                  <th class="w-125p">Register URL</th>
                </tr>
              </thead>
              <tbody>
                <For each={data()?.discounts}>
                  {(discount: any) => (
                    <tr>
                      <td>{discount.code}</td>
                      <td>{discount.description}</td>
                      <td>
                        {discount.type == "percentage"
                          ? discount.amount + "%"
                          : (discount.amount / 100).toFixed(2) + "$"}
                      </td>
                      <td>
                        {discount.restrict_to
                          ? data()
                              ?.products.filter((product: any) =>
                                discount.restrict_to.includes(product.id)
                              )
                              .map((product: any) => product.name)
                              .join(" | ")
                          : "ALL"}
                      </td>
                      <td>
                        <div class="text-center">
                          <span
                            class="cur-p pr"
                            onclick={() => {
                              const url = `${
                                import.meta.env.VITE_APP_URL
                              }/register?plan=${
                                discount.restrict_to
                                  ? data()?.products.filter((product: any) =>
                                      discount.restrict_to.includes(product.id)
                                    )[0].id == data()?.TeamPlanId
                                    ? "Team"
                                    : "Team Annual"
                                  : "Team"
                              }&discount=${discount.id}`;
                              navigator.clipboard.writeText(url);
                            }}
                          >
                            <Popover
                              content="Copy to clipboard"
                              left={95}
                              top={-35}
                            >
                              <Icons name="copy" stroke="#2e61a3"></Icons>
                            </Popover>
                          </span>
                        </div>
                      </td>
                    </tr>
                  )}
                </For>
              </tbody>
            </table>
          </Show>
        </div>
      </SystemLayout>
    </main>
  );
};

export default customPricing;
