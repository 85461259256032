import { Component, createEffect, createSignal } from "solid-js";
import Popup from "../global/Popup";
import { selectedFolderData } from "~/store/global.store";
import FolderForms from "../global/FolderForms";

interface Props {
  user_id: string;
  jwt_token: string;
}

const [hasMultipleLines, setHasMultipleLines] = createSignal(false as boolean);

const FolderFormsPopup: Component<Props> = (props) => {
  createEffect(() => {
    if (selectedFolderData().forms?.length > 5) {
      setHasMultipleLines(true);
    } else {
      setHasMultipleLines(false);
    }
  });

  return (
    <>
      <Popup
        heading={selectedFolderData().name}
        classes="folder-forms"
        name="folderForms"
      >
        <div
          class={`folder-forms ${hasMultipleLines() ? "multiple-lines" : ""}`}
        ></div>
      </Popup>
      <FolderForms
        user_id={props.user_id}
        jwt_token={props.jwt_token}
      ></FolderForms>
    </>
  );
};

export { hasMultipleLines };
export default FolderFormsPopup;
