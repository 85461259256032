import { Component, For, Show, createEffect, createSignal } from "solid-js";
import { A, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { notify } from "~/components/global/Notification";
import messages from "~/helpers/message";
import Pagination, {
  currentPage,
  setCurrentPage,
} from "~/components/global/Pagination";
import Search from "~/components/global/Search";
import { setPageTitle } from "~/root";
import { SITE_TITLE } from "~/consts";
import {
  popupQueue,
  setAssignedForms,
  setOtherForms,
  setPopup,
  removePopup,
  userIcons,
} from "~/store/global.store";
import AssigneeList from "~/components/popup/AssigneeListPopup";
import { validateUser } from "./api/validateUser";
import GroupForms from "~/components/popup/UserGroupFormsPopup";
import ConfirmPopup from "~/components/popup/ConfirmPopup";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);

    const groupsListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/group/list`,
      jwt_token: jwt_token as string,
      body: { user_id, search_string: "", sort_string: "", skip: 0, take: 10 },
    };
    let list = await fetchApi(groupsListApiParams);
    if (list?.statusCode == 401) {
      throw redirect("/logout");
    }
    let groupsList = list?.data?.groupsList;
    let totalItems = list?.data?.totalItems;
    let totalPages = list?.data?.totalPages;

    return {
      groupsList,
      user_id,
      jwt_token,
      totalItems,
      totalPages,
    };
  });
}

const groups: Component = () => {
  setPageTitle(`${SITE_TITLE} - Team Users`);
  const data = useRouteData<typeof routeData>();
  const [getGroupsList, setGroupsList] = createSignal(
    data()?.groupsList as any
  );
  const [totalPages, setTotalPages] = createSignal(0 as number);
  const [searchString, setSearchString] = createSignal("" as any);
  const [sortString, setSortString] = createSignal("" as any);
  const [getTotalItems, setTotalItems] = createSignal(
    data()?.totalItems as any
  );
  const [assigneeList, setAssigneeList] = createSignal([] as any);
  const [assigneeAvatarsList, setAssigneeAvatarsList] = createSignal([] as any);
  const [formGroupId, setFormGroupId] = createSignal("" as string);
  const [formGroupName, setFormGroupName] = createSignal("" as string);
  const [groupIdToDelete, setGroupIdToDelete] = createSignal("" as string);

  createEffect(() => {
    if (data()?.groupsList) {
      setGroupsList(data()?.groupsList);
    } else {
      location.reload();
    }
    if (data()?.totalItems) {
      setTotalItems(data()?.totalItems);
    }
  });

  function getApiParams(user_id: string, jwt_token: string, skip: number) {
    return {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/group/list`,
      jwt_token,
      body: {
        user_id,
        skip,
        take: 10,
        search_string: searchString() ? searchString() : "",
        sort_string: sortString() ? sortString() : "",
      },
    };
  }

  async function handlePageChange(
    page: any,
    jwt_token: string,
    user_id: string
  ) {
    let skip: number = 0;
    if (page != 1) {
      skip = (page - 1) * 10;
    }
    const apiParams = getApiParams(user_id, jwt_token, skip);
    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (list?.statusCode == 403) {
      location.reload();
    }
    setCurrentPage(page);
    setGroupsList(list.data.groupsList);
  }

  async function deleteGroup(
    group_id: string,
    jwt_token: string,
    user_id: string
  ) {
    try {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/group/delete`,
        jwt_token: jwt_token as string,
        body: {
          group_id,
          user_id,
        },
      };
      const deleteGroup = await fetchApi(apiParams);
      if (deleteGroup?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (deleteGroup?.statusCode == 403) {
        location.reload();
      }
      if (deleteGroup?.statusCode == 403) {
        location.reload();
      }
      let message: any;
      let theme: any = "success";
      if (deleteGroup && deleteGroup.status) {
        let skip: number = 0;
        if (currentPage() != 1) {
          skip = (currentPage() - 1) * 10;
        }
        const apiParams = getApiParams(user_id, jwt_token, skip);
        let list = await fetchApi(apiParams);
        if (list?.statusCode == 401) {
          window.location.href = "/logout";
        } else if (list?.statusCode == 403) {
          location.reload();
        }
        setTotalPages(list.data.totalPages);
        setGroupsList(list.data.groupsList);
        setTotalItems(list.data.totalItems);
        message = messages.group.deleted;
      } else {
        message = messages.error.group_delete;
        theme = "error";
      }
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    } catch (err) {
      return err;
    }
  }

  async function searchGroups(
    searchString: any,
    user_id: string,
    jwt_token: string
  ) {
    setSearchString(searchString);
    let skip: number = 0;
    const apiParams = getApiParams(user_id, jwt_token, skip);
    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (list?.statusCode == 403) {
      location.reload();
    }
    setGroupsList(list.data.groupsList);
    setTotalPages(list.data.totalPages);
    setCurrentPage(1);
  }

  async function filterGroups(
    filterString: any,
    user_id: string,
    jwt_token: string
  ) {
    setSortString(filterString);
    let skip: number = 0;
    if (currentPage() != 1) {
      skip = (currentPage() - 1) * 10;
    }
    const apiParams = getApiParams(user_id, jwt_token, skip);
    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (list?.statusCode == 403) {
      location.reload();
    }
    setGroupsList(list.data.groupsList);
  }

  async function groupForms(
    user_id: string,
    jwt_token: string,
    name: string,
    id: string
  ) {
    let apiParams: any = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/list`,
      jwt_token: jwt_token as string,
      body: { user_id },
    };
    const groupForms = await fetchApi(apiParams);
    if (groupForms?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (groupForms?.statusCode == 403) {
      location.reload();
    }
    setAssignedForms(
      groupForms.data.filter((form: any) => form.assigneeGroup?.includes(id))
    );
    setOtherForms(
      groupForms.data.filter((form: any) => !form.assigneeGroup?.includes(id))
    );
    setPopup("userGroupForms");
    setFormGroupId(id);
    setFormGroupName(name);
  }

  return (
    <main>
      <Show when={popupQueue.includes("userGroupForms")}>
        <GroupForms
          user_id={data()?.user_id}
          id={formGroupId()}
          jwt_token={data()?.jwt_token}
          name={formGroupName()}
          mode="group"
        ></GroupForms>
      </Show>
      <Show when={popupQueue.includes("assigneeList")}>
        <AssigneeList
          list={assigneeList()}
          avatarList={assigneeAvatarsList()}
          heading="Assignee's List"
        ></AssigneeList>
      </Show>
      <Show when={popupQueue.includes("confirmGroupRemove")}>
        <ConfirmPopup
          message="<b>Removing this team will also remove shared form rights for associated users.<br/>Are you sure you want to proceed?</b>"
          name="confirmGroupRemove"
          onconfirm={(status: boolean) => {
            removePopup("confirmGroupRemove");
            if (status) {
              deleteGroup(
                groupIdToDelete(),
                data()?.jwt_token,
                data()?.user_id
              );
            }
            setGroupIdToDelete("");
          }}
        ></ConfirmPopup>
      </Show>
      <SystemLayout>
        <input type="hidden" name="user_id" value={data()?.user_id} />
        <div class="center">
          <div class="flex space-between app-w-50">
            <h2 class="title color">Manage Teams</h2>
            <A href="/group/add" class="btn primary float-right">
              <Icons name="plus" stroke="#fff" />
              &nbsp; Create Team
            </A>
          </div>
          <br />
          <div>
            <div class="flex app-flex space-between">
              <h3 class="title w-58">Teams List</h3>
              <div class="w-200">
                <Search
                  onEnter={(search: any) => {
                    searchGroups(search, data()?.user_id, data()?.jwt_token);
                  }}
                  placeholder="Search Team"
                ></Search>
              </div>
            </div>
            <table class="table groups-table" style={{ "--width": "100%" }}>
              <thead class="user-select-none">
                <tr>
                  <th>
                    <span>Team Name</span>
                    <span
                      class="float-right cur-p"
                      onclick={() => {
                        filterGroups(
                          sortString() == "" || sortString() == "asc_name"
                            ? "desc_name"
                            : "asc_name",
                          data()?.user_id,
                          data()?.jwt_token
                        );
                      }}
                    >
                      <Icons name="arrowsSort" height={16} width={16}></Icons>
                    </span>
                  </th>
                  <th>Users</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <Show
                  when={
                    getGroupsList()
                      ? getGroupsList()?.length == 0
                      : data()?.groupsList?.length == 0
                  }
                >
                  <tr class="text-center">
                    <td colSpan="5">
                      <small>
                        <b>No Team Data Found !!</b>
                      </small>
                    </td>
                  </tr>
                </Show>
                <For
                  each={getGroupsList() ? getGroupsList() : data()?.groupsList}
                >
                  {(group) => (
                    <tr>
                      <td>{group.name}</td>
                      <td>
                        <div class="form-users__group">
                          <For each={group.users}>
                            {(assignee: any, key: any) => (
                              <>
                                <Show when={key() < 8}>
                                  <div
                                    class="form-user"
                                    style={{
                                      "--z-index": group.users?.length - key(),
                                      "--background-color":
                                        group.usersAvatar[key()] != ""
                                          ? "transparant"
                                          : userIcons().find(
                                              (user: any) =>
                                                user.name == assignee
                                            )?.theme,
                                    }}
                                    data-title={assignee}
                                  >
                                    <Show when={group.usersAvatar[key()] != ""}>
                                      <img
                                        class="form-user"
                                        src={group.usersAvatar[key()]}
                                      ></img>
                                    </Show>
                                    <Show when={group.usersAvatar[key()] == ""}>
                                      {
                                        userIcons().find(
                                          (user: any) => user.name == assignee
                                        )?.logo
                                      }
                                    </Show>
                                  </div>
                                </Show>
                                <Show
                                  when={
                                    group.users?.length > 8 &&
                                    group.users?.length - 1 == key()
                                  }
                                >
                                  <div
                                    class="form-user"
                                    style={{
                                      "--z-index": group.users?.length - key(),
                                      "--background-color": "#000000",
                                    }}
                                    data-title="more"
                                    onclick={() => {
                                      setAssigneeList(group.users);
                                      setAssigneeAvatarsList(group.usersAvatar);
                                      setPopup("assigneeList");
                                    }}
                                  >
                                    <Icons name="plus" stroke="#ffffff"></Icons>
                                  </div>
                                </Show>
                              </>
                            )}
                          </For>
                        </div>
                      </td>
                      <td class="w-400">
                        <A
                          href={`/group/${group.id}/edit`}
                          class="btn success sm mb-5"
                        >
                          <Icons name="pencil" stroke="#fff" width={18} />
                          <span>&nbsp; Manage</span>
                        </A>
                        &nbsp;
                        <button
                          class="btn danger sm mb-5"
                          onclick={() => {
                            setGroupIdToDelete(group.id);
                            setPopup("confirmGroupRemove");
                          }}
                        >
                          <Icons name="x" stroke="#fff" width={18} />
                          <span>&nbsp;Delete</span>
                        </button>
                        &nbsp;
                        <button
                          class="btn primary sm"
                          onclick={() =>
                            groupForms(
                              data()?.user_id,
                              data()?.jwt_token,
                              group.name,
                              group.id
                            )
                          }
                        >
                          <Icons name="formTab" stroke="#fff" width={18} />
                          <span>&nbsp;Share Forms</span>
                        </button>
                      </td>
                    </tr>
                  )}
                </For>
              </tbody>
            </table>
          </div>
          <Show
            when={
              getGroupsList()
                ? getGroupsList()?.length != 0
                : data()?.groupsList?.length != 0
            }
          >
            <Pagination
              currentPage={currentPage()}
              totalPages={totalPages() ? totalPages() : data()?.totalPages}
              onPageChange={(page: any) =>
                handlePageChange(page, data()?.jwt_token, data()?.user_id)
              }
              itemsPerPage={10}
              totalItems={
                getTotalItems() ? getTotalItems() : data()?.totalItems
              }
              itemPerPageFilter={true}
            ></Pagination>
          </Show>
        </div>
      </SystemLayout>
    </main>
  );
};

export default groups;
