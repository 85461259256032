import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import {
  RouteDataArgs,
  useNavigate,
  useParams,
  useRouteData,
} from "solid-start";
import FieldEditor from "~/components/builder/FieldEditor";
import Icons from "~/components/icons/Icons";
import Tabs from "~/components/global/Tabs";
import { formBuilderSidebarItems } from "~/store/field-editor.store";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { validateUser } from "~/routes/api/validateUser";
import { setPageTitle } from "~/root";
import { SITE_TITLE } from "~/consts";
import {
  activeField,
  alertMessage,
  builderRedirection,
  builderSidebarOpen,
  builderToolbarOpen,
  contextMenu,
  contextMenuLeft,
  contextMenuTop,
  currentformName,
  cutCopyField,
  fieldsToLink,
  formFieldsToLink,
  formScale,
  isBuilderPageDropdownOpen,
  isMobile,
  linkedField,
  linkedFormValues,
  linkedLinkValues,
  linkedParentValues,
  nestedList,
  popupQueue,
  removeLastFomrPopup,
  removePopup,
  selectedDropDown,
  setActiveField,
  setAlertMessage,
  setBuilderRedirection,
  setBuilderToolbarOpen,
  setContextMenu,
  setContextMenuLeft,
  setContextMenuTop,
  setCurrentFormId,
  setCurrentformName,
  setFieldsToLink,
  setFormScale,
  setFormValues,
  setIsBuilderPageDropdownOpen,
  setIsFormUpdated,
  setIsMobile,
  setIsPageLoader,
  setLinkedField,
  setLinkedFieldLabel,
  setLinkedFormValues,
  setLinkedLinkValues,
  setLinkedParentValues,
  setPopup,
  setRecordDeleteRight,
  setSelectedDropDown,
  setlinkChangeData,
} from "~/store/global.store";
import {
  setCalculatedArr,
  setIsFormChangesCanceled,
} from "~/store/records.store";
import { currentPage, setCurrentPage } from "~/components/global/Pagination";
import {
  createFormJson,
  formJson,
  removeFormPage,
  setCurrenFormSavedFields,
  setFormJson,
  setFormLastPageId,
  setIsFormJsonUpdated,
  setTotalFormPages,
  totalFormPages,
  updateFormJson,
  updateFormJsonPages,
} from "~/store/form.store";
import FieldLinkerPopup, {
  formFieldLinkType,
  formFieldRemoveValue,
} from "~/components/popup/FieldLinkerPopup";
import LinkFieldPopup, {
  changedLinkedItems,
  selectedItem,
  setChangedLinkedItems,
  setSelectedItem,
} from "~/components/popup/LinkFieldPopup";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import { saveFormSettings } from "~/components/form/formSettings";
import ChooseScriptItems from "~/components/popup/ChooseScriptItems";
import EmailSettings from "~/components/popup/EmailSettings";
import ContextMenu from "~/components/global/ContextMenu";
import Guidline from "~/components/builder/Guidline";
import FormField from "~/components/fields/FormField";
import Notification from "~/components/global/Notification";
import FormNavbar, { selectedFormId } from "~/components/global/FormNavbar";
import convert from "~/helpers/convertor";
import FormSettingsPopup from "~/components/popup/FormSettingsPopup";
import { FRAME_HEIGHT, FRAME_WIDTH } from "~/helpers/constant";
import { setToolbarButtons } from "~/components/form/ToolbarButtonsSettings";
import { userRole } from "~/store/user-form-role";
import { setSelectedTableLabel } from "~/components/fields/LabelField";

export function routeData({ params }: RouteDataArgs) {
  validateUser();
  return createServerData$(
    async ([, id], { request }) => {
      const userDetails = await getUser(request);
      if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
        throw redirect("/logout");
      }
      const user_id = userDetails.data.id;
      const jwt_token: any = await getAuthToken(request);

      return {
        user_id,
        userDetails,
        jwt_token,
      };
    },
    { key: () => ["groupId", params.id] }
  );
}

const builder: Component = () => {
  const navigate = useNavigate();
  const data: any = useRouteData<typeof routeData>();
  const { id } = useParams();
  const [otherSpace, setOtherSpace] = createSignal(0 as number);
  const [isMounted, setIsMounted] = createSignal(false as boolean);

  let windowWidth: number = 0;
  let windowHeight: number = 0;
  let leftBarWidth: number = 236;
  let rightBarWidth: number = 300;
  let formWidth: number = 770;
  let formMargin: number = 32;

  onMount(() => {
    setIsMounted(true);
    // Close popup/popover on esc
    document.addEventListener("keyup", (event) => {
      if (event.key === "Escape") {
        removeLastFomrPopup();
      }
    });
    if (window.innerWidth <= 450) {
      setFormScale((window.innerWidth * 0.435) / 375);
      setIsMobile(true);
    } else {
      setFormScale(
        localStorage.getItem("fc_form_scale")
          ? Number(localStorage.getItem("fc_form_scale"))
          : 1
      );
    }
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
    setOtherSpace(
      (windowWidth - leftBarWidth - rightBarWidth - formMargin - formWidth) / 2
    );
  });
  createEffect(async () => {
    if (
      data()?.userDetails?.data?.role?.role_name &&
      data()?.userDetails?.data?.role?.role_name != "admin" &&
      data()?.userDetails?.data?.role?.role_name != "sub_admin" &&
      data()?.userDetails?.data?.role?.role_name != "designer"
    ) {
      location.reload();
    }
    if (id) {
      setCurrentFormId(id);
    }
    if (
      data()?.jwt_token &&
      isMounted() &&
      (formJson.pages?.length == 0 || formJson.pages?.length == undefined)
    ) {
      setIsMounted(false);
      const formStructureApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/view`,
        jwt_token: data()?.jwt_token as string,
        body: { form_id: id, user_id: data()?.user_id, limit: 1 },
      };
      let formStructure = await fetchApi(formStructureApiParams);
      if (
        formStructure?.statusCode == 401 ||
        formStructure?.statusCode == 403
      ) {
        window.location.href = "/logout";
      }
      if (formStructure.status && formStructure.data != null) {
        setFormLastPageId(formStructure.data.lastPageId);
        let formPages: any = [];
        for (let i = 0; i < formStructure.data.totalPages; i++) {
          formPages.push({});
        }
        if (userRole() != "admin") {
          setRecordDeleteRight(formStructure.data.recordDeleteRight);
        } else {
          setRecordDeleteRight("true");
        }
        setCurrentformName(formStructure.data.name);
        setTotalFormPages(formStructure.data.totalPages);
        setPageTitle(`${SITE_TITLE} - ${formStructure.data.name}`);
        formStructure = JSON.parse(formStructure.data.structure);
        formPages[0] = formStructure.pages;
        formPages = formPages.flatMap((page: any) => page);
        formPages = formPages.map((page: any) => {
          if (!page.fields) {
            return page;
          }
          return {
            ...page,
            fields: page.fields.map((field: any) => {
              if (field.properties?.dataFieldSync?.frame?.[0]?.length === 1) {
                return {
                  ...field,
                  properties: {
                    ...field.properties,
                    dataFieldSync: {
                      ...field.properties.dataFieldSync,
                      frame: field.properties.dataFieldSync.frame.map(
                        (subArray: any, index: number) =>
                          index === 0 && typeof subArray[0] === "string"
                            ? subArray[0].split(",").map(Number)
                            : subArray
                      ),
                    },
                  },
                };
              }
              return field;
            }),
          };
        });
        setFormJson({
          root: {
            formStructure: {
              ...formStructure,
              pages: formPages,
            },
          },
          pages: formPages,
        });
        setToolbarButtons([]);
        setCurrenFormSavedFields(
          formJson.root?.formStructure?.pages.flatMap((page: any) =>
            page.fields?.map((field: any) => field.properties.dataFieldSync.uid)
          )
        );
        setIsPageLoader(false);
        const pdfApiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/pdf-generate`,
          jwt_token: data()?.jwt_token,
          body: {
            form_id: id,
            user_id: data()?.user_id,
            export_formats: "PDF",
            barcodeUrls: [],
          },
        };
        let result = await fetchApi(pdfApiParams);
        if (result?.statusCode == 401 || result?.statusCode == 403) {
          window.location.href = "/logout";
        }
      }
    }
    if (formJson.root?.formStructure) {
      if (formJson.currentField.dataFieldSync) {
        const arr: any = [];
        setLinkedFieldLabel("");
        formJson.root?.formStructure?.pages?.forEach((data: any, key: any) => {
          const page: any = "Page " + Number(key + 1);
          const LinkedFieldPageData: any = [];
          const CheckboxFieldPageData: any = [];
          const DropdownFieldPageData: any = [];
          const RadioFieldPageData: any = [];
          const DateFieldPageData: any = [];
          data?.fields?.forEach((field: any) => {
            if (
              field.properties?.inputType == "text" ||
              field.properties?.inputType == "number" ||
              field.properties?.inputType == "email" ||
              field.properties?.inputType == "url" ||
              field.properties?.inputType == "currency" ||
              field.properties?.inputType == "calculated" ||
              field.properties?.inputType == "autoIncrement"
            ) {
              const pageObject: any = {
                id: field.properties.dataFieldSync?.uid,
                label: field.properties.dataFieldSync?.label?.replace(/:$/, ""),
              };
              LinkedFieldPageData.push(pageObject);
              if (
                formJson.currentField.dataFieldSync?.linkedFieldId ==
                field.properties.dataFieldSync?.uid
              ) {
                setLinkedField(field.properties.dataFieldSync?.uid);
                setTimeout(() => {
                  setLinkedFieldLabel(
                    field.properties.dataFieldSync?.label.replace(/:$/, "") +
                      " (" +
                      page +
                      ")"
                  );
                }, 0);
              }
            }
            if (
              field.type == "CheckboxField" ||
              field.type == "DropdownField" ||
              field.type == "RadioField" ||
              field.type == "DateField"
            ) {
              if (
                formJson.currentField.dataFieldSync?.uid !=
                field.properties.dataFieldSync?.uid
              ) {
                const pageObject: any = {
                  id: field.properties.dataFieldSync?.uid,
                  label: field.properties.dataFieldSync?.label?.replace(
                    /:$/,
                    ""
                  ),
                };
                if (field.type == "CheckboxField") {
                  CheckboxFieldPageData.push(pageObject);
                } else if (field.type == "RadioField") {
                  RadioFieldPageData.push(pageObject);
                } else if (field.type == "DropdownField") {
                  DropdownFieldPageData.push(pageObject);
                } else {
                  DateFieldPageData.push(pageObject);
                }
                if (
                  formJson.currentField.dataFieldSync?.linkedFieldId ==
                  field.properties.dataFieldSync?.uid
                ) {
                  setLinkedField(field.properties.dataFieldSync?.uid);
                  setTimeout(() => {
                    setLinkedFieldLabel(
                      field.properties.dataFieldSync?.label.replace(/:$/, "") +
                        " (" +
                        page +
                        ")"
                    );
                  }, 0);
                }
              }
            }
          });
          if (
            formJson.currentField.dataFieldSync?.type == "TextField" &&
            LinkedFieldPageData.length > 0
          ) {
            arr.push({ page, pageData: LinkedFieldPageData });
            setFieldsToLink(arr);
          }
          if (
            formJson.currentField.dataFieldSync?.type == "CheckBoxField" &&
            CheckboxFieldPageData.length > 0
          ) {
            arr.push({ page, pageData: CheckboxFieldPageData });
            setFieldsToLink(arr);
          }
          if (
            formJson.currentField.dataFieldSync?.type == "DropdownField" &&
            DropdownFieldPageData.length > 0
          ) {
            arr.push({ page, pageData: DropdownFieldPageData });
            setFieldsToLink(arr);
          }
          if (
            formJson.currentField.dataFieldSync?.type == "RadioButtonField" &&
            RadioFieldPageData.length > 0
          ) {
            arr.push({ page, pageData: RadioFieldPageData });
            setFieldsToLink(arr);
          }
          if (
            formJson.currentField.dataFieldSync?.type == "DateField" &&
            DateFieldPageData.length > 0
          ) {
            arr.push({ page, pageData: DateFieldPageData });
            setFieldsToLink(arr);
          }
        });
      }

      const arr: any = [];
      formJson.root?.formStructure?.pages.forEach((page: any, key: any) => {
        const pageArr: any = [];
        page?.fields?.forEach((field: any) => {
          if (
            field.type != "LabelField" &&
            field.type != "LineField" &&
            field.type != "TableField" &&
            field.type != "ImageField" &&
            field.type != "ScriptButtonField"
          ) {
            const tempObject: any = {
              id: field.properties.dataFieldSync.uid,
              label: field.properties.dataFieldSync.label,
              top: field.properties.dataFieldSync.frame[0][1],
              left: field.properties.dataFieldSync.frame[0][0],
              page: key,
            };
            pageArr.push(tempObject);
          }
        });
        arr.push(pageArr);
      });
      arr.forEach((item: any) => {
        item.sort((a: any, b: any) => {
          if (a.page !== b.page) {
            return a.page - b.page;
          }
          if (a.top === b.top) {
            return a.left - b.left;
          }
          return a.top - b.top;
        });
      });
      const finalArr: any = [];
      arr.forEach((item: any, index: any) => {
        if (item.length > 0) {
          const pageChar: any = String.fromCharCode(65 + index);
          item.forEach((val: any, index2: any) => {
            const key = val.id;
            const value = pageChar + ((index2 % item.length) + 1);
            finalArr[key] = value;
          });
        }
      });
      setCalculatedArr(finalArr);
    }
    if (formJson.currentField) {
      setActiveField(formJson.currentField.dataFieldSync?.type);
    }
    if (
      Object.keys(formJson.currentField).length > 0 &&
      formJson.currentField.dataFieldSync.type != "TableField"
    ) {
      setBuilderToolbarOpen(true);
    } else {
      setBuilderToolbarOpen(false);
    }
  });

  createEffect(() => {
    if (formJson.currentPage) {
      const formFrame = document.querySelector(".builder-container");
      formFrame?.scrollTo({ top: 0 });
    }
  });

  return (
    <>
      <Notification />
      <Show when={popupQueue.includes("FieldLinker")}>
        <FieldLinkerPopup
          jwt_token={data()?.jwt_token}
          user_id={data()?.user_id}
        ></FieldLinkerPopup>
      </Show>
      <Show when={popupQueue.includes("linkField")}>
        <LinkFieldPopup name="linkField"></LinkFieldPopup>
      </Show>
      <Show when={popupQueue.includes("formLinkField")}>
        <LinkFieldPopup
          name="formLinkField"
          data={formFieldsToLink()}
          type={formFieldLinkType()}
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></LinkFieldPopup>
      </Show>
      <Show when={popupQueue.includes("formSettings")}>
        <FormSettingsPopup
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          form_id={selectedFormId()}
        ></FormSettingsPopup>
      </Show>
      <ConfirmPopup
        message={
          popupQueue.includes("warning") ||
          popupQueue.includes("scriptBtnParamAlert")
            ? alertMessage()
            : popupQueue.includes("confirmFormChanges") ||
              popupQueue.includes("confirmFormSettingsChanges")
            ? "<b>Save Changes to this form?</b>"
            : popupQueue.includes("confirmUpdateSelectedItems")
            ? "<b>Both Fields have different items in them.<br/>Do you want to proceed?<br/>It'll replace current field's items with the linked field's items.</b>"
            : popupQueue.includes("indexFormAlert")
            ? "<b>Index Form Requires Multi Page Form</b>"
            : popupQueue.includes("tableChangesAlert")
            ? "<b>Not Enough Space..</b>"
            : popupQueue.includes("pageRemoveConfirm")
            ? "<b>This page will be removed permanently.<br/>Are you sure you want to remove page?</b>"
            : ""
        }
        name={
          popupQueue.includes("warning")
            ? "warning"
            : popupQueue.includes("confirmFormChanges")
            ? "confirmFormChanges"
            : popupQueue.includes("indexFormAlert")
            ? "indexFormAlert"
            : popupQueue.includes("confirmFormSettingsChanges")
            ? "confirmFormSettingsChanges"
            : popupQueue.includes("tableChangesAlert")
            ? "tableChangesAlert"
            : popupQueue.includes("scriptBtnParamAlert")
            ? "scriptBtnParamAlert"
            : popupQueue.includes("pageRemoveConfirm")
            ? "pageRemoveConfirm"
            : "confirmUpdateSelectedItems"
        }
        warning={popupQueue.includes("warning") ? true : false}
        alert={
          popupQueue.includes("indexFormAlert") ||
          popupQueue.includes("tableChangesAlert") ||
          popupQueue.includes("scriptBtnParamAlert")
            ? true
            : false
        }
        onconfirm={(status: boolean) => {
          if (popupQueue.includes("warning")) {
            if (status) {
              let parentFieldArr: any = linkedParentValues().filter(
                (obj: any) => {
                  return obj.id !== formFieldRemoveValue();
                }
              );
              setLinkedParentValues(parentFieldArr);
              let childFormArr = linkedFormValues().filter((obj: any) => {
                return obj.id !== formFieldRemoveValue();
              });
              childFormArr = childFormArr.filter((obj: any) => {
                return obj.parentField !== formFieldRemoveValue();
              });
              setLinkedFormValues(childFormArr);
              let uniqueChildFormArr = childFormArr.filter(
                (item: any, index: any, self: any) =>
                  index === self.findIndex((t: any) => t.id === item.id)
              );
              setFormValues(uniqueChildFormArr);
              let childFieldArr = linkedLinkValues().filter((obj: any) => {
                return obj.childForm !== formFieldRemoveValue();
              });
              childFieldArr = childFieldArr.filter((obj: any) => {
                return obj.parentField !== formFieldRemoveValue();
              });
              setLinkedLinkValues(childFieldArr);
            }
            setLinkedField("");
            removePopup("warning");
          } else if (popupQueue.includes("confirmFormChanges")) {
            if (status) {
              if (
                formJson.pages
                  .flatMap((page: any) => page.fields)
                  .findIndex(
                    (field: any) =>
                      field.type == "CheckboxField" ||
                      field.type == "SignatureField" ||
                      field.type == "PhotoField" ||
                      field.type == "AnnotationField"
                  ) != -1 &&
                formJson.pages
                  .flatMap((page: any) => page.fields)
                  .findIndex(
                    (field: any) =>
                      field.type == "TextField" ||
                      field.type == "DateField" ||
                      field.type == "DropdownField" ||
                      field.type == "LocationField" ||
                      field.type == "BarcodeField" ||
                      field.type == "RadioField"
                  ) == -1
              ) {
                setTimeout(() => {
                  removePopup("confirmFormChanges");
                  setAlertMessage(
                    "<b>You must create at least one primary field such as text or date field before you can save the form.</b>"
                  );
                  setPopup("scriptBtnParamAlert");
                }, 100);
              } else {
                setTimeout(() => {
                  removePopup("confirmFormChanges");
                }, 100);
                saveFormSettings(data()?.user_id, data()?.jwt_token, id);
              }
            } else {
              setIsFormJsonUpdated(false);
              setIsFormChangesCanceled(true);
            }
            if (
              (status &&
                formJson.pages
                  .flatMap((page: any) => page.fields)
                  .findIndex(
                    (field: any) =>
                      field.type == "TextField" || field.type == "DateField"
                  ) != -1) ||
              !status
            ) {
              setTimeout(() => {
                removePopup("confirmFormChanges");
              }, 100);
              setIsFormJsonUpdated(false);
              setIsFormUpdated(false);
              navigate(
                `${
                  builderRedirection() == "/form/list"
                    ? "/form/list"
                    : `/form/render/${id}`
                }`
              );
              setBuilderRedirection("");
            }
          } else if (popupQueue.includes("indexFormAlert")) {
            removePopup("indexFormAlert");
          } else if (popupQueue.includes("confirmFormSettingsChanges")) {
            if (status) {
            } else {
              setFormJson({
                pages: formJson.root?.formStructure?.pages,
              });
            }
            removePopup("confirmFormSettingsChanges");
            removePopup("formSettings");
          } else if (popupQueue.includes("tableChangesAlert")) {
            removePopup("tableChangesAlert");
          } else if (popupQueue.includes("scriptBtnParamAlert")) {
            removePopup("scriptBtnParamAlert");
          } else if (popupQueue.includes("pageRemoveConfirm")) {
            if (status) {
              removeFormPage(data()?.user_id, data()?.jwt_token, id);
            }
            removePopup("pageRemoveConfirm");
          } else {
            if (status) {
              if (linkedField() != "") {
                nestedList()
                  ? updateFormJson(
                      "choiceFieldSync",
                      "linkedFieldId",
                      selectedItem(),
                      true
                    )
                  : updateFormJson(
                      formJson.currentField.dataFieldSync?.type ==
                        "RadioButtonField" ||
                        formJson.currentField.dataFieldSync?.type ==
                          "DropdownField"
                        ? "choiceFieldSync"
                        : "dataFieldSync",
                      "linkedFieldId",
                      selectedItem()
                    );
                if (changedLinkedItems().length > 0) {
                  updateFormJson(
                    "choiceFieldSync",
                    "items",
                    changedLinkedItems()
                  );
                }
                setSelectedItem("");
                setChangedLinkedItems([]);
              }
              setlinkChangeData(true);
              removePopup("linkField");
            } else {
              setLinkedField("");
              setLinkedFieldLabel("");
            }
            removePopup("confirmUpdateSelectedItems");
          }
        }}
      ></ConfirmPopup>
      <Show when={popupQueue.includes("scriptItems")}>
        <ChooseScriptItems data={fieldsToLink()}></ChooseScriptItems>
      </Show>
      <Show when={popupQueue.includes("emailSettings")}>
        <EmailSettings></EmailSettings>
      </Show>
      <Show when={contextMenu() == "form"}>
        <ContextMenu
          top={contextMenuTop()}
          left={contextMenuLeft()}
          field="form"
        ></ContextMenu>
      </Show>
      <div
        class="form-builder"
        onclick={(e: any) => {
          const element: any = document.getElementById("addRemovePages");
          if (element?.contains(e.target)) {
            selectedDropDown() == ""
              ? setSelectedDropDown("add_page")
              : setSelectedDropDown("");
          } else {
            setSelectedDropDown("");
          }
          const element2: any = document.querySelector(
            ".builder-pagination-dropdown"
          );
          if (element2?.contains(e.target)) {
            setIsBuilderPageDropdownOpen(true);
          } else {
            setIsBuilderPageDropdownOpen(false);
          }
          const element3: any = document.querySelectorAll(".tableLabelHeader");
          let count: any = 0;
          element3.forEach((el: any) => {
            if (el.contains(e.target)) {
              count++;
            }
          });
          if (count == 0) {
            setSelectedTableLabel("");
          }
        }}
      >
        <FormNavbar
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          formName={currentformName()}
          formId={id}
          active="builder"
        ></FormNavbar>
        <div class="builder-body">
          <div
            class={`builder-sidebar ${builderSidebarOpen() ? "toggle" : ""}`}
          >
            <Tabs />
            <div class="builder-sidebar__body">
              <br />
              <ul class="builder-fields">
                <For each={formBuilderSidebarItems}>
                  {(item) => (
                    <>
                      <li
                        class={
                          activeField() == item.value ||
                          formJson.currentField?.dataFieldSync?.type ==
                            item.value
                            ? "active"
                            : ""
                        }
                        onclick={(e: any) => {
                          e.preventDefault();
                          createFormJson(item.value, formJson.currentPage);
                        }}
                        ondragstart={(e: any) => {
                          e.dataTransfer.effectAllowed = "move";
                          e.dataTransfer.setData("text/plain", item.value);
                        }}
                        draggable={true}
                      >
                        <Icons name={item.icon} />
                        &nbsp;
                        {item.label}
                      </li>
                    </>
                  )}
                </For>
              </ul>
            </div>
          </div>
          <div
            class={`builder-container ${isMobile() ? "pr overflow-unset" : ""}`}
          >
            <div
              ondragover={(e: any) => {
                e.preventDefault();
                e.dataTransfer.dropEffect = "move";
                const topBarHeight = 50;
                const paginationHeight = 75;
                const formMargin = 32;
                const fieldHeight = Number(convert(0.375, "in-px"));
                const windowHeight = window.innerHeight;
                const fieldTop =
                  windowHeight -
                  (topBarHeight +
                    paginationHeight +
                    formMargin / 2 +
                    fieldHeight);
                const fieldBottom =
                  windowHeight - topBarHeight - paginationHeight;

                // Scrolling down
                if (e.clientY - topBarHeight - formMargin / 2 > fieldTop) {
                  const element: any =
                    document.querySelector(".builder-container");
                  element.scrollTop +=
                    e.clientY - topBarHeight - formMargin / 2 - fieldTop;
                }
                // Scrolling up
                if (e.clientY - topBarHeight - formMargin / 2 < fieldBottom) {
                  const element: any =
                    document.querySelector(".builder-container");
                  element.scrollTop -=
                    fieldBottom - e.clientY - topBarHeight - formMargin / 2;
                }
              }}
              ondrop={(e: any) => {
                e.preventDefault();
                const fieldValue = e.dataTransfer.getData("text/plain");
                const windowWidth = window.innerWidth;
                const leftBarWidth = 236;
                const rightBarWidth = 300;
                const topBarHeight = 50;
                const formWidth = 770;
                const formMargin = 32;
                const otherSpace =
                  (windowWidth -
                    leftBarWidth -
                    rightBarWidth -
                    formMargin -
                    formWidth) /
                  2;

                const left =
                  e.clientX -
                  leftBarWidth -
                  formMargin / 2 -
                  (otherSpace > 0 ? otherSpace : 0);
                const element: any =
                  document.querySelector(".builder-container");
                const top =
                  e.clientY + element.scrollTop - topBarHeight - formMargin / 2;
                createFormJson(fieldValue, formJson.currentPage, left, top);
              }}
              class={`builder-form grid ${isMobile() ? "origin-left" : ""}`}
              style={{
                "--width": Number(convert(FRAME_WIDTH, "in-px")) + "px",
                "--height": Number(convert(FRAME_HEIGHT, "in-px")) + "px",
                "--scale": formScale(),
                "--scale-margin-bottom":
                  Math.round(
                    formScale() < 1
                      ? -1 *
                          ((1 - formScale()) *
                            Number(convert(FRAME_HEIGHT, "in-px")))
                      : (formScale() - 1) *
                          Number(convert(FRAME_HEIGHT, "in-px")) +
                          28
                  ) + "px",
                "--scale-margin-left":
                  ((formScale() - 1) * 400 - 16 > otherSpace()
                    ? (formScale() - 1) * 400 - 16
                    : otherSpace()) + "px",
              }}
              id="form_frame"
              onmousedown={(e: any) => {
                if (
                  e.target.id == "form_frame" ||
                  Array.from(e.target.classList).includes("field-group")
                ) {
                  setContextMenu("");
                  setTimeout(() => {
                    setFormJson({
                      currentField: {},
                      multipleFields: [],
                    });
                  }, 0);
                }
              }}
              oncontextmenu={(e: any) => {
                if (e.target.id == "form_frame" && cutCopyField().length > 0) {
                  e.preventDefault();
                  setTimeout(() => {
                    setContextMenuLeft(e.clientX);
                    setContextMenuTop(e.clientY);
                    setContextMenu("form");
                  }, 0);
                } else if (
                  e.target.id == "form_frame" &&
                  cutCopyField().length == 0
                ) {
                  setContextMenu("");
                }
              }}
            >
              <For each={formJson?.pages[formJson.currentPage]?.fields}>
                {(field) => <FormField data={field} fieldsType="designer" />}
              </For>
              <Show
                when={
                  formJson?.pages[formJson.currentPage]?.fields?.length == 0
                }
              >
                <div
                  class="builder_info"
                  style={{ "--top": window.innerHeight / 2 - 152 + "px" }}
                >
                  <Icons name="sort"></Icons>
                  <span class="lbl">Click or drag and drop a field</span>
                </div>
              </Show>
              <Guidline></Guidline>
            </div>
            <div class="underline form" style={{ "--left": "236px" }}></div>
            <div class="mb-28"></div>
            <div
              class={`form-pagination flex app-flex space-between align-center ${
                isMobile() ? "pa p0 pr-10" : ""
              }`}
              style={{
                "--left": "236px",
                "--width":
                  Number(convert(FRAME_WIDTH, "in-px")) +
                  otherSpace() * 2 +
                  27 +
                  "px",
                "--padding": `0px ${otherSpace() + 16}px`,
              }}
            >
              <div class="flex align-center bg-light app-dn br-8">
                <button
                  class={`btn btn-ico small ${
                    formScale() <= 0.25 ? "bg-gray disabled" : ""
                  }`}
                  onclick={() => {
                    setFormScale(Number((formScale() - 0.05).toFixed(2)));
                    localStorage.setItem("fc_form_scale", String(formScale()));
                  }}
                >
                  <Icons
                    name="minus"
                    stroke="#525657"
                    width={18}
                    height={18}
                  ></Icons>
                </button>
                <button
                  class="btn btn-ico small"
                  onclick={() => {
                    setFormScale(1);
                    localStorage.setItem("fc_form_scale", "1");
                  }}
                >
                  <label class="lbl user-select-none">Fit</label>
                </button>
                <button
                  class={`btn btn-ico small ${
                    formScale() >= 1.5 ? "bg-gray disabled" : ""
                  }`}
                  onclick={() => {
                    setFormScale(Number((formScale() + 0.05).toFixed(2)));
                    localStorage.setItem("fc_form_scale", String(formScale()));
                  }}
                >
                  <Icons
                    name="plus"
                    stroke="#525657"
                    width={18}
                    height={18}
                  ></Icons>
                </button>
              </div>
              <div class="flex align-center gap">
                <Show when={currentPage() != 1}>
                  <span
                    class="mt-4 cur-p"
                    onclick={() => {
                      setCurrentPage(currentPage() - 1);
                      setFormJson({
                        currentField: {},
                        currentPage: currentPage() - 1,
                      });
                      updateFormJsonPages(
                        data()?.user_id,
                        data()?.jwt_token,
                        id
                      );
                    }}
                  >
                    <Icons
                      name="boldArrowLeft"
                      stroke="#525657"
                      width={26}
                      height={26}
                    ></Icons>
                  </span>
                </Show>
                <span class="pr">
                  <label
                    class="lbl fs-14 cur-p user-select-none builder-pagination-dropdown"
                    onclick={() => {
                      setIsBuilderPageDropdownOpen(
                        isBuilderPageDropdownOpen() ? false : true
                      );
                    }}
                  >
                    Page {currentPage()}
                  </label>
                  <Show when={isBuilderPageDropdownOpen()}>
                    <div class="dropdown builder-pagination">
                      <span class="dropdown-caret"></span>
                      <ul class="dropdown-list">
                        <For
                          each={Array.from(
                            { length: totalFormPages() },
                            (_, i) => i
                          ).map((page: any) => page + 1)}
                        >
                          {(item: any) => (
                            <li
                              class={`dropdown-items ${
                                item == currentPage() ? "active" : ""
                              }`}
                            >
                              <a
                                onclick={() => {
                                  setCurrentPage(item);
                                  setFormJson({
                                    currentField: {},
                                    currentPage: item - 1,
                                  });
                                  updateFormJsonPages(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    id
                                  );
                                  setIsBuilderPageDropdownOpen(false);
                                }}
                              >{`Page ${item}`}</a>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </Show>
                </span>
                <Show
                  when={
                    totalFormPages() > 1 && currentPage() != totalFormPages()
                  }
                >
                  <span
                    class="mt-4 cur-p"
                    onclick={() => {
                      setFormJson({
                        currentField: {},
                        currentPage: currentPage(),
                      });
                      setCurrentPage(currentPage() + 1);
                      updateFormJsonPages(
                        data()?.user_id,
                        data()?.jwt_token,
                        id
                      );
                    }}
                  >
                    <Icons
                      name="boldArrowRight"
                      stroke="#525657"
                      width={26}
                      height={26}
                    ></Icons>
                  </span>
                </Show>
              </div>
              <div class="w-50p user-select-none">&nbsp;</div>
            </div>
            <Show when={formJson.root?.formStructure?.indexTabs?.length > 0}>
              <ul
                class="index-tabs"
                style={{
                  "--left":
                    Math.ceil(
                      windowWidth -
                        rightBarWidth -
                        formMargin -
                        otherSpace() +
                        (768 * formScale() - 768) / 2 +
                        1
                    ) + "px",
                  "--height":
                    (1056 * formScale() < windowHeight - 80
                      ? 1056 * formScale() - 5
                      : windowHeight - 80) + "px",
                }}
              >
                <For each={formJson.root?.formStructure?.indexTabs}>
                  {(item: any, key: any) => (
                    <li
                      class={`tab-bg-${(key() + 1) % 6}`}
                      onclick={() => {
                        setCurrentPage(item.page);
                        setFormJson({
                          currentField: {},
                          currentPage: item.page - 1,
                        });
                        updateFormJsonPages(
                          data()?.user_id,
                          data()?.jwt_token,
                          id
                        );
                      }}
                    >
                      {item.tabTitle}
                    </li>
                  )}
                </For>
              </ul>
            </Show>
          </div>
          <div
            class={`builder-toolbar ${builderToolbarOpen() ? "toggle" : ""}`}
          >
            <FieldEditor
              user_id={data()?.user_id}
              jwt_token={data()?.jwt_token}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default builder;
