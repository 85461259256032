import { Component, createEffect, createSignal, onMount } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { setPageTitle } from "~/root";
import { SITE_TITLE } from "~/consts";
import { validateUser } from "./api/validateUser";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import BackLink from "~/components/micro/BackLink";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id: any = userDetails.data.id;

    const subscriptionDetailsApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/details`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        just_subscription_data: true,
      },
    };
    let subscriptionDetails = await fetchApi(subscriptionDetailsApiParams);
    if (subscriptionDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/transcation/data`,
      jwt_token,
      body: {
        subscription_id: subscriptionDetails.data.user_subscription_id,
      },
    };
    const transcation = await fetchApi(apiParams);
    if (transcation?.statusCode == 401) {
      throw redirect("/logout");
    }

    const paddleInfo: any = {
      token: process.env.PADDLE_TOKEN,
    };

    return {
      user_id,
      jwt_token,
      transcation,
      paddleInfo,
    };
  });
}

const cardUpdate: Component = () => {
  setPageTitle(`${SITE_TITLE} - Update`);

  const environmentPaddle = import.meta.env["VITE_PADDLE_ENVIRONMENT"];
  const data = useRouteData<typeof routeData>();

  const [paddle, setPaddle] = createSignal<Paddle>();

  onMount(() => {
    initializePaddle({
      environment: environmentPaddle,
      token: data()?.paddleInfo.token,
      checkout: {
        settings: {
          showAddDiscounts: false,
        },
      },
      eventCallback: function (data: any) {
        if (data.name == "checkout.completed") {
          setTimeout(() => {
            location.href = "/billing-payments";
          }, 2000);
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  });

  createEffect(() => {
    if (data()?.jwt_token && data()?.transcation) {
      updateCard(data()?.jwt_token, data()?.transcation.data);
    }
  });

  async function updateCard(jwt_token: string, transactionId: string) {
    paddle()?.Checkout.open({
      settings: {
        displayMode: "inline",
        frameTarget: "checkout-container",
        frameInitialHeight: 450,
        frameStyle: "width: 650px; border: none",
      },
      transactionId,
    });
  }

  return (
    <main>
      <SystemLayout>
        <div>
          <input type="hidden" name="data" value={data()?.user_id} />
          <h2 class="title color">Update Card</h2>
          <br />
          <div class="flex justify-center checkout-container"></div>
          <BackLink link="/billing-payments" />
        </div>
      </SystemLayout>
    </main>
  );
};

export default cardUpdate;
