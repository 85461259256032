import { Component, Show, createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import generateCSS from "~/helpers/generator";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  formJson,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  isMouseDown,
  multipleSelectField,
  selectedDropDown,
  setIsMouseDown,
  setPopup,
} from "~/store/global.store";
import {
  calculatedArr,
  imageFile,
  isImageUploaded,
  isRecordSubmit,
  preLoadData,
  recordsJson,
  setImageFile,
  setIsImageUploaded,
  setUpdatedRecordImages,
  updatedRecordImages,
  updateFormRecords,
} from "~/store/records.store";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
import Icons from "../icons/Icons";
import convert from "~/helpers/convertor";
import {
  setPhotoAnnotateData,
  setSelectedImageUid,
} from "../popup/PhotoAnnotatePopup";

interface Props {
  properties: any;
  fieldsType: string;
}

const PAGE_RES_DEFAULT = 84;
const imageSizes: any = {
  Large: 10.5 * PAGE_RES_DEFAULT,
  Medium: 5 * PAGE_RES_DEFAULT,
  Small: 2.5 * PAGE_RES_DEFAULT,
};

const [imageBase64, setImageBase64] = createSignal("" as string);
const [formImage, setFormImage] = createSignal([] as any);

const ImageField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [photoAnnotateFields, setPhotoAnnotateFields] = createSignal([] as any);

  let canvasRef: any;

  function getContainedSize(img: any) {
    var ratio = img?.naturalWidth / img?.naturalHeight;
    var width = img.height * ratio;
    var height = img.height;
    if (width > img.width) {
      width = img.width;
      height = img.width / ratio;
    }
    return [width?.toFixed(2), height?.toFixed(2)];
  }

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      if (
        Object.keys(recordsJson.currentRecord).length > 0 &&
        recordsJson.currentRecord?.pages?.length > 0
      ) {
        const data: any = recordsJson.currentRecord?.pages[
          formJson.currentPage
        ]?.fields.find(
          (field: any) => field.field_id == props.properties.dataFieldSync.uid
        )?.additional_data?.annotation;
        const canvas: any = canvasRef;
        if (canvas) {
          const ctx = canvas.getContext("2d") as any;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          if (data) {
            setTimeout(() => {
              const element: any = document.getElementById(
                `image_${props.properties.dataFieldSync.uid}`
              );
              if (element) {
                const result: any = getContainedSize(element);
                const tempArr: any = [];
                const index: any = tempArr.findIndex(
                  (item: any) =>
                    item.field_id == props.properties.dataFieldSync.uid &&
                    item.record_id == recordsJson.currentRecord.id
                );
                if (index == -1) {
                  tempArr.push({
                    field_id: props.properties.dataFieldSync.uid,
                    record_id: recordsJson.currentRecord.id,
                    width: result[0] + "px",
                    height: result[1] + "px",
                  });
                } else {
                  tempArr.splice(index, 1);
                }
                setPhotoAnnotateFields(tempArr);
                const signatureJSON = {
                  points: JSON.parse(data).points,
                };
                let signatureArr: any = [];
                let newSet: any = [];
                signatureJSON.points.forEach((p: any, i: number) => {
                  let x = p.split("{")[1].split(",")[0];
                  let y = p.split("{")[1].split(",")[1].split("}")[0];
                  let c = JSON.parse(data).colors[i];
                  if (x != "nan") {
                    let xyc = { x, y, c };
                    newSet.push(xyc);
                  }
                  if (x == "nan") {
                    if (newSet.length) signatureArr.push(newSet);
                    newSet = [];
                  }
                  if (i == signatureJSON.points.length - 1)
                    signatureArr.push(newSet);
                });
                let ratio: any, width: any, height: any, size: any;
                width = element.width;
                height = element.height;
                ratio = width / height;
                if (ratio > 1) {
                  switch (true) {
                    case width >= imageSizes["Medium"]:
                      size = "Large";
                      if (width > imageSizes["Large"]) {
                        width = imageSizes["Large"];
                        height = width / ratio;
                      }
                      break;
                    case width < imageSizes["Medium"] &&
                      width > imageSizes["Small"]:
                      size = "Medium";
                      break;
                    default:
                      size = "Small";
                      break;
                  }
                } else {
                  switch (true) {
                    case height >= imageSizes["Medium"]:
                      size = "Large";
                      if (height > imageSizes["Large"]) {
                        height = imageSizes["Large"];
                        width = height * ratio;
                      }
                      break;
                    case width < imageSizes["Medium"] &&
                      width > imageSizes["Small"]:
                      size = "Medium";
                      break;
                    default:
                      size = "Small";
                      break;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                signatureArr.forEach((field: { x: any; y: any; c: any }[]) => {
                  field.map(
                    (
                      val: {
                        x: any;
                        y: any;
                        c: any;
                      },
                      i
                    ) => {
                      if (i == 0) {
                        ctx.beginPath();
                      }
                      ctx.strokeStyle = props.properties.inkFieldSync?.inkColor
                        ? props.properties.inkFieldSync.inkColor
                        : val.c;
                      ctx.lineTo(
                        val.x * (Number(canvas.width) / element.naturalWidth),
                        val.y * (Number(canvas.height) / element.naturalHeight)
                      );
                      ctx.stroke();
                      if (i == field.length - 1) {
                        ctx.closePath();
                        ctx.fillStyle = props.properties.inkFieldSync?.inkColor
                          ? props.properties.inkFieldSync.inkColor
                          : val.c;
                      }
                    }
                  );
                });
              }
            }, 500);
          } else {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
          }
        }
      } else {
        const canvas: any = canvasRef;
        if (canvas) {
          const ctx = canvas.getContext("2d") as any;
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
      }
      setFieldProperties(assignFieldProperties(props.properties));
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  return (
    <>
      <Show when={props.properties.dataFieldSync.type == "ImageField"}>
        <Show
          when={props.fieldsType == "render" || props.fieldsType == "locked"}
        >
          <img
            src={
              props.properties.imageFieldCommon.imageBase64
                ? props.properties.imageFieldCommon.imageBase64.split(
                    "https://"
                  ).length > 1
                  ? props.properties.imageFieldCommon.imageBase64
                  : `data:image/png;base64,` +
                    props.properties.imageFieldCommon.imageBase64
                : "/photo2x.png"
            }
            class={`pa object-contain ${
              selectedDropDown() != "" ? "z-index-0" : "z-index-1"
            }`}
            style={generateCSS(fieldProperties)}
            loading="lazy"
          />
        </Show>
        <Show
          when={props.fieldsType != "render" && props.fieldsType != "locked"}
        >
          <img
            src={
              props.properties.imageFieldCommon.imageBase64
                ? props.properties.imageFieldCommon.imageBase64.split(
                    "https://"
                  ).length > 1
                  ? props.properties.imageFieldCommon.imageBase64
                  : `data:image/png;base64,` +
                    props.properties.imageFieldCommon.imageBase64
                : "/photo2x.png"
            }
            class={`pa object-contain ${
              props.fieldsType == "designer" ? "builder-field-border" : ""
            } ${
              selectedFields().length > 0 &&
              !selectedFields().includes(props.properties.dataFieldSync.uid)
                ? "pointer-none"
                : ""
            } ${selectedDropDown() != "" ? "z-index-0" : "z-index-1"}`}
            style={generateCSS(fieldProperties)}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseMove={() => {
              if (isMouseDown() && fieldMovement() == "") {
                updatedCurrentField(props.properties.dataFieldSync.uid);
                setIsGuidline(true);
                setFieldMovement("move");
              }
            }}
            onMouseUp={() => setIsMouseDown(false)}
            onclick={(event: any) =>
              multipleSelectField() || event.shiftKey
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : updatedCurrentField(props.properties.dataFieldSync.uid)
            }
            oncontextmenu={(event: any) => {
              multipleSelectField() || event.shiftKey
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : updatedCurrentField(props.properties.dataFieldSync.uid);
              addContectMenu(event);
            }}
            loading="lazy"
          />
        </Show>
      </Show>
      <Show when={props.properties.dataFieldSync.type == "PhotoField"}>
        <Show
          when={
            props.fieldsType == "render" ||
            props.fieldsType == "locked" ||
            props.fieldsType == "preview"
          }
        >
          <Show when={props.fieldsType == "render"}>
            <Show when={!preLoadData()[props.properties.dataFieldSync.uid]}>
              <input
                type="file"
                id={`formImage_${props.properties.dataFieldSync.uid}`}
                style={generateCSS(fieldProperties)}
                accept="image/png, image/jpg, image/jpeg, image/webp"
                onchange={(e: any) => {
                  setIsImageUploaded(true);
                  const selectedFile = e.target.files[0];
                  if (selectedFile) {
                    const reader = new FileReader();
                    reader.onload = (e: any) => {
                      const base64Data = e.target.result.split(",")[1];
                      const tempArr: any = [...formImage()];
                      if (
                        tempArr.findIndex(
                          (obj: any) =>
                            obj.uid == props.properties.dataFieldSync.uid
                        ) != -1
                      ) {
                        const index: number = tempArr.findIndex(
                          (obj: any) =>
                            obj.uid == props.properties.dataFieldSync.uid
                        );
                        tempArr.splice(index, 1);
                      }
                      const obj: any = {
                        uid: props.properties.dataFieldSync.uid,
                        imageBase64: base64Data,
                      };
                      tempArr.push(obj);
                      setFormImage(tempArr);
                      if (
                        !updatedRecordImages().includes(
                          props.properties.dataFieldSync.uid.toString()
                        )
                      ) {
                        setImageFile([...imageFile(), selectedFile]);
                        setUpdatedRecordImages([
                          ...updatedRecordImages(),
                          props.properties.dataFieldSync.uid.toString(),
                        ]);
                      } else {
                        const index = updatedRecordImages().indexOf(
                          props.properties.dataFieldSync.uid.toString()
                        );
                        const tempArr: any = [...imageFile()];
                        if (index !== -1) {
                          tempArr[index] = selectedFile;
                        }
                        setImageFile(tempArr);
                      }
                      updateFormRecords(
                        props.properties.dataFieldSync.uid,
                        base64Data,
                        selectedFile
                      );
                    };
                    reader.readAsDataURL(selectedFile);
                  }
                }}
                class="pa d-n"
              />
            </Show>
          </Show>
          <label
            for={`formImage_${props.properties.dataFieldSync.uid}`}
            onclick={() => {
              if (preLoadData()[props.properties.dataFieldSync.uid]) {
                setPhotoAnnotateData(props.properties);
                setSelectedImageUid(props.properties.dataFieldSync.uid);
                setPopup("PhotoAnnotate");
              }
            }}
          >
            <div
              class={`pa ${props.fieldsType != "preview" ? "cur-p" : ""} ${
                props.fieldsType == "preview" ? "pointer-none" : ""
              } ${selectedDropDown() != "" ? "z-index-0" : "z-index-1"}`}
              style={generateCSS(fieldProperties)}
            >
              <img
                id={`image_${props.properties.dataFieldSync.uid}`}
                src={
                  preLoadData()[props.properties.dataFieldSync.uid] !=
                    undefined &&
                  preLoadData()[props.properties.dataFieldSync.uid] != "" &&
                  props.fieldsType != "preview"
                    ? preLoadData()[props.properties.dataFieldSync.uid].split(
                        "https://"
                      ).length > 1
                      ? preLoadData()[props.properties.dataFieldSync.uid]
                      : `data:image/png;base64,` +
                        preLoadData()[props.properties.dataFieldSync.uid]
                    : "/photo2x.png"
                }
                style={{
                  height: "100%",
                  width: "100%",
                  "object-fit": "contain",
                }}
                class={`${
                  props.fieldsType == "preview" || props.fieldsType == "locked"
                    ? "pointer-none"
                    : ""
                }`}
                loading="lazy"
              />
              <canvas
                class="transform-translate"
                ref={canvasRef}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  width:
                    photoAnnotateFields().find(
                      (item: any) =>
                        item.field_id == props.properties.dataFieldSync.uid &&
                        item.record_id == recordsJson.currentRecord.id
                    )?.width ?? "100%",
                  height:
                    photoAnnotateFields().find(
                      (item: any) =>
                        item.field_id == props.properties.dataFieldSync.uid &&
                        item.record_id == recordsJson.currentRecord.id
                    )?.height ?? "100%",
                }}
              ></canvas>
            </div>
            <Show
              when={
                (props.fieldsType == "render" ||
                  props.fieldsType == "locked") &&
                (isImageUploaded() || isRecordSubmit()) &&
                updatedRecordImages().includes(
                  props.properties.dataFieldSync.uid.toString()
                )
              }
            >
              <div
                class={`pa ${
                  props.fieldsType == "preview" ? "pointer-none" : ""
                } ${isRecordSubmit() ? "image-loader" : "opacity-5"}`}
                style={generateCSS(fieldProperties)}
              >
                <Show when={isRecordSubmit()}>
                  <Icons name="buttonLoader"></Icons>
                </Show>
              </div>
            </Show>
          </label>
        </Show>
        <Show when={props.fieldsType == "designer"}>
          <img
            src={"/photo2x.png"}
            class={`pa ${
              props.fieldsType == "designer" ? "builder-field-border" : ""
            } ${
              selectedFields().length > 0 &&
              !selectedFields().includes(props.properties.dataFieldSync.uid)
                ? "pointer-none"
                : ""
            } ${selectedDropDown() != "" ? "z-index-0" : "z-index-1"}`}
            style={generateCSS(fieldProperties)}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseMove={() => {
              if (isMouseDown() && fieldMovement() == "") {
                updatedCurrentField(props.properties.dataFieldSync.uid);
                setIsGuidline(true);
                setFieldMovement("move");
              }
            }}
            onMouseUp={() => setIsMouseDown(false)}
            onclick={(event: any) =>
              multipleSelectField() || event.shiftKey
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : updatedCurrentField(props.properties.dataFieldSync.uid)
            }
            oncontextmenu={(event: any) => {
              multipleSelectField() || event.shiftKey
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : updatedCurrentField(props.properties.dataFieldSync.uid);
              addContectMenu(event);
            }}
            loading="lazy"
          />
          <Show
            when={
              (formJson.currentField?.inputType == "calculated" ||
                formJson.currentField?.dateInputType == "calculated") &&
              props.fieldsType == "designer" &&
              calculatedArr()[props.properties.dataFieldSync.uid]
            }
          >
            <span
              class="pa z-index-1"
              style={{
                left:
                  Number(
                    convert(props.properties.dataFieldSync.frame[0][0], "in-px")
                  ) -
                  25 +
                  Number(
                    convert(props.properties.dataFieldSync.frame[1][0], "in-px")
                  ) /
                    2 +
                  "px",
                top:
                  Number(
                    convert(props.properties.dataFieldSync.frame[0][1], "in-px")
                  ) +
                  Number(
                    convert(props.properties.dataFieldSync.frame[1][1], "in-px")
                  ) /
                    2 -
                  10 +
                  "px",
              }}
            >
              {calculatedArr()[props.properties.dataFieldSync.uid]}
            </span>
          </Show>
        </Show>
      </Show>
    </>
  );
};

export { imageBase64, setImageBase64, formImage, setFormImage };
export default ImageField;
