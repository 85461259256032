import { Component, For, Show, createSignal, onMount } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/admin.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { setCurrentPage } from "~/components/global/Pagination";
import Search from "~/components/global/Search";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/expiry-card/users`,
      jwt_token: jwt_token as string,
      body: {
        skip: 0,
        take: 20,
        search_string: "",
        sort_string: "",
      },
    };
    let list = await fetchApi(apiParams);
    if (list.statusCode == 401) {
      throw redirect("/logout");
    }
    let expiryCardUsers = list.data.expiryCardUsers;
    let totalItems = list.data.totalItems;
    let totalPages: any = list.data.totalPages;
    return { expiryCardUsers, jwt_token, totalItems, totalPages };
  });
}

const expiryCardUsers: Component = () => {
  setPageTitle(`${SITE_TITLE} - Expiry Card Users`);
  const data: any = useRouteData<typeof routeData>();
  const [expiryCardUsers, setExpiryCardUsers] = createSignal(
    data()?.expiryCardUsers as any
  );
  const [searchString, setSearchString] = createSignal("" as any);
  const [getTotalItems, setTotalItems] = createSignal(
    data()?.totalItems as any
  );
  const [isListLoader, setIsListLoader] = createSignal(false as boolean);
  const [isMoveToTopVisible, setIsMoveToTopVisible] = createSignal(
    false as boolean
  );

  onMount(() => {
    const debouncedScrollHandler = debounce(handleScroll, 200);
    const element: any = document.querySelector(".main-content");
    element.addEventListener("scroll", debouncedScrollHandler);
  });

  function getApiParams(jwt_token: string, skip: number) {
    return {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/expiry-card/users`,
      jwt_token,
      body: {
        skip: skip > 0 ? skip : 0,
        take: 20,
        sort_string: "",
        search_string: searchString() ? searchString() : "",
      },
    };
  }

  const debounce = (func: any, delay: any) => {
    let timeoutId: any;
    return (...args: any) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const handleScroll = async (event: any) => {
    setIsMoveToTopVisible(event.target.scrollTop > 300 ? true : false);
    const scrollableHeight =
      event.target.scrollHeight - event.target.clientHeight;
    const currentPosition = event.target.scrollTop;
    if (
      currentPosition >= scrollableHeight &&
      (expiryCardUsers()
        ? expiryCardUsers().length != getTotalItems()
        : data()?.expiryCardUsers.length != data()?.totalItems) &&
      !isListLoader()
    ) {
      setIsListLoader(true);
      const apiParams = getApiParams(
        data()?.jwt_token,
        20 * Math.ceil(expiryCardUsers().length / 20)
      );
      const list = await fetchApi(apiParams);
      if (list?.statusCode == 401) {
        window.location.href = "/logout";
      }
      if (list) {
        setExpiryCardUsers([
          ...expiryCardUsers(),
          ...list.data.expiryCardUsers,
        ]);
        setIsListLoader(false);
      }
    }
  };

  async function searchUsers(searchString: any, jwt_token: string) {
    setSearchString(searchString);
    let skip: number = 0;
    const apiParams = getApiParams(jwt_token, skip);

    let list = await fetchApi(apiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    }
    setExpiryCardUsers(list.data.expiryCardUsers);
    setCurrentPage(1);
  }

  return (
    <main>
      <Show when={isMoveToTopVisible()}>
        <button
          class="btn sm move-to-top"
          onclick={() => {
            const element: any = document.querySelector(".main-content");
            element.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        >
          <Icons name="arrowUp" stroke="#2e61a3" width={30} height={30}></Icons>
        </button>
      </Show>
      <SystemLayout>
        <div class="center p-r">
          <div class="flex space-between">
            <h3 class="title color">Expiry Card Users</h3>
            <div class="flex gap">
              <div class="w-150">
                <Search
                  onEnter={(search: any) => {
                    searchUsers(search, data()?.jwt_token);
                  }}
                  placeholder="Search User"
                ></Search>
              </div>
            </div>
          </div>
          <div>
            <div class="flex space-between flex-items">
              <div class="w-100">
                <table class="table w-100">
                  <thead class="user-select-none">
                    <tr>
                      <th>User Name</th>
                      <th>Email</th>
                      <th>Card</th>
                      <th>Expiry At</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Show
                      when={
                        expiryCardUsers()
                          ? expiryCardUsers().length == 0
                          : data()?.expiryCardUsers.length == 0
                      }
                    >
                      <tr class="text-center">
                        <td colSpan="8">
                          <small>
                            <b>No Data Found !!</b>
                          </small>
                        </td>
                      </tr>
                    </Show>
                    <For
                      each={
                        expiryCardUsers()
                          ? expiryCardUsers()
                          : data()?.expiryCardUsers
                      }
                    >
                      {(user) => (
                        <tr>
                          <td>{user.username}</td>
                          <td>{user.email}</td>
                          <td>
                            <strong>
                              {user.card_brand?.charAt(0).toUpperCase() +
                                user.card_brand?.slice(1)}
                            </strong>{" "}
                            <small>card ending in </small>
                            <strong>{user.card_last_four}</strong>
                          </td>
                          <td>{user.card_expiry_date}</td>
                        </tr>
                      )}
                    </For>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default expiryCardUsers;
