import { Component, Show, createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import convert from "~/helpers/convertor";
import generateCSS from "~/helpers/generator";
import Icons from "../icons/Icons";
import {
  isMouseDown,
  multipleSelectField,
  setIsMouseDown,
  setPopup,
} from "~/store/global.store";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  additionalData,
  preLoadData,
  recordsJson,
  updateFormRecords,
} from "~/store/records.store";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
import { isSignature } from "../popup/SignaturePopup";

interface Props {
  properties: any;
  fieldsType: string;
}

const [currentSignatureField, setCurrentSignatureField] = createSignal(
  {} as any
);

const CanvasField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [signatureLocked, setSignatureLocked] = createSignal(false as boolean);
  const [canvasContext, setCanvasContext] = createSignal(
    {} as CanvasRenderingContext2D
  );
  const [offsetX, setOffsetX] = createSignal(0 as number);
  const [offsetY, setOffsetY] = createSignal(0 as number);
  const [drawing, setDrawing] = createSignal(false as boolean);
  const [activeTool, setActiveTool] = createSignal("pencil" as string);
  const [signaturePoints, setSignaturePoints] = createSignal([] as any);

  let canvasRef: any;
  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
    const canvas: any = canvasRef;
    if (canvas) {
      preLoadData();
      additionalData();
      setCanvasContext(canvas.getContext("2d"));
      const ctx = canvas.getContext("2d") as any;
      if (recordsJson.currentRecord) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
      setTimeout(() => {
        if (!preLoadData()[props.properties.dataFieldSync.uid]) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          return;
        }
        if (
          (props.fieldsType != "designer" &&
            (props.properties.dataFieldSync.type == "SignatureField" ||
              props.properties.dataFieldSync.type == "NotesField") &&
            preLoadData()[props.properties.dataFieldSync.uid] &&
            (preLoadData()[props.properties.dataFieldSync.uid].length > 24
              ? JSON.parse(preLoadData()[props.properties.dataFieldSync.uid])
                  ?.nPoints > 0
              : JSON.parse(
                  preLoadData()
                    [props.properties.dataFieldSync.uid].replaceAll(
                      '"',
                      "&quot;"
                    )
                    .replaceAll("'", '"')
                )?.nPoints > 0)) ||
          isSignature()
        ) {
          setSignaturePoints(preLoadData()[props.properties.dataFieldSync.uid]);
          const signatureJSON = {
            points: preLoadData()[props.properties.dataFieldSync.uid]
              ? JSON.parse(preLoadData()[props.properties.dataFieldSync.uid])
                  .points
              : [],
          };
          let signatureArr: any = [];
          let newSet: any = [];
          signatureJSON.points.forEach((p: any, i: number) => {
            let x = p.split("{")[1].split(",")[0];
            let y = p.split("{")[1].split(",")[1].split("}")[0];
            if (x != "nan") {
              let xy = { x, y };
              newSet.push(xy);
            }
            if (x == "nan") {
              if (newSet.length) signatureArr.push(newSet);
              newSet = [];
            }
            if (i == signatureJSON.points.length - 1) signatureArr.push(newSet);
          });

          if (
            additionalData()[props.properties.dataFieldSync?.uid] &&
            additionalData()[props.properties.dataFieldSync?.uid]
              ?.display_value &&
            Object.keys(
              additionalData()[props.properties.dataFieldSync?.uid]
                ?.display_value
            )?.length > 1
          ) {
            const svgString =
              additionalData()[props.properties.dataFieldSync?.uid]
                ?.display_value;
            const completeSvgString = `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
              ${
                svgString.includes('xmlns="http://www.w3.org/2000/svg"')
                  ? svgString
                  : svgString.replace(
                      "<svg",
                      '<svg xmlns="http://www.w3.org/2000/svg"'
                    )
              }`;

            const parser = new DOMParser();
            const svgDoc = parser.parseFromString(
              completeSvgString,
              "image/svg+xml"
            );
            const elementsToColor =
              svgDoc.querySelectorAll("path, rect, circle");
            elementsToColor.forEach((element) => {
              element.setAttribute(
                "fill",
                props.properties?.inkFieldSync?.inkColor
              );
              element.setAttribute(
                "stroke",
                props.properties?.inkFieldSync?.inkColor
              );
            });
            const serializer = new XMLSerializer();
            const updatedSvgString = serializer.serializeToString(
              svgDoc.documentElement
            );
            const svgBlob = new Blob([updatedSvgString], {
              type: "image/svg+xml;charset=utf-8",
            });
            const url = URL.createObjectURL(svgBlob);
            const img = new Image();
            const cleanup = () => {
              URL.revokeObjectURL(url);
            };

            img.onload = () => {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
              cleanup();
            };

            img.onerror = (error) => {
              console.error("Error loading SVG:", error);
              cleanup();
            };

            img.crossOrigin = "anonymous";
            img.src = url;
          } else {
            signatureArr.forEach((field: { x: any; y: any }[]) => {
              field.map(
                (
                  val: {
                    x: any;
                    y: any;
                  },
                  i
                ) => {
                  if (i == 0) {
                    ctx.beginPath();
                  }
                  ctx.strokeStyle = props.properties.inkFieldSync?.inkColor
                    ? props.properties.inkFieldSync.inkColor
                    : "#000";
                  ctx.lineTo(val.x, val.y);
                  ctx.stroke();
                  if (i == field.length - 1) {
                    ctx.closePath();
                    ctx.fillStyle = props.properties.inkFieldSync?.inkColor
                      ? props.properties.inkFieldSync.inkColor
                      : "#000";
                  }
                }
              );
            });
          }
        }
      }, 0);
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  function drawSign(newX: number, newY: number) {
    canvasContext().beginPath();
    if (activeTool() === "eraser") {
      canvasContext().lineWidth = 30;
      canvasContext().globalCompositeOperation = "destination-out";
      canvasContext().lineCap = "round";
      canvasContext().lineJoin = "round";
    } else {
      canvasContext().lineWidth = 1;
      canvasContext().globalCompositeOperation = "source-over";
      canvasContext().lineCap = "round";
      canvasContext().lineJoin = "round";
    }
    canvasContext().moveTo(offsetX(), offsetY());
    canvasContext().lineTo(newX, newY);
    canvasContext().stroke();
    setOffsetX(newX);
    setOffsetY(newY);
  }

  function updateSignatureRecord() {
    let points: any;
    if (typeof signaturePoints()[0] == "string") {
      points = signaturePoints();
    } else {
      if (signaturePoints().length > 0) {
        points = generatePointsFromCanvas(
          signaturePoints()
            .map((currentArray: any) => {
              let subArrays = [];
              let currentSubArray = [];
              currentArray.sort((a: any, b: any) => a.x - b.x || a.y - b.y);
              for (let i = 0; i < currentArray.length; i++) {
                let point = currentArray[i];
                let prevPoint = currentArray[i - 1];
                if (
                  !prevPoint ||
                  Math.abs(point.x - prevPoint.x) > 8 ||
                  Math.abs(point.y - prevPoint.y) > 8
                ) {
                  if (currentSubArray.length > 0) {
                    subArrays.push(currentSubArray);
                  }
                  currentSubArray = [point];
                } else {
                  currentSubArray.push(point);
                }
              }
              if (currentSubArray.length > 0) {
                subArrays.push(currentSubArray);
              }
              return subArrays;
            })
            .flat()
        );
      } else {
        points = [];
      }
    }
    const result = {
      nPoints: points.length,
      points,
    };
    updateFormRecords(
      props.properties.dataFieldSync.uid,
      JSON.stringify(result)
    );
  }

  function generatePointsFromCanvas(arrays: any) {
    let result = [];
    for (let i = 0; i < arrays.length; i++) {
      let currentArray = arrays[i];
      let uniqueArray = [];
      for (let j = 0; j < currentArray.length; j++) {
        let currentElement = currentArray[j];
        let isUnique = true;
        for (let k = 0; k < i; k++) {
          if (
            arrays[k].some(
              (item: any) =>
                item.x === currentElement.x && item.y === currentElement.y
            )
          ) {
            isUnique = false;
            break;
          }
        }
        if (isUnique) {
          uniqueArray.push(currentElement);
        }
      }
      uniqueArray = uniqueArray.map((point: any) => `{${point.x}, ${point.y}}`);
      uniqueArray.unshift("{nan,nan}");
      result.push(uniqueArray);
    }
    return result.flat();
  }

  return (
    <>
      <div
        style={generateCSS(fieldProperties)}
        class={`pa ${
          String(props.properties.dataFieldSync.parentTableId) != "0"
            ? "z-index-1"
            : "z-index-0"
        } ${props.fieldsType == "designer" ? "builder-field-border" : ""} ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        }`}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) =>
          multipleSelectField() || event.shiftKey
            ? updatedMultipleFields(props.properties.dataFieldSync.uid)
            : updatedCurrentField(props.properties.dataFieldSync.uid)
        }
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      >
        <Show
          when={
            props.properties.dataFieldSync.type == "SignatureField" ||
            props.properties.dataFieldSync.type == "NotesField"
          }
        >
          <canvas
            ref={canvasRef}
            width={convert(props.properties.dataFieldSync.frame[1][0], "in-px")}
            height={convert(
              props.properties.dataFieldSync.frame[1][1],
              "in-px"
            )}
            style={{
              cursor:
                props.properties.dataFieldSync.type == "NotesField"
                  ? activeTool() === "eraser"
                    ? "cell"
                    : "crosshair"
                  : "auto",
            }}
            onmousedown={(e: any) => {
              if (props.properties.dataFieldSync.type == "NotesField") {
                setOffsetX(e.offsetX);
                setOffsetY(e.offsetY);
                setDrawing(true);
              }
            }}
            onmousemove={(e: any) => {
              if (
                drawing() &&
                props.properties.dataFieldSync.type == "NotesField"
              ) {
                drawSign(e.offsetX, e.offsetY);
              }
            }}
            onmouseup={() => {
              if (props.properties.dataFieldSync.type == "NotesField") {
                setDrawing(false);
                const canvas = canvasRef;
                const ctx = canvas.getContext("2d");
                const imageData = ctx.getImageData(
                  0,
                  0,
                  canvas.width,
                  canvas.height
                ).data;
                const points: any = [];
                // Iterate through pixel data
                for (let y = 0; y < canvas.height; y++) {
                  for (let x = 0; x < canvas.width; x++) {
                    const index = (y * canvas.width + x) * 4;
                    // Check if the pixel is not transparent
                    if (imageData[index + 3] !== 0) {
                      points.push({ x, y });
                    }
                  }
                }
                if (preLoadData()[props.properties.dataFieldSync?.uid]) {
                  setSignaturePoints([points]);
                } else {
                  if (points.length > 0) {
                    setSignaturePoints([points]);
                  } else {
                    setSignaturePoints([]);
                  }
                }
                updateSignatureRecord();
              }
            }}
            onmouseleave={() => setDrawing(false)}
          ></canvas>
        </Show>
        <Show
          when={
            (props.properties.dataFieldSync.type == "SignatureField" ||
              props.properties.dataFieldSync.type == "NotesField") &&
            props.fieldsType == "render" &&
            !signatureLocked()
          }
        >
          <Show when={props.properties.dataFieldSync.type == "NotesField"}>
            <div
              style={{
                position: "absolute",
                top: 0,
                right: "30px",
                cursor: "pointer",
              }}
            >
              <div onclick={() => setActiveTool("eraser")}>
                <Icons
                  name="eraser"
                  stroke={activeTool() == "eraser" ? "#86BDFB" : ""}
                ></Icons>
              </div>
            </div>
          </Show>
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
          >
            <div
              onclick={() => {
                if (props.properties.dataFieldSync.type == "NotesField") {
                  setActiveTool("pencil");
                } else {
                  setPopup("signature");
                  setCurrentSignatureField(props.properties);
                }
              }}
            >
              <Icons
                name="pencil"
                stroke={activeTool() == "pencil" ? "#86BDFB" : ""}
                width={
                  props.properties.dataFieldSync.frame[1][1] <= 0.25 ? 14 : 24
                }
                height={
                  props.properties.dataFieldSync.frame[1][1] <= 0.25 ? 14 : 24
                }
              ></Icons>
            </div>
          </div>
        </Show>
        <Show
          when={
            props.properties.dataFieldSync.type != "SignatureField" &&
            props.properties.dataFieldSync.type != "NotesField" &&
            (props.properties?.bgImageBase64 ||
              preLoadData()[props.properties.dataFieldSync.uid])
          }
        >
          <img
            class="pr w-100 h-100p object-contain"
            src={
              preLoadData()[props.properties.dataFieldSync.uid]
                ? preLoadData()[props.properties.dataFieldSync.uid].split(
                    "https://"
                  ).length > 1
                  ? preLoadData()[props.properties.dataFieldSync.uid]
                  : "data:image/png;base64," +
                    preLoadData()[
                      props.properties.dataFieldSync.uid
                    ].replaceAll("\r\n", "")
                : "data:image/png;base64," + props.properties?.bgImageBase64
            }
          ></img>
        </Show>
      </div>
      <Show
        when={
          props.properties.dataFieldSync.type == "SignatureField" &&
          props.properties.dataFieldSync.frame[1][1] > 0.75
        }
      >
        <div
          class="pa"
          style={{
            border: "1px solid",
            width:
              convert(
                props.properties.dataFieldSync.frame[1][0] - 0.2,
                "in-px"
              ) + "px",
            top:
              Number(
                convert(
                  props.properties.dataFieldSync.frame[0][1] - 0.2,
                  "in-px"
                )
              ) +
              Number(
                convert(props.properties.dataFieldSync.frame[1][1], "in-px")
              ) +
              "px",
            left:
              convert(
                props.properties.dataFieldSync.frame[0][0] - 0.15,
                "in-px"
              ) + "px",
          }}
        ></div>
      </Show>
    </>
  );
};

export { currentSignatureField };
export default CanvasField;
