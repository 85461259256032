import { createSignal } from "solid-js";
import { createStore, produce } from "solid-js/store";
import {
  cutCopyField,
  generateRandomInt64String,
  setAllSelectField,
  setContextMenu,
  setContextMenuLeft,
  setContextMenuTop,
  setCutCopyField,
  setLinkedField,
  setLinkedFieldLabel,
  setMultipleSelectField,
  setPopup,
} from "./global.store";
import {
  addFormFields,
  checkboxFieldData,
  dateFieldData,
  dropdownFieldData,
  labelFieldData,
  mapping,
  signatureFieldData,
  textFieldData,
} from "./field-editor.store";
import {
  isFieldsMoved,
  setIsFieldsMoved,
  setIsGuidline,
} from "~/components/builder/Guidline";
import convert from "~/helpers/convertor";
import { setCurrentPage } from "~/components/global/Pagination";
import { setIsSidebarVisible } from "~/components/theme/FormRightBar";
import { fieldPastePosition } from "~/components/global/ContextMenu";
import { setPages } from "~/components/global/FormNavbar";
import fetchApi from "~/lib/api";
import { updatedCalulatedFieldvalue, updatePreLoadData } from "./records.store";
import { saveFormSettings } from "~/components/form/formSettings";
import { hourTimeFormat } from "~/components/builder/FieldEditor";

const [formDesigner, setFormDesigner] = createStore({
  fields: [] as Array<string>,
  currentItem: {} as Object,
  rootJson: {} as JSON,
});

const [formJson, setFormJson] = createStore({
  root: {} as any,
  pages: {} as Array<Object>,
  currentPage: 0 as number,
  currentField: {} as {
    [key: string]: string | boolean | number | undefined | any;
  },
  multipleFields: [] as any,
});

export const [totalForms, setTotalForms] = createSignal(0 as number);

export const [currenFormSavedFields, setCurrenFormSavedFields] = createSignal(
  [] as any
);
export const [tableOldFrame, setTableOldFrame] = createSignal([] as any);

const [isFormJsonUpdated, setIsFormJsonUpdated] = createSignal(
  false as boolean
);
const [isNewField, setIsNewField] = createSignal(false as boolean);
export const [isFolderForm, setIsFolderForm] = createSignal(false as boolean);

export const [totalFormPages, setTotalFormPages] = createSignal(0 as number);
export const [formLastPageId, setFormLastPageId] = createSignal("" as any);
export const [removedPages, setRemovedPages] = createSignal([] as any);

export const [offset, setOffset] = createSignal(0 as number);

export const [isRedirectFromBuilder, setIsRedirectFromBuilder] = createSignal(
  false as boolean
);

export const [locationFormatUpdate, setLocationFormatUpdate] = createSignal(
  {} as any
);

export const [minMaxValue, setMinMaxValue] = createSignal({
  min: "",
  max: "",
} as any);

export const [isFieldJustSelected, setIsFieldJustSelected] = createSignal(
  false as boolean
);

export const [isNewLabelField, setIsNewLabelField] = createSignal(
  false as boolean
);

export const [clipData, setClipData] = createStore({
  last_saved: Date.now() / 1000,
  clipboard_data: [],
  is_formtabs_enabled: true,
}) as any;

export async function updateFormJsonPages(
  user_id: string,
  jwt_token: string,
  form_id: string,
  page?: any
) {
  if (!(Object.keys(formJson.pages[formJson.currentPage]).length > 0)) {
    const formStructureApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/view/page`,
      jwt_token,
      body: {
        form_id,
        user_id,
        offset: page ? page : formJson.currentPage + 1,
      },
    };
    let formStructure = await fetchApi(formStructureApiParams);
    if (formStructure?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (formStructure?.statusCode == 403) {
      location.reload();
    }
    let formPages: any = [...formJson.pages];
    formPages[formStructure.data.page_number - 1] = JSON.parse(
      formStructure.data.page_structure
    );
    formPages = formPages.flatMap((page: any) => page);
    formPages = formPages.map((page: any) => {
      if (!page.fields) {
        return page;
      }
      return {
        ...page,
        fields: page.fields.map((field: any) => {
          if (field.properties?.dataFieldSync?.frame?.[0]?.length === 1) {
            return {
              ...field,
              properties: {
                ...field.properties,
                dataFieldSync: {
                  ...field.properties.dataFieldSync,
                  frame: field.properties.dataFieldSync.frame.map(
                    (subArray: any, index: number) =>
                      index === 0 && typeof subArray[0] === "string"
                        ? subArray[0].split(",").map(Number)
                        : subArray
                  ),
                },
              },
            };
          }
          return field;
        }),
      };
    });
    setFormJson({
      pages: formPages,
      root: {
        formStructure: {
          ...formJson.root.formStructure,
          pages: formPages,
        },
      },
    });
    updatePreLoadData();
    updatedCalulatedFieldvalue(true);
  }
}

export function createNewFormPage(type?: string) {
  setFormLastPageId(Number(formLastPageId() + 1));
  setFormJson({
    pages: [...formJson.pages, { id: formLastPageId(), fields: [] }],
    root: {
      formStructure: {
        ...formJson.root.formStructure,
        pages: [...formJson.pages, { id: formLastPageId(), fields: [] }],
      },
    },
  });
  if (type && type == "add_page") {
    setIsFormJsonUpdated(true);
    setFormJson("currentPage", totalFormPages());
    setTotalFormPages(totalFormPages() + 1);
    setCurrentPage(formJson.currentPage + 1);
  }
}

export function duplicateFormPage() {
  const tabelFields: any = [];
  const fields: any = [];
  formJson.pages[formJson.currentPage]?.fields.forEach((obj: any) => {
    let formField: any = JSON.parse(JSON.stringify(obj));
    const tempObj: any = {
      old: Number(formField.properties.dataFieldSync.uid),
    };
    formField.properties.dataFieldSync.uid = generateRandomInt64String();
    tempObj["new"] = formField.properties.dataFieldSync.uid;
    if (formField.properties.dataFieldSync.type == "TableField") {
      tabelFields.push(tempObj);
    }
    fields.push(JSON.parse(JSON.stringify(formField)));
  });
  if (tabelFields.length > 0) {
    tabelFields.forEach((tableId: any) => {
      fields.forEach((field: any) => {
        if (field.properties.dataFieldSync.parentTableId == tableId["old"]) {
          field.properties.dataFieldSync.parentTableId = tableId["new"];
        }
      });
    });
  }
  const tempPages: any = [...formJson.pages];
  tempPages[tempPages.length - 1] = {
    ...tempPages[tempPages.length - 1],
    fields,
  };
  setPages(tempPages);
  setFormJson({
    pages: tempPages,
    root: {
      formStructure: {
        ...formJson.root.formStructure,
        pages: tempPages,
      },
    },
  });
  setIsFormJsonUpdated(true);
  setFormJson("currentPage", totalFormPages());
  setTotalFormPages(totalFormPages() + 1);
  setCurrentPage(formJson.currentPage + 1);
}

export function removeFormPage(
  user_id: string,
  jwt_token: string,
  form_id: string
) {
  let updatedPages = [...formJson.pages];
  let indexTabs = [...formJson.root.formStructure?.indexTabs];

  setRemovedPages([...removedPages(), updatedPages[formJson.currentPage]?.id]);
  updatedPages = updatedPages.filter(
    (page: any) => page.id != updatedPages[formJson.currentPage]?.id
  );
  setFormJson(
    "root",
    produce((root: any) => {
      root.formStructure.pages = updatedPages;
      root.formStructure.indexTabs = indexTabs?.filter(
        (tab: any) => tab.page != formJson.currentPage + 1
      );
    })
  );
  setFormJson({
    pages: updatedPages,
  });
  setTotalFormPages(totalFormPages() - 1);
  if (formJson.currentPage != 0) {
    setCurrentPage(formJson.currentPage);
    setFormJson("currentPage", formJson.currentPage - 1);
    updateFormJsonPages(user_id, jwt_token, form_id, formJson.currentPage + 1);
  } else {
    updateFormJsonPages(user_id, jwt_token, form_id, formJson.currentPage + 2);
  }
  setIsFormJsonUpdated(true);
  saveFormSettings(user_id, jwt_token, form_id);
}

export function removeFormField() {
  let currentFields: any = [];
  if (formJson.multipleFields.length > 0) {
    currentFields = [...formJson.multipleFields];
  } else {
    currentFields = [formJson.currentField];
  }
  if (
    currentFields
      .map((f: any) => String(f.dataFieldSync?.parentTableId))
      .filter((f: any) => f != "0" && f != "")?.length > 0
  ) {
    return;
  }
  let fieldIds: any = [];
  if (Object.keys(formJson.currentField).length > 0) {
    if (formJson.currentField.dataFieldSync?.type == "TableField") {
      fieldIds = formJson.pages[formJson.currentPage]?.fields
        .filter(
          (field: any) =>
            field.properties.dataFieldSync.parentTableId ==
            formJson.currentField.dataFieldSync?.uid
        )
        .map((field: any) => field.properties.dataFieldSync.uid);
    }
    fieldIds.push(formJson.currentField.dataFieldSync?.uid);
  }
  if (formJson.multipleFields.length > 0) {
    formJson.multipleFields.forEach((field: any) => {
      if (field.dataFieldSync?.type == "TableField") {
        let tableFieldIds = formJson.pages[formJson.currentPage]?.fields
          .filter(
            (f: any) =>
              f.properties.dataFieldSync.parentTableId ==
              field.dataFieldSync?.uid
          )
          .map((f: any) => f.properties.dataFieldSync.uid);
        fieldIds = [...fieldIds, ...tableFieldIds];
      }
      fieldIds.push(field.dataFieldSync?.uid);
    });
  }
  setFormJson(
    "root",
    produce((root: any) => {
      root.formStructure.pages.forEach((page: any) => {
        const tempArr: any = page?.fields;
        page.fields = tempArr?.filter(
          (field: any) => !fieldIds.includes(field.properties.dataFieldSync.uid)
        );
      });
      root.formStructure.primaryFields =
        root.formStructure.primaryFields.filter(
          (id: any) => !fieldIds.includes(id)
        );
      root.formStructure.sortFields = root.formStructure.primaryFields.filter(
        (id: any) => !fieldIds.includes(id)
      );
    })
  );
  setFormJson({
    currentField: {},
    multipleFields: [],
  });
  setIsFormJsonUpdated(true);
  setIsGuidline(false);
}

export function updateCutCopyField() {
  let type: string = "";
  let selectedFields: any = [];
  if (formJson.multipleFields.length > 1) {
    selectedFields = [...formJson.multipleFields];
  } else if (Object.keys(formJson.currentField).length > 0) {
    selectedFields = [formJson.currentField];
  }
  const cutCopyFieldArr: any = [];
  selectedFields.forEach((field: any) => {
    switch (field.dataFieldSync?.type) {
      case "RadioButtonField":
        type = "RadioField";
        break;
      case "AnnotateField":
        type = "AnnotationField";
        break;
      case "CheckBoxField":
        type = "CheckboxField";
        break;
      default:
        type = field.dataFieldSync?.type;
        break;
    }
    const cutCopyFieldObj: any = {
      type: type,
      properties: { ...field },
    };
    cutCopyFieldArr.push(cutCopyFieldObj);
    if (field.dataFieldSync?.type == "TableField") {
      formJson.pages[formJson.currentPage]?.fields
        .filter(
          (obj: any) =>
            obj.properties.dataFieldSync.parentTableId ==
            field.dataFieldSync?.uid
        )
        .forEach((obj: any) => {
          switch (obj.properties.dataFieldSync?.type) {
            case "CheckBoxField":
              type = "CheckboxField";
              break;
            default:
              type = obj.properties.dataFieldSync?.type;
              break;
          }
          const cutCopyFieldObj: any = {
            type: type,
            properties: { ...obj.properties },
          };
          cutCopyFieldArr.push(cutCopyFieldObj);
        });
    }
  });
  setCutCopyField(cutCopyFieldArr);
}

export function pasteFormField() {
  setIsFormJsonUpdated(true);
  const tabelFields: any = [];
  const newFields: any = [];
  setFormJson(
    "root",
    produce((root: any) => {
      let topLeftObject: any = cutCopyField()[0];
      cutCopyField().forEach((obj: any) => {
        const currentTop = obj.properties.dataFieldSync.frame[0][1];
        const currentLeft = obj.properties.dataFieldSync.frame[0][0];
        const topLeftTop = topLeftObject.properties.dataFieldSync.frame[0][1];
        const topLeftLeft = topLeftObject.properties.dataFieldSync.frame[0][0];
        if (
          currentTop < topLeftTop ||
          (currentTop === topLeftTop && currentLeft < topLeftLeft)
        ) {
          topLeftObject = obj;
        }
      });
      const left: number = topLeftObject.properties.dataFieldSync.frame[0][0];
      const top: number = topLeftObject.properties.dataFieldSync.frame[0][1];

      cutCopyField().forEach((obj: any, key: any) => {
        let formField: any = JSON.parse(JSON.stringify(obj));
        const tempObj: any = {
          old: formField.properties.dataFieldSync.uid,
        };
        formField.properties.dataFieldSync.uid = generateRandomInt64String();
        tempObj["new"] = formField.properties.dataFieldSync.uid;
        if (formField.properties.dataFieldSync.type == "TableField") {
          tabelFields.push(tempObj);
        }
        const tempArr: any = formField.properties.dataFieldSync.frame;
        const tempLeft: number =
          Number(convert(fieldPastePosition()[0], "px-in")) +
          (key != 0
            ? formField.properties.dataFieldSync.frame[0][0] - left
            : 0);
        tempArr[0][0] =
          Math.round(
            (tempLeft + formField.properties.dataFieldSync.frame[1][0] > 6.96 &&
            formField.properties.dataFieldSync.type != "TableField" &&
            String(formField.properties.dataFieldSync.parentTableId) == "0"
              ? 6.96 + 1.5 - formField.properties.dataFieldSync.frame[1][0]
              : tempLeft) / 0.125
          ) * 0.125;

        const tempTop: number =
          Number(convert(fieldPastePosition()[1], "px-in")) +
          (key != 0 ? formField.properties.dataFieldSync.frame[0][1] - top : 0);
        tempArr[0][1] =
          Math.round(
            (tempTop + formField.properties.dataFieldSync.frame[1][1] > 10.55 &&
            formField.properties.dataFieldSync.type != "TableField" &&
            String(formField.properties.dataFieldSync.parentTableId) == "0"
              ? 10.55 + 0.375 - formField.properties.dataFieldSync.frame[1][1]
              : tempTop) / 0.125
          ) * 0.125;

        formField.properties.dataFieldSync.frame = tempArr;
        newFields.push(formField.properties.dataFieldSync.uid);
        root.formStructure.pages[formJson.currentPage].fields.push(
          JSON.parse(JSON.stringify(formField))
        );
      });
    })
  );
  if (tabelFields.length > 0) {
    setFormJson(
      "pages",
      produce((pages: any) => {
        pages[formJson.currentPage]?.fields.forEach((obj: any) => {
          tabelFields.forEach((data: any) => {
            if (
              obj.properties.dataFieldSync.parentTableId == data.old &&
              newFields.includes(obj.properties.dataFieldSync.uid)
            ) {
              obj.properties.dataFieldSync.parentTableId = data.new;
            }
          });
        });
      })
    );
  }
  setContextMenu("");
}

export function createFormJson(
  type: string,
  page: number,
  dropLeft?: number,
  dropTop?: number
) {
  let fieldUid: string = "";
  setIsFormJsonUpdated(true);
  setFormJson(
    "root",
    produce((root: any) => {
      let formField: any = JSON.parse(JSON.stringify(addFormFields[type]));
      formField.properties.dataFieldSync.uid = generateRandomInt64String();
      let left: any = 0;
      let top: any = 0;
      if (formJson.pages[page].fields.length > 0) {
        const tempArr: any =
          formJson.pages[page].fields[formJson.pages[page].fields.length - 1]
            .properties.dataFieldSync.frame;
        left = Number(tempArr[0][0]);
        top = Number(tempArr[0][1]) + Number(tempArr[1][1]) + 0.05;
      } else {
        left = 3.5;
        top = 0.375;
      }
      fieldUid = formField.properties.dataFieldSync.uid;
      left = dropLeft ? Number(convert(dropLeft + 25, "px-in")) : left;
      top = dropTop ? Number(convert(dropTop, "px-in")) : top;
      top = top < 0.25 ? 0.25 : top;
      formField.properties.dataFieldSync.fontSize = Number(
        type == "TableField" &&
          Number(formJson.root.formStructure.defaultFontSize) > 18
          ? 18
          : formJson.root.formStructure.defaultFontSize
      );
      switch (type) {
        case "SignatureField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 4.66 ? 4.66 : Number(left.toFixed(3)),
              top > 9.51 ? 9.51 : Number(top.toFixed(3)),
            ],
            [3.5, 1.25],
          ];
          break;

        case "ImageField":
        case "PhotoField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 6.16 ? 6.16 : Number(left.toFixed(3)),
              top > 8.77 ? 8.77 : Number(top.toFixed(3)),
            ],
            [2, 2],
          ];
          break;

        case "AnnotateField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 5.16 ? 5.16 : Number(left.toFixed(3)),
              top > 8.77 ? 8.77 : Number(top.toFixed(3)),
            ],
            [3, 2],
          ];
          break;

        case "LineField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 4.66 ? 4.66 : Number(left.toFixed(3)),
              top > 10.52 ? 10.52 : Number(top.toFixed(3)),
            ],
            [3.5, 0.25],
          ];
          break;

        case "LocationField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 4.66 ? 4.66 : Number(left.toFixed(3)),
              top > 9.28 ? 9.28 : Number(top.toFixed(3)),
            ],
            [3.5, 1.5],
          ];
          break;

        case "BarcodeField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 5.91 ? 5.91 : Number(left.toFixed(3)),
              top > 9.53 ? 9.53 : Number(top.toFixed(3)),
            ],
            [2.25, 1.25],
          ];
          break;

        case "TableField":
          left = left > 3.29 ? 3.29 : Number(left.toFixed(3));
          top = top > 8.9 ? 8.9 : Number(top.toFixed(3));
          formField.properties.dataFieldSync.frame = [
            [left, top],
            [4.875, 1.875],
          ];
          break;

        case "CheckBoxField":
          formField.properties.dataFieldSync.frame = [
            [
              left > 6.66 ? 6.66 : Number(left.toFixed(3)),
              top > 10.4 ? 10.4 : Number(top.toFixed(3)),
            ],
            [
              1.5,
              Math.round(
                (formField.properties.dataFieldSync.fontSize / 72) * 4 + 0.49
              ) /
                4 +
                0.125,
            ],
          ];
          break;

        default:
          formField.properties.dataFieldSync.frame = [
            [
              left > 6.66 ? 6.66 : Number(left.toFixed(3)),
              top > 10.4 ? 10.4 : Number(top.toFixed(3)),
            ],
            [
              1.5,
              type == "ScriptButtonField"
                ? 0.375
                : Math.round(
                    (formField.properties.dataFieldSync.fontSize / 72) * 4 +
                      0.49
                  ) /
                    4 +
                  0.125,
            ],
          ];
          break;
      }
      formField.properties.dataFieldSync.frame[0][0] =
        Math.round(formField.properties.dataFieldSync.frame[0][0] / 0.125) *
        0.125;
      formField.properties.dataFieldSync.frame[0][1] =
        Math.round(formField.properties.dataFieldSync.frame[0][1] / 0.125) *
        0.125;
      root.formStructure.pages[page].fields.push(formField);
      setFormJson({
        pages: root.formStructure.pages,
      });
      setIsNewField(true);
      updatedCurrentField(fieldUid);
      if (type == "TableField") {
        for (let rows = 0; rows < 3; rows++) {
          formField = JSON.parse(JSON.stringify(addFormFields["LabelField"]));
          formField.properties.dataFieldSync.uid = generateRandomInt64String();
          formField.properties.dataFieldSync.parentTableId =
            formJson.currentField.dataFieldSync?.uid;
          formField.properties.dataFieldSync.tableCellAddress =
            mapping[rows] + 0;
          formField.properties.dataFieldSync.label = "Column " + (rows + 1);
          formField.properties.attributedStringHTML = `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
            <html>
              <head>
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                <meta http-equiv="Content-Style-Type" content="text/css" />
                <title></title>
                <meta name="Generator" content="Cocoa HTML Writer" />
              </head>
              <body>
                <p style="margin: 0; color: #000000"><span style="font-family: Helvetica">${
                  "Column " + (rows + 1)
                }</span></p>
              </body>
            </html>`;
          formField.properties.dataFieldSync.frame = [
            [
              Math.round((left + 1.5 * rows) / 0.125) * 0.125,
              Math.round(top / 0.125) * 0.125,
            ],
            [1.5, 0.375],
          ];
          formField.properties.dataFieldSync.fontSize = Number(
            Number(formJson.root.formStructure.defaultFontSize) > 18
              ? 18
              : formJson.root.formStructure.defaultFontSize
          );
          root.formStructure.pages[page].fields.push(formField);
          for (let columns = 1; columns < 4; columns++) {
            formField = JSON.parse(JSON.stringify(addFormFields["TextField"]));
            formField.properties.dataFieldSync.uid =
              generateRandomInt64String();
            formField.properties.dataFieldSync.fontSize = Number(
              Number(formJson.root.formStructure.defaultFontSize) > 18
                ? 18
                : formJson.root.formStructure.defaultFontSize
            );
            formField.properties.dataFieldSync.parentTableId =
              formJson.currentField.dataFieldSync?.uid;
            formField.properties.dataFieldSync.tableCellAddress =
              mapping[rows] + columns;
            formField.properties.dataFieldSync.frame = [
              [
                Math.round((left + 1.5 * rows) / 0.125) * 0.125,
                Math.round((top + 0.375 * columns) / 0.125) * 0.125,
              ],
              [1.5, 0.375],
            ];
            root.formStructure.pages[page].fields.push(formField);
          }
        }
      }
    })
  );
  setFormJson({
    pages: formJson.root.formStructure.pages,
  });
  if (type == "LabelField") {
    setIsNewLabelField(true);
  }
}

export function updateFormJson(
  parentField: string,
  field: string,
  value: string | number | boolean | Array<string> | undefined,
  isNested?: any,
  rowKey?: string | number,
  columnKey?: string | number,
  position?: string
) {
  let changeAccordingToFontSize = true;
  if (formJson.currentField.dataFieldSync.label?.length != value?.length - 1) {
    changeAccordingToFontSize = false;
  }
  if (parentField == "dataFieldSync") {
    if (formJson.currentField.dataFieldSync?.hasOwnProperty(field)) {
      setFormJson(
        "currentField",
        produce(
          (item: any) =>
            (item.dataFieldSync[field] =
              field == "fontSize" ? Number(value) : value)
        )
      );
    }
    if (field == "label" || field == "fontSize") {
      setCurrenFormSavedFields(
        currenFormSavedFields().filter(
          (f: any) =>
            String(formJson.currentField?.dataFieldSync.uid) != String(f)
        )
      );
      if (
        formJson.currentField?.dataFieldSync?.parentTableId == 0 ||
        formJson.currentField?.dataFieldSync?.parentTableId == ""
      ) {
        setFormJson(
          "currentField",
          produce((item: any) => {
            if (formJson.currentField.dataFieldSync.type == "CheckBoxField") {
              if (
                formJson.currentField.dataFieldSync.uid ==
                item.dataFieldSync.uid
              ) {
                const frame: any = item.dataFieldSync.frame;
                frame[1][1] = calculateCheckboxHeight(
                  item.dataFieldSync.frame[1][0] - 0.09,
                  item.dataFieldSync.label,
                  item.dataFieldSync.fontSize,
                  item.dataFieldSync.shouldDisplayLabel,
                  "Helvetica"
                );
                item.dataFieldSync.frame = frame;
              }
            }
            if (formJson.currentField.dataFieldSync.type == "LabelField") {
              if (
                formJson.currentField.dataFieldSync.uid ==
                item.dataFieldSync.uid
              ) {
                const frame: any = item.dataFieldSync.frame;
                if (String(item.dataFieldSync.parentTableId) == "0") {
                  frame[1][1] = calculateLabelHeight(
                    item.dataFieldSync.fontSize,
                    // Old Code
                    // item.dataFieldSync.label,
                    // New Code
                    item.dataFieldSync.label.replace(/\n{2,}/g, (match: any) =>
                      "\n".repeat(Math.max(1, match.length / 2.5))
                    ),
                    item.dataFieldSync.frame[1][0],
                    item.font == 0
                      ? "Times New Roman"
                      : item.font == 1
                      ? "Helvetica"
                      : "Courier New"
                  );
                }
                item.dataFieldSync.frame = frame;
              }
            }
            if (
              (formJson.currentField.dataFieldSync.type == "TextField" ||
                formJson.currentField.dataFieldSync.type == "DateField" ||
                formJson.currentField.dataFieldSync.type == "DropdownField" ||
                formJson.currentField.dataFieldSync.type == "LocationField") &&
              field == "fontSize"
            ) {
              if (
                formJson.currentField.dataFieldSync.uid ==
                item.dataFieldSync.uid
              ) {
                const frame: any = item.dataFieldSync.frame;
                frame[1][1] =
                  Math.round((item.dataFieldSync.fontSize / 72) * 4 + 0.49) /
                    4 +
                  0.125;
                if (
                  formJson.currentField.dataFieldSync.type == "LocationField"
                ) {
                  frame[1][1] = frame[1][1] + 1;
                }
                item.dataFieldSync.frame = frame;
              }
            }
            if (
              formJson.currentField.dataFieldSync.type == "RadioButtonField" &&
              field == "fontSize"
            ) {
              if (
                formJson.currentField.dataFieldSync.uid ==
                item.dataFieldSync.uid
              ) {
                const frame: any = item.dataFieldSync.frame;
                frame[1][1] =
                  Math.ceil(
                    radioFieldButtons(
                      item.choiceFieldSync.items.map((item: any) => ({
                        title: item.title,
                      })),
                      item.dataFieldSync.fontSize,
                      item.dataFieldSync.frame[1][0]
                    ).bounds.height / 0.125
                  ) * 0.125;
                item.dataFieldSync.frame = frame;
              }
            }
            if (
              formJson.currentField.dataFieldSync.type != "LabelField" &&
              formJson.currentField.dataFieldSync.type != "CheckBoxField" &&
              formJson.currentField.dataFieldSync.type != "ImageField" &&
              formJson.currentField.dataFieldSync.type != "LineField" &&
              formJson.currentField.dataFieldSync.type != "TableField" &&
              formJson.currentField.dataFieldSync.type != "ScriptButtonField" &&
              formJson.currentField?.dataFieldSync.shouldDisplayLabel &&
              formJson.currentField?.dataFieldSync.label.replace(":", "")
                ?.length > 0 &&
              (formJson.currentField?.dataFieldSync.parentTableId == 0 ||
                formJson.currentField?.dataFieldSync.parentTableId == "" ||
                formJson.currentField?.dataFieldSync.parentTableId == "0")
            ) {
              const elmt: any = document.getElementById(
                formJson.currentField?.dataFieldSync.uid + "_label_"
              );
              const labelWidth: any = calculateSize(
                formJson.currentField?.dataFieldSync.fontSize,
                elmt
              ).pLW;
              item.dataFieldSync["labelWidth"] =
                labelWidth < 0.375 ? 0.375 : labelWidth;
              // const frame: any = item.dataFieldSync.frame;
              // if (labelWidth < 0.375) {
              //   frame[0][0] = labelWidth + 0.5;
              // }
            } else {
              item.dataFieldSync["labelWidth"] = 0;
              // const labelElement = document.getElementById(
              //   `label_${formJson.currentField.dataFieldSync?.uid}`
              // );
              // const frame: any = item.dataFieldSync.frame;
              // if (value == "") {
              //   frame[1][1] = 0.375;
              //   item.dataFieldSync.frame = frame;
              // }
              // if (labelElement) {
              //   setTimeout(() => {
              //     frame[1][1] = Number(
              //       convert(labelElement.clientHeight, "px-in")?.toFixed(3)
              //     );
              //     item.dataFieldSync.frame = frame;
              //   }, 0);
              // }
            }
          })
        );
      }
    }
    if (field == "linkedFieldId") {
      setFormJson(
        "currentField",
        produce((item: any) => (item.linkType = "linked_child"))
      );
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields?.forEach((item: any) => {
              if (value == item.properties.dataFieldSync?.uid) {
                item.properties.linkType = "linked_parent";
              }
            });
          });
        })
      );
    }
    if (field == "tableFieldType") {
      let fieldData: any = {};
      switch (value) {
        case "CheckBoxField":
          fieldData = JSON.parse(JSON.stringify(checkboxFieldData));
          break;

        case "DropdownField":
          fieldData = JSON.parse(JSON.stringify(dropdownFieldData));
          break;

        case "LabelField":
          fieldData = JSON.parse(JSON.stringify(labelFieldData));
          break;

        case "SignatureField":
          fieldData = JSON.parse(JSON.stringify(signatureFieldData));
          break;

        case "text":
        case "number":
        case "currency":
        case "text_calculated":
          fieldData = JSON.parse(JSON.stringify(textFieldData));
          break;

        case "date":
        case "time":
        case "date_time":
        case "date_calculated":
          fieldData = JSON.parse(JSON.stringify(dateFieldData));
          break;

        default:
          break;
      }
      setFormJson(
        "root",
        produce((root: any) => {
          root.formStructure.pages.forEach((page: any) => {
            page.fields?.forEach((item: any, key: any) => {
              if (
                item.properties.dataFieldSync.uid ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                fieldData.properties.dataFieldSync.uid =
                  item.properties.dataFieldSync.uid;
                fieldData.properties.dataFieldSync.frame =
                  item.properties.dataFieldSync.frame;
                fieldData.properties.dataFieldSync.parentTableId =
                  item.properties.dataFieldSync.parentTableId;
                fieldData.properties.dataFieldSync.tableCellAddress =
                  item.properties.dataFieldSync.tableCellAddress;
                fieldData.properties.dataFieldSync.fontSize = Number(
                  formJson.root.formStructure.defaultFontSize
                );
                if (fieldData.type == "TextField") {
                  fieldData.properties.inputType =
                    value == "text_calculated" ? "calculated" : value;
                }
                if (fieldData.type == "DateField") {
                  fieldData.properties.dateInputType =
                    value == "date_calculated" ? "calculated" : value;
                }
                if (value == "time") {
                  fieldData.properties.dateFormat = "hh:mm a";
                } else if (value == "date_time") {
                  fieldData.properties.dateFormat = "dd MMMM yyyy 'at' hh:mm a";
                }
                page.fields[key] = fieldData;
              }
            });
          });
        })
      );
      setFormJson({
        currentField: formJson.pages[formJson.currentPage]?.fields.filter(
          (field: any) =>
            field.properties.dataFieldSync.uid ==
            formJson.currentField?.dataFieldSync?.uid
        )[0].properties,
      });
      if (value == "LabelField") {
        setIsNewLabelField(true);
      }
    }
  } else if (parentField == "choiceFieldSync") {
    if (formJson.currentField.choiceFieldSync.hasOwnProperty(field)) {
      setFormJson(
        "currentField",
        produce((item: any) => (item.choiceFieldSync[field] = value))
      );
    }
    if (field == "items") {
      if (formJson.currentField.choiceFieldSync?.linkType == "linked_parent") {
        setFormJson(
          "pages",
          produce((pages: any) => {
            pages.forEach((page: any) => {
              page.fields?.forEach((item: any) => {
                if (
                  formJson.currentField.dataFieldSync?.uid ==
                  item.properties.dataFieldSync?.linkedFieldId
                ) {
                  item.properties.choiceFieldSync.items = value;
                }
              });
            });
          })
        );
      }
    }
    if (field == "linkType") {
      const tempArr: any = formJson.currentField.choiceFieldSync?.items;
      if (formJson.currentField.choiceFieldSync?.nestedListString) {
        JSON.parse(
          formJson.currentField.choiceFieldSync?.nestedListString
        ).forEach((item: any) => {
          item.subTitles.forEach((subItem: any) => {
            tempArr.push(subItem.subTitle);
          });
        });
      }

      setFormJson(
        "currentField",
        produce((item: any) => (item.choiceFieldSync.items = tempArr))
      );
      setFormJson(
        "currentField",
        produce((item: any) => (item.choiceFieldSync.nestedListString = ""))
      );

      setLinkedField("");
      setLinkedFieldLabel("");
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields?.forEach((item: any) => {
              if (
                formJson.currentField.dataFieldSync?.linkedFieldId ==
                item.properties.dataFieldSync?.uid
              ) {
                item.properties.choiceFieldSync[field] = value;
              }
            });
          });
        })
      );
      setFormJson(
        "currentField",
        produce((item: any) => (item.dataFieldSync.linkedFieldId = 0))
      );
    }
    if (field == "linkedFieldId") {
      setFormJson(
        "currentField",
        produce(
          (item: any) =>
            (item.choiceFieldSync.linkType = isNested
              ? "linked_nested"
              : "linked_child")
        )
      );
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields?.forEach((item: any) => {
              if (value == item.properties.dataFieldSync?.uid) {
                item.properties.choiceFieldSync.linkType = "linked_parent";
                setFormJson(
                  "currentField",
                  produce(
                    (val: any) =>
                      (val.dataFieldSync.linkedFieldId =
                        item.properties.dataFieldSync?.uid)
                  )
                );
                if (isNested) {
                  const tempArr: any = [];
                  item.properties.choiceFieldSync.items.forEach((item: any) => {
                    const tempObject: any = {
                      title: item.title,
                      subTitles: [],
                    };
                    tempArr.push(tempObject);
                  });
                  setFormJson(
                    "currentField",
                    produce(
                      (val: any) =>
                        (val.choiceFieldSync.nestedListString =
                          JSON.stringify(tempArr))
                    )
                  );
                }
              }
            });
          });
        })
      );
    }
  } else if (parentField == "imageFieldCommon") {
    setFormJson(
      "currentField",
      produce((item: any) => {
        item.imageFieldCommon[field] = value;
      })
    );
  } else if (parentField == "inkFieldSync") {
    setFormJson(
      "currentField",
      produce((item: any) => (item.inkFieldSync[field] = value))
    );
  } else {
    if (field == "row_sizes") {
      const tempRowArr: any = formJson.currentField.row_sizes?.split(",");
      tempRowArr[rowKey] = String(Number(tempRowArr[rowKey]) + Number(value));

      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields?.forEach((item: any) => {
              if (
                item.properties.dataFieldSync.uid ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                item.properties[field] = tempRowArr.join(",");
                const tempArr: any = item.properties.dataFieldSync.frame;
                tempArr[1][1] = tempArr[1][1] + Number(value);
                item.properties.dataFieldSync.frame = tempArr;
              }
              if (
                item.properties.dataFieldSync.parentTableId ==
                  formJson.currentField.dataFieldSync?.uid &&
                rowKey != undefined
              ) {
                if (
                  item.properties.dataFieldSync.tableCellAddress.includes(
                    rowKey
                  )
                ) {
                  const tempArr: any = item.properties.dataFieldSync.frame;
                  tempArr[1][1] = tempArr[1][1] + Number(value);
                  item.properties.dataFieldSync.frame = tempArr;
                } else {
                  if (
                    item.properties.dataFieldSync.tableCellAddress.split(
                      ""
                    )[1] > rowKey
                  ) {
                    const tempArr: any = item.properties.dataFieldSync.frame;
                    tempArr[0][1] = tempArr[0][1] + Number(value);
                    item.properties.dataFieldSync.frame = tempArr;
                  }
                }
              }
            });
          });
        })
      );
    } else if (field == "rows") {
      let tempArr: any = formJson.currentField?.row_sizes?.split(",");
      let minusTableHight: number = 0;
      if (value > 0) {
        tempArr.splice(rowKey, 0, "0.375");
      } else {
        minusTableHight = -Number(tempArr[rowKey]);
        tempArr.splice(rowKey, 1);
      }
      const arr: any = [];
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any, page_key: any) => {
            const fieldsArr: any = page?.fields ? page.fields : [];
            if (
              Number(formJson.currentField.dataFieldSync.frame[0][1]) +
                Number(formJson.currentField.dataFieldSync.frame[1][1]) >=
                11.2 &&
              (position == "above" || position == "below")
            ) {
              setPopup("tableChangesAlert");
              return;
            }
            if (value > 0) {
              page.fields?.forEach((field: any) => {
                if (
                  formJson.currentField.dataFieldSync?.uid ==
                  field.properties.dataFieldSync.parentTableId
                ) {
                  for (let index = 0; index < tempArr.length; index++) {
                    if (index >= Number(rowKey)) {
                      if (
                        field.properties.dataFieldSync.tableCellAddress.includes(
                          index
                        )
                      ) {
                        if (!arr.includes(field.properties.dataFieldSync.uid)) {
                          field.properties.dataFieldSync.tableCellAddress =
                            field.properties.dataFieldSync.tableCellAddress.split(
                              ""
                            )[0] +
                            (index + 1);
                          arr.push(field.properties.dataFieldSync.uid);
                        }
                      }
                    }
                    if (index >= Number(rowKey) + 1 && value > 0) {
                      if (
                        field.properties.dataFieldSync.tableCellAddress.includes(
                          index
                        )
                      ) {
                        const tempArr: any =
                          field.properties.dataFieldSync.frame;
                        tempArr[0][1] = tempArr[0][1] + 0.375;
                        field.properties.dataFieldSync.frame = tempArr;
                      }
                    }
                  }
                }
              });
              if (formJson.currentPage == page_key) {
                for (
                  let i = 0;
                  i < Number(formJson.currentField?.columns);
                  i++
                ) {
                  const newFieldObj: any = JSON.parse(
                    JSON.stringify(textFieldData)
                  );
                  newFieldObj.properties.dataFieldSync.uid =
                    generateRandomInt64String();
                  newFieldObj.properties.dataFieldSync.fontSize = Number(
                    formJson.root.formStructure.defaultFontSize
                  );
                  newFieldObj.properties.dataFieldSync.parentTableId =
                    formJson.currentField.dataFieldSync?.uid;
                  newFieldObj.properties.dataFieldSync.tableCellAddress =
                    mapping[i] + rowKey;

                  let frame: any = [];
                  page.fields?.forEach((field: any) => {
                    if (
                      field.properties.dataFieldSync.parentTableId ==
                        formJson.currentField.dataFieldSync?.uid &&
                      field.properties.dataFieldSync.tableCellAddress ==
                        mapping[i] + (Number(rowKey) - 1)
                    ) {
                      frame = JSON.parse(
                        JSON.stringify(field.properties.dataFieldSync.frame)
                      );
                      frame[0][1] =
                        frame[0][1] +
                        Number(
                          formJson.currentField?.row_sizes?.split(",")[
                            rowKey - 1
                          ]
                        );
                      frame[1][1] = 0.375;
                    }
                  });
                  newFieldObj.properties.dataFieldSync.frame = frame;
                  fieldsArr.push(newFieldObj);
                }
              }
            } else {
              const removeFieldKeys: any = [];
              fieldsArr.forEach((field: any, key: any) => {
                if (
                  formJson.currentField.dataFieldSync?.uid ==
                    field.properties.dataFieldSync.parentTableId &&
                  field.properties.dataFieldSync.tableCellAddress.includes(
                    rowKey
                  )
                ) {
                  removeFieldKeys.push(key);
                }
              });
              removeFieldKeys.sort((a: any, b: any) => b - a);
              removeFieldKeys.forEach((item: any) => {
                fieldsArr.splice(item, 1);
              });
            }
            page.fields?.forEach((item: any) => {
              if (
                item.properties.dataFieldSync.uid ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                item.properties[field] = item.properties[field] + value;
                item.properties.row_sizes = tempArr.join(",");
                tempArr = item.properties.dataFieldSync.frame;

                tempArr[1][1] =
                  tempArr[1][1] + (value > 0 ? 0.375 : minusTableHight);
                item.properties.dataFieldSync.frame = tempArr;
              }
              if (
                item.properties.dataFieldSync.parentTableId ==
                  formJson.currentField.dataFieldSync?.uid &&
                rowKey != undefined
              ) {
                for (
                  let index = 0;
                  index < formJson.currentField?.row_sizes?.split(",").length;
                  index++
                ) {
                  if (index >= Number(rowKey) && value <= 0) {
                    if (
                      item.properties.dataFieldSync.tableCellAddress.includes(
                        index + 1
                      )
                    ) {
                      if (!arr.includes(item.properties.dataFieldSync.uid)) {
                        const tempArr: any =
                          item.properties.dataFieldSync.frame;
                        tempArr[0][1] =
                          tempArr[0][1] -
                          formJson.currentField?.row_sizes?.split(",")[rowKey];
                        item.properties.dataFieldSync.frame = tempArr;
                        item.properties.dataFieldSync.tableCellAddress =
                          item.properties.dataFieldSync.tableCellAddress.split(
                            ""
                          )[0] + String(Number(index));
                        arr.push(item.properties.dataFieldSync.uid);
                      }
                    }
                  }
                }
              }
            });
          });
        })
      );
    } else if (field == "columns") {
      let tempArr: any = formJson.currentField?.column_sizes?.split(",");
      if (value > 0) {
        tempArr.splice(columnKey, 0, "1.5");
      } else {
        tempArr.splice(Number(columnKey) - 1, 1);
      }

      const arr: any = [];
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any, page_key: any) => {
            const fieldsArr: any = page?.fields ? page.fields : [];
            if (
              position == "before" &&
              columnKey == 0 &&
              formJson.currentField.dataFieldSync.frame[0][0] <= 1.8
            ) {
              setPopup("tableChangesAlert");
              return;
            } else if (
              Number(formJson.currentField.dataFieldSync.frame[0][0]) +
                Number(formJson.currentField.dataFieldSync.frame[1][0]) >=
                6.9 &&
              (position == "before" || position == "after")
            ) {
              setPopup("tableChangesAlert");
              return;
            }
            if (value > 0) {
              page.fields?.forEach((field: any) => {
                if (
                  formJson.currentField.dataFieldSync?.uid ==
                  field.properties.dataFieldSync.parentTableId
                ) {
                  for (let index = 0; index < tempArr.length; index++) {
                    if (index >= Number(columnKey)) {
                      if (
                        field.properties.dataFieldSync.tableCellAddress.includes(
                          mapping[index]
                        )
                      ) {
                        if (!arr.includes(field.properties.dataFieldSync.uid)) {
                          field.properties.dataFieldSync.tableCellAddress =
                            mapping[index + 1] +
                            field.properties.dataFieldSync.tableCellAddress.split(
                              ""
                            )[1];
                          arr.push(field.properties.dataFieldSync.uid);
                        }
                      }
                    }
                    if (index >= Number(columnKey) + 1 && value > 0) {
                      if (
                        field.properties.dataFieldSync.tableCellAddress.includes(
                          mapping[index]
                        )
                      ) {
                        const arr: any = field.properties.dataFieldSync.frame;
                        arr[0][0] = arr[0][0] + 1.5;
                        field.properties.dataFieldSync.frame = arr;
                      }
                    }
                  }
                }
              });
              if (formJson.currentPage == page_key) {
                for (let i = 0; i < Number(formJson.currentField?.rows); i++) {
                  let newFieldObj: any = {};
                  if (i == 0) {
                    newFieldObj = JSON.parse(JSON.stringify(labelFieldData));

                    newFieldObj.properties.dataFieldSync.label =
                      "Column " + (Number(formJson.currentField?.columns) + 1);
                    newFieldObj.properties.attributedStringHTML = `<!DOCTYPE html PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
                      <html>
                        <head>
                          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                          <meta http-equiv="Content-Style-Type" content="text/css" />
                          <title></title>
                          <meta name="Generator" content="Cocoa HTML Writer" />
                        </head>
                        <body>
                          <p style="margin: 0; color: #000000"><span style="font-family: Helvetica">${
                            "Column " +
                            (Number(formJson.currentField?.columns) + 1)
                          }</span></p>
                        </body>
                      </html>`;
                  } else {
                    newFieldObj = JSON.parse(JSON.stringify(textFieldData));
                  }
                  newFieldObj.properties.dataFieldSync.fontSize = Number(
                    formJson.root.formStructure.defaultFontSize
                  );
                  newFieldObj.properties.dataFieldSync.uid =
                    generateRandomInt64String();
                  newFieldObj.properties.dataFieldSync.parentTableId =
                    formJson.currentField.dataFieldSync?.uid;
                  newFieldObj.properties.dataFieldSync.tableCellAddress =
                    mapping[columnKey] + i;

                  let frame: any = [];
                  page.fields?.forEach((field: any) => {
                    if (
                      field.properties.dataFieldSync.parentTableId ==
                      formJson.currentField.dataFieldSync?.uid
                    ) {
                      if (
                        field.properties.dataFieldSync.tableCellAddress ==
                        mapping[Number(columnKey) + 1] + i
                      ) {
                        frame = JSON.parse(
                          JSON.stringify(field.properties.dataFieldSync.frame)
                        );
                        frame[0][0] = frame[0][0] - 1.5;
                      } else if (
                        columnKey == Number(formJson.currentField?.columns) &&
                        field.properties.dataFieldSync.tableCellAddress ==
                          mapping[Number(columnKey) - 1] + i
                      ) {
                        frame = JSON.parse(
                          JSON.stringify(field.properties.dataFieldSync.frame)
                        );
                        frame[0][0] =
                          frame[0][0] +
                          Number(
                            formJson.currentField.column_sizes.split(",")[
                              columnKey - 1
                            ]
                          );
                      }
                    }
                  });
                  frame[1][0] = 1.5;
                  newFieldObj.properties.dataFieldSync.frame = frame;
                  fieldsArr.push(newFieldObj);
                }
              }
            } else {
              const removeFieldKeys: any = [];
              fieldsArr.forEach((field: any, key: any) => {
                if (
                  formJson.currentField.dataFieldSync?.uid ==
                    field.properties.dataFieldSync.parentTableId &&
                  field.properties.dataFieldSync.tableCellAddress.includes(
                    mapping[columnKey - 1]
                  )
                ) {
                  removeFieldKeys.push(key);
                }
              });
              removeFieldKeys.sort((a: any, b: any) => b - a);
              removeFieldKeys.forEach((item: any) => {
                fieldsArr.splice(item, 1);
              });
            }
            page.fields?.forEach((item: any) => {
              if (
                item.properties.dataFieldSync.uid ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                item.properties[field] = item.properties[field] + value;
                item.properties.column_sizes = tempArr.join(",");
                const arr = item.properties.dataFieldSync.frame;
                arr[1][0] =
                  arr[1][0] +
                  (value > 0
                    ? 1.5
                    : -formJson.currentField?.column_sizes?.split(",")[
                        columnKey - 1
                      ]);
                if (position == "before" && columnKey == 0) {
                  arr[0][0] = arr[0][0] - 1.5;
                }
                item.properties.dataFieldSync.frame = arr;
              }
              if (
                item.properties.dataFieldSync.parentTableId ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                for (let index = 0; index < tempArr.length + 1; index++) {
                  if (index >= Number(columnKey) && value <= 0) {
                    if (
                      item.properties.dataFieldSync.tableCellAddress.includes(
                        mapping[index]
                      )
                    ) {
                      if (!arr.includes(item.properties.dataFieldSync.uid)) {
                        const arr: any = item.properties.dataFieldSync.frame;
                        arr[0][0] =
                          arr[0][0] -
                          formJson.currentField?.column_sizes?.split(",")[
                            columnKey - 1
                          ];
                        item.properties.dataFieldSync.frame = arr;
                        item.properties.dataFieldSync.tableCellAddress =
                          mapping[index - 1] +
                          item.properties.dataFieldSync.tableCellAddress.split(
                            ""
                          )[1];
                        arr.push(item.properties.dataFieldSync.uid);
                      }
                    }
                  }
                }
                if (columnKey == 0 && position == "before") {
                  const arr: any = item.properties.dataFieldSync.frame;
                  arr[0][0] = arr[0][0] - 1.5;
                  item.properties.dataFieldSync.frame = arr;
                }
              }
            });
          });
        })
      );
    } else {
      setFormJson(
        "currentField",
        produce((item: any) => {
          item[field] = value;
          if (formJson.currentField.dataFieldSync.type == "LabelField") {
            if (
              formJson.currentField.dataFieldSync.uid == item.dataFieldSync.uid
            ) {
              const frame: any = item.dataFieldSync.frame;
              if (String(item.dataFieldSync.parentTableId) == "0") {
                frame[1][1] = calculateLabelHeight(
                  item.dataFieldSync.fontSize,
                  // Old Code
                  // item.dataFieldSync.label,
                  // New Code
                  item.dataFieldSync.label.replace(/\n{2,}/g, (match: any) =>
                    "\n".repeat(Math.max(1, match.length / 2.5))
                  ),
                  item.dataFieldSync.frame[1][0],
                  item.font == 0
                    ? "Times New Roman"
                    : item.font == 1
                    ? "Helvetica"
                    : "Courier New"
                );
              }
              item.dataFieldSync.frame = frame;
            }
          }
          if (field == "dateInputType") {
            item.dateFormat =
              value == "date_time"
                ? hourTimeFormat()
                  ? "dd MMMM yyyy 'at' HH:mm"
                  : "dd MMMM yyyy 'at' hh:mm a"
                : value == "time"
                ? hourTimeFormat()
                  ? "HH:mm"
                  : "hh:mm a"
                : "dd MMMM yyyy";
          }
        })
      );
      let oldFormat: string = "";
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields?.forEach((item: any) => {
              if (
                item.properties.dataFieldSync.uid ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                oldFormat = item.properties[field];
                item.properties[field] = value;
                if (field == "dateInputType") {
                  item.properties.dateFormat =
                    value == "date_time"
                      ? hourTimeFormat()
                        ? "dd MMMM yyyy 'at' HH:mm"
                        : "dd MMMM yyyy 'at' hh:mm a"
                      : value == "time"
                      ? hourTimeFormat()
                        ? "HH:mm"
                        : "hh:mm a"
                      : "dd MMMM yyyy";
                }
              }
            });
          });
        })
      );
      if (field == "locationFormat") {
        setLocationFormatUpdate({
          field_id: formJson.currentField.dataFieldSync?.uid,
          old_format: oldFormat,
          format: value,
        });
      }
    }
  }

  setIsFormJsonUpdated(true);
}

function layoutFont(uiFont: any, fontSize: any) {
  const fontName = uiFont.fontName;
  const fontSizeInPoints = Math.round((fontSize / 72) * 84 * 4) / 4;
  return { fontName, fontSize: fontSizeInPoints };
}

export function calculateCheckboxHeight(
  pW: any,
  label: any,
  fontSize: any,
  showLabel: any,
  uiFont: any
) {
  const displayLabel = label && showLabel ? label : "";
  const layoutFontObj = layoutFont(uiFont, fontSize);
  const paragraphStyle = {
    lineBreak: "word-wrap",
  };
  const boundingRect = getBoundingRect(
    displayLabel,
    {
      fontFamily: layoutFontObj.fontName,
      fontSize: `${layoutFontObj.fontSize}px`,
      lineHeight: paragraphStyle.lineBreak,
    },
    Math.round(pW * 84) - (16 + 7)
  );
  const lineHeight = Math.max(
    Math.ceil((boundingRect.height + 7) / 21) * 21,
    42
  );
  const height = (lineHeight - (4 + 12)) / 84;
  const pH = Math.ceil(height * 8) / 8;

  return pH;
}

// Old Code
// export function calculateLabelHeight(
//   fontSize: any,
//   text: any,
//   maxWidth: any,
//   font: any
// ) {
//   // Create canvas for text measurement
//   const canvas = document.createElement("canvas");
//   const ctx: any = canvas.getContext("2d");
//   // Convert font size to pixels
//   const fontSizeInPx = (fontSize / 72) * 84;
//   // Set font on canvas context
//   ctx.font = `${fontSizeInPx}px ${font}`;
//   // Calculate pixels per inch and inches per pixel
//   const pixelsPerInch = 84;
//   const inchesPerPixel = 1 / 84;
//   const maxWidthInPx = Math.round(maxWidth * pixelsPerInch);

//   // Function to wrap long word character by character
//   function wrapLongWord(word: string): string[] {
//     const wrappedParts = [];
//     let currentPart = "";
//     for (let char of word) {
//       const testPart = currentPart + char;
//       const metrics = ctx.measureText(testPart);
//       if (metrics.width > maxWidthInPx && currentPart.length > 0) {
//         wrappedParts.push(currentPart);
//         currentPart = char;
//       } else {
//         currentPart = testPart;
//       }
//     }
//     if (currentPart.length > 0) {
//       wrappedParts.push(currentPart);
//     }
//     return wrappedParts;
//   }

//   // Process text with mixed content
//   const words = text.split(" ");
//   let lines = [];
//   let currentLine = "";
//   for (let i = 0; i < words.length; i++) {
//     const word = words[i];
//     const testLine = currentLine + word;
//     const testLineWithSpace = testLine + " ";
//     const metrics = ctx.measureText(testLineWithSpace);
//     const wordMetrics = ctx.measureText(word);

//     // Check if single word is longer than max width
//     if (wordMetrics.width > maxWidthInPx) {
//       // If current line is not empty, push it before processing long word
//       if (currentLine.trim().length > 0) {
//         lines.push(currentLine.trim());
//         currentLine = "";
//       }
//       // Wrap long word and add its parts to lines
//       const wrappedWordParts = wrapLongWord(word);
//       lines.push(...wrappedWordParts.slice(0, -1));
//       // Add last part to current line
//       currentLine = wrappedWordParts[wrappedWordParts.length - 1] + " ";
//     }
//     // Normal word-wrap check
//     else if (metrics.width > maxWidthInPx && i > 0) {
//       lines.push(currentLine.trim());
//       currentLine = word + " ";
//     } else {
//       currentLine = testLineWithSpace;
//     }
//   }
//   // Add remaining line if not empty
//   if (currentLine.trim().length > 0) {
//     lines.push(currentLine.trim());
//   }
//   // Calculate full height (including line breaks)
//   const lineHeight = fontSizeInPx * 1.2;
//   const fullHeight = lineHeight * lines.length * inchesPerPixel;
//   // Calculate single line height
//   const singleLineHeight = fontSizeInPx * inchesPerPixel;
//   // Calculate final height based on conditions
//   let finalHeight = 0;

//   if (fullHeight > singleLineHeight || fontSize > 15.0) {
//     finalHeight = (Math.ceil((fullHeight * 8) / 3) * 3) / 8;
//   }

//   return finalHeight == 0 ? 0.375 : finalHeight;
// }

// New Code
export function calculateLabelHeight(
  fontSize: any,
  text: any,
  maxWidth: any,
  font: any
) {
  // Create canvas for text measurement
  const canvas = document.createElement("canvas");
  const ctx: any = canvas.getContext("2d");
  // Convert font size to pixels
  const fontSizeInPx =
    (Math.round((Number(fontSize) / 72) * 84 * 4) / 4 / 84) * 96;
  // Set font on canvas context
  ctx.font = `${fontSizeInPx}px ${font}`;
  // Calculate pixels per inch and inches per pixel
  const pixelsPerInch = 84;
  const inchesPerPixel = 1 / 84;
  const maxWidthInPx = Math.round(maxWidth * pixelsPerInch);

  // Function to wrap long words character by character
  function wrapLongWord(word: string): string[] {
    const wrappedParts = [];
    let currentPart = "";
    for (let char of word) {
      const testPart = currentPart + char;
      const metrics = ctx.measureText(testPart);
      if (metrics.width > maxWidthInPx && currentPart.length > 0) {
        wrappedParts.push(currentPart);
        currentPart = char;
      } else {
        currentPart = testPart;
      }
    }
    if (currentPart.length > 0) {
      wrappedParts.push(currentPart);
    }
    return wrappedParts;
  }

  // Split text by newline characters (\n) to preserve line breaks
  const textLines = text.split("\n").filter(Boolean);

  let lines: string[] = [];

  for (let textLine of textLines) {
    if (textLine.trim() === "") {
      // Empty line, add a placeholder to ensure height is calculated
      lines.push(" ");
      continue;
    }
    const words = textLine.split(" ");
    let currentLine = "";
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const testLine = currentLine + word;
      const testLineWithSpace = testLine + " ";
      const metrics = ctx.measureText(testLineWithSpace);
      const wordMetrics = ctx.measureText(word);
      // Check if single word is longer than max width
      if (wordMetrics.width - 8 > maxWidthInPx) {
        // If current line is not empty, push it before processing long word
        if (currentLine.trim().length > 0) {
          lines.push(currentLine.trim());
          currentLine = "";
        }
        // Wrap long word and add its parts to lines
        const wrappedWordParts = wrapLongWord(word);
        lines.push(...wrappedWordParts.slice(0, -1));
        // Add last part to current line
        currentLine = wrappedWordParts[wrappedWordParts.length - 1] + " ";
      }
      // Normal word-wrap check
      else if (metrics.width - 14 > maxWidthInPx && i > 0) {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      } else {
        currentLine = testLineWithSpace;
      }
    }
    // Add remaining line if not empty
    if (currentLine.trim().length > 0) {
      lines.push(currentLine.trim());
    }
  }
  // Calculate full height (including empty lines)
  // const lineHeight = fontSizeInPx * 1.2;
  const lineHeight = (fontSize / 72) * 84 * 1.2;
  const fullHeight = lineHeight * lines.length * inchesPerPixel;
  // Calculate single line height
  const singleLineHeight = fontSizeInPx * inchesPerPixel;
  // Calculate final height based on conditions
  let finalHeight = 0;

  if (fullHeight > singleLineHeight || fontSize > 15.0) {
    finalHeight = (Math.ceil((fullHeight * 8) / 3) * 3) / 8;
  }

  return finalHeight == 0 ? 0.375 : finalHeight;
}

export function radioFieldButtons(
  buttons: any,
  fontSize: any,
  containerWidth: any
) {
  if (!buttons || buttons.length === 0) {
    return [];
  }
  const PAGE_RES_DEFAULT: any = 84;
  const layoutPPI: any = 96;

  // Helper function to measure text
  function measureText(text: any, maxWidth: any) {
    const div: any = document.createElement("div");
    div.style.position = "absolute";
    div.style.visibility = "hidden";
    div.style["max-width"] = `${maxWidth}px`;
    div.style.fontSize = `${
      (Math.round((fontSize / 72) * PAGE_RES_DEFAULT * 4) / 4 / 84) * 96
    }px`;
    div.style.fontFamily = "Helvetica";
    div.style.whiteSpace = "pre-wrap";
    div.style["word-wrap"] = "break-word";
    div.textContent = text;

    document.body.appendChild(div);
    const size = {
      width: div.offsetWidth,
      height: div.offsetHeight,
    };
    document.body.removeChild(div);
    return size;
  }

  // Calculate available width
  let width = containerWidth * PAGE_RES_DEFAULT;
  if (width <= 0) {
    width = (window.innerWidth / layoutPPI) * PAGE_RES_DEFAULT;
  }

  // Initialize layout variables
  let lineWidth = 0;
  let lineHeight = 0;
  let y = 0;
  let lineStart = 0;
  const layoutedButtons = [...buttons];

  // New Code
  let buttonWidth: any = 30;
  // First pass: measure all buttons
  layoutedButtons.forEach((button, idx) => {
    const textSize = measureText(button.title, width - (16 + 7));
    const buttonFrame = {
      size: {
        width: Math.ceil(textSize.width + (16 + 7)),
        height: Math.max(Math.ceil((textSize.height + 7) / 21) * 21, 42),
      },
      origin: { x: 0, y: 0 },
    };
    button.realRect = buttonFrame;
    // Check if we need to start a new line
    // Old Code
    // if (
    //   lineWidth + 10 * (idx - lineStart + 1) + buttonFrame.size.width > width &&
    //   idx !== lineStart
    // ) {
    //   // Layout current line
    //   const leftover = Math.min(
    //     Math.round((width - lineWidth) / (idx - lineStart - 1)),
    //     40
    //   );
    //   let x = 0;
    //   for (let i = lineStart; i < idx; i++) {
    //     const btn = layoutedButtons[i];
    //     btn.realRect.origin.x = Math.round(x);
    //     x += btn.realRect.size.width + leftover;
    //     btn.realRect.origin.y = Math.round(y);
    //     btn.realRect.size.height = lineHeight - 12;
    //   }
    //   y += lineHeight;
    //   lineWidth = 0;
    //   lineHeight = 0;
    //   lineStart = idx;
    // }
    // New Code
    buttonWidth += buttonFrame.size.width;
    if (buttonWidth > containerWidth * 96 && idx !== lineStart) {
      // Layout current line
      const leftover = Math.min(
        Math.round((width - lineWidth) / (idx - lineStart - 1)),
        40
      );
      let x = 0;
      for (let i = lineStart; i < idx; i++) {
        const btn = layoutedButtons[i];
        btn.realRect.origin.x = Math.round(x);
        x += btn.realRect.size.width + leftover;
        btn.realRect.origin.y = Math.round(y);
        btn.realRect.size.height = lineHeight - 12;
      }
      y += lineHeight;
      lineWidth = 0;
      lineHeight = 0;
      lineStart = idx;
    }
    lineHeight = Math.max(buttonFrame.size.height, lineHeight);
    lineWidth += buttonFrame.size.width;
  });

  // Layout final line
  const leftover = Math.min(
    Math.round((width - lineWidth) / (layoutedButtons.length - lineStart - 1)),
    40
  );
  let x = 0;
  for (let i = lineStart; i < layoutedButtons.length; i++) {
    const btn = layoutedButtons[i];
    btn.realRect.origin.x = Math.round(x);
    x += btn.realRect.size.width + leftover;
    btn.realRect.origin.y = Math.round(y);
    btn.realRect.size.height = lineHeight - 12;
  }
  // Convert real coordinates to screen coordinates
  layoutedButtons.forEach((button) => {
    button.frame = {
      x:
        Math.round((button.realRect.origin.x / PAGE_RES_DEFAULT) * layoutPPI) +
        7,
      y: Math.round((button.realRect.origin.y / PAGE_RES_DEFAULT) * layoutPPI),
      width:
        Math.round(
          (button.realRect.size.width / PAGE_RES_DEFAULT) * layoutPPI
        ) - 7,
      height: Math.round(
        (button.realRect.size.height / PAGE_RES_DEFAULT) * layoutPPI
      ),
    };
  });
  // Calculate final bounds
  const bounds = {
    width: containerWidth,
    height: (y + lineHeight - (4 + 12)) / PAGE_RES_DEFAULT,
  };

  return {
    buttons: layoutedButtons,
    bounds: bounds,
  };
}

function getBoundingRect(text: string, style: any, maxWidth: number) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;
  context.font = `${style.fontSize} ${style.fontFamily}`;
  const words = text.split(/(\s+)/);
  let line = "";
  let maxHeight = 0;
  let totalHeight = 0;
  const lineHeight =
    parseInt(style?.lineHeight, 10) ||
    Math.ceil(parseFloat(style.fontSize) * 1.2);
  words.forEach((word) => {
    if (context.measureText(word).width > maxWidth) {
      let subWord = "";
      for (let i = 0; i < word.length; i++) {
        if (context.measureText(subWord + word[i]).width > maxWidth) {
          if (subWord) {
            totalHeight += lineHeight;
            maxHeight = Math.max(maxHeight, totalHeight);
            subWord = "";
          }
        }
        subWord += word[i];
      }
      if (subWord) {
        totalHeight += lineHeight;
        maxHeight = Math.max(maxHeight, totalHeight);
      }
    } else {
      const testLine = line + word;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > maxWidth && line !== "") {
        totalHeight += lineHeight;
        maxHeight = Math.max(maxHeight, totalHeight);
        line = word;
      } else {
        line = testLine;
      }
    }
  });
  totalHeight += lineHeight;
  return { width: maxWidth, height: maxHeight == 21 ? 42 : maxHeight };
}

function calculateSize(fontSize: any, label: any) {
  // Calculate displaySize
  const displaySize = (fontSize / 72) * 84;
  // Set font on the label element
  const fontSizeRounded = Math.round(displaySize * 4) / 4;
  label.style.fontSize = `${fontSizeRounded}px`;
  // Calculate the bounding rectangle
  const boundingRect = label.getBoundingClientRect();
  // Calculate the width and height
  let szWidth = Math.ceil(boundingRect.width) / 84;
  let szHeight = (Math.ceil(boundingRect.height) / 84) * 2;
  // Calculate width
  const width = szWidth;
  // Calculate _pLW
  const _pLW = Math.min(Math.ceil(width * 8) / 8, 1);

  return {
    width: width,
    height: szHeight,
    pLW: _pLW,
  };
}

export function calculateTextLines(
  text: any,
  width: any,
  fontSize: any,
  fontFamily: any
) {
  // Create a hidden div element
  const div = document.createElement("div");
  document.body.appendChild(div);

  // Apply styles
  div.style.position = "absolute";
  div.style.visibility = "hidden";
  div.style.width = `${width}px`;
  div.style.fontSize = `${fontSize}px`;
  div.style.fontFamily = fontFamily;
  div.style.lineHeight = "1";
  div.style.wordBreak = "break-word";
  div.style.whiteSpace = "normal";
  div.style.padding = "0";
  div.style.margin = "0";

  // Set text
  div.innerText = text;

  // Get computed styles
  // const computedStyle = window.getComputedStyle(div);
  const lineHeight = fontSize * 1.2;
  const totalHeight = div.clientHeight;

  // Remove the temporary element
  document.body.removeChild(div);

  // Calculate the number of lines
  return Math.ceil(totalHeight / lineHeight);
}

export function getTextHeightAndLines(
  text: any,
  frameWidth: any,
  fontSize: any
) {
  const canvas = document.createElement("canvas");
  const context: any = canvas.getContext("2d");
  context.font = `${fontSize}px Helvetica`;

  const words = text.split(" ");
  let lines = [];
  let currentLine = "";

  words.forEach((word: any) => {
    let testLine = currentLine ? `${currentLine} ${word}` : word;
    let textWidth = context.measureText(testLine).width;

    if (textWidth > frameWidth) {
      lines.push(currentLine);
      currentLine = word;
    } else {
      currentLine = testLine;
    }
  });

  if (currentLine) {
    lines.push(currentLine);
  }

  const lineHeight = fontSize * 1.2;
  const textHeight = lines.length * lineHeight;

  return { textHeight, lines: lines.length };
}

export function updatedCurrentField(uid: string) {
  setFormJson({ currentField: {} });
  setIsSidebarVisible(true);
  setIsFieldJustSelected(true);
  return setFormJson("currentField", {
    ...formJson.pages[formJson.currentPage]?.fields.filter(
      (item: any) => item.properties.dataFieldSync.uid === uid
    )[0]?.properties,
    inputType:
      formJson.pages[formJson.currentPage]?.fields.filter(
        (item: any) => item.properties.dataFieldSync.uid === uid
      )[0]?.properties?.inputType ?? "",
    dateInputType:
      formJson.pages[formJson.currentPage]?.fields.filter(
        (item: any) => item.properties.dataFieldSync.uid === uid
      )[0]?.properties?.dateInputType ?? "",
  });
}

export function addContectMenu(event: any) {
  const formFrame: any = document.querySelector(".builder-container");
  if (
    Object.keys(formJson.currentField)?.length == 0 ||
    formJson.currentField?.dataFieldSync?.parentTableId == 0 ||
    formJson.currentField?.dataFieldSync?.parentTableId == ""
  ) {
    setContextMenu("field");
    const formFrameRect = formFrame.getBoundingClientRect();
    const left: any = event.clientX - formFrameRect.left - 30;
    const top: any =
      event.clientY + formFrame.scrollTop - formFrameRect.top - 30;
    setContextMenuLeft(left);
    setContextMenuTop(top);
  }
}

export function updatedMultipleFields(uid: string) {
  setMultipleSelectField(true);
  if (uid != "ALL") {
    var field = formJson.pages[formJson.currentPage]?.fields.find(
      (item: any) => item.properties.dataFieldSync.uid === uid
    );
  }
  if (formJson.multipleFields.length > 1) {
    var selectedFields: any = [...formJson.multipleFields];
  } else if (Object.keys(formJson.currentField).length > 0) {
    var selectedFields: any = [formJson.currentField];
  } else {
    setFormJson({
      multipleFields: [],
    });
    setMultipleSelectField(false);
    setAllSelectField(false);
    return;
  }
  if (uid == "ALL") {
    selectedFields = [];
    formJson.pages[formJson.currentPage]?.fields.forEach((field: any) => {
      if (String(field.properties.dataFieldSync.parentTableId) == "0") {
        selectedFields.push(field.properties);
      }
    });
    setAllSelectField(false);
  } else {
    if (field) {
      const fieldIndex = selectedFields.findIndex(
        (selectedField: any) => selectedField.dataFieldSync.uid === uid
      );
      if (fieldIndex === -1) {
        selectedFields.push(field.properties);
      } else {
        if (!isFieldsMoved()) {
          selectedFields.splice(fieldIndex, 1);
        }
        setIsFieldsMoved(false);
      }
    }
  }
  setFormJson({
    currentField: {},
  });
  return setFormJson("multipleFields", selectedFields);
}

export {
  formDesigner,
  setFormDesigner,
  formJson,
  setFormJson,
  isFormJsonUpdated,
  setIsFormJsonUpdated,
  isNewField,
};
