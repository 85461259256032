import { Component, createEffect, createSignal, Show } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Dropdown from "~/components/global/Dropdown";
import LibraryTemplates from "~/components/global/LibraryTemplates";
import { notify } from "~/components/global/Notification";
import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/admin.layout";
import AddTemplateFormPopup from "~/components/popup/AddTemplateFormPopup";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import FormPreview from "~/components/popup/FormPreview";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { setPageTitle } from "~/root";
import { validateUser } from "~/routes/api/validateUser";
import {
  adjustFormFolderNames,
  formTemplateCategories,
  popupQueue,
  removePopup,
  selectedDropDown,
  setPopup,
} from "~/store/global.store";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/library/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
      },
    };
    let libraryForms = await fetchApi(apiParams);
    if (libraryForms.statusCode == 401) {
      throw redirect("/logout");
    }
    libraryForms = libraryForms.data;
    return { user_id, jwt_token, libraryForms };
  });
}

const index: Component = () => {
  setPageTitle(`${SITE_TITLE} - Library Form Builder`);
  const data = useRouteData<typeof routeData>();

  const [libraryForms, setLibraryForms] = createSignal(
    data()?.libraryForms as any
  );
  const [currentCategory, setCurrentCategory] = createSignal("" as string);
  const [deleteFormId, setDeleteFormId] = createSignal("" as string);

  createEffect(() => {
    if (data()?.libraryForms) {
      setLibraryForms(data()?.libraryForms);
      setTimeout(() => {
        adjustFormFolderNames();
      }, 0);
    }
  });

  async function updateList(user_id: string, jwt_token: string) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/library/list`,
      jwt_token,
      body: {
        user_id,
        category: currentCategory(),
      },
    };
    let libraryForms = await fetchApi(apiParams);
    if (libraryForms?.statusCode == 401) {
      window.location.href = "/logout";
    }
    libraryForms = libraryForms.data;
    setLibraryForms(libraryForms);
    adjustFormFolderNames();
  }

  return (
    <main>
      <Show when={popupQueue.includes("FormPreview")}>
        <FormPreview
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          adminPanel={true}
        ></FormPreview>
      </Show>
      <Show when={popupQueue.includes("addTemplateForm")}>
        <AddTemplateFormPopup
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          setValue={() => updateList(data()?.user_id, data()?.jwt_token)}
        ></AddTemplateFormPopup>
      </Show>
      <Show when={popupQueue.includes("confirmDeleteForm")}>
        <ConfirmPopup
          message="<b>Do you want to delete Form?</b>"
          name="confirmDeleteForm"
          onconfirm={async (status: boolean) => {
            if (status) {
              const apiParams = {
                method: "POST" as string,
                url: `${import.meta.env.VITE_API_URL}/form/library/delete`,
                jwt_token: data()?.jwt_token,
                body: {
                  form_id: deleteFormId(),
                },
              };
              let publication = await fetchApi(apiParams);
              if (
                publication?.statusCode == 401 ||
                publication?.statusCode == 403
              ) {
                window.location.href = "/logout";
              }
              if (publication && publication.status) {
                setLibraryForms(
                  libraryForms().filter(
                    (form: any) => form.id != deleteFormId()
                  )
                );
                adjustFormFolderNames();
                notify({
                  isNotified: true,
                  message: `Sample form template deleted successfully.`,
                  theme: "success",
                  placement: "top",
                  timeout: 2500,
                });
              }
            }
            removePopup("confirmDeleteForm");
          }}
        ></ConfirmPopup>
      </Show>
      <SystemLayout>
        <div class="center p-r">
          <div class="flex space-between">
            <h3 class="title color">Sample Templates</h3>
            <div class="flex gap">
              <div class="pr w-250">
                <div
                  id="form_template"
                  class="user-role-dropdown cur-p br-unset"
                >
                  <span class="dropdown-div lbl" id="form_template">
                    {currentCategory() ? currentCategory() : "ALL"}
                  </span>
                  <Icons name="chevronDown" stroke="#6c6e6f"></Icons>
                </div>
                <Show when={selectedDropDown() == "form_template"}>
                  <Dropdown
                    list={[
                      { label: "ALL", value: "" },
                      ...formTemplateCategories,
                    ]}
                    title="label"
                    link="link"
                    class="w-100 t-34"
                    selectedValue={currentCategory()}
                    isFormDropdown={true}
                    setValue={(val: any) => {
                      setCurrentCategory(val);
                      updateList(data()?.user_id, data()?.jwt_token);
                    }}
                  />
                </Show>
              </div>
              <button
                class="btn sm primary"
                onclick={() => setPopup("addTemplateForm")}
              >
                Add New Template
              </button>
            </div>
          </div>
          <Show when={libraryForms()?.length > 0}>
            <LibraryTemplates
              user_id={data()?.user_id}
              jwt_token={data()?.jwt_token}
              libraryForms={libraryForms()}
              adminPanel={true}
              updateList={(form_id: string) => {
                setLibraryForms(
                  libraryForms().map((form: any) => {
                    if (form.id == form_id) {
                      return {
                        ...form,
                        is_published: form.is_published ? false : true,
                      };
                    }
                    return form;
                  })
                );
                adjustFormFolderNames();
              }}
              deleteForm={(form_id: string) => {
                setDeleteFormId(form_id);
                setPopup("confirmDeleteForm");
              }}
            ></LibraryTemplates>
          </Show>
          <Show when={libraryForms()?.length == 0}>
            <div class="h-150p flex gap align-center justify-center">
              <span class="lbl">No Templates Found !!</span>
            </div>
          </Show>
        </div>
      </SystemLayout>
    </main>
  );
};

export default index;
