import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import Search from "../global/Search";
import Checkbox from "../global/Checkbox";
import { setUsersList, userId, usersList } from "~/store/user-form-role";
import fetchApi from "~/lib/api";
import messages from "~/helpers/message";
import { notify } from "../global/Notification";
import Popup from "../global/Popup";
import { formSharedUsers } from "~/store/global.store";

interface Props {
  user_id: string;
  jwt_token: string;
  users: any;
  groups: any;
  form_id: any;
  setValue: any;
  formUsers?: any;
  formTeams?: any;
  isForm?: boolean;
  folder_id?: any;
  folderUsers?: any;
}

const ShareForm: Component<Props> = (props: any) => {
  const [assignRightType, setAssignRightType] = createSignal("user" as string);
  const [groupsList, setGroupsList] = createSignal([]);
  const [advanceOptions, setAdvanceOptions] = createSignal(true as boolean);
  const [selectAllOption, setSelectAllOption] = createSignal(true as boolean);
  const [multipleUsers, setMultipleUsers] = createSignal([] as any);
  const [multipleGroups, setMultipleGroups] = createSignal([] as any);
  const [multipleUsersArr, setMultipleUsersArr] = createSignal([] as any);
  const [allUsersSelected, setAllUsersSelected] = createSignal(
    false as boolean
  );
  const [currentGroup, setCurrentGroup] = createSignal("" as string);
  const [selectedGroupUsers, setSelectedGroupUsers] = createSignal([] as any);

  onMount(() => {
    setMultipleUsers([]);
    setMultipleUsersArr([]);
    setMultipleGroups([]);
    setAllUsersSelected(false);
    if (props.users) {
      const newData: Array<object> = [];
      props.users.forEach((user: any) => {
        newData.push({
          id: user.id,
          name: user.first_name + " " + user.last_name,
        });
      });
      setUsersList([...newData]);
    }
    if (props?.isForm) {
      setMultipleUsers([...props.formUsers.map((user: any) => user.user_id)]);
      setMultipleGroups([
        ...props.formTeams.map((group: any) => group.group_id),
      ]);
      setMultipleUsersArr([...props.formUsers]);
    } else {
      setMultipleUsers([...props.folderUsers]);
    }
  });

  createEffect(() => {
    if (props.groups) {
      const newGroupData: Array<object> = [];
      props.groups.forEach((group: any) => {
        newGroupData.push({
          label: group.name,
          value: group.id,
        });
      });
      setGroupsList([...newGroupData]);
    }
    if (props?.formUsers || props?.folderUsers) {
      if (
        props?.formUsers.length - 1 == usersList?.length ||
        props?.folderUsers.length - 1 == usersList?.length
      ) {
        setAllUsersSelected(true);
      } else {
        setAllUsersSelected(false);
      }
    }
  });

  function toggleRightType(type: string) {
    setAssignRightType(type);
    addGroup("ALL");
    setCurrentGroup("");
    setAllUsersSelected(false);
    if (multipleUsers().length - 1 == usersList?.length) {
      setAllUsersSelected(true);
    } else {
      setAllUsersSelected(false);
    }
  }

  function addGroup(item: any) {
    updateUsersList("", false);
    setTimeout(() => {
      if (item == "ALL") {
        setAdvanceOptions(true);
        setSelectAllOption(true);
      } else {
        const groupUsers = item.users;
        const groupUsersList: any = usersList
          ?.filter((user: any) =>
            groupUsers.some((groupUser: any) =>
              user.name.includes(groupUser.trim())
            )
          )
          .map((user: any) => user);
        setUsersList(groupUsersList);
        setMultipleUsers([
          multipleUsers()[0],
          ...groupUsersList.map((obj: any) => obj.id),
        ]);

        const groupItems: any = usersList
          ?.filter((user: any) =>
            groupUsers.some((groupUser: any) =>
              user.name.includes(groupUser.trim())
            )
          )
          .map((user: any) => user);
        setSelectAllOption(true);
        setAdvanceOptions(true);
        setSelectedGroupUsers(groupItems);
      }
    }, 30);
  }

  async function updateUsersList(search_string: any, advanceOptions: boolean) {
    const usersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/list`,
      jwt_token: props.jwt_token as string,
      body: {
        user_id: props.user_id,
        search_string,
        sort_string: "",
      },
    };
    let users = await fetchApi(usersListApiParams);
    if (users?.statusCode == 401 || users?.statusCode == 403) {
      window.location.href = "/logout";
    }
    users = users.data.usersList.users;
    const newData: Array<object> = [];
    users.forEach((user: any) => {
      newData.push({
        id: user.id,
        name: user.first_name + " " + user.last_name,
      });
    });
    if (advanceOptions) {
      setSelectAllOption(true);
      setAllUsersSelected(false);
      setAdvanceOptions(true);
    }
    setUsersList([...newData]);
  }

  async function updateGroupsList(search_string: any) {
    const groupsListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/group/list`,
      jwt_token: props.jwt_token as string,
      body: {
        user_id: props.user_id,
        search_string: search_string,
        sort_string: "asc_name",
      },
    };
    const list = await fetchApi(groupsListApiParams);
    if (list?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (list?.statusCode == 403) {
      location.reload();
    }
    let groups = list.data.groupsList;
    const newGroupData: Array<object> = [];
    groups.forEach((group: any) => {
      newGroupData.push({
        label: group.name,
        value: group.id,
      });
    });
    setGroupsList([...newGroupData]);
  }

  function multipleUserRight(user_id: string) {
    let multipleUserArr: any = multipleUsers();
    if (user_id == "ALL") {
      if (multipleUserArr.length - 1 == usersList?.length) {
        setMultipleUsers(
          multipleUsersArr()
            .filter((user: any) => user.type === "team")
            .map((user: any) => user.user_id)
        );
      } else {
        setMultipleUsers([
          multipleUsers()[0],
          ...usersList?.map((i: { id: any }) => i.id),
        ]);
      }
    } else {
      if (multipleUsers().filter((val: any) => val == user_id).length > 0) {
        const index = multipleUserArr.indexOf(user_id);
        if (index > -1) {
          multipleUserArr.splice(index, 1);
        }
      } else {
        multipleUserArr.push(user_id);
      }
      setMultipleUsers(multipleUserArr);
    }
    setAllUsersSelected(false);
    if (multipleUsers().length - 1 == usersList?.length) {
      setAllUsersSelected(true);
    }
  }

  async function shareForm() {
    let formData: any = {};
    if (props?.isForm) {
      if (assignRightType() == "user") {
        if (props?.formUsers.length > 0) {
          if (!multipleUsers()?.includes(props.user_id)) {
            setMultipleUsers([...multipleUsers(), props.user_id]);
          }
          formData = {
            login_user_id: props.user_id,
            form_id: props.form_id,
            shared_users: props?.isForm
              ? [
                  ...new Set(
                    multipleUsers().filter(
                      (item: any) =>
                        !formSharedUsers().some((obj: any) =>
                          obj.users.includes(item)
                        )
                    )
                  ),
                ].join(",")
              : "",
            removed_users: props?.isForm
              ? [
                  ...new Set(
                    formSharedUsers().reduce((acc: any, obj: any) => {
                      let diff = obj.users.filter(
                        (user: any) => !multipleUsers().includes(user)
                      );
                      return acc.concat(diff);
                    }, [])
                  ),
                ].join(",")
              : "",
          };
          updateShareFormInDB(formData, true);
        } else {
          props.form_id.split(",").forEach((form_id: string, key: number) => {
            formData = {
              login_user_id: props.user_id,
              form_id,
              shared_users: [
                ...new Set(
                  multipleUsers().filter(
                    (item: any) =>
                      !formSharedUsers()
                        .filter((form: any) => form.form_id == form_id)
                        .some((obj: any) => obj.users.includes(item))
                  )
                ),
              ]
                .filter((item: any) => item)
                .join(","),
              removed_users: "",
            };
            updateShareFormInDB(
              formData,
              key == props.form_id.split(",").length - 1 ? true : false
            );
          });
        }
      } else {
        props.form_id.split(",").forEach((form_id: string, key: number) => {
          formData = {
            login_user_id: props.user_id,
            form_id,
            shared_teams: multipleGroups()
              .filter(
                (groupId: string) =>
                  !props?.formTeams.some(
                    (team: any) => team.group_id === groupId
                  )
              )
              .join(","),
            removed_teams: props?.formTeams
              .map((team: any) => team.group_id)
              .filter((groupId: string) => !multipleGroups().includes(groupId))
              .join(","),
          };
          updateShareFormInDB(
            formData,
            key == props.form_id.split(",").length - 1 ? true : false
          );
        });
      }
    } else {
      const shared_users: any = multipleUsers()
        .filter((folder_id: any) => !props?.folderUsers.includes(folder_id))
        .filter((user_id: any) => user_id && user_id?.trim() != "");
      const removed_users: any = props?.folderUsers
        .filter((folder_id: any) => !multipleUsers().includes(folder_id))
        .filter((user_id: any) => user_id && user_id?.trim() != "");
      let userFolderRightsApiParams: any = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/folder/user/add/rights`,
        jwt_token: props.jwt_token as string,
        body: {
          user_id: props.user_id,
          folder_id: props?.folder_id,
          shared_users: shared_users.join(","),
          removed_users: removed_users.join(","),
        },
      };
      const folderUserRight = await fetchApi(userFolderRightsApiParams);
      let message: any = messages.error.share_folder;
      let theme: string = "danger";
      if (folderUserRight && folderUserRight.status) {
        props.setValue(true);
        message = messages.form.share_folder;
        theme = "success";
      }
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
      setAllUsersSelected(false);
    }
  }

  async function updateShareFormInDB(formData: any, is_last: boolean) {
    let userFormRightsApiParams: any = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/user/add/rights`,
      jwt_token: props.jwt_token as string,
      body: formData,
    };
    const formUserRight = await fetchApi(userFormRightsApiParams);
    if (formUserRight?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (formUserRight?.statusCode == 403) {
      location.reload();
    }
    let message: any = messages.error.share_form;
    let theme: string = "danger";
    if (formUserRight && formUserRight.status) {
      props.setValue(true);
      message = messages.form.share_form;
      theme = "success";
    }
    if (is_last) {
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
      setAllUsersSelected(false);
    }
  }

  return (
    <Popup
      heading={props?.isForm ? "Share Form" : "Share Folder"}
      classes="small"
      name="shareForm"
      overlap={true}
    >
      <div class="flex app-flex space-between flex-wrap">
        <Show when={props?.isForm}>
          <div class="p-10 flex app-flex gap w-100">
            <div class="switch-wrapper w-100 mb-0">
              <input
                id="users"
                type="radio"
                name="switch"
                onchange={() => toggleRightType("user")}
                checked={assignRightType() == "user"}
              />
              <input
                id="groups"
                type="radio"
                name="switch"
                onchange={() => toggleRightType("group")}
                checked={assignRightType() == "group"}
              />
              <label class="toggle-label" for="users">
                Users
              </label>
              <label class="toggle-label" for="groups">
                Teams
              </label>
              <span class="highlighter bg-light"></span>
            </div>
          </div>
        </Show>
        <Show when={assignRightType() == "user"}>
          <div class="p-10 w-100">
            <Search
              onkeyup={(search: any) => {
                updateUsersList(search, true);
              }}
              placeholder="Search User"
            ></Search>
          </div>
          <ul class="share-form-users-div form-builder__panel--list w-100">
            <For each={usersList}>
              {(item: any, index: any) => (
                <>
                  <Show
                    when={index() == 0 && advanceOptions() && selectAllOption()}
                  >
                    <div class="flex app-flex gap align-center">
                      <Checkbox
                        label=""
                        name="select_all"
                        value={(allUsersSelected() ? true : false) as boolean}
                        setValue={(val: boolean) => multipleUserRight("ALL")}
                      />
                      <label class="w-100" for="select_all_id">
                        <li class="w-100">Select All</li>
                      </label>
                    </div>
                    <div class="underline"></div>
                  </Show>
                  <div
                    class={`flex app-flex gap align-center ${
                      props?.isForm &&
                      multipleUsersArr().some(
                        (user: any) =>
                          user.user_id === item.id && user.type === "team"
                      )
                        ? "pointer-none bg-gray"
                        : ""
                    }`}
                  >
                    <Show when={item.id != props.user_id && advanceOptions()}>
                      <Checkbox
                        label=""
                        name={item.id}
                        value={
                          (props?.isForm
                            ? multipleUsers().includes(item.id) &&
                              multipleUsersArr()
                                .map((user: any) => user.user_id)
                                .includes(item.id)
                            : multipleUsers().includes(item.id)
                            ? true
                            : false) as boolean
                        }
                        setValue={(val: boolean) => multipleUserRight(item.id)}
                      />
                    </Show>
                    <label class="w-100" for={`${item.id}_id`}>
                      <li>
                        <div class="flex space-between align-center">
                          <span>{item.name}</span>
                          <Show
                            when={
                              props?.isForm &&
                              multipleUsersArr().some(
                                (user: any) =>
                                  user.user_id === item.id &&
                                  user.type === "team"
                              )
                            }
                          >
                            <span class="color">
                              {
                                multipleUsersArr().find(
                                  (user: any) => user.user_id === item.id
                                )?.name
                              }
                            </span>
                          </Show>
                        </div>
                      </li>
                    </label>
                  </div>
                </>
              )}
            </For>
          </ul>
        </Show>
        <Show when={assignRightType() == "group"}>
          <div class="p-10 w-100">
            <Search
              onkeyup={(search: any) => {
                updateGroupsList(search);
              }}
              placeholder="Search Team"
            ></Search>
          </div>
          <ul class="share-form-users-div form-builder__panel--list w-100">
            <For each={groupsList()}>
              {(item: any) => (
                <>
                  <div class="flex app-flex gap align-center">
                    <Checkbox
                      label=""
                      name={item.value}
                      value={
                        multipleGroups()?.includes(item.value) ? true : false
                      }
                      setValue={() => {
                        const tempArr: any = [...multipleGroups()];
                        const index: any = tempArr.findIndex(
                          (val: any) => item.value == val
                        );
                        if (index == -1) {
                          tempArr.push(item.value);
                        } else {
                          tempArr.splice(index, 1);
                        }
                        setMultipleGroups(tempArr);
                      }}
                    />
                    <label class="w-100" for={`${item.value}_id`}>
                      <li>{item.label}</li>
                    </label>
                  </div>
                </>
              )}
            </For>
          </ul>
        </Show>
        <div class="flex flex-column app-flex space-between p-10 w-100">
          <Show when={advanceOptions()}>
            <button class="btn primary w-40" onclick={() => shareForm()}>
              {props?.isForm ? "Share Form" : "Share Folder"}
            </button>
          </Show>
          <Show
            when={
              props?.isForm &&
              multipleUsersArr().some((user: any) => user.type === "team")
            }
          >
            <small class="lbl alert-text">
              <sup>*</sup>Forms already shared with teams can't be reshared.
            </small>
          </Show>
        </div>
      </div>
    </Popup>
  );
};

export default ShareForm;
