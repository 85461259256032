import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { useNavigate, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import CancelSubscriptionPopup, {
  setIsCanceling,
} from "~/components/popup/CancelSubscriptionPopup";
import messages from "~/helpers/message";
import { popupQueue, removePopup, setPopup } from "~/store/global.store";
import { setPageTitle } from "~/root";
import { SITE_TITLE } from "~/consts";
import { validateUser } from "./api/validateUser";
import Pagination, {
  currentPage,
  setCurrentPage,
} from "~/components/global/Pagination";
import { format } from "date-fns";
import ManageLicences from "~/components/popup/ManageLicencesPopup";
import SelectOne from "~/components/global/SelectOne";
import Pricing from "~/components/global/Pricing";
import {
  setTotalUserLicences,
  totalUserLicences,
  userSubscription,
} from "~/store/user-form-role";
import { notify } from "~/components/global/Notification";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import {
  ANNUAL_SUBSCRIPTION_PRICE,
  MONTHLY_SUBSCRIPTION_PRICE,
} from "~/helpers/constant";
import ManageStorage from "~/components/popup/ManageStoragePopup";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;
    const email = userDetails.data.email;

    const usersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        search_string: "",
        sort_string: "",
        user_skip: 0,
        user_take: 50,
      },
    };
    let users = await fetchApi(usersListApiParams);
    if (users.statusCode == 401 || users.statusCode == 403) {
      throw redirect("/logout");
    }
    let usersList: any = users.data.usersList.users;
    let assignedLicences: any = users.data.assignedLicences;

    const subscriptionDetailsApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/details`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        list: true,
      },
    };
    let subscriptionDetails = await fetchApi(subscriptionDetailsApiParams);
    if (subscriptionDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    let totalSubscriptionQuantity: number | any =
      subscriptionDetails.data?.purchasedLicences;
    const totalPriceObject: any = {
      "Team Old": totalSubscriptionQuantity * MONTHLY_SUBSCRIPTION_PRICE,
      "Team Annual Old": totalSubscriptionQuantity * ANNUAL_SUBSCRIPTION_PRICE,
      Team: totalSubscriptionQuantity * MONTHLY_SUBSCRIPTION_PRICE,
      "Team Annual": totalSubscriptionQuantity * ANNUAL_SUBSCRIPTION_PRICE,
    };
    const totalPrice: number =
      totalPriceObject[subscriptionDetails.data.plan_name];
    subscriptionDetails = subscriptionDetails.data;
    const subscriptionOrders = subscriptionDetails.subscriptionOrders;
    const paddleInfo: any = {
      token: process.env.PADDLE_TOKEN,
    };

    // const planApiParams = {
    //   method: "GET" as string,
    //   url: `${import.meta.env.VITE_API_URL}/subscription/pricing`,
    //   jwt_token: jwt_token as string,
    // };
    // let plans = await fetchApi(planApiParams);
    // if (plans?.statusCode == 401) {
    //   throw redirect("/logout");
    // }
    // plans = plans.data;

    // let upgradePlanList: any = plans;
    if (
      subscriptionDetails.plan_name == "Team" ||
      subscriptionDetails.plan_name == "Team Annual"
    ) {
      let plan: string = "";
      if (subscriptionDetails.plan_name == "Team") {
        plan = "Team Annual";
      } else {
        plan = "Team";
      }
    }
    let price: number =
      subscriptionOrders.data[0].items[0].price.unit_price.amount / 100;
    const lastPayment: any = (
      subscriptionOrders.data[0].items?.reduce(
        (sum: any, item: any) => sum + (item?.quantity || 0),
        0
      ) * price
    ).toFixed(2);
    const lastPaymentDate: any = subscriptionOrders.data[0].created_at;
    const totalPayment: any = subscriptionDetails.pricing
      .reduce(
        (sum: any, item: any) =>
          sum + (item.quantity * (item.price.unit_price.amount / 100) || 0),
        0
      )
      .toFixed(2);
    const subscriptionDate: any = subscriptionDetails.created_at;
    const nextPaymentDate: any = subscriptionDetails.pricing[0].next_billed_at;

    return {
      email,
      user_id,
      jwt_token,
      subscriptionDetails,
      totalSubscriptionQuantity,
      totalPrice,
      paddleInfo,
      usersList,
      assignedLicences,
      // upgradePlanList,
      subscriptionOrders,
      lastPayment,
      lastPaymentDate,
      totalPayment,
      subscriptionDate,
      nextPaymentDate,
    };
  });
}

const billingPayments: Component = () => {
  setPageTitle(`${SITE_TITLE} - Licensing`);
  const data = useRouteData<typeof routeData>();

  const navigate: any = useNavigate();

  const [totalLicences, setTotalLicences] = createSignal(
    data()?.totalSubscriptionQuantity as any
  );
  const [remainingLicences, setRemainingLicences] = createSignal(
    (data()?.totalSubscriptionQuantity - data()?.assignedLicences) as any
  );
  const [orderMetaData, setOrderMetaData] = createSignal([] as any);
  const [ordersList, setOrdersList] = createSignal([] as any);
  const [manageLicencetype, setManageLicencetype] = createSignal("" as string);
  const [selectedOption, setSelectedOption] = createSignal("card" as string);
  const [totalPages, setTotalPages] = createSignal(1 as number);

  const selectOptions: any = [
    {
      display: "User Licences",
      name: "licence",
    },
    {
      display: "Billing Method",
      name: "card",
    },
  ];

  createEffect(() => {
    if (data()?.subscriptionOrders) {
      setCurrentPage(1);
      setOrderMetaData([data()?.subscriptionOrders.meta]);
      setOrdersList(
        data()?.subscriptionOrders.data?.length > 1
          ? data()?.subscriptionOrders.data.filter(
              (item: any) => item.invoice_id
            )
          : data()?.subscriptionOrders.data
      );
      setTotalPages(
        Math.ceil(
          data()?.subscriptionOrders.meta.pagination.estimated_total / 10
        )
      );
    }
  });

  async function handlePageChange(page: any) {
    if (currentPage() != page) {
      setCurrentPage(page);
      const subscriptionDetailsApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/subscription/details`,
        jwt_token: data()?.jwt_token as string,
        body: {
          user_id: data()?.user_id,
          list: true,
          nextPageUrl:
            page > 1 ? orderMetaData()[page - 2]?.pagination.next : null,
        },
      };
      const subscriptionDetails = await fetchApi(subscriptionDetailsApiParams);
      if (subscriptionDetails?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (subscriptionDetails?.statusCode == 403) {
        location.reload();
      }
      if (subscriptionDetails && subscriptionDetails?.status) {
        if (page > 1) {
          setOrdersList(
            subscriptionDetails.data.data.length > 1
              ? subscriptionDetails.data.data.filter(
                  (item: any) => item.invoice_id
                )
              : subscriptionDetails.data.data
          );
          setOrderMetaData([...orderMetaData(), subscriptionDetails.data.meta]);
        } else {
          setOrdersList(
            subscriptionDetails.data.subscriptionOrders.data.length > 1
              ? subscriptionDetails.data.subscriptionOrders.data.filter(
                  (item: any) => item.invoice_id
                )
              : subscriptionDetails.data.subscriptionOrders.data
          );
          setOrderMetaData([subscriptionDetails.data.subscriptionOrders.meta]);
        }
      }
    }
  }

  async function cancelSubscription() {
    setIsCanceling(true);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/cancel`,
      jwt_token: data()?.jwt_token,
      body: {
        subscription_id: data()?.subscriptionDetails.user_subscription_id,
      },
    };
    const cancelSubscription = await fetchApi(apiParams);
    if (cancelSubscription?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (cancelSubscription?.statusCode == 403) {
      location.reload();
    }
    if (cancelSubscription?.status) {
      localStorage.setItem("subscription_cancel", "true");
      setTimeout(() => {
        setIsCanceling(false);
        location.href = "/logout";
      }, 3000);
    }
  }

  return (
    <main>
      <CancelSubscriptionPopup
        subscriptionDetails={data()?.subscriptionDetails}
        oncancel={() => setPopup("confirmSubscriptionCancel")}
      />
      <Show when={popupQueue.includes("manageLicences")}>
        <ManageLicences
          jwt_token={data()?.jwt_token}
          subscription_id={data()?.subscriptionDetails.user_subscription_id}
          is_paused={data()?.subscriptionDetails.is_paused}
          subscriptionDetails={data()?.subscriptionDetails}
          type={manageLicencetype()}
          totalSubscriptionQuantity={
            totalLicences()
              ? totalLicences()
              : data()?.totalSubscriptionQuantity
          }
          assignedLicences={data()?.assignedLicences}
          usersList={data()?.usersList}
          setValue={async (value: number) => {
            const subscriptionDetailsApiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/subscription/details`,
              jwt_token: data()?.jwt_token as string,
              body: {
                user_id: data()?.user_id,
                list: true,
              },
            };
            const subscriptionDetails = await fetchApi(
              subscriptionDetailsApiParams
            );
            if (subscriptionDetails?.statusCode == 401) {
              window.location.href = "/logout";
            } else if (subscriptionDetails?.statusCode == 403) {
              location.reload();
            }
            setCurrentPage(1);
            setOrdersList(
              subscriptionDetails.data.subscriptionOrders.data.length > 1
                ? subscriptionDetails.data.subscriptionOrders.data.filter(
                    (item: any) => item.invoice_id
                  )
                : subscriptionDetails.data.subscriptionOrders.data
            );
            setTotalLicences(
              subscriptionDetails.data.totalSubscriptionQuantity
            );
            setTotalUserLicences(totalUserLicences() + value);
            setOrderMetaData([
              subscriptionDetails.data.subscriptionOrders.meta,
            ]);
            setRemainingLicences(
              Number(totalLicences()) - Number(data()?.assignedLicences)
            );
            setTotalPages(
              Math.ceil(
                subscriptionDetails.data.subscriptionOrders.meta.pagination
                  .estimated_total / 10
              )
            );
          }}
          removeUsers={async (userEmails: string) => {
            if (userEmails != "") {
              const apiParams = {
                method: "POST" as string,
                url: `${import.meta.env.VITE_API_URL}/user/remove`,
                jwt_token: data()?.jwt_token,
                body: { emails: userEmails },
              };
              const removeUsers = await fetchApi(apiParams);
              if (removeUsers?.statusCode == 401) {
                window.location.href = "/logout";
              } else if (removeUsers?.statusCode == 403) {
                location.reload();
              }
            }
          }}
        ></ManageLicences>
      </Show>
      <Show when={popupQueue.includes("manageStorage")}>
        <ManageStorage
          jwt_token={data()?.jwt_token}
          subscription_id={data()?.subscriptionDetails.user_subscription_id}
          subscriptionDetails={data()?.subscriptionDetails}
          setValue={() => {}}
        ></ManageStorage>
      </Show>
      <ConfirmPopup
        message="<b>Your subscription will be deactivated immediately.<br/>Are you sure you want to proceed?</b>"
        name="confirmSubscriptionCancel"
        onconfirm={(status: boolean) => {
          removePopup("confirmSubscriptionCancel");
          if (status) {
            cancelSubscription();
          }
        }}
      ></ConfirmPopup>
      <SystemLayout>
        <div>
          <div class="center">
            <h2 class="title color">Licensing</h2>
            <div class="flex space-between">
              <SelectOne
                set={selectOptions}
                active={selectedOption()}
                setActive={(val: string) => {
                  setSelectedOption(val);
                }}
                class="account-details"
              ></SelectOne>
              <Show when={selectedOption() == "card"}>
                <div class="flex gap">
                  {/* <button
                    class="btn primary"
                    onclick={() => setPopup("manageStorage")}
                  >
                    <Icons name="storage" width={20} stroke="#fff" />
                    &nbsp; Manage Storage
                  </button> */}
                  <button
                    class="btn primary"
                    onclick={() => navigate("/card-update")}
                  >
                    <Icons name="card" width={20} stroke="#fff" />
                    &nbsp; Update Card
                  </button>
                  <button
                    type="button"
                    onclick={() => setPopup("cancel_subscription")}
                    class="btn danger mr-25"
                  >
                    <Icons name="x" width={20} stroke="#fff" />
                    &nbsp; Cancel Plan
                  </button>
                </div>
              </Show>
            </div>
            <br />
            <Show when={selectedOption() == "card"}>
              {/* <table class="table w-100">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Email</th>
                    <th>State</th>
                    <th>Card Brand</th>
                    <th>Card Last Four</th>
                    <th>Card Expiry</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <strong class="color">
                        {data()?.subscriptionDetails.plan_name}
                      </strong>
                    </td>
                    <td>{data()?.email}</td>
                    <td>
                      {messages.subscription[data()?.subscriptionDetails.state]}
                    </td>
                    <td>
                      {data()
                        ?.subscriptionDetails.card_brand?.charAt(0)
                        .toUpperCase() +
                        data()?.subscriptionDetails.card_brand?.slice(1)}
                    </td>
                    <td>{data()?.subscriptionDetails.card_last_four}</td>
                    <td>{data()?.subscriptionDetails.card_expiry_date}</td>
                    <td>
                      {totalLicences()
                        ? totalLicences()
                        : data()?.totalSubscriptionQuantity}
                    </td>
                    <td>${data()?.totalPrice.toFixed(2)}</td>
                    <td>
                      <button
                        class="btn primary"
                        onclick={() => navigate("/card-update")}
                      >
                        <Icons name="card" width={20} stroke="#fff" />
                        &nbsp; Update Card
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table> */}
              <div class="flex space-between">
                <div class="w-28">
                  <div class="product-information amount">
                    <div class="amount-info flex gap-0_8 flex-column">
                      <span class="text-dark-gray fs-14">Recurring amount</span>
                      <div>
                        <span class="lbl fs-24">${data()?.totalPayment}</span>
                        <span class="fs-14"> /month (Inc. tax)</span>
                      </div>
                      <div>
                        <div class="badge sm success fs-14">
                          <div class="flex">
                            <span>
                              <Icons
                                name="circleCheck"
                                width={18}
                                height={18}
                              ></Icons>
                            </span>
                            &nbsp;<span class="mt-1">Active</span>
                          </div>
                        </div>
                        <br />
                        <div class="fs-14 mt-10">
                          on{" "}
                          {data()?.subscriptionDate
                            ? format(
                                new Date(data()?.subscriptionDate),
                                "MMM d, yyyy"
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div class="underline light"></div>
                    <div class="amount-info flex gap-0_8 flex-column">
                      <span class="text-dark-gray fs-14">Last payment</span>
                      <div>
                        <span class="lbl fs-24">
                          $
                          {data()?.lastPayment > 0
                            ? data()?.lastPayment
                            : (0).toFixed(2)}
                        </span>
                        <br />
                        <div class="fs-14 mt-5">
                          paid on{" "}
                          {data()?.lastPaymentDate
                            ? format(
                                new Date(data()?.lastPaymentDate),
                                "MMM d, yyyy"
                              )
                            : ""}
                        </div>
                      </div>
                      <div>
                        <div class="badge sm success fs-14">
                          <div class="flex">
                            <span>
                              <Icons
                                name="circleCheck"
                                width={18}
                                height={18}
                              ></Icons>
                            </span>
                            &nbsp;<span class="mt-1">Complete</span>
                          </div>
                        </div>
                      </div>
                      <div class="underline"></div>
                      <span class="text-dark-gray fs-14">Next payment</span>
                      <div>
                        <span class="lbl fs-24">${data()?.totalPayment}</span>
                        <br />
                        <div class="fs-14 mt-5">
                          billed on{" "}
                          {data()?.nextPaymentDate
                            ? format(
                                new Date(data()?.nextPaymentDate),
                                "MMM d, yyyy"
                              )
                            : ""}
                        </div>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
                <div class="w-69">
                  <div class="product-information">
                    <div class="flex space-between">
                      <strong class="lbl">Subscription details</strong>
                      <span class="badge md primary">
                        {
                          messages.subscription[
                            data()?.subscriptionDetails.state
                          ]
                        }
                      </span>
                    </div>
                    <br />
                    <div class="flex space-between">
                      <div class="flex gap-0_5 flex-column">
                        <span class="text-dark-gray">Email</span>
                        <span class="lbl">{data()?.email}</span>
                      </div>
                      <div class="flex gap-0_5 flex-column">
                        <span class="text-dark-gray">Payment method</span>
                        <span>
                          <span class="lbl">
                            {data()
                              ?.subscriptionDetails.card_brand?.charAt(0)
                              .toUpperCase() +
                              data()?.subscriptionDetails.card_brand?.slice(1)}
                          </span>
                          &nbsp; <span class="fw-200">ending in</span> &nbsp;
                          <span class="lbl">
                            {data()?.subscriptionDetails.card_last_four}
                          </span>
                          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{" "}
                          <span class="fw-200">Expires:</span>
                          &nbsp;
                          <span class="lbl">
                            {data()?.subscriptionDetails.card_expiry_date}
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div class="product-information">
                    <div class="lbl">
                      <strong>Product details</strong>
                    </div>
                    <br />
                    <table>
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th class="text-center w-74p">Qty</th>
                          <th class="text-center w-74p">Price</th>
                          <th class="text-right">Amount (Inc. tax)</th>
                        </tr>
                      </thead>
                      <tbody>
                        <For each={data()?.subscriptionDetails.pricing}>
                          {(item: any) => (
                            <tr>
                              <td class="flex gap">
                                <img src="/favicon.ico" class="thumbnail esm" />
                                <div class="flex gap flex-column">
                                  <span>{item.product.name}</span>
                                  <span class="lbl fs-14">
                                    {item.price.name}
                                  </span>
                                  <span class="fs-12 text-gray">
                                    {item.price.description}
                                  </span>
                                </div>
                              </td>
                              <td class="text-center">{item.quantity}</td>
                              <td class="text-center">
                                $
                                {(item.price.unit_price.amount / 100).toFixed(
                                  2
                                )}
                              </td>
                              <td class="text-right">
                                $
                                {(
                                  item.quantity *
                                  (item.price.unit_price.amount / 100)
                                ).toFixed(2)}
                              </td>
                            </tr>
                          )}
                        </For>
                        <tr>
                          <td class="border-none"></td>
                          <td colspan={3} class="border-none">
                            <div class="flex space-between">
                              <span class="text-right">Total (Inc. tax)</span>
                              <span class="text-right">
                                ${data()?.totalPayment}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Show>
            {/* <Show when={selectedOption() == "plan"}>
              <br />
              <div class="text-center">
                <Pricing
                  plans={data()?.upgradePlanList}
                  user_id={data()?.user_id}
                  jwt_token={data()?.jwt_token}
                  subscriptionId={
                    data()?.subscriptionDetails.user_subscription_id
                  }
                  planName={data()?.subscriptionDetails.plan_name}
                  isPricingPage={true}
                ></Pricing>
              </div>
            </Show> */}
          </div>
          <Show when={selectedOption() == "licence"}>
            <div>
              <div class="flex space-between">
                <div class="badge licences app-w-50">
                  <span>
                    <strong>
                      {totalLicences()
                        ? totalLicences()
                        : data()?.totalSubscriptionQuantity}
                    </strong>{" "}
                    Licenses Purchased
                  </span>
                  &nbsp; | &nbsp;
                  <span>
                    <strong>{data()?.assignedLicences}</strong> Users Assigned
                  </span>
                  &nbsp; | &nbsp;
                  <span>
                    <strong>
                      {remainingLicences()
                        ? remainingLicences()
                        : (totalLicences()
                            ? totalLicences()
                            : data()?.totalSubscriptionQuantity) -
                          data()?.assignedLicences}
                    </strong>{" "}
                    Users Remaining
                  </span>
                </div>
                <Show when={userSubscription() == "Team"}>
                  <div class="flex app-flex space-between">
                    <button
                      class="btn primary"
                      onclick={() => {
                        setManageLicencetype("add");
                        setPopup("manageLicences");
                      }}
                    >
                      <Icons name="plus" stroke="#fff" />
                      &nbsp;Add Licences
                    </button>
                    &nbsp;
                    <button
                      class="btn danger"
                      onclick={() => {
                        setManageLicencetype("remove");
                        setPopup("manageLicences");
                      }}
                    >
                      <Icons name="trash" stroke="#fff" />
                      &nbsp;Remove Licences
                    </button>
                  </div>
                </Show>
              </div>
              <br />
              <table class="table licences-table w-100">
                <thead>
                  <tr>
                    <th>Order Id</th>
                    <th>Quantity</th>
                    <th>Receipt</th>
                    <th>Subscription Date</th>
                  </tr>
                </thead>
                <tbody>
                  <For
                    each={
                      ordersList()
                        ? ordersList()
                        : data()?.subscriptionOrders.data
                    }
                  >
                    {(order: any) => (
                      <tr>
                        <td class="pr">
                          <span>{order.id}</span>
                        </td>
                        <td>
                          {order.items
                            .map((item: any) => Number(item.quantity))
                            .reduce((acc: any, curr: any) => acc + curr, 0)}
                        </td>
                        <td>
                          <button
                            class="btn primary sm"
                            onclick={async () => {
                              let url: any = "";
                              const apiParams = {
                                method: "POST" as string,
                                url: `${
                                  import.meta.env.VITE_API_URL
                                }/subscription/transcation/url`,
                                jwt_token: data()?.jwt_token,
                                body: {
                                  transaction_id: order.id,
                                },
                              };
                              const transcation = await fetchApi(apiParams);
                              if (transcation?.statusCode == 401) {
                                window.location.href = "/logout";
                              } else if (transcation?.statusCode == 403) {
                                location.reload();
                              }
                              if (transcation.status) {
                                url = transcation.data;
                              }
                              if (url) {
                                const a: any = document.createElement("a");
                                a.href = url;
                                a.target = "_blank";
                                a.download = `invoice-${order.subscription_order_id}`;
                                document.body.appendChild(a);
                                a?.click();
                                document.body.removeChild(a);
                              } else {
                                notify({
                                  isNotified: true,
                                  message:
                                    messages.warn.transcation_invoice_in_trial,
                                  theme: "warm",
                                  placement: "top",
                                  timeout: 2500,
                                });
                              }
                            }}
                          >
                            <Icons
                              name="document"
                              stroke="#fff"
                              width={18}
                              height={18}
                            />
                          </button>
                        </td>
                        <td>
                          {format(new Date(order.created_at), "MMMM,dd yyyy")}
                        </td>
                      </tr>
                    )}
                  </For>
                </tbody>
              </table>
              <Show when={orderMetaData()[0]?.pagination?.has_more}>
                <Pagination
                  currentPage={currentPage()}
                  totalPages={totalPages()}
                  onPageChange={async (page: any) => handlePageChange(page)}
                  itemsPerPage={10}
                  totalItems={orderMetaData()[0]?.pagination?.estimated_total}
                  itemPerPageFilter={true}
                  noPageNo={true}
                ></Pagination>
              </Show>
            </div>
          </Show>
        </div>
      </SystemLayout>
    </main>
  );
};

export default billingPayments;
