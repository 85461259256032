import { Component, createEffect, createSignal, For, Show } from "solid-js";
import Popup from "../global/Popup";
import {
  imageFile,
  preLoadData,
  recordsJson,
  setAdditionalData,
  setImageFile,
  setIsImageUploaded,
  setUpdatedRecordImages,
  updatedRecordImages,
  updateFormRecords,
} from "~/store/records.store";
import { formJson } from "~/store/form.store";
import { formImage, setFormImage } from "../fields/ImageField";
import Icons from "../icons/Icons";
import SelectOne from "../global/SelectOne";
import {
  removePopup,
  rgbToHex,
  setAlertMessage,
  setPopup,
} from "~/store/global.store";
import { fieldColors } from "~/store/field-editor.store";
import fetchApi from "~/lib/api";

interface Props {
  jwt_token: any;
}

const PAGE_RES_DEFAULT = 84;
const imageSizes: any = {
  Large: 10.5 * PAGE_RES_DEFAULT,
  Medium: 5 * PAGE_RES_DEFAULT,
  Small: 2.5 * PAGE_RES_DEFAULT,
};

const [canvasContext, setCanvasContext] = createSignal(
  {} as CanvasRenderingContext2D
);
const [selectedImageUid, setSelectedImageUid] = createSignal("" as string);
const [photoAnnotateData, setPhotoAnnotateData] = createSignal({} as any);
const [imageUpdateSize, setImageUpdateSize] = createSignal("" as string);
const [imageSize, setImageSize] = createSignal("Large" as string);
const [imageDimension, setImageDimension] = createSignal({} as any);
const [oldImageDimension, setOldImageDimension] = createSignal({} as any);
const [hasImageResizeDone, setHasImageResizeDone] = createSignal(
  false as boolean
);
const [annotationData, setAnnotationData] = createSignal({} as any);
const [image, setImage] = createSignal("" as any);
let canvas2Ref: any;
let imageRef: any;

export async function resizeImage(jwt_token: string, size?: any) {
  setHasImageResizeDone(true);
  let ratio: any, width: any, height: any;
  let imgRef: any = imageRef;
  if (imgRef) {
    width = imgRef.width;
    height = imgRef.height;
    ratio = width / height;
    setOldImageDimension({
      width,
      height,
    });
    let updateImage: boolean = false;
    if (size) {
      if (ratio > 1) {
        width = imageSizes[size];
        height = width / ratio;
      } else {
        height = imageSizes[size];
        width = height * ratio;
      }
      updateImage = true;
    } else {
      if (ratio > 1) {
        switch (true) {
          case width >= imageSizes["Medium"]:
            size = "Large";
            if (width > imageSizes["Large"]) {
              updateImage = true;
              width = imageSizes["Large"];
              height = width / ratio;
            }
            break;
          case width < imageSizes["Medium"] && width > imageSizes["Small"]:
            size = "Medium";
            break;
          default:
            size = "Small";
            break;
        }
      } else {
        switch (true) {
          case height >= imageSizes["Medium"]:
            size = "Large";
            if (height > imageSizes["Large"]) {
              updateImage = true;
              height = imageSizes["Large"];
              width = height * ratio;
            }
            break;
          case width < imageSizes["Medium"] && width > imageSizes["Small"]:
            size = "Medium";
            break;
          default:
            size = "Small";
            break;
        }
      }
    }
    if (updateImage) {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/image/resize`,
        jwt_token,
        body: {
          img_url: imageRef.src,
          width,
          height,
        },
      };
      const response = await fetchApi(apiParams);
      if (response?.statusCode == 401 || response?.statusCode == 403) {
        window.location.href = "/logout";
      }
      if (response.status) {
        setImage(response.data.base64Image);
      }
    }
    setImageSize(size);
    setImageDimension({
      width,
      height,
    });
    drawAnnotation();
  }
}

function drawAnnotation() {
  setTimeout(() => {
    const canvas: any = canvas2Ref;
    if (canvas) {
      canvas.width = imageDimension()?.width;
      canvas.height = imageDimension()?.height;
      const ctx = canvas.getContext("2d") as any;
      setCanvasContext(ctx);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      if (Object.keys(annotationData()).length > 0) {
        setAnnotationData({
          ...annotationData(),
          points: annotationData().points?.map((item: any, key: any) => {
            const [x, y] = item
              .slice(1, -1)
              .split(",")
              .map((value: any) => value.trim());
            if (x.toLowerCase() !== "nan" && y.toLowerCase() !== "nan") {
              const newX = (
                (x * Number(canvas.width)) /
                oldImageDimension()?.width
              ).toFixed(2);
              const newY = (
                (y * Number(canvas.height)) /
                oldImageDimension()?.height
              ).toFixed(1);
              return `{${newX}, ${newY}}`;
            }
            return item;
          }),
        });
        const signatureJSON = {
          points: annotationData().points,
        };
        let signatureArr: any = [];
        let newSet: any = [];
        signatureJSON.points.forEach((p: any, i: number) => {
          let x = p.split("{")[1].split(",")[0];
          let y = p.split("{")[1].split(",")[1].split("}")[0];
          let c = annotationData().colors[i];
          if (x != "nan") {
            let xyc = { x, y, c };
            newSet.push(xyc);
          }
          if (x == "nan") {
            if (newSet.length) signatureArr.push(newSet);
            newSet = [];
          }
          if (i == signatureJSON.points.length - 1) signatureArr.push(newSet);
        });

        signatureArr.forEach((field: { x: any; y: any; c: any }[]) => {
          field.map(
            (
              val: {
                x: any;
                y: any;
                c: any;
              },
              i
            ) => {
              if (i == 0) {
                ctx.beginPath();
              }
              ctx.strokeStyle = photoAnnotateData().inkFieldSync?.inkColor
                ? photoAnnotateData().inkFieldSync.inkColor
                : val.c;
              ctx.lineTo(val.x, val.y);
              ctx.stroke();
              if (i == field.length - 1) {
                ctx.closePath();
                ctx.fillStyle = photoAnnotateData().inkFieldSync?.inkColor
                  ? photoAnnotateData().inkFieldSync.inkColor
                  : val.c;
              }
            }
          );
        });
      } else {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
      }
    }
  }, 0);
}

const PhotoAnnotate: Component<Props> = (props) => {
  const [offsetX, setOffsetX] = createSignal(0 as number);
  const [offsetY, setOffsetY] = createSignal(0 as number);
  const [drawing, setDrawing] = createSignal(false as boolean);
  const [activeTool, setActiveTool] = createSignal("pencil" as string);
  const [signaturePoints, setSignaturePoints] = createSignal([] as any);
  const [pencilColor, setPencilColor] = createSignal("#000000" as string);
  const [showColorsOption, setShowColorsOption] = createSignal(
    false as boolean
  );

  createEffect(() => {
    if (photoAnnotateData().dataFieldSync) {
      if (Object.keys(recordsJson.currentRecord).length > 0) {
        setImage(
          preLoadData()[photoAnnotateData().dataFieldSync.uid] != undefined &&
            preLoadData()[photoAnnotateData().dataFieldSync.uid] != "" &&
            preLoadData()[photoAnnotateData().dataFieldSync.uid].split(
              "https://"
            ).length > 1
            ? preLoadData()[photoAnnotateData().dataFieldSync.uid]
            : `data:image/png;base64,` +
                preLoadData()[photoAnnotateData().dataFieldSync.uid]
        );
        const data: any = recordsJson.currentRecord?.pages[
          formJson.currentPage
        ].fields.find(
          (field: any) =>
            field.field_id == photoAnnotateData().dataFieldSync.uid
        )?.additional_data?.annotation;
        setAnnotationData(data ? JSON.parse(data) : {});
        setTimeout(() => {
          resizeImage(props.jwt_token);
        }, 100);
      }
    }
  });

  function drawSign(newX: number, newY: number) {
    const canvas = canvas2Ref;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = pencilColor();
    ctx.fillRect(newX, newY, 1, 1);
    const strokeStyle = pencilColor();
    canvasContext().beginPath();
    canvasContext().strokeStyle = strokeStyle;
    if (activeTool() === "eraser") {
      canvasContext().lineWidth = 30;
      canvasContext().globalCompositeOperation = "destination-out";
      canvasContext().lineCap = "round";
      canvasContext().lineJoin = "round";
      canvasContext().strokeStyle = "rgba(0,0,0,1)";
    } else {
      canvasContext().lineWidth = 2;
      canvasContext().globalCompositeOperation = "source-over";
      canvasContext().lineCap = "round";
      canvasContext().lineJoin = "round";
      canvasContext().strokeStyle = strokeStyle;
    }
    canvasContext().moveTo(offsetX(), offsetY());
    canvasContext().lineTo(newX, newY);
    canvasContext().stroke();
    setOffsetX(newX);
    setOffsetY(newY);
  }

  function updateSignatureRecord() {
    let points: any, colors: any;
    if (typeof signaturePoints()[0] == "string") {
      points = signaturePoints();
    } else {
      if (signaturePoints().length > 0) {
        [points, colors] = generatePointsFromCanvas(
          signaturePoints()
            .map((currentArray: any) => {
              let subArrays = [];
              let currentSubArray = [];
              currentArray.sort((a: any, b: any) => a.x - b.x || a.y - b.y);
              for (let i = 0; i < currentArray.length; i++) {
                let point = currentArray[i];
                let prevPoint = currentArray[i - 1];
                if (
                  !prevPoint ||
                  Math.abs(point.x - prevPoint.x) > 8 ||
                  Math.abs(point.y - prevPoint.y) > 8
                ) {
                  if (currentSubArray.length > 0) {
                    subArrays.push(currentSubArray);
                  }
                  currentSubArray = [point];
                } else {
                  currentSubArray.push(point);
                }
              }
              if (currentSubArray.length > 0) {
                subArrays.push(currentSubArray);
              }
              return subArrays;
            })
            .flat()
        );
      } else {
        points = [];
        colors = [];
      }
    }
    let pointsArr: any = [];
    let colorsArr: any = [];
    let nextValidColor: any = null;

    points.forEach((item: any) => {
      let parts = item.slice(1, -1).split(",");
      parts = parts.map((part: any) => part.trim());
      let points = parts.slice(0, 2);
      let color = parts[2];

      pointsArr.push(`{${points.join(", ")}}`);
      colorsArr.push(color);
    });
    for (let i = colorsArr.length - 1; i >= 0; i--) {
      if (colorsArr[i] === "nan") {
        if (nextValidColor) {
          colorsArr[i] = nextValidColor;
        }
      } else {
        nextValidColor = colorsArr[i];
      }
    }
    const result = {
      nPoints: pointsArr.length,
      points: pointsArr,
      colors: colorsArr,
    };
    setAnnotationData(result);
  }

  function generatePointsFromCanvas(arrays: any) {
    let result = [];
    let colors: any = [];
    for (let i = 0; i < arrays.length; i++) {
      let currentArray = arrays[i];
      let uniqueArray = [];
      for (let j = 0; j < currentArray.length; j++) {
        let currentElement = currentArray[j];
        let isUnique = true;
        for (let k = 0; k < i; k++) {
          if (
            arrays[k].some(
              (item: any) =>
                item.x === currentElement.x && item.y === currentElement.y
            )
          ) {
            isUnique = false;
            break;
          }
        }
        if (isUnique) {
          uniqueArray.push(currentElement);
        }
      }
      uniqueArray = uniqueArray.map(
        (point: any) => `{${point.x}, ${point.y}, ${point.c}}`
      );
      uniqueArray.unshift("{nan,nan,nan}");
      result.push(uniqueArray);
    }
    return [result.flat(), colors];
  }

  return (
    <Popup heading="Photo Annotate" classes="large" name="PhotoAnnotate">
      <div class="annotate-photo-navbar">
        <span
          class="a-link cur-p"
          onclick={() => {
            if (
              image()?.split("https://").length <= 1 &&
              hasImageResizeDone()
            ) {
              var arr = image().split(","),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
              while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
              }
              var file = new File([u8arr], "test", { type: mime });
              setImageFile([...imageFile(), file]);
              setUpdatedRecordImages([
                ...updatedRecordImages(),
                selectedImageUid().toString(),
              ]);
            }
            setAdditionalData({ annotation: JSON.stringify(annotationData()) });
            updateFormRecords(
              photoAnnotateData().dataFieldSync.uid,
              image()?.split("https://").length <= 1
                ? image().split("base64,")[1]
                : image(),
              "",
              true,
              { annotation: JSON.stringify(annotationData()) }
            );
            removePopup("PhotoAnnotate");
          }}
        >
          Done
        </span>
        <span
          class="a-link cur-p"
          onclick={() => {
            const link = document.createElement("a");
            link.href = image();
            link.download = `IMG_${imageSize()}`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }}
        >
          Save to Library
        </span>
        <span
          class="a-link cur-p"
          onclick={() => {
            setUpdatedRecordImages([]);
            setImageFile([]);
            updateFormRecords(
              photoAnnotateData().dataFieldSync.uid,
              "",
              "",
              true,
              {}
            );
            removePopup("PhotoAnnotate");
          }}
        >
          Clear
        </span>
        <input
          type="file"
          class="d-n"
          accept="image/png, image/jpg, image/jpeg, image/webp"
          id="diff_image"
          onchange={(e: any) => {
            setIsImageUploaded(true);
            setImageSize("Large");
            let img = imageRef;
            img.style.width = "auto";
            img.style.height = "auto";
            const selectedFile = e.target.files[0];
            if (selectedFile) {
              const reader = new FileReader();
              reader.onload = (e: any) => {
                const base64Data = e.target.result.split(",")[1];
                setImage(base64Data);
                const tempArr: any = [...formImage()];
                if (
                  tempArr.findIndex(
                    (obj: any) =>
                      obj.uid == photoAnnotateData().dataFieldSync.uid
                  ) != -1
                ) {
                  const index: number = tempArr.findIndex(
                    (obj: any) =>
                      obj.uid == photoAnnotateData().dataFieldSync.uid
                  );
                  tempArr.splice(index, 1);
                }
                const obj: any = {
                  uid: photoAnnotateData().dataFieldSync.uid,
                  imageBase64: base64Data,
                };
                tempArr.push(obj);
                setFormImage(tempArr);
                if (
                  !updatedRecordImages().includes(
                    photoAnnotateData().dataFieldSync.uid.toString()
                  )
                ) {
                  setImageFile([...imageFile(), selectedFile]);
                  setUpdatedRecordImages([
                    ...updatedRecordImages(),
                    photoAnnotateData().dataFieldSync.uid.toString(),
                  ]);
                } else {
                  const index = updatedRecordImages().indexOf(
                    photoAnnotateData().dataFieldSync.uid.toString()
                  );
                  const tempArr: any = [...imageFile()];
                  if (index !== -1) {
                    tempArr[index] = selectedFile;
                  }
                  setImageFile(tempArr);
                }
                updateFormRecords(
                  photoAnnotateData().dataFieldSync.uid,
                  base64Data,
                  selectedFile,
                  true,
                  {}
                );
              };
              reader.readAsDataURL(selectedFile);
            }
          }}
        />
        <label class="a-link cur-p" for="diff_image">
          Different Image
        </label>
      </div>
      <div
        class="pr text-center"
        style={{
          height: "80%",
          width: "80%",
          margin: "10px auto 70px auto",
        }}
      >
        <img
          ref={imageRef}
          src={image()}
          style={{
            "object-fit": "contain",
          }}
          class="pointer-none"
          loading="lazy"
        />
        <canvas
          ref={canvas2Ref}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            cursor: activeTool() === "eraser" ? "cell" : "crosshair",
          }}
          onmousedown={(e: any) => {
            setOffsetX(e.offsetX);
            setOffsetY(e.offsetY);
            setDrawing(true);
          }}
          onmousemove={(e: any) => {
            if (drawing()) {
              drawSign(e.offsetX, e.offsetY);
            }
          }}
          onmouseup={() => {
            setDrawing(false);
            const canvas = canvas2Ref;
            const ctx = canvas.getContext("2d");
            const imageData = ctx.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            ).data;
            const points: any = [];
            // Iterate through pixel data
            for (let y = 0; y < canvas.height; y++) {
              for (let x = 0; x < canvas.width; x++) {
                const index = (y * canvas.width + x) * 4;
                // Check if the pixel is not transparent
                if (imageData[index + 3] !== 0) {
                  const r = imageData[index];
                  const g = imageData[index + 1];
                  const b = imageData[index + 2];
                  const color = `rgb(${r}, ${g}, ${b})`;
                  points.push({ x, y, c: rgbToHex(color) });
                }
              }
            }
            if (
              recordsJson.currentRecord?.pages[
                formJson.currentPage
              ].fields.find(
                (field: any) =>
                  field.field_id == photoAnnotateData().dataFieldSync.uid
              )?.additional_data?.annotation
            ) {
              setSignaturePoints([points]);
            } else {
              if (points.length > 0) {
                setSignaturePoints([points]);
              } else {
                setSignaturePoints([]);
              }
            }
            updateSignatureRecord();
          }}
          onmouseleave={() => setDrawing(false)}
        ></canvas>
      </div>
      <div class="annotate-photo-footer">
        <div class="flex gap align-center w-33">
          <label class="w-125p">Image Size:</label>
          <SelectOne
            class="full-width"
            set={[
              { display: "Small", name: "Small" },
              { display: "Medium", name: "Medium" },
              { display: "Large", name: "Large" },
            ].filter((option) => {
              if (imageSize() === "Small") {
                return option.name === "Small";
              } else if (imageSize() === "Medium") {
                return option.name === "Small" || option.name === "Medium";
              }
              return true;
            })}
            active={imageSize()}
            setActive={(val: string) => {
              setImageUpdateSize(val);
              setAlertMessage(
                "<b>The image will be scaled to a lower resolution.<br>This cannot be undone.</b>"
              );
              setPopup("confirmUpdateImageSize");
            }}
            isSingle={imageSize() === "Small" ? true : false}
          ></SelectOne>
        </div>
        <div class="flex gap">
          <span onclick={() => setActiveTool("eraser")}>
            <Icons
              name="eraser"
              stroke={activeTool() == "eraser" ? "#86BDFB" : ""}
              width={26}
              height={26}
            ></Icons>
          </span>
          <span class="pr" onclick={() => setActiveTool("pencil")}>
            <span
              onclick={() =>
                setShowColorsOption(showColorsOption() ? false : true)
              }
            >
              <Icons
                name="pencil"
                stroke={activeTool() == "pencil" ? "#86BDFB" : ""}
                width={26}
                height={26}
              ></Icons>
            </span>
            <Show when={showColorsOption()}>
              <div class="pa t--210 r--35 w-74p">
                <div class="flex app-flex gap-0_5 flex-wrap">
                  <For each={fieldColors}>
                    {(item: any) => (
                      <div
                        class={`field-color photo ${
                          item.isActiveWhite && pencilColor() == item.color
                            ? "active-white"
                            : !item.isActiveWhite && pencilColor() == item.color
                            ? "active-black"
                            : ""
                        }`}
                        style={{
                          background: `${item.color}`,
                        }}
                        onclick={() => setPencilColor(item.color)}
                      ></div>
                    )}
                  </For>
                </div>
              </div>
            </Show>
          </span>
        </div>
      </div>
    </Popup>
  );
};

export { setPhotoAnnotateData, setSelectedImageUid, imageUpdateSize };
export default PhotoAnnotate;
