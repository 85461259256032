import { Component, createSignal, onMount } from "solid-js";
import { RouteDataArgs, useParams, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import UserForm from "../_form";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { setSelectedDropDown } from "~/store/global.store";
import { validateUser } from "~/routes/api/validateUser";

export function routeData({ params }: RouteDataArgs) {
  validateUser();
  return createServerData$(
    async ([, userId], { request }) => {
      const userDetails = await getUser(request, true);
      if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
        throw redirect("/logout");
      }
      const user_id: any = userDetails.data.id;
      const jwt_token: any = await getAuthToken(request);
      const sub_user_id: any = userId;
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/user/profile`,
        jwt_token: jwt_token as string,
        body: {
          user_id: sub_user_id,
        },
      };
      let profileDetails = await fetchApi(apiParams);
      if (
        profileDetails?.statusCode == 401 ||
        profileDetails?.statusCode == 403
      ) {
        throw redirect("/logout");
      }
      profileDetails = profileDetails.data;
      return { user_id, jwt_token, profileDetails };
    },
    { key: () => ["user_profile", params.userId] }
  );
}

const userEdit: Component = () => {
  setPageTitle(`${SITE_TITLE} - Edit User`);
  const data = useRouteData<typeof routeData>();
  const { userId } = useParams<{ userId: string }>();

  return (
    <main>
      <SystemLayout>
        <div
          class="center h-100"
          onclick={(e: any) => {
            if (e.target.id != "user_role_dropdown") {
              setSelectedDropDown("");
            }
          }}
        >
          <div class="flex space-between">
            <h3 class="title color">Update User</h3>
          </div>
          <div class="mw-800">
            <UserForm
              user_id={userId}
              admin_user_id={data()?.user_id}
              data={data()?.profileDetails}
              type="edit"
              api_url={`${import.meta.env.VITE_API_URL}/user/update/profile`}
            />
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default userEdit;
