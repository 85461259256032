import { Component, Show } from "solid-js";
import Popup from "../global/Popup";
import { popupQueue } from "~/store/global.store";

interface Props {
  message: string;
  onconfirm: any;
  alert?: boolean;
  warning?: boolean;
  name: string;
}

const ConfirmPopup: Component<Props> = (props) => {
  return (
    <Popup
      heading={
        props?.alert
          ? popupQueue.includes("passwordRegenerate") ||
            popupQueue.includes("userAdd")
            ? "Success"
            : "Alert"
          : props?.warning
          ? "Warning"
          : "Confirm"
      }
      classes={`small ${props.name == "importAlert" ? "" : "top"}`}
      overlap={true}
      name={props.name}
    >
      <div>
        <div innerHTML={props.message}></div>
      </div>
      <br />
      <Show when={props.name == "importAlert"}>
        <div class="underline"></div>
      </Show>
      <div
        class={`flex app-flex gap-0_5 ${
          props.name == "importAlert" ? "justify-center" : ""
        }`}
      >
        <button class="btn primary" onclick={() => props.onconfirm(true)}>
          {props.name == "confirmPermission" ||
          props.name == "confirmRecordChanges"
            ? "Yes"
            : "OK"}
        </button>
        <Show when={!props?.alert}>
          <button class="btn danger" onclick={() => props.onconfirm(false)}>
            {props.name == "confirmPermission"
              ? "No"
              : // : props.name == "confirmRecordChanges"
                // ? "Clear"
                "Cancel"}
          </button>
        </Show>
      </div>
    </Popup>
  );
};

export default ConfirmPopup;
