import {
  Component,
  JSX,
  Show,
  children,
  createSignal,
  onMount,
} from "solid-js";
import Notification from "../global/Notification";
import Popover from "../global/Popover";
import Icons from "../icons/Icons";
import { useSearchParams } from "solid-start";
import { createServerAction$ } from "solid-start/server";
import { logout } from "~/db/session";
import currencyFormatter from "currency-formatter";
import { jwtToken, userId } from "~/store/user-form-role";

interface Props {
  children: JSX.Element;
  size?: string;
  hasNavbar?: boolean;
}

const [checkoutData, setCheckoutData] = createSignal({} as any);
const [hasTrial, setHasTrial] = createSignal(false as boolean);

const AuthLayout: Component<Props> = (props) => {
  const slot = children(() => props.children);
  const layoutSize = props.size ? ` ${props.size}` : "";

  const [searchParams, setSearchParams]: any = useSearchParams();

  const [, { Form }] = createServerAction$(
    async (form: FormData, { request }) => {
      const userId: any = form.get("user_id");
      const jwtToken: any = form.get("jwt_token");
      return await logout(request, userId, jwtToken);
    }
  );

  const formatCurrency: any = (amount: any, currencyCode: any) => {
    try {
      return currencyFormatter.format(amount, {
        code: currencyCode,
      });
    } catch (error: any) {
      return null;
    }
  };

  return (
    <>
      <Show when={props?.hasNavbar}>
        <div class="payment-checkout">
          <Form>
            <input type="hidden" name="user_id" value={userId()} />
            <input type="hidden" name="jwt_token" value={jwtToken()} />
            <button class="btn a-link" name="logout" type="submit">
              Logout
            </button>
          </Form>
        </div>
      </Show>
      <div class="layout">
        <div class="layout-auth">
          <div class={`auth-box${layoutSize}`}>
            <div
              class={`auth-box__left ${
                props?.hasNavbar && props?.size == "mih-650" ? "pricing" : ""
              }`}
            >
              <Show when={props?.hasNavbar && props?.size == "mih-650"}>
                <div class="text-white">
                  <div>
                    <strong>Order Summary</strong>
                  </div>
                  <br />
                  <br />
                  <h1 class="m-0 text-secondary">
                    {hasTrial()
                      ? import.meta.env.VITE_PADDLE_TRIAL_DAYS
                        ? `${import.meta.env.VITE_PADDLE_TRIAL_DAYS} days free`
                        : ""
                      : "Resume Subscription"}
                  </h1>
                  <div class="flex align-center mt-4 pr">
                    then{" "}
                    {formatCurrency(
                      Number(checkoutData()?.recurring_totals?.total),
                      checkoutData()?.currency_code
                    )}{" "}
                    {checkoutData()?.items
                      ? checkoutData()?.items[0]?.billing_cycle?.interval ==
                        "month"
                        ? "monthly"
                        : "yearly"
                      : searchParams.plan == "Team"
                      ? "monthly"
                      : "yearly"}
                    , inc. Tax&nbsp;
                    <Popover
                      left={0}
                      top={125}
                      content="This is the estimated recurring total as of today. This price may change if your tax rates or applied discounts change in the future."
                    >
                      <Icons name="info" stroke="#fff"></Icons>
                    </Popover>
                  </div>
                  <br />
                  <br />
                  <div class="flex gap">
                    <img src="/favicon.ico" class="thumbnail md" />
                    <div class="w-100">
                      <div class="flex space-between">
                        <div class="flex flex-column">
                          <div>
                            {checkoutData()?.items
                              ? checkoutData()?.items[0]?.product?.name
                              : "FormConnect ProMax"}
                          </div>
                          <div class="fs-12 mt-1">
                            {checkoutData()?.items
                              ? checkoutData()?.items[0]?.price_name
                              : searchParams.plan == "Team"
                              ? "Monthly"
                              : "Annual"}
                          </div>
                        </div>
                      </div>
                      <br />
                      <div class="flex space-between">
                        <span>Quantity</span>
                        <span>{searchParams.quantity}</span>
                      </div>
                      <div class="underline record light"></div>
                      <div class="flex space-between">
                        <span>Due today</span>
                        <span>
                          {formatCurrency(
                            hasTrial()
                              ? 0.0
                              : Number(checkoutData()?.recurring_totals?.total),
                            checkoutData()?.currency_code
                          )}
                        </span>
                      </div>
                      <Show when={hasTrial()}>
                        <div class="flex space-between mt-4">
                          <span>After trial ends</span>
                          <span>
                            {formatCurrency(
                              Number(checkoutData()?.recurring_totals?.total),
                              checkoutData()?.currency_code
                            )}
                            /per{" "}
                            {checkoutData()?.items
                              ? checkoutData()?.items[0]?.billing_cycle
                                  ?.interval
                              : searchParams.plan == "Team"
                              ? "month"
                              : "year"}
                          </span>
                        </div>
                      </Show>
                    </div>
                  </div>
                </div>
              </Show>
              <div
                class={`auth-box__img-container ${
                  props?.hasNavbar && props?.size == "mih-650" ? "pricing" : ""
                }`}
              >
                <img src="/logo.png" alt="Logo" class="auth-box__img" />
              </div>
            </div>
            <div class="auth-box__right">{slot}</div>
          </div>
        </div>
      </div>
      <Notification />
    </>
  );
};

export { setCheckoutData, setHasTrial };
export default AuthLayout;
