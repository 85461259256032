import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import Icons from "../icons/Icons";
import {
  activeField,
  setActiveField,
  setIsFormSettingsUpdated,
} from "~/store/global.store";
import { formJson } from "~/store/form.store";

const [indexTabs, setIndexTabs] = createSignal([] as any);
const [indexTabsUpdated, setIndexTabsUpdated] = createSignal(false as boolean);

const IndexTabsSettings: Component = () => {
  const [formPages, setFormPages] = createSignal([] as any);
  const [itemRemoved, setItemRemoved] = createSignal(false as any);

  onMount(() => {
    setIndexTabs([]);
    const tempArr: any = [];
    formJson.pages.forEach((page: any, key: any) => {
      const tempObject: any = {
        label: "Page " + (key + 1),
        value: key + 1,
      };
      tempArr.push(tempObject);
    });
    setFormPages(tempArr);
    if (formJson.root.formStructure.indexTabs.length > 0) {
      formJson.root.formStructure.indexTabs.forEach((item: any) => {
        addSelectionData(item.tabTitle, item.page);
      });
    }
  });

  function addSelectionData(name: string, page: number) {
    const updatedItems: any = [...indexTabs()];
    const _id = crypto.randomUUID();
    const tempObject: any = {
      tabTitle: name,
      page: page,
    };
    const data: any = {
      id: _id,
      value: tempObject,
    };
    updatedItems.push(data);
    setIndexTabs(updatedItems);
  }

  function removeSelectionData(id: string) {
    const updatedItems: any = [...indexTabs()];
    let findIndex = updatedItems.findIndex((item: any) => item.id == id);
    updatedItems.splice(findIndex, 1);
    setIndexTabs(updatedItems);
    setItemRemoved(true);
    setIndexTabsUpdated(true);
  }

  return (
    <>
      <div>
        <For each={indexTabs()}>
          {(tab: any) => (
            <div class="index-tab-items">
              <label>Title:</label>
              <div class="app-flex flex align-center space-between pr">
                <input
                  type="text"
                  class="input"
                  id={tab.id}
                  value={tab.value.tabTitle}
                  onclick={() => {
                    setActiveField(tab.id);
                  }}
                  onchange={(e: any) => {
                    setIndexTabsUpdated(true);
                    tab.value.tabTitle = e.target.value;
                  }}
                />
                <Show when={activeField() == tab.id}>
                  <span
                    class="pa z-index-1 cur-p r-5"
                    onclick={() => {
                      const updatedTab = indexTabs().map((item: any) => {
                        if (tab.id === item.id) {
                          return {
                            ...item,
                            value: {
                              ...item.value,
                              tabTitle: "",
                            },
                          };
                        }
                        return item;
                      });
                      setIndexTabs(updatedTab);
                      setIndexTabsUpdated(true);
                    }}
                  >
                    <Icons name="circleX" width={20} height={20}></Icons>
                  </span>
                </Show>
              </div>
              <label>Link to:</label>
              <select
                class="input w-20 app-w-auto"
                onchange={(e: any) => (tab.value.page = Number(e.target.value))}
              >
                <For each={formPages()}>
                  {(page: any) => (
                    <option
                      value={page.value}
                      selected={tab.value.page == page.value ? true : false}
                    >
                      {page.label}
                    </option>
                  )}
                </For>
              </select>
              <div
                onclick={() => {
                  setIsFormSettingsUpdated(true);
                  removeSelectionData(tab.id);
                }}
              >
                <Icons
                  name="circleMinus"
                  width={20}
                  height={20}
                  stroke="red"
                ></Icons>
              </div>
            </div>
          )}
        </For>
        <button
          class="btn primary sm"
          onclick={() => {
            addSelectionData("", 1);
            setIndexTabsUpdated(true);
            setIsFormSettingsUpdated(true);
          }}
        >
          <Icons name="plus" stroke="white"></Icons>&nbsp; Add Index Tab
        </button>
      </div>
    </>
  );
};

export { indexTabs, indexTabsUpdated };
export default IndexTabsSettings;
