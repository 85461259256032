import { createSignal } from "solid-js";
import { createStore } from "solid-js/store";

const [usersList, setUsersList] = createStore([]);

const [userId, setUserId] = createSignal("" as string);
const [jwtToken, setJwtToken] = createSignal("" as string);
const [userRole, setUserRole] = createSignal("" as string);
const [userAvatar, setUserAvatar] = createSignal("" as string);
const [userSubscription, setUserSubscription] = createSignal("" as string);
const [userSubscriptionStatus, setUserSubscriptionStatus] = createSignal(
  "" as string
);
const [userSubscriptionDate, setUserSubscriptionDate] = createSignal(
  "" as string
);
const [totalUserLicences, setTotalUserLicences] = createSignal(0 as number);
const [totalStorageUsed, setTotalStorageUsed] = createSignal(0 as number);
const [loginUserName, setLoginUserName] = createSignal("" as string);
const [userNotifications, setUserNotifications] = createSignal([] as any);
const [unReadNotificationCount, setUnReadNotificationCount] = createSignal(
  0 as number
);

export {
  usersList,
  setUsersList,
  userId,
  setUserId,
  jwtToken,
  setJwtToken,
  userRole,
  setUserRole,
  userAvatar,
  setUserAvatar,
  userSubscription,
  setUserSubscription,
  userSubscriptionStatus,
  setUserSubscriptionStatus,
  userSubscriptionDate,
  setUserSubscriptionDate,
  totalUserLicences,
  setTotalUserLicences,
  totalStorageUsed,
  setTotalStorageUsed,
  loginUserName,
  setLoginUserName,
  userNotifications,
  setUserNotifications,
  unReadNotificationCount,
  setUnReadNotificationCount,
};
