import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { produce } from "solid-js/store";
import convert from "~/helpers/convertor";
import {
  calculateCheckboxHeight,
  calculateLabelHeight,
  formJson,
  pasteFormField,
  radioFieldButtons,
  removeFormField,
  setFormJson,
  setIsFormJsonUpdated,
  setTableOldFrame,
  tableOldFrame,
  updateCutCopyField,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  contextMenu,
  contextMenuLeft,
  contextMenuTop,
  isColumnMovement,
  isMouseDown,
  multipleSelectField,
  setContextMenu,
  setContextMenuLeft,
  setContextMenuTop,
  setIsFieldNameEditable,
  setIsMouseDown,
} from "~/store/global.store";
import ContextMenu, { setFieldPastePosition } from "../global/ContextMenu";
import { setSelectedTableLabel } from "../fields/LabelField";
const [isGuidline, setIsGuidline] = createSignal(false as boolean);
const [fieldMovement, setFieldMovement] = createSignal("" as string);
const [selectedFields, setSelectedFields] = createSignal([] as any);
const [isFieldsMoved, setIsFieldsMoved] = createSignal(false as boolean);

const Guidline: Component = () => {
  const [fieldGuidlines, setFieldGuidlines] = createSignal([] as any);

  let movementX: any = 0;
  let movementY: any = 0;

  createEffect(() => {
    if (Object.keys(formJson.currentField).length > 0) {
      setIsGuidline(true);
    }
    if (formJson.currentField) {
      setContextMenu("");
    }
    if (formJson.multipleFields.length > 0) {
      setFieldGuidlines(formJson.multipleFields);
    } else if (Object.keys(formJson.currentField).length > 0) {
      setFieldGuidlines([formJson.currentField]);
    } else {
      setFieldGuidlines([]);
    }
  });

  onMount(() => {
    const formFrame = document.querySelector(".builder-container");
    document?.addEventListener("contextmenu", (event: any) => {
      event.preventDefault();

      if (
        Object.keys(formJson.currentField)?.length == 0 ||
        formJson.currentField?.dataFieldSync?.parentTableId == 0 ||
        formJson.currentField?.dataFieldSync?.parentTableId == ""
      ) {
        setContextMenu("field");
        // const formFrameRect = formFrame.getBoundingClientRect();
        // const left: any = event.clientX - formFrameRect.left - 30;
        // const top: any =
        //   event.clientY + formFrame.scrollTop - formFrameRect.top - 30;
        setContextMenuLeft(event.clientX);
        setContextMenuTop(event.clientY);
      }
    });
    // Remove event on mouseup
    formFrame?.addEventListener("mouseup", () => {
      setFieldMovement("");
      setIsMouseDown(false);
      setSelectedFields([]);
    });
    // Create mousemove event
    formFrame?.addEventListener("mousemove", (event: any) => {
      let offsetX = 0;
      let offsetY = 0;
      if (event.target.classList.contains("builder-form")) {
        offsetX = event.offsetX;
        offsetY = event.offsetY;
      } else {
        let target = event.target;
        while (target && !target.classList.contains("field-group")) {
          offsetX += target.offsetLeft;
          offsetY += target.offsetTop;
          target = target.parentElement;
        }
      }
      let arr: any;
      if (offsetX && offsetY) {
        arr = [offsetX, offsetY];
      } else {
        arr = [100, 20];
      }
      setFieldPastePosition(arr);
      if (location.href.includes("builder")) {
        return handleMouseMove(event);
      }
    });
    window?.addEventListener("keydown", (event) => {
      const activeElement: any = document.activeElement;
      let isFieldMovement: any = true;
      if (
        !event.ctrlKey ||
        (event.ctrlKey &&
          ["ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"].includes(
            event.key
          ))
      ) {
        if (
          activeElement.tagName === "INPUT" ||
          activeElement.hasAttribute("type")
        ) {
          const inputType = activeElement.getAttribute("type");
          isFieldMovement =
            inputType != "text" &&
            inputType != "range" &&
            inputType != "number";
        }
        if (
          activeElement.tagName == "DIV" &&
          activeElement.hasAttribute("contenteditable")
        ) {
          isFieldMovement = false;
        }
      }
      if (isFieldMovement) {
        setIsMouseDown(true);
        setFieldMovement("move");
        let movement_x: number = 0;
        let movement_y: number = 0;
        let arrowAction = "";

        switch (event.key) {
          case "ArrowUp":
            arrowAction = "arrow";
            event.preventDefault();
            movement_y = event.shiftKey ? -24 : -12;
            break;

          case "ArrowDown":
            arrowAction = "arrow";
            event.preventDefault();
            movement_y = event.shiftKey ? 24 : 12;
            break;

          case "ArrowLeft":
            arrowAction = "arrow";
            movement_x = event.shiftKey ? -24 : -12;
            break;

          case "ArrowRight":
            arrowAction = "arrow";
            movement_x = event.shiftKey ? 24 : 12;
            break;

          default:
            break;
        }
        let currentFields: any = [];
        if (formJson.multipleFields.length > 0) {
          currentFields = [...formJson.multipleFields];
        } else {
          currentFields = [formJson.currentField];
        }
        switch (true) {
          case event.key == "Delete" ||
            (event.key == "Backspace" && event.ctrlKey):
            if (location.href.includes("builder")) {
              removeFormField();
            }
            break;
          case event.key == "c" && event.ctrlKey:
            updateCutCopyField();
            setFormJson({
              currentField: {},
              multipleFields: [],
            });
            setContextMenu("");
            break;
          case event.key == "x" && event.ctrlKey:
            updateCutCopyField();
            removeFormField();
            break;
          case event.key == "v" && event.ctrlKey:
            if (location.href.includes("form/builder")) {
              pasteFormField();
            }
            break;
          case event.key == "ArrowUp":
          case event.key == "ArrowDown":
          case event.key == "ArrowLeft":
          case event.key == "ArrowRight":
            if (
              location.href.includes("form/builder")
              // &&
              // (!isFieldJustSelected() ||
              //   formJson.currentField?.dataFieldSync.type == "ImageField" ||
              //   formJson.currentField?.dataFieldSync.type == "LineField" ||
              //   formJson.currentField?.dataFieldSync.type == "TableField")
            ) {
              handleMouseMove(
                false,
                movement_x,
                movement_y,
                arrowAction,
                event.shiftKey
              );
            }
            break;
          default:
            break;
        }
        setIsMouseDown(false);
        setSelectedFields([]);
      }
    });
  });

  function handleMouseMove(
    event: any,
    movement_x?: number,
    movement_y?: number,
    arrowAction: string = "",
    shiftKey?: boolean
  ) {
    if (!isMouseDown() || isColumnMovement()) return;
    if (event) {
      movementX += event.movementX;
      movementY += event.movementY;
      if (
        movementX < 12 &&
        movementX > -12 &&
        movementY < 12 &&
        movementY > -12
      ) {
        return;
      }
    } else {
      movementX = movement_x;
      movementY = movement_y;
    }
    let currentFields: any = [];
    if (formJson.multipleFields.length > 0) {
      currentFields = [...formJson.multipleFields];
    } else if (Object.keys(formJson.currentField).length > 0) {
      currentFields = [formJson.currentField];
    }
    setIsFieldsMoved(true);
    if (
      currentFields.filter(
        (f: any) =>
          String(f.dataFieldSync.parentTableId) != "" &&
          String(f.dataFieldSync.parentTableId) != "0"
      ).length == 0
    ) {
      const fields: any = currentFields.map((f: any) => ({
        frame: f.dataFieldSync.frame,
        type: f.dataFieldSync.type,
        shouldDisplayLabel: f.dataFieldSync.shouldDisplayLabel,
        labelWidth: f.dataFieldSync.labelWidth,
        label: f.dataFieldSync.label,
        fontSize: f.dataFieldSync.fontSize,
      }));
      for (const f of fields) {
        if (
          (["move", "left", "top-left", "bottom-left"].includes(
            fieldMovement()
          ) &&
            movementX < 0 &&
            f.frame[0][0] + Number(convert(movementX, "px-in")) <=
              0.2 +
                (f.type === "CheckBoxField" ||
                f.type === "LabelField" ||
                f.type === "ScriptButtonField" ||
                !f.shouldDisplayLabel
                  ? 0
                  : f.labelWidth
                  ? f.labelWidth <= 1
                    ? f.labelWidth
                    : 1
                  : 0)) ||
          (["move", "right", "top-right", "bottom-right"].includes(
            fieldMovement()
          ) &&
            movementX > 0 &&
            f.frame[0][0] + Number(convert(movementX, "px-in")) >=
              6.79 - (f.frame[1][0] - 1.5)) ||
          (["move", "top", "top-left", "top-right"].includes(fieldMovement()) &&
            movementY < 0 &&
            f.frame[0][1] + Number(convert(movementY, "px-in")) <= 0.24) ||
          (["move", "bottom", "bottom-left", "bottom-right"].includes(
            fieldMovement()
          ) &&
            movementY > 0 &&
            f.frame[0][1] + Number(convert(movementY, "px-in")) >=
              10.41 - (f.frame[1][1] - 0.375))
        ) {
          return;
        }
        if (
          (["left", "top-left", "bottom-left"].includes(fieldMovement()) &&
            movementX > 0 &&
            f.frame[1][0] - Number(convert(movementX, "px-in")) <
              (f.type == "BarcodeField"
                ? 1.25
                : f.type == "ScriptButtonField" || f.type == "RadioButtonField"
                ? 1
                : f.type == "LocationField" || f.type == "SignatureField"
                ? 0.5
                : f.type == "LabelField"
                ? 0.125
                : f.type == "CheckBoxField"
                ? f.shouldDisplayLabel && f.label?.trim()?.length >= 5
                  ? 1
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 4
                  ? 0.875
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 3
                  ? 0.75
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 2
                  ? 0.625
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 1
                  ? 0.5
                  : 0.375
                : 0.25)) ||
          (["right", "top-right", "bottom-right"].includes(fieldMovement()) &&
            movementX < 0 &&
            f.frame[1][0] + Number(convert(movementX, "px-in")) <
              (f.type == "BarcodeField"
                ? 1.25
                : f.type == "ScriptButtonField" || f.type == "RadioButtonField"
                ? 1
                : f.type == "LocationField" || f.type == "SignatureField"
                ? 0.5
                : f.type == "LabelField"
                ? 0.125
                : f.type == "CheckBoxField"
                ? f.shouldDisplayLabel && f.label?.trim()?.length >= 5
                  ? 1
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 4
                  ? 0.875
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 3
                  ? 0.75
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 2
                  ? 0.625
                  : f.shouldDisplayLabel && f.label?.trim()?.length == 1
                  ? 0.5
                  : 0.375
                : 0.25)) ||
          (["top", "top-left", "top-right"].includes(fieldMovement()) &&
            movementY > 0 &&
            f.frame[1][1] - Number(convert(movementY, "px-in")) <
              (f.type == "BarcodeField" || f.type == "LocationField"
                ? 0.375
                : 0.25)) ||
          (["bottom", "bottom-left", "bottom-right"].includes(
            fieldMovement()
          ) &&
            movementY < 0 &&
            f.frame[1][1] + Number(convert(movementY, "px-in")) <
              (f.type == "BarcodeField" || f.type == "LocationField"
                ? 0.375
                : 0.25))
        ) {
          return;
        }
        if (f.type == "DateField" || f.type == "DropdownField") {
          if (
            (["top", "top-left", "top-right"].includes(fieldMovement()) &&
              movementY < 0 &&
              f.frame[1][1] - Number(convert(movementY, "px-in")) > 0.375) ||
            (["bottom", "bottom-left", "bottom-right"].includes(
              fieldMovement()
            ) &&
              movementY > 0 &&
              f.frame[1][1] + Number(convert(movementY, "px-in")) > 0.375)
          ) {
            return;
          }
        }
        if (
          (f.type == "TextField" || f.type == "LocationField") &&
          Math.round((f.fontSize / 72) * 4 + 0.49) / 4 + 0.125 > 0.375
        ) {
          if (
            (["top", "top-left", "top-right"].includes(fieldMovement()) &&
              movementY > 0 &&
              f.frame[1][1] - Number(convert(movementY, "px-in")) <
                Math.round((f.fontSize / 72) * 4 + 0.49) / 4 + 0.125) ||
            (["bottom", "bottom-left", "bottom-right"].includes(
              fieldMovement()
            ) &&
              movementY < 0 &&
              f.frame[1][1] + Number(convert(movementY, "px-in")) <
                Math.round((f.fontSize / 72) * 4 + 0.49) / 4 + 0.125)
          ) {
            return;
          }
        }
      }
      const currentTableFields: any = currentFields.filter(
        (f: any) => f.dataFieldSync.type == "TableField"
      );
      const tempTableArr: any = {};
      for (const tf of currentTableFields) {
        tempTableArr[tf.dataFieldSync.uid] = {
          x: tf.dataFieldSync.frame[0][0],
          y: tf.dataFieldSync.frame[0][1],
        };
      }
      setTableOldFrame(tempTableArr);
      setSelectedFields(currentFields.map((f: any) => f.dataFieldSync.uid));
      setIsFormJsonUpdated(true);
      currentFields.forEach((currentField: any, field_key: any) => {
        setFormJson(
          "pages",
          produce((pages: any) => {
            pages.forEach((page: any) => {
              page.fields?.forEach((field: any) => {
                if (currentField.dataFieldSync?.type == "TableField") {
                  setFieldMovement("move");
                }
                if (
                  currentField.dataFieldSync?.uid ==
                  field.properties.dataFieldSync.uid
                ) {
                  const tempArr: any = field.properties.dataFieldSync.frame;
                  let canMove = false;
                  if (event.target)
                    event.target.classList.contains("builder-container")
                      ? (canMove = false)
                      : (canMove = true);
                  switch (fieldMovement()) {
                    case "move":
                      if (
                        (canMove && event.target !== undefined) ||
                        arrowAction == "arrow"
                      ) {
                        if (movementX >= 12 || movementX <= -12) {
                          tempArr[0][0] +=
                            movementX > 0
                              ? movementX == 24 && shiftKey
                                ? 0.25
                                : 0.125
                              : movementX == -24 && shiftKey
                              ? -0.25
                              : -0.125;
                          if (field_key == currentFields.length - 1) {
                            movementX = 0;
                          }
                        }
                        if (movementY >= 12 || movementY <= -12) {
                          tempArr[0][1] +=
                            movementY > 0
                              ? movementY == 24 && shiftKey
                                ? 0.25
                                : 0.125
                              : movementY == -24 && shiftKey
                              ? -0.25
                              : -0.125;
                          if (field_key == currentFields.length - 1) {
                            movementY = 0;
                          }
                        }
                      }
                      break;

                    case "left":
                      if (movementX >= 12 || movementX <= -12) {
                        tempArr[0][0] += movementX > 0 ? 0.125 : -0.125;
                        tempArr[1][0] -= movementX > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementX = 0;
                        }
                      }
                      break;

                    case "right":
                      if (movementX >= 12 || movementX <= -12) {
                        tempArr[1][0] += movementX > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementX = 0;
                        }
                      }
                      break;

                    case "top":
                      if (movementY >= 12 || movementY <= -12) {
                        tempArr[0][1] += movementY > 0 ? 0.125 : -0.125;
                        tempArr[1][1] -= movementY > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementY = 0;
                        }
                      }
                      break;

                    case "bottom":
                      if (movementY >= 12 || movementY <= -12) {
                        tempArr[1][1] += movementY > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementY = 0;
                        }
                      }
                      break;

                    case "top-left":
                      if (movementX >= 12 || movementX <= -12) {
                        tempArr[0][0] += movementX > 0 ? 0.125 : -0.125;
                        tempArr[1][0] -= movementX > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementX = 0;
                        }
                      }
                      if (movementY >= 12 || movementY <= -12) {
                        tempArr[0][1] += movementY > 0 ? 0.125 : -0.125;
                        tempArr[1][1] -= movementY > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementY = 0;
                        }
                      }
                      break;

                    case "top-right":
                      if (movementY >= 12 || movementY <= -12) {
                        tempArr[1][1] -= movementY > 0 ? 0.125 : -0.125;
                        tempArr[0][1] += movementY > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementY = 0;
                        }
                      }
                      if (movementX >= 12 || movementX <= -12) {
                        tempArr[1][0] += movementX > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementX = 0;
                        }
                      }
                      break;

                    case "bottom-left":
                      if (movementY >= 12 || movementY <= -12) {
                        tempArr[1][1] += movementY > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementY = 0;
                        }
                      }
                      if (movementX >= 12 || movementX <= -12) {
                        tempArr[0][0] += movementX > 0 ? 0.125 : -0.125;
                        tempArr[1][0] -= movementX > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementX = 0;
                        }
                      }
                      break;

                    case "bottom-right":
                      if (movementY >= 12 || movementY <= -12) {
                        tempArr[1][1] += movementY > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementY = 0;
                        }
                      }
                      if (movementX >= 12 || movementX <= -12) {
                        tempArr[1][0] += movementX > 0 ? 0.125 : -0.125;
                        if (field_key == currentFields.length - 1) {
                          movementX = 0;
                        }
                      }
                      break;

                    default:
                      break;
                  }
                  tempArr[0][0] = Number(tempArr[0][0].toFixed(3));
                  tempArr[0][1] = Number(tempArr[0][1].toFixed(3));
                  tempArr[1][0] = Number(tempArr[1][0].toFixed(3));
                  tempArr[1][1] = Number(Number(tempArr[1][1]).toFixed(3));
                  if (
                    field.properties.dataFieldSync.type == "CheckBoxField" &&
                    (field.properties.dataFieldSync.parentTableId == 0 ||
                      field.properties.dataFieldSync.parentTableId == "")
                  ) {
                    tempArr[1][1] = calculateCheckboxHeight(
                      tempArr[1][0] - 0.09,
                      field.properties.dataFieldSync.label,
                      field.properties.dataFieldSync.fontSize,
                      field.properties.dataFieldSync.shouldDisplayLabel,
                      "Helvetica"
                    );
                  }
                  if (
                    field.properties.dataFieldSync.type == "LabelField" &&
                    (field.properties.dataFieldSync.parentTableId == 0 ||
                      field.properties.dataFieldSync.parentTableId == "")
                  ) {
                    if (
                      !(
                        field.properties.dataFieldSync.fontSize <= 15 &&
                        tempArr[1][1] < 0.375
                      )
                    ) {
                      tempArr[1][1] = calculateLabelHeight(
                        field.properties.dataFieldSync.fontSize,
                        // Old Code
                        // field.properties.dataFieldSync.label,
                        // New Code
                        field.properties.dataFieldSync.label.replace(
                          /\n{2,}/g,
                          (match: any) =>
                            "\n".repeat(Math.max(1, match.length / 2.5))
                        ),
                        tempArr[1][0],
                        field.properties.font == 0
                          ? "Times New Roman"
                          : field.properties.font == 1
                          ? "Helvetica"
                          : "Courier New"
                      );
                    }
                  }
                  if (
                    field.properties.dataFieldSync.type == "RadioButtonField"
                  ) {
                    tempArr[1][1] =
                      Math.ceil(
                        radioFieldButtons(
                          field.properties.choiceFieldSync.items.map(
                            (item: any) => ({
                              title: item.title,
                            })
                          ),
                          field.properties.dataFieldSync.fontSize,
                          field.properties.dataFieldSync.frame[1][0]
                        ).bounds?.height / 0.125
                      ) * 0.125;
                    tempArr[1][1] = tempArr[1][1] ? tempArr[1][1] : 0.375;
                  }
                  field.properties.dataFieldSync.frame = tempArr;
                  // const navbarHeight = 50;
                  // const paginationHeight = 75;
                  // const formFrameMargin = 16;
                  // const fieldHeight = Number(convert(tempArr[1][1], "in-px"));
                  // const windowHeight = window.innerHeight;
                  // const fieldTop =
                  //   windowHeight -
                  //   (navbarHeight +
                  //     paginationHeight +
                  //     formFrameMargin +
                  //     fieldHeight);
                  // const fieldBottom =
                  //   windowHeight - navbarHeight - paginationHeight;

                  // // Scrolling down
                  // if (Number(convert(tempArr[0][1], "in-px")) > fieldTop) {
                  //   const element: any =
                  //     document.querySelector(".builder-container");
                  //   element.scrollTop +=
                  //     Number(convert(tempArr[0][1], "in-px")) - fieldTop;
                  // }
                  // // Scrolling up
                  // if (Number(convert(tempArr[0][1], "in-px")) < fieldBottom) {
                  //   const element: any =
                  //     document.querySelector(".builder-container");
                  //   element.scrollTop -=
                  //     fieldBottom - Number(convert(tempArr[0][1], "in-px"));
                  // }
                }
                if (
                  currentField.dataFieldSync?.uid ==
                  field.properties.dataFieldSync.parentTableId
                ) {
                  const tableFieldFrame = currentFields.find(
                    (obj: any) =>
                      obj.dataFieldSync.uid ==
                      field.properties.dataFieldSync.parentTableId
                  ).dataFieldSync.frame;
                  const tempArr = field.properties.dataFieldSync.frame;
                  tempArr[0][0] += Number(
                    (
                      tableFieldFrame[0][0] -
                      tableOldFrame()[currentField.dataFieldSync?.uid].x
                    ).toFixed(3)
                  );
                  tempArr[0][1] += Number(
                    (
                      tableFieldFrame[0][1] -
                      tableOldFrame()[currentField.dataFieldSync?.uid].y
                    ).toFixed(3)
                  );
                  field.properties.dataFieldSync.frame = tempArr;
                }
              });
            });
          })
        );
      });
      setTableOldFrame([]);
    }
  }

  return (
    <>
      <Show when={isGuidline() && fieldGuidlines().length > 0}>
        <For each={fieldGuidlines()}>
          {(field: any) => (
            <>
              <Show when={contextMenu() == "field"}>
                <ContextMenu
                  top={contextMenuTop()}
                  left={contextMenuLeft()}
                  field={field}
                ></ContextMenu>
              </Show>
              <Show when={field.dataFieldSync?.type != "TableField"}>
                <div
                  class={`guidline ${
                    formJson.currentField &&
                    Object.keys(formJson.currentField)?.length > 0 &&
                    String(
                      formJson.currentField.dataFieldSync?.parentTableId
                    ) != "0" &&
                    formJson.currentField.dataFieldSync?.tableCellAddress.includes(
                      0
                    )
                      ? "cur-p"
                      : ""
                  }`}
                  onClick={() => {
                    if (
                      formJson.currentField &&
                      Object.keys(formJson.currentField)?.length > 0 &&
                      String(
                        formJson.currentField.dataFieldSync?.parentTableId
                      ) != "0" &&
                      formJson.currentField.dataFieldSync?.tableCellAddress.includes(
                        0
                      )
                    ) {
                      setSelectedTableLabel(
                        formJson.currentField.dataFieldSync?.uid
                      );
                      updatedCurrentField(
                        formJson.currentField.dataFieldSync?.parentTableId
                      );
                    }
                  }}
                  style={{
                    "--left": `${
                      Number(
                        convert(field.dataFieldSync.frame[0][0], "in-px")
                      ) - 24
                    }px`,
                    "--top": `${
                      Number(
                        convert(field.dataFieldSync.frame[0][1], "in-px")
                      ) - 3
                    }px`,
                    "--width": `${
                      Number(
                        convert(field.dataFieldSync.frame[1][0], "in-px")
                      ) + 6
                    }px`,
                    "--height": `${
                      Number(
                        convert(field.dataFieldSync.frame[1][1], "in-px")
                      ) + 6
                    }px`,
                  }}
                  onMouseDown={(e: any) => {
                    e.preventDefault();
                    setIsMouseDown(true);
                    movementX = 0;
                    movementY = 0;
                    setFieldMovement("move");
                  }}
                  onclick={(event: any) => {
                    setIsFieldNameEditable(false);
                    multipleSelectField() || event.shiftKey
                      ? updatedMultipleFields(field.dataFieldSync.uid)
                      : "";
                  }}
                ></div>
                <Show
                  when={
                    String(field.dataFieldSync.parentTableId) != "" &&
                    String(field.dataFieldSync.parentTableId) == "0"
                  }
                >
                  <Show
                    when={[
                      "TextField",
                      "DateField",
                      "DropdownField",
                      "LabelField",
                      "SignatureField",
                      "ImageField",
                      "PhotoField",
                      "AnnotateField",
                      "LocationField",
                      "BarcodeField",
                      "LineField",
                    ].includes(field.dataFieldSync.type)}
                  >
                    <Show
                      when={
                        (field.dataFieldSync.type != "LabelField" &&
                          field.dataFieldSync.type != "DateField" &&
                          field.dataFieldSync.type != "DropdownField") ||
                        (field.dataFieldSync.type == "LabelField" &&
                          field.dataFieldSync.fontSize <= 15 &&
                          field.dataFieldSync.frame[1][1] <= 0.375) ||
                        ((field.dataFieldSync.type == "DateField" ||
                          field.dataFieldSync.type == "DropdownField") &&
                          field.dataFieldSync.fontSize <= 18.5 &&
                          field.dataFieldSync.frame[1][1] <= 0.375)
                      }
                    >
                      <Show when={field.dataFieldSync.type != "LineField"}>
                        <span
                          class="guidline-span"
                          style={{
                            "--left": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][0],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][0],
                                  "in-px"
                                )
                              ) -
                              23
                            }px`,
                            "--top": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][1],
                                  "in-px"
                                )
                              ) - 5
                            }px`,
                            "--cursor": "nesw-resize",
                          }}
                          onMouseDown={() => {
                            setIsMouseDown(true);
                            movementX = 0;
                            movementY = 0;
                            setFieldMovement("top-right");
                          }}
                        ></span>
                        <span
                          class="guidline-span"
                          style={{
                            "--left": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][0],
                                  "in-px"
                                )
                              ) - 26
                            }px`,
                            "--top": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][1],
                                  "in-px"
                                )
                              ) - 5
                            }px`,
                            "--cursor": "nwse-resize",
                          }}
                          onMouseDown={() => {
                            setIsMouseDown(true);
                            movementX = 0;
                            movementY = 0;
                            setFieldMovement("top-left");
                          }}
                        ></span>
                        <span
                          class="guidline-span"
                          style={{
                            "--left": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][0],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][0],
                                  "in-px"
                                )
                              ) -
                              23
                            }px`,
                            "--top": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][1],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][1],
                                  "in-px"
                                )
                              ) -
                              2
                            }px`,
                            "--cursor": "nwse-resize",
                          }}
                          onMouseDown={() => {
                            setIsMouseDown(true);
                            movementX = 0;
                            movementY = 0;
                            setFieldMovement("bottom-right");
                          }}
                        ></span>
                        <span
                          class="guidline-span"
                          style={{
                            "--left": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][0],
                                  "in-px"
                                )
                              ) - 26
                            }px`,
                            "--top": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][1],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][1],
                                  "in-px"
                                )
                              ) -
                              2
                            }px`,
                            "--cursor": "nesw-resize",
                          }}
                          onMouseDown={() => {
                            setIsMouseDown(true);
                            movementX = 0;
                            movementY = 0;
                            setFieldMovement("bottom-left");
                          }}
                        ></span>
                      </Show>
                      <Show
                        when={
                          field.dataFieldSync.type != "LineField" ||
                          (field.dataFieldSync.type == "LineField" &&
                            field.isVertical)
                        }
                      >
                        <span
                          class="guidline-span horizontal bar"
                          style={{
                            "--left": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][0],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][0],
                                  "in-px"
                                )
                              ) /
                                2 -
                              36
                            }px`,
                            "--top": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][1],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][1],
                                  "in-px"
                                )
                              ) -
                              1
                            }px`,
                            "--cursor": "ns-resize",
                          }}
                          onMouseDown={() => {
                            setIsMouseDown(true);
                            movementX = 0;
                            movementY = 0;
                            setFieldMovement("bottom");
                          }}
                        ></span>
                        <span
                          class="guidline-span horizontal bar"
                          style={{
                            "--left": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][0],
                                  "in-px"
                                )
                              ) +
                              Number(
                                convert(
                                  field.dataFieldSync.frame[1][0],
                                  "in-px"
                                )
                              ) /
                                2 -
                              36
                            }px`,
                            "--top": `${
                              Number(
                                convert(
                                  field.dataFieldSync.frame[0][1],
                                  "in-px"
                                )
                              ) - 5
                            }px`,
                            "--cursor": "ns-resize",
                          }}
                          onMouseDown={() => {
                            setIsMouseDown(true);
                            movementX = 0;
                            movementY = 0;
                            setFieldMovement("top");
                          }}
                        ></span>
                      </Show>
                    </Show>
                  </Show>
                  <Show
                    when={
                      field.dataFieldSync.type != "LineField" ||
                      (field.dataFieldSync.type == "LineField" &&
                        !field.isVertical)
                    }
                  >
                    <span
                      class="guidline-span bar"
                      style={{
                        "--left": `${
                          Number(
                            convert(field.dataFieldSync.frame[0][0], "in-px")
                          ) +
                          Number(
                            convert(field.dataFieldSync.frame[1][0], "in-px")
                          ) -
                          22
                        }px`,
                        "--top": `${
                          Number(
                            convert(field.dataFieldSync.frame[0][1], "in-px")
                          ) +
                          Number(
                            convert(field.dataFieldSync.frame[1][1], "in-px")
                          ) /
                            2 -
                          10
                        }px`,
                        "--cursor": "ew-resize",
                      }}
                      onMouseDown={() => {
                        setIsMouseDown(true);
                        movementX = 0;
                        movementY = 0;
                        setFieldMovement("right");
                      }}
                    ></span>
                    <span
                      class="guidline-span bar"
                      style={{
                        "--left": `${
                          Number(
                            convert(field.dataFieldSync.frame[0][0], "in-px")
                          ) - 26
                        }px`,
                        "--top": `${
                          Number(
                            convert(field.dataFieldSync.frame[0][1], "in-px")
                          ) +
                          Number(
                            convert(field.dataFieldSync.frame[1][1], "in-px")
                          ) /
                            2 -
                          10
                        }px`,
                        "--cursor": "ew-resize",
                      }}
                      onMouseDown={() => {
                        setIsMouseDown(true);
                        movementX = 0;
                        movementY = 0;
                        setFieldMovement("left");
                      }}
                    ></span>
                  </Show>
                </Show>
              </Show>
            </>
          )}
        </For>
      </Show>
    </>
  );
};

export {
  isGuidline,
  setIsGuidline,
  fieldMovement,
  setFieldMovement,
  selectedFields,
  isFieldsMoved,
  setIsFieldsMoved,
};
export default Guidline;
