import { Component, Show, createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import convert from "~/helpers/convertor";
import generateCSS from "~/helpers/generator";
import Icons from "../icons/Icons";
import { Html5QrcodeScanner } from "html5-qrcode";
import {
  isMouseDown,
  multipleSelectField,
  removePopup,
  setIsMouseDown,
  setPopup,
} from "~/store/global.store";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  preLoadData,
  setPreLoadData,
  updateFormRecords,
} from "~/store/records.store";
import JsBarcode from "jsbarcode";
import QRCode from "qrcode";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";

interface Props {
  properties: any;
  fieldsType: string;
}

const [openScanner, setOpenScanner] = createSignal(false as boolean);
const [dataSrc, setDataSrc] = createSignal({} as any);

const BarcodeField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [codeFormat, setCodeFormat] = createSignal("" as string);

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
    if (
      preLoadData()[props.properties.dataFieldSync.uid] &&
      !dataSrc()[props.properties.dataFieldSync.uid]
    ) {
      generateBarcode(preLoadData()[props.properties.dataFieldSync.uid]);
    }
  });

  function generateBarcode(barcodeValue: string) {
    const format: any = codeFormat()
      ? codeFormat()?.replace("_", "")
      : "CODE128";
    if (format == "QRCODE") {
      QRCode.toDataURL(barcodeValue).then((url) => {
        setDataSrc({
          ...dataSrc(),
          [props.properties.dataFieldSync.uid]: url,
        });
      });
    } else {
      const canvas = document.createElement("canvas");
      JsBarcode(canvas, barcodeValue, {
        format,
        width: 2,
        height: 100,
        displayValue: false,
      });
      setDataSrc({
        ...dataSrc(),
        [props.properties.dataFieldSync.uid]: canvas.toDataURL("image/png"),
      });
    }
  }

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  function startScanner() {
    setPopup("barcode");
    setOpenScanner(true);
    let html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      false
    );
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);
  }

  function onScanSuccess(decodedText: any, decodedResult: any) {
    setCodeFormat(decodedResult.result.format.formatName);
    updateFormRecords(props.properties.dataFieldSync.uid, decodedText);
    const tempArr: any = { ...preLoadData() };
    tempArr[props.properties.dataFieldSync.uid] = decodedText;
    setPreLoadData(tempArr);
    generateBarcode(preLoadData()[props.properties.dataFieldSync.uid]);
    if (decodedText != "") {
      document.getElementById("html5-qrcode-button-camera-stop")?.click();
      document.getElementById("html5-qrcode-anchor-scan-type-change")?.click();
    }
    setTimeout(() => {
      removePopup("barcode");
    }, 1000);
  }

  function onScanFailure(error: any) {
    // handle scan failure, usually better to ignore and keep scanning.
    console.warn(`Code scan error = ${error}`);
  }

  return (
    <>
      <div
        class={`pa ${
          props.fieldsType == "designer" ? "builder-field-border" : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        }`}
        style={generateCSS(fieldProperties)}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) =>
          props.fieldsType != "render"
            ? multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid)
            : ""
        }
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      >
        <div class="app-flex flex space-between">
          <input
            class={`input w-80 ${
              preLoadData()[props.properties.dataFieldSync.uid] != "" &&
              preLoadData()[props.properties.dataFieldSync.uid]?.includes(
                "http"
              )
                ? "pr-25"
                : ""
            }`}
            type="text"
            value={
              props.fieldsType != "designer" &&
              preLoadData()[props.properties.dataFieldSync.uid]
                ? preLoadData()[props.properties.dataFieldSync.uid]
                : ""
            }
            onblur={(e: any) => {
              if (props.fieldsType == "render") {
                const tempArr: any = { ...preLoadData() };
                tempArr[props.properties.dataFieldSync.uid] = e.target.value;
                setPreLoadData(tempArr);
                updateFormRecords(
                  props.properties.dataFieldSync.uid,
                  e.target.value,
                  false,
                  false
                );
                if (e.target.value) {
                  setCodeFormat("CODE128");
                  generateBarcode(e.target.value);
                }
              }
            }}
            readOnly={props.fieldsType != "render" ? true : false}
            disabled={props.fieldsType == "preview" ? true : false}
          />
          <Show
            when={
              props.fieldsType == "render" &&
              preLoadData()[props.properties.dataFieldSync.uid] != "" &&
              preLoadData()[props.properties.dataFieldSync.uid]?.includes(
                "http"
              )
            }
          >
            <a
              href={preLoadData()[props.properties.dataFieldSync.uid]}
              class="pa r-38 t-7 cur-p"
              target="_blank"
            >
              <Icons
                name="circleArrowRight"
                stroke="#007AFE"
                height={20}
                width={20}
              ></Icons>
            </a>
          </Show>
          <div
            class={`app-flex flex align-center justify-center w-20 ${
              props.fieldsType == "render" ? "cur-p" : ""
            }`}
            onclick={() => (props.fieldsType == "render" ? startScanner() : "")}
          >
            <Icons
              name="barcode"
              width={25}
              height={25}
              stroke="#007AFE"
            ></Icons>
          </div>
        </div>
        <Show when={props.properties.dataFieldSync.frame[1][1] > 0.375}>
          <div
            class="flex align-center justify-center"
            style={{
              height: "calc(100% - 36px)",
            }}
          >
            <img
              onclick={() =>
                props.fieldsType == "render" ? startScanner() : ""
              }
              src={`${
                props.fieldsType == "render" &&
                preLoadData()[props.properties.dataFieldSync.uid]
                  ? dataSrc()[props.properties.dataFieldSync.uid]
                  : "/photo2x.png"
              }`}
              class={`${
                props.fieldsType == "render" &&
                preLoadData()[props.properties.dataFieldSync.uid]
                  ? "object-contain"
                  : ""
              } ${props.fieldsType == "render" ? "cur-p" : ""}`}
              style={{
                // width:
                //   Number(
                //     convert(props.properties.dataFieldSync.frame[1][0], "in-px")
                //   ) -
                //   2 +
                //   "px",
                width: "auto",
                height:
                  convert(
                    props.properties.dataFieldSync.frame[1][1] - 0.375,
                    "in-px"
                  ) + "px",
                "max-height": "85px",
                "max-width": "120px",
              }}
            />
          </div>
        </Show>
      </div>
    </>
  );
};

export { openScanner, setDataSrc };
export default BarcodeField;
