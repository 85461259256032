import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { useRouteData } from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import Icons from "~/components/icons/Icons";
import { popupQueue, setPopup } from "~/store/global.store";
import AddAppVersionPopup from "~/components/popup/AddAppVersionPopup";
import fetchApi from "~/lib/api";
import { format } from "date-fns";
import { notify } from "~/components/global/Notification";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);

    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/app/versions/list`,
      jwt_token: jwt_token as string,
      // body: {
      //   sort_string: "asc_created",
      // },
    };
    let appVersions = await fetchApi(apiParams);
    if (appVersions?.statusCode == 401 || appVersions?.statusCode == 403) {
      throw redirect("/logout");
    }
    appVersions = appVersions.data;
    return { appVersions, jwt_token };
  });
}

const appVersions: Component = () => {
  setPageTitle(`${SITE_TITLE} - App Versions`);
  const data = useRouteData<typeof routeData>();

  const [appVersions, setAppVersions] = createSignal([] as any);
  const [sortString, setSortString] = createSignal("asc_created" as string);

  createEffect(() => {
    if (data()?.appVersions) {
      setAppVersions(data()?.appVersions);
    }
  });

  async function fetchAppVersionsList(jwt_token: any) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/app/versions/list`,
      jwt_token: jwt_token as string,
      // body: {
      //   sort_string: sortString(),
      // },
    };
    let appVersions = await fetchApi(apiParams);
    if (appVersions?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (appVersions?.statusCode == 403) {
      location.reload();
    }
    if (appVersions && appVersions.status) {
      setAppVersions(appVersions.data);
    }
  }

  return (
    <main>
      <Show when={popupQueue.includes("addAppVersion")}>
        <AddAppVersionPopup
          setValue={() => {
            fetchAppVersionsList(data()?.jwt_token);
          }}
        ></AddAppVersionPopup>
      </Show>
      <SystemLayout>
        <div class="flex space-between align-center">
          <h3 class="title color">Manage App Versions</h3>
          <button
            class="btn primary sm"
            onclick={() => setPopup("addAppVersion")}
          >
            Add New App Version
          </button>
        </div>
        <br />
        <div>
          <table class="table w-100">
            <thead class="user-select-none">
              <tr>
                <th class="w-150p">Version</th>
                <th>File Name</th>
                <th class="w-183p">Added At</th>
                <th class="w-125p">Action</th>
              </tr>
            </thead>
            <tbody>
              <For each={appVersions()}>
                {(data: any) => (
                  <tr>
                    <td>{data.version}</td>
                    <td>{data.name}</td>
                    <td>{format(new Date(data.created_at), "MMMM,dd yyyy")}</td>
                    <td>
                      <button
                        class="btn primary sm"
                        onclick={() => {
                          navigator.clipboard.writeText(data.url);
                          notify({
                            isNotified: true,
                            message: "Copied!!",
                            theme: "success",
                            placement: "top",
                            timeout: 1000,
                          });
                        }}
                      >
                        <Icons name="copy" stroke="#fff"></Icons>
                      </button>
                    </td>
                  </tr>
                )}
              </For>
            </tbody>
          </table>
        </div>
      </SystemLayout>
    </main>
  );
};

export default appVersions;
