import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import {
  formJson,
  isFieldJustSelected,
  isNewLabelField,
  setFormJson,
  setIsFieldJustSelected,
  setIsFormJsonUpdated,
  setIsNewLabelField,
  updateFormJson,
  updatedCurrentField,
} from "~/store/form.store";
import RangeSlider from "../global/RangeSlider";
import SelectOne from "../global/SelectOne";
import Checkbox from "../global/Checkbox";
import {
  colorSettings,
  dateFieldTypes,
  dateFormat,
  fieldAlignments,
  fieldColors,
  fontFamily,
  lineScales,
  lineTypes,
  locationFieldTypes,
  tableFieldTypes,
  textFieldTypes,
} from "~/store/field-editor.store";
import {
  formImage,
  imageBase64,
  setFormImage,
  setImageBase64,
} from "../fields/ImageField";
import {
  activeField,
  linkedFieldLabel,
  nestedList,
  parseHtml,
  rgbToHex,
  setActiveField,
  setIsFieldNameEditable,
  setNestedList,
  setPopup,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import AddSelectionItems from "./AddSelectionItems";
import { read, utils } from "xlsx";
import { createStore, produce } from "solid-js/store";
import ScriptButtonEditor from "./ScriptButtonEditor";
import {
  isFieldsMoved,
  isGuidline,
  selectedFields,
  setIsFieldsMoved,
} from "./Guidline";
import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Color from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";
import Paragraph from "@tiptap/extension-paragraph";
import {
  isNotScriptButtonEmail,
  setIsNotScriptButtonEmail,
} from "../popup/EmailSettings";

let editor: any;

interface Props {
  user_id: string;
  jwt_token: string;
}

export const [hourTimeFormat, setHourTimeFormat] = createSignal(
  false as boolean
);

const FieldEditor: Component<Props> = (props: any) => {
  const [dateTimeFormat, setDateTimeFormat] = createSignal("" as any);
  const [labelColorType, setLabelColorType] = createSignal(
    "textColor" as string
  );
  const [linkedFiled, setLinkedFiled] = createSignal(false as boolean);
  const [subField, setSubField] = createSignal("" as string);
  const [labelFieldSelectedContent, setLabelFieldSelectedContent] = createStore(
    {
      isBold: false as boolean,
      isItalic: false as boolean,
      isUnderline: false as boolean,
      font: 1 as number,
      color: "#000000" as string,
    }
  ) as any;
  const [hasEditorFocus, setHasEditorFocus] = createSignal(false as boolean);
  const [isNewField, setIsNewField] = createSignal(false as boolean);
  const [selectedText, setSelectedText] = createSignal("" as string);

  let labelInputRef: any;

  createEffect(() => {
    if (formJson.currentField?.dataFieldSync?.uid) {
      setIsFieldsMoved(true);
      setIsNewField(true);
    }
  });

  createEffect(() => {
    if (formJson.currentField && selectedFields().length == 0) {
      if (!isNotScriptButtonEmail() && isFieldJustSelected()) {
        if (
          formJson.currentField.dataFieldSync?.type == "TableField" ||
          formJson.currentField.dataFieldSync?.type == "LineField" ||
          formJson.currentField.dataFieldSync?.type == "ImageField"
        ) {
          setIsFieldNameEditable(false);
        } else {
          if (formJson.currentField && selectedFields().length == 0) {
            setTimeout(() => {
              labelInputRef?.focus();
            }, 0);
          } else {
            labelInputRef?.blur();
          }
          if (
            formJson.currentField.dataFieldSync?.type == "LabelField" &&
            !hasEditorFocus()
          ) {
            setHasEditorFocus(true);
          }
        }
        if (formJson.currentField.dataFieldSync?.label?.trim() != ":") {
          setActiveField("label_id");
        }
      }
      if (formJson.currentField.dataFieldSync?.type == "LabelField") {
        setTimeout(() => {
          if (editor && (isFieldsMoved() || !isNewField())) {
            editor.destroy();
          }
          const editorObject: any = {
            element: labelInputRef,
            extensions: [
              StarterKit,
              TextStyle,
              Underline,
              Color,
              FontFamily,
              Paragraph.configure({
                HTMLAttributes: {
                  style: `margin: 0; color: #000000`,
                },
              }),
            ],
            content:
              parseHtml(
                formJson.currentField?.attributedStringHTML?.replace(
                  /<br\s*(?:class="ProseMirror-trailingBreak")?\s*\/?>/gi,
                  ""
                ),
                formJson.currentField?.dataFieldSync?.fontSize,
                // formJson.currentField?.font == 0
                //   ? "Times New Roman"
                //   : formJson.currentField?.font == 1
                //   ? "Helvetica"
                //   : "Courier New",
                formJson.currentField?.color,
                "designer"
              ) ?? formJson.currentField?.dataFieldSync?.label,
            editable: true,
            onSelectionUpdate() {
              setLabelFieldSelectedContent({
                isBold: false,
                isItalic: false,
                isUnderline: false,
                font: 1,
                color: "#000000",
              });
            },
            onFocus() {
              setHasEditorFocus(true);
            },
            onBlur() {
              setHasEditorFocus(false);
            },
            onUpdate({ editor }) {
              if (labelInputRef.innerText != "") {
                var divElement: any = document.querySelector(
                  ".tiptap.ProseMirror"
                );
                var innerContent = divElement.innerHTML;
                innerContent = innerContent.replace(/  /g, "&nbsp;&nbsp;");
                const string = `
                      <!DOCTYPE html PUBLIC \"-//W3C//DTD HTML 4.01//EN\" \"http://www.w3.org/TR/html4/strict.dtd\">
                        <html>
                          <head>
                            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
                            <meta http-equiv="Content-Style-Type" content="text/css" />
                            <title></title>
                            <meta name="Generator" content="Cocoa HTML Writer" />
                          </head>
                          <body>
                            ${innerContent}
                          </body>
                        </html>
                    `;
                updateFormJson(
                  "dataFieldSync",
                  "label",
                  labelInputRef.innerText
                );
                updateFormJson("", "attributedStringHTML", string);
              }
            },
          };
          // if (!isNotScriptButtonEmail() && isFieldJustSelected()) {
          //   editorObject["autofocus"] = "end";
          // }
          if (!editor && (!isFieldsMoved() || isNewField())) {
            editorObject["autofocus"] = "end";
          } else {
            delete editorObject["autofocus"];
          }
          if (!editor || isFieldsMoved() || !isNewField()) {
            setIsFieldsMoved(false);
            editor = new Editor(editorObject);
          }
          if (isNewLabelField()) {
            editor.chain().focus().setFontFamily("Helvetica").run();
            setIsNewLabelField(false);
          }
        }, 0);
      }
    } else {
      if (editor) {
        editor.destroy();
      }
      labelInputRef?.blur();
    }
    if (formJson.currentField?.dateFormat) {
      setDateTimeFormat(formJson.currentField?.dateFormat);
      if (
        dateFormat.date_time_24?.filter(
          (item: { id: string }) =>
            item.id === formJson.currentField?.dateFormat
        ).length > 0 ||
        formJson.currentField?.dateFormat == "HH:mm"
      ) {
        setHourTimeFormat(true);
      } else {
        setHourTimeFormat(false);
      }
    }
    if (
      formJson.currentField.dataFieldSync?.type == "ImageField" &&
      imageBase64() != ""
    ) {
      updateFormJson("imageFieldCommon", "imageBase64", imageBase64());
      setImageBase64("");
    }
    if (formJson.currentField?.dateInputType == "calculated") {
      setDateTimeFormat(formJson.currentField?.calculationResultFormat);
    }
    if (
      formJson.currentField.linkType == "linked_child" ||
      formJson.currentField.choiceFieldSync?.linkType == "linked_child"
    ) {
      setLinkedFiled(true);
    } else {
      setLinkedFiled(false);
    }

    if (formJson.currentField.choiceFieldSync?.linkType == "linked_nested") {
      setNestedList(true);
    } else {
      setNestedList(false);
    }
    if (formJson.currentField.dataFieldSync?.type == "TextField") {
      setSubField("Text");
    } else if (formJson.currentField.dataFieldSync?.type == "DateField") {
      setSubField("Date");
    } else {
      setSubField("");
    }
  });

  function updateSelectedField() {
    const selection = window.getSelection();
    if (!selection || selection.rangeCount === 0) return;
    let range = selection.getRangeAt(0);
    let node: any = range?.startContainer;
    if (selectedText() && selection.toString()?.trim() !== "" && node) {
      let safetyCounter = 100;
      while (node && node.parentNode && node.parentNode.tagName !== "P") {
        node = node.parentNode;
        if (--safetyCounter <= 0) {
          console.error("Safety limit reached while traversing the DOM.");
          return;
        }
      }
      if (!node) return;
      let clonedNode: any = node.cloneNode(true);
      let startContainerParent: any = range.startContainer.parentNode;
      if (clonedNode.childNodes.length > 1) {
        clonedNode.childNodes.forEach((n: any) => {
          if (n.outerHTML !== startContainerParent.outerHTML) {
            clonedNode.removeChild(n);
          }
        });
      }

      if (clonedNode.childNodes[0]?.tagName !== "SPAN") {
        let nodesToRemove: any[] = [];
        clonedNode.childNodes[0]?.childNodes.forEach((n: any) => {
          if (n.outerHTML !== startContainerParent.outerHTML) {
            nodesToRemove.push(n);
          }
        });
        nodesToRemove.forEach((n: any) => {
          clonedNode.childNodes[0].removeChild(n);
        });
      }
      const boldregex = /<strong>/;
      const italicregex = /<em>/;
      const underlineregex = /<u>/;
      setLabelFieldSelectedContent({
        isBold: boldregex.test(clonedNode.outerHTML),
        isItalic: italicregex.test(clonedNode.outerHTML),
        isUnderline: underlineregex.test(clonedNode.outerHTML),
        font: clonedNode.outerHTML?.includes("Courier")
          ? 2
          : clonedNode.outerHTML?.includes("Helvetica")
          ? 1
          : formJson.currentField.font,
        color: clonedNode.outerHTML?.split("color: ")[1]?.split(/"|;/)[0]
          ? rgbToHex(clonedNode.outerHTML.split("color: ")[1]?.split(/"|;/)[0])
          : "#000000",
      });
    }
  }

  onMount(() => {
    document.addEventListener("selectionchange", handleSelectionChange);
  });

  function handleSelectionChange() {
    if (window.getSelection()?.toString()) {
      setSelectedText(window.getSelection()?.toString());
    }
  }

  return (
    <Show
      when={
        isGuidline() &&
        Object.keys(formJson.currentField).length > 0 &&
        formJson.currentField.dataFieldSync?.type != "TableField"
      }
    >
      <div>
        <div class="builder-title">
          <span class="subtitle">
            {formJson.currentField.dataFieldSync?.type == "DateField"
              ? formJson.currentField?.dateInputType == "date_time"
                ? "Date and Time"
                : formJson.currentField.dateInputType?.charAt(0).toUpperCase() +
                  formJson.currentField.dateInputType?.slice(1)
              : formJson.currentField.dataFieldSync?.type == "TextField"
              ? formJson.currentField.inputType?.charAt(0).toUpperCase() +
                formJson.currentField.inputType?.slice(1)
              : formJson.currentField.dataFieldSync?.type == "ScriptButtonField"
              ? "Script Button"
              : formJson.currentField.dataFieldSync?.type == "LocationField"
              ? "GPS Coordinates"
              : (formJson.currentField.dataFieldSync?.type.replace(
                  /Field/i,
                  ""
                ) as string)}
          </span>
          <span
            class="sidebar-menu"
            onclick={() => setFormJson({ currentField: {} })}
          >
            <Icons name="x" stroke="red" strokeWidth="2.5"></Icons>
          </span>
        </div>
        <div class="panel-body builder-toolbar__body">
          <Show
            when={
              formJson.currentField.dataFieldSync?.type != "LineField" &&
              formJson.currentField.dataFieldSync?.type !=
                "ScriptButtonField" &&
              formJson.currentField.dataFieldSync?.type != "ImageField"
            }
          >
            <div class="form-group">
              <label for="label_id">Field Name:</label>
              <div class="app-flex flex align-center space-between pr">
                <Show
                  when={
                    formJson.currentField.dataFieldSync?.type == "LabelField"
                  }
                >
                  <div
                    ref={labelInputRef}
                    class="label-input-field label-data editor"
                    id="label_id"
                    onclick={() => {
                      setActiveField("label_id");
                    }}
                    onmouseup={() => updateSelectedField()}
                    onkeyup={() => updateSelectedField()}
                    onfocus={() => {
                      setIsFieldJustSelected(true);
                    }}
                    onkeydown={(event: any) => {
                      if (event.key == "Tab") {
                        setIsNotScriptButtonEmail(false);
                        setIsFieldJustSelected(false);
                      }
                    }}
                    onfocusin={() => setIsFieldNameEditable(true)}
                    onfocusout={() => {
                      setIsFieldNameEditable(false);
                      setIsNotScriptButtonEmail(false);
                      setIsFieldJustSelected(false);
                    }}
                  ></div>
                </Show>
                <Show
                  when={
                    formJson.currentField.dataFieldSync?.type != "LabelField"
                  }
                >
                  <input
                    ref={labelInputRef}
                    type="text"
                    id="label_id"
                    class="input"
                    value={
                      formJson.currentField.dataFieldSync?.label
                        ? formJson.currentField.dataFieldSync?.label.replace(
                            /:$/,
                            ""
                          )
                        : ""
                    }
                    onclick={() => {
                      setActiveField("label_id");
                    }}
                    onInput={(event) =>
                      updateFormJson(
                        "dataFieldSync",
                        "label",
                        formJson.currentField.dataFieldSync?.type !=
                          "CheckBoxField" &&
                          formJson.currentField.dataFieldSync?.type !=
                            "LabelField"
                          ? event.currentTarget.value.replace(/:$/, "") + ":"
                          : event.currentTarget.value
                      )
                    }
                    onfocus={() => {
                      setIsFieldJustSelected(true);
                    }}
                    onkeydown={(event: any) => {
                      if (event.key == "Tab") {
                        setIsNotScriptButtonEmail(false);
                        setIsFieldJustSelected(false);
                      }
                    }}
                    onblur={() => {
                      setIsNotScriptButtonEmail(false);
                      setIsFieldJustSelected(false);
                    }}
                    onfocusin={() => setIsFieldNameEditable(true)}
                    onfocusout={() => setIsFieldNameEditable(false)}
                  />
                </Show>
                <Show
                  when={
                    activeField() == "label_id" &&
                    formJson.currentField.dataFieldSync?.label != ""
                  }
                >
                  <span
                    class="pa z-index-1 cur-p r-5"
                    onclick={() => {
                      updateFormJson("dataFieldSync", "label", "");
                      updateFormJson("", "attributedStringHTML", "");
                      if (editor) {
                        editor.commands.setContent("");
                        editor.commands.focus();
                      }
                      labelInputRef?.focus();
                    }}
                  >
                    <Icons name="circleX" width={20} height={20}></Icons>
                  </span>
                </Show>
              </div>
            </div>
            <div class="clearfix"></div>
            <br />
            <RangeSlider
              step={0.5}
              min={6}
              max={
                String(formJson.currentField.dataFieldSync?.parentTableId) !=
                "0"
                  ? 18
                  : 60
              }
              label="Font Size:"
              value={formJson.currentField.dataFieldSync?.fontSize}
              setValue={(val: string) => {
                updateFormJson("dataFieldSync", "fontSize", Number(val));
              }}
            />
            <div class="clearfix"></div>
            <br />
            <Show
              when={
                (formJson.currentField.dataFieldSync?.type != "CheckBoxField" ||
                  (formJson.currentField.dataFieldSync?.type ==
                    "CheckBoxField" &&
                    formJson.currentField.dataFieldSync.parentTableId != 0 &&
                    formJson.currentField.dataFieldSync.parentTableId != "")) &&
                formJson.currentField.dataFieldSync?.type !=
                  "RadioButtonField" &&
                formJson.currentField.dataFieldSync?.type != "PhotoField" &&
                formJson.currentField.dataFieldSync?.type != "SignatureField" &&
                formJson.currentField.dataFieldSync?.type != "NotesField" &&
                formJson.currentField.dataFieldSync?.type != "AnnotateField" &&
                formJson.currentField.dataFieldSync?.type != "LocationField" &&
                formJson.currentField.dataFieldSync?.type != "BarcodeField"
              }
            >
              <div class="form-group">
                <label for="">Field Alignment:</label>
                <div class="clearfix"></div>
                <SelectOne
                  class="full-width"
                  set={fieldAlignments}
                  active={formJson.currentField.dataFieldSync?.alignment}
                  setActive={(val: string) => {
                    updateFormJson("dataFieldSync", "alignment", val);
                  }}
                ></SelectOne>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type != "LabelField" &&
                (String(formJson.currentField.dataFieldSync?.parentTableId) ==
                  "0" ||
                  (String(formJson.currentField.dataFieldSync?.parentTableId) !=
                    "0" &&
                    formJson.currentField.dataFieldSync?.type ==
                      "CheckBoxField"))
              }
            >
              <Checkbox
                label="Display label"
                name="display_lbl"
                value={
                  formJson.currentField.dataFieldSync
                    ?.shouldDisplayLabel as boolean
                }
                setValue={(val: boolean) =>
                  updateFormJson("dataFieldSync", "shouldDisplayLabel", val)
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type == "CheckBoxField"
              }
            >
              <Checkbox
                label="Checked by default"
                name="check_by_default"
                value={formJson.currentField?.isCheckedByDefault as boolean}
                setValue={(val: boolean) =>
                  updateFormJson("", "isCheckedByDefault", val)
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.inputType != "autoIncrement" &&
                formJson.currentField.inputType != "calculated" &&
                formJson.currentField.inputType != "linked" &&
                formJson.currentField.dataFieldSync?.type != "CheckBoxField" &&
                formJson.currentField.dataFieldSync?.type != "LabelField" &&
                formJson.currentField?.dateInputType != "calculated"
              }
            >
              <Checkbox
                label="Required field"
                name="required_field"
                value={
                  formJson.currentField.dataFieldSync?.isRequired as boolean
                }
                setValue={(val: boolean) =>
                  updateFormJson("dataFieldSync", "isRequired", val)
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.inputType != "autoIncrement" &&
                formJson.currentField.inputType != "calculated" &&
                formJson.currentField.inputType != "linked" &&
                formJson.currentField.dataFieldSync?.type != "LabelField" &&
                formJson.currentField?.dateInputType != "linked" &&
                formJson.currentField?.dateInputType != "calculated" &&
                formJson.currentField.dataFieldSync?.type != "SignatureField" &&
                formJson.currentField.dataFieldSync?.type != "AnnotateField" &&
                formJson.currentField.dataFieldSync?.type != "LocationField" &&
                formJson.currentField.dataFieldSync?.type != "BarcodeField"
              }
            >
              <Checkbox
                label="Copy field when cloning record"
                name="copy_when_clone"
                value={
                  formJson.currentField.dataFieldSync
                    ?.shouldCopyWhenClone as boolean
                }
                setValue={(val: boolean) =>
                  updateFormJson("dataFieldSync", "shouldCopyWhenClone", val)
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                String(formJson.currentField.dataFieldSync?.parentTableId) !=
                "0"
              }
            >
              <hr />
              <label class="lbl">Select Field Type:</label>
              <div class="field-selector">
                <For each={tableFieldTypes}>
                  {(field: any) => (
                    <div class="field-selector-list">
                      <div
                        class={`list-item ${
                          field.subFields.length == 0 ? "cur-p" : ""
                        }`}
                        onclick={() =>
                          field.subFields.length > 0
                            ? setSubField(field.label)
                            : updateFormJson(
                                "dataFieldSync",
                                "tableFieldType",
                                field.value
                              )
                        }
                      >
                        <label
                          class={field.subFields.length == 0 ? "cur-p" : ""}
                        >
                          {field.label}
                        </label>
                        <Show when={field.subFields.length > 0}>
                          <span onclick={() => setSubField("")}>
                            <Icons name="chevronRight"></Icons>
                          </span>
                        </Show>
                        <Show
                          when={
                            field.subFields.length == 0 &&
                            field.value ==
                              formJson.pages[
                                formJson.currentPage
                              ].fields.filter(
                                (f: any) =>
                                  f.properties.dataFieldSync.uid ==
                                  formJson.currentField?.dataFieldSync?.uid
                              )[0].properties.dataFieldSync.type
                          }
                        >
                          <Icons name="check" stroke="#2e61a3"></Icons>
                        </Show>
                        <Show
                          when={
                            field.subFields.length > 0 &&
                            subField() == field.label
                          }
                        >
                          <For each={field.subFields}>
                            {(subField: any) => (
                              <div class="field-selector-list sub-item">
                                <div
                                  class="list-item cur-p"
                                  onclick={(e: any) => {
                                    updateFormJson(
                                      "dataFieldSync",
                                      "tableFieldType",
                                      subField.value
                                    );
                                  }}
                                >
                                  <label class="cur-p">{subField.label}</label>
                                  <Show
                                    when={
                                      subField.value ==
                                        formJson.pages[
                                          formJson.currentPage
                                        ].fields.filter(
                                          (f: any) =>
                                            f.properties.dataFieldSync.uid ==
                                            formJson.currentField?.dataFieldSync
                                              ?.uid
                                        )[0].properties.inputType ||
                                      subField.value ==
                                        formJson.pages[
                                          formJson.currentPage
                                        ].fields.filter(
                                          (f: any) =>
                                            f.properties.dataFieldSync.uid ==
                                            formJson.currentField?.dataFieldSync
                                              ?.uid
                                        )[0].properties.dateInputType ||
                                      (formJson.pages[
                                        formJson.currentPage
                                      ].fields.filter(
                                        (f: any) =>
                                          f.properties.dataFieldSync.uid ==
                                          formJson.currentField?.dataFieldSync
                                            ?.uid
                                      )[0].properties.dataFieldSync?.type ==
                                        "TextField" &&
                                        subField.value ==
                                          `text_${
                                            formJson.pages[
                                              formJson.currentPage
                                            ].fields.filter(
                                              (f: any) =>
                                                f.properties.dataFieldSync
                                                  .uid ==
                                                formJson.currentField
                                                  ?.dataFieldSync?.uid
                                            )[0].properties.inputType
                                          }`) ||
                                      (formJson.pages[
                                        formJson.currentPage
                                      ].fields.filter(
                                        (f: any) =>
                                          f.properties.dataFieldSync.uid ==
                                          formJson.currentField?.dataFieldSync
                                            ?.uid
                                      )[0].properties.dataFieldSync?.type ==
                                        "DateField" &&
                                        formJson.pages[
                                          formJson.currentPage
                                        ].fields.filter(
                                          (f: any) =>
                                            f.properties.dataFieldSync.uid ==
                                            formJson.currentField?.dataFieldSync
                                              ?.uid
                                        )[0].properties.dateInputType !=
                                          "time" &&
                                        subField.value ==
                                          `date_${
                                            formJson.pages[
                                              formJson.currentPage
                                            ].fields.filter(
                                              (f: any) =>
                                                f.properties.dataFieldSync
                                                  .uid ==
                                                formJson.currentField
                                                  ?.dataFieldSync?.uid
                                            )[0].properties.dateInputType
                                          }`)
                                    }
                                  >
                                    <Icons
                                      name="check"
                                      stroke="#2e61a3"
                                    ></Icons>
                                  </Show>
                                </div>
                              </div>
                            )}
                          </For>
                        </Show>
                      </div>
                    </div>
                  )}
                </For>
              </div>
              <hr />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={formJson.currentField.dataFieldSync?.type == "LabelField"}
            >
              <div class="form-group">
                <SelectOne
                  class="full-width"
                  set={fontFamily}
                  active={labelFieldSelectedContent.font}
                  setActive={(val: number) => {
                    if (selectedText() != "") {
                      editor
                        .chain()
                        .focus()
                        .setFontFamily(
                          val == 0
                            ? "Times New Roman"
                            : val == 1
                            ? "Helvetica"
                            : "Courier New"
                        )
                        .run();
                    } else {
                      editor.commands.selectAll();
                      editor.commands.setFontFamily(
                        val == 0
                          ? "Times New Roman"
                          : val == 1
                          ? "Helvetica"
                          : "Courier New"
                      );
                    }
                    setLabelFieldSelectedContent({
                      font: val,
                    });
                    updateFormJson("", "font", val);
                  }}
                  showTooltip={true}
                ></SelectOne>
              </div>
              <div class="clearfix"></div>
              <br />
              <div class="flex app-flex gap">
                <Checkbox
                  label="Bold"
                  name="bold_field"
                  value={labelFieldSelectedContent.isBold}
                  setValue={(val: boolean) => {
                    if (selectedText() != "") {
                      if (val) {
                        editor.chain().focus().setBold().run();
                        setLabelFieldSelectedContent({
                          isBold: true,
                        });
                      } else {
                        editor.chain().focus().unsetBold().run();
                        setLabelFieldSelectedContent({
                          isBold: false,
                        });
                      }
                    } else {
                      editor.commands.selectAll();
                      if (val) {
                        editor.commands.setBold();
                      } else {
                        editor.commands.unsetBold();
                      }
                      updateFormJson("", "isBold", val);
                    }
                  }}
                />
                <Checkbox
                  label="Italic"
                  name="italic_field"
                  value={labelFieldSelectedContent.isItalic}
                  setValue={(val: boolean) => {
                    if (selectedText() != "") {
                      if (val) {
                        editor.chain().focus().setItalic().run();
                        setLabelFieldSelectedContent({
                          isItalic: true,
                        });
                      } else {
                        editor.chain().focus().unsetItalic().run();
                        setLabelFieldSelectedContent({
                          isItalic: false,
                        });
                      }
                    } else {
                      editor.commands.selectAll();
                      if (val) {
                        editor.commands.setItalic();
                      } else {
                        editor.commands.unsetItalic();
                      }
                      updateFormJson("", "isItalic", val);
                    }
                  }}
                />
                <Checkbox
                  label="Underline"
                  name="underline_field"
                  value={labelFieldSelectedContent.isUnderline}
                  setValue={(val: boolean) => {
                    if (selectedText() != "") {
                      if (val) {
                        editor.chain().focus().setUnderline().run();
                        setLabelFieldSelectedContent({
                          isUnderline: true,
                        });
                      } else {
                        editor.chain().focus().unsetUnderline().run();
                        setLabelFieldSelectedContent({
                          isUnderline: false,
                        });
                      }
                    } else {
                      editor.commands.selectAll();
                      if (val) {
                        editor.commands.setUnderline();
                      } else {
                        editor.commands.unsetUnderline();
                      }
                      // updateFormJson("", "isUnderline", val);
                    }
                  }}
                />
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.inputType != "autoIncrement" &&
                formJson.currentField.inputType != "calculated" &&
                formJson.currentField.inputType != "url" &&
                formJson.currentField.inputType != "email" &&
                formJson.currentField.inputType != "linked" &&
                formJson.currentField.dataFieldSync?.type != "CheckBoxField" &&
                formJson.currentField.dataFieldSync?.type != "LabelField" &&
                formJson.currentField.dataFieldSync?.type != "DropdownField" &&
                formJson.currentField.dataFieldSync?.type !=
                  "RadioButtonField" &&
                formJson.currentField.dataFieldSync?.type != "DateField" &&
                formJson.currentField.dataFieldSync?.type != "PhotoField" &&
                formJson.currentField.dataFieldSync?.type != "SignatureField" &&
                formJson.currentField.dataFieldSync?.type != "NotesField" &&
                formJson.currentField.dataFieldSync?.type != "AnnotateField" &&
                formJson.currentField.dataFieldSync?.type != "LocationField" &&
                formJson.currentField.dataFieldSync?.type != "BarcodeField"
              }
            >
              <div class="form-group">
                <Checkbox
                  label="Populate field"
                  name="populated"
                  value={formJson.currentField?.isPrePopulated as boolean}
                  setValue={(val: boolean) =>
                    updateFormJson("", "isPrePopulated", val)
                  }
                />
                <div class="flex align-center space-between pr">
                  <input
                    class="input"
                    type="text"
                    value={formJson.currentField?.prePopulatedText}
                    onclick={() => {
                      setActiveField("populated_text");
                    }}
                    onkeyup={(e: any) =>
                      updateFormJson("", "prePopulatedText", e.target.value)
                    }
                  />
                  <Show when={activeField() == "populated_text"}>
                    <span
                      class="pa z-index-1 cur-p r-5"
                      onclick={() => updateFormJson("", "prePopulatedText", "")}
                    >
                      <Icons name="circleX" width={20} height={20}></Icons>
                    </span>
                  </Show>
                </div>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type == "DateField" &&
                formJson.currentField?.dateInputType != "linked" &&
                formJson.currentField?.dateInputType != "calculated"
              }
            >
              <Checkbox
                label="Populate field with current Date-Time"
                name="populated_date"
                value={formJson.currentField?.isDateTimeStamp as boolean}
                setValue={(val: boolean) =>
                  updateFormJson("", "isDateTimeStamp", val)
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={formJson.currentField.dataFieldSync?.type == "DateField"}
            >
              <div
                class={
                  formJson.currentField.dateInputType == "time"
                    ? "flex gap-0_5"
                    : ""
                }
              >
                <Show
                  when={formJson.currentField.dateInputType != "calculated"}
                >
                  <label>
                    {formJson.currentField.dateInputType == "time"
                      ? "Time Format:"
                      : "Date Format:"}
                    &nbsp;
                  </label>
                </Show>
                <Show when={formJson.currentField.dateInputType == "time"}>
                  <Checkbox
                    label="24-Hour Time"
                    name="24_hour_time"
                    value={(hourTimeFormat() ? true : false) as boolean}
                    setValue={(val: boolean) => {
                      setDateTimeFormat("");
                      hourTimeFormat()
                        ? (setHourTimeFormat(false),
                          formJson.currentField.dateInputType == "time"
                            ? updateFormJson("", "dateFormat", "hh:mm a")
                            : "")
                        : (setHourTimeFormat(true),
                          formJson.currentField.dateInputType == "time"
                            ? updateFormJson("", "dateFormat", "HH:mm")
                            : "");
                    }}
                  />
                  <div class="clearfix"></div>
                  <br />
                  <br />
                </Show>
                <Show when={formJson.currentField.dateInputType != "time"}>
                  <select
                    class="date_format_list"
                    onchange={(e: any) => {
                      if (formJson.currentField.dateInputType != "calculated") {
                        updateFormJson("", "dateFormat", e.target.value);
                      } else {
                        updateFormJson(
                          "",
                          "calculationResultFormat",
                          e.target.value
                        );
                      }
                    }}
                  >
                    <option value="" disabled selected={dateTimeFormat() == ""}>
                      Select Date Format
                    </option>
                    <For
                      each={
                        dateFormat[
                          hourTimeFormat()
                            ? formJson.currentField.dateInputType == "linked"
                              ? "date_time_24"
                              : formJson.currentField.dateInputType + "_24"
                            : formJson.currentField.dateInputType == "linked"
                            ? "date_time"
                            : formJson.currentField?.dateInputType
                        ]
                      }
                    >
                      {(item: any) => (
                        <>
                          <Show
                            when={
                              formJson.currentField.dateInputType !=
                              "calculated"
                            }
                          >
                            <option
                              value={item.id}
                              selected={dateTimeFormat() == item.id}
                            >
                              {item.value}
                            </option>
                          </Show>
                          <Show
                            when={
                              formJson.currentField.dateInputType ==
                              "calculated"
                            }
                          >
                            <option
                              value={item.id}
                              selected={dateTimeFormat() == item.id}
                            >
                              {item.value}
                            </option>
                          </Show>
                        </>
                      )}
                    </For>
                  </select>
                  <div class="clearfix"></div>
                  <br />
                </Show>
                <Show
                  when={
                    formJson.currentField.dateInputType != "time" &&
                    formJson.currentField.dateInputType != "date" &&
                    formJson.currentField.dateInputType != "calculated"
                  }
                >
                  <Checkbox
                    label="24-Hour Time"
                    name="24_hour_time"
                    value={(hourTimeFormat() ? true : false) as boolean}
                    setValue={(val: boolean) => {
                      setDateTimeFormat("");
                      hourTimeFormat()
                        ? (setHourTimeFormat(false),
                          formJson.currentField.dateInputType == "time"
                            ? updateFormJson("", "dateFormat", "hh:mm a")
                            : "")
                        : (setHourTimeFormat(true),
                          formJson.currentField.dateInputType == "time"
                            ? updateFormJson("", "dateFormat", "HH:mm")
                            : "");
                    }}
                  />
                  <div class="clearfix"></div>
                  <br />
                </Show>
              </div>
            </Show>
            <Show
              when={
                formJson.currentField.inputType == "calculated" ||
                formJson.currentField?.dateInputType == "calculated"
              }
            >
              <div class="form-group">
                <label for="formula">Formula:</label>
                <div class="flex align-center space-between pr">
                  <input
                    id="formula"
                    class="input"
                    type="text"
                    value={formJson.currentField?.calculationFormula}
                    onclick={() => {
                      setActiveField("formula");
                    }}
                    onkeyup={(e: any) =>
                      updateFormJson("", "calculationFormula", e.target.value)
                    }
                  />
                  <Show when={activeField() == "formula"}>
                    <span
                      class="pa z-index-1 cur-p r-5"
                      onclick={() =>
                        updateFormJson("", "calculationFormula", "")
                      }
                    >
                      <Icons name="circleX" width={20} height={20}></Icons>
                    </span>
                  </Show>
                </div>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.inputType == "number" ||
                formJson.currentField.inputType == "currency" ||
                formJson.currentField.inputType == "calculated" ||
                formJson.currentField?.dateInputType == "calculated"
              }
            >
              <div class="flex app-flex gap">
                <div class="form-group flex align-center">
                  <label for="minimum">Minimum:</label>&nbsp;
                  <div class="flex align-center space-between pr">
                    <input
                      id="minimum"
                      class="input"
                      type="number"
                      value={formJson.currentField.dataFieldSync?.minimum}
                      onclick={() => {
                        setActiveField("minimum");
                      }}
                      onblur={(e: any) => {
                        if (formJson.currentField.dataFieldSync?.maximum) {
                          e.target.value =
                            e.target.value >=
                            Number(formJson.currentField.dataFieldSync?.maximum)
                              ? Number(
                                  formJson.currentField.dataFieldSync?.maximum
                                ) - 1
                              : e.target.value;
                        }
                        updateFormJson(
                          "dataFieldSync",
                          "minimum",
                          e.target.value
                        );
                      }}
                    />
                    <Show when={activeField() == "minimum"}>
                      <span
                        class="pa z-index-1 cur-p r-5"
                        onclick={() =>
                          updateFormJson("dataFieldSync", "minimum", "")
                        }
                      >
                        <Icons name="circleX" width={20} height={20}></Icons>
                      </span>
                    </Show>
                  </div>
                </div>
                <div class="form-group flex align-center">
                  <label for="maximum">Maximum:</label>&nbsp;
                  <div class="flex align-center space-between pr">
                    <input
                      id="maximum"
                      class="input"
                      type="number"
                      value={formJson.currentField.dataFieldSync?.maximum}
                      onclick={() => {
                        setActiveField("maximum");
                      }}
                      onblur={(e: any) => {
                        if (formJson.currentField.dataFieldSync?.minimum) {
                          e.target.value =
                            e.target.value <=
                            Number(formJson.currentField.dataFieldSync?.minimum)
                              ? Number(
                                  formJson.currentField.dataFieldSync?.minimum
                                ) + 1
                              : e.target.value;
                        }
                        updateFormJson(
                          "dataFieldSync",
                          "maximum",
                          e.target.value
                        );
                      }}
                    />
                    <Show when={activeField() == "maximum"}>
                      <span
                        class="pa z-index-1 cur-p r-5"
                        onclick={() =>
                          updateFormJson("dataFieldSync", "maximum", "")
                        }
                      >
                        <Icons name="circleX" width={20} height={20}></Icons>
                      </span>
                    </Show>
                  </div>
                </div>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show when={formJson.currentField.inputType == "calculated"}>
              <RangeSlider
                step={1}
                min={2}
                max={5}
                label="Decimal Places:"
                value={formJson.currentField?.decimalPlaces}
                setValue={(val: string) => {
                  updateFormJson("", "decimalPlaces", val);
                }}
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show when={formJson.currentField.inputType == "autoIncrement"}>
              <div class="flex justify-end">
                <small class="lbl">
                  Starting at {formJson.currentField.autoIncrementFrom}
                </small>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                (formJson.currentField.dataFieldSync?.type == "CheckBoxField" ||
                  formJson.currentField.dataFieldSync?.type ==
                    "DropdownField" ||
                  formJson.currentField.dataFieldSync?.type ==
                    "RadioButtonField") &&
                String(formJson.currentField.dataFieldSync?.parentTableId) ==
                  "0"
              }
            >
              <Checkbox
                label="Link field"
                name="link_field"
                value={
                  (formJson.currentField.dataFieldSync?.type == "CheckBoxField"
                    ? formJson.currentField.linkType != "linked_parent"
                      ? false
                      : true
                    : formJson.currentField.choiceFieldSync?.linkType !=
                        "linked_parent" && linkedFiled()
                    ? true
                    : false) as boolean
                }
                setValue={(val: boolean) =>
                  linkedFiled()
                    ? (setLinkedFiled(false),
                      formJson.currentField.dataFieldSync?.type ==
                      "CheckBoxField"
                        ? updateFormJson("", "linkType", "unlinked")
                        : updateFormJson(
                            "choiceFieldSync",
                            "linkType",
                            "unlinked"
                          ))
                    : setLinkedFiled(true)
                }
                disabled={
                  formJson.currentField.linkType == "linked_parent" ||
                  formJson.currentField.choiceFieldSync?.linkType ==
                    "linked_parent" ||
                  nestedList()
                    ? true
                    : false
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.inputType == "linked" ||
                (formJson.currentField.dataFieldSync?.type == "CheckBoxField" &&
                  formJson.currentField.linkType != "linked_parent" &&
                  linkedFiled()) ||
                (formJson.currentField.dataFieldSync?.type == "DropdownField" &&
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_parent" &&
                  linkedFiled()) ||
                (formJson.currentField.dataFieldSync?.type ==
                  "RadioButtonField" &&
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_parent" &&
                  linkedFiled()) ||
                (formJson.currentField.dataFieldSync?.type == "DateField" &&
                  formJson.currentField?.dateInputType == "linked")
              }
            >
              <div class="flex app-flex space-between">
                <div class="flex app-flex gap align-center">
                  <label class="w-40">Link to:</label>
                  <input
                    class="input"
                    type="text"
                    value={linkedFieldLabel()}
                    disabled
                  />
                </div>
                <div class="flex align-center app-w-20">
                  <button
                    class="btn sm"
                    onclick={() => {
                      setPopup("linkField");
                    }}
                  >
                    <Icons name="chevronRightFilled" />
                  </button>
                </div>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type == "DropdownField" &&
                String(formJson.currentField.dataFieldSync?.parentTableId) ==
                  "0"
              }
            >
              <Checkbox
                label="Nested List"
                name="nested_list"
                value={
                  (formJson.currentField.choiceFieldSync?.linkType ==
                    "linked_nested" && nestedList()
                    ? true
                    : false) as boolean
                }
                setValue={(val: boolean) =>
                  nestedList()
                    ? (setNestedList(false),
                      updateFormJson("choiceFieldSync", "linkType", "unlinked"))
                    : setNestedList(true)
                }
                disabled={
                  formJson.currentField.linkType == "linked_parent" ||
                  formJson.currentField.choiceFieldSync?.linkType ==
                    "linked_parent" ||
                  linkedFiled()
                    ? true
                    : false
                }
              />
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type == "DropdownField" &&
                nestedList()
              }
            >
              <div class="flex app-flex space-between">
                <div class="flex app-flex gap align-center">
                  <label class="w-40">Link to:</label>
                  <input
                    class="input"
                    type="text"
                    value={linkedFieldLabel()}
                    disabled
                  />
                </div>
                <div class="flex align-center">
                  <button
                    class="btn sm"
                    onclick={() => {
                      setPopup("linkField");
                    }}
                  >
                    <Icons name="chevronRightFilled" />
                  </button>
                </div>
              </div>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type == "DropdownField" ||
                formJson.currentField.dataFieldSync?.type == "RadioButtonField"
              }
            >
              <div class="flex gap">
                <Show
                  when={
                    formJson.currentField.dataFieldSync?.type == "DropdownField"
                  }
                >
                  <input
                    type="file"
                    id="import_list"
                    class="d-n"
                    accept=".xlsx, .csv"
                    onchange={(event: any) => {
                      const file = event.target.files[0];
                      const reader = new FileReader();

                      reader.onload = function (e: any) {
                        const contents = e.target.result;
                        const workbook = read(contents, { type: "binary" });
                        const worksheet =
                          workbook.Sheets[workbook.SheetNames[0]];
                        const jsonData = utils.sheet_to_json(worksheet, {
                          header: 1,
                        });
                        let finalArr: any = [
                          ...formJson.currentField.choiceFieldSync?.items,
                          ...jsonData.flat(),
                        ];
                        finalArr = [...new Set(finalArr)];
                        finalArr = finalArr.map((val: any, key: number) => ({
                          id: key,
                          title: val,
                        }));

                        const nestedListArr: any = jsonData
                          .flat()
                          .filter(
                            (item: any) =>
                              !formJson.currentField.choiceFieldSync?.items.includes(
                                item
                              )
                          );
                        if (nestedListArr.length > 0) {
                          setIsFormJsonUpdated(true);
                        }
                        setFormJson(
                          "pages",
                          produce((pages: any) => {
                            pages.forEach((page: any) => {
                              page.fields?.forEach((item: any) => {
                                if (
                                  formJson.currentField.dataFieldSync?.uid ==
                                  item.properties.dataFieldSync?.uid
                                ) {
                                  item.properties.choiceFieldSync.items =
                                    finalArr;
                                }
                                if (
                                  formJson.currentField.dataFieldSync?.uid ==
                                  item.properties.dataFieldSync.linkedFieldId
                                ) {
                                  const nestedListString: any = JSON.parse(
                                    item.properties.choiceFieldSync
                                      .nestedListString
                                  );

                                  nestedListArr.forEach(
                                    (item: string | unknown) => {
                                      const tempObject: any = {
                                        title: item,
                                        subTitles: [],
                                      };
                                      nestedListString.push(tempObject);
                                    }
                                  );
                                  item.properties.choiceFieldSync.nestedListString =
                                    JSON.stringify(nestedListString);
                                }
                              });
                            });
                          })
                        );
                      };
                      reader.readAsBinaryString(file);
                    }}
                  />
                  <label for="import_list" class="btn primary sm">
                    Import List
                  </label>
                </Show>
              </div>
              <div class="clearfix"></div>
              <br />
              <AddSelectionItems></AddSelectionItems>
            </Show>
          </Show>
          <Show when={formJson.currentField.dataFieldSync?.type == "LineField"}>
            <div class="form-group">
              <label for="">Line:</label>
              <div class="clearfix"></div>
              <SelectOne
                class="full-width"
                set={lineTypes}
                active={formJson.currentField?.lineStyle}
                setActive={(val: string) => {
                  updateFormJson("", "lineStyle", val);
                }}
              ></SelectOne>
            </div>
            <div class="clearfix"></div>
            <br />
            <RangeSlider
              step={1}
              min={1}
              max={16}
              label="Thickness:"
              value={formJson.currentField?.thickness}
              setValue={(val: string) => {
                updateFormJson("", "thickness", val);
              }}
            />
            <div class="clearfix"></div>
            <br />
            <SelectOne
              class="full-width"
              set={lineScales}
              active={formJson.currentField?.isVertical}
              setActive={(val: string) => {
                updateFormJson("", "isVertical", val);
                if (val) {
                  updateFormJson("dataFieldSync", "frame", [
                    formJson.currentField.dataFieldSync?.frame[0],
                    [0.25, 3.5],
                  ]);
                } else {
                  updateFormJson("dataFieldSync", "frame", [
                    formJson.currentField.dataFieldSync?.frame[0],
                    [3.5, 0.25],
                  ]);
                }
              }}
            ></SelectOne>
            <div class="clearfix"></div>
            <br />
          </Show>
          <Show
            when={
              formJson.currentField.dataFieldSync?.type == "ScriptButtonField"
            }
          >
            <ScriptButtonEditor
              user_id={props.user_id}
              jwt_token={props.jwt_token}
            ></ScriptButtonEditor>
          </Show>
          <Show
            when={
              formJson.currentField.dataFieldSync?.type != "CheckBoxField" &&
              formJson.currentField.dataFieldSync?.type != "DropdownField" &&
              formJson.currentField.dataFieldSync?.type != "RadioButtonField" &&
              formJson.currentField.dataFieldSync?.type != "PhotoField" &&
              formJson.currentField.dataFieldSync?.type !=
                "ScriptButtonField" &&
              formJson.currentField.dataFieldSync?.type != "AnnotateField" &&
              formJson.currentField.dataFieldSync?.type != "LocationField" &&
              formJson.currentField.dataFieldSync?.type != "BarcodeField" &&
              formJson.currentField.dataFieldSync?.type != "NotesField" &&
              formJson.currentField.dataFieldSync?.type != "ImageField"
            }
          >
            <Show
              when={formJson.currentField.dataFieldSync?.type == "LabelField"}
            >
              <SelectOne
                class="full-width"
                set={colorSettings}
                active={labelColorType()}
                setActive={(val: string) => {
                  setLabelColorType(val);
                }}
              ></SelectOne>
              <div class="clearfix"></div>
              <br />
            </Show>
            <Show
              when={
                formJson.currentField.dataFieldSync?.type == "SignatureField"
              }
            >
              <div class="form-group">
                <label>Ink Color:</label>
              </div>
            </Show>
            <div class="flex app-flex gap-0_8 flex-wrap w-100">
              <For each={fieldColors}>
                {(item: any) => (
                  <div
                    class={`field-color ${
                      (formJson.currentField.dataFieldSync?.type == "LabelField"
                        ? labelColorType() == "textColor"
                          ? labelFieldSelectedContent.color
                          : formJson.currentField?.backgroundColor
                        : formJson.currentField.dataFieldSync?.type ==
                          "SignatureField"
                        ? formJson.currentField?.inkFieldSync?.inkColor
                        : formJson.currentField?.color) == item.color
                        ? item.isActiveWhite
                          ? labelColorType() == "backgroundColor" &&
                            item.color == "#000000"
                            ? "active-black"
                            : "active-white"
                          : "active-black"
                        : ""
                    }`}
                    style={{
                      background: `${
                        labelColorType() == "backgroundColor" &&
                        item.color == "#000000"
                          ? "#f5f5f5"
                          : item.color
                      }`,
                      border:
                        labelColorType() == "backgroundColor" &&
                        item.color == "#000000"
                          ? "1px solid"
                          : "",
                    }}
                    onclick={() => {
                      if (
                        formJson.currentField.dataFieldSync.type ==
                          "LabelField" &&
                        labelColorType() == "textColor"
                      ) {
                        setLabelFieldSelectedContent({
                          color: item.color,
                        });
                        if (selectedText() != "") {
                          editor.chain().focus().setColor(item.color).run();
                        } else {
                          editor.commands.selectAll();
                          editor.commands.setColor(item.color);
                        }
                        updateFormJson("", "color", item.color);
                      } else {
                        updateFormJson(
                          formJson.currentField.dataFieldSync.type ==
                            "SignatureField"
                            ? "inkFieldSync"
                            : "",
                          formJson.currentField.dataFieldSync.type ==
                            "SignatureField"
                            ? "inkColor"
                            : labelColorType() == "textColor"
                            ? "color"
                            : "backgroundColor",
                          item.color
                        );
                      }
                    }}
                  ></div>
                )}
              </For>
            </div>
          </Show>
          <Show
            when={formJson.currentField.dataFieldSync?.type == "AnnotateField"}
          >
            <input
              type="file"
              class="d-n"
              id="annotate_image"
              onchange={(e: any) => {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  const reader = new FileReader();
                  reader.onload = (event: any) => {
                    const base64Data = event.target.result.split(",")[1];
                    updateFormJson("", "bgImageBase64", base64Data);
                  };
                  reader.readAsDataURL(selectedFile);
                }
              }}
              accept="image/png, image/jpg, image/jpeg, image/webp"
            />
            <label for="annotate_image" class="color">
              Set Image
            </label>
          </Show>
          <Show
            when={
              (isNewField() &&
                String(formJson.currentField.dataFieldSync?.parentTableId) ==
                  "0" &&
                (formJson.currentField.dataFieldSync?.type == "TextField" ||
                  formJson.currentField.dataFieldSync?.type == "DateField")) ||
              formJson.currentField.dataFieldSync?.type == "LocationField"
            }
          >
            <br />
            <label class="lbl">
              {formJson.currentField.dataFieldSync?.type == "LocationField"
                ? "Select GPS Unit"
                : "Select Field Type"}
              :
            </label>
            <div class="field-selector">
              <For
                each={
                  formJson.currentField.dataFieldSync?.type == "TextField"
                    ? textFieldTypes
                    : formJson.currentField.dataFieldSync?.type == "DateField"
                    ? dateFieldTypes
                    : locationFieldTypes
                }
              >
                {(field: any) => (
                  <div class="field-selector-list">
                    <div
                      class="list-item cur-p"
                      onclick={() =>
                        updateFormJson(
                          "",
                          formJson.currentField.dataFieldSync?.type ==
                            "TextField"
                            ? "inputType"
                            : formJson.currentField.dataFieldSync?.type ==
                              "DateField"
                            ? "dateInputType"
                            : "locationFormat",
                          field.value
                        )
                      }
                    >
                      <label class="cur-p">{field.label}</label>
                      <Show
                        when={
                          formJson.currentField.dataFieldSync?.type ==
                          "TextField"
                            ? field.value == formJson.currentField?.inputType
                            : formJson.currentField.dataFieldSync?.type ==
                              "DateField"
                            ? field.value ==
                              formJson.currentField?.dateInputType
                            : field.value ==
                              formJson.currentField?.locationFormat
                        }
                      >
                        <Icons name="check" stroke="#2e61a3"></Icons>
                      </Show>
                    </div>
                  </div>
                )}
              </For>
            </div>
          </Show>
          <Show
            when={formJson.currentField.dataFieldSync?.type == "ImageField"}
          >
            <div class="flex justify-center">
              <input
                type="file"
                id={`formImage_${formJson.currentField.dataFieldSync?.uid}`}
                onchange={(e: any) => {
                  updatedCurrentField(formJson.currentField.dataFieldSync?.uid);
                  const selectedFile = e.target.files[0];
                  if (selectedFile) {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                      const base64Data = event.target.result.split(",")[1];
                      setImageBase64(base64Data);
                      const tempArr: any = [...formImage()];
                      if (
                        tempArr.findIndex(
                          (obj: any) =>
                            obj.uid == formJson.currentField.dataFieldSync.uid
                        ) != -1
                      ) {
                        const index: number = tempArr.findIndex(
                          (obj: any) =>
                            obj.uid == formJson.currentField.dataFieldSync.uid
                        );
                        tempArr.splice(index, 1);
                      }
                      const obj: any = {
                        uid: formJson.currentField.dataFieldSync?.uid,
                        imageBase64: base64Data,
                      };
                      tempArr.push(obj);
                      setFormImage(tempArr);
                    };
                    reader.readAsDataURL(selectedFile);
                  }
                }}
                accept="image/png, image/jpg, image/jpeg, image/webp"
                class="d-n"
              />
              <label
                class="btn sm primary"
                for={`formImage_${formJson.currentField.dataFieldSync?.uid}`}
              >
                Attach Image
              </label>
            </div>
          </Show>
          <br />
        </div>
      </div>
    </Show>
  );
};

export default FieldEditor;
