import { Component, createSignal, Show } from "solid-js";
import { useNavigate, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import { notify } from "~/components/global/Notification";
import SystemLayout from "~/components/layouts/system.layout";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import messages from "~/helpers/message";
import { setPageTitle } from "~/root";
import { validateUser } from "~/routes/api/validateUser";
import { updateFormsList } from "./list";
import Icons from "~/components/icons/Icons";
import { formsList, setFormsList } from "~/store/global.store";

export const [importRecordFile, setImportRecordFile] = createSignal({} as any);

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);

    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;
    return { user_id, jwt_token };
  });
}

const importFile: Component = () => {
  setPageTitle(`${SITE_TITLE} - Import`);
  const data = useRouteData<typeof routeData>();

  const navigate: any = useNavigate();

  const [isFileUploaded, setIsFileUploaded] = createSignal(false as boolean);

  async function uploadFile(file: any) {
    if (file.name.split(".").pop().toLowerCase() == "fmcx") {
      setIsFileUploaded(true);
      const formData: any = new FormData();
      formData.append("user_id", data()?.user_id);
      formData.append("structure", file);
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/form/import`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${data()?.jwt_token}`,
          },
          body: formData,
        }
      ).then((res: any) => res.json());
      if (response.statusCode == 401) {
        location.href = "/logout";
      } else {
        let message: any = "";
        let theme: any = "danger";
        if (response && response.status) {
          theme = "success";
          message = messages.form.design;
        } else {
          message = response.message;
        }
        notify({
          isNotified: true,
          message,
          theme,
          placement: "top",
          timeout: 2500,
        });
      }
      await updateFormsList(data()?.user_id, data()?.jwt_token);
      setFormsList(
        formsList().map((form: any) => {
          if (form.form_id === response.data.formId) {
            return {
              ...form,
              totalRecords: response.data.totalRecords,
            };
          }
          return form;
        })
      );
      setIsFileUploaded(false);
      navigate("/form/list");
    } else if (
      ["csv", "json", "xml"].includes(file.name.split(".").pop().toLowerCase())
    ) {
      setImportRecordFile(file);
      navigate("/form/list");
    }
  }

  return (
    <main>
      <SystemLayout>
        <div class="flex space-between align-center app-w-50">
          <h2 class="title color">Import</h2>
        </div>
        {/* <br />
        <div class="flex app-flex space-between">
          <div class="w-48">
            <h3 class="title">Import FMCX</h3>
            <div class="form-group p-5">
              <label>STEP 1: Open Finder</label>
              <br />
              <label>STEP 2: Select .fmcx file to import</label>
            </div>
            <div class="w-80 underline"></div>
            <div class="p-5">
              <input
                type="file"
                class="d-n"
                id="import_form"
                accept=".fmcx"
                onChange={async (event: any) => {
                  const file: any = event.target.files[0];
                  if (file) {
                    const formData: any = new FormData();
                    formData.append("user_id", data()?.user_id);
                    formData.append("structure", file);
                    const response = await fetch(
                      `${import.meta.env.VITE_API_URL}/form/import`,
                      {
                        method: "POST",
                        headers: {
                          Authorization: `Bearer ${data()?.jwt_token}`,
                        },
                        body: formData,
                      }
                    ).then((res: any) => res.json());
                    if (response.statusCode == 401) {
                      location.href = "/logout";
                    } else {
                      let message: any = "";
                      let theme: any = "danger";
                      if (response && response.status) {
                        theme = "success";
                        message = messages.form.design;
                      } else {
                        message = response.message;
                      }
                      notify({
                        isNotified: true,
                        message,
                        theme,
                        placement: "top",
                        timeout: 2500,
                      });
                    }
                  }
                  event.target.value = null;
                  updateFormsList(data()?.user_id, data()?.jwt_token);
                  navigate("/form/list");
                }}
              />
              <label class="btn primary sm" for="import_form">
                Import
              </label>
            </div>
          </div>
          <div class="border-right"></div>
          <div class="w-48">
            <h3 class="title">Import JSON | CSV | XML </h3>
            <div class="form-group p-5">
              <label>STEP 1: Open Finder</label>
              <br />
              <label>STEP 2: Select .json or .csv or .xml file to import</label>
            </div>
            <div class="w-80 underline"></div>
            <div class="p-5">
              <input
                type="file"
                class="d-n"
                id="import_record"
                accept=".json,.csv,.xml"
                onChange={async (event: any) => {
                  const file: any = event.target.files[0];
                  if (file) {
                    setImportRecordFile(file);
                  }
                  event.target.value = null;
                  navigate("/form/list");
                }}
              />
              <label class="btn primary sm" for="import_record">
                Import
              </label>
            </div>
          </div>
        </div> */}
        <div class="import-form">
          <input
            type="file"
            class="d-n"
            id="import_file"
            accept=".fmcx,.json,.csv,.xml"
            onChange={(event: any) => {
              const file: any = event.target.files[0];
              if (file) {
                uploadFile(file);
                event.target.value = null;
              }
            }}
          />
          <label for="import_file">
            <div
              class="lbl"
              draggable={true}
              ondragover={(event: any) => {
                event.preventDefault();
              }}
              ondrop={(event: any) => {
                event.preventDefault();
                const file = event.dataTransfer.files[0];
                if (file) {
                  uploadFile(file);
                }
              }}
            >
              <div class="text-center">
                <Show when={isFileUploaded()}>
                  <span>
                    <Icons name="buttonLoader" width={20} height={20}></Icons>
                    &nbsp; Uploading File
                  </span>
                </Show>
                <Show when={!isFileUploaded()}>
                  <span>
                    <Icons name="upload"></Icons>&nbsp; Upload/Drop File
                  </span>
                </Show>
                <br />
                <span class="fs-12">
                  supported file formats .fmcx, .json, .csv and .xml
                </span>
              </div>
            </div>
          </label>
        </div>
      </SystemLayout>
    </main>
  );
};

export default importFile;
