import { Component, Show, createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";

import generateCSS from "~/helpers/generator";
import {
  isFormDropDownOpen,
  isMouseDown,
  multipleSelectField,
  selectedDropDown,
  setAlertMessage,
  setIsFormDropDownOpen,
  setIsMouseDown,
  setPopup,
  setSelectedDropDown,
} from "~/store/global.store";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  formJson,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  additionalData,
  calculatedArr,
  preLoadData,
  setAdditionalData,
  updatedCalulatedFieldvalue,
  updateFormRecords,
} from "~/store/records.store";
import Dropdown from "../global/Dropdown";
import Icons from "../icons/Icons";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";

interface Props {
  properties: any;
  fieldsType: string;
}

const [nestedFieldData, setNestedFieldData] = createSignal([] as any);
const [selectedDropDownId, setSelectedDropDownId] = createSignal("" as string);

const DropdownField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [nestedField, setNestedField] = createSignal("" as string);

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
    if (props.properties.choiceFieldSync.linkType == "linked_nested") {
      formJson.pages.forEach((page: any) => {
        page.fields?.forEach((field: any) => {
          if (
            props.properties.dataFieldSync?.linkedFieldId ==
            field.properties.dataFieldSync?.uid
          ) {
            setNestedField(
              field.properties.dataFieldSync?.label.replace(/:$/, "")
            );
          }
        });
      });
    }
    if (preLoadData()[props.properties.dataFieldSync.uid]) {
      formJson.pages.forEach((page: any) => {
        page.fields?.forEach((field: any) => {
          if (
            field.properties.dataFieldSync.linkedFieldId ==
              props.properties.dataFieldSync.uid &&
            field.properties.choiceFieldSync.nestedListString
          ) {
            setNestedFieldData(
              JSON.parse(field.properties.choiceFieldSync.nestedListString)
                .filter(
                  (item: any) =>
                    item.title ==
                    formJson.pages.flatMap((page: any) =>
                      page.fields
                        ?.filter(
                          (f: any) =>
                            field.properties.dataFieldSync.linkedFieldId ==
                            f.properties.dataFieldSync.uid
                        )[0]
                        .properties.choiceFieldSync.items.filter(
                          (item: any) =>
                            item.id ==
                              preLoadData()[
                                props.properties.dataFieldSync.uid
                              ] ||
                            item.title ==
                              preLoadData()[props.properties.dataFieldSync.uid]
                        )
                    )[0]?.title
                )[0]
                ?.subTitles.map((obj: any) => ({
                  id: obj.uuid,
                  title: obj.subTitle,
                })) ?? []
            );
          }
        });
      });
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  return (
    <>
      <div
        style={generateCSS(fieldProperties)}
        class={`pa flex app-flex space-between align-center field border-1 bg-white ${
          props.fieldsType == "designer" ? "builder-field-border" : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        } ${
          String(props.properties.dataFieldSync.parentTableId) != "0"
            ? "z-index-1"
            : "z-index-0"
        } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
        id={`fieldDropDown_${props.properties.dataFieldSync.uid}`}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (
            isMouseDown() &&
            fieldMovement() == "" &&
            props.fieldsType == "designer"
          ) {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) => {
          if (props.fieldsType == "render") {
            setSelectedDropDownId(props.properties.dataFieldSync.uid);
            if (
              props.properties.choiceFieldSync.linkType == "linked_nested" &&
              nestedFieldData()?.length == 0
            ) {
              preLoadData()[props.properties.dataFieldSync.linkedFieldId]
                ? setAlertMessage(
                    `<b>No ${props.properties.dataFieldSync.label.replace(
                      /:$/,
                      ""
                    )} field's value to select.</b>`
                  )
                : setAlertMessage(
                    `<b>Select ${nestedField()} field's value first.</b>`
                  );
              setPopup("alert");
            } else if (
              props.properties.choiceFieldSync.linkType == "linked_child"
            ) {
              setAlertMessage("<b>This field is linked to another field</b>");
              setPopup("alert");
            } else {
              if (selectedDropDown() == "") {
                setIsFormDropDownOpen(true);
                setSelectedDropDown(props.properties.dataFieldSync.uid);
              } else {
                setIsFormDropDownOpen(false);
                setSelectedDropDown("");
              }
            }
          } else {
            multipleSelectField() || event.shiftKey
              ? String(props.properties.dataFieldSync.parentTableId) == "0"
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : ""
              : updatedCurrentField(props.properties.dataFieldSync.uid);
          }
        }}
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? String(props.properties.dataFieldSync.parentTableId) == "0"
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : ""
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      >
        <div class="dropdown-div ff-Helvetica">
          {(props.fieldsType == "render" || props.fieldsType == "locked") &&
          preLoadData()[props.properties.dataFieldSync.uid] != "" &&
          preLoadData()[props.properties.dataFieldSync.uid] != "-1"
            ? props.properties.choiceFieldSync.linkType == "linked_nested"
              ? additionalData()[props.properties.dataFieldSync.uid]
                  ?.display_value
              : props.properties.choiceFieldSync.items.filter(
                  (item: any) =>
                    Number(item.id) ==
                    Number(preLoadData()[props.properties.dataFieldSync.uid])
                )[0]?.title
            : (formJson.currentField?.inputType == "calculated" ||
                formJson.currentField?.dateInputType == "calculated") &&
              props.fieldsType == "designer" &&
              calculatedArr()[props.properties.dataFieldSync.uid]
            ? calculatedArr()[props.properties.dataFieldSync.uid]
            : ""}
        </div>
        <Icons name="chevronDown" stroke="#6c6e6f"></Icons>
      </div>
      <Show
        when={
          props.fieldsType != "preview" &&
          selectedDropDown() == props.properties.dataFieldSync.uid
        }
      >
        <Dropdown
          list={
            nestedFieldData()?.length > 0 &&
            props.properties.choiceFieldSync.linkType == "linked_nested"
              ? nestedFieldData()
              : props.properties.choiceFieldSync.items
          }
          title=""
          data={props}
          cssProperties={fieldProperties}
          setValue={(val: any) => {
            setSelectedDropDown("");
            setIsFormDropDownOpen(false);
            formJson.pages.forEach((page: any) => {
              page.fields?.forEach((field: any) => {
                if (
                  props.properties.dataFieldSync.uid ==
                    field.properties.dataFieldSync.linkedFieldId &&
                  field.properties.choiceFieldSync?.nestedListString
                ) {
                  if (
                    field.properties.choiceFieldSync.linkType == "linked_nested"
                  ) {
                    updateFormRecords(field.properties.dataFieldSync.uid, "");
                  }
                  JSON.parse(
                    field.properties.choiceFieldSync.nestedListString
                  ).forEach((item: any) => {
                    if (
                      item.title ==
                        props.properties.choiceFieldSync.items?.find(
                          (item: any) => item.id === val || item.title === val
                        ).id ||
                      item.title ==
                        props.properties.choiceFieldSync.items?.find(
                          (item: any) => item.id === val || item.title === val
                        ).title
                    ) {
                      setNestedFieldData(
                        item.subTitles
                          .filter((title: any) => title)
                          .map((obj: any) => ({
                            id: obj.uuid,
                            title: obj.subTitle,
                          }))
                      );
                    }
                  });
                }
              });
            });
            if (props.properties.choiceFieldSync?.linkType == "linked_nested") {
              const value: any = {
                display_value: JSON.parse(
                  props.properties.choiceFieldSync.nestedListString
                )
                  .flatMap((list: any) => list.subTitles)
                  ?.find((item: any) => item.uuid == val)?.subTitle,
              };
              const dataArr: any = { ...additionalData() };
              dataArr[props.properties.dataFieldSync.uid] = value;
              setAdditionalData(dataArr);
              updateFormRecords(
                props.properties.dataFieldSync.uid,
                val,
                false,
                false,
                value
              );
            }
            if (
              props.properties.choiceFieldSync?.linkType != "linked_child" &&
              props.properties.choiceFieldSync?.linkType != "linked_nested"
            ) {
              const value: any = {
                display_value: props.properties.choiceFieldSync.items.find(
                  (item: any) => item.id == val
                )?.title,
              };
              const dataArr: any = { ...additionalData() };
              dataArr[props.properties.dataFieldSync.uid] = value;
              setAdditionalData(dataArr);
              updateFormRecords(
                props.properties.dataFieldSync.uid,
                String(val),
                false,
                false,
                value
              );
            }
            if (
              formJson.pages.flatMap((page: any) =>
                page.fields?.filter(
                  (field: any) =>
                    field.properties.inputType == "calculated" &&
                    field.properties.calculationFormula != ""
                )
              ).length > 0
            ) {
              updatedCalulatedFieldvalue();
            }
          }}
        ></Dropdown>
      </Show>
    </>
  );
};

export { nestedFieldData, selectedDropDownId };
export default DropdownField;
