import {
  Component,
  createSignal,
  onMount,
  Show,
  useTransition,
} from "solid-js";
import { A, useNavigate, useRouteData } from "solid-start";
import {
  createServerAction$,
  createServerData$,
  redirect,
} from "solid-start/server";
import { Transition } from "solid-transition-group";
import { getUser, logout } from "~/db/session";
import Dropdown from "../global/Dropdown";
import {
  jwtToken,
  loginUserName,
  userAvatar,
  userId,
  userNotifications,
  userRole,
} from "~/store/user-form-role";
import {
  getInitails,
  popupQueue,
  selectedDropDown,
  setIsFormDropDownOpen,
  setPopup,
  setSelectedDropDown,
  setSidebarOpen,
  sidebarOpen,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import { trialLeft } from "~/store/trial";
import TrialEndPopup from "../popup/TrialEndPopup";

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    return userDetails;
  });
}

const [isDropdownOpen, setIsDropdownOpen] = createSignal(false as boolean);

const Navbar: Component = () => {
  const data = useRouteData<typeof routeData>();
  const navigate = useNavigate();
  const loggedInStatus = data == undefined ? false : true;
  const [loggedIn] = createSignal(loggedInStatus as boolean);
  const [secondNotificationTop, setSecondNotificationTop] = createSignal(
    0 as number
  );
  const [isFirstNotificationVisibal, setIsFirstNotificationVisibal] =
    createSignal(false as boolean);
  const [isSecondNotificationVisibal, setIsSecondNotificationVisibal] =
    createSignal(false as boolean);
  const [, { Form }] = createServerAction$(
    async (form: FormData, { request }) => {
      const userId: any = form.get("user_id");
      const jwtToken: any = form.get("jwt_token");
      return await logout(request, userId, jwtToken);
    }
  );
  const [pending, start] = useTransition();

  onMount(async () => {
    if (localStorage.getItem("is_first_notification_visibal") == "false") {
      setIsFirstNotificationVisibal(false);
    } else {
      setIsFirstNotificationVisibal(true);
    }
    setTimeout(() => {
      const elm: any = document.querySelector(".global");
      if (elm) {
        setSecondNotificationTop(elm.offsetHeight + 3);
      }
      if (localStorage.getItem("is_second_notification_visibal") == "false") {
        setIsSecondNotificationVisibal(false);
      } else {
        setIsSecondNotificationVisibal(true);
      }
    }, 0);
  });

  function hideNotification(type: string) {
    if (type == "first") {
      localStorage.setItem("is_first_notification_visibal", "false");
      setSecondNotificationTop(0);
      setIsFirstNotificationVisibal(false);
    } else {
      localStorage.setItem("is_second_notification_visibal", "false");
      setIsSecondNotificationVisibal(false);
    }
  }

  const profileItems = [
    // { title: "Dashboard", link: "/form/list" },
    { title: "Profile", link: "/my-profile" },
    { title: "Settings", link: "/user-settings" },
    { title: "Log out", link: "/logout" },
  ];

  return (
    <>
      <Show when={popupQueue.includes("TrialEnd")}>
        <TrialEndPopup
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></TrialEndPopup>
      </Show>
      <Show when={userNotifications()?.length > 0}>
        <Show when={isFirstNotificationVisibal()}>
          <div class="notification-bar global">
            <div
              class="content"
              innerHTML={userNotifications()[0]?.message}
            ></div>
            <button
              class="btn btn-ico"
              onclick={() => hideNotification("first")}
            >
              <Icons name="x" stroke="#2e61a3"></Icons>
            </button>
          </div>
        </Show>
        <Show
          when={
            isSecondNotificationVisibal() && userNotifications()?.length > 1
          }
        >
          <div
            class="notification-bar second"
            style={{ "--top": secondNotificationTop() + "px" }}
          >
            <div
              class="content"
              innerHTML={userNotifications()[1]?.message}
            ></div>
            <button
              class="btn btn-ico"
              onclick={() => hideNotification("second")}
            >
              <Icons name="x" stroke="#2e61a3"></Icons>
            </button>
          </div>
        </Show>
      </Show>
      <nav
        id="navbar_profile"
        class="navbar"
        onclick={(e: any) => {
          const element: any = document.getElementById("profilePic");
          if (element?.contains(e.target)) {
            selectedDropDown() == ""
              ? setSelectedDropDown("profile")
              : setSelectedDropDown("");
          } else {
            setSelectedDropDown("");
            setIsFormDropDownOpen(false);
          }
        }}
      >
        <div class="logo">
          <A href="/form/list">
            <img src="/Form-Connect.png" class="thumbnail sm" />
          </A>
        </div>
        <Show when={!loggedIn()}>
          <ul class="menu-list">
            <li class="menu-item">
              <A class="menu-link type-link" href="/">
                Home
              </A>
            </li>
            <li class="menu-item">
              <A class="menu-link type-link" href="/about">
                About
              </A>
            </li>
            <li class="menu-item">
              <A class="menu-link type-link" href="/features">
                Features
              </A>
            </li>
            <li class="menu-item">
              <A class="menu-link type-link" href="/pricing">
                Pricing
              </A>
            </li>
            <li class="menu-item">
              <Form>
                <input type="hidden" name="user_id" value={userId()} />
                <input type="hidden" name="jwt_token" value={jwtToken()} />
                <button
                  class="non-btn menu-link type-link"
                  name="logout"
                  type="submit"
                >
                  Log out
                </button>
              </Form>
            </li>
          </ul>
        </Show>
        <Show when={loggedIn()}>
          <div class="flex gap align-center h-100p">
            <Show when={trialLeft() > 0}>
              <div
                class="badge trial lbl cur-p"
                onclick={() => {
                  setPopup("TrialEnd");
                }}
              >
                Trial Ends in {trialLeft()} Days
              </div>
            </Show>
            <div class="profile-avatar">
              <div class="flex app-flex gap align-center">
                <Show when={userAvatar()}>
                  <img
                    src={userAvatar()}
                    class="profile-pic"
                    id="profilePic"
                    classList={{ pending: pending() }}
                    tabIndex={0}
                  />
                </Show>
                <Show when={!userAvatar()}>
                  <div class="profile-pic initials" id="profilePic">
                    {loginUserName() ? getInitails(loginUserName()) : ""}
                  </div>
                </Show>
                <Transition name="slide-fade">
                  <Show when={selectedDropDown() == "profile" && data()}>
                    <Dropdown
                      list={
                        userRole() == "admin"
                          ? profileItems
                          : profileItems.filter(
                              (item: any) => item.title != "Settings"
                            )
                      }
                      title="title"
                      link="link"
                      selectedValue=""
                      class="app-r-42"
                    />
                  </Show>
                </Transition>
                <div
                  class="sidebar-menu"
                  onclick={() =>
                    sidebarOpen() ? setSidebarOpen(false) : setSidebarOpen(true)
                  }
                >
                  <Icons name="menu" width={26} height={26}></Icons>
                </div>
              </div>
            </div>
          </div>
        </Show>
      </nav>
    </>
  );
};

export { isDropdownOpen, setIsDropdownOpen };
export default Navbar;
