const localList: any = [
  { label: "Afrikaans (Namibia)", value: "af_NA", currency: "NAD" },
  { label: "Afrikaans (South Africa)", value: "af_ZA", currency: "ZAR" },
  { label: "Aghem (Cameroon)", value: "agq_CM", currency: "XAF" },
  { label: "Ainu (Japan)", value: "ain_JP", currency: "JPY" },
  { label: "Akan (Ghana)", value: "ak_GH", currency: "GHC" },
  { label: "Albanian (Albania)", value: "sq_AL", currency: "ALL" },
  { label: "Albanian (Kosovo)", value: "sq_XK", currency: "EUR" },
  { label: "Albanian (North Macedonia)", value: "sq_MK", currency: "MKD" },
  { label: "Amharic (Ethiopia)", value: "am_ET", currency: "ETB" },
  {
    label: "Apache, Western (United States)",
    value: "apw_US",
    currency: "USS",
  },
  { label: "Arabic (Algeria)", value: "ar_DZ", currency: "DZD" },
  { label: "Arabic (Bahrain)", value: "ar_BH", currency: "BHD" },
  { label: "Arabic (Chad)", value: "ar_TD", currency: "XAF" },
  { label: "Arabic (Comoros)", value: "ar_KM", currency: "KMF" },
  { label: "Arabic (Djibouti)", value: "ar_DJ", currency: "DJF" },
  { label: "Arabic (Egypt)", value: "ar_EG", currency: "EGP" },
  { label: "Arabic (Eritrea)", value: "ar_ER", currency: "ERN" },
  { label: "Arabic (Iraq)", value: "ar_IQ", currency: "IQD" },
  { label: "Arabic (Israel)", value: "ar_IL", currency: "ILS" },
  { label: "Arabic (Jordan)", value: "ar_JO", currency: "JOD" },
  { label: "Arabic (Kuwait)", value: "ar_KW", currency: "KWD" },
  { label: "Arabic (Lebanon)", value: "ar_LB", currency: "LBP" },
  { label: "Arabic (Libya)", value: "ar_LY", currency: "LYD" },
  { label: "Arabic (Mauritania)", value: "ar_MR", currency: "MRO" },
  { label: "Arabic (Morocco)", value: "ar_MA", currency: "MAD" },
  { label: "Arabic (Oman)", value: "ar_OM", currency: "OMR" },
  {
    label: "Arabic (Palestinian Territories)",
    value: "ar_PS",
    currency: "EUR",
  },
  { label: "Arabic (Qatar)", value: "ar_QA", currency: "QAR" },
  { label: "Arabic (Saudi Arabia)", value: "ar_SA", currency: "SAR" },
  { label: "Arabic (Somalia)", value: "ar_SO", currency: "SOS" },
  { label: "Arabic (South Sudan)", value: "ar_SS", currency: "SSP" },
  { label: "Arabic (Sudan)", value: "ar_SD", currency: "SDG" },
  { label: "Arabic (Syria)", value: "ar_SY", currency: "SYP" },
  { label: "Arabic (Tunisia)", value: "ar_TN", currency: "TND" },
  { label: "Arabic (United Arab Emirates)", value: "ar_AE", currency: "AED" },
  { label: "Arabic (Western Sahara)", value: "ar_EH", currency: "MAD" },
  { label: "Arabic (Yemen)", value: "ar_YE", currency: "YER" },
  { label: "Armenian (Armenia)", value: "hy_AM", currency: "AMD" },
  { label: "Assamese (India)", value: "as_IN", currency: "INR" },
  { label: "Assyrian (Iraq)", value: "syr_IQ", currency: "IQD" },
  { label: "Assyrian (Syria)", value: "syr_SY", currency: "SYP" },
  { label: "Asturian (Spain)", value: "ast_ES", currency: "EUR" },
  { label: "Asu (Tanzania)", value: "asa_TZ", currency: "TZS" },
  {
    label: "Azerbaijani (Cyrillic, Azerbaijan)",
    value: "az_Cyrl_AZ",
    currency: "AZN",
  },
  {
    label: "Azerbaijani (Latin, Azerbaijan)",
    value: "az_Latn_AZ",
    currency: "AZN",
  },
  { label: "Bafia (Cameroon)", value: "ksf_CM", currency: "XAF" },
  { label: "Bambara (Mali)", value: "bm_ML", currency: "XOF" },
  { label: "Bangla (Bangladesh)", value: "bn_BD", currency: "BDT" },
  { label: "Bangla (India)", value: "bn_IN", currency: "INR" },
  { label: "Basaa (Cameroon)", value: "bas_CM", currency: "XAF" },
  { label: "Bashkir (Russia)", value: "ba_RU", currency: "RUB" },
  { label: "Basque (Spain)", value: "eu_ES", currency: "EUR" },
  { label: "Belarusian (Belarus)", value: "be_BY", currency: "BYR" },
  { label: "Bemba (Zambia)", value: "bem_ZM", currency: "ZMW" },
  { label: "Bena (Tanzania)", value: "bez_TZ", currency: "TZS" },
  { label: "Blin (Eritrea)", value: "byn_ER", currency: "ERN" },
  { label: "Bodo (India)", value: "brx_IN", currency: "INR" },
  {
    label: "Bosnian (Cyrillic, Bosnia & Herzegovina)",
    value: "bs_Cyrl_BA",
    currency: "BAM",
  },
  {
    label: "Bosnian (Latin, Bosnia & Herzegovina)",
    value: "bs_Latn_BA",
    currency: "BAM",
  },
  { label: "Breton (France)", value: "br_FR", currency: "EUR" },
  { label: "Bulgarian (Bulgaria)", value: "bg_BG", currency: "BGN" },
  { label: "Burmese (Myanmar [Burma])", value: "my_MM", currency: "MMK" },
  {
    label: "Cantonese, Simplified (China mainland)",
    value: "yue_Hans_CN",
    currency: "CNY",
  },
  {
    label: "Cantonese, Traditional (Hong Kong)",
    value: "yue_Hant_HK",
    currency: "HKD",
  },
  { label: "Catalan (Andorra)", value: "ca_AD", currency: "EUR" },
  { label: "Catalan (France)", value: "ca_FR", currency: "EUR" },
  { label: "Catalan (Italy)", value: "ca_IT", currency: "EUR" },
  { label: "Catalan (Spain)", value: "ca_ES", currency: "EUR" },
  { label: "Cebuano (Philippines)", value: "ceb_PH", currency: "PHP" },
  {
    label: "Central Atlas Tamazight (Morocco)",
    value: "tzm_MA",
    currency: "MAD",
  },
  { label: "Chakma (Bangladesh)", value: "ccp_BD", currency: "BDT" },
  { label: "Chakma (India)", value: "ccp_IN", currency: "INR" },
  { label: "Chechen (Russia)", value: "ce_RU", currency: "RUB" },
  { label: "Cherokee (United States)", value: "chr_US", currency: "USS" },
  { label: "Chickasaw (United States)", value: "cic_US", currency: "USS" },
  { label: "Chiga (Uganda)", value: "cgg_UG", currency: "UGX" },
  {
    label: "Chinese, Simplified (China mainland)",
    value: "zh_Hans_CN",
    currency: "CNY",
  },
  {
    label: "Chinese, Simplified (Hong Kong)",
    value: "zh_Hans_HK",
    currency: "HKD",
  },
  {
    label: "Chinese, Simplified (Japan)",
    value: "zh_Hans_JP",
    currency: "JPY",
  },
  {
    label: "Chinese, Simplified (Macao)",
    value: "zh_Hans_MO",
    currency: "MOP",
  },
  {
    label: "Chinese, Simplified (Singapore)",
    value: "zh_Hans_SG",
    currency: "SGD",
  },
  {
    label: "Chinese, Traditional (China mainland)",
    value: "zh_Hant_CN",
    currency: "TWD",
  },
  {
    label: "Chinese, Traditional (Hong Kong)",
    value: "zh_Hant_HK",
    currency: "HKD",
  },
  {
    label: "Chinese, Traditional (Macao)",
    value: "zh_Hant_MO",
    currency: "MOP",
  },
  {
    label: "Chinese, Traditional (Taiwan)",
    value: "zh_Hant_TW",
    currency: "TWD",
  },
  { label: "Choctaw (United States)", value: "cho_US", currency: "USS" },
  { label: "Chuvash (Russia)", value: "cv_RU", currency: "RUB" },
  { label: "Colognian (Germany)", value: "ksh_DE", currency: "EUR" },
  { label: "Cornish (United Kingdom)", value: "kw_GB", currency: "GBP" },
  { label: "Corsican (France)", value: "co_FR", currency: "EUR" },
  { label: "Croatian (Bosnia & Herzegovina)", value: "hr_BA", currency: "BAM" },
  { label: "Croatian (Croatia)", value: "hr_HR", currency: "CZK" },
  { label: "Czech (Czechia)", value: "cs_CZ", currency: "CZK" },
  { label: "Danish (Denmark)", value: "da_DK", currency: "DKK" },
  { label: "Danish (Greenland)", value: "da_GL", currency: "DKK" },
  { label: "Dhivehi (Maldives)", value: "dv_MV", currency: "MVR" },
  { label: "Dogri (India)", value: "doi_IN", currency: "INR" },
  { label: "Duala (Cameroon)", value: "dua_CM", currency: "XAF" },
  { label: "Dutch (Aruba)", value: "nl_AW", currency: "AWG" },
  { label: "Dutch (Belgium)", value: "nl_BE", currency: "EUR" },
  { label: "Dutch (Caribbean Netherlands)", value: "nl_BQ", currency: "EUR" },
  { label: "Dutch (Curaçao)", value: "nl_CW", currency: "ANG" },
  { label: "Dutch (Netherlands)", value: "nl_NL", currency: "EUR" },
  { label: "Dutch (Sint Maarten)", value: "nl_SX", currency: "ANG" },
  { label: "Dutch (Suriname)", value: "nl_SR", currency: "SRG" },
  { label: "Dzongkha (Bhutan)", value: "dz_BT", currency: "BTN" },
  { label: "Embu (Kenya)", value: "ebu_KE", currency: "KES" },
  { label: "English (Albania)", value: "en_AL", currency: "ALL" },
  { label: "English (American Samoa)", value: "en_AS", currency: "USD" },
  { label: "English (Anguilla)", value: "en_AI", currency: "XCD" },
  { label: "English (Antigua & Barbuda)", value: "en_AG", currency: "XCD" },
  { label: "English (Argentina)", value: "en_AR", currency: "ARS" },
  { label: "English (Australia)", value: "en_AU", currency: "AUD" },
  { label: "English (Austria)", value: "en_AT", currency: "EUR" },
  { label: "English (Bahamas)", value: "en_BS", currency: "BSD" },
  { label: "English (Bangladesh)", value: "en_BD", currency: "BDT" },
  { label: "English (Barbados)", value: "en_BB", currency: "BBD" },
  { label: "English (Belgium)", value: "en_BE", currency: "EUR" },
  { label: "English (Belize)", value: "en_BZ", currency: "BZD" },
  { label: "English (Bermuda)", value: "en_BM", currency: "BMD" },
  { label: "English (Botswana)", value: "en_BW", currency: "BWP" },
  { label: "English (Brazil)", value: "en_BR", currency: "BRL" },
  {
    label: "English (British Virgin Islands)",
    value: "en_VG",
    currency: "USD",
  },
  { label: "English (Brunei)", value: "en_BN", currency: "BND" },
  { label: "English (Bulgaria)", value: "en_BG", currency: "BGN" },
  { label: "English (Burundi)", value: "en_BI", currency: "BIF" },
  { label: "English (Cameroon)", value: "en_CM", currency: "XAF" },
  { label: "English (Canada)", value: "en_CA", currency: "CAD" },
  { label: "English (Cape Verde)", value: "en_CV", currency: "CVE" },
  { label: "English (Cayman Islands)", value: "en_KY", currency: "KYD" },
  { label: "English (Chagos Archipelago)", value: "en_IO", currency: "USD" },
  { label: "English (Chile)", value: "en_CL", currency: "CLP" },
  { label: "English (China mainland)", value: "en_CN", currency: "CNY" },
  { label: "English (Christmas Island)", value: "en_CX", currency: "AUD" },
  {
    label: "English (Cocos [Keeling] Islands)",
    value: "en_CC",
    currency: "AUD",
  },
  { label: "English (Colombia)", value: "en_CO", currency: "COP" },
  { label: "English (Cook Islands)", value: "en_CK", currency: "NZD" },
  { label: "English (Cyprus)", value: "en_CY", currency: "TRY" },
  { label: "English (Czechia)", value: "en_CZ", currency: "CZK" },
  { label: "English (Denmark)", value: "en_DK", currency: "DKK" },
  { label: "English (Diego Garcia)", value: "en_DG", currency: "USD" },
  { label: "English (Dominica)", value: "en_DM", currency: "XCD" },
  { label: "English (Eritrea)", value: "en_ER", currency: "ERN" },
  { label: "English (Estonia)", value: "en_EE", currency: "EUR" },
  { label: "English (Eswatini)", value: "en_SZ", currency: "SZL" },
  { label: "English (Falkland Islands)", value: "en_FK", currency: "FKP" },
  { label: "English (Fiji)", value: "en_FJ", currency: "FJD" },
  { label: "English (Finland)", value: "en_FI", currency: "EUR" },
  { label: "English (France)", value: "en_FR", currency: "EUR" },
  { label: "English (Gambia)", value: "en_GM", currency: "GMD" },
  { label: "English (Germany)", value: "en_DE", currency: "EUR" },
  { label: "English (Ghana)", value: "en_GH", currency: "GHC" },
  { label: "English (Gibraltar)", value: "en_GI", currency: "GIP" },
  { label: "English (Greece)", value: "en_GR", currency: "EUR" },
  { label: "English (Grenada)", value: "en_GD", currency: "XCD" },
  { label: "English (Guam)", value: "en_GU", currency: "USD" },
  { label: "English (Guernsey)", value: "en_GG", currency: "GBP" },
  { label: "English (Guyana)", value: "en_GY", currency: "GYD" },
  { label: "English (Hong Kong)", value: "en_HK", currency: "HKD" },
  { label: "English (Hungary)", value: "en_HU", currency: "HUF" },
  { label: "English (India)", value: "en_IN", currency: "INR" },
  { label: "English (Indonesia)", value: "en_ID", currency: "IDR" },
  { label: "English (Ireland)", value: "en_IE", currency: "EUR" },
  { label: "English (Isle of Man)", value: "en_IM", currency: "GBP" },
  { label: "English (Israel)", value: "en_IL", currency: "ILS" },
  { label: "English (Jamaica)", value: "en_JM", currency: "JMD" },
  { label: "English (Japan)", value: "en_JP", currency: "JPY" },
  { label: "English (Jersey)", value: "en_JE", currency: "GBP" },
  { label: "English (Kenya)", value: "en_KE", currency: "KES" },
  { label: "English (Kiribati)", value: "en_KI", currency: "AUD" },
  { label: "English (Latvia)", value: "en_LV", currency: "EUR" },
  { label: "English (Lesotho)", value: "en_LS", currency: "LSL" },
  { label: "English (Liberia)", value: "en_LR", currency: "LRD" },
  { label: "English (Lithuania)", value: "en_LT", currency: "EUR" },
  { label: "English (Macao)", value: "en_MO", currency: "MOP" },
  { label: "English (Madagascar)", value: "en_MG", currency: "MGF" },
  { label: "English (Malawi)", value: "en_MW", currency: "MWK" },
  { label: "English (Malaysia)", value: "en_MY", currency: "MYR" },
  { label: "English (Maldives)", value: "en_MV", currency: "MVR" },
  { label: "English (Malta)", value: "en_MT", currency: "MTL" },
  { label: "English (Marshall Islands)", value: "en_MH", currency: "USD" },
  { label: "English (Mauritius)", value: "en_MU", currency: "MUR" },
  { label: "English (Mexico)", value: "en_MX", currency: "MXN" },
  { label: "English (Micronesia)", value: "en_FM", currency: "USD" },
  { label: "English (Montserrat)", value: "en_MS", currency: "XCD" },
  { label: "English (Myanmar [Burma])", value: "en_MM", currency: "MMK" },
  { label: "English (Namibia)", value: "en_NA", currency: "NAD" },
  { label: "English (Nauru)", value: "en_NR", currency: "AUD" },
  { label: "English (Netherlands)", value: "en_NL", currency: "EUR" },
  { label: "English (New Zealand)", value: "en_NZ", currency: "NZD" },
  { label: "English (Nigeria)", value: "en_NG", currency: "NGN" },
  { label: "English (Niue)", value: "en_NU", currency: "NZD" },
  { label: "English (Norfolk Island)", value: "en_NF", currency: "AUD" },
  {
    label: "English (Northern Mariana Islands)",
    value: "en_MP",
    currency: "USD",
  },
  { label: "English (Norway)", value: "en_NO", currency: "NOK" },
  { label: "English (Pakistan)", value: "en_PK", currency: "PKR" },
  { label: "English (Palau)", value: "en_PW", currency: "USD" },
  { label: "English (Papua New Guinea)", value: "en_PG", currency: "PGK" },
  { label: "English (Philippines)", value: "en_PH", currency: "PHP" },
  { label: "English (Pitcairn Islands)", value: "en_PN", currency: "NZD" },
  { label: "English (Poland)", value: "en_PL", currency: "PLN" },
  { label: "English (Portugal)", value: "en_PT", currency: "EUR" },
  { label: "English (Puerto Rico)", value: "en_PR", currency: "USD" },
  { label: "English (Russia)", value: "en_RU", currency: "RUB" },
  { label: "English (Rwanda)", value: "en_RW", currency: "RWF" },
  { label: "English (Samoa)", value: "en_WS", currency: "WST" },
  { label: "English (Saudi Arabia)", value: "en_SA", currency: "SAR" },
  { label: "English (Seychelles)", value: "en_SC", currency: "SCR" },
  { label: "English (Sierra Leone)", value: "en_SL", currency: "SLL" },
  { label: "English (Singapore)", value: "en_SG", currency: "SGD" },
  { label: "English (Sint Maarten)", value: "en_SX", currency: "ANG" },
  { label: "English (Slovakia)", value: "en_SK", currency: "EUR" },
  { label: "English (Slovenia)", value: "en_SI", currency: "EUR" },
  { label: "English (Solomon Islands)", value: "en_SB", currency: "SBD" },
  { label: "English (South Africa)", value: "en_ZA", currency: "ZAR" },
  { label: "English (South Korea)", value: "en_KR", currency: "KRW" },
  { label: "English (South Sudan)", value: "en_SS", currency: "SSP" },
  { label: "English (St. Helena)", value: "en_SH", currency: "SHP" },
  { label: "English (St. Kitts & Nevis)", value: "en_KN", currency: "XCD" },
  { label: "English (St. Lucia)", value: "en_LC", currency: "XCD" },
  {
    label: "English (St. Vincent & Grenadines)",
    value: "en_VC",
    currency: "XCD",
  },
  { label: "English (Sudan)", value: "en_SD", currency: "SDG" },
  { label: "English (Sweden)", value: "en_SE", currency: "SEK" },
  { label: "English (Switzerland)", value: "en_CH", currency: "CHF" },
  { label: "English (Taiwan)", value: "en_TW", currency: "TWD" },
  { label: "English (Tanzania)", value: "en_TZ", currency: "TZS" },
  { label: "English (Thailand)", value: "en_TH", currency: "THB" },
  { label: "English (Tokelau)", value: "en_TK", currency: "NZD" },
  { label: "English (Tonga)", value: "en_TO", currency: "TOP" },
  { label: "English (Trinidad & Tobago)", value: "en_TT", currency: "TTD" },
  {
    label: "English (Turks & Caicos Islands)",
    value: "en_TC",
    currency: "USD",
  },
  { label: "English (Tuvalu)", value: "en_TV", currency: "AUD" },
  { label: "English (Türkiye)", value: "en_TR", currency: "TRY" },
  { label: "English (US Outlying Islands)", value: "en_UM", currency: "USD" },
  { label: "English (US Virgin Islands)", value: "en_VI", currency: "USD" },
  { label: "English (Uganda)", value: "en_UG", currency: "UGX" },
  { label: "English (Ukraine)", value: "en_UA", currency: "UAH" },
  { label: "English (United Arab Emirates)", value: "en_AE", currency: "AED" },
  { label: "English (United Kingdom)", value: "en_GB", currency: "GBP" },
  { label: "English (United States)", value: "en_US", currency: "USD" },
  {
    label: "English (United States, Computer)",
    value: "en_US_POSIX",
    currency: "USS",
  },
  { label: "English (Vanuatu)", value: "en_VU", currency: "VUV" },
  { label: "English (Zambia)", value: "en_ZM", currency: "ZMW" },
  { label: "English (Zimbabwe)", value: "en_ZW", currency: "ZWD" },
  { label: "Erzya (Russia)", value: "myv_RU", currency: "RUB" },
  { label: "Estonian (Estonia)", value: "et_EE", currency: "EUR" },
  { label: "Ewe (Ghana)", value: "ee_GH", currency: "GHC" },
  { label: "Ewe (Togo)", value: "ee_TG", currency: "XOF" },
  { label: "Ewondo (Cameroon)", value: "ewo_CM", currency: "XAF" },
  { label: "Faroese (Denmark)", value: "fo_DK", currency: "DKK" },
  { label: "Faroese (Faroe Islands)", value: "fo_FO", currency: "FOK" },
  { label: "Filipino (Philippines)", value: "fil_PH", currency: "PHP" },
  { label: "Finnish (Finland)", value: "fi_FI", currency: "EUR" },
  { label: "French (Algeria)", value: "fr_DZ", currency: "DZD" },
  { label: "French (Belgium)", value: "fr_BE", currency: "EUR" },
  { label: "French (Benin)", value: "fr_BJ", currency: "XOF" },
  { label: "French (Burkina Faso)", value: "fr_BF", currency: "XOF" },
  { label: "French (Burundi)", value: "fr_BI", currency: "BIF" },
  { label: "French (Cameroon)", value: "fr_CM", currency: "XAF" },
  { label: "French (Canada)", value: "fr_CA", currency: "CAD" },
  {
    label: "French (Central African Republic)",
    value: "fr_CF",
    currency: "XAF",
  },
  { label: "French (Chad)", value: "fr_TD", currency: "XAF" },
  { label: "French (Comoros)", value: "fr_KM", currency: "KMF" },
  { label: "French (Congo - Brazzaville)", value: "fr_CG", currency: "AUD" },
  { label: "French (Congo - Kinshasa)", value: "fr_CD", currency: "AUD" },
  { label: "French (Côte d’Ivoire)", value: "fr_CI", currency: "XOF" },
  { label: "French (Djibouti)", value: "fr_DJ", currency: "DJF" },
  { label: "French (Equatorial Guinea)", value: "fr_GQ", currency: "XAF" },
  { label: "French (France)", value: "fr_FR", currency: "EUR" },
  { label: "French (French Guiana)", value: "fr_GF", currency: "EUR" },
  { label: "French (French Polynesia)", value: "fr_PF", currency: "XPF" },
  { label: "French (Gabon)", value: "fr_GA", currency: "XAF" },
  { label: "French (Guadeloupe)", value: "fr_GP", currency: "EUR" },
  { label: "French (Guinea)", value: "fr_GN", currency: "GNF" },
  { label: "French (Haiti)", value: "fr_HT", currency: "HTG" },
  { label: "French (Luxembourg)", value: "fr_LU", currency: "EUR" },
  { label: "French (Madagascar)", value: "fr_MG", currency: "MGF" },
  { label: "French (Mali)", value: "fr_ML", currency: "XOF" },
  { label: "French (Martinique)", value: "fr_MQ", currency: "EUR" },
  { label: "French (Mauritania)", value: "fr_MR", currency: "MRO" },
  { label: "French (Mauritius)", value: "fr_MU", currency: "MUR" },
  { label: "French (Mayotte)", value: "fr_YT", currency: "EUR" },
  { label: "French (Monaco)", value: "fr_MC", currency: "EUR" },
  { label: "French (Morocco)", value: "fr_MA", currency: "MAD" },
  { label: "French (New Caledonia)", value: "fr_NC", currency: "XPF" },
  { label: "French (Niger)", value: "fr_NE", currency: "XOF" },
  { label: "French (Réunion)", value: "fr_RE", currency: "EUR" },
  { label: "French (Rwanda)", value: "fr_RW", currency: "RWF" },
  { label: "French (Senegal)", value: "fr_SN", currency: "XOF" },
  { label: "French (Seychelles)", value: "fr_SC", currency: "SCR" },
  { label: "French (St. Barthélemy)", value: "fr_BL", currency: "EUR" },
  { label: "French (St. Martin)", value: "fr_MF", currency: "EUR" },
  { label: "French (St. Pierre & Miquelon)", value: "fr_PM", currency: "EUR" },
  { label: "French (Switzerland)", value: "fr_CH", currency: "CHF" },
  { label: "French (Syria)", value: "fr_SY", currency: "SYP" },
  { label: "French (Togo)", value: "fr_TG", currency: "XOF" },
  { label: "French (Tunisia)", value: "fr_TN", currency: "TND" },
  { label: "French (Vanuatu)", value: "fr_VU", currency: "VUV" },
  { label: "French (Wallis & Futuna)", value: "fr_WF", currency: "XPF" },
  { label: "Friulian (Italy)", value: "fur_IT", currency: "EUR" },
  { label: "Fula (Adlam, Burkina Faso)", value: "ff_Adlm_BF", currency: "XOF" },
  { label: "Fula (Adlam, Cameroon)", value: "ff_Adlm_CM", currency: "XAF" },
  { label: "Fula (Adlam, Gambia)", value: "ff_Adlm_GM", currency: "GMD" },
  { label: "Fula (Adlam, Ghana)", value: "ff_Adlm_GH", currency: "GHC" },
  { label: "Fula (Adlam, Guinea)", value: "ff_Adlm_GN", currency: "GNF" },
  {
    label: "Fula (Adlam, Guinea-Bissau)",
    value: "ff_Adlm_GW",
    currency: "GWP",
  },
  { label: "Fula (Adlam, Liberia)", value: "ff_Adlm_LR", currency: "LRD" },
  { label: "Fula (Adlam, Mauritania)", value: "ff_Adlm_MR", currency: "MRO" },
  { label: "Fula (Adlam, Niger)", value: "ff_Adlm_NE", currency: "XOF" },
  { label: "Fula (Adlam, Nigeria)", value: "ff_Adlm_NG", currency: "NGN" },
  { label: "Fula (Adlam, Senegal)", value: "ff_Adlm_SN", currency: "XOF" },
  { label: "Fula (Adlam, Sierra Leone)", value: "ff_Adlm_SL", currency: "SLL" },
  { label: "Fula (Latin, Burkina Faso)", value: "ff_Latn_BF", currency: "XOF" },
  { label: "Fula (Latin, Cameroon)", value: "ff_Latn_CM", currency: "XAF" },
  { label: "Fula (Latin, Gambia)", value: "ff_Latn_GM", currency: "GMD" },
  { label: "Fula (Latin, Ghana)", value: "ff_Latn_GH", currency: "GHC" },
  { label: "Fula (Latin, Guinea)", value: "ff_Latn_GN", currency: "GNF" },
  {
    label: "Fula (Latin, Guinea-Bissau)",
    value: "ff_Latn_GW",
    currency: "GWP",
  },
  { label: "Fula (Latin, Liberia)", value: "ff_Latn_LR", currency: "LRD" },
  { label: "Fula (Latin, Mauritania)", value: "ff_Latn_MR", currency: "MRO" },
  { label: "Fula (Latin, Niger)", value: "ff_Latn_NE", currency: "XOF" },
  { label: "Fula (Latin, Nigeria)", value: "ff_Latn_NG", currency: "NGN" },
  { label: "Fula (Latin, Senegal)", value: "ff_Latn_SN", currency: "XOF" },
  { label: "Fula (Latin, Sierra Leone)", value: "ff_Latn_SL", currency: "SLL" },
  { label: "Ga (Ghana)", value: "gaa_GH", currency: "GHC" },
  { label: "Galician (Spain)", value: "gl_ES", currency: "EUR" },
  { label: "Ganda (Uganda)", value: "lg_UG", currency: "UGX" },
  { label: "Geez (Eritrea)", value: "gez_ER", currency: "ERN" },
  { label: "Geez (Ethiopia)", value: "gez_ET", currency: "ETB" },
  { label: "Georgian (Georgia)", value: "ka_GE", currency: "GEL" },
  { label: "German (Austria)", value: "de_AT", currency: "EUR" },
  { label: "German (Belgium)", value: "de_BE", currency: "EUR" },
  { label: "German (Germany)", value: "de_DE", currency: "EUR" },
  { label: "German (Italy)", value: "de_IT", currency: "EUR" },
  { label: "German (Liechtenstein)", value: "de_LI", currency: "CHF" },
  { label: "German (Luxembourg)", value: "de_LU", currency: "EUR" },
  { label: "German (Switzerland)", value: "de_CH", currency: "CHF" },
  { label: "Greek (Cyprus)", value: "el_CY", currency: "TRY" },
  { label: "Greek (Greece)", value: "el_GR", currency: "EUR" },
  { label: "Guarani (Paraguay)", value: "gn_PY", currency: "PYG" },
  { label: "Gujarati (India)", value: "gu_IN", currency: "INR" },
  { label: "Gusii (Kenya)", value: "guz_KE", currency: "KES" },
  { label: "Hausa (Ghana)", value: "ha_GH", currency: "GHC" },
  { label: "Hausa (Niger)", value: "ha_NE", currency: "XOF" },
  { label: "Hausa (Nigeria)", value: "ha_NG", currency: "NGN" },
  { label: "Hawaiian (United States)", value: "haw_US", currency: "USS" },
  { label: "Hebrew (Israel)", value: "he_IL", currency: "ILS" },
  { label: "Hindi (India)", value: "hi_IN", currency: "INR" },
  { label: "Hindi (Latin, India)", value: "hi_Latn_IN", currency: "INR" },
  { label: "Hungarian (Hungary)", value: "hu_HU", currency: "HUF" },
  { label: "Icelandic (Iceland)", value: "is_IS", currency: "ISK" },
  { label: "Igbo (Nigeria)", value: "ig_NG", currency: "NGN" },
  { label: "Inari Sami (Finland)", value: "smn_FI", currency: "EUR" },
  { label: "Indonesian (Indonesia)", value: "id_ID", currency: "IDR" },
  { label: "Inuktitut (Canada)", value: "iu_CA", currency: "CAD" },
  { label: "Irish (Ireland)", value: "ga_IE", currency: "EUR" },
  { label: "Irish (United Kingdom)", value: "ga_GB", currency: "GBP" },
  { label: "Italian (Italy)", value: "it_IT", currency: "EUR" },
  { label: "Italian (San Marino)", value: "it_SM", currency: "EUR" },
  { label: "Italian (Switzerland)", value: "it_CH", currency: "CHF" },
  { label: "Italian (Vatican City)", value: "it_VA", currency: "EUR" },
  { label: "Japanese (Japan)", value: "ja_JP", currency: "JPY" },
  { label: "Javanese (Indonesia)", value: "jv_ID", currency: "IDR" },
  { label: "Jju (Nigeria)", value: "kaj_NG", currency: "NGN" },
  { label: "Jola-Fonyi (Senegal)", value: "dyo_SN", currency: "XOF" },
  { label: "Kabuverdianu (Cape Verde)", value: "kea_CV", currency: "CVE" },
  { label: "Kabyle (Algeria)", value: "kab_DZ", currency: "DZD" },
  { label: "Kako (Cameroon)", value: "kkj_CM", currency: "XAF" },
  { label: "Kalaallisut (Greenland)", value: "kl_GL", currency: "DKK" },
  { label: "Kalenjin (Kenya)", value: "kln_KE", currency: "KES" },
  { label: "Kamba (Kenya)", value: "kam_KE", currency: "KES" },
  { label: "Kannada (India)", value: "kn_IN", currency: "INR" },
  {
    label: "Kashmiri (Devanagari, India)",
    value: "ks_Deva_IN",
    currency: "INR",
  },
  { label: "Kashmiri (Naskh, India)", value: "ks_Arab_IN", currency: "INR" },
  { label: "Kashmiri (Nastaliq, India)", value: "ks_Aran_IN", currency: "INR" },
  { label: "Kazakh (Kazakhstan)", value: "kk_KZ", currency: "KZT" },
  { label: "Khmer (Cambodia)", value: "km_KH", currency: "KHR" },
  { label: "Kikuyu (Kenya)", value: "ki_KE", currency: "KES" },
  { label: "Kinyarwanda (Rwanda)", value: "rw_RW", currency: "RWF" },
  { label: "Konkani (India)", value: "kok_IN", currency: "INR" },
  { label: "Korean (North Korea)", value: "ko_KP", currency: "KPW" },
  { label: "Korean (South Korea)", value: "ko_KR", currency: "KRW" },
  { label: "Koyra Chiini (Mali)", value: "khq_ML", currency: "XOF" },
  { label: "Koyraboro Senni (Mali)", value: "ses_ML", currency: "XOF" },
  { label: "Kpelle (Guinea)", value: "kpe_GN", currency: "GNF" },
  { label: "Kpelle (Liberia)", value: "kpe_LR", currency: "LRD" },
  { label: "Kurdish (Türkiye)", value: "ku_TR", currency: "TRY" },
  { label: "Kurdish, Sorani (Iran)", value: "ckb_IR", currency: "IRR" },
  { label: "Kurdish, Sorani (Iraq)", value: "ckb_IQ", currency: "IQD" },
  { label: "Kwasio (Cameroon)", value: "nmg_CM", currency: "XAF" },
  { label: "Kyrgyz (Kyrgyzstan)", value: "ky_KG", currency: "KGS" },
  { label: "Lakota (United States)", value: "lkt_US", currency: "USS" },
  { label: "Langi (Tanzania)", value: "lag_TZ", currency: "TZS" },
  { label: "Lao (Laos)", value: "lo_LA", currency: "LAK" },
  { label: "Latvian (Latvia)", value: "lv_LV", currency: "EUR" },
  { label: "Lingala (Angola)", value: "ln_AO", currency: "AOA" },
  {
    label: "Lingala (Central African Republic)",
    value: "ln_CF",
    currency: "XAF",
  },
  { label: "Lingala (Congo - Brazzaville)", value: "ln_CG", currency: "AUD" },
  { label: "Lingala (Congo - Kinshasa)", value: "ln_CD", currency: "AUD" },
  { label: "Lithuanian (Lithuania)", value: "lt_LT", currency: "EUR" },
  { label: "Low German (Germany)", value: "nds_DE", currency: "EUR" },
  { label: "Low German (Netherlands)", value: "nds_NL", currency: "EUR" },
  { label: "Lower Sorbian (Germany)", value: "dsb_DE", currency: "EUR" },
  { label: "Luba-Katanga (Congo - Kinshasa)", value: "lu_CD", currency: "AUD" },
  { label: "Luo (Kenya)", value: "luo_KE", currency: "KES" },
  { label: "Luxembourgish (Luxembourg)", value: "lb_LU", currency: "EUR" },
  { label: "Luyia (Kenya)", value: "luy_KE", currency: "KES" },
  { label: "Macedonian (North Macedonia)", value: "mk_MK", currency: "MKD" },
  { label: "Machame (Tanzania)", value: "jmc_TZ", currency: "TZS" },
  { label: "Maithili (India)", value: "mai_IN", currency: "INR" },
  { label: "Makhuwa-Meetto (Mozambique)", value: "mgh_MZ", currency: "MZM" },
  { label: "Makonde (Tanzania)", value: "kde_TZ", currency: "TZS" },
  { label: "Malagasy (Madagascar)", value: "mg_MG", currency: "MGF" },
  { label: "Malay (Arabic, Brunei)", value: "ms_Arab_BN", currency: "BND" },
  { label: "Malay (Arabic, Malaysia)", value: "ms_Arab_MY", currency: "MYR" },
  { label: "Malay (Brunei)", value: "ms_BN", currency: "BND" },
  { label: "Malay (Indonesia)", value: "ms_ID", currency: "IDR" },
  { label: "Malay (Malaysia)", value: "ms_MY", currency: "MYR" },
  { label: "Malay (Singapore)", value: "ms_SG", currency: "SGD" },
  { label: "Malayalam (India)", value: "ml_IN", currency: "INR" },
  { label: "Maltese (Malta)", value: "mt_MT", currency: "MTL" },
  { label: "Manipuri (Bangla, India)", value: "mni_Beng_IN", currency: "INR" },
  {
    label: "Manipuri (Meitei Mayek, India)",
    value: "mni_Mtei_IN",
    currency: "INR",
  },
  { label: "Manx (Isle of Man)", value: "gv_IM", currency: "GBP" },
  { label: "Mapuche (Chile)", value: "arn_CL", currency: "CLP" },
  { label: "Marathi (India)", value: "mr_IN", currency: "INR" },
  { label: "Masai (Kenya)", value: "mas_KE", currency: "KES" },
  { label: "Masai (Tanzania)", value: "mas_TZ", currency: "TZS" },
  { label: "Mazanderani (Iran)", value: "mzn_IR", currency: "IRR" },
  { label: "Māori (New Zealand)", value: "mi_NZ", currency: "NZD" },
  { label: "Meru (Kenya)", value: "mer_KE", currency: "KES" },
  { label: "Metaʼ (Cameroon)", value: "mgo_CM", currency: "XAF" },
  { label: "Mohawk (Canada)", value: "moh_CA", currency: "CAD" },
  { label: "Mongolian (Mongolia)", value: "mn_MN", currency: "MNT" },
  { label: "Morisyen (Mauritius)", value: "mfe_MU", currency: "MUR" },
  { label: "Mundang (Cameroon)", value: "mua_CM", currency: "XAF" },
  { label: "Nama (Namibia)", value: "naq_NA", currency: "NAD" },
  { label: "Navajo (United States)", value: "nv_US", currency: "USS" },
  { label: "Nepali (India)", value: "ne_IN", currency: "INR" },
  { label: "Nepali (Nepal)", value: "ne_NP", currency: "NPR" },
  { label: "Ngiemboon (Cameroon)", value: "nnh_CM", currency: "XAF" },
  { label: "Ngomba (Cameroon)", value: "jgo_CM", currency: "XAF" },
  { label: "Nigerian Pidgin (Nigeria)", value: "pcm_NG", currency: "NGN" },
  { label: "North Ndebele (Zimbabwe)", value: "nd_ZW", currency: "ZWD" },
  { label: "Northern Luri (Iran)", value: "lrc_IR", currency: "IRR" },
  { label: "Northern Luri (Iraq)", value: "lrc_IQ", currency: "IQD" },
  { label: "Northern Sami (Finland)", value: "se_FI", currency: "EUR" },
  { label: "Northern Sami (Norway)", value: "se_NO", currency: "NOK" },
  { label: "Northern Sami (Sweden)", value: "se_SE", currency: "SEK" },
  { label: "Northern Sotho (South Africa)", value: "nso_ZA", currency: "ZAR" },
  { label: "Norwegian Bokmål (Norway)", value: "nb_NO", currency: "NOK" },
  {
    label: "Norwegian Bokmål (Svalbard & Jan Mayen)",
    value: "nb_SJ",
    currency: "NOK",
  },
  { label: "Norwegian Nynorsk (Norway)", value: "nn_NO", currency: "NOK" },
  { label: "Nuer (South Sudan)", value: "nus_SS", currency: "SSP" },
  { label: "Nyanja (Malawi)", value: "ny_MW", currency: "MWK" },
  { label: "Nyankole (Uganda)", value: "nyn_UG", currency: "UGX" },
  { label: "N’Ko (Guinea)", value: "nqo_GN", currency: "GNF" },
  { label: "Occitan (France)", value: "oc_FR", currency: "EUR" },
  { label: "Odia (India)", value: "or_IN", currency: "INR" },
  { label: "Oromo (Ethiopia)", value: "om_ET", currency: "ETB" },
  { label: "Oromo (Kenya)", value: "om_KE", currency: "KES" },
  { label: "Ossetic (Georgia)", value: "os_GE", currency: "GEL" },
  { label: "Ossetic (Russia)", value: "os_RU", currency: "RUB" },
  { label: "Pashto (Afghanistan)", value: "ps_AF", currency: "AFN" },
  { label: "Pashto (Pakistan)", value: "ps_PK", currency: "PKR" },
  { label: "Persian (Afghanistan)", value: "fa_AF", currency: "AFN" },
  { label: "Persian (Iran)", value: "fa_IR", currency: "IRR" },
  { label: "Polish (Poland)", value: "pl_PL", currency: "PLN" },
  { label: "Portuguese (Angola)", value: "pt_AO", currency: "AOA" },
  { label: "Portuguese (Brazil)", value: "pt_BR", currency: "BRL" },
  { label: "Portuguese (Cape Verde)", value: "pt_CV", currency: "CVE" },
  { label: "Portuguese (Equatorial Guinea)", value: "pt_GQ", currency: "XAF" },
  { label: "Portuguese (France)", value: "pt_FR", currency: "EUR" },
  { label: "Portuguese (Guinea-Bissau)", value: "pt_GW", currency: "GWP" },
  { label: "Portuguese (Luxembourg)", value: "pt_LU", currency: "EUR" },
  { label: "Portuguese (Macao)", value: "pt_MO", currency: "MOP" },
  { label: "Portuguese (Mozambique)", value: "pt_MZ", currency: "MZM" },
  { label: "Portuguese (Portugal)", value: "pt_PT", currency: "EUR" },
  {
    label: "Portuguese (São Tomé & Príncipe)",
    value: "pt_ST",
    currency: "STD",
  },
  { label: "Portuguese (Switzerland)", value: "pt_CH", currency: "CHF" },
  { label: "Portuguese (Timor-Leste)", value: "pt_TL", currency: "USD" },
  { label: "Punjabi (Gurmukhi, India)", value: "pa_Guru_IN", currency: "INR" },
  { label: "Punjabi (Naskh, Pakistan)", value: "pa_Arab_PK", currency: "PKR" },
  {
    label: "Punjabi (Nastaliq, Pakistan)",
    value: "pa_Aran_PK",
    currency: "PKR",
  },
  { label: "Quechua (Bolivia)", value: "qu_BO", currency: "BOB" },
  { label: "Quechua (Ecuador)", value: "qu_EC", currency: "USD" },
  { label: "Quechua (Peru)", value: "qu_PE", currency: "PEN" },
  {
    label: "Rohingya (Hanifi, Bangladesh)",
    value: "rhg_Rohg_BD",
    currency: "BDT",
  },
  {
    label: "Rohingya (Hanifi, Myanmar [Burma])",
    value: "rhg_Rohg_MM",
    currency: "MMK",
  },
  { label: "Romanian (Moldova)", value: "ro_MD", currency: "MDL" },
  { label: "Romanian (Romania)", value: "ro_RO", currency: "ROL" },
  { label: "Romansh (Switzerland)", value: "rm_CH", currency: "CHF" },
  { label: "Rombo (Tanzania)", value: "rof_TZ", currency: "TZS" },
  { label: "Rundi (Burundi)", value: "rn_BI", currency: "BIF" },
  { label: "Russian (Belarus)", value: "ru_BY", currency: "BYR" },
  { label: "Russian (Kazakhstan)", value: "ru_KZ", currency: "KZT" },
  { label: "Russian (Kyrgyzstan)", value: "ru_KG", currency: "KGS" },
  { label: "Russian (Moldova)", value: "ru_MD", currency: "MDL" },
  { label: "Russian (Russia)", value: "ru_RU", currency: "RUB" },
  { label: "Russian (Ukraine)", value: "ru_UA", currency: "UAH" },
  { label: "Rwa (Tanzania)", value: "rwk_TZ", currency: "TZS" },
  { label: "Samburu (Kenya)", value: "saq_KE", currency: "KES" },
  { label: "Samoan (American Samoa)", value: "sm_AS", currency: "USD" },
  { label: "Samoan (Samoa)", value: "sm_WS", currency: "WST" },
  {
    label: "Sango (Central African Republic)",
    value: "sg_CF",
    currency: "XAF",
  },
  { label: "Sangu (Tanzania)", value: "sbp_TZ", currency: "TZS" },
  { label: "Sanskrit (India)", value: "sa_IN", currency: "INR" },
  {
    label: "Santali (Devanagari, India)",
    value: "sat_Deva_IN",
    currency: "INR",
  },
  { label: "Santali (Ol Chiki, India)", value: "sat_Olck_IN", currency: "INR" },
  { label: "Sardinian (Italy)", value: "sc_IT", currency: "EUR" },
  {
    label: "Scottish Gaelic (United Kingdom)",
    value: "gd_GB",
    currency: "GBP",
  },
  { label: "Sena (Mozambique)", value: "seh_MZ", currency: "MZM" },
  {
    label: "Serbian (Cyrillic, Bosnia & Herzegovina)",
    value: "sr_Cyrl_BA",
    currency: "BAM",
  },
  { label: "Serbian (Cyrillic, Kosovo)", value: "sr_Cyrl_XK", currency: "EUR" },
  {
    label: "Serbian (Cyrillic, Montenegro)",
    value: "sr_Cyrl_ME",
    currency: "EUR",
  },
  { label: "Serbian (Cyrillic, Serbia)", value: "sr_Cyrl_RS", currency: "CSD" },
  {
    label: "Serbian (Latin, Bosnia & Herzegovina)",
    value: "sr_Latn_BA",
    currency: "BAM",
  },
  { label: "Serbian (Latin, Kosovo)", value: "sr_Latn_XK", currency: "EUR" },
  {
    label: "Serbian (Latin, Montenegro)",
    value: "sr_Latn_ME",
    currency: "EUR",
  },
  { label: "Serbian (Latin, Serbia)", value: "sr_Latn_RS", currency: "CSD" },
  { label: "Shambala (Tanzania)", value: "ksb_TZ", currency: "TZS" },
  { label: "Shona (Zimbabwe)", value: "sn_ZW", currency: "ZWD" },
  { label: "Sichuan Yi (China mainland)", value: "ii_CN", currency: "CNY" },
  { label: "Sicilian (Italy)", value: "scn_IT", currency: "EUR" },
  { label: "Sindhi (Arabic, Pakistan)", value: "sd_Arab_PK", currency: "PKR" },
  { label: "Sindhi (Devanagari, India)", value: "sd_Deva_IN", currency: "INR" },
  { label: "Sinhala (Sri Lanka)", value: "si_LK", currency: "LKR" },
  { label: "Slovak (Slovakia)", value: "sk_SK", currency: "EUR" },
  { label: "Slovenian (Slovenia)", value: "sl_SI", currency: "EUR" },
  { label: "Soga (Uganda)", value: "xog_UG", currency: "UGX" },
  { label: "Somali (Djibouti)", value: "so_DJ", currency: "DJF" },
  { label: "Somali (Ethiopia)", value: "so_ET", currency: "ETB" },
  { label: "Somali (Kenya)", value: "so_KE", currency: "KES" },
  { label: "Somali (Somalia)", value: "so_SO", currency: "SOS" },
  { label: "South Ndebele (South Africa)", value: "nr_ZA", currency: "ZAR" },
  { label: "Southern Sotho (Lesotho)", value: "st_LS", currency: "LSL" },
  { label: "Southern Sotho (South Africa)", value: "st_ZA", currency: "ZAR" },
  { label: "Spanish (Antigua & Barbuda)", value: "es_AG", currency: "XCD" },
  { label: "Spanish (Argentina)", value: "es_AR", currency: "ARS" },
  { label: "Spanish (Bahamas)", value: "es_BS", currency: "BSD" },
  { label: "Spanish (Barbados)", value: "es_BB", currency: "BBD" },
  { label: "Spanish (Belize)", value: "es_BZ", currency: "BZD" },
  { label: "Spanish (Bermuda)", value: "es_BM", currency: "BMD" },
  { label: "Spanish (Bolivia)", value: "es_BO", currency: "BOB" },
  { label: "Spanish (Brazil)", value: "es_BR", currency: "BRL" },
  {
    label: "Spanish (British Virgin Islands)",
    value: "es_VG",
    currency: "USD",
  },
  { label: "Spanish (Canada)", value: "es_CA", currency: "CAD" },
  { label: "Spanish (Canary Islands)", value: "es_IC", currency: "EUR" },
  { label: "Spanish (Caribbean Netherlands)", value: "es_BQ", currency: "EUR" },
  { label: "Spanish (Cayman Islands)", value: "es_KY", currency: "KYD" },
  { label: "Spanish (Ceuta & Melilla)", value: "es_EA", currency: "EUR" },
  { label: "Spanish (Chile)", value: "es_CL", currency: "CLP" },
  { label: "Spanish (Colombia)", value: "es_CO", currency: "COP" },
  { label: "Spanish (Costa Rica)", value: "es_CR", currency: "CRC" },
  { label: "Spanish (Cuba)", value: "es_CU", currency: "CUP" },
  { label: "Spanish (Curaçao)", value: "es_CW", currency: "ANG" },
  { label: "Spanish (Dominica)", value: "es_DM", currency: "XCD" },
  { label: "Spanish (Dominican Republic)", value: "es_DO", currency: "DOP" },
  { label: "Spanish (Ecuador)", value: "es_EC", currency: "USD" },
  { label: "Spanish (El Salvador)", value: "es_SV", currency: "SVC" },
  { label: "Spanish (Equatorial Guinea)", value: "es_GQ", currency: "XAF" },
  { label: "Spanish (Grenada)", value: "es_GD", currency: "XCD" },
  { label: "Spanish (Guatemala)", value: "es_GT", currency: "GTQ" },
  { label: "Spanish (Guyana)", value: "es_GY", currency: "GYD" },
  { label: "Spanish (Haiti)", value: "es_HT", currency: "HTG" },
  { label: "Spanish (Honduras)", value: "es_HN", currency: "HNL" },
  { label: "Spanish (Mexico)", value: "es_MX", currency: "MXN" },
  { label: "Spanish (Nicaragua)", value: "es_NI", currency: "NIO" },
  { label: "Spanish (Panama)", value: "es_PA", currency: "PAB" },
  { label: "Spanish (Paraguay)", value: "es_PY", currency: "PYG" },
  { label: "Spanish (Peru)", value: "es_PE", currency: "PEN" },
  { label: "Spanish (Philippines)", value: "es_PH", currency: "PHP" },
  { label: "Spanish (Puerto Rico)", value: "es_PR", currency: "USD" },
  { label: "Spanish (Spain)", value: "es_ES", currency: "EUR" },
  { label: "Spanish (St. Kitts & Nevis)", value: "es_KN", currency: "XCD" },
  { label: "Spanish (St. Lucia)", value: "es_LC", currency: "XCD" },
  {
    label: "Spanish (St. Vincent & Grenadines)",
    value: "es_VC",
    currency: "XCD",
  },
  { label: "Spanish (Trinidad & Tobago)", value: "es_TT", currency: "TTD" },
  {
    label: "Spanish (Turks & Caicos Islands)",
    value: "es_TC",
    currency: "USD",
  },
  { label: "Spanish (US Virgin Islands)", value: "es_VI", currency: "USD" },
  { label: "Spanish (United States)", value: "es_US", currency: "USS" },
  { label: "Spanish (Uruguay)", value: "es_UY", currency: "UYU" },
  { label: "Spanish (Venezuela)", value: "es_VE", currency: "VEF" },
  {
    label: "Standard Moroccan Tamazight (Morocco)",
    value: "zgh_MA",
    currency: "MAD",
  },
  {
    label: "Sundanese (Latin, Indonesia)",
    value: "su_Latn_ID",
    currency: "IDR",
  },
  { label: "Swahili (Congo - Kinshasa)", value: "sw_CD", currency: "AUD" },
  { label: "Swahili (Kenya)", value: "sw_KE", currency: "KES" },
  { label: "Swahili (Tanzania)", value: "sw_TZ", currency: "TZS" },
  { label: "Swahili (Uganda)", value: "sw_UG", currency: "UGX" },
  { label: "Swati (Eswatini)", value: "ss_SZ", currency: "SZL" },
  { label: "Swati (South Africa)", value: "ss_ZA", currency: "ZAR" },
  { label: "Swedish (Åland Islands)", value: "sv_AX", currency: "EUR" },
  { label: "Swedish (Finland)", value: "sv_FI", currency: "EUR" },
  { label: "Swedish (Sweden)", value: "sv_SE", currency: "SEK" },
  { label: "Swiss German (France)", value: "gsw_FR", currency: "EUR" },
  { label: "Swiss German (Liechtenstein)", value: "gsw_LI", currency: "CHF" },
  { label: "Swiss German (Switzerland)", value: "gsw_CH", currency: "CHF" },
  {
    label: "Tachelhit (Latin, Morocco)",
    value: "shi_Latn_MA",
    currency: "MAD",
  },
  {
    label: "Tachelhit (Tifinagh, Morocco)",
    value: "shi_Tfng_MA",
    currency: "MAD",
  },
  { label: "Taita (Kenya)", value: "dav_KE", currency: "KES" },
  { label: "Tajik (Tajikistan)", value: "tg_TJ", currency: "TJS" },
  { label: "Tamil (India)", value: "ta_IN", currency: "INR" },
  { label: "Tamil (Malaysia)", value: "ta_MY", currency: "MYR" },
  { label: "Tamil (Singapore)", value: "ta_SG", currency: "SGD" },
  { label: "Tamil (Sri Lanka)", value: "ta_LK", currency: "LKR" },
  { label: "Taroko (Taiwan)", value: "trv_TW", currency: "TWD" },
  { label: "Tasawaq (Niger)", value: "twq_NE", currency: "XOF" },
  { label: "Tatar (Russia)", value: "tt_RU", currency: "RUB" },
  { label: "Telugu (India)", value: "te_IN", currency: "INR" },
  { label: "Teso (Kenya)", value: "teo_KE", currency: "KES" },
  { label: "Teso (Uganda)", value: "teo_UG", currency: "UGX" },
  { label: "Thai (Thailand)", value: "th_TH", currency: "THB" },
  { label: "Tibetan (China mainland)", value: "bo_CN", currency: "CNY" },
  { label: "Tibetan (India)", value: "bo_IN", currency: "INR" },
  { label: "Tigre (Eritrea)", value: "tig_ER", currency: "ERN" },
  { label: "Tigrinya (Eritrea)", value: "ti_ER", currency: "ERN" },
  { label: "Tigrinya (Ethiopia)", value: "ti_ET", currency: "ETB" },
  { label: "Tongan (Tonga)", value: "to_TO", currency: "TOP" },
  { label: "Tsonga (South Africa)", value: "ts_ZA", currency: "ZAR" },
  { label: "Tswana (Botswana)", value: "tn_BW", currency: "BWP" },
  { label: "Tswana (South Africa)", value: "tn_ZA", currency: "ZAR" },
  { label: "Turkish (Cyprus)", value: "tr_CY", currency: "TRY" },
  { label: "Turkish (Türkiye)", value: "tr_TR", currency: "TRY" },
  { label: "Turkmen (Turkmenistan)", value: "tk_TM", currency: "TMM" },
  { label: "Tyap (Nigeria)", value: "kcg_NG", currency: "NGN" },
  { label: "Ukrainian (Ukraine)", value: "uk_UA", currency: "UAH" },
  { label: "Upper Sorbian (Germany)", value: "hsb_DE", currency: "EUR" },
  { label: "Urdu (Naskh, India)", value: "ur_Arab_IN", currency: "INR" },
  { label: "Urdu (Naskh, Pakistan)", value: "ur_Arab_PK", currency: "PKR" },
  { label: "Urdu (Nastaliq, Pakistan)", value: "ur_Aran_PK", currency: "PKR" },
  { label: "Uyghur (China mainland)", value: "ug_CN", currency: "CNY" },
  {
    label: "Uzbek (Arabic, Afghanistan)",
    value: "uz_Arab_AF",
    currency: "AFN",
  },
  {
    label: "Uzbek (Cyrillic, Uzbekistan)",
    value: "uz_Cyrl_UZ",
    currency: "UZS",
  },
  { label: "Uzbek (Latin, Uzbekistan)", value: "uz_Latn_UZ", currency: "UZS" },
  { label: "Vai (Latin, Liberia)", value: "vai_Latn_LR", currency: "LRD" },
  { label: "Vai (Vai, Liberia)", value: "vai_Vaii_LR", currency: "LRD" },
  { label: "Venda (South Africa)", value: "ve_ZA", currency: "ZAR" },
  { label: "Vietnamese (Vietnam)", value: "vi_VN", currency: "VND" },
  { label: "Vunjo (Tanzania)", value: "vun_TZ", currency: "TZS" },
  { label: "Walloon (Belgium)", value: "wa_BE", currency: "EUR" },
  { label: "Walser (Switzerland)", value: "wae_CH", currency: "CHF" },
  { label: "Welsh (United Kingdom)", value: "cy_GB", currency: "GBP" },
  { label: "Western Frisian (Netherlands)", value: "fy_NL", currency: "EUR" },
  { label: "Wolaytta (Ethiopia)", value: "wal_ET", currency: "ETB" },
  { label: "Wolof (Senegal)", value: "wo_SN", currency: "XOF" },
  { label: "Xhosa (South Africa)", value: "xh_ZA", currency: "ZAR" },
  { label: "Yakut (Russia)", value: "sah_RU", currency: "RUB" },
  { label: "Yangben (Cameroon)", value: "yav_CM", currency: "XAF" },
  { label: "Yoruba (Benin)", value: "yo_BJ", currency: "XOF" },
  { label: "Yoruba (Nigeria)", value: "yo_NG", currency: "NGN" },
  { label: "Zarma (Niger)", value: "dje_NE", currency: "XOF" },
  { label: "Zulu (South Africa)", value: "zu_ZA", currency: "ZAR" },
];

export { localList };
