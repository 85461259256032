import { Component, createSignal, onMount } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import ChangePassword from "~/components/global/ChangePassword";
import SystemLayout from "~/components/layouts/system.layout";
import { SITE_TITLE } from "~/consts";
import { getAuthToken,  getUser } from "~/db/session";
import { setPageTitle } from "~/root";
import { validateUser } from "./api/validateUser";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;
    return { user_id, jwt_token };
  });
}

const updatePassword: Component = () => {
  setPageTitle(`${SITE_TITLE} - Change Password`);
  const data = useRouteData<typeof routeData>();


  return (
    <main>
      <SystemLayout>
        <div>
          <h2 class="title color">Change Password</h2>
          <div class="w-50">
            <ChangePassword
              user_id={data()?.user_id}
              jwt_token={data()?.jwt_token}
              updatePassword={true}
            ></ChangePassword>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default updatePassword;
