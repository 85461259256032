const dateFormat: any = {
  date: [
    { id: "dd MMMM yyyy", value: "17 January 2021" },
    { id: "dd MMMM yy", value: "17 January 21" },
    { id: "MM/dd/yyyy", value: "01/17/2021" },
    { id: "MM/dd/yy", value: "01/17/21" },
    { id: "dd/MM/yyyy", value: "17/01/2021" },
    { id: "dd/MM/yy", value: "17/01/21" },
  ],
  time: [],
  date_time: [
    { id: "dd MMMM yyyy 'at' hh:mm a", value: "17 January 2021 at 11:30 PM" },
    { id: "dd MMMM yy 'at' hh:mm a", value: "17 January 21 at 11:30 PM" },
    { id: "MM/dd/yyyy 'at' hh:mm a", value: "01/17/2021 at 11:30 PM" },
    { id: "MM/dd/yy 'at' hh:mm a", value: "01/17/21 at 11:30 PM" },
    { id: "dd/MM/yyyy 'at' hh:mm a", value: "17/01/2021 at 11:30 PM" },
    { id: "dd/MM/yy 'at' hh:mm a", value: "17/01/21 at 11:30 PM" },
  ],
  date_time_24: [
    { id: "dd MMMM yyyy 'at' HH:mm", value: "17 January 2021 at 23:30" },
    { id: "dd MMMM yy 'at' HH:mm", value: "17 January 21 at 23:30" },
    { id: "MM/dd/yyyy 'at' HH:mm", value: "01/17/2021 at 23:30" },
    { id: "MM/dd/yy 'at' HH:mm", value: "01/17/21 at 23:30" },
    { id: "dd/MM/yyyy 'at' HH:mm", value: "17/01/2021 at 23:30" },
    { id: "dd/MM/yy 'at' HH:mm", value: "17/01/21 at 23:30" },
  ],
  calculated: [
    { id: "day", value: "Day" },
    { id: "day_hour_min", value: "Day, Hour, Minute" },
    { id: "hour", value: "Hour" },
    { id: "hour_min", value: "Hour, Minute" },
    { id: "min", value: "Minute" },
  ],
};

const fieldAlignments: Array<object> = [
  {
    display: "Left",
    name: "left.alignment",
  },
  { display: "Center", name: "center.alignment" },
  { display: "Right", name: "right.alignment" },
];

const lineTypes: Array<object> = [
  {
    display: "Solid",
    name: "solid",
  },
  { display: "Dotted", name: "dotted" },
  { display: "Dashed", name: "dashed" },
];

const lineScales: Array<object> = [
  {
    display: "Horizontal Line",
    name: false,
  },
  { display: "Vertical Line", name: true },
];

const sortTypes: Array<object> = [
  {
    display: "Ascending",
    name: "asc",
  },
  { display: "Descending", name: "desc" },
];

const fontFamily: Array<object> = [
  {
    display: `<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="font-icons"
    >
      <text
        x="0"
        y="24"
        font-family="Helvetica"
        font-size="30"
      >
        F
      </text>
    </svg>`,
    name: 1,
  },
  {
    display: `<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="font-icons"
    >
      <text
        x="0"
        y="24"
        font-family="Times New Roman"
        font-size="30"
      >
        F
      </text>
    </svg>`,
    name: 0,
  },
  {
    display: `<svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      class="font-icons"
    >
      <text
        x="0"
        y="24"
        font-family="Courier New"
        font-size="30"
      >
        F
      </text>
    </svg>`,
    name: 2,
  },
];

const colorSettings: Array<object> = [
  {
    display: "Text Color",
    name: "textColor",
  },
  { display: "Background Color", name: "backgroundColor" },
];

const fieldColors: Array<object> = [
  { color: "#000000", isActiveWhite: true },
  { color: "#990000", isActiveWhite: true },
  { color: "#999900", isActiveWhite: false },
  { color: "#009900", isActiveWhite: false },
  { color: "#009999", isActiveWhite: false },
  { color: "#000099", isActiveWhite: true },
  { color: "#990099", isActiveWhite: true },
  { color: "#999999", isActiveWhite: false },
  { color: "#FF1A1A", isActiveWhite: true },
  { color: "#FFFF1A", isActiveWhite: false },
  { color: "#1AFF1A", isActiveWhite: false },
  { color: "#1AFFFF", isActiveWhite: false },
  { color: "#86BDFB", isActiveWhite: false },
  { color: "#FF1AFF", isActiveWhite: false },
];

const decimalPlaceExample: any = {
  2: "3.14",
  3: "3.142",
  4: "3.1416",
  5: "3.14159",
};

const tableFieldTypes: any = [
  {
    label: "Text",
    value: "TextField",
    subFields: [
      { label: "Text", value: "text" },
      { label: "Number", value: "number" },
      { label: "Currency", value: "currency" },
      { label: "Calculation", value: "text_calculated" },
    ],
  },
  {
    label: "Date",
    value: "DateField",
    subFields: [
      { label: "Date", value: "date" },
      { label: "Time", value: "time" },
      { label: "Date and Time", value: "date_time" },
      { label: "Date Calculation", value: "date_calculated" },
    ],
  },
  { label: "Checkbox", value: "CheckBoxField", subFields: [] },
  { label: "Dropdown", value: "DropdownField", subFields: [] },
  { label: "Label", value: "LabelField", subFields: [] },
  { label: "Signature", value: "SignatureField", subFields: [] },
];

const textFieldTypes: any = [
  {
    label: "Text",
    value: "text",
  },
  { label: "Number", value: "number" },
  { label: "Currency", value: "currency" },
  { label: "URL", value: "url" },
  { label: "Email Address", value: "email" },
  { label: "Auto-increment Number", value: "autoIncrement" },
  { label: "Calculation", value: "calculated" },
  { label: "Linked Field", value: "linked" },
];

const dateFieldTypes: any = [
  { label: "Date", value: "date" },
  { label: "Time", value: "time" },
  { label: "Date and Time", value: "date_time" },
  { label: "Linked Field", value: "linked" },
  { label: "Calculation", value: "calculated" },
];

const locationFieldTypes: any = [
  { label: "Decimal Degrees", value: "decimal" },
  { label: "Degrees, minutes, seconds", value: "deg_min_sec" },
  { label: "Degrees, decimal minutes", value: "decimal_min" },
];

const scriptBtnCommands: Array<object> = [
  { label: "Save", value: "Save" },
  { label: "Open Form", value: "OpenForm" },
  { label: "Export Form", value: "ExportRecord" },
  { label: "Close Form", value: "CloseForm" },
  { label: "Open Form Tab", value: "OpenFormsInNewTab" },
  { label: "Close Form Tab", value: "CloseTab" },
  { label: "Add Record", value: "AddRecord" },
  { label: "Clone Record", value: "CloneRecord" },
  { label: "Lock Record", value: "LockRecord" },
  { label: "Delete Record", value: "DeleteRecord" },
  { label: "Clear Screen", value: "ClearScreen" },
  { label: "Print", value: "Print" },
  { label: "Go To Page", value: "GoToPage" },
  { label: "Open Folder", value: "OpenFolder" },
  { label: "Find Record", value: "FindRecord" },
];

const scriptSelectionItems: Array<object> = [
  { label: "Form To Open", searchLabel: "Form Name", value: "OpenForm" },
  {
    label: "Cloud Service",
    searchLabel: "Cloud Service",
    value: "Cloud Service",
  },
  { label: "Export Record", searchLabel: "Format", value: "ExportRecord" },
  {
    label: "Forms To Open In New Tab",
    searchLabel: "Form Name",
    value: "OpenFormsInNewTab",
  },
  { label: "Go To Page", searchLabel: "Page", value: "GoToPage" },
  { label: "Folder To Open", searchLabel: "Folder Name", value: "OpenFolder" },
];

const cloudServices: Array<object> = [
  { label: "Send via Email", value: "email", icon: "mail.png" },
  { label: "Save to DropBox", value: "dropbox", icon: "dropbox.png" },
  { label: "Save to Box", value: "box", icon: "box.png" },
  { label: "Save to OneDrive", value: "onedrive", icon: "onedrive.png" },
  {
    label: "Save to Google Drive",
    value: "googledrive",
    icon: "googledrive.png",
  },
];

const exportFormats: Array<object> = [
  { label: "PDF", value: "PDF" },
  { label: "CSV", value: "CSV" },
  { label: "XML", value: "XML" },
  { label: "HTML", value: "HTML" },
  { label: "JSON", value: "JSON" },
  // { label: "FMCX", value: "FMCX" },
];

const exportOtherFormats: Array<object> = [
  { label: "FORM JSON", value: "form_json" },
  { label: "RECORD JSON", value: "record_json" },
];

const scriptParameter: any = {
  parameter_name: "",
  parameter_selection_style: "Single",
  parameter_type: {},
  parameter_values: [
    {
      param_value_additinal_info: {},
      param_value_display: "",
      param_value_key: "",
    },
  ],
};

const mapping: any = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};

const formBuilderSidebarItems: any = [
  { label: "Text Box", icon: "inputField", value: "TextField" },
  { label: "Date", icon: "dateField", value: "DateField" },
  { label: "Checkbox", icon: "checkboxField", value: "CheckBoxField" },
  { label: "Dropdown", icon: "dropdownField", value: "DropdownField" },
  { label: "Radio Buttons", icon: "radionField", value: "RadioButtonField" },
  { label: "Label", icon: "labelField", value: "LabelField" },
  { label: "Signature", icon: "signatureField", value: "SignatureField" },
  { label: "Image", icon: "imageField", value: "ImageField" },
  { label: "Photo", icon: "photoField", value: "PhotoField" },
  { label: "Annotate", icon: "annotateField", value: "AnnotateField" },
  { label: "Line", icon: "lineField", value: "LineField" },
  { label: "GPS Coordinates", icon: "locationField", value: "LocationField" },
  { label: "Table", icon: "tableField", value: "TableField" },
  {
    label: "Script Button",
    icon: "scriptButtonField",
    value: "ScriptButtonField",
  },
  { label: "Barcode", icon: "barcode", value: "BarcodeField" },
];

const jsonFormStructure: any = {
  enabledToolbarIcons: [
    { type: "tbicon_export" },
    { type: "tbicon_clipboard" },
    { type: "tbicon_doc_scanner" },
    { type: "tbicon_reports" },
    { type: "tbicon_form_tab" },
    { type: "tbicon_lock_record" },
    { type: "tbicon_tag_record" },
    { type: "tbicon_delete_record" },
    { type: "tbicon_clone_record" },
    { type: "tbicon_add_record" },
    { type: "tbicon_formlink" },
    { type: "tbicon_collaboration" },
    { type: "tbicon_add_page" },
    { type: "tbicon_add_field" },
    { type: "tbicon_records" },
    { type: "tbicon_save_kiosk" },
    { type: "tbicon_clear_kiosk" },
  ],
  pages: [],
  lastSaved: 710676386.25264704,
  isPDFTextBoxEnabled: true,
  areRecordSyncEnabled: false,
  isEmbedImageEnabled: true,
  uuid: "",
  kioskPassword: "",
  isPDFRecordsEnabled: false,
  name: "",
  pageId: 1,
  queryBuilderFields: "",
  formLinkString: "",
  primaryFields: [],
  isLockFromDesignerEnabled: true,
  paperSize: "Letter",
  theme: "Redbook",
  isRecordSortingDisabled: false,
  defaultFontSize: 12,
  isKioskModeEnabled: false,
  indexTabs: [],
  isDesignerLockedOnExport: true,
  locale: "en_US",
};

const dataFieldSync: any = {
  label: "",
  uid: 0,
  labelWidth: 0,
  isRequired: false,
  frame: [
    [0, 0],
    [0, 0],
  ],
  maximum: "",
  shouldDisplayLabel: true,
  minimum: "",
  linkedFieldId: 0,
  shouldCopyWhenClone: false,
  alignment: "center.alignment",
  parentTableId: 0,
  tableCellAddress: "",
  fontSize: 14.5,
};

const choiceFieldSync: any = {
  preSelected: -1,
  items: [],
  totalItems: 1,
  nestedListString: "",
  linkType: "unlinked",
};

const textFieldData: any = {
  type: "TextField",
  properties: {
    decimalPlaces: 2,
    dataFieldSync: { ...dataFieldSync, type: "TextField" },
    color: "#000000",
    isPrePopulated: false,
    inputType: "text",
    calculationFormula: "",
    autoIncrementFrom: 1,
    prePopulatedText: "",
  },
};

const labelFieldData: any = {
  type: "LabelField",
  properties: {
    isBold: false,
    isItalic: false,
    dataFieldSync: { ...dataFieldSync, type: "LabelField" },
    color: "#000000",
    attributedStringHTML: "",
    font: 0,
    backgroundColor: "#000000",
  },
};

const dateFieldData: any = {
  type: "DateField",
  properties: {
    dateInputType: "date",
    color: "#000000",
    dataFieldSync: { ...dataFieldSync, type: "DateField" },
    dateFormat: "dd MMMM yyyy",
    isDateTimeStamp: false,
    calculationFormula: "",
    calculationResultFormat: "day",
  },
};

const checkboxFieldData: any = {
  type: "CheckboxField",
  properties: {
    isCheckedByDefault: false,
    linkType: "unlinked",
    dataFieldSync: { ...dataFieldSync, type: "CheckBoxField" },
  },
};

const radioButtonFieldData: any = {
  type: "RadioField",
  properties: {
    choiceFieldSync: { ...choiceFieldSync },
    dataFieldSync: { ...dataFieldSync, type: "RadioButtonField" },
  },
};

const dropdownFieldData: any = {
  type: "DropdownField",
  properties: {
    choiceFieldSync: { ...choiceFieldSync },
    dataFieldSync: { ...dataFieldSync, type: "DropdownField" },
  },
};

const signatureFieldData: any = {
  type: "SignatureField",
  properties: {
    inkFieldSync: { inkColor: "#000000" },
    dataFieldSync: { ...dataFieldSync, type: "SignatureField" },
  },
};

const imageFieldData: any = {
  type: "ImageField",
  properties: {
    imageFieldCommon: { imageBase64: "" },
    dataFieldSync: { ...dataFieldSync, type: "ImageField" },
  },
};

const photoFieldData: any = {
  type: "PhotoField",
  properties: {
    imageFieldCommon: { imageBase64: "" },
    dataFieldSync: { ...dataFieldSync, type: "PhotoField" },
  },
};

const annotateFieldData: any = {
  type: "AnnotationField",
  properties: {
    bgImageBase64: "",
    dataFieldSync: { ...dataFieldSync, type: "AnnotateField" },
  },
};

const lineFieldData: any = {
  type: "LineField",
  properties: {
    isVertical: false,
    dataFieldSync: { ...dataFieldSync, type: "LineField" },
    lineStyle: "solid",
    thickness: 2,
    color: "#000000",
  },
};

const locationFieldData: any = {
  type: "LocationField",
  properties: {
    locationFormat: "decimal",
    dataFieldSync: { ...dataFieldSync, type: "LocationField" },
  },
};

const tableFieldData: any = {
  type: "TableField",
  properties: {
    row_sizes: "0.375,0.375,0.375,0.375",
    rows: 4,
    columns: 3,
    dataFieldSync: { ...dataFieldSync, type: "TableField" },
    column_sizes: "1.5,1.5,1.5",
  },
};

const scriptButtonFieldData: any = {
  type: "ScriptButtonField",
  properties: {
    actionString: "[]",
    isDebugModeActive: false,
    dataFieldSync: { ...dataFieldSync, type: "ScriptButtonField" },
  },
};

const barcodeFieldData: any = {
  type: "BarcodeField",
  properties: {
    connectionString: "",
    dataFieldSync: { ...dataFieldSync, type: "BarcodeField" },
  },
};

const addFormFields: any = {
  TextField: JSON.parse(JSON.stringify(textFieldData)),
  DateField: JSON.parse(JSON.stringify(dateFieldData)),
  CheckBoxField: JSON.parse(JSON.stringify(checkboxFieldData)),
  DropdownField: JSON.parse(JSON.stringify(dropdownFieldData)),
  RadioButtonField: JSON.parse(JSON.stringify(radioButtonFieldData)),
  LabelField: JSON.parse(JSON.stringify(labelFieldData)),
  SignatureField: JSON.parse(JSON.stringify(signatureFieldData)),
  ImageField: JSON.parse(JSON.stringify(imageFieldData)),
  PhotoField: JSON.parse(JSON.stringify(photoFieldData)),
  AnnotateField: JSON.parse(JSON.stringify(annotateFieldData)),
  LineField: JSON.parse(JSON.stringify(lineFieldData)),
  LocationField: JSON.parse(JSON.stringify(locationFieldData)),
  TableField: JSON.parse(JSON.stringify(tableFieldData)),
  BarcodeField: JSON.parse(JSON.stringify(barcodeFieldData)),
  ScriptButtonField: JSON.parse(JSON.stringify(scriptButtonFieldData)),
};

const formEvents: any = [
  { label: "Share Form", icon: "share", value: "share_form" },
  { label: "Rename Form", icon: "inputField", value: "rename_form_folder" },
  { label: "Clone Form", icon: "copy", value: "clone_form_folder" },
  { label: "Delete Form", icon: "trash", value: "remove_form_folder" },
];

const folderEvents: any = [
  { label: "Rename Folder", icon: "inputField", value: "rename_form_folder" },
  { label: "Clone Folder", icon: "copy", value: "clone_form_folder" },
  { label: "Delete Folder", icon: "trash", value: "remove_form_folder" },
];

const formPageEvents: any = [
  { label: "Add Page", value: "add_page" },
  { label: "Duplicate Page", value: "duplicate_page" },
  { label: "Remove Page", value: "remove_page" },
];

export {
  dateFormat,
  fieldAlignments,
  lineTypes,
  lineScales,
  sortTypes,
  fontFamily,
  colorSettings,
  fieldColors,
  decimalPlaceExample,
  tableFieldTypes,
  textFieldTypes,
  dateFieldTypes,
  locationFieldTypes,
  scriptBtnCommands,
  scriptSelectionItems,
  cloudServices,
  exportFormats,
  exportOtherFormats,
  scriptParameter,
  mapping,
  formBuilderSidebarItems,
  jsonFormStructure,
  textFieldData,
  labelFieldData,
  dateFieldData,
  checkboxFieldData,
  radioButtonFieldData,
  dropdownFieldData,
  signatureFieldData,
  imageFieldData,
  photoFieldData,
  annotateFieldData,
  lineFieldData,
  tableFieldData,
  locationFieldData,
  scriptButtonFieldData,
  barcodeFieldData,
  addFormFields,
  folderEvents,
  formEvents,
  formPageEvents,
};
