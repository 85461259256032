import { Component, Show, createEffect, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import generateCSS from "~/helpers/generator";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  formJson,
  setFormJson,
  setIsFolderForm,
  setOffset,
  updatedCurrentField,
  updatedMultipleFields,
  updateFormJsonPages,
} from "~/store/form.store";
import fetchApi from "~/lib/api";
import { useLocation, useNavigate } from "solid-start";
import { setCurrentPage } from "../global/Pagination";
import {
  isMouseDown,
  multipleSelectField,
  selectedFolderData,
  selectedRecords,
  setAlertMessage,
  setExportFormSettings,
  setIsMouseDown,
  setPopup,
  setRecordsSearch,
  setSelectedFolderData,
  setSelectedRecords,
} from "~/store/global.store";
import convert from "~/helpers/convertor";
import {
  addRecord,
  alertFormRecords,
  cloneRecord,
  deleteRecord,
  isRecordUpdated,
  linkedFormRecordsData,
  lockRecord,
  recordsJson,
  setFormRecords,
  setIsRecordSubmit,
  setLinkedFormRecordsData,
  setPreLoadData,
  setRecordsJson,
  setSelectedRecordId,
  updateFormRecordsList,
  updateRecord,
  updateRecordsList,
} from "~/store/records.store";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
import Icons from "../icons/Icons";
import { setIsPrinting } from "../form/PrintForm";
import {
  exportFormRecordFiles,
  setExportFormat,
} from "~/store/export-files.store";
import { userRole } from "~/store/user-form-role";
import { notify } from "../global/Notification";

interface Props {
  properties: any;
  fieldsType: string;
  jwt_token: string;
  user_id: string;
}

const [isProcessing, setIsProcessing] = createSignal(false as boolean);
const [mailSettings, setMailSettings] = createSignal({} as any);
const [scriptOpenFormId, setScriptOpenFormId] = createSignal("" as string);
const [currentScriptBtn, setCurrentScriptBtn] = createSignal("" as string);
const [openFolder, setOpenFolder] = createSignal(false as boolean);

const ScriptButtonField: Component<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [fieldProperties, setFieldProperties] = createStore({});

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  function scriptButtonAction() {
    setIsProcessing(true);
    const actions = JSON.parse(props.properties.actionString);
    let promiseChain: any = Promise.resolve();
    actions.forEach((command: any, index: any) => {
      promiseChain = promiseChain.then(() => {
        executeCommand(command, index);
      });
    });
    return promiseChain
      .then(() => {
        setTimeout(() => {
          setIsProcessing(false);
          setCurrentScriptBtn("");
        }, 500);
      })
      .catch((error: any) => {
        setIsProcessing(false);
        setCurrentScriptBtn("");
        console.error("Error in scriptButtonAction:", error);
      });
  }

  function executeCommand(command: any, index: any) {
    return new Promise((resolve: any, reject: any) => {
      if (command.command_type == "OpenForm") {
        const apiParams = {
          method: "POST",
          url: `${import.meta.env.VITE_API_URL}/form/id/fetch`,
          jwt_token: props.jwt_token,
          body: {
            user_id: props.user_id,
            form_name:
              command.parameters[0].parameter_values[0].param_value_display,
          },
        };
        fetchApi(apiParams)
          .then((form) => {
            if (form?.statusCode === 401) {
              window.location.href = "/logout";
              return;
            } else if (form?.statusCode === 403) {
              window.location.reload();
              return;
            }
            if (form?.data) {
              setScriptOpenFormId(form.data.id);
              setFormRecords([]);
              window.location.href = `/form/render/${form.data.id}`;
            } else {
              notify({
                isNotified: true,
                message: `${command.parameters[0].parameter_values[0].param_value_display} Form Not Found!`,
                theme: "danger",
                placement: "top",
                timeout: 2500,
              });
            }
            resolve();
          })
          .catch((error) => {
            console.error("Error executing OpenForm:", error);
            reject(error);
          });
      } else if (
        command.command_type == "CloseForm" ||
        command.command_type == "CloseTab"
      ) {
        setCurrentPage(1);
        navigate("/form/list");
        resolve();
      } else if (command.command_type == "GoToPage") {
        setCurrentPage(
          command.parameters[0].parameter_values[0].param_value_display
        );
        setFormJson({
          currentPage:
            command.parameters[0].parameter_values[0].param_value_display - 1,
        });
        if (formJson.currentPage != 0) {
          updateFormJsonPages(
            props?.user_id,
            props?.jwt_token,
            location.pathname.split("/")[3],
            formJson.currentPage + 1
          );
        } else {
          updateFormJsonPages(
            props?.user_id,
            props?.jwt_token,
            location.pathname.split("/")[3],
            formJson.currentPage + 2
          );
        }
        resolve();
      } else if (command.command_type == "OpenFormsInNewTab") {
        const formNames =
          command.parameters[0].parameter_values[0].param_value_display.split(
            ","
          );
        const promises = formNames.map((formName: any) => {
          const apiParams = {
            method: "POST",
            url: `${import.meta.env.VITE_API_URL}/form/id/fetch`,
            jwt_token: props.jwt_token,
            body: {
              user_id: props.user_id,
              form_name: formName.trim(),
            },
          };
          return new Promise((innerResolve: any, innerReject: any) => {
            fetchApi(apiParams)
              .then((form) => {
                if (form?.statusCode === 401) {
                  window.location.href = "/logout";
                  return;
                } else if (form?.statusCode === 403) {
                  window.location.reload();
                  return;
                }
                if (form?.data) {
                  const popup = window.open(
                    `/form/render/${form.data.id}`,
                    "_blank"
                  );
                  if (!popup || popup.closed) {
                    const isSafari: boolean =
                      /safari/i.test(navigator.userAgent) &&
                      !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(
                        navigator.userAgent
                      );
                    setAlertMessage(
                      `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
                        isSafari
                          ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                          : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                      }</span>`
                    );
                    setPopup("alert");
                  }
                } else {
                  notify({
                    isNotified: true,
                    message: `${formName.trim()} Form Not Found!`,
                    theme: "danger",
                    placement: "top",
                    timeout: 2500,
                  });
                }
                innerResolve();
              })
              .catch((error) => {
                console.error("Error executing OpenFormsInNewTab:", error);
                innerReject(error);
              });
          });
        });
        Promise.all(promises)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      } else if (command.command_type == "ClearScreen") {
        setRecordsJson({
          currentRecord: {},
        });
        setSelectedRecordId("");
        setFormJson({
          currentPage: 0,
        });
        setCurrentPage(formJson.currentPage + 1);
        setSelectedRecordId("");
        setRecordsJson({
          currentRecord: {},
          multipleRecords: [],
        });
        setPreLoadData([]);
        resolve();
      } else if (command.command_type == "OpenFolder") {
        const apiParams = {
          method: "POST",
          url: `${import.meta.env.VITE_API_URL}/form/folder/fetch`,
          jwt_token: props.jwt_token,
          body: {
            user_id: props?.user_id,
            form_id: formJson.root.formStructure.uuid,
            form_name:
              command.parameters[0].parameter_values[0].param_value_display,
          },
        };
        fetchApi(apiParams)
          .then((folder) => {
            if (folder?.statusCode === 401) {
              window.location.href = "/logout";
              return;
            } else if (folder?.statusCode === 403) {
              window.location.reload();
              return;
            }
            if (folder?.data) {
              setSelectedFolderData(folder.data);
              setOpenFolder(true);
              setIsFolderForm(true);
              navigate("/form/list");
            } else {
              notify({
                isNotified: true,
                message: `${command.parameters[0].parameter_values[0].param_value_display} Folder Not Found!`,
                theme: "danger",
                placement: "top",
                timeout: 2500,
              });
            }
            resolve();
          })
          .catch((error) => {
            console.error("Error executing OpenFolder:", error);
            reject(error);
          });
      } else if (command.command_type == "AddRecord") {
        if (alertFormRecords()) {
          setIsRecordSubmit(true);
          if (isRecordUpdated() == "add") {
            addRecord();
            if (linkedFormRecordsData().length > 0) {
              linkedFormRecordsData().forEach((item: any) => {
                addRecord(item.uid, "", item.record, item.form_id);
              });
              setLinkedFormRecordsData([]);
            }
          } else {
            updateRecord();
            if (linkedFormRecordsData().length > 0) {
              linkedFormRecordsData().forEach((item: any) => {
                updateRecord("", "", item.record, item.form_id, item.record_id);
              });
              setLinkedFormRecordsData([]);
            }
          }
        }
        resolve();
      } else if (command.command_type == "DeleteRecord") {
        deleteRecord();
        resolve();
      } else if (command.command_type == "CloneRecord") {
        if (
          Object.keys(recordsJson.currentRecord).length > 0 &&
          alertFormRecords()
        ) {
          cloneRecord(location.pathname.split("/")[3]);
        }
        resolve();
      } else if (command.command_type == "LockRecord") {
        if (
          Object.keys(recordsJson.currentRecord).length > 0 &&
          alertFormRecords()
        ) {
          lockRecord();
        }
        resolve();
      } else if (command.command_type == "FindRecord") {
        setOffset(0);
        setRecordsSearch(
          command.parameters[0].parameter_values[0].param_value_display
        );
        setRecordsJson({
          records: recordsJson.records.filter((record: any) =>
            record.record_name
              .toLowerCase()
              .includes(
                command.parameters[0].parameter_values[0].param_value_display.toLowerCase()
              )
          ),
        });
        updateFormRecordsList(location.pathname.split("/")[3], 0);
        const newUrl = `/form/render/${
          location.pathname.split("/")[3]
        }?search=${encodeURIComponent(
          command.parameters[0].parameter_values[0].param_value_display
        )}`;
        window.history.pushState(
          {
            search:
              command.parameters[0].parameter_values[0].param_value_display,
          },
          "",
          newUrl
        );
        resolve();
      } else if (command.command_type == "Print") {
        setSelectedRecords([
          ...selectedRecords(),
          recordsJson.multipleRecords.length > 0
            ? recordsJson.multipleRecords.map((record: any) => record.record_id)
            : Object.keys(recordsJson.currentRecord).length > 0
            ? [recordsJson.currentRecord.record_id]
            : [],
        ]);
        setIsPrinting(true);
        resolve();
      } else if (command.command_type == "ExportRecord") {
        if (
          command.parameters[0].parameter_values[0].param_value_display ==
          "email"
        ) {
          setMailSettings({
            mail_recipients:
              command.parameters[0].parameter_values[0]
                .param_value_additinal_info.EmailRecipients,
            mail_cc:
              command.parameters[0].parameter_values[0]
                .param_value_additinal_info.EmailCC,
            mail_subject:
              command.parameters[0].parameter_values[0]
                .param_value_additinal_info.EmailSubject,
            mail_message:
              command.parameters[0].parameter_values[0]
                .param_value_additinal_info.EmailBody,
          });
        }
        setSelectedRecords([
          ...selectedRecords(),
          recordsJson.multipleRecords.length > 0
            ? recordsJson.multipleRecords.map((record: any) => record.record_id)
            : Object.keys(recordsJson.currentRecord).length > 0
            ? [recordsJson.currentRecord.record_id]
            : [],
        ]);
        setExportFormat(
          command.parameters[1].parameter_values[0].param_value_display.split(
            ","
          )
        );
        setSelectedRecordId("");
        setRecordsJson({
          multipleRecords: [],
        });
        setExportFormSettings(true);
        const apiParams = {
          method: "POST",
          url: `${import.meta.env.VITE_API_URL}/form/auto/export/get`,
          jwt_token: props.jwt_token,
          body: {
            user_id: props.user_id,
            form_id: location.pathname.split("/")[3],
          },
        };
        fetchApi(apiParams)
          .then((exportSettings) => {
            if (exportSettings?.statusCode === 401) {
              window.location.href = "/logout";
              return;
            } else if (exportSettings?.statusCode === 403) {
              window.location.reload();
              return;
            }
            exportFormRecordFiles(
              props.user_id,
              props.jwt_token,
              location.pathname.split("/")[3],
              command.parameters[0].parameter_values[0].param_value_display,
              exportSettings.data
            ).then(() => {
              resolve();
            });
          })
          .catch((error) => {
            console.error("Error executing ExportRecord:", error);
            reject(error);
          });
      } else {
        setTimeout(() => {
          resolve();
        }, 500);
      }
    });
  }

  return (
    <>
      <Show when={props.fieldsType == "designer"}>
        <div
          class="pa builder-field-border"
          style={{
            left:
              Number(
                convert(props.properties.dataFieldSync.frame[0][0], "in-px")
              ) -
              21 +
              "px",
            top:
              convert(props.properties.dataFieldSync.frame[0][1], "in-px") +
              "px",
            width:
              convert(props.properties.dataFieldSync.frame[1][0], "in-px") +
              "px",
            height:
              convert(props.properties.dataFieldSync.frame[1][1], "in-px") +
              "px",
          }}
        ></div>
      </Show>
      <div
        style={generateCSS(fieldProperties)}
        class={`pa script-btn  ${
          props.fieldsType == "preview" ? "pointer-none" : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        }`}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) => {
          if (userRole() != "viewer") {
            setCurrentScriptBtn(props.properties.dataFieldSync.uid);
            props.fieldsType != "render" && props.fieldsType != "locked"
              ? multipleSelectField() || event.shiftKey
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : updatedCurrentField(props.properties.dataFieldSync.uid)
              : scriptButtonAction();
          }
        }}
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      >
        <span class="dropdown-div script">
          <Show
            when={
              !isProcessing() ||
              currentScriptBtn() != props.properties.dataFieldSync.uid
            }
          >
            {props.properties.dataFieldSync.label}
          </Show>
          <Show
            when={
              isProcessing() &&
              currentScriptBtn() == props.properties.dataFieldSync.uid
            }
          >
            <Icons
              name="buttonLoader"
              stroke="#bdc2c5"
              width={20}
              height={20}
            ></Icons>
          </Show>
        </span>
      </div>
    </>
  );
};

export {
  setIsProcessing,
  mailSettings,
  setMailSettings,
  scriptOpenFormId,
  setScriptOpenFormId,
  openFolder,
  setOpenFolder,
};
export default ScriptButtonField;
