import { Component, For, Show, createSignal, onMount } from "solid-js";
import { useNavigate } from "solid-start";
import {
  adjustFormFolderNames,
  formsToAutoLaunch,
  getRandomColorWithProperContrast,
  getSignatureInitials,
  isAutoLaunchEnabled,
  isMouseDown,
  removePopup,
  renameForm,
  selectedDropDown,
  selectedFolderData,
  selectedForm,
  selectedFormRect,
  selectedFormUuid,
  setAlertMessage,
  setAssigneeAvatarsList,
  setAssigneeList,
  setConfirmDeleteForm,
  setCurrentformName,
  setFormsToAutoLaunch,
  setIsAutoLaunchEnabled,
  setIsMouseDown,
  setPopup,
  setRenameForm,
  setSelectedDropDown,
  setSelectedForm,
  setSelectedFormRect,
  setSelectedForms,
  setSelectedFormType,
  setSelectedFormUuid,
  userIcons,
} from "~/store/global.store";
import { userRole } from "~/store/user-form-role";
import Icons from "../icons/Icons";
import {
  attachFormToFolder,
  cloneFormStructure,
  deleteFormStructure,
  formFieldLinker,
  formSettings,
  renameFormStructureName,
  shareForm,
  toggleFormPin,
} from "~/routes/form/list";
import { hasMultipleLines } from "../popup/FolderFormsPopup";
import { Portal } from "solid-js/web";
import { setIsFolderForm } from "~/store/form.store";
import Dropdown from "./Dropdown";
import { formEvents } from "~/store/field-editor.store";
import { setSelectedFormId } from "./FormNavbar";
import fetchApi from "~/lib/api";
import { notify } from "./Notification";
import messages from "~/helpers/message";

interface Props {
  user_id: string;
  jwt_token: string;
}

const FolderForms: Component<Props> = (props: any) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = createSignal(false as boolean);
  let action = "";

  let inputRef: any;

  onMount(() => {
    setTimeout(() => {
      setIsActive(true);
      setFormsToAutoLaunch(
        selectedFolderData()?.auto_launch_form_ids?.split(",") ?? []
      );
      adjustFormFolderNames();
    }, 100);
  });

  return (
    <>
      <Show when={isMouseDown()}>
        <Portal>
          <div
            class="pa clone"
            style={{
              height: selectedFormRect().height + "px",
              width: selectedFormRect().width + "px",
              top: selectedFormRect().top + "px",
              left: selectedFormRect().left + "px",
              "z-index": 9999,
            }}
            onmousemove={(e: any) => {
              if (e.movementX > 1 || e.movementY > 1) {
                action = "drag";
              }
              if (!isMouseDown()) return;
              const element = e.target;
              let parentElement: any = "";
              if (element.parentElement.classList.contains("clone")) {
                parentElement = element.parentElement;
              } else if (
                element.parentElement.parentElement.classList.contains("clone")
              ) {
                parentElement = element.parentElement.parentElement;
              } else {
                return;
              }
              const newLeft =
                parseFloat(parentElement.style.left || 0) + e.movementX;
              parentElement.style.left = `${newLeft}px`;
              const newTop =
                parseFloat(parentElement.style.top || 0) + e.movementY;
              parentElement.style.top = `${newTop}px`;
              adjustFormFolderNames();
            }}
            onmouseleave={() => {
              setIsMouseDown(false);
              action = "";
            }}
            onmouseup={async (e: any) => {
              e.preventDefault();
              if (action == "") {
                const form: any = selectedFolderData().forms.find(
                  (form: any) => form.id == selectedForm()
                );
                if (isAutoLaunchEnabled()) {
                  const autoLaunchForms: any = [...formsToAutoLaunch()];
                  const index: number = autoLaunchForms.findIndex(
                    (item: any) => item === form.form_id
                  );
                  if (index == -1) {
                    autoLaunchForms.push(form.form_id);
                  } else {
                    autoLaunchForms.splice(index, 1);
                  }
                  setFormsToAutoLaunch(autoLaunchForms);
                } else {
                  if (
                    formsToAutoLaunch()?.length > 0 &&
                    formsToAutoLaunch().includes(form.form_id)
                  ) {
                    setIsFolderForm(true);
                    navigate(`/form/render/${form.id}`);
                    removePopup("folderForms");
                    selectedFolderData()
                      .forms.filter(
                        (item: any) =>
                          formsToAutoLaunch().includes(item.form_id) &&
                          item.form_id != form.form_id
                      )
                      .forEach((item: any) => {
                        const popup = window.open(
                          `/form/render/${item.id}`,
                          "_blank"
                        );
                        const isSafari: boolean =
                          /safari/i.test(navigator.userAgent) &&
                          !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(
                            navigator.userAgent
                          );
                        if (!popup || popup.closed) {
                          setAlertMessage(
                            `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
                              isSafari
                                ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                                : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
                            }</span>`
                          );
                          setPopup("alert");
                        }
                      });
                  } else {
                    setIsFolderForm(true);
                    navigate(`/form/render/${selectedForm()}`);
                    removePopup("folderForms");
                  }
                }
              }
              const bodyHeight: number = document.body.offsetHeight;
              const bodyWidth: number = document.body.offsetWidth;

              const left: number = (bodyWidth - 900) / 2;
              const right: number = (bodyWidth + 900) / 2;
              const top: number =
                (bodyHeight - (hasMultipleLines() ? 568 : 323)) / 2;
              const bottom: number =
                (bodyHeight + (hasMultipleLines() ? 568 : 323)) / 2;
              if (e.x < left || e.x > right || e.y < top || e.y > bottom) {
                if (!isMouseDown()) return;
                if (
                  selectedFolderData().theme == "folder" &&
                  !(
                    userRole() != "admin" &&
                    selectedFolderData().shared_users?.length > 1
                  )
                ) {
                  attachFormToFolder(
                    props.jwt_token,
                    props.user_id,
                    selectedForm(),
                    selectedFolderData().id,
                    true
                  );
                  removePopup("folderForms");
                }
              }
              setIsMouseDown(false);
              action = "";
            }}
          >
            <div class="pa flex app-flex align-center justify-center w-160p h-100p form-actions">
              <label
                class={`form-list-label ${
                  selectedFolderData().forms.filter(
                    (form: any) => form.id == selectedForm()
                  )[0]?.theme
                }`}
                style={{
                  "font-size":
                    selectedFolderData().forms.filter(
                      (form: any) => form.id == selectedForm()
                    )[0]?.name?.length > 120
                      ? "0.7rem"
                      : selectedFolderData().forms.filter(
                          (form: any) => form.id == selectedForm()
                        )[0]?.name?.length < 120 &&
                        selectedFolderData().forms.filter(
                          (form: any) => form.id == selectedForm()
                        )[0]?.name?.length > 60
                      ? "0.8rem"
                      : selectedFolderData().forms.filter(
                          (form: any) => form.id == selectedForm()
                        )[0]?.name?.length < 60 &&
                        selectedFolderData().forms.filter(
                          (form: any) => form.id == selectedForm()
                        )[0]?.name?.length > 30
                      ? "0.9rem"
                      : "1rem",
                }}
              >
                {selectedFolderData()
                  .forms.filter((form: any) => form.id == selectedForm())[0]
                  ?.name?.slice(0, 80)}{" "}
                {selectedFolderData().forms.filter(
                  (form: any) => form.id == selectedForm()
                )[0]?.name?.length > 80
                  ? "..."
                  : ""}
              </label>
            </div>
            <img
              src={`/form_themes/${
                selectedFolderData().forms.filter(
                  (form: any) => form.id == selectedForm()
                )[0]?.theme
              }.png`}
              class="form-list-image"
            />
          </div>
        </Portal>
      </Show>
      <div
        class={`folder-forms-container ${!isActive() ? "d-n" : ""}`}
        onclick={(e: any) => {
          const element: any = document.getElementById(
            `formDropDown_${selectedDropDown()}`
          );
          if (element?.contains(e.target)) {
          } else {
            setSelectedDropDown("");
          }
        }}
      >
        <Show when={isAutoLaunchEnabled()}>
          <div class="auto-launch-confirm">
            <button
              class="btn sm primary"
              onclick={() => setIsAutoLaunchEnabled(false)}
            >
              Cancel
            </button>
            <span class="lbl"> Select forms to open together</span>
            <button
              class="btn sm primary"
              onclick={async () => {
                const apiParams = {
                  method: "POST" as string,
                  url: `${
                    import.meta.env.VITE_API_URL
                  }/form/folder/auto-launch`,
                  jwt_token: props.jwt_token,
                  body: {
                    user_id: props.user_id,
                    folder_id: selectedFolderData().id,
                    auto_launch_form_ids: formsToAutoLaunch().join(","),
                  },
                };
                const folderFormsAutoLaunch = await fetchApi(apiParams);
                if (
                  folderFormsAutoLaunch?.statusCode == 401 ||
                  folderFormsAutoLaunch?.statusCode == 403
                ) {
                  window.location.href = "/logout";
                }
                let message: string = messages.error.error;
                let theme: string = "danger";
                if (folderFormsAutoLaunch.status) {
                  theme = "success";
                  message = messages.folder.auto_launch;
                }
                notify({
                  isNotified: true,
                  message,
                  theme,
                  placement: "top",
                  timeout: 2500,
                });
              }}
            >
              Done
            </button>
          </div>
        </Show>
        <Show
          when={
            !isAutoLaunchEnabled() && selectedFolderData().forms?.length > 0
          }
        >
          <div class="auto-launch-div">
            <button
              class="btn sm btn-ico"
              onclick={() => setIsAutoLaunchEnabled(true)}
            >
              <Icons name="launch"></Icons>
              &nbsp;Auto Launch
            </button>
          </div>
        </Show>
        <div class="folder-forms-content">
          <div class="flex app-flex gap row-gap-1_5 flex-wrap">
            <For each={selectedFolderData().forms}>
              {(form: any) => {
                const combinedAssignees = [
                  ...(Array.isArray(form.assignee) ? form.assignee : []),
                  ...(Array.isArray(form.assigneeGroupNames)
                    ? form.assigneeGroupNames
                    : []),
                ];
                const combinedAvatars = [
                  ...(Array.isArray(form.assigneeAvatar)
                    ? form.assigneeAvatar
                    : []),
                  ...(Array.isArray(form.assigneeGroupNames)
                    ? form.assigneeGroupNames
                    : []),
                ];
                const uniqueAssignees = new Map();
                combinedAssignees.forEach((username, index) => {
                  if (!uniqueAssignees.has(username)) {
                    uniqueAssignees.set(username, combinedAvatars[index]);
                  }
                });
                const deduplicatedAssignees = [...uniqueAssignees.keys()];
                const deduplicatedAvatars = [...uniqueAssignees.values()];
                return (
                  <>
                    <div
                      class={`pr cur-p ${
                        isMouseDown() && selectedForm() == form.id
                          ? "opacity-25"
                          : ""
                      }`}
                      onmousedown={(e: any) => {
                        setSelectedForm(form.id);
                        setSelectedFormUuid(form.form_id);
                        if (
                          e.target.classList.contains("form-actions") ||
                          e.target.classList.contains("form-list-label")
                        ) {
                          e.preventDefault();
                          setIsMouseDown(true);
                          setSelectedFormRect(
                            e.target.parentElement.getBoundingClientRect()
                          );
                          adjustFormFolderNames();
                        }
                      }}
                    >
                      <div class="w-160p po-r">
                        {/* <Show
                        when={
                          userRole() == "admin" ||
                          userRole() == "sub_admin" ||
                          userRole() == "designer"
                        }
                      > */}
                        <button
                          id={`formDropDown_${form.id}`}
                          class="non-btn pa t--22 r-0 cur-p"
                          onclick={(e) => {
                            e.preventDefault();
                            setSelectedDropDown(form.id);
                          }}
                        >
                          <Icons name="infoDots" stroke="#6c6e6f"></Icons>
                        </button>
                        {/* </Show> */}
                        <div
                          class={`form-assignee form-users__group ${
                            form.assignee?.length > 3 ? "has-multiple" : ""
                          }`}
                          onclick={(e: any) => {
                            const element: any = document.getElementById(
                              `folder_${form.id}_more_btn`
                            );
                            if (!element?.contains(e.target)) {
                              if (isAutoLaunchEnabled()) {
                                const autoLaunchForms: any = [
                                  ...formsToAutoLaunch(),
                                ];
                                const index: number = autoLaunchForms.findIndex(
                                  (item: any) => item === form.form_id
                                );
                                if (index == -1) {
                                  autoLaunchForms.push(form.form_id);
                                } else {
                                  autoLaunchForms.splice(index, 1);
                                }
                                setFormsToAutoLaunch(autoLaunchForms);
                              } else {
                                setIsFolderForm(true);
                                navigate(`/form/render/${selectedForm()}`);
                                removePopup("folderForms");
                              }
                            }
                          }}
                        >
                          <For each={deduplicatedAssignees}>
                            {(assignee: any, key: any) => (
                              <>
                                <Show when={key() < 3}>
                                  <div
                                    class="form-user folder"
                                    style={{
                                      "--z-index":
                                        deduplicatedAssignees?.length - key(),
                                      "--background-color":
                                        deduplicatedAvatars[key()] != "" &&
                                        deduplicatedAvatars[key()].includes(
                                          "http"
                                        )
                                          ? "transparant"
                                          : userIcons().find(
                                              (user: any) =>
                                                user.name == assignee
                                            )?.theme
                                          ? userIcons().find(
                                              (user: any) =>
                                                user.name == assignee
                                            )?.theme
                                          : getRandomColorWithProperContrast(
                                              "#ffffff"
                                            ),
                                      border:
                                        deduplicatedAvatars[key()] != "" &&
                                        deduplicatedAvatars[key()].includes(
                                          "http"
                                        )
                                          ? "none"
                                          : userIcons().find(
                                              (user: any) =>
                                                user.name == assignee
                                            )?.theme
                                          ? "none"
                                          : "2px solid",
                                    }}
                                    data-title={assignee}
                                  >
                                    <Show
                                      when={
                                        deduplicatedAvatars[key()] != "" &&
                                        deduplicatedAvatars[key()].includes(
                                          "http"
                                        )
                                      }
                                    >
                                      <img
                                        src={deduplicatedAvatars[key()]}
                                      ></img>
                                    </Show>
                                    <Show
                                      when={
                                        deduplicatedAvatars[key()] == "" ||
                                        !deduplicatedAvatars[key()].includes(
                                          "http"
                                        )
                                      }
                                    >
                                      {userIcons().find(
                                        (user: any) => user.name == assignee
                                      )?.logo
                                        ? userIcons().find(
                                            (user: any) => user.name == assignee
                                          )?.logo
                                        : getSignatureInitials(assignee)}
                                    </Show>
                                  </div>
                                </Show>
                                <Show
                                  when={
                                    deduplicatedAssignees?.length > 3 &&
                                    deduplicatedAssignees?.length - 1 == key()
                                  }
                                >
                                  <div
                                    id={"folder_" + form.id + "_more_btn"}
                                    class="form-user folder"
                                    style={{
                                      "--z-index":
                                        deduplicatedAssignees?.length - key(),
                                      "--background-color": "#000",
                                    }}
                                    data-title="more"
                                    onclick={() => {
                                      if (isAutoLaunchEnabled()) {
                                        const autoLaunchForms: any = [
                                          ...formsToAutoLaunch(),
                                        ];
                                        const index: number =
                                          autoLaunchForms.findIndex(
                                            (item: any) => item === form.form_id
                                          );
                                        if (index == -1) {
                                          autoLaunchForms.push(form.form_id);
                                        } else {
                                          autoLaunchForms.splice(index, 1);
                                        }
                                        setFormsToAutoLaunch(autoLaunchForms);
                                      } else {
                                        setCurrentformName(form.name);
                                        setAssigneeList({
                                          assignee: form.assignee,
                                          assigneeTeams:
                                            form.assigneeGroupNames,
                                        });
                                        setAssigneeAvatarsList({
                                          assigneeAvatar: form.assigneeAvatar,
                                        });
                                        setPopup("assigneeList");
                                      }
                                    }}
                                  >
                                    <Icons name="plus" stroke="#ffffff"></Icons>
                                  </div>
                                </Show>
                              </>
                            )}
                          </For>
                        </div>
                        <Show when={form.totalRecords}>
                          <div class="records-count">{form.totalRecords}</div>
                        </Show>
                      </div>
                      <Show when={selectedDropDown() == form.id}>
                        <Dropdown
                          list={
                            form.is_pinned
                              ? [
                                  ...(userRole() == "designer"
                                    ? formEvents.filter(
                                        (item: any) =>
                                          item.value != "share_form"
                                      )
                                    : userRole() == "filler" ||
                                      userRole() == "viewer"
                                    ? []
                                    : formEvents),
                                  selectedFolderData().theme == "folder" &&
                                  !(
                                    userRole() != "admin" &&
                                    selectedFolderData().shared_users?.length >
                                      1
                                  )
                                    ? {
                                        label: "Move From Folder",
                                        icon: "folderDetach",
                                        value: "remove_from_folder",
                                      }
                                    : {},
                                  {
                                    label: "Unpin Form",
                                    icon: "filledPin",
                                    value: "pin_form",
                                  },
                                ].filter(
                                  (form: any) => Object.keys(form)?.length > 0
                                )
                              : [
                                  ...(userRole() == "designer"
                                    ? formEvents.filter(
                                        (item: any) =>
                                          item.value != "share_form"
                                      )
                                    : userRole() == "filler" ||
                                      userRole() == "viewer"
                                    ? []
                                    : formEvents),
                                  selectedFolderData().theme == "folder" &&
                                  !(
                                    userRole() != "admin" &&
                                    selectedFolderData().shared_users?.length >
                                      1
                                  )
                                    ? {
                                        label: "Move From Folder",
                                        icon: "folderDetach",
                                        value: "remove_from_folder",
                                      }
                                    : {},
                                  {
                                    label: "Pin Form",
                                    icon: "pin",
                                    value: "pin_form",
                                  },
                                ].filter(
                                  (form: any) => Object.keys(form)?.length > 0
                                )
                          }
                          title="label"
                          form={true}
                          setValue={(val: string) => {
                            switch (val) {
                              case "shared_forms":
                                setAssigneeList(form.assignee);
                                setAssigneeAvatarsList(form.assigneeAvatar);
                                setPopup("assigneeList");
                                break;
                              case "rename_form_folder":
                                setRenameForm(form.id);
                                inputRef?.focus();
                                break;
                              case "form_builder":
                                navigate(`/form/builder/${form.id}`);
                                break;
                              case "share_form":
                                setSelectedForms([form.id]);
                                shareForm(
                                  props.user_id,
                                  props.jwt_token,
                                  form.id,
                                  true
                                );
                                break;
                              case "clone_form_folder":
                                cloneFormStructure(
                                  props.user_id,
                                  props.jwt_token,
                                  form.form_id,
                                  "form"
                                );
                                break;
                              case "form_settings":
                                formSettings(
                                  props.user_id,
                                  form.id,
                                  props.jwt_token
                                );
                                setSelectedFormId(form.id);
                                break;
                              case "field_linker":
                                formFieldLinker(
                                  props.user_id,
                                  form.id,
                                  props.jwt_token
                                );
                                setSelectedFormId(form.id);
                                break;
                              case "remove_form_folder":
                                setConfirmDeleteForm(false);
                                setSelectedFormType(form.theme);
                                deleteFormStructure(
                                  props.user_id,
                                  props.jwt_token,
                                  form.form_id,
                                  "form"
                                );
                                break;
                              case "remove_from_folder":
                                setSelectedDropDown("");
                                attachFormToFolder(
                                  props.jwt_token,
                                  props.user_id,
                                  selectedForm(),
                                  selectedFolderData().id,
                                  true
                                );
                                break;
                              case "pin_form":
                                toggleFormPin(
                                  props.user_id,
                                  form.form_id,
                                  props.jwt_token,
                                  true
                                );
                                break;
                              default:
                                break;
                            }
                          }}
                          class="r--3"
                        ></Dropdown>
                      </Show>
                      <Show when={form.is_pinned && !isAutoLaunchEnabled()}>
                        <div
                          class={`pinned-form ${
                            formsToAutoLaunch()?.includes(form.form_id)
                              ? "left"
                              : ""
                          }`}
                        >
                          <Icons name="filledPin"></Icons>
                        </div>
                      </Show>
                      <Show
                        when={
                          formsToAutoLaunch()?.includes(form.form_id) &&
                          !isAutoLaunchEnabled()
                        }
                      >
                        <div class="launch-form">
                          <Icons name="launch" stroke="#2e61a3"></Icons>
                        </div>
                      </Show>
                      <Show when={isAutoLaunchEnabled()}>
                        <div class="launch-form">
                          <Icons
                            name={
                              formsToAutoLaunch()?.includes(form.form_id)
                                ? "circleCheck"
                                : "circle"
                            }
                            stroke="#1cbd47"
                          ></Icons>
                        </div>
                      </Show>
                      <Show when={form.totalRecords}>
                        <div class="records-count template">
                          {form.totalRecords}
                        </div>
                      </Show>
                      <div class="pa flex app-flex align-center justify-center w-160p h-100p form-actions">
                        <Show when={renameForm() == form.id}>
                          <div class="list-options active">
                            <button
                              class="list-option__btn"
                              onclick={(e: any) => {
                                e.preventDefault();
                                if (inputRef.value != "") {
                                  renameFormStructureName(
                                    props.user_id,
                                    props.jwt_token,
                                    selectedFormUuid(),
                                    inputRef.value,
                                    "form"
                                  );
                                }
                                setRenameForm("");
                                adjustFormFolderNames();
                              }}
                            >
                              <Icons name="check" stroke="#ffffff" />
                            </button>
                            <button
                              class="list-option__btn"
                              onclick={(e: any) => {
                                e.preventDefault();
                                setRenameForm("");
                                adjustFormFolderNames();
                              }}
                            >
                              <Icons name="x" stroke="#ffffff" />
                            </button>
                          </div>
                        </Show>
                        <Show when={renameForm() != form.id}>
                          <label
                            class={`form-list-label ${form.theme}`}
                            style={{
                              "font-size":
                                form.name?.length > 120
                                  ? "0.7rem"
                                  : form.name?.length < 120 &&
                                    form.name?.length > 60
                                  ? "0.8rem"
                                  : form.name?.length < 60 &&
                                    form.name?.length > 30
                                  ? "0.9rem"
                                  : "1rem",
                            }}
                          >
                            {form.name?.slice(0, 80)}{" "}
                            {form.name?.length > 80 ? "..." : ""}
                          </label>
                        </Show>
                        <Show when={renameForm() == form.id}>
                          <input
                            ref={inputRef}
                            type="text"
                            class="input w-68"
                            value={form.name}
                            autofocus={true}
                          />
                        </Show>
                      </div>
                      <img
                        src={`/form_themes/${form.theme}.png`}
                        class="form-list-image"
                      />
                    </div>
                  </>
                );
              }}
            </For>
            <Show when={selectedFolderData().forms?.length == 0}>
              <br />
              <label class="lbl w-100 text-center">
                No forms attached to this folder.
                <br />
                <br />
                Please drag any form to the folder.
              </label>
            </Show>
          </div>
        </div>
      </div>
    </>
  );
};

export default FolderForms;
