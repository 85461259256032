import { Component, For, Show } from "solid-js";
import Popover from "./Popover";

interface Props {
  set: Array<{ [key: string]: string | boolean | number | any }>;
  active?: string | any;
  setActive: Function;
  class?: string;
  isSingle?: boolean;
  showTooltip?: boolean;
}
const SelectOne: Component<Props> = (props) => {
  return (
    <ul class={`list-selection ${props?.class ? props.class : ""}`}>
      <For each={props.set}>
        {(item) => (
          <>
            <Show when={!props?.showTooltip}>
              <li
                class={
                  item.name == props.active
                    ? `active ${props?.isSingle ? "full-width" : ""}`
                    : ""
                }
                innerHTML={item.display}
                onclick={() => props.setActive(item.name)}
              ></li>
            </Show>
            <Show when={props?.showTooltip}>
              <li
                class={
                  item.name == props.active
                    ? `font active ${props?.isSingle ? "full-width" : ""}`
                    : "font"
                }
                data-title={
                  item.name == 1
                    ? "Helvetica"
                    : item.name == 0
                    ? "Times New Roman"
                    : "Courier New"
                }
                innerHTML={item.display}
                onclick={() => props.setActive(item.name)}
              ></li>
            </Show>
          </>
        )}
      </For>
    </ul>
  );
};

export default SelectOne;
