import { Component, Show, createEffect, createSignal, onMount } from "solid-js";
import Popup from "../global/Popup";
import {
  activeField,
  removePopup,
  selectedScript,
  selectedScriptId,
  setActiveField,
  setSelectedScript,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import { createStore } from "solid-js/store";
import { updateScriptItemData } from "../builder/ScriptButtonEditor";

const [isNotScriptButtonEmail, setIsNotScriptButtonEmail] = createSignal(
  false as boolean
);

const EmailSettings: Component = () => {
  const [emailSettingsFields, setEmailSettingsFields] = createStore({
    EmailRecipients: "" as string,
    EmailCC: "" as string,
    EmailSubject: "" as string,
    EmailBody: "" as string,
  } as any);

  onMount(() => setIsNotScriptButtonEmail(true));

  createEffect(() => {
    if (selectedScript()) {
      setEmailSettingsFields({
        EmailRecipients:
          selectedScript().filter(
            (obj: any) => obj.command_type == "ExportRecord"
          )[0]?.parameters[0].parameter_values[0].param_value_additinal_info
            ?.EmailRecipients ?? "",
        EmailCC:
          selectedScript().filter(
            (obj: any) => obj.command_type == "ExportRecord"
          )[0]?.parameters[0].parameter_values[0].param_value_additinal_info
            ?.EmailCC ?? "",
        EmailSubject:
          selectedScript().filter(
            (obj: any) => obj.command_type == "ExportRecord"
          )[0]?.parameters[0].parameter_values[0].param_value_additinal_info
            ?.EmailSubject ?? "",
        EmailBody:
          selectedScript().filter(
            (obj: any) => obj.command_type == "ExportRecord"
          )[0]?.parameters[0].parameter_values[0].param_value_additinal_info
            ?.EmailBody ?? "",
      });
    }
  });

  function updateEmailSettings(type: string) {
    const tempScript = selectedScript().map((script: any) => {
      if (script.uuid_string === selectedScriptId()) {
        const info: any =
          script?.parameters[0].parameter_values[0].param_value_additinal_info;
        const additionalInfo: any = {
          EmailRecipients: info?.EmailRecipients ? info?.EmailRecipients : "",
          EmailCC: info?.EmailCC ? info?.EmailCC : "",
          EmailSubject: info?.EmailSubject ? info?.EmailSubject : "",
          EmailBody: info?.EmailBody ? info?.EmailBody : "",
        };
        additionalInfo[type] = emailSettingsFields[type];
        return {
          ...script,
          parameters: [
            {
              ...script?.parameters[0],
              parameter_values: [
                {
                  ...script?.parameters[0].parameter_values[0],
                  param_value_additinal_info: additionalInfo,
                },
              ],
            },
            ...script?.parameters.slice(1),
          ],
        };
      }
      return script;
    });
    setSelectedScript(tempScript);
  }

  return (
    <Popup heading={"Email Settings"} classes="small" name="emailSettings">
      <div>
        <div class="flex gap space-between align-center">
          <label for="email_to">To:</label>
          <div class="flex align-center w-100 space-between pr">
            <input
              type="text"
              class="input"
              id="email_to"
              value={emailSettingsFields["EmailRecipients"]}
              onclick={() => {
                setActiveField("email_to");
              }}
              oninput={(e: any) => {
                setEmailSettingsFields({ EmailRecipients: e.target.value });
                updateEmailSettings("EmailRecipients");
              }}
            />
            <Show
              when={
                activeField() == "email_to" &&
                emailSettingsFields["EmailRecipients"] != ""
              }
            >
              <span
                class="pa z-index-1 cur-p r-5"
                onclick={() => {
                  setEmailSettingsFields({ EmailRecipients: "" });
                  updateEmailSettings("EmailRecipients");
                }}
              >
                <Icons name="circleX" width={20} height={20}></Icons>
              </span>
            </Show>
          </div>
        </div>
        <br />
        <div class="flex gap space-between align-center">
          <label for="email_cc">CC/BCC:</label>
          <div class="flex align-center w-100 space-between pr">
            <input
              type="text"
              class="input"
              id="email_cc"
              value={emailSettingsFields["EmailCC"]}
              onclick={() => {
                setActiveField("email_cc");
              }}
              oninput={(e: any) => {
                setEmailSettingsFields({ EmailCC: e.target.value });
                updateEmailSettings("EmailCC");
              }}
            />
            <Show
              when={
                activeField() == "email_cc" &&
                emailSettingsFields["EmailCC"] != ""
              }
            >
              <span
                class="pa z-index-1 cur-p r-5"
                onclick={() => {
                  setEmailSettingsFields({ EmailCC: "" });
                  updateEmailSettings("EmailCC");
                }}
              >
                <Icons name="circleX" width={20} height={20}></Icons>
              </span>
            </Show>
          </div>
        </div>
        <br />
        <div class="flex gap space-between align-center">
          <label for="email_subject">Subject:</label>
          <div class="flex align-center w-100 space-between pr">
            <input
              type="text"
              class="input"
              id="email_subject"
              value={emailSettingsFields["EmailSubject"]}
              oninput={(e: any) => {
                setEmailSettingsFields({ EmailSubject: e.target.value });
                updateEmailSettings("EmailSubject");
              }}
              onclick={() => {
                setActiveField("email_subject");
              }}
            />
            <Show
              when={
                activeField() == "email_subject" &&
                emailSettingsFields["EmailSubject"] != ""
              }
            >
              <span
                class="pa z-index-1 cur-p r-5"
                onclick={() => {
                  setEmailSettingsFields({ EmailSubject: "" });
                  updateEmailSettings("EmailSubject");
                }}
              >
                <Icons name="circleX" width={20} height={20}></Icons>
              </span>
            </Show>
          </div>
        </div>
        <br />
        <label for="email_body">Body:</label>
        <br />
        <textarea
          name=""
          class="input h-150p"
          id="email_body"
          oninput={(e: any) => {
            setEmailSettingsFields({ EmailBody: e.target.value });
            updateEmailSettings("EmailBody");
          }}
        >
          {
            selectedScript().filter(
              (obj: any) => obj.command_type == "ExportRecord"
            )[0]?.parameters[0].parameter_values[0].param_value_additinal_info
              ?.EmailBody
          }
        </textarea>
        <br />
        <button
          class="float-right btn sm primary"
          onclick={() => {
            updateScriptItemData();
            removePopup("emailSettings");
          }}
        >
          Save
        </button>
      </div>
    </Popup>
  );
};

export { isNotScriptButtonEmail, setIsNotScriptButtonEmail };
export default EmailSettings;
