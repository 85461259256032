import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { createStore } from "solid-js/store";
import fetchApi from "~/lib/api";
import {
  calculateRect,
  labelFieldTop,
  selectedRecords,
  setAlertMessage,
  setPopup,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import JsBarcode from "jsbarcode";
import { setIsProcessing } from "../fields/ScriptButtonField";
import convert from "~/helpers/convertor";
import {
  calculateTextLines,
  getTextHeightAndLines,
  radioFieldButtons,
} from "~/store/form.store";

interface Props {
  user_id: string;
  jwt_token: string;
  form_id: string;
}

const [isPrinting, setIsPrinting] = createSignal(false as boolean);

const PrintForm: Component<Props> = (props) => {
  const [formPrintData, setFormPrintData] = createStore({
    form: [],
    records: [],
    data: [],
    status: false,
  }) as any;
  var count = 0;

  onMount(async () => {
    const formDataApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/print/data`,
      jwt_token: props.jwt_token as string,
      body: {
        user_id: props.user_id,
        form_id: props.form_id,
        record_ids: selectedRecords().join(","),
      },
    };
    let data = await fetchApi(formDataApiParams);
    if (data?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (data?.statusCode == 403) {
      location.reload();
    }
    if (data && data.status) {
      setFormPrintData({
        formStructure: {
          ...JSON.parse(data.data.form.structure),
        },
        records: data.data.records,
        data: data.data.data,
        status: true,
      });
      setIsPrinting(false);
      setIsProcessing(false);
    }
  });

  // createEffect(() => {
  //   if (formPrintData.data && formPrintData.data.length > 0) {
  //     for (const page of formPrintData.data) {
  //       for (const field of page) {
  //         const elm: any = document.getElementById(
  //           `field_print_label_${field.uid}`
  //         );
  //         if (elm) {
  //           elm.style.top = `${
  //             Number(elm.style.top.split("px")[0]) +
  //             Number(field.top) +
  //             ((Number(field.height) >= 36 ? 36 : Number(field.height)) -
  //               elm.offsetHeight) /
  //               2 +
  //             (Number(field.fontSize) > 18.5 ? Number(field.fontSize) / 2 : 0)
  //           }px`;
  //         }
  //       }
  //     }
  //   }
  // });

  createEffect(() => {
    var element: any = document.getElementById("print-form");
    if (element && formPrintData.status) {
      var divContents = element.innerHTML;
      var printWindow: any = window.open("", "", "height=440,width=740");
      if (printWindow) {
        printWindow.document.write("<html><head>");
        printWindow.document.write(
          "</head><body style='font-family: Helvetica'>"
        );
        printWindow.document.write(divContents);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        for (const field of formPrintData.formStructure.pages
          .flatMap((page: any) => page.fields)
          .filter((field: any) => field.type == "LabelField")) {
          const element: any = printWindow.document.getElementById(
            `print_label_${field.properties.dataFieldSync.uid}`
          );
          if (element) {
            // Old Code
            // const originalWhiteSpace = element.style.whiteSpace;
            // element.style.whiteSpace = "nowrap";
            // element.style.whiteSpace = originalWhiteSpace;
            // if (
            //   Array.from(element.childNodes).filter(
            //     (node: any) =>
            //       node.nodeType !== Node.TEXT_NODE &&
            //       node.nodeName.toLowerCase() !== "meta" &&
            //       node.nodeName.toLowerCase() !== "title"
            //   ).length > 1
            // ) {
            //   element.style["padding-top"] =
            //     String(
            //       Math.abs(
            //         Math.round(
            //           (Number(element.style["font-size"]?.split("px")[0]) /
            //             72) *
            //             4 +
            //             0.49
            //         ) /
            //           4 +
            //           0.125 -
            //           Number(element.style["font-size"]?.split("px")[0]) * 1.2
            //       ) /
            //         Array.from(element.childNodes).filter(
            //           (node: any) =>
            //             node.nodeType !== Node.TEXT_NODE &&
            //             node.nodeName.toLowerCase() !== "meta" &&
            //             node.nodeName.toLowerCase() !== "title"
            //         ).length
            //     ) + "px";
            // } else {
            //   element.style["justify-content"] = "center";
            // }
            // New Code
            if (
              calculateTextLines(
                field.properties.dataFieldSync.label,
                Number(
                  convert(field.properties.dataFieldSync.frame[1][0], "in-px")
                ),
                (Math.round(
                  (Number(field.properties.dataFieldSync.fontSize) / 72) *
                    84 *
                    4
                ) /
                  4 /
                  84) *
                  96,
                field.properties.font == 0
                  ? "Times New Roman"
                  : field.properties.font == 1
                  ? "Helvetica"
                  : "Courier New"
              ) > 1
            ) {
              element.style["padding-top"] =
                labelFieldTop[field.properties.dataFieldSync.fontSize] + "px";
              const paragraphs = element.querySelectorAll("p");
              paragraphs.forEach((p: any) => {
                p.style.margin = "0";
                p.style.setProperty(
                  "line-height",
                  (Math.round(
                    (Number(field.properties.dataFieldSync.fontSize) / 72) *
                      84 *
                      4
                  ) /
                    4 /
                    84) *
                    96 +
                    "px",
                  "important"
                );
              });

              const lists = element.querySelectorAll("ul");
              lists.forEach((ul: HTMLElement) => {
                ul.style.margin = "0";
                ul.style.padding = "0";
                ul.style.setProperty("padding-left", "15px", "important");
              });
            } else {
              element.style["justify-content"] = "center";
              const lists = element.querySelectorAll("ul");
              lists.forEach((ul: HTMLElement) => {
                ul.style.margin = "0";
                ul.style.padding = "0";
                ul.style.setProperty("padding-left", "15px", "important");
              });
            }
          }
        }
        printWindow.print();
      } else {
        const isSafari: boolean =
          /safari/i.test(navigator.userAgent) &&
          !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(
            navigator.userAgent
          );
        setAlertMessage(
          `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
            isSafari
              ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
              : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
          }</span>`
        );
        setPopup("alert");
      }
    }
  });

  function generateBarcode(barcodeValue: string) {
    const canvas = document.createElement("canvas");
    JsBarcode(canvas, barcodeValue, {
      format: "CODE128",
      width: 2,
      height: 100,
      displayValue: false,
    });
    return canvas.toDataURL("image/png");
  }

  return (
    <>
      <div
        id="print-form"
        style={`position: absolute; left: 0px; top: 0px; width: 775px; height: ${
          formPrintData.records.length * formPrintData.data.length * 1123
        }px; overflow: hidden;`}
      >
        <For each={formPrintData.records}>
          {(record: any) => {
            return (
              <For each={formPrintData.data}>
                {(page: any) =>
                  (() => {
                    count++;
                    return (
                      <For each={page}>
                        {(field: any) => {
                          let rect = calculateRect(
                            Number(field.fontSize),
                            Number(convert(field.left, "px-in")),
                            Number(convert(field.top, "px-in")),
                            field.label,
                            "Helvetica"
                          );
                          return (
                            <Show when={field.type != "ScriptButtonField"}>
                              <Show
                                when={
                                  field.type != "LabelField" &&
                                  field.type != "CheckboxField" &&
                                  field.type != "ImageField" &&
                                  field.type != "LineField" &&
                                  field.type != "TableField" &&
                                  field.type != "ScriptButtonField" &&
                                  field.shouldDisplayLabel &&
                                  (field.parentTableId == 0 ||
                                    field.parentTableId == "" ||
                                    field.parentTableId == "0")
                                }
                              >
                                <label
                                  id={`field_print_label_${field.uid}`}
                                  style={`position: absolute; display: flex; align-items: ${
                                    Number(field.height) <= 36
                                      ? "center"
                                      : "start"
                                  }; justify-content: right; left: ${
                                    rect.x - 21.13 > 0 ? rect.x - 21.13 : 0
                                  }px; top: ${
                                    (count - 1) * 1123 +
                                    Number(field.top) +
                                    ((Number(field.height) >= 36
                                      ? 36
                                      : Number(field.height)) -
                                      Math.round(
                                        getTextHeightAndLines(
                                          field.label,
                                          rect.width - 1,
                                          (Math.round(
                                            (Number(field.fontSize) / 72) *
                                              84 *
                                              4
                                          ) /
                                            4 /
                                            84) *
                                            96
                                        ).textHeight -
                                          (getTextHeightAndLines(
                                            field.label,
                                            rect.width - 1,
                                            (Math.round(
                                              (Number(field.fontSize) / 72) *
                                                84 *
                                                4
                                            ) /
                                              4 /
                                              84) *
                                              96
                                          ).lines -
                                            1)
                                      )) /
                                      2 +
                                    (Number(field.fontSize) > 18.5
                                      ? Number(field.fontSize) / 2
                                      : 0) -
                                    2
                                  }px; width: ${rect.width - 1}px; font-size: ${
                                    (Math.round(
                                      (Number(field.fontSize) / 72) * 84 * 4
                                    ) /
                                      4 /
                                      84) *
                                    96
                                  }px; text-align: right; z-index: -1; color: ${
                                    field.color
                                  }; overflow-wrap: break-word; word-break: break-word;`}
                                >
                                  {field.label}
                                </label>
                              </Show>
                              <Show
                                when={
                                  field.type == "TextField" ||
                                  field.type == "DateField"
                                }
                              >
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${
                                    String(field.parentTableId) != "0"
                                      ? field.width - 8
                                      : field.width
                                  }px; height: ${
                                    String(field.parentTableId) != "0"
                                      ? Number(field.height) - 4
                                      : Number(field.height) > 25
                                      ? Number(field.height) - 6
                                      : field.height
                                  }px; font-size: ${
                                    (Math.round(
                                      (Number(field.fontSize) / 72) * 84 * 4
                                    ) /
                                      4 /
                                      84) *
                                    96
                                  }px; text-align: ${
                                    field.alignment
                                  }; border: ${
                                    formPrintData.formStructure
                                      ?.isPDFTextBoxEnabled
                                      ? "1px solid #bdc2c5"
                                      : "none"
                                  }; border-radius: none; text-align: ${
                                    field.alignment
                                  }; overflow: hidden; white-space-nowrap; line-height: ${
                                    String(field.parentTableId) != "0"
                                      ? Number(field.height) - 4
                                      : Number(field.height) > 25
                                      ? Number(field.height) - 6
                                      : field.height
                                  }px;`}
                                >
                                  <div
                                    style={`verticle-align: middle; padding: 7px;`}
                                  >
                                    {record[field.uid]}
                                  </div>
                                </div>
                              </Show>
                              <Show when={field.type == "CheckboxField"}>
                                <div
                                  style={`position: absolute; display: flex; justify-content: ${
                                    String(field.parentTableId) == "0"
                                      ? "start"
                                      : field.alignment
                                  }; gap: 0.5rem; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${
                                    String(field.parentTableId) == "0"
                                      ? Number(field.width) + 3
                                      : Number(field.width) - 7
                                  }px; height: ${
                                    String(field.parentTableId) == "0"
                                      ? field.height
                                      : field.height - 2
                                  }px; font-size: ${
                                    (Math.round(
                                      (Number(field.fontSize) / 72) * 84 * 4
                                    ) /
                                      4 /
                                      84) *
                                    96
                                  }px; border: ${
                                    String(field.parentTableId) != "0" &&
                                    formPrintData.formStructure
                                      .isPDFTextBoxEnabled
                                      ? "1px solid #bdc2c5"
                                      : ""
                                  }; border-right: ${
                                    String(field.parentTableId) != "0"
                                      ? "none"
                                      : ""
                                  };`}
                                >
                                  <div
                                    style={{
                                      height:
                                        (Math.round(
                                          (Number(field.fontSize) / 72) * 84 * 4
                                        ) /
                                          4 /
                                          84) *
                                          96 +
                                        2 +
                                        "px",
                                      "max-height": "16px",
                                      "max-width": "16px",
                                      display: "flex",
                                      "align-items": "center",
                                      "margin-top": "9px",
                                    }}
                                  >
                                    <div style="width: 15px; height: 15px; border: 1px solid #bdc2c5;">
                                      <Show when={record[field.uid] == "true"}>
                                        <Icons
                                          name="check"
                                          width={16}
                                          height={16}
                                        ></Icons>
                                      </Show>
                                    </div>
                                  </div>
                                  <Show when={field.shouldDisplayLabel}>
                                    <span
                                      style={`word-wrap: break-word; margin-top: ${
                                        Math.round((15 / 84) * 96) -
                                        Math.round(
                                          ((Math.round(
                                            (Number(field.fontSize) / 72) *
                                              84 *
                                              4
                                          ) /
                                            4 /
                                            84) *
                                            96 *
                                            0.92 -
                                            (Math.round(
                                              (Number(field.fontSize) / 72) *
                                                84 *
                                                4
                                            ) /
                                              4 /
                                              84) *
                                              96 *
                                              -0.23) /
                                            2
                                        )
                                      }px; ${
                                        String(field.parentTableId) != "0"
                                          ? `display: -webkit-box; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: ${Math.floor(
                                              Number(field.height) /
                                                ((Math.round(
                                                  (Number(field.fontSize) /
                                                    72) *
                                                    84 *
                                                    4
                                                ) /
                                                  4 /
                                                  84) *
                                                  96 *
                                                  1.2)
                                            )}`
                                          : ""
                                      }`}
                                    >
                                      {field.label}
                                    </span>
                                  </Show>
                                </div>
                              </Show>
                              <Show when={field.type == "LabelField"}>
                                <Show when={field.attributedStringHTML}>
                                  <Show
                                    when={String(field.parentTableId) == "0"}
                                  >
                                    <div
                                      id={`print_label_${field.uid}`}
                                      style={`position: absolute; left: ${
                                        field.left - 21
                                      }px; top: ${
                                        (count - 1) * 1123 + Number(field.top)
                                      }px; width: ${Number(
                                        String(field.parentTableId) == "0"
                                          ? field.width
                                          : field.width - 8
                                      )}px; height: ${
                                        field.height > 36 &&
                                        field.label &&
                                        calculateTextLines(
                                          field.label,
                                          field.width,
                                          (Math.round(
                                            (Number(field.fontSize) / 72) *
                                              84 *
                                              4
                                          ) /
                                            4 /
                                            84) *
                                            96,
                                          field.font == 0
                                            ? "Times New Roman"
                                            : field.font == 1
                                            ? "Helvetica"
                                            : "Courier New"
                                        ) == 1
                                          ? (Math.round(
                                              (((Math.round(
                                                (Number(field.fontSize) / 72) *
                                                  84 *
                                                  4
                                              ) /
                                                4 /
                                                84) *
                                                96) /
                                                72) *
                                                4 *
                                                0.49
                                            ) /
                                              4 +
                                              0.125) *
                                            96
                                          : field.height
                                      }px; display: flex; flex-direction: column; text-align: ${
                                        field.alignment
                                      }; font-size: ${
                                        (Math.round(
                                          (Number(field.fontSize) / 72) * 84 * 4
                                        ) /
                                          4 /
                                          84) *
                                        96
                                      }px; color: ${
                                        field.color
                                      }; background-color:${
                                        String(field.parentTableId) == "0"
                                          ? field.backgroundColor != "#000000"
                                            ? field.backgroundColor
                                            : ""
                                          : field.tableCellAddress.includes(0)
                                          ? field.backgroundColor == "#000000"
                                            ? "#86BDFB"
                                            : field.backgroundColor
                                          : ""
                                      }; border: ${
                                        String(field.parentTableId) != "0" &&
                                        formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "1px solid #bdc2c5"
                                          : ""
                                      }; word-wrap: break-word; ${
                                        String(field.parentTableId) == "0"
                                          ? ""
                                          : `line-height: ${field.height}px; white-space: nowrap; overflow: hidden;`
                                      }`}
                                      innerHTML={field.attributedStringHTML}
                                    ></div>
                                  </Show>
                                  <Show
                                    when={String(field.parentTableId) != "0"}
                                  >
                                    <div
                                      id={`print_label_${field.uid}`}
                                      style={`position: absolute; left: ${
                                        field.left - 21
                                      }px; top: ${
                                        (count - 1) * 1123 + Number(field.top)
                                      }px; width: ${Number(
                                        field.width - 6
                                      )}px; height: ${
                                        field.height > 36 &&
                                        field.label &&
                                        calculateTextLines(
                                          field.label,
                                          field.width,
                                          (Math.round(
                                            (Number(field.fontSize) / 72) *
                                              84 *
                                              4
                                          ) /
                                            4 /
                                            84) *
                                            96,
                                          field.font == 0
                                            ? "Times New Roman"
                                            : field.font == 1
                                            ? "Helvetica"
                                            : "Courier New"
                                        ) == 1
                                          ? (Math.round(
                                              (((Math.round(
                                                (Number(field.fontSize) / 72) *
                                                  84 *
                                                  4
                                              ) /
                                                4 /
                                                84) *
                                                96) /
                                                72) *
                                                4 *
                                                0.49
                                            ) /
                                              4 +
                                              0.125) *
                                            96
                                          : field.height -
                                            (field.tableCellAddress.includes(0)
                                              ? 0
                                              : labelFieldTop[field.fontSize] +
                                                2)
                                      }px; display: flex; flex-direction: column; text-align: ${
                                        field.alignment
                                      }; font-size: ${
                                        (Math.round(
                                          (Number(field.fontSize) / 72) * 84 * 4
                                        ) /
                                          4 /
                                          84) *
                                        96
                                      }px; color: ${
                                        field.color
                                      }; background-color:${
                                        String(field.parentTableId) == "0"
                                          ? field.backgroundColor != "#000000"
                                            ? field.backgroundColor
                                            : ""
                                          : field.tableCellAddress.includes(0)
                                          ? field.backgroundColor == "#000000"
                                            ? "#86BDFB"
                                            : field.backgroundColor
                                          : ""
                                      }; border: ${
                                        String(field.parentTableId) != "0" &&
                                        formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "1px solid #bdc2c5"
                                          : ""
                                      }; word-wrap: break-word;`}
                                    >
                                      <div
                                        style={
                                          calculateTextLines(
                                            field.label,
                                            field.width,
                                            (Math.round(
                                              (Number(field.fontSize) / 72) *
                                                84 *
                                                4
                                            ) /
                                              4 /
                                              84) *
                                              96,
                                            field.font == 0
                                              ? "Times New Roman"
                                              : field.font == 1
                                              ? "Helvetica"
                                              : "Courier New"
                                          ) > 1 || field.height > 36
                                            ? `display: -webkit-box; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: ${Math.floor(
                                                (Number(field.height) -
                                                  labelFieldTop[
                                                    field.fontSize
                                                  ]) /
                                                  ((Math.round(
                                                    (Number(field.fontSize) /
                                                      72) *
                                                      84 *
                                                      4
                                                  ) /
                                                    4 /
                                                    84) *
                                                    96 *
                                                    1.2)
                                              )}`
                                            : `height: 100%; display: flex; align-items: center; justify-content: ${field.alignment}`
                                        }
                                        innerHTML={field.attributedStringHTML}
                                      ></div>
                                    </div>
                                  </Show>
                                </Show>
                                <Show when={!field.attributedStringHTML}>
                                  <div
                                    style={`position: absolute; left: ${
                                      field.left - 21
                                    }px; top: ${
                                      (count - 1) * 1123 + Number(field.top)
                                    }px;  width: ${Number(
                                      String(field.parentTableId) == "0"
                                        ? field.width
                                        : field.width - 8
                                    )}px; height: ${
                                      String(field.parentTableId) == "0"
                                        ? field.height
                                        : field.height - 2
                                    }px; display: flex; flex-direction: column; align-items: center; color: ${
                                      field.color
                                    }; font-size: ${
                                      (Math.round(
                                        (Number(field.fontSize) / 72) * 84 * 4
                                      ) /
                                        4 /
                                        84) *
                                      96
                                    }px; background-color:${
                                      String(field.parentTableId) == "0"
                                        ? field.backgroundColor != "#000000"
                                          ? field.backgroundColor
                                          : ""
                                        : field.tableCellAddress.includes(0)
                                        ? field.backgroundColor == "#000000"
                                          ? "#86BDFB"
                                          : field.backgroundColor
                                        : ""
                                    }; justify-content: ${
                                      field.alignment
                                    }; font-weight: ${
                                      field.isBold ? "bold" : ""
                                    }; font-style : ${
                                      field.isItalic ? "italic" : ""
                                    }; font-family: ${
                                      field.font == 0
                                        ? "Times New Roman"
                                        : field.font == 1
                                        ? "Helvetica"
                                        : "Courier New"
                                    }; border: ${
                                      String(field.parentTableId) != "0" &&
                                      formPrintData.formStructure
                                        .isPDFTextBoxEnabled
                                        ? "1px solid #bdc2c5"
                                        : ""
                                    };`}
                                  >
                                    {field.label}
                                  </div>
                                </Show>
                              </Show>
                              <Show when={field.type == "DropdownField"}>
                                <select
                                  name={
                                    field.shouldDisplayLabel
                                      ? field.label
                                      : "Unnamed Field"
                                  }
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${
                                    String(field.parentTableId) == "0"
                                      ? field.width
                                      : field.width - 5
                                  }px; height: ${
                                    String(field.parentTableId) == "0"
                                      ? field.height
                                      : field.height - 1
                                  }px; font-size: ${
                                    (Math.round(
                                      (Number(field.fontSize) / 72) * 84 * 4
                                    ) /
                                      4 /
                                      84) *
                                    96
                                  }px; border: ${
                                    formPrintData.formStructure
                                      .isPDFTextBoxEnabled
                                      ? "1px solid #bdc2c5"
                                      : "none"
                                  };`}
                                >
                                  <option
                                    style={`text-align: ${field.alignment};`}
                                  >
                                    {field.items.filter(
                                      (item: any) =>
                                        String(record[field.uid]) ==
                                          String(item.id) ||
                                        item.id == field.preSelected
                                    )[0]?.title
                                      ? field.items.filter(
                                          (item: any) =>
                                            String(record[field.uid]) ==
                                              String(item.id) ||
                                            item.id == field.preSelected
                                        )[0]?.title
                                      : "--"}
                                  </option>
                                </select>
                              </Show>
                              <Show when={field.type == "RadioField"}>
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${
                                    Number(field.width) + 2
                                  }px; height: ${field.height}px; font-size: ${
                                    (Math.round(
                                      (Number(field.fontSize) / 72) * 84 * 4
                                    ) /
                                      4 /
                                      84) *
                                    96
                                  }px;`}
                                >
                                  {() => {
                                    const radioFieldInfo: any =
                                      radioFieldButtons(
                                        field.items.map((item: any) => ({
                                          title: item.title,
                                        })),
                                        field.fontSize,
                                        Number(convert(field.width, "px-in"))
                                      );
                                    return (
                                      <For each={field.items}>
                                        {(item: any, key: any) => (
                                          <div
                                            style={`position: absolute; display: flex; align-items: center; 
                                              left: ${
                                                radioFieldInfo.buttons[key()]
                                                  .frame.x
                                              }px;
                                              top: ${
                                                radioFieldInfo.buttons[key()]
                                                  .frame.y
                                              }px;
                                              width: ${
                                                radioFieldInfo.buttons[key()]
                                                  .frame.width
                                              }px;
                                              height: ${
                                                radioFieldInfo.buttons[key()]
                                                  .frame.height
                                              }px;
                                            `}
                                          >
                                            <span style="width: 16px; height: 16px; border: 1px solid #bdc2c5; border-radius: 50%;">
                                              <Show
                                                when={
                                                  record[field.uid] != "" &&
                                                  (record[field.uid] ==
                                                    item.id ||
                                                    record[field.uid] ==
                                                      item.title)
                                                }
                                              >
                                                <Icons
                                                  name="circleFill"
                                                  width={14}
                                                  height={14}
                                                ></Icons>
                                              </Show>
                                            </span>
                                            &nbsp;
                                            {item.title}
                                          </div>
                                        )}
                                      </For>
                                    );
                                  }}
                                </div>
                              </Show>
                              <Show when={field.type == "LineField"}>
                                <div
                                  style={`position: absolute; left: ${
                                    field.isVertical
                                      ? field.left - 22 + field.width / 2
                                      : field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 +
                                    (field.isVertical
                                      ? Number(field.top) + 1
                                      : Number(field.top) +
                                        Number(field.height) / 2) -
                                    1
                                  }px; width: ${
                                    field.isVertical ? "1" : Number(field.width)
                                  }px; height: ${
                                    field.isVertical
                                      ? Number(field.height)
                                      : "1"
                                  }px; border-${
                                    field.isVertical ? "right" : "bottom"
                                  }: ${field.thickness * 2}px ${
                                    field.lineStyle
                                  }; color: ${field.color}; z-index: 1;`}
                                ></div>
                              </Show>
                              <Show
                                when={
                                  field.type == "SignatureField" ||
                                  field.type == "NoteField"
                                }
                              >
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${
                                    String(field.parentTableId) == "0"
                                      ? field.width
                                      : Number(field.width) - 8
                                  }px; height: ${
                                    String(field.parentTableId) == "0"
                                      ? field.height
                                      : Number(field.height) - 2
                                  }px; border: ${
                                    formPrintData.formStructure
                                      .isPDFTextBoxEnabled
                                      ? "1px solid #bdc2c5"
                                      : "none"
                                  };`}
                                >
                                  <Show when={record[field.uid]}>
                                    <img src={record[field.uid]} />
                                  </Show>
                                  <Show
                                    when={
                                      field.type == "SignatureField" &&
                                      field.height > 72
                                    }
                                  >
                                    <div
                                      style={`position: absolute; left: 10px; bottom: 20px; right: 10px; width: auto; border-bottom: ${
                                        formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "2px solid"
                                          : "none"
                                      };`}
                                    ></div>
                                  </Show>
                                </div>
                              </Show>
                              <Show when={field.type == "LocationField"}>
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${Number(
                                    field.width
                                  )}px; height: ${
                                    Number(field.height) + 1
                                  }px; white-space: nowrap; overflow: hidden;`}
                                >
                                  <Show when={field.height > 36}>
                                    <img
                                      src={`https://maps.googleapis.com/maps/api/staticmap?center=${
                                        record[field.uid]
                                          ? record[field.uid]?.replace(" ", ",")
                                          : "Laguna Niguel"
                                      }&zoom=13&size=600x300&markers=color:red|${record[
                                        field.uid
                                      ]?.replace(" ", ",")}&key=${
                                        import.meta.env
                                          .VITE_GOOGLE_STATIC_MAP_API_KEY
                                      }`}
                                      style={`position: absolute; left: 0px; right: 0px; top: 0px; width: ${
                                        field.width - 1
                                      }px !important; height: ${
                                        field.height -
                                        Number(
                                          convert(
                                            Math.round(
                                              (field.fontSize / 72) * 4 + 0.49
                                            ) /
                                              4 +
                                              0.125,
                                            "in-px"
                                          )
                                        )
                                      }px; border: ${
                                        formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "1px solid"
                                          : ""
                                      }; border-bottom: none;`}
                                    />
                                    <div
                                      style={`position: absolute; left: 0px; right: 0px; height: ${Number(
                                        convert(
                                          Math.round(
                                            (field.fontSize / 72) * 4 + 0.49
                                          ) /
                                            4 +
                                            0.125,
                                          "in-px"
                                        )
                                      )}px; line-height: ${Number(
                                        convert(
                                          Math.round(
                                            (field.fontSize / 72) * 4 + 0.49
                                          ) /
                                            4 +
                                            0.125,
                                          "in-px"
                                        )
                                      )}px; top: ${
                                        field.height -
                                        Number(
                                          convert(
                                            Math.round(
                                              (field.fontSize / 72) * 4 + 0.49
                                            ) /
                                              4 +
                                              0.125,
                                            "in-px"
                                          )
                                        )
                                      }px; width: ${
                                        field.width - 1
                                      }px !important; border: ${
                                        formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "1px solid"
                                          : "none"
                                      }; font-size: ${
                                        (Math.round(
                                          (Number(field.fontSize) / 72) * 84 * 4
                                        ) /
                                          4 /
                                          84) *
                                        96
                                      }px;`}
                                    >
                                      {record[field.uid]}
                                    </div>
                                  </Show>
                                  <Show when={field.height <= 36}>
                                    <div
                                      style={`position: absolute; left: 0px; right: 0px; height: ${
                                        field.height
                                      }px; line-height: ${
                                        field.height
                                      }px; bottom: 0px; width: ${
                                        field.width - 1
                                      }px !important; border: ${
                                        formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "1px solid"
                                          : "none"
                                      };`}
                                    >
                                      {record[field.uid]}
                                    </div>
                                  </Show>
                                </div>
                              </Show>
                              <Show
                                when={
                                  field.type == "ImageField" &&
                                  field.imageBase64
                                }
                              >
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${field.width}px; height: ${
                                    field.height
                                  }px;`}
                                >
                                  <img
                                    src={`${
                                      field.imageBase64
                                        ? field.imageBase64.split("https://")
                                            .length > 1
                                          ? field.imageBase64
                                          : "data:image/png;base64," +
                                            field.imageBase64
                                        : `/photo2x.png`
                                    }`}
                                    style="height: 100%; width: 100%; object-fit: contain;"
                                  />
                                </div>
                              </Show>
                              <Show
                                when={
                                  field.type == "PhotoField" &&
                                  record[field.uid]
                                }
                              >
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${field.width}px; height: ${
                                    field.height
                                  }px;`}
                                >
                                  <img
                                    src={`${
                                      record[field.uid]
                                        ? record[field.uid].split("https://")
                                            .length > 1
                                          ? record[field.uid]
                                          : "data:image/png;base64," +
                                            record[field.uid]
                                        : `/photo2x.png`
                                    }`}
                                    style="height: 100%; width: 100%; object-fit: contain;"
                                  />
                                </div>
                              </Show>
                              <Show when={field.type == "BarcodeField"}>
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${Number(
                                    field.width
                                  )}px; height: ${field.height}px;`}
                                >
                                  <div
                                    style={`position: absolute; left: 0px; right: 0px; top: 0px; width: ${Number(
                                      field.width
                                    )}px; height: 26px; line-height: 26px; border: ${
                                      formPrintData.formStructure
                                        .isPDFTextBoxEnabled
                                        ? "1px solid"
                                        : "none"
                                    }; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;`}
                                  >
                                    {record[field.uid]}
                                  </div>
                                  <Show
                                    when={
                                      formPrintData.formStructure
                                        .isPDFTextBoxEnabled &&
                                      field.height > 36
                                    }
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        "align-items": "center",
                                        "justify-content": "center",
                                        position: "absolute",
                                        left: "0px",
                                        right: "0px",
                                        top: "28px",
                                        height: "calc(100% - 36px)",
                                        width: field.width + "px",
                                        border: formPrintData.formStructure
                                          .isPDFTextBoxEnabled
                                          ? "1px solid"
                                          : "none",
                                        "border-top": "none",
                                      }}
                                    >
                                      <img
                                        style={`width: auto; height: ${convert(
                                          Number(
                                            convert(field.height, "px-in")
                                          ) - 0.375,
                                          "in-px"
                                        )}px; max-height: 85px; max-width: 120px;`}
                                        src={
                                          record[field.uid] &&
                                          record[field.uid] != ""
                                            ? generateBarcode(record[field.uid])
                                            : "/photo2x.png"
                                        }
                                      />
                                    </div>
                                  </Show>
                                </div>
                              </Show>
                              <Show when={field.type == "AnnotationField"}>
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${Number(
                                    field.width
                                  )}px; height: ${field.height}px; border: ${
                                    formPrintData.formStructure
                                      .isPDFTextBoxEnabled
                                      ? "1px solid"
                                      : "none"
                                  };`}
                                >
                                  <Show
                                    when={
                                      field?.bgImageBase64 || record[field.uid]
                                    }
                                  >
                                    <img
                                      style="position: relative; width: 100%; height: 100%; object-fit: contain;"
                                      src={`data:image/png;base64,${
                                        record[field.uid].replaceAll("\r\n", "")
                                          ? record[field.uid].replaceAll(
                                              "\r\n",
                                              ""
                                            )
                                          : field?.bgImageBase64
                                      }`}
                                    ></img>
                                  </Show>
                                </div>
                              </Show>
                              <Show when={field.type == "TableField"}>
                                <div
                                  style={`position: absolute; left: ${
                                    field.left - 21
                                  }px; top: ${
                                    (count - 1) * 1123 + Number(field.top)
                                  }px; width: ${
                                    Number(field.width) - 44
                                  }px; height: ${
                                    field.height - 38
                                  }px; border: ${
                                    formPrintData.formStructure
                                      .isPDFTextBoxEnabled
                                      ? "1px solid black"
                                      : "none"
                                  }; z-index: 99;`}
                                ></div>
                              </Show>
                            </Show>
                          );
                        }}
                      </For>
                    );
                  })()
                }
              </For>
            );
          }}
        </For>
      </div>
    </>
  );
};
export { isPrinting, setIsPrinting };
export default PrintForm;
