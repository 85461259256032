import { Component, createSignal, onMount } from "solid-js";
import { RouteDataArgs, useParams, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken,  getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import "@thisbeyond/solid-select/style.css";
import GroupForm from "../_form";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "~/routes/api/validateUser";

export function routeData({ params }: RouteDataArgs) {
  validateUser();
  return createServerData$(
    async ([, groupId], { request }) => {
      const userDetails = await getUser(request, true);
      if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
        throw redirect("/logout");
      }
      const user_id: any = userDetails.data.id;
      const jwt_token: any = await getAuthToken(request);
      const group_id: any = groupId;
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/group/details`,
        jwt_token: jwt_token as string,
        body: {
          group_id,
        },
      };
      let groupDetails = await fetchApi(apiParams);
      if (groupDetails?.statusCode == 401) {
        throw redirect("/logout");
      }
      if (groupDetails?.status) {
        groupDetails = groupDetails.data;
        const selectedUsers: Array<string> = [];
        groupDetails.group_users.forEach((groupUser: any) => {
          selectedUsers.push(groupUser.name);
        });
      }
      const usersListApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/group/users/list`,
        jwt_token: jwt_token as string,
        body: {
          user_id,
          group_id: groupId,
          search_string: "",
          sort_string: "",
        },
      };
      const list = await fetchApi(usersListApiParams);
      if (list?.statusCode == 401) {
        throw redirect("/logout");
      }
      let usersList: any = undefined;
      if (list?.status) {
        usersList = list.data.unSelectedGroupsUsers;
        const index: any = list.data.selectedGroupsUsers.findIndex(
          (user: any) => user.id == list.data.adminUser.id
        );
        if (index == -1) {
          usersList.unshift({ ...list.data.adminUser, role: "admin" });
        } else {
          list.data.selectedGroupsUsers[index]["role"] = "admin";
        }
      }
      const editFormData = {
        groupId: groupDetails?.id,
        groupName: groupDetails?.name,
        selectedUsersList: list?.data?.selectedGroupsUsers,
      };

      return {
        user_id,
        jwt_token,
        usersList,
        groupDetails,
        editFormData,
      };
    },
    { key: () => ["group_details", params.groupId] }
  );
}

const groupEdit: Component = () => {
  setPageTitle(`${SITE_TITLE} - Edit Team`);
  const data = useRouteData<typeof routeData>();
  const { groupId } = useParams();


  return (
    <main>
      <SystemLayout>
        <div class="center">
          <div class="flex space-between">
            <h3 class="color title">Update Team</h3>
          </div>
          <div class="flex gap"></div>
          <br />
          <GroupForm
            list={data()?.usersList}
            type="edit"
            api_url={`${import.meta.env.VITE_API_URL}/group/edit`}
            userId={data()?.user_id}
            groupId={groupId}
            data={data()?.editFormData}
            jwt_token={data()?.jwt_token}
          />
        </div>
      </SystemLayout>
    </main>
  );
};

export default groupEdit;
