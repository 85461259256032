export default async function fetchApi(data: {
  method: string;
  url: string;
  jwt_token?: string;
  body?: any;
}) {
  try {
    const options: any = {
      method: data.method,
      headers: {
        Authorization: `Bearer ${data.jwt_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    };
    if (data.body !== undefined) {
      options.body = JSON.stringify(data.body);
    }
    const response = await fetch(data.url, options);
    return response.json();
  } catch (error) {
    return { error };
  }
}
