import { Component } from "solid-js";
import Popup from "../global/Popup";
import { add, format } from "date-fns";
import { userSubscriptionDate } from "~/store/user-form-role";
import fetchApi from "~/lib/api";
import { removePopup } from "~/store/global.store";
import { notify } from "../global/Notification";
import messages from "~/helpers/message";
import { useNavigate } from "solid-start";

interface Props {
  user_id: string;
  jwt_token: string;
}

const TrialEndPopup: Component<Props> = (props) => {
  const navigate = useNavigate();

  return (
    <Popup heading="End Trial Subscription" classes="small" name="TrialEnd">
      <div class="flex space-between">
        <label class="flex">
          <div class="w-183p">Subscription State</div>
          <div class="text-gray">:</div>
        </label>
        <span class="lbl color">Trialing</span>
      </div>
      <div class="underline"></div>
      <div class="flex space-between">
        <label class="flex">
          <div class="w-183p">Subscription Date</div>
          <div class="text-gray">:</div>
        </label>
        <span class="lbl color">
          {format(new Date(userSubscriptionDate()), "MMMM,dd yyyy")}
        </span>
      </div>
      <div class="underline"></div>
      <div class="flex space-between">
        <label class="flex">
          <div class="w-183p">Trial End Date</div>
          <div class="text-gray">:</div>
        </label>
        <span class="lbl color">
          {format(
            new Date(
              add(new Date(userSubscriptionDate()), {
                days: import.meta.env.VITE_PADDLE_TRIAL_DAYS,
              })
            ),
            "MMMM,dd yyyy"
          )}
        </span>
      </div>
      <div class="underline"></div>
      <div class="text-center">
        <button
          class="btn sm danger"
          onclick={async () => {
            const apiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/subscription/trial-end`,
              jwt_token: props.jwt_token,
              body: {
                user_id: props.user_id,
              },
            };
            let endTrial = await fetchApi(apiParams);
            if (endTrial?.statusCode == 401) {
              window.location.href = "/logout";
            } else if (endTrial?.statusCode == 403) {
              location.reload();
            }
            if (endTrial && endTrial.status) {
              navigate("/checkout");
            } else {
              notify({
                isNotified: true,
                message: messages.error.error,
                theme: "danger",
                placement: "top",
                timeout: 2500,
              });
              removePopup("TrialEnd");
            }
          }}
        >
          End Trial Now
        </button>
      </div>
    </Popup>
  );
};

export default TrialEndPopup;
