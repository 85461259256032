import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Checkbox from "../global/Checkbox";
import Icons from "../icons/Icons";
import { activeField, setActiveField } from "~/store/global.store";
import { cloudServices, exportFormats } from "~/store/field-editor.store";
import { formJson } from "~/store/form.store";
import { createStore } from "solid-js/store";
import {
  exportFilesData,
  fetchBoxFoldersList,
  fetchDropboxFoldersList,
  fetchGoogleDriveFoldersList,
  fetchOneDriveFoldersList,
  getBoxCurrentAccountDetails,
  getDropBoxCurrentAccountDetails,
  getGoogleDriveCurrentAccountDetails,
  getOneDriveCurrentAccountDetails,
  serviceTokens,
  setExportFilesData,
  setServiceTokens,
} from "~/store/export-files.store";
import fetchApi from "~/lib/api";
import { A } from "solid-start";

interface Props {
  user_id: string;
  jwt_token: string;
  form_id: string;
}

const [autoExportSettings, setAutoExportSettings] = createStore({
  service: "none",
  formats: [],
  settings: {},
}) as any;
const [mailDetails, setMailDetails] = createStore({
  mail_subject: "",
  mail_message: "",
  mail_recipients: "",
}) as any;

const AutoExportSettings: Component<Props> = (props: any) => {
  const [isLoading, setIsLoading] = createSignal(false as boolean);
  const [hasServiceAccess, setHasServiceAccess] = createSignal(
    false as boolean
  );
  const [prompting, setPrompting] = createSignal(false as boolean);

  createEffect(async () => {
    if (formJson.root.formStructure?.autoExportSetting) {
      setAutoExportSettings(formJson.root.formStructure?.autoExportSetting);
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/auto/export/get`,
        jwt_token: props.jwt_token as string,
        body: {
          user_id: props.user_id,
          form_id: props.form_id,
        },
      };
      let exportSettings = await fetchApi(apiParams);
      if (
        exportSettings?.statusCode == 401 ||
        exportSettings?.statusCode == 403
      ) {
        window.location.href = "/logout";
      }
      if (
        exportSettings &&
        exportSettings.status &&
        exportSettings.data &&
        Object.keys(exportSettings.data)?.length > 0
      ) {
        setAutoExportSettings({
          settings: exportSettings.data,
        });
        setPrompting(exportSettings.data.is_without_prompting);
      }
      updateFolderStructure(
        formJson.root.formStructure?.autoExportSetting.service
      );
    }
    if (autoExportSettings.service == "email") {
      setMailDetails({
        mail_subject: autoExportSettings.settings?.mail_subject,
        mail_message: autoExportSettings.settings?.mail_message,
        mail_recipients: autoExportSettings.settings?.mail_recipients,
      });
    } else {
      setMailDetails({
        mail_subject: "",
        mail_message: "",
        mail_recipients: "",
      });
    }
  });

  async function updateFolderStructure(service: string) {
    setIsLoading(true);
    const serviceTokensParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/service/tokens/list`,
      jwt_token: props.jwt_token,
      body: { user_id: props.user_id },
    };
    let serviceTokens = await fetchApi(serviceTokensParams);
    if (serviceTokens?.statusCode == 401 || serviceTokens?.statusCode == 403) {
      window.location.href = "/logout";
    }
    serviceTokens = serviceTokens.data;
    setServiceTokens(serviceTokens);
    if (serviceTokens && serviceTokens[service]) {
      switch (service) {
        case "dropbox":
          getDropBoxCurrentAccountDetails(serviceTokens["dropbox"]).then(
            (res: any) => {
              if (res && res?.account_id) {
                setHasServiceAccess(true);
                fetchDropboxFoldersList(
                  serviceTokens["dropbox"],
                  autoExportSettings.settings["dropbox_path"]
                    ? autoExportSettings.settings["dropbox_path"]
                    : ""
                ).then(() => setIsLoading(false));
              } else {
                setHasServiceAccess(false);
                setIsLoading(false);
                updateAutoExportSettings();
              }
            }
          );
          break;
        case "box":
          getBoxCurrentAccountDetails(
            props.jwt_token,
            serviceTokens["box"]
          ).then((res: any) => {
            if (res) {
              setHasServiceAccess(true);
              fetchBoxFoldersList(
                props.jwt_token,
                serviceTokens["box"],
                autoExportSettings.settings["box_path"] &&
                  autoExportSettings.settings["box_path"] != ":"
                  ? autoExportSettings.settings["box_path"]
                      .split(":")[0]
                      .split("/")[
                      autoExportSettings.settings["box_path"]
                        .split(":")[0]
                        .split("/").length - 1
                    ]
                  : "0",
                autoExportSettings.settings["box_path"]
                  ? autoExportSettings.settings["box_path"]?.split(":")[1]
                  : "",
                autoExportSettings.settings["box_path"]
                  ? autoExportSettings.settings["box_path"]?.split(":")[0]
                  : "0"
              ).then(() => setIsLoading(false));
            } else {
              setHasServiceAccess(false);
              setIsLoading(false);
              updateAutoExportSettings();
            }
          });
          break;
        case "onedrive":
          getOneDriveCurrentAccountDetails(serviceTokens["onedrive"]).then(
            (res: any) => {
              if (res && res?.id) {
                setHasServiceAccess(true);
                fetchOneDriveFoldersList(
                  serviceTokens["onedrive"],
                  autoExportSettings.settings["onedrive_path"] &&
                    autoExportSettings.settings["onedrive_path"] != ":"
                    ? autoExportSettings.settings["onedrive_path"]
                        .split(":")[0]
                        .split("/")[
                        autoExportSettings.settings["onedrive_path"]
                          .split(":")[0]
                          .split("/").length - 1
                      ]
                    : "",
                  autoExportSettings.settings["onedrive_path"]
                    ? autoExportSettings.settings["onedrive_path"]?.split(
                        ":"
                      )[1]
                    : "",
                  autoExportSettings.settings["onedrive_path"]
                    ? autoExportSettings.settings["onedrive_path"]?.split(
                        ":"
                      )[0]
                    : "0"
                ).then(() => setIsLoading(false));
              } else {
                setHasServiceAccess(false);
                setIsLoading(false);
                updateAutoExportSettings();
              }
            }
          );
          break;
        case "googledrive":
          getGoogleDriveCurrentAccountDetails(
            serviceTokens["googledrive"]
          ).then((res: any) => {
            if (res && res?.user) {
              setHasServiceAccess(true);
              fetchGoogleDriveFoldersList(
                serviceTokens["googledrive"],
                autoExportSettings.settings["googledrive_path"] &&
                  autoExportSettings.settings["googledrive_path"] != ":"
                  ? autoExportSettings.settings["googledrive_path"]
                      .split(":")[0]
                      .split("/")[
                      autoExportSettings.settings["googledrive_path"]
                        .split(":")[0]
                        .split("/").length - 1
                    ]
                  : "",
                autoExportSettings.settings["googledrive_path"]
                  ? autoExportSettings.settings["googledrive_path"]?.split(
                      ":"
                    )[1]
                  : "",
                autoExportSettings.settings["googledrive_path"]
                  ? autoExportSettings.settings["googledrive_path"]?.split(
                      ":"
                    )[0]
                  : "0"
              ).then(() => setIsLoading(false));
            } else {
              setHasServiceAccess(false);
              setIsLoading(false);
              updateAutoExportSettings();
            }
          });
          break;
        default:
          break;
      }
    }
  }

  async function updateAutoExportSettings() {
    if (autoExportSettings.service != "none") {
      const content: any = {
        user_id: props.user_id,
        form_id: props.form_id,
        is_without_prompting: prompting(),
        service: autoExportSettings.service,
      };
      if (autoExportSettings.service == "email") {
        content["mail_subject"] = mailDetails.mail_subject;
        content["mail_message"] = mailDetails.mail_message;
        content["mail_recipients"] = mailDetails.mail_recipients;
      } else {
        content["service_path"] =
          autoExportSettings.service == "dropbox"
            ? exportFilesData.path
            : exportFilesData.idsPath + ":" + exportFilesData.path;
      }
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/auto/export/save`,
        jwt_token: props.jwt_token as string,
        body: content,
      };
      let exportSettings = await fetchApi(apiParams);
      if (exportSettings?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (exportSettings?.statusCode == 403) {
        location.reload();
      }
    }
  }

  return (
    <>
      <div>
        <br />
        <div class="app-flex flex space-between align-center">
          <label>Export Method:</label>
          <select
            class="input w-40"
            onchange={(e: any) => {
              setAutoExportSettings({ service: e.target.value });
              if (e.target.value == "email") {
                setMailDetails({
                  mail_subject: autoExportSettings.settings?.mail_subject,
                  mail_message: autoExportSettings.settings?.mail_message,
                  mail_recipients: autoExportSettings.settings?.mail_recipients,
                });
              }
              setExportFilesData({
                foldersList: [],
                path: "",
                idsPath: "",
              });
              updateFolderStructure(e.target.value);
            }}
          >
            <For
              each={[
                { value: "", label: "Select Export Method" },
                { value: "none", label: "None" },
                ...cloudServices,
              ]}
            >
              {(item: any, key: any) => (
                <option
                  value={item.value}
                  disabled={key() == 0 ? true : false}
                  selected={
                    item.value == autoExportSettings.service ? true : false
                  }
                >
                  {item.label}
                </option>
              )}
            </For>
          </select>
        </div>
        <Show when={autoExportSettings.service != "none"}>
          <br />
          <div class="flex gap-2">
            <label class="p-5">Formats:</label>
            <div class="app-flex flex flex-wrap w-100">
              <For each={exportFormats}>
                {(type: any) => (
                  <div class="w-30 p-5">
                    <Checkbox
                      label={type.label}
                      name={type.value}
                      value={
                        autoExportSettings.formats.includes(type.value)
                          ? true
                          : (false as boolean)
                      }
                      setValue={() => {
                        const formats: any = [...autoExportSettings.formats];
                        const index: number = formats.findIndex(
                          (item: any) => item === type.value
                        );
                        if (index == -1) {
                          formats.push(type.value);
                        } else {
                          formats.splice(index, 1);
                        }
                        setAutoExportSettings({
                          formats,
                        });
                      }}
                    />
                  </div>
                )}
              </For>
            </div>
          </div>
        </Show>
        <Show when={autoExportSettings.service == "email"}>
          <div class="p-2">
            <div class="app-flex flex gap align-center">
              <label class="min-w-80" for="email_subject">
                Subject:
              </label>
              <div class="app-flex flex align-center space-between pr">
                <input
                  type="text"
                  class="input"
                  onclick={() => {
                    setActiveField("email_subject");
                  }}
                  name="email_subject"
                  id="email_subject"
                  value={
                    mailDetails.mail_subject ? mailDetails.mail_subject : ""
                  }
                  onchange={(e: any) => {
                    setMailDetails({
                      mail_subject: e.target.value,
                    });
                    updateAutoExportSettings();
                  }}
                />
                <Show when={activeField() == "email_subject"}>
                  <span class="pa z-index-1 cur-p r-5">
                    <Icons name="circleX" width={20} height={20}></Icons>
                  </span>
                </Show>
              </div>
            </div>
            <br />
            <div class="app-flex flex gap">
              <label class="min-w-80" for="email_message">
                Message:
              </label>
              <textarea
                class="w-100"
                name="email_message"
                id="email_message"
                cols="30"
                rows="10"
                onchange={(e: any) => {
                  setMailDetails({
                    mail_message: e.target.value,
                  });
                  updateAutoExportSettings();
                }}
              >
                {mailDetails.mail_message}
              </textarea>
            </div>
            <br />
            <div class="app-flex flex gap">
              <div class="min-w-80">
                <label for="email_message">Recipients:</label>
                <br />
                <small class="fs-12">(one per line)</small>
              </div>
              <textarea
                class="w-100"
                name="email_message"
                id="email_message"
                cols="30"
                rows="10"
                onchange={(e: any) => {
                  setMailDetails({
                    mail_recipients: e.target.value,
                  });
                  updateAutoExportSettings();
                }}
              >
                {mailDetails.mail_recipients}
              </textarea>
            </div>
          </div>
        </Show>
        <Show
          when={
            autoExportSettings.service != "none" &&
            autoExportSettings.service != "email"
          }
        >
          <div class="p-2">
            <div class="app-flex flex gap">
              <label
                class="min-w-210 user-select-none"
                for="upload_without_prompting_id"
              >
                Upload without prompting:
              </label>
              <Checkbox
                label=""
                name="upload_without_prompting"
                value={
                  autoExportSettings?.settings?.is_without_prompting
                    ? true
                    : (false as boolean)
                }
                setValue={(val: boolean) => {
                  setPrompting(val);
                  updateAutoExportSettings();
                }}
              />
            </div>
          </div>
          <Show when={!hasServiceAccess() && !isLoading()}>
            <div class="alert-text">
              <b>
                Please export a record once before choosing "Auto Export"
                settings.
              </b>
            </div>
          </Show>
          <Show when={hasServiceAccess() && !isLoading()}>
            <div class="app-flex flex align-center space-between bg-light lbl p-5 fw-800">
              <span class="app-fs-12">
                {`${
                  cloudServices.filter(
                    (service: any) =>
                      service.value == autoExportSettings.service
                  )[0]?.label
                }${exportFilesData.path.replaceAll("/", " > ")}`}
              </span>
              <Show when={exportFilesData.path != ""}>
                <A
                  class="a-link inline-flex"
                  href=""
                  onclick={() => {
                    setIsLoading(true);
                    const segments = exportFilesData.path.split("/");
                    let idsPath: any;
                    segments.pop();
                    switch (autoExportSettings.service) {
                      case "dropbox":
                        fetchDropboxFoldersList(
                          serviceTokens()["dropbox"],
                          segments.join("/")
                        ).then(() => {
                          setIsLoading(false);
                          updateAutoExportSettings();
                        });
                        break;
                      case "box":
                        idsPath = exportFilesData.idsPath.split("/");
                        idsPath.pop();
                        fetchBoxFoldersList(
                          props.jwt_token,
                          serviceTokens()["box"],
                          idsPath[idsPath.length - 1],
                          segments.join("/"),
                          idsPath.join("/")
                        ).then(() => {
                          setIsLoading(false);
                          updateAutoExportSettings();
                        });
                        break;
                      case "onedrive":
                        idsPath = exportFilesData.idsPath.split("/");
                        idsPath.pop();
                        fetchOneDriveFoldersList(
                          serviceTokens()["onedrive"],
                          idsPath[idsPath.length - 1],
                          segments.join("/"),
                          idsPath.join("/")
                        ).then(() => {
                          setIsLoading(false);
                          updateAutoExportSettings();
                        });
                        break;
                      case "googledrive":
                        idsPath = exportFilesData.idsPath.split("/");
                        idsPath.pop();
                        fetchGoogleDriveFoldersList(
                          serviceTokens()["googledrive"],
                          idsPath[idsPath.length - 1],
                          segments.join("/"),
                          idsPath.join("/")
                        ).then(() => {
                          setIsLoading(false);
                          updateAutoExportSettings();
                        });
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <Icons name="arrowLeft" stroke="hsl(214, 56%, 41%)" />
                  &nbsp; Back
                </A>
              </Show>
            </div>
          </Show>
          <br />
          <div
            class={`app-flex flex gap flex-wrap ${
              isLoading() ? "justify-center" : ""
            }`}
          >
            <Show when={isLoading()}>
              <div class="app-flex flex align-center">
                <h3 class="lbl">Loading</h3>&nbsp;
                <Icons name="loader"></Icons>
              </div>
            </Show>
            <Show when={!isLoading()}>
              <For each={exportFilesData.foldersList}>
                {(folder: any) => (
                  <div
                    class="pr"
                    onclick={() => {
                      setIsLoading(true);
                      switch (autoExportSettings.service) {
                        case "dropbox":
                          fetchDropboxFoldersList(
                            serviceTokens()["dropbox"],
                            exportFilesData.path + "/" + folder.name
                          ).then(() => {
                            setIsLoading(false);
                            updateAutoExportSettings();
                          });
                          break;
                        case "box":
                          fetchBoxFoldersList(
                            props.jwt_token,
                            serviceTokens()["box"],
                            folder.id,
                            `${exportFilesData.path}/${folder.name}`,
                            `${exportFilesData.idsPath}/${folder.id}`
                          ).then(() => {
                            setIsLoading(false);
                            updateAutoExportSettings();
                          });
                          break;
                        case "onedrive":
                          fetchOneDriveFoldersList(
                            serviceTokens()["onedrive"],
                            folder.id,
                            `${exportFilesData.path}/${folder.name}`,
                            `${exportFilesData.idsPath}/${folder.id}`
                          ).then(() => {
                            setIsLoading(false);
                            updateAutoExportSettings();
                          });
                          break;
                        case "googledrive":
                          fetchGoogleDriveFoldersList(
                            serviceTokens()["googledrive"],
                            folder.id,
                            `${exportFilesData.path}/${folder.name}`,
                            `${exportFilesData.idsPath}/${folder.id}`
                          ).then(() => {
                            setIsLoading(false);
                            updateAutoExportSettings();
                          });
                          break;
                        default:
                          break;
                      }
                    }}
                  >
                    <img
                      src="/form_themes/folder.png"
                      class="service-folder-image"
                    />
                    <label class="service-folder-name">{folder.name}</label>
                  </div>
                )}
              </For>
            </Show>
          </div>
        </Show>
      </div>
    </>
  );
};

export { autoExportSettings };
export default AutoExportSettings;
