import { createSignal } from "solid-js";
import { formJson, setOffset } from "./form.store";
import { format } from "date-fns";
import { nestedFieldData } from "~/components/fields/DropdownField";
import { createStore, produce } from "solid-js/store";
import fetchApi from "~/lib/api";
import { localList } from "./locale.store";
import {
  dateFormats,
  formsList,
  recordDeleteRight,
  recordsSearch,
  selectedFolderData,
  setAlertMessage,
  setContextMenu,
  setFormsList,
  setIsPageLoader,
  setPopup,
  setSelectedFolderData,
  timeFormats,
} from "./global.store";
import {
  recordsOrder,
  selectedUsers,
  setRecordsOrder,
  setSelectedUsers,
} from "~/components/popup/SortRecordsPopup";
import { notify } from "~/components/global/Notification";
import { Parser } from "expr-eval";
import {
  totalStorageUsed,
  totalUserLicences,
  userRole,
} from "./user-form-role";
import messages from "~/helpers/message";

const [preLoadData, setPreLoadData] = createSignal([] as any);
const [additionalData, setAdditionalData] = createSignal({} as any);
const [recordsJson, setRecordsJson] = createStore({
  records: [] as any,
  currentRecord: {} as {
    [key: string]: string | boolean | number | undefined | any;
  },
  multipleRecords: [] as any,
});
const [recordsList, setRecordsList] = createSignal([] as any);
const [updateRecordDetails, setUpdateRecordDetails] = createSignal({} as any);

const [calculatedArr, setCalculatedArr] = createSignal([] as any);

const [recordToDeleteId, setRecordToDeleteId] = createSignal(
  [] as Array<string>
);

const [isCurrentRecordLocked, setIsCurrentRecordLocked] = createSignal(
  false as boolean
);

const [selectedRecordId, setSelectedRecordId] = createSignal("" as string);
const [selectedRecord, setSelectedRecord] = createSignal("" as string);

const [totalRecordsCount, setTotalRecordsCount] = createSignal(0 as number);
const [totalRecordsShowCount, setTotalRecordsShowCount] = createSignal(
  0 as number
);

export const [deletedRecordsCount, setDeletedRecordsCount] = createSignal(
  0 as number
);

export const [imageFile, setImageFile] = createSignal([] as any);

const [isRecordUpdated, setIsRecordUpdated] = createSignal("" as string);

export const [isImageUploaded, setIsImageUploaded] = createSignal(
  false as boolean
);

export const [isRecordSubmit, setIsRecordSubmit] = createSignal(
  false as boolean
);
export const [recordSaveCancelAction, setRecordSaveCancelAction] = createSignal(
  "" as string
);

const [isClonedRecord, setIsClonedRecord] = createSignal(false as boolean);

export const [linkedFormRecordsData, setLinkedFormRecordsData] = createSignal(
  [] as any
);

export const [isLoader, setIsLoader] = createSignal(false as boolean);
export const [formRecords, setFormRecords] = createSignal([] as any);

export const [isFormChangesCanceled, setIsFormChangesCanceled] = createSignal(
  false as boolean
);

export const [updatedRecordImages, setUpdatedRecordImages] = createSignal(
  [] as any
);

export const [isRecentRecords, setIsRecentRecords] = createSignal(
  false as boolean
);

export function updatePreLoadData() {
  const arr: any = [];
  formJson.pages?.forEach((page: any, key: any) => {
    if (Object.keys(page)?.length > 0) {
      const pageArr: any = [];
      page?.fields?.forEach((field: any) => {
        if (
          field.type != "LabelField" &&
          field.type != "LineField" &&
          field.type != "TableField" &&
          field.type != "ImageField" &&
          field.type != "ScriptButtonField"
        ) {
          const tempObject: any = {
            id: field.properties.dataFieldSync.uid,
            label: field.properties.dataFieldSync.label,
            top: field.properties.dataFieldSync.frame[0][1],
            left: field.properties.dataFieldSync.frame[0][0],
            page: key,
          };
          pageArr.push(tempObject);
        }
      });
      arr.push(pageArr);
    } else {
      arr.push([]);
    }
  });
  arr.forEach((item: any) => {
    item.sort((a: any, b: any) => {
      if (a.page !== b.page) {
        return a.page - b.page;
      }
      if (a.top === b.top) {
        return a.left - b.left;
      }
      return a.top - b.top;
    });
  });
  const finalArr: any = [];
  arr.forEach((item: any, index: any) => {
    if (item.length > 0) {
      const pageChar: any = String.fromCharCode(65 + index);
      item.forEach((val: any, index2: any) => {
        const key = val.id;
        const value = pageChar + ((index2 % item.length) + 1);
        finalArr[key] = value;
      });
    }
  });
  setCalculatedArr(finalArr);
  if (Object.keys(recordsJson.currentRecord).length == 0) {
    const preLoadData: any = getPreLoadData(formJson, finalArr);
    setPreLoadData(preLoadData);
  }
}

export function getPreLoadData(formJson: any, finalArr?: any) {
  const tempArr: any = [];
  formJson.pages.forEach((page: any) => {
    page?.fields?.forEach(async (field: any) => {
      let calculatedFieldValue: any = "";
      if (
        finalArr &&
        field.properties?.inputType == "calculated" &&
        field.properties?.calculationFormula != ""
      ) {
        extractValues(field.properties.calculationFormula).forEach(
          (item: any, key: any) => {
            if (findKeyByValue(finalArr, item)) {
              if (tempArr[findKeyByValue(finalArr, item)]) {
                calculatedFieldValue += Number(
                  tempArr[findKeyByValue(finalArr, item)] == "true"
                    ? 1
                    : tempArr[findKeyByValue(finalArr, item)] == "false"
                    ? 0
                    : tempArr[findKeyByValue(finalArr, item)]
                );
              } else {
                calculatedFieldValue += 0;
              }
            } else {
              calculatedFieldValue += item;
            }
          }
        );
        const parser = new Parser();
        const expression = parser.parse(calculatedFieldValue);
        const result = expression.evaluate({});
        calculatedFieldValue = result
          ? result.toFixed(field.properties.decimalPlaces)
          : "0";
      }
      if (field.properties?.dateInputType == "calculated") {
        if (field.properties.calculationResultFormat == "day_hour_min") {
          calculatedFieldValue = "0 mins";
        } else if (field.properties.calculationResultFormat == "hour_min") {
          calculatedFieldValue = "0 mins";
        }
      }
      tempArr[field.properties.dataFieldSync.uid] = field.properties
        ?.isPrePopulated
        ? field.properties.inputType == "currency"
          ? formJson.root.formStructure?.locale
            ? new Intl.NumberFormat(
                formJson.root.formStructure?.locale?.replace("_", "-"),
                {
                  style: "currency",
                  currency: localList.filter((obj: any) =>
                    obj.value === formJson.root.formStructure?.locale
                      ? formJson.root.formStructure?.locale
                      : ""
                  )[0]?.currency,
                }
              ).format(field.properties?.prePopulatedText)
            : ""
          : field.properties?.prePopulatedText
        : field.properties?.isDateTimeStamp
        ? dateFormats.includes(field.properties.dateFormat)
          ? format(new Date(), "yyyy-MM-dd")
          : timeFormats.includes(field.properties.dateFormat)
          ? format(new Date(), "HH:mm")
          : format(new Date(), "yyyy-MM-dd HH:mm")
        : field.properties.choiceFieldSync?.preSelected != -1 &&
          field.properties.choiceFieldSync?.linkType != "linked_child"
        ? field.properties.choiceFieldSync?.items.filter(
            (item: any) =>
              item.id == field.properties.choiceFieldSync?.preSelected
          )[0]?.id
        : calculatedFieldValue != ""
        ? calculatedFieldValue
          ? calculatedFieldValue
          : 0
        : "";
      if (field.properties?.isCheckedByDefault != undefined) {
        tempArr[field.properties.dataFieldSync.uid] =
          field.properties?.isCheckedByDefault;
      }
      if (tempArr[field.properties.dataFieldSync.uid] == undefined) {
        tempArr[field.properties.dataFieldSync.uid] = "";
      }
      if (typeof tempArr[field.properties.dataFieldSync.uid] != "string") {
        tempArr[field.properties.dataFieldSync.uid] = String(
          tempArr[field.properties.dataFieldSync.uid]
        );
      }
    });
  });
  formJson.pages.forEach((page: any) => {
    page?.fields?.forEach((field: any) => {
      if (
        field.properties.dataFieldSync.linkedFieldId != 0 &&
        field.properties.choiceFieldSync?.linkType != "linked_nested"
      ) {
        tempArr[field.properties.dataFieldSync.uid] =
          tempArr[field.properties.dataFieldSync.linkedFieldId];
      }
    });
  });
  return tempArr;
}

export function alertFormRecords() {
  if (userRole() == "viewer") {
    return true;
  }
  let requiredFieldAlert: string = "";
  let minMaxFieldAlert: string = "";
  formJson.pages.forEach((page: any) => {
    page.fields?.forEach((field: any) => {
      if (
        field.properties.dataFieldSync.isRequired &&
        preLoadData()[field.properties.dataFieldSync.uid] == ""
      ) {
        requiredFieldAlert +=
          "<li>" +
          field.properties.dataFieldSync.label.replace(/:$/, "") +
          "</li>";
      }
      if (
        field.properties.dataFieldSync?.minimum &&
        field.properties.dataFieldSync?.maximum &&
        preLoadData()[field.properties.dataFieldSync.uid] != "" &&
        (preLoadData()[field.properties.dataFieldSync.uid] <
          Number(field.properties.dataFieldSync?.minimum) ||
          preLoadData()[field.properties.dataFieldSync.uid] >
            Number(field.properties.dataFieldSync?.maximum))
      ) {
        minMaxFieldAlert = `<b>Incorrect field value:</b><br/>${field.properties.dataFieldSync.label.replace(
          /:$/,
          ""
        )} must be between ${field.properties.dataFieldSync?.minimum} and ${
          field.properties.dataFieldSync?.maximum
        }`;
      }
    });
  });
  if (requiredFieldAlert != "") {
    setPopup("alert");
    setAlertMessage(
      `<b>Fields are required:</b><ul class="mb-0">${requiredFieldAlert}</ul>`
    );
    return false;
  }
  if (minMaxFieldAlert != "") {
    setPopup("alert");
    setAlertMessage(minMaxFieldAlert);
    return false;
  }
  return true;
}

export async function updateFormRecordsList(form_id: string, offset: number) {
  if (offset == 0) {
    setIsLoader(true);
  }
  const formRecordsApiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/records/list`,
    jwt_token: updateRecordDetails().jwt_token,
    body: {
      user_id: updateRecordDetails().user_id,
      form_id,
      search_string: recordsSearch(),
      offset,
      limit: 20,
      api: "web",
      is_recent: isRecentRecords(),
    },
  };
  let formRecords = await fetchApi(formRecordsApiParams);
  if (formRecords?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (formRecords?.statusCode == 403) {
    location.reload();
  }
  if (formRecords && formRecords.status) {
    if (offset == 0) {
      setRecordsJson({
        records: [],
      });
      setRecordsList([]);
    }
    setSelectedUsers(
      formRecords.data.recordsUserOrder
        ? formRecords.data.recordsUserOrder?.split(",")
        : []
    );
    setIsPageLoader(false);
    setRecordsOrder(formRecords.data?.recordsOrder);
    setTotalRecordsCount(
      formRecords.data?.totalRecords ? formRecords.data?.totalRecords : 0
    );
    // setTotalRecordsShowCount(
    //   formRecords.data?.totalRecords ? formRecords.data?.totalRecords : 0
    // );
    setDeletedRecordsCount(formRecords.data.deletedRecords);
    if (recordsSearch() == "") {
      setFormRecords(formRecords);
    }
    setIsLoader(false);
    formRecords = formRecords.data.records;
    if (selectedUsers().length == 0) {
      setRecordsJson({
        records: [...recordsJson.records, ...formRecords],
      });
    } else {
      setRecordsJson({
        records: [
          ...recordsJson.records,
          ...formRecords.flatMap((list: any) => list.subList),
        ],
      });
    }
    updateRecordsList(formRecords);
    // setTotalRecordsShowCount(formRecords.length);
  }
}

export function addNewRecord(form_id: string, uid?: string, value?: string) {
  if (uid == undefined) {
    updatePreLoadData();
  }
  const records: any = [...recordsJson.records];
  const newRecordsData: any = [];
  formJson.pages.forEach((page: any, key: any) => {
    const newRecordsObject: any = {
      id: key + 1,
      fields: [],
    };
    page.fields?.forEach((field: any) => {
      newRecordsObject.fields.push({
        value:
          field.properties.dataFieldSync.uid == uid
            ? value
            : preLoadData()[field.properties.dataFieldSync.uid],
        field_id: field.properties.dataFieldSync.uid,
        additional_data: {},
      });
    });
    newRecordsData.push(newRecordsObject);
  });
  let newRecordName: any = [];
  const fields: any =
    formJson.root.formStructure?.sortFields &&
    formJson.root.formStructure?.sortFields?.length > 0
      ? formJson.root.formStructure.sortFields.flatMap((field: any) =>
          typeof field == "string"
            ? field.split(",").map((field: any) => field.trim())
            : field
        )
      : formJson.root.formStructure.primaryFields;
  fields.forEach((id: string) => {
    let type = "";
    formJson.pages.forEach((page: any) => {
      page.fields?.forEach((item: any) => {
        if (item.properties.dataFieldSync.uid == id) {
          type = item.type;
        }
      });
    });
    preLoadData()[id] != ""
      ? newRecordName.push(
          type == "DateField"
            ? preLoadData()[id]?.replaceAll("-", "/")
            : preLoadData()[id]
        )
      : "";
  });
  const newRecord: any = {
    id:
      formJson.root.formStructure.uuid +
      ":" +
      crypto.randomUUID().toUpperCase(),
    form_uuid: formJson.root.formStructure.uuid,
    name: newRecordName.length > 0 ? newRecordName.join(" ") : "Unnamed",
    is_locked: false,
    pages: newRecordsData,
  };
  records.unshift(newRecord);
  setRecordsJson({
    records,
    currentRecord: records[0],
  });
  addRecord(uid, value);
}

export function cloneRecord(form_id: string) {
  if (
    Object.keys(recordsJson.currentRecord).length > 0 ||
    recordsJson.multipleRecords.length > 0
  ) {
    const record: any = { ...recordsJson.currentRecord };
    delete record.record_id;
    let tempArr: any = [];
    if (recordsJson.multipleRecords.length) {
      recordsJson.multipleRecords.forEach(async (record: any) => {
        const formRecordsApiParams = {
          method: "POST",
          url: `${import.meta.env.VITE_API_URL}/form/record`,
          jwt_token: updateRecordDetails().jwt_token,
          body: {
            user_id: updateRecordDetails().user_id,
            record_id: [
              recordsJson.records.filter(
                (item: any) => item.id == record.record_id
              )[0].record_id,
            ],
          },
        };
        let recordData = await fetchApi(formRecordsApiParams);
        if (recordData?.statusCode == 401) {
          window.location.href = "/logout";
        } else if (recordData?.statusCode == 403) {
          location.reload();
        }
        if (recordData.status && recordData.data[0].record) {
          tempArr = [JSON.parse(recordData.data[0].record)];
          updateCloneRecordData(tempArr, form_id);
        }
      });
    } else {
      tempArr = [record];
      updateCloneRecordData(tempArr, form_id);
    }
  }
}

export function updateCloneRecordData(tempArr: any, form_id: string) {
  tempArr.forEach((item: any) => {
    const records: any = [...recordsJson.records];
    const newRecordsData: any = [];
    formJson.pages.forEach((page: any, key: any) => {
      const newRecordsObject: any = {
        id: key + 1,
        fields: [],
      };
      page.fields?.forEach((field: any) => {
        newRecordsObject.fields.push({
          value: field.properties.dataFieldSync?.shouldCopyWhenClone
            ? item.pages[key].fields.filter(
                (obj: any) => obj.field_id == field.properties.dataFieldSync.uid
              )[0]?.value ?? ""
            : "",
          field_id: field.properties.dataFieldSync.uid,
          additional_data: {},
        });
      });
      newRecordsData.push(newRecordsObject);
    });
    let newRecordName: any = [];
    const fields: any =
      formJson.root.formStructure?.sortFields &&
      formJson.root.formStructure?.sortFields?.length > 0
        ? formJson.root.formStructure.sortFields.flatMap((field: any) =>
            typeof field == "string"
              ? field.split(",").map((field: any) => field.trim())
              : field
          )
        : formJson.root.formStructure.primaryFields;
    fields.forEach((id: string) => {
      newRecordsData.forEach((page: any) => {
        page.fields?.forEach((field: any) => {
          if (field.field_id == id && field.value != "") {
            newRecordName.push(field.value);
          }
        });
      });
    });
    const newRecord: any = {
      id:
        formJson.root.formStructure.uuid +
        ":" +
        crypto.randomUUID().toUpperCase(),
      form_uuid: formJson.root.formStructure.uuid,
      name: newRecordName.length > 0 ? newRecordName.join(" ") : "Unnamed",
      is_locked: false,
      pages: newRecordsData,
    };
    records.unshift(newRecord);
    setRecordsJson({
      records,
      currentRecord: records[0],
    });
    setIsRecordSubmit(true);
    setIsClonedRecord(true);
    addRecord();
  });
}

export function lockRecord() {
  const record: any = { ...recordsJson.currentRecord };
  const tempArr: any =
    recordsJson.multipleRecords.length > 0
      ? recordsJson.multipleRecords
      : [record];

  tempArr.forEach(async (item: any) => {
    if (item.record_id) {
      const formRecordsApiParams = {
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/form/record`,
        jwt_token: updateRecordDetails().jwt_token,
        body: {
          user_id: updateRecordDetails().user_id,
          record_id: [
            recordsJson.records.filter(
              (record: any) => record.id == item.record_id
            )[0]?.record_id
              ? recordsJson.records.filter(
                  (record: any) => record.id == item.record_id
                )[0].record_id
              : recordsJson.records.filter(
                  (record: any) => record.id == item.record_id
                )[0].id,
          ],
        },
      };
      let recordData = await fetchApi(formRecordsApiParams);
      if (recordData?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (recordData?.statusCode == 403) {
        location.reload();
      }
      if (recordData.status && recordData.data[0].record) {
        setRecordsJson({
          currentRecord: {
            ...JSON.parse(recordData.data[0].record),
            record_id: recordData.data[0].id,
            is_locked: true,
          },
        });
        setIsRecordSubmit(true);
        updateRecord();
        setIsCurrentRecordLocked(true);
      }
    }
  });
}

export function deleteRecord() {
  if (recordDeleteRight()) {
    if (Object.keys(recordsJson.currentRecord).length) {
      setAlertMessage("<b>Delete this record?</b>");
      setRecordToDeleteId([recordsJson.currentRecord.record_id]);
      setPopup("confirmRecordDelete");
    }
    if (recordsJson.multipleRecords.length > 0) {
      setAlertMessage("<b>Delete these records?</b>");
      setRecordToDeleteId(
        recordsJson.multipleRecords.map((record: any) => record.record_id)
      );
      setPopup("confirmRecordDelete");
    }
  }
}

export function updateRecordsList(formRecords?: any, isSortRecords?: boolean) {
  let sorting: any = recordsOrder();
  if (recordsJson.records?.length > 0) {
    if (selectedUsers().length == 0) {
      const data: any = [...recordsJson.records];
      data.sort((a: any, b: any) => {
        const nameA = a.record_name?.trim() ? a.record_name?.trim() : "Unnamed";
        const nameB = b.record_name?.trim() ? b.record_name?.trim() : "Unnamed";
        if (sorting === "asc") {
          if (nameA < nameB) return -1;
          if (nameA > nameB) return 1;
        } else if (sorting === "desc") {
          if (nameA > nameB) return -1;
          if (nameA < nameB) return 1;
        }
        return 0;
      });
      const result = [];
      let currentLabel: any = null;
      let currentSubList: any = [];
      data.forEach((item: any) => {
        const initial = item.record_name?.trim()[0]
          ? item.record_name?.trim()[0]
          : "U";
        if (/[A-Z]/.test(initial)) {
          if (initial !== currentLabel) {
            if (currentLabel !== null) {
              result.push({ label: currentLabel, subList: currentSubList });
            }
            currentLabel = initial;
            currentSubList = [];
          }
          currentSubList.push(item);
        } else if (/[a-z]/.test(initial)) {
          if (initial !== currentLabel) {
            if (currentLabel !== null) {
              result.push({ label: currentLabel, subList: currentSubList });
            }
            currentLabel = initial;
            currentSubList = [];
          }
          currentSubList.push(item);
        } else if (/[0-9]/.test(initial)) {
          if (currentLabel !== "#") {
            if (currentLabel !== null) {
              result.push({ label: currentLabel, subList: currentSubList });
            }
            currentLabel = "#";
            currentSubList = [];
          }
          currentSubList.push(item);
        }
      });
      if (currentLabel !== null) {
        result.push({ label: currentLabel, subList: currentSubList });
      }
      const hashLabelIndex = result.findIndex((item) => item.label === "#");
      if (hashLabelIndex !== -1) {
        const hashLabel = result.splice(hashLabelIndex, 1)[0];
        result.push(hashLabel);
      }
      if (sorting === "desc" && result[result.length - 1].label == "#") {
        const temp = result[0];
        result[0] = result[result.length - 1];
        result[result.length - 1] = temp;
      }
      setRecordsList(result);
    } else {
      if (
        formRecords.length > 0 &&
        formRecords[0].label == recordsList()[recordsList().length - 1]?.label
      ) {
        let tempArr: any = [...recordsList()];
        setRecordsList([]);
        tempArr[tempArr.length - 1].subList = [
          ...tempArr[tempArr.length - 1].subList,
          ...formRecords[0].subList,
        ];
        formRecords.shift();
        tempArr = [...tempArr, ...formRecords];
        setRecordsList(tempArr);
      } else {
        setRecordsList([...recordsList(), ...formRecords]);
      }
    }
  } else {
    setRecordsList([]);
  }
  if (isSortRecords) {
    setSelectedRecord("");
  } else {
    if (
      recordsJson.currentRecord &&
      Object.keys(recordsJson.currentRecord)?.length > 0
    ) {
      setSelectedRecord(
        recordsJson.currentRecord.record_id +
          "_" +
          recordsList()
            .flatMap((item: any) => item.subList)
            .findIndex(
              (item: any) => item.record_id == recordsJson.currentRecord?.id
            )
      );
    }
  }
  setTotalRecordsShowCount(
    recordsList().flatMap((item: any) => item.subList).length
  );
}

export async function updateFormRecords(
  uid: string,
  value: string,
  file?: any,
  toUpdate: boolean = true,
  additional_data?: any,
  noUpdate?: boolean
) {
  let linkedUid: string = "";
  setPreLoadData((prevRecords) => {
    const records: any = { ...prevRecords };
    records[uid] = typeof value == "string" ? value : String(value);
    formJson.pages.forEach((page: any) => {
      page.fields?.forEach((field: any) => {
        if (
          field.properties.dataFieldSync.linkedFieldId === uid &&
          nestedFieldData()?.length == 0 &&
          field.properties.choiceFieldSync?.linkType != "linked_nested"
        ) {
          linkedUid = field.properties.dataFieldSync.uid;
          records[linkedUid] = typeof value == "string" ? value : String(value);
        }
        let tempArr: any = [];
        if (
          formJson.root.formStructure.formLinkString &&
          JSON.parse(formJson.root.formStructure.formLinkString)?.length > 0
        ) {
          tempArr = JSON.parse(formJson.root.formStructure.formLinkString).map(
            (obj: any) =>
              obj.childFieldId
                .split(",")
                .filter(
                  (obj: any) =>
                    obj.split(":")[1] == formJson.root.formStructure.uuid
                )
          );
          tempArr = tempArr.filter((obj: any) => obj.length > 0);
          tempArr = tempArr
            .flatMap((arr: any) => arr)
            .map((obj: any) => obj.split(":")[0]);
        }
        if (formJson.root.formStructure.formLinkString) {
          JSON.parse(formJson.root.formStructure.formLinkString)
            .filter(
              (obj: any) =>
                obj.parentFormUUID == formJson.root.formStructure.uuid
            )
            .forEach((obj: any) => {
              obj.childFieldId
                ?.split(",")
                .map((item: any) => item?.split(":")[0])
                .forEach((item: any) => {
                  records[item] = records[obj.parentFieldId?.split(":")[0]];
                });
            });
        }
      });
    });
    return records;
  });
  const dataArr: any = { ...additionalData() };
  dataArr[uid] = additional_data;
  setAdditionalData(dataArr);
  if (Object.keys(recordsJson.currentRecord).length > 0) {
    let newRecordName: any = [];
    const fields: any =
      formJson.root.formStructure?.sortFields &&
      formJson.root.formStructure?.sortFields?.length > 0
        ? formJson.root.formStructure.sortFields.flatMap((field: any) =>
            typeof field == "string"
              ? field.split(",").map((field: any) => field.trim())
              : field
          )
        : formJson.root.formStructure.primaryFields;
    fields.forEach((id: string) => {
      let type = "";
      formJson.pages.forEach((page: any) => {
        page.fields?.forEach((item: any) => {
          if (item.properties.dataFieldSync.uid == id) {
            type = item.type;
          }
        });
      });
      preLoadData()[id] != ""
        ? newRecordName.push(
            type == "DateField"
              ? preLoadData()[id]?.replaceAll("-", "/")
              : additionalData()[id]?.display_value
              ? additionalData()[id].display_value
              : preLoadData()[id]
          )
        : "";
    });
    newRecordName =
      newRecordName.length > 0 ? newRecordName.join(" ") : "Unnamed";

    if (
      recordsJson.currentRecord.pages.flatMap((p: any) =>
        p.fields.filter((f: any) => f.field_id == uid)
      ).length == 0
    ) {
      let currentRcd: any = { ...recordsJson.currentRecord };
      formJson.pages.forEach((p: any, p_key: any) => {
        p.fields?.forEach((f: any) => {
          if (f.properties.dataFieldSync.uid == uid) {
            const updatedPages = [...currentRcd.pages];

            // Update the specific page's fields
            updatedPages[p_key] = {
              ...updatedPages[p_key],
              fields: [
                ...updatedPages[p_key].fields,
                {
                  additional_data:
                    additional_data && Object.keys(additional_data).length > 0
                      ? additional_data
                      : {},
                  field_id: uid,
                  value: typeof value === "string" ? value : String(value),
                },
              ],
            };
            currentRcd = {
              ...currentRcd,
              pages: updatedPages,
            };
          }
        });
      });
      setRecordsJson("currentRecord", currentRcd);
    } else {
      setRecordsJson(
        "currentRecord",
        produce((record: any) => {
          record.name = newRecordName;
          record.pages.forEach((page: any) => {
            page.fields?.forEach((item: any) => {
              if (uid == item.field_id) {
                if (additional_data) {
                  item.additional_data =
                    additional_data && Object.keys(additional_data).length > 0
                      ? additional_data
                      : {};
                }
                item.value = typeof value == "string" ? value : String(value);
              }
              if (linkedUid == item.field_id) {
                item.value = typeof value == "string" ? value : String(value);
              }
            });
          });
        })
      );
    }
    const records: any = [];
    recordsJson.records.forEach((record: any) => {
      if (record.id == recordsJson.currentRecord.id) {
        records.push(recordsJson.currentRecord);
      } else {
        records.push(record);
      }
    });
    setRecordsJson({
      records,
    });
    if (recordsJson.currentRecord?.record_id) {
      updateRecord(uid, value);
      if (!noUpdate) {
        setIsRecordUpdated("update");
      }
    }
  } else {
    if (!noUpdate) {
      setIsRecordUpdated("add");
    }
    addNewRecord(window.location.pathname.split("/")[3], uid, value);
  }
}

export async function addRecord(
  uid?: string,
  value?: string,
  record?: any,
  form_id?: string
) {
  if (uid && value) {
    updateLinkedFormStructure(uid, value, "add");
  }
  if (isRecordSubmit()) {
    if (
      totalStorageUsed() >
      totalUserLicences() * Number(import.meta.env.VITE_FREE_PER_USER_STORAGE)
    ) {
      setIsRecordSubmit(true);
      notify({
        isNotified: true,
        message: `<div>${messages.warn.storage_used}. ${
          userRole() === "admin"
            ? "&nbsp;&nbsp;<A class='btn sm primary' href='/billing-payments'>Check Storage Plans</A>"
            : "Contact Your Admin."
        }</div>`,
        theme: "warm",
        placement: "top",
        timeout: 4000,
      });
      return;
    }
    const formData: any = new FormData();
    formData.append("user_id", updateRecordDetails().user_id);
    formData.append("device_type", "web");
    formData.append(
      "form_id",
      form_id ? form_id : formJson.root.formStructure.uuid
    );
    formData.append(
      "record_id",
      record ? record.id : recordsJson.currentRecord.id
    );
    formData.append(
      "parent_record_field",
      record ? `${uid}:${recordsJson.currentRecord.id.split(":")[1]}` : ""
    );
    formData.append(
      "record_name",
      record ? record.name : recordsJson.currentRecord.name
    );
    if (updatedRecordImages().length > 0) {
      imageFile().forEach((file: any) => {
        formData.append(`files`, file);
      });
      formData.append("field_ids", updatedRecordImages().join(","));
    }
    const preLoadedData: any = { ...preLoadData() };
    Object.keys(preLoadedData).forEach((item: any) => {
      if (preLoadedData[item] == "") {
        delete preLoadedData[item];
      }
      if (updatedRecordImages()?.includes(item.toString())) {
        preLoadedData[item] = "";
      }
    });
    formData.append(
      "structure",
      JSON.stringify(
        isClonedRecord()
          ? recordsJson.currentRecord.pages
              .flatMap((page: any) => page.fields)
              .filter((item: any) => item.value)
              .reduce((acc: any, item: any) => {
                acc[item.field_id] = item.value;
                return acc;
              }, {})
          : preLoadedData
      )
    );
    formData.append("additional_data", JSON.stringify(additionalData()));
    let recordAdd = await fetch(
      `${import.meta.env.VITE_API_URL}/form/record/add/update`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${updateRecordDetails().jwt_token}`,
        },
        body: formData,
      }
    )
      .then((res: any) => res.json())
      .catch((err) => {
        window.location.href = "/logout";
      });
    if (recordAdd?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (recordAdd?.statusCode == 403) {
      location.reload();
    }
    setFormsList(
      formsList()?.map((form: any) => {
        if (
          form.theme != "folder" &&
          form?.form_id == formJson.root.formStructure.uuid
        ) {
          return {
            ...form,
            totalRecords: Number(form.totalRecords) + 1,
          };
        }
        return form;
      })
    );
    if (recordAdd && recordAdd.status && record == undefined) {
      setPreLoadData([]);
      setIsImageUploaded(false);
      setImageFile([]);
      if (!recordAdd.data?.record?.id) {
        return;
      }
      if (!isRecentRecords()) {
        setTotalRecordsCount(totalRecordsCount() + 1);
        setTotalRecordsShowCount(totalRecordsShowCount() + 1);
      }
      const index: any = recordsJson.records.findIndex(
        (obj: any) =>
          obj.record_id == recordAdd.data.record.record_id ||
          obj.id == recordAdd.data.record.record_id
      );
      setRecordsJson({
        records:
          index == -1
            ? [...recordsJson.records, recordAdd.data.record].map(
                (item: any) => {
                  if (item.id?.split(":").length > 1) {
                    return recordAdd.data.record;
                  }
                  return item;
                }
              )
            : [
                ...recordsJson.records.map((item: any) => {
                  if (item.id?.split(":").length > 1) {
                    return recordAdd.data.record;
                  }
                  return item;
                }),
              ],
        currentRecord: {
          ...recordsJson.currentRecord,
          record_id: recordAdd.data.record.id,
        },
        multipleRecords: [],
      });
      setSelectedRecordId(recordAdd.data.record.id);
      setContextMenu("");
      if (selectedUsers().length == 0 && !isRecentRecords()) {
        updateRecordsList();
      } else {
        let tempArr: any = [...recordsList()];
        setRecordsList([]);
        tempArr.forEach((obj: any) => {
          if (obj.label == recordAdd.data.record.added_by) {
            obj.subList.push(recordAdd.data.record);
          }
        });
        if (isRecentRecords()) {
          if (selectedUsers().length == 0) {
            setRecordsList(
              tempArr
                .map((item: any) => {
                  const subList = item.subList.filter((subItem: any) =>
                    subItem.form_recent_records.some(
                      (record: any) => !record.is_read
                    )
                  );
                  return { ...item, subList };
                })
                .filter((item: any) => item.subList.length > 0)
            );
          } else {
            const filteredRecordsList = tempArr
              .map((list: any) => {
                const filteredSubList = list.subList.filter((obj: any) =>
                  obj.form_recent_records.some((record: any) => !record.is_read)
                );
                return {
                  ...list,
                  subList: filteredSubList,
                };
              })
              .filter((list: any) => list.subList.length > 0);
            setRecordsList(filteredRecordsList);
          }
        } else {
          setRecordsList(tempArr);
        }
        setSelectedRecord(
          recordAdd.data.record.id +
            "_" +
            (recordsList().find(
              (obj: any) => obj.label == recordAdd.data.record.added_by
            )?.subList?.length -
              1)
        );
      }
      if (linkedFormRecordsData().length == 0) {
        setIsRecordSubmit(false);
        notify({
          isNotified: true,
          message: "Record Added Successfully",
          theme: "success",
          placement: "top",
          timeout: 2500,
        });
      }
      setIsRecordUpdated("");
      const preLoadData: any = [];
      const additionalData: any = {};
      recordsJson.currentRecord.pages.forEach((page: any) => {
        page.fields?.forEach((field: any) => {
          if (
            field?.additional_data &&
            Object.keys(field.additional_data).length > 0
          ) {
            additionalData[field.field_id] = field.additional_data;
          }
          preLoadData[field.field_id] = field.value;
        });
      });
      formJson.pages.forEach((page: any) => {
        page?.fields?.forEach(async (field: any) => {
          if (field.properties.inputType == "currency") {
            preLoadData[field.properties.dataFieldSync.uid] = formJson.root
              .formStructure?.locale
              ? new Intl.NumberFormat(
                  formJson.root.formStructure?.locale.replace("_", "-"),
                  {
                    style: "currency",
                    currency: localList.filter(
                      (obj: any) =>
                        obj.value === formJson.root.formStructure?.locale
                    )[0]?.currency,
                  }
                ).format(
                  preLoadData[field.properties.dataFieldSync.uid].replaceAll(
                    /[^0-9.]/g,
                    ""
                  )
                )
              : "";
          }
        });
      });
      setAdditionalData(additionalData);
      setPreLoadData(preLoadData);
    }
  }
}

export async function updateRecord(
  uid?: string,
  value?: string,
  updatedRecord?: any,
  form_id?: string,
  linked_record_id?: string
) {
  if (uid && value) {
    updateLinkedFormStructure(uid, value, "update");
  }
  if (isRecordSubmit()) {
    if (
      totalStorageUsed() >
      totalUserLicences() * Number(import.meta.env.VITE_FREE_PER_USER_STORAGE)
    ) {
      setIsRecordSubmit(true);
      notify({
        isNotified: true,
        message: `<div>${messages.warn.storage_used}. ${
          userRole() === "admin"
            ? "&nbsp;&nbsp;<A class='btn sm primary' href='/billing-payments'>Check Storage Plans</A>"
            : "Contact Your Admin."
        }</div>`,
        theme: "warm",
        placement: "top",
        timeout: 4000,
      });
      return;
    }
    const record: any = { ...recordsJson.currentRecord };
    const record_id: any = record.record_id;
    delete record.record_id;
    const formData: any = new FormData();
    formData.append("user_id", updateRecordDetails().user_id);
    formData.append("device_type", "web");
    formData.append(
      "form_id",
      form_id ? form_id : formJson.root.formStructure.uuid
    );
    formData.append(
      "record_id",
      linked_record_id ? linked_record_id : record.id
    );
    formData.append(
      "record_name",
      updatedRecord ? updatedRecord.name : record.name
    );
    if (updatedRecordImages().length > 0) {
      imageFile().forEach((file: any) => {
        formData.append(`files`, file);
      });
      formData.append("field_ids", updatedRecordImages().join(","));
      const preLoadedData: any = { ...preLoadData() };
      Object.keys(preLoadedData).forEach((item: any) => {
        if (updatedRecordImages().includes(item.toString())) {
          preLoadedData[item] = "";
        }
      });
      formData.append("structure", JSON.stringify(preLoadedData));
    } else {
      formData.append(
        "structure",
        updatedRecord ? JSON.stringify(updatedRecord) : JSON.stringify(record)
      );
    }
    formData.append("additional_data", JSON.stringify(additionalData()));
    let recordUpdate = await fetch(
      `${import.meta.env.VITE_API_URL}/form/record/add/update`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${updateRecordDetails().jwt_token}`,
        },
        body: formData,
      }
    )
      .then((res: any) => res.json())
      .catch((err) => {
        window.location.href = "/logout";
      });
    if (recordUpdate?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (recordUpdate?.statusCode == 403) {
      location.reload();
    }
    if (recordUpdate && recordUpdate.status && updatedRecord == undefined) {
      setIsImageUploaded(false);
      setImageFile([]);
      const updatedRecords = recordsJson.records.map((item: any) => {
        if (item.id === recordUpdate.data.id) {
          return {
            ...item,
            record_name: recordUpdate.data.record_name,
            is_locked: recordUpdate.data.is_locked,
          };
        }
        return item;
      });
      setRecordsJson({
        records: updatedRecords,
        multipleRecords: [],
      });
      setContextMenu("");
      if (selectedUsers().length == 0 && !isRecentRecords()) {
        updateRecordsList();
      } else {
        const tempArr = recordsList().map((list: any) => {
          const filteredSubList = list.subList.map((obj: any) =>
            recordUpdate.data.id == obj.id
              ? {
                  ...obj,
                  record_name: recordUpdate.data.record_name,
                  is_locked: recordUpdate.data.is_locked,
                }
              : obj
          );
          return {
            ...list,
            subList: filteredSubList,
          };
        });
        if (isRecentRecords()) {
          if (selectedUsers().length == 0) {
            setRecordsList(
              tempArr
                .map((item: any) => {
                  const subList = item.subList.filter((subItem: any) =>
                    subItem.form_recent_records.some(
                      (record: any) => !record.is_read
                    )
                  );
                  return { ...item, subList };
                })
                .filter((item: any) => item.subList.length > 0)
            );
          } else {
            const filteredRecordsList = tempArr
              .map((list: any) => {
                const filteredSubList = list.subList.filter((obj: any) =>
                  obj.form_recent_records.some((record: any) => !record.is_read)
                );
                return {
                  ...list,
                  subList: filteredSubList,
                };
              })
              .filter((list: any) => list.subList.length > 0);
            setRecordsList(filteredRecordsList);
          }
        } else {
          setRecordsList(tempArr);
        }
      }
      const preLoadData: any = [];
      recordsJson.currentRecord?.pages.forEach((page: any) => {
        page.fields?.forEach((field: any) => {
          preLoadData[field.field_id] = field.value;
        });
      });
      setPreLoadData(preLoadData);
      if (linkedFormRecordsData().length == 0) {
        setIsRecordSubmit(false);
        notify({
          isNotified: true,
          message: "Record Updated Successfully",
          theme: "success",
          placement: "top",
          timeout: 2500,
        });
      }
      setIsRecordUpdated("");
    }
  }
}

export async function removeRecord() {
  const recordsToDelete: any = recordToDeleteId();
  const record_ids: any = recordsJson.records
    .filter((record: any) => recordsToDelete.includes(record.id))
    .map((record: any) => record.record_id);
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/record/remove`,
    jwt_token: updateRecordDetails().jwt_token as string,
    body: {
      user_id: updateRecordDetails().user_id,
      device_type: "web",
      form_id: formJson.root.formStructure.uuid,
      record_ids: record_ids.join(","),
      image_field_ids: formJson.pages
        .flatMap((page: any) =>
          page.fields?.filter(
            (field: any) => field.properties.dataFieldSync.type == "PhotoField"
          )
        )
        .map((item: any) => item?.properties.dataFieldSync.uid)
        .join(","),
    },
  };
  let recordRemove = await fetchApi(apiParams);
  if (recordRemove?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (recordRemove?.statusCode == 403) {
    location.reload();
  }
  if (recordRemove && recordRemove.status) {
    setSelectedFolderData({
      ...selectedFolderData(),
      forms: selectedFolderData().forms?.map((form: any) => {
        if (form.form_id == formJson.root.formStructure.uuid) {
          return {
            ...form,
            totalRecords: Number(form.totalRecords) - record_ids.length,
          };
        }
        return form;
      }),
    });
    setFormsList(
      formsList()?.map((form: any) => {
        if (form.theme == "folder") {
          form = {
            ...form,
            forms: form?.forms?.map((form: any) => {
              if (form.form_id == formJson.root.formStructure.uuid) {
                return {
                  ...form,
                  totalRecords: Number(form.totalRecords) - record_ids.length,
                };
              }
              return form;
            }),
          };
        } else {
          if (form.form_id == formJson.root.formStructure.uuid) {
            return {
              ...form,
              totalRecords: Number(form.totalRecords) - record_ids.length,
            };
          }
        }
        return form;
      })
    );
    setTotalRecordsCount(totalRecordsCount() - recordsToDelete.length);
    setTotalRecordsShowCount(totalRecordsShowCount() - recordsToDelete.length);
    setRecordsJson({
      records: recordsJson.records.filter(
        (item: any) => !recordsToDelete.includes(item.id)
      ),
      multipleRecords: [],
    });
    setContextMenu("");
    setDeletedRecordsCount(recordsToDelete.length);
    if (selectedUsers().length == 0) {
      updateRecordsList();
    } else {
      const filteredRecordsList = recordsList().map((list: any) => {
        const filteredSubList = list.subList.filter(
          (obj: any) => !recordsToDelete.includes(obj.id)
        );
        return {
          ...list,
          subList: filteredSubList,
        };
      });
      setRecordsList(filteredRecordsList);
    }
  }
}

export async function restoreDeletedRecords() {
  const formRecordsApiParams = {
    method: "POST",
    url: `${import.meta.env.VITE_API_URL}/form/records/restore`,
    jwt_token: updateRecordDetails().jwt_token,
    body: {
      user_id: updateRecordDetails().user_id,
      form_id: formJson.root.formStructure.uuid,
      device_type: "web",
    },
  };
  let recordsRestore = await fetchApi(formRecordsApiParams);
  if (recordsRestore?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (recordsRestore?.statusCode == 403) {
    location.reload();
  }
  if (
    recordsRestore &&
    recordsRestore.status &&
    recordsRestore.data?.count != 0
  ) {
    setSelectedFolderData({
      ...selectedFolderData(),
      forms: selectedFolderData().forms?.map((form: any) => {
        if (form.form_id == formJson.root.formStructure.uuid) {
          return {
            ...form,
            totalRecords:
              Number(form.totalRecords) + recordsRestore.data?.count,
          };
        }
        return form;
      }),
    });
    setFormsList(
      formsList()?.map((form: any) => {
        if (form.theme == "folder") {
          form = {
            ...form,
            forms: form?.forms?.map((form: any) => {
              if (form.form_id == formJson.root.formStructure.uuid) {
                return {
                  ...form,
                  totalRecords:
                    Number(form.totalRecords) + recordsRestore.data?.count,
                };
              }
              return form;
            }),
          };
        } else {
          if (form.form_id == formJson.root.formStructure.uuid) {
            return {
              ...form,
              totalRecords:
                Number(form.totalRecords) + recordsRestore.data?.count,
            };
          }
        }
        return form;
      })
    );
    setOffset(0);
    setRecordsJson({
      records: [],
      currentRecord: {},
      multipleRecords: [],
    });
    setRecordsList([]);
    setFormRecords([]);
    setSelectedRecordId("");
    updateFormRecordsList(updateRecordDetails().form_id, 0);
  } else {
    setIsPageLoader(false);
  }
}

export function updateLinkedFormStructure(
  uid: string,
  value: string,
  type: string
) {
  if (formJson.root.formStructure.formLinkString) {
    JSON.parse(formJson.root.formStructure.formLinkString).forEach(
      async (item: any) => {
        if (formJson.root.formStructure.uuid == item.childFormUUID) {
          if (item.parentFieldId.split(":")[0] == uid) {
            setRecordsJson(
              "currentRecord",
              produce((record: any) => {
                record.pages.forEach((page: any) => {
                  page.fields?.forEach((field: any) => {
                    item.childFieldId.split(",").forEach((val: any) => {
                      if (field.field_id == val.split(":")[0]) {
                        field.value = value;
                      }
                    });
                  });
                });
              })
            );
          }
        } else {
          if (item.parentFieldId.split(":")[0] == uid) {
            const apiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/form/linked/structure`,
              jwt_token: updateRecordDetails().jwt_token as string,
              body: {
                user_id: updateRecordDetails().user_id,
                form_id: item.childFormUUID,
              },
            };
            let form = await fetchApi(apiParams);
            if (form?.statusCode == 401) {
              window.location.href = "/logout";
            } else if (form?.statusCode == 403) {
              location.reload();
            }
            if (type == "add") {
              addLinkedFormRecord(form, uid, value, item);
            } else {
              const apiParams = {
                method: "POST" as string,
                url: `${import.meta.env.VITE_API_URL}/form/record/linked`,
                jwt_token: updateRecordDetails().jwt_token as string,
                body: {
                  user_id: updateRecordDetails().user_id,
                  parent_record_field: `${uid}:${
                    recordsJson.currentRecord.id.split(":")[1]
                  }`,
                },
              };
              const record: any = await fetchApi(apiParams);
              if (record?.statusCode == 401) {
                window.location.href = "/logout";
              } else if (record?.statusCode == 403) {
                location.reload();
              }
              if (record.data) {
                const tempArr: any = JSON.parse(record.data.record);
                tempArr.pages.forEach((page: any) => {
                  page.fields?.forEach((field: any) => {
                    item.childFieldId.split(",").forEach((val: any) => {
                      if (field.field_id == val.split(":")[0]) {
                        field.value = value;
                      }
                    });
                  });
                });
                let newRecordName: any = [];
                const fields: any =
                  JSON.parse(form.data.structure)?.sortFields &&
                  JSON.parse(form.data.structure)?.sortFields?.length > 0
                    ? JSON.parse(form.data.structure).sortFields.flatMap(
                        (field: any) =>
                          typeof field == "string"
                            ? field.split(",").map((field: any) => field.trim())
                            : field
                      )
                    : JSON.parse(form.data.structure).primaryFields;
                fields.forEach((id: string) => {
                  tempArr.pages.forEach((page: any) => {
                    page.fields?.forEach((field: any) => {
                      if (id == field.field_id && field.value != "") {
                        newRecordName.push(field.value);
                      }
                    });
                  });
                });
                tempArr.name =
                  newRecordName.length > 0
                    ? newRecordName.join(",")
                    : "Unnamed";
                setLinkedFormRecordsData([
                  ...linkedFormRecordsData(),
                  {
                    record: tempArr,
                    form_id: form.data.form_id,
                    record_id: record.data.record_id,
                  },
                ]);
              } else {
                addLinkedFormRecord(form, uid, value, item);
              }
            }
          }
        }
      }
    );
  }
}

async function addLinkedFormRecord(
  form: any,
  uid: string,
  value: string,
  item: any
) {
  const preLoadData: any = getPreLoadData(JSON.parse(form.data.structure));

  let record: any = {};
  const newRecordsData: any = [];
  JSON.parse(form.data.structure).pages.forEach((page: any, key: any) => {
    const newRecordsObject: any = {
      id: key + 1,
      fields: [],
    };
    page.fields?.forEach((field: any) => {
      newRecordsObject.fields.push({
        value: preLoadData[field.properties.dataFieldSync.uid],
        field_id: field.properties.dataFieldSync.uid,
        additional_data: {},
      });
    });
    newRecordsData.push(newRecordsObject);
  });
  item.childFieldId.split(",").forEach((val: any) => {
    newRecordsData.forEach((page: any) => {
      page.fields?.forEach((field: any) => {
        if (field.field_id == val.split(":")[0]) {
          field.value = value;
        }
      });
    });
  });
  let newRecordName: any = [];
  const fields: any =
    JSON.parse(form.data.structure)?.sortFields &&
    JSON.parse(form.data.structure)?.sortFields?.length > 0
      ? JSON.parse(form.data.structure).sortFields.flatMap((field: any) =>
          typeof field == "string"
            ? field.split(",").map((field: any) => field.trim())
            : field
        )
      : JSON.parse(form.data.structure).primaryFields;
  fields.forEach((id: string) => {
    newRecordsData.forEach((page: any) => {
      page.fields?.forEach((field: any) => {
        if (id == field.field_id && field.value != "") {
          newRecordName.push(field.value);
        }
      });
    });
  });
  record = {
    id: item.childFormUUID + ":" + crypto.randomUUID().toUpperCase(),
    form_uuid: item.childFormUUID,
    name: newRecordName.length > 0 ? newRecordName.join(" ") : "Unnamed",
    is_locked: false,
    pages: newRecordsData,
  };
  setLinkedFormRecordsData([
    ...linkedFormRecordsData(),
    {
      uid,
      record,
      form_id: form.data.form_id,
    },
  ]);
}

export function findKeyByValue(obj: any, targetValue: any) {
  for (const key in obj) {
    if (obj[key] === targetValue.toUpperCase()) {
      return key;
    }
  }
  return 0;
}

export function extractValues(inputString: string) {
  const regex = /([A-Z,a-z]\d+)|([+\-*\/()])|(-?\d+(\.\d+)?)/g;
  const matches = inputString?.match(regex);
  return matches ? matches : [];
}

export function parseCustomDate(dateString: string) {
  var parts = dateString?.split(" , ");
  var datePart = parts[0].split("/");
  var timePart = parts[1];
  var day = parseInt(datePart[0], 10);
  var month = parseInt(datePart[1], 10) - 1;
  var year = parseInt(datePart[2], 10);
  if (year < 100) {
    year += 2000;
  }
  if (timePart) {
    var timeParts = timePart.split(":");
    var hour = parseInt(timeParts[0], 10);
    var minute = parseInt(timeParts[1], 10);
    var date = new Date(year, month, day, hour, minute);
  } else {
    var date = new Date(year, month, day);
  }
  return date;
}

export function updatedCalulatedFieldvalue(noUpdate?: boolean) {
  let isCurrency: boolean = false;
  let isCalculated: boolean = false;
  const calculationsArr: any = [];
  const fieldInputTypes: any = formJson.pages
    .flatMap((page: any) =>
      page.fields?.map((field: any) => ({
        [field.properties.dataFieldSync.uid]: field.properties.inputType,
      }))
    )
    .reduce((acc, obj) => Object.assign(acc, obj), {});
  formJson.pages.forEach((page: any) => {
    page.fields?.forEach((field: any) => {
      let calculatedFieldValue: any = "";
      if (
        field.properties?.inputType == "calculated" &&
        field.properties.calculationFormula != ""
      ) {
        let arr: any = [];
        extractValues(field.properties.calculationFormula).forEach(
          (item: any, key: any) => {
            if (findKeyByValue(calculatedArr(), item)) {
              if (
                fieldInputTypes[findKeyByValue(calculatedArr(), item)] ==
                  "calculated" &&
                formJson.pages?.flatMap((page: any) =>
                  page.fields?.find(
                    (field: any) =>
                      field &&
                      field.properties.dataFieldSync.uid ==
                        findKeyByValue(calculatedArr(), item)
                  )
                )
              ) {
                formJson.pages
                  ?.flatMap((page: any) =>
                    page.fields?.find(
                      (field: any) =>
                        field &&
                        field.properties.dataFieldSync.uid ==
                          findKeyByValue(calculatedArr(), item)
                    )
                  )
                  .forEach((field: any) => {
                    extractValues(
                      field?.properties?.calculationFormula
                    ).forEach((elm) => {
                      if (findKeyByValue(calculatedArr(), elm)) {
                        arr.push(findKeyByValue(calculatedArr(), elm));
                      }
                    });
                  });
              } else {
                arr.push(findKeyByValue(calculatedArr(), item));
              }
              calculationsArr.push(arr);
              if (preLoadData()[findKeyByValue(calculatedArr(), item)]) {
                calculatedFieldValue +=
                  preLoadData()[findKeyByValue(calculatedArr(), item)] == "true"
                    ? 1
                    : preLoadData()[findKeyByValue(calculatedArr(), item)] ==
                      "false"
                    ? 0
                    : preLoadData()
                        [findKeyByValue(calculatedArr(), item)]?.match(
                          /[\d,.]+/
                        )[0]
                        .replaceAll(",", "");
              } else {
                calculatedFieldValue += 0;
              }
            } else {
              calculatedFieldValue += item;
            }
          }
        );
        [...new Set(calculationsArr)].forEach((arr: any) => {
          isCurrency = arr.some(
            (item: any) => fieldInputTypes[item] === "currency"
          );
        });
        const parser = new Parser();
        const expression = parser.parse(calculatedFieldValue);
        const result = expression.evaluate({});
        calculatedFieldValue = result
          ? result.toFixed(field.properties.decimalPlaces)
          : "0";
        if (calculatedFieldValue == "NaN") {
          calculatedFieldValue = "0";
        }
        calculatedFieldValue = isCurrency
          ? new Intl.NumberFormat(
              formJson.root.formStructure?.locale.replace("_", "-"),
              {
                style: "currency",
                currency: localList.filter(
                  (obj: any) =>
                    obj.value === formJson.root.formStructure?.locale
                )[0]?.currency,
              }
            ).format(calculatedFieldValue)
          : calculatedFieldValue;
        if (
          formJson.pages.flatMap((page: any) =>
            page.fields?.filter(
              (field: any) =>
                field.properties.dateInputType == "calculated" &&
                field.properties.calculationFormula != ""
            )
          ).length > 0
        ) {
          updateFormRecords(
            field.properties.dataFieldSync.uid,
            calculatedFieldValue,
            false,
            false,
            {},
            noUpdate
          );
        } else {
          updateFormRecords(
            field.properties.dataFieldSync.uid,
            calculatedFieldValue,
            false,
            false,
            {},
            noUpdate
          );
        }
      }
      let dateFormat: string = "";
      if (
        field.properties?.dateInputType == "calculated" &&
        field.properties.calculationFormula != ""
      ) {
        let allValuesExist: boolean = true;
        extractValues(field.properties.calculationFormula).forEach(
          (item: any) => {
            if (findKeyByValue(calculatedArr(), item)) {
              formJson.pages.forEach((page: any) => {
                page.fields?.forEach((f: any) => {
                  if (
                    f.properties.dataFieldSync.uid ==
                    findKeyByValue(calculatedArr(), item)
                  ) {
                    dateFormat = f.properties.dateFormat;
                  }
                });
              });
              if (
                preLoadData()
                  [findKeyByValue(calculatedArr(), item)]?.replace(/at/g, ",")
                  .split(":")[0].length == 2
              ) {
                const tempDate: any =
                  format(new Date(), "dd MMMM yyyy") +
                  "," +
                  preLoadData()[findKeyByValue(calculatedArr(), item)];
                calculatedFieldValue += new Date(tempDate).getTime();
              } else {
                calculatedFieldValue += new Date(
                  preLoadData()[findKeyByValue(calculatedArr(), item)]?.replace(
                    /at/g,
                    ","
                  )
                ).getTime();
              }
            } else {
              calculatedFieldValue += item;
            }
          }
        );
        if (
          calculatedFieldValue != "" &&
          !calculatedFieldValue?.includes("NaN")
        ) {
          const parser = new Parser();
          const expression = parser.parse(calculatedFieldValue);
          const result = expression.evaluate({});
          const milliseconds = result
            ? result.toFixed(field.properties.decimalPlaces)
            : "0";
          const millisecondsInMinute = 60 * 1000;
          const millisecondsInHour = 60 * millisecondsInMinute;
          const millisecondsInDay = 24 * millisecondsInHour;
          let days: any = Math.round(milliseconds / millisecondsInDay);
          let hours: any = Math.round(
            (milliseconds % millisecondsInDay) / millisecondsInHour
          );
          let allHours: any = Math.round(milliseconds / millisecondsInHour);
          let minutes: any = Math.round(
            (milliseconds % millisecondsInHour) / millisecondsInMinute
          );
          if (dateFormat == "hh:mm a" || dateFormat == "HH:mm") {
            days = 0;
          }
          if (hours >= 1) {
            days = days != 0 ? days - 1 : days;
          }
          if (minutes >= 1) {
            hours =
              Math.abs(hours) != 0 ? Math.abs(hours) - 1 : Math.abs(hours);
            allHours = allHours != 0 ? allHours - 1 : allHours;
          }

          const daysText =
            days !== 0
              ? `${days} ${days != 1 && days != -1 ? "days " : "day "}`
              : "";
          const hoursText =
            Math.abs(hours) !== 0
              ? `${days !== 0 ? Math.abs(hours) : hours} ${
                  hours != 1 && hours != -1 ? "hrs " : "hr "
                }`
              : "";
          const fullHoursText = allHours
            ? `${allHours} ${allHours != 1 && allHours != -1 ? "hrs " : "hr "}`
            : "";
          const minutesText =
            Math.abs(minutes) !== 0
              ? `${days !== 0 || hours !== 0 ? Math.abs(minutes) : minutes} ${
                  minutes != 1 && minutes != -1 ? "mins" : "min"
                }`
              : `${days !== 0 || hours !== 0 ? "" : "0 mins"}`;

          if (field.properties.calculationResultFormat == "day") {
            calculatedFieldValue =
              Math.round(milliseconds / millisecondsInDay) == 0 ||
              dateFormat == "hh:mm a" ||
              dateFormat == "HH:mm"
                ? "0"
                : Math.round(milliseconds / millisecondsInDay);
          } else if (
            field.properties.calculationResultFormat == "day_hour_min"
          ) {
            calculatedFieldValue = `${daysText}${hoursText}${minutesText}`;
          } else if (field.properties.calculationResultFormat == "hour") {
            calculatedFieldValue =
              Math.round(milliseconds / millisecondsInHour) == 0
                ? "0"
                : Math.round(milliseconds / millisecondsInHour);
          } else if (field.properties.calculationResultFormat == "hour_min") {
            calculatedFieldValue = `${fullHoursText}${minutesText}`;
          } else if (field.properties.calculationResultFormat == "min") {
            calculatedFieldValue =
              Math.round(milliseconds / millisecondsInMinute) == 0
                ? "0"
                : Math.round(milliseconds / millisecondsInMinute);
          }
          updateFormRecords(
            field.properties.dataFieldSync.uid,
            calculatedFieldValue ? calculatedFieldValue : "0",
            false,
            false,
            {},
            noUpdate
          );
        }
      }
    });
  });
}

export {
  setUpdateRecordDetails,
  recordsJson,
  setRecordsJson,
  recordsList,
  setRecordsList,
  preLoadData,
  setPreLoadData,
  additionalData,
  setAdditionalData,
  calculatedArr,
  setCalculatedArr,
  recordToDeleteId,
  setRecordToDeleteId,
  isCurrentRecordLocked,
  setIsCurrentRecordLocked,
  selectedRecord,
  setSelectedRecord,
  selectedRecordId,
  setSelectedRecordId,
  totalRecordsCount,
  setTotalRecordsCount,
  totalRecordsShowCount,
  setTotalRecordsShowCount,
  isRecordUpdated,
  setIsRecordUpdated,
};
