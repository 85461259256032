import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";

const [currentPage, setCurrentPage] = createSignal(1 as number);

interface Props {
  currentPage: any;
  totalPages: any;
  onPageChange: any;
  itemsPerPage?: any;
  totalItems?: any;
  itemPerPageFilter: boolean;
  noPageNo?: boolean;
}

const Pagination: Component<Props> = (props) => {
  const [allPages, setAllPages] = createSignal([] as any);
  const [pageNumber, setPageNumber] = createSignal(1 as number);

  createEffect(() => {
    const pages = [];
    let startPage = 1;
    let endPage = props.totalPages;
    const currentPage = props.currentPage;
    // handlePageClick(currentPage);
    switch (true) {
      case props.totalPages <= 5:
        startPage = 1;
        endPage = props.totalPages;
        break;
      case currentPage <= 3:
        startPage = 1;
        endPage = 4;
        break;
      case currentPage >= props.totalPages - 2:
        startPage = props.totalPages - 3;
        endPage = props.totalPages;
        break;
      default:
        startPage = currentPage - 1;
        endPage = currentPage + 1;
    }
    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push("...");
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (endPage < props.totalPages) {
      if (endPage < props.totalPages - 1) {
        pages.push("...");
      }
      pages.push(props.totalPages);
    }
    setAllPages(pages);
  });

  onMount(() => {
    const formPagination: any = document.querySelector(".form-pagination");
    if (formPagination) {
      const parentElement: any = formPagination?.parentElement;
      const childElements = parentElement.children;
      const windowWidth = window.innerWidth;
      const leftBarWidth = 236;
      const rightBarWidth = 300;
      const formWidth = 770;
      const formMargin = 32;
      const otherSpace =
        (windowWidth - leftBarWidth - rightBarWidth - formMargin - formWidth) /
        2;
      formPagination.style.left =
        childElements[0].getBoundingClientRect().left +
        (otherSpace > 0 ? otherSpace : 0) +
        "px";
      window.addEventListener(
        "resize",
        () =>
          (formPagination.style.left =
            childElements[0].getBoundingClientRect().left + "px")
      );
    }
  });

  const handlePageClick = (page: any) => {
    if (page < 1 || page > props.totalPages) return;
    props.onPageChange(page);
  };

  return (
    <div class="flex space-between align-center">
      <Show when={props.itemPerPageFilter}>
        <div class="lbl">
          Page {props.currentPage} of {props.totalPages}{" "}
        </div>
      </Show>
      <div
        class={`flex flex-column ${!props.itemPerPageFilter ? "w-100" : ""}`}
      >
        <div class="flex space-between">
          <ul class={`pagination ${!props.itemPerPageFilter ? "form" : ""}`}>
            <li class={`page-item ${props.currentPage === 1 && "disabled"}`}>
              <a
                class="page-link"
                href="#"
                onClick={() => handlePageClick(props.currentPage - 1)}
              >
                Previous
              </a>
            </li>
            <Show when={!props.itemPerPageFilter}>
              <div class="flex align-center">
                <div class="lbl">
                  Page {props.currentPage} of {props.totalPages}{" "}
                </div>
                &nbsp;
                <input
                  class="input w-60px text-center app-dn"
                  type="number"
                  min={1}
                  max={props.totalPages}
                  value={props.currentPage}
                  placeholder="Pg No."
                  onchange={(e: any) => setPageNumber(Number(e.target.value))}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      handlePageClick(Number(e.target.value));
                    }
                  }}
                />
                &nbsp;
                <button
                  class="btn primary p-10 app-dn"
                  onclick={() => {
                    handlePageClick(pageNumber());
                  }}
                >
                  GO
                </button>
              </div>
            </Show>
            <Show when={props.itemPerPageFilter && !props?.noPageNo}>
              <For each={allPages()}>
                {(page) => (
                  <>
                    <Show when={page == "..."}>
                      <li class={`page-item disabled`}>
                        <a
                          class="page-link"
                          href="#"
                          onClick={() => handlePageClick(page)}
                        >
                          {page}
                        </a>
                      </li>
                    </Show>
                    <Show when={page != "..."}>
                      <li
                        class={`page-item ${
                          props.currentPage === page && "active"
                        }`}
                      >
                        <a
                          class="page-link"
                          href="#"
                          onClick={() => handlePageClick(page)}
                        >
                          {page}
                        </a>
                      </li>
                    </Show>
                  </>
                )}
              </For>
            </Show>
            <li
              class={`page-item ${
                props.currentPage === props.totalPages && "disabled"
              }`}
            >
              <a
                class="page-link"
                href="#"
                onClick={() => handlePageClick(props.currentPage + 1)}
              >
                Next
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export { currentPage, setCurrentPage };
export default Pagination;
