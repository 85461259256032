import { Component, Show } from "solid-js";
interface Props {
  label: string;
  name: string;
  value: boolean;
  setValue: Function;
  disabled?: boolean;
  class?: string;
  checkboxHeight?: number | string;
}
const Checkbox: Component<Props> = (props) => {
  return (
    <div
      class={`form-group checkbox-group ${
        props?.checkboxHeight ? "flex align-center" : ""
      } ${props?.class == "form-checkbox" ? "form-checkbox-div" : ""}`}
      style={{
        height: props?.checkboxHeight ? props?.checkboxHeight + "px" : "auto",
        "min-height": "18px",
      }}
    >
      <input
        class={`${props?.disabled ? "z-index--1" : ""} ${props?.class}`}
        type="checkbox"
        id={`${props.name}_id`}
        name={props.name}
        onChange={(e) => props.setValue(e.currentTarget.checked as boolean)}
        onkeyup={(e) => {
          if (e.key == "Enter") {
            if (e.currentTarget.checked) {
              props.setValue(false as boolean);
            } else {
              props.setValue(true as boolean);
            }
          }
        }}
        checked={props.value ? true : false}
        disabled={props?.disabled ? true : false}
      />
      <Show when={props.label != ""}>
        <label
          for={`${props.name}_id`}
          class={props?.disabled ? "disabled" : ""}
        >
          {props.label}
        </label>
      </Show>
    </div>
  );
};

export default Checkbox;
