import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";

import { validateUser } from "./api/validateUser";
import fetchApi from "~/lib/api";
import { notify } from "~/components/global/Notification";
import messages from "~/helpers/message";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);

    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/settings/get`,
      jwt_token,
      body: {
        user_id,
      },
    };
    let userSettings = await fetchApi(apiParams);
    if (userSettings?.statusCode == 401) {
      throw redirect("/logout");
    }
    userSettings = userSettings.data;

    return {
      user_id,
      jwt_token,
      userSettings,
    };
  });
}

const userSettings: Component = () => {
  setPageTitle(`${SITE_TITLE} - User Settings`);
  const data = useRouteData<typeof routeData>();
  const [autoLogout, setAutoLogout] = createSignal(false as boolean);
  const [formShareEmail, setFormShareEmail] = createSignal(false as boolean);
  const [autoLogoutTime, setAutoLogoutTime] = createSignal("2" as string);
  const [isUserSettingsUpdated, setIsUserSettingsUpdated] = createSignal(
    false as boolean
  );

  const autoLogoutTimesList: any = [
    { value: "2", label: "2 Hours" },
    { value: "3", label: "3 Hours" },
    { value: "5", label: "5 Hours" },
    { value: "10", label: "10 Hours" },
    { value: "12", label: "12 Hours" },
  ];

  createEffect(() => {
    if (data()?.userSettings && data()?.userSettings?.session?.value) {
      setAutoLogout(true);
      setAutoLogoutTime(data()?.userSettings?.session?.value);
    }
    if (data()?.userSettings && data()?.userSettings?.shareForm?.value) {
      setFormShareEmail(
        data()?.userSettings?.shareForm?.value == "true" ? true : false
      );
    }
  });

  return (
    <main>
      <SystemLayout>
        <div>
          <h2 class="title color">User Settings</h2>
          <br />
          <div class="flex gap-0_5 align-center lbl user-select-none">
            <input type="hidden" value={data()?.jwt_token} />
            <input
              type="checkbox"
              id="auto_logout"
              onchange={(e: any) => {
                setAutoLogout(e.target.checked);
                setIsUserSettingsUpdated(true);
              }}
              checked={autoLogout()}
            />
            <label for="auto_logout">
              Auto Logout (&nbsp;
              <span class="fs-14">
                Automatically log users out after a specified period
              </span>
              &nbsp;)
            </label>
          </div>
          <Show when={autoLogout()}>
            <div class="underline record"></div>
            <div class="flex space-around align-center user-select-none">
              <For each={autoLogoutTimesList}>
                {(item: any) => (
                  <div
                    class={`badge w-183p text-center lbl cur-p ${
                      item.value == autoLogoutTime() ? "selected" : ""
                    }`}
                    onclick={() => {
                      setIsUserSettingsUpdated(true);
                      setAutoLogoutTime(item.value);
                    }}
                  >
                    {item.label}
                  </div>
                )}
              </For>
            </div>
          </Show>
          <br />
          <div class="flex gap-0_5 align-center lbl user-select-none">
            <input
              type="checkbox"
              id="form_share_mail"
              onchange={(e: any) => {
                setFormShareEmail(e.target.checked);
                setIsUserSettingsUpdated(true);
              }}
              checked={formShareEmail()}
            />
            <label for="form_share_mail">
              Send email notification upon sharing a form
            </label>
          </div>
          <br />
          <br />
          <button
            class={`btn primary ${isUserSettingsUpdated() ? "" : "disabled"}`}
            onclick={async () => {
              if (isUserSettingsUpdated()) {
                const apiParams = {
                  method: "POST" as string,
                  url: `${import.meta.env.VITE_API_URL}/user/settings/update`,
                  jwt_token: data()?.jwt_token,
                  body: {
                    user_id: data()?.user_id,
                    session_time: autoLogout() ? autoLogoutTime() : null,
                    share_form_email: formShareEmail() ? "true" : "false",
                  },
                };
                let userSettingsUpdate = await fetchApi(apiParams);
                if (userSettingsUpdate?.statusCode == 401) {
                  window.location.href = "/logout";
                } else if (userSettingsUpdate?.statusCode == 403) {
                  location.reload();
                }
                let message = messages.error.update_user_settings;
                let theme = "danger";
                if (userSettingsUpdate && userSettingsUpdate.status) {
                  message = messages.user.update_user_settings;
                  theme = "success";
                }
                notify({
                  isNotified: true,
                  message,
                  theme,
                  placement: "top",
                  timeout: 2500,
                });
              }
            }}
          >
            Save
          </button>
        </div>
      </SystemLayout>
    </main>
  );
};

export default userSettings;
