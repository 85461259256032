export default function blurInput(el: HTMLInputElement): void {
  updateLbl(el, "add");
}

function updateLbl(el: HTMLInputElement, type: string = "add") {
  const span = document.createElement("label") as HTMLElement;
  span.classList.add("input-lbl");
  setTimeout(() => {
    span.innerHTML =
      (el.getAttribute("example-data")
        ? el.getAttribute("example-data")
        : el.getAttribute("placeholder")) || "";
    if (span.innerHTML) {
      el.parentElement?.appendChild(span);
      if (span.innerHTML.includes("Search")) {
        el.setAttribute("placeholder", "Search...");
      }
    }
  }, 100);
}
