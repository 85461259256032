import { Component } from "solid-js";
import * as Collections from "./Collections";
import { Dynamic } from "solid-js/web";
interface Props {
  name: string;
  id?: any;
  stroke?: string;
  width?: number;
  height?: number;
  strokeWidth?: string;
}
const Icons: Component<Props> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
      width={props.width ? props.width : 24}
      height={props.height ? props.height : 24}
      viewBox="0 0 24 24"
      stroke-width={props.strokeWidth ? props.strokeWidth : 2}
      stroke={props.stroke ? props.stroke : "#2c3e50"}
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      id={props?.id}
    >
      <Dynamic component={Collections[props.name]} />
    </svg>
  );
};

export default Icons;
