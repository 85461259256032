import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Icons from "../icons/Icons";
import { localList } from "~/store/locale.store";
import { setIsFormSettingsUpdated } from "~/store/global.store";
import { formJson } from "~/store/form.store";

const [formLocale, setFormLocale] = createSignal("" as string);
const [localsList, setLocalsList] = createSignal(localList as any);

export function updateLocalesList(search: string) {
  setLocalsList(
    localList.filter((item: any) =>
      item.label.toLowerCase().includes(search.trim().toLowerCase())
    )
  );
}

const LocaleSettings: Component = () => {
  createEffect(() => {
    if (formLocale() == "") {
      setFormLocale(formJson.root.formStructure?.locale);
    }
    if (formLocale() != "") {
      const selectedElement = document.querySelector(".locale-list.selected");
      if (selectedElement) {
        selectedElement.scrollIntoView({
          block: "center",
        });
      }
    }
  });

  return (
    <>
      <div>
        <For each={localsList()}>
          {(item: any) => (
            <div
              class={`locale-list ${
                formLocale() == item.value ? "selected" : ""
              }`}
              onclick={() => {
                setIsFormSettingsUpdated(true);
                setFormLocale(item.value);
              }}
            >
              <div class="list-item">
                <label>{item.label}</label>
                <Show when={formLocale() == item.value}>
                  <Icons name="check"></Icons>
                </Show>
              </div>
            </div>
          )}
        </For>
      </div>
    </>
  );
};

export { formLocale };
export default LocaleSettings;
