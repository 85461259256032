import { Component, For, Show, createEffect } from "solid-js";
import generateCSS from "~/helpers/generator";
import { createStore } from "solid-js/store";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  formJson,
  radioFieldButtons,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  additionalData,
  calculatedArr,
  preLoadData,
  setAdditionalData,
  updateFormRecords,
  updatedCalulatedFieldvalue,
} from "~/store/records.store";
import { localList } from "~/store/locale.store";
import {
  activeField,
  isFormDropDownOpen,
  isMouseDown,
  multipleSelectField,
  selectedDropDown,
  setActiveField,
  setAlertMessage,
  setIsMouseDown,
  setPopup,
  updateNumbersForField,
} from "~/store/global.store";
import Checkbox from "../global/Checkbox";
import DatePicker from "~/components/global/DatePicker";
import Icons from "../icons/Icons";
import convert from "~/helpers/convertor";
import { userRole } from "~/store/user-form-role";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
interface Props {
  properties: any;
  fieldsType: string;
}
const TextField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(
        assignFieldProperties(props.properties, props.fieldsType)
      );
    }
  });

  if (props.fieldsType) {
    setFieldProperties(
      assignFieldProperties(props.properties, props.fieldsType)
    );
  }

  return (
    <>
      <Show when={props.properties.dataFieldSync.type == "TextField"}>
        <Show
          when={
            props.properties.dataFieldSync.frame[1][1] <= 0.375 ||
            String(props.properties.dataFieldSync.parentTableId) != "0"
          }
        >
          <input
            type={
              props.properties?.inputType == "number" &&
              props.fieldsType == "render"
                ? formJson.currentField?.inputType == "calculated"
                  ? "text"
                  : "number"
                : "text"
            }
            id={props.properties.dataFieldSync.uid}
            style={generateCSS(fieldProperties)}
            class={`field input-field ${
              activeField() == props.properties.dataFieldSync.uid &&
              preLoadData()[props.properties.dataFieldSync.uid] != ""
                ? "pr-25"
                : ""
            } ${
              String(props.properties.dataFieldSync.parentTableId) != "0"
                ? "z-index-1"
                : "z-index-0"
            } ${
              props.fieldsType == "designer" ? "cur-p builder-field-border" : ""
            } ${
              props.fieldsType == "preview" || props.fieldsType == "locked"
                ? "pointer-none"
                : ""
            } ${
              selectedFields().length > 0 &&
              !selectedFields().includes(props.properties.dataFieldSync.uid)
                ? "pointer-none"
                : ""
            } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
            value={
              (props.fieldsType == "render" || props.fieldsType == "locked") &&
              preLoadData()[props.properties.dataFieldSync.uid]
                ? preLoadData()[props.properties.dataFieldSync.uid]
                : (formJson.currentField?.inputType == "calculated" ||
                    formJson.currentField?.dateInputType == "calculated") &&
                  props.fieldsType != "render"
                ? calculatedArr()[props.properties.dataFieldSync.uid]
                  ? calculatedArr()[props.properties.dataFieldSync.uid]
                  : ""
                : (props.fieldsType == "render" ||
                    props.fieldsType == "locked") &&
                  props.properties.inputType == "linked"
                ? preLoadData()[props.properties.dataFieldSync.linkedFieldId]
                  ? preLoadData()[props.properties.dataFieldSync.linkedFieldId]
                  : ""
                : ""
            }
            min={
              props.properties.dataFieldSync.minimum != ""
                ? props.properties.dataFieldSync.minimum
                : ""
            }
            max={
              props.properties.dataFieldSync.maximum != ""
                ? props.properties.dataFieldSync.maximum
                : ""
            }
            readonly={props.fieldsType != "render" ? true : false}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseMove={() => {
              if (isMouseDown() && fieldMovement() == "") {
                updatedCurrentField(props.properties.dataFieldSync.uid);
                setIsGuidline(true);
                setFieldMovement("move");
              }
            }}
            onMouseUp={() => setIsMouseDown(false)}
            onclick={(event: any) => {
              if (props.fieldsType != "render") {
                if (userRole() != "viewer") {
                  multipleSelectField() || event.shiftKey
                    ? String(props.properties.dataFieldSync.parentTableId) ==
                      "0"
                      ? updatedMultipleFields(
                          props.properties.dataFieldSync.uid
                        )
                      : ""
                    : updatedCurrentField(props.properties.dataFieldSync.uid);
                }
              } else {
                setActiveField(props.properties.dataFieldSync.uid);
              }
            }}
            oncontextmenu={(event: any) => {
              if (props.fieldsType == "designer") {
                multipleSelectField() || event.shiftKey
                  ? String(props.properties.dataFieldSync.parentTableId) == "0"
                    ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                    : ""
                  : updatedCurrentField(props.properties.dataFieldSync.uid);
                addContectMenu(event);
              }
            }}
            onblur={(e: any) => {
              if (props.fieldsType == "render") {
                if (props.properties?.inputType == "number") {
                  e.target.value = updateNumbersForField(
                    e.target.value,
                    props.properties.dataFieldSync.minimum,
                    props.properties.dataFieldSync.maximum
                  );
                }
                if (
                  props.properties?.inputType == "currency" &&
                  !isNaN(parseFloat(e.target.value))
                ) {
                  e.target.value = new Intl.NumberFormat(
                    formJson.root.formStructure?.locale.replace("_", "-"),
                    {
                      style: "currency",
                      currency: localList.filter(
                        (obj: any) =>
                          obj.value === formJson.root.formStructure?.locale
                      )[0]?.currency,
                    }
                  ).format(e.target.value);
                }
                if (
                  formJson.pages.flatMap((page: any) =>
                    page.fields?.filter(
                      (field: any) =>
                        field.properties.inputType == "calculated" &&
                        field.properties.calculationFormula != ""
                    )
                  ).length > 0
                ) {
                  updateFormRecords(
                    props.properties.dataFieldSync.uid,
                    e.target.value,
                    false,
                    false
                  );
                  updatedCalulatedFieldvalue();
                } else {
                  updateFormRecords(
                    props.properties.dataFieldSync.uid,
                    e.target.value
                  );
                }
              }
            }}
            onkeydown={(e: any) => {
              if (
                props.properties?.inputType == "number" &&
                props.fieldsType == "render" &&
                /[^0-9.]/g.test(e.key) &&
                ![
                  "Backspace",
                  "Delete",
                  "ArrowLeft",
                  "ArrowRight",
                  "Tab",
                ].includes(e.key)
              ) {
                e.preventDefault();
              }
            }}
            disabled={
              (props.fieldsType == "render" || props.fieldsType == "locked") &&
              (props.properties.inputType == "linked" ||
                props.properties.inputType == "calculated" ||
                props.properties.inputType == "autoIncrement")
                ? true
                : false
            }
            autocomplete="off"
          />
          <Show
            when={
              props.fieldsType != "preview" &&
              activeField() === props.properties.dataFieldSync.uid &&
              preLoadData()[props.properties.dataFieldSync.uid] != "" &&
              (props.properties?.inputType == "text" ||
                props.properties?.inputType == "number" ||
                props.properties?.inputType == "currency" ||
                props.properties?.inputType == "url" ||
                props.properties?.inputType == "email")
            }
          >
            <span
              class="pa z-index-1 app-flex flex align-center cur-p"
              style={{
                top:
                  Number(
                    convert(props.properties.dataFieldSync.frame[0][1], "in-px")
                  ) + "px",
                left:
                  Number(
                    convert(props.properties.dataFieldSync.frame[0][0], "in-px")
                  ) +
                  Number(
                    convert(props.properties.dataFieldSync.frame[1][0], "in-px")
                  ) -
                  50 +
                  "px",
                height:
                  Number(
                    convert(props.properties.dataFieldSync.frame[1][1], "in-px")
                  ) + "px",
                width: "20px",
              }}
              onclick={() => {
                if (
                  formJson.pages.flatMap((page: any) =>
                    page.fields?.filter(
                      (field: any) =>
                        field.properties.inputType == "calculated" &&
                        field.properties.calculationFormula != ""
                    )
                  ).length > 0
                ) {
                  updateFormRecords(
                    props.properties.dataFieldSync.uid,
                    "",
                    false,
                    false
                  );
                  updatedCalulatedFieldvalue();
                } else {
                  updateFormRecords(props.properties.dataFieldSync.uid, "");
                }
              }}
            >
              <Icons name="circleX" width={20} height={20}></Icons>
            </span>
          </Show>
        </Show>
        <Show
          when={
            props.properties.dataFieldSync.frame[1][1] > 0.375 &&
            String(props.properties.dataFieldSync.parentTableId) == "0"
          }
        >
          <textarea
            id={props.properties.dataFieldSync.uid}
            style={generateCSS(fieldProperties)}
            class={`field input-field textarea ${
              props.fieldsType == "designer" ? "cur-p builder-field-border" : ""
            } ${
              props.fieldsType == "preview" || props.fieldsType == "locked"
                ? "pointer-none"
                : ""
            } ${
              selectedFields().length > 0 &&
              !selectedFields().includes(props.properties.dataFieldSync.uid)
                ? "pointer-none"
                : ""
            } ${
              selectedDropDown() != ""
                ? String(props.properties.dataFieldSync.parentTableId) != "0"
                  ? "z-index-1"
                  : "z-index-0"
                : "z-index-1"
            } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
            value={
              (props.fieldsType == "render" || props.fieldsType == "locked") &&
              preLoadData()[props.properties.dataFieldSync.uid]
                ? preLoadData()[props.properties.dataFieldSync.uid]
                : (formJson.currentField?.inputType == "calculated" ||
                    formJson.currentField?.dateInputType == "calculated") &&
                  props.fieldsType == "designer" &&
                  calculatedArr()[props.properties.dataFieldSync.uid]
                ? calculatedArr()[props.properties.dataFieldSync.uid]
                : ""
            }
            readonly={props.fieldsType != "render" ? true : false}
            onMouseDown={() => setIsMouseDown(true)}
            onMouseMove={() => {
              if (isMouseDown() && fieldMovement() == "") {
                updatedCurrentField(props.properties.dataFieldSync.uid);
                setIsGuidline(true);
                setFieldMovement("move");
              }
            }}
            onMouseUp={() => setIsMouseDown(false)}
            onclick={(event: any) =>
              multipleSelectField() || event.shiftKey
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : updatedCurrentField(props.properties.dataFieldSync.uid)
            }
            oninput={(e: any) => {
              updateFormRecords(
                props.properties.dataFieldSync.uid,
                e.target.value
              );
            }}
            autocomplete="off"
          />
        </Show>
      </Show>
      <Show when={props.properties.dataFieldSync.type == "DateField"}>
        <DatePicker
          type={props.fieldsType}
          properties={props.properties}
          fieldProperties={fieldProperties}
        ></DatePicker>
      </Show>
      <Show when={props.properties.dataFieldSync.type == "CheckBoxField"}>
        <div
          class={`pa app-flex flex gap-0_5 ${
            selectedDropDown() == ""
              ? "z-index-1"
              : String(props.properties.dataFieldSync.parentTableId) != "0"
              ? "z-index-1"
              : "z-index-0"
          } ${props.fieldsType == "designer" ? "builder-field-border" : ""} ${
            selectedFields().length > 0 &&
            !selectedFields().includes(props.properties.dataFieldSync.uid)
              ? "pointer-none"
              : ""
          } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
          style={generateCSS(fieldProperties)}
          onMouseDown={() => setIsMouseDown(true)}
          onMouseMove={() => {
            if (isMouseDown() && fieldMovement() == "") {
              updatedCurrentField(props.properties.dataFieldSync.uid);
              setIsGuidline(true);
              if (fieldMovement() == "") {
                setFieldMovement("move");
              }
            }
          }}
          onMouseUp={() => setIsMouseDown(false)}
          onclick={(event: any) =>
            multipleSelectField() || event.shiftKey
              ? String(props.properties.dataFieldSync.parentTableId) == "0"
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : ""
              : updatedCurrentField(props.properties.dataFieldSync.uid)
          }
          oncontextmenu={(event: any) => {
            if (props.fieldsType == "designer") {
              multipleSelectField() || event.shiftKey
                ? String(props.properties.dataFieldSync.parentTableId) == "0"
                  ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                  : ""
                : updatedCurrentField(props.properties.dataFieldSync.uid);
              addContectMenu(event);
            }
          }}
        >
          <div
            class={`flex gap-0_5 w-100 ${
              props.properties.dataFieldSync.alignment == "center.alignment" &&
              (props.properties.dataFieldSync.parentTableId != 0 ||
                props.properties.dataFieldSync.parentTableId != "")
                ? "justify-center"
                : props.properties.dataFieldSync.alignment ==
                    "right.alignment" &&
                  (props.properties.dataFieldSync.parentTableId != 0 ||
                    props.properties.dataFieldSync.parentTableId != "")
                ? "justify-end"
                : ""
            }`}
          >
            <Checkbox
              label=""
              class="form-checkbox"
              name={props.properties.dataFieldSync.uid}
              checkboxHeight={
                (Math.round(
                  (Number(props.properties.dataFieldSync.fontSize) / 72) *
                    84 *
                    4
                ) /
                  4 /
                  84) *
                  96 +
                2
              }
              value={
                (((props.fieldsType == "render" ||
                  props.fieldsType == "locked") &&
                  preLoadData()[props.properties.dataFieldSync.uid] ==
                    "true") ||
                preLoadData()[props.properties.dataFieldSync.uid] == true ||
                preLoadData()[props.properties.dataFieldSync.uid] == 1
                  ? true
                  : false) as boolean
              }
              setValue={(val: any) => {
                {
                  props.properties?.linkType == "linked_child"
                    ? ((val = false),
                      setAlertMessage(
                        "<b>This field is linked to another field</b>"
                      ),
                      setPopup("alert"))
                    : "";
                  if (props.properties?.linkType != "linked_child") {
                    updateFormRecords(props.properties.dataFieldSync.uid, val);
                    if (
                      formJson.pages.flatMap((page: any) =>
                        page.fields?.filter(
                          (field: any) =>
                            field.properties.inputType == "calculated" &&
                            field.properties.calculationFormula != ""
                        )
                      ).length > 0
                    ) {
                      updatedCalulatedFieldvalue();
                    }
                  } else {
                    val = preLoadData()[props.properties.dataFieldSync.uid];
                  }
                }
              }}
              disabled={props.fieldsType != "render" ? true : false}
            />
            <Show when={props.properties.dataFieldSync.shouldDisplayLabel}>
              <label
                class="text-left word-break"
                style={`font-size: ${
                  (Math.round(
                    (Number(props.properties.dataFieldSync.fontSize) / 72) *
                      84 *
                      4
                  ) /
                    4 /
                    84) *
                    96 -
                  // New Code
                  (props.fieldsType == "preview" ? 2 : 0)
                }px; margin-top: ${
                  Math.round((15 / 84) * 96) -
                  Math.round(
                    ((Math.round(
                      (Number(props.properties.dataFieldSync.fontSize) / 72) *
                        84 *
                        4
                    ) /
                      4 /
                      84) *
                      96 *
                      0.92 -
                      (Math.round(
                        (Number(props.properties.dataFieldSync.fontSize) / 72) *
                          84 *
                          4
                      ) /
                        4 /
                        84) *
                        96 *
                        -0.23) /
                      2
                  )
                }px; ${
                  String(props.properties.dataFieldSync.parentTableId) != "0"
                    ? `height: fit-content; display: -webkit-box; -webkit-box-orient: vertical; text-overflow: ellipsis; overflow: hidden; -webkit-line-clamp: ${Math.floor(
                        Number(
                          convert(
                            props.properties.dataFieldSync.frame[1][1],
                            "in-px"
                          )
                        ) /
                          ((Math.round(
                            (Number(props.properties.dataFieldSync.fontSize) /
                              72) *
                              84 *
                              4
                          ) /
                            4 /
                            84) *
                            96 *
                            1.2)
                      )}`
                    : ""
                }`}
                for={`${props.properties.dataFieldSync.uid}_id`}
              >
                {(formJson.currentField?.inputType == "calculated" ||
                  formJson.currentField?.dateInputType == "calculated") &&
                props.fieldsType == "designer" &&
                calculatedArr()[props.properties.dataFieldSync.uid]
                  ? calculatedArr()[props.properties.dataFieldSync.uid]
                  : props.properties.dataFieldSync.label}
              </label>
            </Show>
          </div>
        </div>
      </Show>
      <Show when={props.properties.dataFieldSync.type == "RadioButtonField"}>
        <Show when={props.fieldsType != "render"}>
          <div
            class={`pa ${
              props.fieldsType == "designer" ? "builder-field-border" : ""
            }`}
            style={{
              left:
                Number(
                  convert(props.properties.dataFieldSync.frame[0][0], "in-px")
                ) -
                21 +
                "px",
              top:
                convert(props.properties.dataFieldSync.frame[0][1], "in-px") +
                "px",
              width:
                convert(props.properties.dataFieldSync.frame[1][0], "in-px") +
                "px",
              height:
                convert(props.properties.dataFieldSync.frame[1][1], "in-px") +
                "px",
            }}
          ></div>
        </Show>
        <div
          class={`pa ${
            props.fieldsType != "render" && props.fieldsType != "preview"
              ? "cur-p"
              : ""
          } ${
            selectedFields().length > 0 &&
            !selectedFields().includes(props.properties.dataFieldSync.uid)
              ? "pointer-none"
              : ""
          } ${props.fieldsType == "preview" ? "user-select-none" : ""}`}
          style={generateCSS(fieldProperties)}
          onMouseDown={() => setIsMouseDown(true)}
          onMouseMove={() => {
            if (isMouseDown() && fieldMovement() == "") {
              updatedCurrentField(props.properties.dataFieldSync.uid);
              setIsGuidline(true);
              setFieldMovement("move");
            }
          }}
          onMouseUp={() => setIsMouseDown(false)}
          onclick={(event: any) =>
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid)
          }
          oncontextmenu={(event: any) => {
            if (props.fieldsType == "designer") {
              multipleSelectField() || event.shiftKey
                ? String(props.properties.dataFieldSync.parentTableId) == "0"
                  ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                  : ""
                : updatedCurrentField(props.properties.dataFieldSync.uid);
              addContectMenu(event);
            }
          }}
        >
          {() => {
            const radioFieldInfo: any = radioFieldButtons(
              props.properties.choiceFieldSync.items.map((item: any) => ({
                title: item.title,
              })),
              props.properties.dataFieldSync.fontSize,
              props.properties.dataFieldSync.frame[1][0]
            );
            return (
              <For
                each={props.properties.choiceFieldSync.items?.filter(
                  (item: any) => item.title != ""
                )}
              >
                {(item: any, key: any) => (
                  <div
                    class="pa flex align-center"
                    style={{
                      left: radioFieldInfo.buttons[key()].frame.x + "px",
                      top: radioFieldInfo.buttons[key()].frame.y + "px",
                      width: radioFieldInfo.buttons[key()].frame.width + "px",
                      height: radioFieldInfo.buttons[key()].frame.height + "px",
                    }}
                  >
                    <input
                      id={
                        props.properties.dataFieldSync?.uid +
                        "_" +
                        item.title +
                        "_" +
                        key()
                      }
                      class={`form-radio ${
                        props.fieldsType == "designer" ? "z-index--1" : ""
                      }`}
                      type="radio"
                      name={props.properties.dataFieldSync?.uid}
                      checked={
                        (props.fieldsType == "render" ||
                          props.fieldsType == "locked") &&
                        preLoadData()[props.properties.dataFieldSync.uid] !=
                          "" &&
                        (preLoadData()[props.properties.dataFieldSync.uid] ==
                          item.title ||
                          preLoadData()[props.properties.dataFieldSync.uid] ==
                            item.id)
                          ? true
                          : false
                      }
                      value={props.properties.choiceFieldSync.items[key()].id}
                      onchange={(e: any) => {
                        props.properties.choiceFieldSync?.linkType ==
                        "linked_child"
                          ? ((e.target.checked = false),
                            setAlertMessage(
                              "<b>This field is linked to another field</b>"
                            ),
                            setPopup("alert"))
                          : "";
                        if (
                          props.properties.choiceFieldSync?.linkType !=
                          "linked_child"
                        ) {
                          const value: any = {
                            display_value:
                              props.properties.choiceFieldSync.items.find(
                                (item: any) => item.id == e.target.value
                              )?.title,
                          };
                          const dataArr: any = { ...additionalData() };
                          dataArr[props.properties.dataFieldSync.uid] = value;
                          setAdditionalData(dataArr);
                          updateFormRecords(
                            props.properties.dataFieldSync.uid,
                            e.target.value ? e.target.value : "",
                            false,
                            false,
                            value
                          );
                        }
                      }}
                      disabled={props.fieldsType != "render" ? true : false}
                    />
                    &nbsp;
                    <label
                      for={
                        props.properties.dataFieldSync?.uid +
                        "_" +
                        item.title +
                        "_" +
                        key()
                      }
                    >
                      {item.title}
                    </label>
                  </div>
                )}
              </For>
            );
          }}
        </div>
      </Show>
    </>
  );
};

export default TextField;
