import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import ChangePassword from "~/components/global/ChangePassword";

import AuthLayout from "~/components/layouts/auth.layout";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import { setPageTitle } from "~/root";
import { validateUser } from "./api/validateUser";
import { createSignal, onMount } from "solid-js";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;
    return { user_id, jwt_token };
  });
}

export default function PasswordChange() {
  setPageTitle(`${SITE_TITLE} - Change Password`);
  const data = useRouteData<typeof routeData>();

  return (
    <main>
      <AuthLayout size="md">
        <ChangePassword
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></ChangePassword>
      </AuthLayout>
    </main>
  );
}
