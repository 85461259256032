import { Component, createEffect, createSignal, onMount, Show } from "solid-js";
import { A, FormError, useSearchParams } from "solid-start";
import { createServerAction$ } from "solid-start/server";
import { createUserSession, login } from "~/db/session";
import { validateUser } from "~/routes/api/validateUser";
import Icons from "../icons/Icons";
import Popover from "./Popover";
import messages from "~/helpers/message";
import { validateEmail, validatePassword } from "~/helpers/validators";
import AuthLayout from "../layouts/auth.layout";
import blurInput from "~/directives/blur-input.directive";

const inputLbl = blurInput;

export function routeData() {
  return validateUser();
}
const [fireBaseToken, setFireBaseToken] = createSignal("" as string);

const LoginComponent: Component = () => {
  const [searchParams] = useSearchParams();

  createEffect(() => {
    if ({ ...searchParams }) {
      const { email } = { ...searchParams } as { email: string };
      if (email) {
        const element: any = document.getElementById("username-input");
        element.value = email;
      }
    }
  });

  const [loggingIn, { Form }] = createServerAction$(async (form: FormData) => {
    const username = form.get("username");
    const password = form.get("password");
    const token: any = form.get("token");
    if (typeof username !== "string" || typeof password !== "string") {
      throw new FormError(`Form not submitted correctly.`);
    }

    const fields = { username, password };
    let fieldErrors: any = {
      username: validateEmail(username.trim()),
      password: validatePassword(password.trim()),
    };
    if (Object.values(fieldErrors).some(Boolean)) {
      if (fieldErrors?.password) {
        fieldErrors.password = fieldErrors?.password["t"];
        throw new FormError("", { fieldErrors, fields });
      } else {
        throw new FormError("Fields invalid", { fieldErrors, fields });
      }
    }

    const user = await login({ username, password, token });
    let redirectTo = "/login";
    if (user == "Invalid credentials") {
      throw new FormError(`Username/Password combination is incorrect`, {
        fields,
      });
    } else if (user == "User account does not exist") {
      throw new FormError(`User account does not exist`, {
        fields,
      });
    } else if (user == "Subscription Inactive") {
      throw new FormError(
        `<strong>Subscription Inactive</strong><br><br />Your subscription is currently inactive. Please contact your administrator to resolve this issue.`,
        {
          fields,
        }
      );
    } else {
      redirectTo =
        user.role.role_name == "super_admin"
          ? "/admin/dashboard"
          : user.role.role_name == "admin"
          ? "/form/list"
          : user.has_set_password
          ? "/form/list"
          : "/change-password";
    }

    return createUserSession(user.id, user.Authorization, redirectTo);
  });

  return (
    <AuthLayout size="md">
      <Form class="auth-form">
        <input type="hidden" name="token" value={fireBaseToken()} />
        <h2 class="title flex align-center justify-center auth-form__title">
          &nbsp;Login
        </h2>
        <div class="clearfix"></div>
        <br />
        <div class="form-group icon-group">
          <span class="form-group__icon">
            <Icons name="user" />
          </span>
          <div class="form-element">
            <input
              id="username-input"
              name="username"
              placeholder="Username"
              class={`input ${
                loggingIn.error?.fieldErrors?.username ? "error-input" : ""
              }`}
              use:inputLbl
            />
          </div>
        </div>
        <Show when={loggingIn.error?.fieldErrors?.username}>
          <p class="error-message" role="alert">
            {loggingIn.error.fieldErrors.username}
          </p>
        </Show>
        <div class="clearfix"></div>
        <br />
        <div class="form-group icon-group">
          <span class="form-group__icon">
            <Icons name="key" />
          </span>
          <div class="form-element">
            <input
              id="password-input"
              class={`input pr-30 ${
                loggingIn.error?.fieldErrors?.password ? "error-input" : ""
              }`}
              name="password"
              type="password"
              placeholder="Password"
              use:inputLbl
            />
          </div>
          <Popover content={messages.info.password}>
            <span class="form-group__info-icon">
              <Icons name="info"></Icons>
            </span>
          </Popover>
        </div>
        <br />
        <Show when={loggingIn.error?.fieldErrors?.password}>
          <div
            role="alert"
            class="error-message"
            innerHTML={loggingIn.error.fieldErrors.password}
          ></div>
        </Show>
        <Show when={loggingIn.error}>
          <div
            role="alert"
            class="error-message"
            id="error-message"
            innerHTML={loggingIn.error.message}
          ></div>
          <br />
        </Show>
        <div class="app-text-center text-center">
          <Show when={!loggingIn.pending}>
            <button class="btn primary" type="submit">
              Login
            </button>
          </Show>
          <Show when={loggingIn.pending}>
            <button class="btn disabled">
              <Icons name="buttonLoader" width={20} height={20}></Icons>
              &nbsp; Logging in....
            </button>
          </Show>
        </div>
        <div class="clearfix"></div>
        <br />
        <a href="/forgot-password" class="a-link">
          Forgot Password?
        </a>
        <p class="text-gray fw-500">
          Don't have an account?{" "}
          <A class="a-link" href="/register">
            Register
          </A>
        </p>
      </Form>
    </AuthLayout>
  );
};

export { setFireBaseToken };
export default LoginComponent;
