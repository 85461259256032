import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onCleanup,
  onMount,
} from "solid-js";

import "~/assets/styles/render.scss";
import {
  RouteDataArgs,
  useNavigate,
  useParams,
  useRouteData,
} from "solid-start";
import { userRole } from "~/store/user-form-role";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { currentPage, setCurrentPage } from "~/components/global/Pagination";
import Icons from "~/components/icons/Icons";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import {
  activeField,
  alertMessage,
  clipboardSearch,
  contextMenu,
  contextMenuLeft,
  contextMenuTop,
  currentformName,
  exportFormSettings,
  formScale,
  formSettingsBar,
  formToolbarOpen,
  isBuilderPageDropdownOpen,
  isMobile,
  isPageLoader,
  popupQueue,
  recordDeleteRight,
  recordShareRight,
  recordSidebarOpen,
  recordsSearch,
  removeLastFomrPopup,
  removePopup,
  selectedDropDown,
  selectedRecords,
  setActiveField,
  setAlertMessage,
  setClipboardSearch,
  setContextMenu,
  setContextMenuLeft,
  setContextMenuTop,
  setCurrentFormId,
  setCurrentformName,
  setExportFormSettings,
  setFormScale,
  setFormSettingsBar,
  setFormSharedUsers,
  setFormToolbarOpen,
  setIsBuilderPageDropdownOpen,
  setIsFormDropDownOpen,
  setIsMobile,
  setIsPageLoader,
  setPopup,
  setRecordContentMenuId,
  setRecordDeleteRight,
  setRecordShareRight,
  setRecordSidebarOpen,
  setRecordsSearch,
  setSelectedDropDown,
  setSelectedRecords,
} from "~/store/global.store";
import {
  addRecord,
  alertFormRecords,
  cloneRecord,
  deleteRecord,
  deletedRecordsCount,
  formRecords,
  isCurrentRecordLocked,
  isFormChangesCanceled,
  isLoader,
  isRecordSubmit,
  isRecordUpdated,
  linkedFormRecordsData,
  lockRecord,
  preLoadData,
  recordSaveCancelAction,
  recordToDeleteId,
  recordsJson,
  recordsList,
  removeRecord,
  restoreDeletedRecords,
  selectedRecord,
  setAdditionalData,
  setFormRecords,
  setIsCurrentRecordLocked,
  setIsFormChangesCanceled,
  setIsRecentRecords,
  setIsRecordSubmit,
  setIsRecordUpdated,
  setLinkedFormRecordsData,
  setPreLoadData,
  setRecordSaveCancelAction,
  setRecordToDeleteId,
  setRecordsJson,
  setRecordsList,
  setSelectedRecord,
  setSelectedRecordId,
  setTotalRecordsShowCount,
  setUpdateRecordDetails,
  totalRecordsCount,
  totalRecordsShowCount,
  updateFormRecords,
  updateFormRecordsList,
  updatePreLoadData,
  updateRecord,
  updatedCalulatedFieldvalue,
} from "~/store/records.store";
import FormField from "~/components/fields/FormField";
import {
  clipData,
  formJson,
  isRedirectFromBuilder,
  offset,
  setClipData,
  setFormJson,
  setFormLastPageId,
  setIsFormJsonUpdated,
  setIsRedirectFromBuilder,
  setOffset,
  setTotalFormPages,
  totalFormPages,
  updateFormJsonPages,
} from "~/store/form.store";
import Notification, { notify } from "~/components/global/Notification";
import { validateUser } from "~/routes/api/validateUser";
import FormNavbar, {
  exportForm,
  isClipBoard,
  newRecord,
  setIsClipBoard,
  setPageSearchString,
  toggleClipboard,
} from "~/components/global/FormNavbar";
import Popup from "~/components/global/Popup";
import { openScanner, setDataSrc } from "~/components/fields/BarcodeField";
import { setIsLocationUpdated } from "~/components/fields/LocationField";
import {
  scriptOpenFormId,
  setScriptOpenFormId,
} from "~/components/fields/ScriptButtonField";
import FolderForms from "~/components/popup/FolderFormsPopup";
import SortRecords, {
  selectedUsers,
} from "~/components/popup/SortRecordsPopup";
import { selectedDropDownId } from "~/components/fields/DropdownField";
import ContextMenu from "~/components/global/ContextMenu";
import SignaturePopup from "~/components/popup/SignaturePopup";
import { currentSignatureField } from "~/components/fields/CanvasField";
import Checkbox from "~/components/global/Checkbox";
import { cloudServices, exportFormats } from "~/store/field-editor.store";
import ExportMailPopup, {
  setBarcodeUrls,
} from "~/components/popup/ExportMailPopup";
import {
  exportFormRecordFiles,
  exportFormat,
  isProcessing,
  setExportFormat,
} from "~/store/export-files.store";
import ExportFilesPopup from "~/components/popup/ExportFilesPopup";
import PrintForm, {
  isPrinting,
  setIsPrinting,
} from "~/components/form/PrintForm";
import RecordSubmitPopup from "~/components/popup/RecordSubmitPopup";
import convert from "~/helpers/convertor";
import { FRAME_HEIGHT, FRAME_WIDTH } from "~/helpers/constant";
import FormPagination from "~/components/form/FormPagination";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import Popover from "~/components/global/Popover";
import ShareRecord, {
  setSharedUsersList,
} from "~/components/popup/ShareRecord";
import PhotoAnnotate, {
  imageUpdateSize,
  resizeImage,
} from "~/components/popup/PhotoAnnotatePopup";
import { setToolbarButtons } from "~/components/form/ToolbarButtonsSettings";
import { localList } from "~/store/locale.store";
import JsBarcode from "jsbarcode";

let counter = 0;

export function routeData({ params }: RouteDataArgs) {
  validateUser();
  return createServerData$(
    async ([, id], { request }) => {
      const userDetails = await getUser(request);
      if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
        throw redirect("/logout");
      }
      const user_id = userDetails.data.id;
      const jwt_token: any = await getAuthToken(request);
      return {
        userDetails,
        user_id,
        jwt_token,
      };
    },
    { key: () => ["formId", params.id] }
  );
}

const render: Component = () => {
  const data = useRouteData<typeof routeData>();
  const navigate = useNavigate();
  const { id } = useParams();
  const [recordToDelete, setRecordToDelete] = createSignal("" as string);
  const [otherSpace, setOtherSpace] = createSignal(0 as number);
  const [currentClipId, setCurrentClipId] = createSignal("" as string);
  const [clipBoardType, setClipBoardType] = createSignal("" as string);
  const [automaticExport, setAutomaticExport] = createSignal(true as boolean);
  const [exportSettings, setExportSettings] = createSignal([] as any);
  const [isRecordsSettingsOpen, setIsRecordsSettingsOpen] = createSignal(
    false as boolean
  );
  const [usersList, setUsersList] = createSignal([] as any);
  const [recordIndex, setRecordIndex] = createSignal(0 as any);
  const [isToggleMessage, setIsToggleMessage] = createSignal(false as boolean);
  const [deletedRecords, setDeletedRecords] = createSignal(0 as number);

  let titleRef: any;
  let debounceTimeout: any;

  let windowWidth: number = 0;
  let windowHeight: number = 0;
  let leftBarWidth: number = 300;
  let rightBarWidth: number = 236;
  let formWidth: number = 770;
  let formMargin: number = 32;

  onMount(() => {
    if (popupQueue.includes("folderForms")) {
      removePopup("folderForms");
    }
    setFormJson({
      currentField: {},
      multipleFields: [],
    });
    setSelectedRecordId("");
    setSelectedRecord("");
    setRecordToDelete("");
    setPreLoadData([]);
    setFormRecords([]);
    setPageSearchString("");
    setRecordsSearch("");
    setClipboardSearch("");
    if (window.innerWidth <= 450) {
      setFormScale((window.innerWidth * 0.435) / 375);
      setIsMobile(true);
    } else {
      setFormScale(
        localStorage.getItem("fc_form_scale")
          ? Number(localStorage.getItem("fc_form_scale"))
          : 1
      );
    }
    setRecordsJson({
      currentRecord: {},
      multipleRecords: [],
    });
    windowWidth = window.innerWidth;
    windowHeight = window.innerHeight;
    setOtherSpace(
      (windowWidth - leftBarWidth - rightBarWidth - formMargin - formWidth) / 2
    );
    const handleKeyUp: any = (event: any) => {
      if (event.key === "Escape") {
        removeLastFomrPopup();
      }
      if (
        (Object.keys(recordsJson.currentRecord).length > 0 ||
          recordsJson.multipleRecords.length > 0) &&
        (event.key == "ArrowUp" || event.key == "ArrowDown")
      ) {
        const mainArr: any = recordsList()
          .map((item: any) => item.subList)
          .flat();
        fetchRecord(
          mainArr[recordIndex()].id,
          data()?.user_id,
          mainArr[recordIndex()].record_id
            ? mainArr[recordIndex()].record_id
            : mainArr[recordIndex()].id,
          mainArr[recordIndex()]?.form_recent_records
            ? mainArr[recordIndex()]?.form_recent_records[0]?.is_read
            : true,
          event.shiftKey
        );
      }
    };
    document.addEventListener("keyup", handleKeyUp);
    const handleKeyDown: any = (event: any) => {
      if (isPageLoader()) {
        event.preventDefault();
      } else {
        if (
          Object.keys(recordsJson.currentRecord).length > 0 ||
          recordsJson.multipleRecords.length > 0
        ) {
          const mainArr: any = recordsList()
            .map((item: any) => item.subList)
            .flat();
          const index: number = mainArr?.findIndex(
            (item: any, key: any) => key == recordIndex()
          );
          if (event.key == "ArrowUp" && index != 0) {
            setSelectedRecordId(mainArr[index - 1].id);
            setRecordIndex(index - 1);
            setSelectedRecord(mainArr[index - 1].id + "_" + (index - 1));
            const newSelectedRecordElement: any = document.querySelector(
              `[data-record-id="${mainArr[index - 1].id + "_" + (index - 1)}"]`
            );
            scrollToRecord(newSelectedRecordElement);
            setRecordsJson({
              currentRecord: mainArr[index - 1],
              multipleRecords: [mainArr[index - 1]],
            });
          } else if (event.key == "ArrowDown" && index != mainArr.length - 1) {
            setSelectedRecordId(mainArr[index + 1].id);
            setRecordIndex(index + 1);
            setSelectedRecord(mainArr[index + 1].id + "_" + (index + 1));
            const newSelectedRecordElement: any = document.querySelector(
              `[data-record-id="${mainArr[index + 1].id + "_" + (index + 1)}"]`
            );
            scrollToRecord(newSelectedRecordElement);
            setRecordsJson({
              currentRecord: mainArr[index + 1],
              multipleRecords: [mainArr[index + 1]],
            });
          }
        }
        if (
          (Object.keys(recordsJson.currentRecord).length > 0 ||
            recordsJson.multipleRecords.length > 0) &&
          !isRecordUpdated()
        ) {
          if (
            (event.key == "Delete" ||
              (event.key == "Backspace" && event.ctrlKey)) &&
            formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
              (obj: any) => obj.type == "tbicon_delete_record"
            ) != -1
          ) {
            deleteRecord();
          }
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    const element: any = document.querySelector(".builder-sidebar");
    if (!element) {
      console.error("Element not found.");
      return;
    }
    let debounceTimeout: any;
    element.addEventListener("scroll", (event: any) => {
      clearTimeout(debounceTimeout);
      debounceTimeout = setTimeout(() => handleScroll(event), 100);
    });
    onCleanup(() => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    });
  });

  function handleScroll(event: any) {
    const scrollableHeight =
      event.target.scrollHeight - event.target.clientHeight;
    const currentPosition = event.target.scrollTop;
    const threshold = 5;
    if (
      currentPosition >= scrollableHeight - threshold &&
      recordsJson.records.length + deletedRecords() >= 20 &&
      recordsJson.records.length !== totalRecordsCount() &&
      !isLoader()
    ) {
      setOffset(20 * Math.ceil(recordsJson.records.length / 20));
      setRecordsJson({
        records: recordsJson.records.filter((record: any) => record?.record_id),
      });
      updateFormRecordsList(id, offset());
      counter = 0;
    }
  }

  function scrollToRecord(recordElement: HTMLElement | null) {
    const element: HTMLElement | null =
      document.querySelector(".builder-sidebar");
    if (element && recordElement) {
      const elementRect = element.getBoundingClientRect();
      const recordRect = recordElement.getBoundingClientRect();
      const offset = recordRect.top - elementRect.top + element.scrollTop;
      element.scrollTop =
        offset - elementRect.height / 2 + recordRect.height / 2;
    }
  }

  let debounceTimer: NodeJS.Timeout | null = null;

  const handleToggleChange = (e: any) => {
    if (debounceTimer) clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      setIsRecentRecords(e.target.checked);
      if (e.target.checked) {
        if (selectedUsers().length == 0) {
          setRecordsList(
            recordsList()
              .map((item: any) => {
                const subList = item.subList.filter((subItem: any) =>
                  subItem.form_recent_records.some(
                    (record: any) => !record.is_read
                  )
                );
                return { ...item, subList };
              })
              .filter((item: any) => item.subList.length > 0)
          );
        } else {
          const filteredRecordsList = recordsList()
            .map((list: any) => {
              const filteredSubList = list.subList.filter((obj: any) =>
                obj.form_recent_records.some((record: any) => !record.is_read)
              );
              return {
                ...list,
                subList: filteredSubList,
              };
            })
            .filter((list: any) => list.subList.length > 0);
          setRecordsList(filteredRecordsList);
        }
        setTotalRecordsShowCount(
          recordsList().flatMap((item: any) => item.subList).length
        );
      } else {
        setTotalRecordsShowCount(totalRecordsCount());
        setOffset(0);
        updateFormRecordsList(id, 0);
      }
    }, 300);
  };

  createEffect(async () => {
    if (id) {
      setCurrentFormId(id);
    }
    if (
      (data()?.jwt_token &&
        formRecords().length == 0 &&
        (formJson.pages?.length == 0 || formJson.pages?.length == undefined)) ||
      (data()?.jwt_token && isFormChangesCanceled())
    ) {
      setFormJson({
        currentPage: 0,
      });
      setCurrentPage(1);
      setIsFormChangesCanceled(false);
      setUpdateRecordDetails({
        user_id: data()?.user_id,
        jwt_token: data()?.jwt_token,
        form_id: scriptOpenFormId() ? scriptOpenFormId() : id,
      });
      const navEntries: any = performance.getEntriesByType("navigation");
      if (!navEntries[0].name.includes("form/list")) {
        var urlString = new URLSearchParams(window.location.search);
        let search: any = urlString.get("search");
        setRecordsSearch(search);
      }
      setIsRecentRecords(false);
      updateFormRecordsList(id, 0);
      const formStructureApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/view`,
        jwt_token: data()?.jwt_token as string,
        body: {
          form_id: scriptOpenFormId() ? scriptOpenFormId() : id,
          user_id: data()?.user_id,
          limit: 1,
          recordIds: true,
        },
      };
      let formStructure = await fetchApi(formStructureApiParams);
      if (
        formStructure?.statusCode == 401 ||
        formStructure?.statusCode == 403
      ) {
        window.location.href = "/logout";
      }
      if (formStructure && formStructure.status) {
        const allFormRecords: any = formStructure.data.allFormRecords;
        setFormLastPageId(formStructure.data.lastPageId);
        let formName = formStructure.data.name;
        let formPages: any = [];
        for (let i = 0; i < formStructure.data.totalPages; i++) {
          formPages.push({});
        }
        setCurrentformName(formName);
        setTotalFormPages(formStructure.data.totalPages);
        if (userRole() != "admin") {
          setRecordDeleteRight(formStructure.data.recordDeleteRight);
        } else {
          setRecordDeleteRight("true");
        }
        if (
          userRole() == "admin" ||
          userRole() == "sub_admin" ||
          userRole() == "designer"
        ) {
          setRecordShareRight("true");
        } else if (userRole() == "viewer") {
          setRecordShareRight("false");
        } else {
          setRecordShareRight(formStructure.data.recordShareRight);
        }
        setPageTitle(`${SITE_TITLE} - ${formName}`);
        formStructure = JSON.parse(formStructure.data.structure);
        const pagesData = JSON.parse(JSON.stringify(formStructure?.pages));
        pagesData.forEach((page: any) => {
          page.fields?.sort((a: any, b: any) => {
            const leftA = a.properties.dataFieldSync.frame[0][0];
            const topA = a.properties.dataFieldSync.frame[0][1];
            const leftB = b.properties.dataFieldSync.frame[0][0];
            const topB = b.properties.dataFieldSync.frame[0][1];
            if (topA !== topB) {
              return topA - topB;
            } else {
              return leftA - leftB;
            }
          });
        });
        formPages[0] = pagesData;
        formPages = formPages.flatMap((page: any) => page);
        formPages = formPages.map((page: any) => {
          if (!page.fields) {
            return page;
          }
          return {
            ...page,
            fields: page.fields.map((field: any) => {
              if (field.properties?.dataFieldSync?.frame?.[0]?.length === 1) {
                return {
                  ...field,
                  properties: {
                    ...field.properties,
                    dataFieldSync: {
                      ...field.properties.dataFieldSync,
                      frame: field.properties.dataFieldSync.frame.map(
                        (subArray: any, index: number) =>
                          index === 0 && typeof subArray[0] === "string"
                            ? subArray[0].split(",").map(Number)
                            : subArray
                      ),
                    },
                  },
                };
              }
              return field;
            }),
          };
        });
        setFormJson({
          root: {
            formStructure: {
              ...formStructure,
              pages: formPages,
            },
          },
          pages: formPages,
        });
        setToolbarButtons([]);
        updatePreLoadData();

        if (userRole() != "viewer") {
          const clipboardApiParams = {
            method: "POST" as string,
            url: `${import.meta.env.VITE_API_URL}/form/clipboards`,
            jwt_token: data()?.jwt_token as string,
            body: {
              user_id: data()?.user_id,
              form_id: scriptOpenFormId() ? scriptOpenFormId() : id,
            },
          };
          let clipboardData = await fetchApi(clipboardApiParams);
          if (clipboardData?.statusCode == 401) {
            window.location.href = "/logout";
          } else if (clipboardData?.statusCode == 403) {
            location.reload();
          }
          if (clipboardData && clipboardData.status && clipboardData.data) {
            setClipData(JSON.parse(clipboardData.data.data));
          }
        }

        const apiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/auto/export/get`,
          jwt_token: data()?.jwt_token as string,
          body: {
            user_id: data()?.user_id,
            form_id: scriptOpenFormId() ? scriptOpenFormId() : id,
          },
        };
        let exportSettings = await fetchApi(apiParams);
        if (exportSettings?.statusCode == 401) {
          window.location.href = "/logout";
        } else if (exportSettings?.statusCode == 403) {
          location.reload();
        }
        if (exportSettings && exportSettings.status) {
          setExportSettings(exportSettings.data);
        }

        const usersListApiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/user/list`,
          jwt_token: data()?.jwt_token as string,
          body: {
            user_id: data()?.user_id,
            search_string: "",
            sort_string: "",
            form_id: id,
          },
        };
        let users = await fetchApi(usersListApiParams);
        if (users?.statusCode == 401 || users?.statusCode == 403) {
          window.location.href = "/logout";
        }
        setFormSharedUsers(users.data.formSharedUsers);
        users = users.data?.usersList.users;
        setUsersList(
          users
            .filter((user: any) => user.role != "viewer")
            .filter((user: any) => user.id != data()?.user_id)
        );
        const barcodeUrls: Array<string> = [];
        await Promise.all(
          formJson.pages.map(async (page: any) => {
            if (Object.keys(page).length > 0) {
              await Promise.all(
                page.fields?.map(async (field: any) => {
                  if (
                    field.type == "BarcodeField" &&
                    allFormRecords.length > 0
                  ) {
                    const formDataApiParams = {
                      method: "POST" as string,
                      url: `${import.meta.env.VITE_API_URL}/form/print/data`,
                      jwt_token: data()?.jwt_token,
                      body: {
                        user_id: data()?.user_id,
                        form_id: id,
                        record_ids: allFormRecords.join(","),
                      },
                    };
                    let response = await fetchApi(formDataApiParams);
                    if (response?.statusCode == 401) {
                      window.location.href = "/logout";
                    } else if (response?.statusCode == 403) {
                      location.reload();
                    }
                    await Promise.all(
                      response.data.records.map(async (record: any) => {
                        if (record[field.properties.dataFieldSync.uid]) {
                          const canvas = document.createElement("canvas");
                          JsBarcode(
                            canvas,
                            record[field.properties.dataFieldSync.uid],
                            {
                              format: "CODE128",
                              width: 2,
                              height: 100,
                              displayValue: false,
                            }
                          );
                          barcodeUrls.push(canvas.toDataURL("image/png"));
                        } else {
                          barcodeUrls.push("");
                        }
                      })
                    );
                  }
                })
              );
            }
          })
        );
        setBarcodeUrls(barcodeUrls);
        const pdfApiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/pdf-generate`,
          jwt_token: data()?.jwt_token,
          body: {
            form_id: id,
            user_id: data()?.user_id,
            export_formats: "PDF",
            barcodeUrls: barcodeUrls,
          },
        };
        let result = await fetchApi(pdfApiParams);
        if (result?.statusCode == 401 || result?.statusCode == 403) {
          window.location.href = "/logout";
        }
      }
      setScriptOpenFormId("");
    }
    if (isClipBoard() == "editor") {
      titleRef?.focus();
    }
    if (isRedirectFromBuilder() && recordsList().length == 0) {
      setIsRecentRecords(false);
      updateFormRecordsList(id, 0);
      setIsRedirectFromBuilder(false);
    }
    if (formJson.currentPage != -1) {
      const formFrame = document.querySelector(".builder-container");
      formFrame?.scrollTo({ top: 0 });
    }
    if (recordsList().length) {
      counter = 0;
    }
  });

  async function fetchRecord(
    read_id: string,
    user_id: string,
    record_id: string,
    is_read?: boolean | undefined,
    select_multiple?: boolean | undefined
  ) {
    if (recordsJson.currentRecord?.record_id == read_id) return;
    setPreLoadData([]);
    setSelectedRecordId(read_id);
    setRecordsJson({
      currentRecord: {},
      multipleRecords: [],
    });
    if (is_read == false) {
      const formRecordReadApiParams = {
        method: "POST",
        url: `${import.meta.env.VITE_API_URL}/form/record/read`,
        jwt_token: data()?.jwt_token,
        body: { user_id, form_id: id, record_id: read_id },
      };
      const recordRead = await fetchApi(formRecordReadApiParams);
      if (recordRead?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (recordRead?.statusCode == 403) {
        location.reload();
      }
      setRecordsList(
        recordsList().map((item: any) => {
          const updatedSubList = item.subList.map((subItem: any) => {
            if (subItem.id === read_id) {
              const updatedFormRecentRecords = subItem.form_recent_records.map(
                (record: any) => ({
                  ...record,
                  is_read: true,
                })
              );
              return {
                ...subItem,
                form_recent_records: updatedFormRecentRecords,
              };
            } else {
              return subItem;
            }
          });
          return {
            ...item,
            subList: updatedSubList,
          };
        })
      );
    }
    const formRecordsApiParams = {
      method: "POST",
      url: `${import.meta.env.VITE_API_URL}/form/record`,
      jwt_token: data()?.jwt_token,
      body: { user_id, record_id: [record_id] },
    };
    let recordData = await fetchApi(formRecordsApiParams);
    if (recordData?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (recordData?.statusCode == 403) {
      location.reload();
    }
    if (recordData && recordData.status) {
      let isRemoved: boolean = false;
      let multipleRecords: any = [...recordsJson.multipleRecords];
      if (
        recordsJson.multipleRecords?.findIndex(
          (record: any) => record.record_id == recordData.data[0].id
        ) != -1
      ) {
        isRemoved = true;
        multipleRecords.pop();
      }
      setDataSrc({});
      setRecordsJson({
        records: recordsJson.records.map((record: any) => {
          if (record.id === recordData.data[0].id) {
            return {
              ...record,
              form_recent_records: [{ is_read: true }],
            };
          }
          return record;
        }),
        currentRecord:
          recordData.data[0].record && !select_multiple
            ? {
                ...JSON.parse(recordData.data[0].record),
                record_id: recordData.data[0].id,
              }
            : {},
        multipleRecords:
          recordData.data[0].record && select_multiple
            ? isRemoved
              ? multipleRecords
              : [
                  ...recordsJson.multipleRecords,
                  {
                    ...JSON.parse(recordData.data[0].record),
                    record_id: recordData.data[0].id,
                  },
                ]
            : [
                {
                  ...JSON.parse(recordData.data[0].record),
                  record_id: recordData.data[0].id,
                },
              ],
      });
      if (!select_multiple) {
        const preLoadData: any = [];
        const additionalData: any = {};
        recordsJson.currentRecord.pages.forEach((page: any) => {
          page.fields?.forEach((field: any) => {
            if (
              field?.additional_data &&
              Object.keys(field.additional_data).length > 0
            ) {
              additionalData[field.field_id] = field.additional_data;
            }
            preLoadData[field.field_id] = field.value;
          });
        });
        formJson.pages.forEach((page: any) => {
          page?.fields?.forEach(async (field: any) => {
            if (field.properties.inputType == "currency") {
              preLoadData[field.properties.dataFieldSync.uid] = formJson.root
                .formStructure?.locale
                ? new Intl.NumberFormat(
                    formJson.root.formStructure?.locale.replace("_", "-"),
                    {
                      style: "currency",
                      currency: localList.filter(
                        (obj: any) =>
                          obj.value === formJson.root.formStructure?.locale
                      )[0]?.currency,
                    }
                  ).format(
                    preLoadData[field.properties.dataFieldSync.uid].replaceAll(
                      /[^0-9.]/g,
                      ""
                    )
                  )
                : "";
            }
          });
        });
        setAdditionalData(additionalData);
        setPreLoadData(preLoadData);
        setIsLocationUpdated(false);
        if (recordsJson.currentRecord?.is_locked) {
          setIsCurrentRecordLocked(true);
        } else {
          setIsCurrentRecordLocked(false);
        }
      }
      updatedCalulatedFieldvalue(true);
    }
    setIsPageLoader(false);
  }

  async function saveClip(user_id: string, jwt_token: string) {
    if (
      clipData.clipboard_data.filter(
        (clip: any) => clip.id == currentClipId()
      )[0]?.text == ""
    ) {
      setAlertMessage("<b>Field's can't be empty</b>");
      setPopup("alert");
    } else {
      const clipboardApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/clip/save`,
        jwt_token: jwt_token as string,
        body: {
          user_id,
          data: JSON.stringify(clipData),
        },
      };
      let clipboardData = await fetchApi(clipboardApiParams);
      if (clipboardData?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (clipboardData?.statusCode == 403) {
        location.reload();
      }
      if (clipboardData.status) {
        updateClipboard(user_id, jwt_token);
        setIsClipBoard("clipboard");
        setClipBoardType("");
      }
    }
  }

  async function updateClipboard(user_id: string, jwt_token: string) {
    const clipboardApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/clipboards`,
      jwt_token: jwt_token as string,
      body: { user_id, form_id: id },
    };
    let clipboardData = await fetchApi(clipboardApiParams);
    if (clipboardData?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (clipboardData?.statusCode == 403) {
      location.reload();
    }
    setClipData(JSON.parse(clipboardData.data.data));
  }

  async function openSortRecords() {
    const formStructureApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/view`,
      jwt_token: data()?.jwt_token,
      body: {
        form_id: id,
        user_id: data()?.user_id,
      },
    };
    let formStructure = await fetchApi(formStructureApiParams);
    if (formStructure?.statusCode == 401 || formStructure?.statusCode == 403) {
      window.location.href = "/logout";
    }
    if (formStructure.status && formStructure.data != null) {
      formStructure = JSON.parse(formStructure.data.structure);
    }
    setFormJson({
      root: { formStructure },
    });
    setFormJson({
      pages: formJson.root?.formStructure.pages,
    });
    setPopup("sortRecords");
  }

  return (
    <>
      <Notification />
      <ConfirmPopup
        message={
          popupQueue.includes("confirmRecordChanges")
            ? "<b>Save record?</b>"
            : alertMessage()
        }
        name={
          popupQueue.includes("confirmRecordDelete")
            ? "confirmRecordDelete"
            : popupQueue.includes("confirmUpdateImageSize")
            ? "confirmUpdateImageSize"
            : popupQueue.includes("confirmRecordChanges")
            ? "confirmRecordChanges"
            : "alert"
        }
        onconfirm={(status: boolean) => {
          if (popupQueue.includes("confirmRecordDelete")) {
            if (status) {
              removeRecord();
              updatePreLoadData();
              setDeletedRecords(deletedRecords() + recordToDeleteId()?.length);
            }
            setRecordToDelete("");
            setRecordToDeleteId([]);
            removePopup("confirmRecordDelete");
            setIsRecordUpdated("");
            setRecordSaveCancelAction("");
            setIsFormJsonUpdated(false);
            setIsRecordUpdated("");
          } else if (popupQueue.includes("confirmUpdateImageSize")) {
            if (status) {
              resizeImage(data()?.jwt_token, imageUpdateSize());
            }
            removePopup("confirmUpdateImageSize");
          } else if (popupQueue.includes("confirmRecordChanges")) {
            if (status) {
              setIsRecordSubmit(true);
              if (isRecordUpdated() == "add") {
                addRecord();
                if (linkedFormRecordsData().length > 0) {
                  linkedFormRecordsData().forEach((item: any) => {
                    addRecord(item.uid, "", item.record, item.form_id);
                  });
                  setLinkedFormRecordsData([]);
                }
              } else {
                if (recordSaveCancelAction() == "clone") {
                  cloneRecord(id);
                } else {
                  updateRecord();
                  if (linkedFormRecordsData().length > 0) {
                    linkedFormRecordsData().forEach((item: any) => {
                      updateRecord(
                        "",
                        "",
                        item.record,
                        item.form_id,
                        item.record_id
                      );
                    });
                    setLinkedFormRecordsData([]);
                  }
                }
              }
            } else {
              updateFormRecordsList(id, 0);
              switch (recordSaveCancelAction()) {
                case "home":
                  navigate(`/form/list`);
                  setCurrentPage(1);
                  setFormJson({
                    currentPage: 0,
                  });
                  break;

                case "builder":
                  setFormJson({
                    currentField: {},
                    multipleFields: [],
                  });
                  navigate(`/form/builder/${id}`);
                  break;

                case "export":
                  setRecordsJson({
                    currentRecord: {},
                    multipleRecords: [],
                  });
                  updatePreLoadData();
                  setSelectedRecord("");
                  exportForm();
                  break;

                case "clone":
                  if (
                    (Object.keys(recordsJson.currentRecord).length > 0 ||
                      recordsJson.multipleRecords.length > 0) &&
                    alertFormRecords()
                  ) {
                    if (recordsJson.currentRecord?.updated_at) {
                      cloneRecord(id);
                    }
                  }
                  break;

                case "add":
                  if (
                    ((Object.keys(recordsJson.currentRecord).length > 0 ||
                      recordsJson.multipleRecords.length > 0) &&
                      alertFormRecords()) ||
                    Object.keys(recordsJson.currentRecord).length == 0
                  ) {
                    newRecord();
                  }
                  break;

                case "clipboard":
                  toggleClipboard();
                  break;

                case "lock":
                  if (
                    (Object.keys(recordsJson.currentRecord).length > 0 ||
                      recordsJson.multipleRecords.length > 0) &&
                    alertFormRecords()
                  ) {
                    lockRecord();
                  }
                  break;

                case "sort":
                  openSortRecords();
                  break;

                case "restore":
                  setIsPageLoader(true);
                  setIsRecordsSettingsOpen(false);
                  restoreDeletedRecords();
                  break;

                default:
                  setRecordIndex(recordSaveCancelAction()?.split("_")[1]);
                  setSelectedRecordId(recordSaveCancelAction()?.split("_")[0]);
                  setSelectedRecord(
                    recordSaveCancelAction()?.split("_")[0] +
                      "_" +
                      recordSaveCancelAction()?.split("_")[1]
                  );
                  setIsPageLoader(true);
                  fetchRecord(
                    recordSaveCancelAction()?.split("_")[0],
                    data()?.user_id,
                    recordSaveCancelAction().split("_")[2],
                    recordSaveCancelAction().split("_")[3] == "true"
                      ? true
                      : false
                  );
                  break;
              }
              setRecordSaveCancelAction("");
              setIsFormJsonUpdated(false);
              setIsRecordUpdated("");
              // setPreLoadData([]);
              // setSelectedRecordId("");
              // setFormJson({
              //   currentPage: 0,
              // });
              // setCurrentPage(formJson.currentPage + 1);
              // setSelectedRecordId("");
              // setRecordsJson({
              //   records: !recordsJson.currentRecord?.last_saved
              //     ? recordsJson.records.slice(1)
              //     : [...recordsJson.records],
              //   currentRecord: {},
              //   multipleRecords: [],
              // });
              // setSelectedRecord("");
              // updatePreLoadData();
            }
            setTimeout(() => {
              removePopup("confirmRecordChanges");
            }, 100);
          } else {
            removePopup("alert");
          }
        }}
        warning={
          popupQueue.includes("confirmRecordDelete") ||
          popupQueue.includes("confirmUpdateImageSize")
            ? true
            : false
        }
        alert={
          !popupQueue.includes("confirmRecordDelete") &&
          !popupQueue.includes("confirmUpdateImageSize") &&
          !popupQueue.includes("confirmRecordChanges")
            ? true
            : false
        }
      ></ConfirmPopup>
      <Show when={popupQueue.includes("PhotoAnnotate")}>
        <PhotoAnnotate jwt_token={data()?.jwt_token}></PhotoAnnotate>
      </Show>
      <Show when={popupQueue.includes("signature")}>
        <SignaturePopup data={currentSignatureField()}></SignaturePopup>
      </Show>
      <Show when={popupQueue.includes("recordSubmit")}>
        <RecordSubmitPopup></RecordSubmitPopup>
      </Show>
      <Show when={popupQueue.includes("folderForms")}>
        <FolderForms
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
        ></FolderForms>
      </Show>
      <Show when={popupQueue.includes("sortRecords")}>
        <SortRecords
          user_id={data()?.user_id}
          form_id={id}
          jwt_token={data()?.jwt_token}
        ></SortRecords>
      </Show>
      <Show when={popupQueue.includes("exportMail")}>
        <ExportMailPopup
          form_id={id}
          jwt_token={data()?.jwt_token}
          userDetails={data()?.userDetails}
          exportSettings={exportSettings()}
        ></ExportMailPopup>
      </Show>
      <Show when={openScanner()}>
        <Popup heading={"Scanner"} classes="sm" name="barcode">
          <div id="reader"></div>
        </Popup>
      </Show>
      <Show when={contextMenu() == "record"}>
        <ContextMenu
          top={contextMenuTop()}
          left={contextMenuLeft()}
          field="record"
          formId={id}
        ></ContextMenu>
      </Show>
      <ExportFilesPopup
        user_id={data()?.user_id}
        jwt_token={data()?.jwt_token}
      ></ExportFilesPopup>
      <Show when={isPrinting()}>
        <div class="pa b-0">
          <PrintForm
            user_id={data()?.user_id}
            jwt_token={data()?.jwt_token}
            form_id={id}
          ></PrintForm>
        </div>
      </Show>
      <Show when={popupQueue.includes("shareRecord")}>
        <ShareRecord
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          users={usersList()}
          form_id={id}
        ></ShareRecord>
      </Show>
      <div
        class="form-builder view"
        onclick={(e: any) => {
          setContextMenu("");
          const element: any = document.getElementById(
            `fieldDropDown_${selectedDropDownId()}`
          );
          if (element?.contains(e.target)) {
            selectedDropDown() == selectedDropDownId()
              ? setSelectedDropDown(selectedDropDownId())
              : (setSelectedDropDown(""), setIsFormDropDownOpen(false));
          } else {
            setIsFormDropDownOpen(false);
            setSelectedDropDown("");
          }
          const element2: any = document.querySelector(
            ".builder-pagination-dropdown"
          );
          if (element2?.contains(e.target)) {
            setIsBuilderPageDropdownOpen(true);
          } else {
            setIsBuilderPageDropdownOpen(false);
          }
          const element3: any = document.querySelector("#records_setting");
          if (element3?.contains(e.target) && deletedRecordsCount() > 0) {
            setIsRecordsSettingsOpen(true);
          } else {
            setIsRecordsSettingsOpen(false);
          }
        }}
      >
        <FormNavbar
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          formName={currentformName()}
          formId={id}
          active="render"
        ></FormNavbar>
        <div class="builder-body">
          <Show
            when={
              !formSettingsBar() &&
              formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                (obj: any) => obj.type == "tbicon_records"
              ) != -1
            }
          >
            <div
              class={`builder-sidebar ${recordSidebarOpen() ? "toggle" : ""} ${
                isToggleMessage() ? "overflow-unset" : ""
              }`}
            >
              <Show when={exportFormSettings()}>
                <div class="export-bar">
                  <For
                    each={[
                      "Export",
                      "Print",
                      "Delete",
                      "All Records",
                      "Form Only",
                    ]}
                  >
                    {(item: any) => (
                      <button
                        class={`btn badge ${
                          item == "Delete" && selectedRecords().length == 0
                            ? "disabled"
                            : ""
                        }`}
                        onclick={() => {
                          switch (item) {
                            case "Export":
                              setExportFormat([]);
                              if (
                                formJson.root?.formStructure
                                  ?.autoExportSetting &&
                                automaticExport()
                              ) {
                                setExportFormat(
                                  formJson.root?.formStructure
                                    ?.autoExportSetting.formats
                                );
                                if (isProcessing() == "") {
                                  exportFormRecordFiles(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    id,
                                    formJson.root?.formStructure
                                      ?.autoExportSetting.service,
                                    exportSettings()
                                  );
                                }
                              } else {
                                setFormSettingsBar(true);
                              }
                              break;
                            case "Print":
                              setIsPrinting(true);
                              break;
                            case "Delete":
                              if (recordDeleteRight()) {
                                setAlertMessage("<b>Delete this record?</b>");
                                setRecordToDeleteId(selectedRecords());
                                setPopup("confirmRecordDelete");
                              }
                              break;
                            case "All Records":
                              setSelectedRecords(
                                recordsJson.records.map(
                                  (record: any) => record.id
                                )
                              );
                              break;
                            default:
                              setSelectedRecords([]);
                              break;
                          }
                        }}
                      >
                        <Show
                          when={
                            isProcessing() == "" ||
                            (isProcessing() != "" && item != "Export")
                          }
                        >
                          {item}
                        </Show>
                        <Show when={isProcessing() != "" && item == "Export"}>
                          &nbsp;<Icons name="loader"></Icons>
                        </Show>
                      </button>
                    )}
                  </For>
                  <Show when={formJson.root?.formStructure?.autoExportSetting}>
                    <Checkbox
                      label="Use automatic settings"
                      name="automatic_settings"
                      value={automaticExport() as boolean}
                      setValue={(val: boolean) =>
                        automaticExport()
                          ? setAutomaticExport(false)
                          : setAutomaticExport(true)
                      }
                    />
                  </Show>
                </div>
              </Show>
              <div
                class="builder-title"
                style={{
                  "--top": exportFormSettings()
                    ? formJson.root?.formStructure?.autoExportSetting
                      ? "126px"
                      : "86px"
                    : "0px",
                }}
              >
                <div class="flex app-flex align-center space-between w-100">
                  {/* <div
                    class={deletedRecordsCount() > 0 ? "cur-p pr" : ""}
                    id="records_setting"
                  >
                    <Show when={userRole() != "viewer"}>
                      <Icons
                        name="moreSettings"
                        width={20}
                        height={20}
                        stroke={
                          deletedRecordsCount() > 0 ? "#2e61a3" : "#6c6e6f"
                        }
                      ></Icons>
                    </Show>
                  </div> */}
                  <div class="w-50p">&nbsp;</div>
                  <span class="subtitle pointer-none">
                    Records(
                    {totalRecordsShowCount()})
                  </span>
                  <div class="flex gap-0_5 app-flex align-center">
                    <Popover content="Toggle Recent Records" top={-6}>
                      <label
                        class="switch"
                        onmouseover={() => setIsToggleMessage(true)}
                        onmouseout={() => setIsToggleMessage(false)}
                      >
                        <input type="checkbox" onchange={handleToggleChange} />
                        <span class="slider round"></span>
                      </label>
                    </Popover>
                    <Show
                      when={
                        !formJson.root.formStructure?.isRecordSortingDisabled
                      }
                    >
                      <div
                        class="cur-p"
                        onClick={async () => {
                          if (
                            Object.keys(recordsJson.currentRecord).length > 0 &&
                            isRecordUpdated() != "" &&
                            !isRecordSubmit()
                          ) {
                            setRecordSaveCancelAction("sort");
                            setPopup("confirmRecordChanges");
                          } else if (
                            Object.keys(recordsJson.currentRecord).length > 0 ||
                            recordsJson.multipleRecords.length > 0
                          ) {
                            if (alertFormRecords()) {
                              openSortRecords();
                            }
                          } else {
                            openSortRecords();
                          }
                        }}
                      >
                        <Icons
                          name="sort"
                          width={20}
                          height={20}
                          stroke="hsl(214, 56%, 41%)"
                        ></Icons>
                      </div>
                    </Show>
                    <div
                      class="sidebar-menu"
                      onclick={() => {
                        setRecordSidebarOpen(false);
                        setExportFormSettings(false);
                        setRecordSidebarOpen(false);
                      }}
                    >
                      &nbsp;
                      <Icons name="x" stroke="red" strokeWidth="2.5"></Icons>
                    </div>
                  </div>
                </div>
              </div>
              <div class="builder-sidebar__body">
                <div class="form-records__list">
                  <div
                    class="records-search-div"
                    style={{
                      "--top": exportFormSettings()
                        ? formJson.root?.formStructure?.autoExportSetting
                          ? "175px"
                          : "135px"
                        : "49px",
                    }}
                  >
                    <Show when={isRecordsSettingsOpen()}>
                      <div
                        class="records-restore lbl"
                        onclick={() => {
                          if (
                            Object.keys(recordsJson.currentRecord).length > 0 &&
                            isRecordUpdated() != "" &&
                            !isRecordSubmit()
                          ) {
                            setRecordSaveCancelAction("restore");
                            setPopup("confirmRecordChanges");
                          } else {
                            setIsPageLoader(true);
                            setIsRecordsSettingsOpen(false);
                            restoreDeletedRecords();
                          }
                        }}
                      >
                        Restore Deleted Records
                      </div>
                    </Show>
                    <Show when={!isRecordsSettingsOpen()}>
                      <input
                        class="records-search"
                        type="text"
                        placeholder="Search"
                        onclick={() => {
                          setActiveField("records_search");
                        }}
                        value={recordsSearch()}
                        onInput={(e: any) => {
                          clearTimeout(debounceTimeout);
                          debounceTimeout = setTimeout(() => {
                            const searchValue = e.target.value;
                            setRecordsSearch(searchValue);
                            setRecordsJson({ records: [] });
                            setOffset(0);
                            updateFormRecordsList(id, 0);
                            const newUrl = `/form/render/${id}?search=${encodeURIComponent(
                              searchValue
                            )}`;
                            window.history.pushState(
                              { search: searchValue },
                              "",
                              newUrl
                            );
                          }, 300);
                        }}
                      />
                      <Show
                        when={
                          activeField() == "records_search" &&
                          recordsSearch()?.length > 0
                        }
                      >
                        <span
                          class="pa z-index-1 cur-p r-15"
                          onclick={() => {
                            setRecordsSearch("");
                            setRecordsJson({ records: [] });
                            const newUrl = `/form/render/${id}?search=${encodeURIComponent(
                              recordsSearch()
                            )}`;
                            window.history.pushState(
                              { search: recordsSearch() },
                              "",
                              newUrl
                            );
                            setRecordsList([]);
                            setOffset(0);
                            updateFormRecordsList(id, 0);
                          }}
                        >
                          <Icons name="circleX" width={20} height={20}></Icons>
                        </span>
                      </Show>
                    </Show>
                  </div>
                  <div class="record-fields">
                    <Show when={isLoader()}>
                      <For each={Array.from({ length: 30 }, (_, i) => i + 1)}>
                        {() => <div class="skeleton-loading records"></div>}
                      </For>
                    </Show>
                    <Show when={!isLoader()}>
                      <For each={recordsList()}>
                        {(item: any, item_key: any) => {
                          return (
                            <>
                              <div class="label">{item.label}</div>
                              <ul>
                                <For each={item.subList}>
                                  {(record: any, key: any) => {
                                    const uniqueIndex = counter++;
                                    return (
                                      <li
                                        data-record-id={
                                          record.id + "_" + uniqueIndex
                                        }
                                        class={`${
                                          recordsJson.multipleRecords?.length >
                                          1
                                            ? recordsJson.multipleRecords
                                                .map(
                                                  (obj: any) => obj.record_id
                                                )
                                                .includes(record.id)
                                              ? "active"
                                              : ""
                                            : selectedRecord() ==
                                                record.id + "_" + uniqueIndex ||
                                              recordToDelete() == record.id
                                            ? selectedRecord() ==
                                              record.id + "_" + uniqueIndex
                                              ? "active"
                                              : "hover"
                                            : ""
                                        }`}
                                        onclick={async (event: any) => {
                                          if (
                                            Object.keys(
                                              recordsJson.currentRecord
                                            ).length > 0 &&
                                            isRecordUpdated() != "" &&
                                            !isRecordSubmit()
                                          ) {
                                            setRecordSaveCancelAction(
                                              record.id +
                                                "_" +
                                                uniqueIndex +
                                                "_" +
                                                (record.record_id
                                                  ? record.record_id
                                                  : record.id) +
                                                "_" +
                                                (record?.form_recent_records
                                                  ? record
                                                      ?.form_recent_records[0]
                                                      ?.is_read
                                                  : true)
                                            );
                                            if (
                                              !(
                                                event.target.classList?.contains(
                                                  "delete-record"
                                                ) ||
                                                event.target.parentElement.classList?.contains(
                                                  "delete-record"
                                                ) ||
                                                event.target.parentElement.parentElement.classList?.contains(
                                                  "delete-record"
                                                )
                                              )
                                            ) {
                                              setPopup("confirmRecordChanges");
                                            }
                                          } else {
                                            setIsRecordUpdated("");
                                            if (exportFormSettings()) {
                                              const records: any = [
                                                ...selectedRecords(),
                                              ];
                                              const index: any =
                                                records?.findIndex(
                                                  (item: any) =>
                                                    record.id == item
                                                );
                                              if (index != -1) {
                                                records.splice(index, 1);
                                              } else {
                                                records.push(record.id);
                                              }
                                              setSelectedRecords(records);
                                            } else {
                                              if (
                                                userRole() != "viewer" &&
                                                event.shiftKey
                                              ) {
                                                updatePreLoadData();
                                                const multipleRecords: any = [
                                                  ...recordsJson.multipleRecords,
                                                ];
                                                if (
                                                  multipleRecords.length == 0 &&
                                                  Object.keys(
                                                    recordsJson.currentRecord
                                                  )?.length > 0
                                                ) {
                                                  multipleRecords.push(
                                                    recordsJson.currentRecord
                                                  );
                                                }
                                                const index: any =
                                                  multipleRecords?.findIndex(
                                                    (item: any) =>
                                                      record.id == item.id
                                                  );
                                                if (index != -1) {
                                                  multipleRecords.splice(
                                                    index,
                                                    1
                                                  );
                                                } else {
                                                  multipleRecords.push({
                                                    ...record,
                                                    record_id: record.id,
                                                  });
                                                }
                                                setRecordsJson({
                                                  currentRecord: {},
                                                  multipleRecords,
                                                });
                                              } else {
                                                setRecordIndex(uniqueIndex);
                                                setSelectedRecordId(record.id);
                                                setSelectedRecord(
                                                  record.id + "_" + uniqueIndex
                                                );
                                                setIsPageLoader(true);
                                                fetchRecord(
                                                  record.id,
                                                  data()?.user_id,
                                                  record.record_id
                                                    ? record.record_id
                                                    : record.id,
                                                  record?.form_recent_records
                                                    ? record
                                                        ?.form_recent_records[0]
                                                        ?.is_read
                                                    : true
                                                );
                                              }
                                            }
                                          }
                                        }}
                                        oncontextmenu={(e: any) => {
                                          if (
                                            !(
                                              Object.keys(
                                                recordsJson.currentRecord
                                              ).length > 0 &&
                                              isRecordUpdated() != "" &&
                                              !isRecordSubmit()
                                            )
                                          ) {
                                            if (
                                              userRole() != "viewer" &&
                                              (Object.keys(
                                                recordsJson.currentRecord
                                              )?.length > 0 ||
                                                recordsJson.multipleRecords
                                                  ?.length > 0) &&
                                              (formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                                                (obj: any) =>
                                                  obj.type ==
                                                  "tbicon_lock_record"
                                              ) != -1 ||
                                                formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                                                  (obj: any) =>
                                                    obj.type ==
                                                    "tbicon_delete_record"
                                                ) != -1 ||
                                                formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                                                  (obj: any) =>
                                                    obj.type ==
                                                    "tbicon_clone_record"
                                                ) != -1)
                                            ) {
                                              e.preventDefault();
                                              setContextMenuLeft(
                                                String(e.clientX + 20)
                                              );
                                              setContextMenuTop(
                                                String(
                                                  window.innerHeight - 110 <
                                                    e.clientY - 30
                                                    ? window.innerHeight - 110
                                                    : e.clientY - 30
                                                )
                                              );
                                              setRecordContentMenuId(record.id);
                                              setContextMenu("record");
                                            }
                                          }
                                        }}
                                        onmouseover={() => {
                                          setRecordToDelete(record.id);
                                        }}
                                        onmouseout={() => {
                                          setRecordToDelete("");
                                        }}
                                      >
                                        <span
                                          class={`mark ${
                                            record?.form_recent_records &&
                                            Object.keys(
                                              record?.form_recent_records
                                            ).length > 0 &&
                                            !record?.form_recent_records[0]
                                              ?.is_read
                                              ? "recent"
                                              : ""
                                          }`}
                                        ></span>
                                        <div class="record-data">
                                          <span
                                            data-title={record.record_name}
                                            class={`notification-badge record-name ${
                                              record.record_name?.length >
                                              (record?.is_locked
                                                ? recordsJson.multipleRecords
                                                    ?.length == 0 &&
                                                  recordToDelete() ==
                                                    record.id &&
                                                  contextMenu() == ""
                                                  ? 25
                                                  : 25
                                                : recordsJson.multipleRecords
                                                    ?.length == 0 &&
                                                  recordToDelete() ==
                                                    record.id &&
                                                  contextMenu() == ""
                                                ? 28
                                                : 28)
                                                ? "long"
                                                : ""
                                            }`}
                                          >
                                            {record.record_name?.length >
                                            (record?.is_locked
                                              ? recordsJson.multipleRecords
                                                  ?.length == 0 &&
                                                recordToDelete() == record.id &&
                                                contextMenu() == ""
                                                ? 19
                                                : 25
                                              : recordsJson.multipleRecords
                                                  ?.length == 0 &&
                                                recordToDelete() == record.id &&
                                                contextMenu() == ""
                                              ? 22
                                              : 28)
                                              ? `${record.record_name.substring(
                                                  0,
                                                  record?.is_locked
                                                    ? recordsJson
                                                        .multipleRecords
                                                        ?.length <= 1 &&
                                                      recordToDelete() ==
                                                        record.id &&
                                                      contextMenu() == ""
                                                      ? 19
                                                      : 25
                                                    : recordsJson
                                                        .multipleRecords
                                                        ?.length <= 1 &&
                                                      recordToDelete() ==
                                                        record.id &&
                                                      contextMenu() == ""
                                                    ? 22
                                                    : 28
                                                )}...`
                                              : record?.record_name &&
                                                record?.record_name?.trim()
                                              ? record.record_name
                                              : "Unnamed"}
                                          </span>
                                          <div class="flex app-flex align-center record-icons">
                                            <Show when={userRole() != "viewer"}>
                                              <Show
                                                when={!exportFormSettings()}
                                              >
                                                <Show
                                                  when={
                                                    userRole() == "admin" ||
                                                    (recordShareRight() ==
                                                      "true" &&
                                                      record?.added_by_id ==
                                                        data()?.user_id)
                                                  }
                                                >
                                                  {/* <Popover
                                                    content="Share Record"
                                                    left={105}
                                                    top={
                                                      uniqueIndex ==
                                                      totalRecordsShowCount() -
                                                        1
                                                        ? 55
                                                        : -10
                                                    }
                                                  > */}
                                                  <div
                                                    data-title="Share Record"
                                                    class={`notification-badge record-badge ${
                                                      totalRecordsShowCount() >
                                                        10 &&
                                                      uniqueIndex ==
                                                        totalRecordsShowCount() -
                                                          1
                                                        ? "last"
                                                        : ""
                                                    } ${
                                                      recordDeleteRight() !=
                                                      "true"
                                                        ? "delete"
                                                        : ""
                                                    } pr-5 cur-p ${
                                                      recordsJson
                                                        .multipleRecords
                                                        ?.length <= 1 &&
                                                      recordToDelete() ==
                                                        record.id &&
                                                      contextMenu() == ""
                                                        ? ""
                                                        : "d-n"
                                                    }`}
                                                    onclick={async () => {
                                                      if (
                                                        Object.keys(
                                                          recordsJson.currentRecord
                                                        ).length > 0 &&
                                                        isRecordUpdated() !=
                                                          "" &&
                                                        !isRecordSubmit()
                                                      ) {
                                                        setPopup(
                                                          "confirmRecordChanges"
                                                        );
                                                      } else {
                                                        const apiParams = {
                                                          method: "POST",
                                                          url: `${
                                                            import.meta.env
                                                              .VITE_API_URL
                                                          }/form/shared/records`,
                                                          jwt_token:
                                                            data()?.jwt_token,
                                                          body: {
                                                            user_id:
                                                              data()?.user_id,
                                                            form_id: id,
                                                            record_id:
                                                              record.id,
                                                          },
                                                        };
                                                        const sharedRecords: any =
                                                          await fetchApi(
                                                            apiParams
                                                          );
                                                        if (
                                                          sharedRecords?.statusCode ==
                                                          401
                                                        ) {
                                                          window.location.href =
                                                            "/logout";
                                                        }
                                                        setSharedUsersList(
                                                          sharedRecords.data
                                                            .sharedRecords
                                                        );
                                                        setPopup("shareRecord");
                                                      }
                                                    }}
                                                  >
                                                    <Icons
                                                      name="exportIco"
                                                      width={20}
                                                      height={20}
                                                    ></Icons>
                                                  </div>
                                                  {/* </Popover> */}
                                                </Show>
                                                <Show
                                                  when={
                                                    recordDeleteRight() ==
                                                      "true" &&
                                                    formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                                                      (obj: any) =>
                                                        obj.type ==
                                                        "tbicon_delete_record"
                                                    ) != -1
                                                  }
                                                >
                                                  {/* <Popover
                                                    content="Delete Record"
                                                    left={105}
                                                    top={
                                                      uniqueIndex ==
                                                      totalRecordsShowCount() -
                                                        1
                                                        ? 55
                                                        : -10
                                                    }
                                                  > */}
                                                  <div
                                                    data-title="Delete Record"
                                                    class={`notification-badge record-badge ${
                                                      totalRecordsShowCount() >
                                                        10 &&
                                                      uniqueIndex ==
                                                        totalRecordsShowCount() -
                                                          1
                                                        ? "last"
                                                        : ""
                                                    } delete delete-record pr-5 cur-p ${
                                                      recordsJson
                                                        .multipleRecords
                                                        ?.length <= 1 &&
                                                      recordToDelete() ==
                                                        record.id &&
                                                      contextMenu() == ""
                                                        ? ""
                                                        : "d-n"
                                                    }`}
                                                    onclick={() => {
                                                      // if (
                                                      //   Object.keys(
                                                      //     recordsJson.currentRecord
                                                      //   ).length > 0 &&
                                                      //   isRecordUpdated() !=
                                                      //     "" &&
                                                      //   !isRecordSubmit()
                                                      // ) {
                                                      //   setPopup(
                                                      //     "confirmRecordChanges"
                                                      //   );
                                                      // } else {
                                                      setAlertMessage(
                                                        "<b>Delete this record?</b>"
                                                      );
                                                      setRecordToDeleteId([
                                                        record.id,
                                                      ]);
                                                      setPopup(
                                                        "confirmRecordDelete"
                                                      );
                                                      // }
                                                    }}
                                                  >
                                                    <Icons
                                                      name="trash"
                                                      stroke="red"
                                                      width={20}
                                                      height={20}
                                                    ></Icons>
                                                  </div>
                                                  {/* </Popover> */}
                                                </Show>
                                              </Show>
                                              <Show when={record?.is_locked}>
                                                <div>
                                                  <Icons
                                                    name="lock"
                                                    width={24}
                                                    height={24}
                                                  ></Icons>
                                                </div>
                                                &nbsp;
                                              </Show>
                                            </Show>
                                            <Show
                                              when={
                                                record?.added_by &&
                                                !exportFormSettings()
                                              }
                                            >
                                              <Popover
                                                content={`Created By : ${
                                                  record.added_by
                                                } ${
                                                  record?.updated_by
                                                    ? `<br><div class="underline"></div> Updated By : ${record?.updated_by}`
                                                    : ""
                                                }`}
                                                top={-3}
                                              >
                                                <div class="record-info">
                                                  <Icons
                                                    name="info"
                                                    stroke="#2e61a3"
                                                    width={16}
                                                    height={16}
                                                  ></Icons>
                                                </div>
                                              </Popover>
                                            </Show>
                                            <Show
                                              when={selectedRecords().includes(
                                                record.id
                                              )}
                                            >
                                              <div>
                                                <Icons
                                                  name="check"
                                                  width={24}
                                                  height={24}
                                                ></Icons>
                                              </div>
                                            </Show>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }}
                                </For>
                              </ul>
                            </>
                          );
                        }}
                      </For>
                    </Show>
                  </div>
                </div>
              </div>
            </div>
          </Show>
          <Show
            when={
              formSettingsBar() &&
              formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                (obj: any) => obj.type == "tbicon_records"
              ) != -1
            }
          >
            <div
              class={`builder-sidebar ${recordSidebarOpen() ? "toggle" : ""}`}
            >
              <div class="builder-title">
                <div class="app-flex flex flex-column w-100 text-center">
                  <span class="fw-600">Export Form</span>
                  <span>File Formats:</span>
                </div>
              </div>
              <div class="builder-sidebar__body">
                <div class="app-flex flex gap-1_3 flex-wrap p-10">
                  <For each={exportFormats}>
                    {(item: any) => (
                      <div class="w-28">
                        <Checkbox
                          label={item.label}
                          name={item.label}
                          value={
                            exportFormat().includes(item.value)
                              ? true
                              : (false as boolean)
                          }
                          setValue={(val: boolean) => {
                            const tempArr: any = [...exportFormat()];
                            const index: any = tempArr?.findIndex(
                              (selectedValue: any) =>
                                selectedValue == item.value
                            );
                            if (index != -1) {
                              tempArr.splice(index, 1);
                            } else {
                              tempArr.push(item.value);
                            }
                            setExportFormat(tempArr);
                          }}
                        />
                      </div>
                    )}
                  </For>
                </div>
                <div class="underline"></div>
                <br />
                <br />
                <div class="app-flex flex gap flex-column p-10">
                  <For
                    each={[
                      ...cloudServices,
                      {
                        label: "Save to Folder",
                        value: "savetofolder",
                        icon: "savetofolder.png",
                      },
                      { label: "Cancel", value: "cancel" },
                    ]}
                  >
                    {(item: any) => (
                      <button
                        class={`btn w-100 export ${
                          (item.value != "cancel" &&
                            exportFormat().length == 0) ||
                          isProcessing() != ""
                            ? "disabled"
                            : ""
                        } ${item.value}`}
                        onclick={async () => {
                          if (isProcessing() == "") {
                            if (item.value == "cancel") {
                              setFormSettingsBar(false);
                            } else {
                              if (exportFormat().length > 0) {
                                exportFormRecordFiles(
                                  data()?.user_id,
                                  data()?.jwt_token,
                                  id,
                                  item.value,
                                  exportSettings()
                                );
                              }
                            }
                          }
                        }}
                      >
                        <Show when={item.value != "cancel"}>
                          <img src={`/cloudServiceIcons/${item.icon}`}></img>
                        </Show>
                        <Show when={item.value == "cancel"}>
                          <Icons
                            name="x"
                            width={22}
                            height={22}
                            stroke="red"
                          ></Icons>
                        </Show>
                        <Show when={isProcessing() != item.value}>
                          {item.label}
                        </Show>
                        <Show when={isProcessing() == item.value}>
                          {item.label?.replace("Save", "Saving")}
                          &nbsp;
                          <Icons name="loader" width={30} height={30}></Icons>
                        </Show>
                      </button>
                    )}
                  </For>
                </div>
              </div>
            </div>
          </Show>
          <Show
            when={
              formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                (obj: any) => obj.type == "tbicon_records"
              ) == -1
            }
          >
            <div class="builder-sidebar"></div>
          </Show>
          <div
            class={`builder-container ${isMobile() ? "pr overflow-unset" : ""}`}
          >
            <div
              class={`builder-form ${isMobile() ? "origin-left" : ""}`}
              id="form_frame"
              onclick={(e: any) => {
                if (e.target.id == "form_frame") {
                  setSelectedDropDown("");
                  setIsFormDropDownOpen(false);
                  setActiveField("");
                }
              }}
              style={{
                "--width": Number(convert(FRAME_WIDTH, "in-px")) + "px",
                "--height": Number(convert(FRAME_HEIGHT, "in-px")) + "px",
                "--scale": formScale(),
                "--scale-margin-bottom":
                  Math.round(
                    formScale() < 1
                      ? -1 *
                          ((1 - formScale()) *
                            Number(convert(FRAME_HEIGHT, "in-px")))
                      : (formScale() - 1) *
                          Number(convert(FRAME_HEIGHT, "in-px")) +
                          28
                  ) + "px",
                "--scale-margin-left":
                  ((formScale() - 1) * 400 - 16 > otherSpace()
                    ? (formScale() - 1) * 400 - 16
                    : otherSpace()) + "px",
              }}
            >
              <For each={formJson?.pages[formJson.currentPage]?.fields}>
                {(field) => (
                  <FormField
                    data={field}
                    fieldsType={
                      isCurrentRecordLocked() || userRole() == "viewer"
                        ? "locked"
                        : "render"
                    }
                    jwt_token={data()?.jwt_token}
                    user_id={data()?.user_id}
                  />
                )}
              </For>
            </div>
            <div class="underline form" style={{ "--left": "300px" }}></div>
            <div class="mb-28"></div>
            <div
              class={`form-pagination flex app-flex space-between align-center ${
                isMobile() ? "pa p0 pr-10" : ""
              }`}
              style={{
                "--left": "300px",
                "--width":
                  Number(convert(FRAME_WIDTH, "in-px")) +
                  otherSpace() * 2 +
                  27 +
                  "px",
                "--padding": `0px ${otherSpace() + 16}px`,
              }}
            >
              <div class="flex align-center bg-light app-dn br-8">
                <button
                  class={`btn btn-ico small ${
                    formScale() <= 0.25 ? "bg-gray disabled" : ""
                  }`}
                  onclick={() => {
                    setFormScale(Number((formScale() - 0.05).toFixed(2)));
                    localStorage.setItem("fc_form_scale", String(formScale()));
                  }}
                >
                  <Icons
                    name="minus"
                    stroke="#525657"
                    width={18}
                    height={18}
                  ></Icons>
                </button>
                <button
                  class="btn btn-ico small"
                  onclick={() => {
                    setFormScale(1);
                    localStorage.setItem("fc_form_scale", "1");
                  }}
                >
                  <label class="lbl">Fit</label>
                </button>
                <button
                  class={`btn btn-ico small ${
                    formScale() >= 1.5 ? "bg-gray disabled" : ""
                  }`}
                  onclick={() => {
                    setFormScale(Number((formScale() + 0.05).toFixed(2)));
                    localStorage.setItem("fc_form_scale", String(formScale()));
                  }}
                >
                  <Icons
                    name="plus"
                    stroke="#525657"
                    width={18}
                    height={18}
                  ></Icons>
                </button>
              </div>
              <div class="flex align-center gap">
                <Show when={currentPage() != 1}>
                  <span
                    class="mt-4 cur-p"
                    onclick={() => {
                      setCurrentPage(currentPage() - 1);
                      setFormJson({
                        currentField: {},
                        currentPage: currentPage() - 1,
                      });
                      updateFormJsonPages(
                        data()?.user_id,
                        data()?.jwt_token,
                        id
                      );
                    }}
                  >
                    <Icons
                      name="boldArrowLeft"
                      stroke="#525657"
                      width={26}
                      height={26}
                    ></Icons>
                  </span>
                </Show>
                <span class="pr">
                  <label
                    class="lbl fs-14 cur-p user-select-none builder-pagination-dropdown"
                    onclick={() => {
                      setIsBuilderPageDropdownOpen(
                        isBuilderPageDropdownOpen() ? false : true
                      );
                    }}
                  >
                    Page {currentPage()}
                  </label>
                  <Show when={isBuilderPageDropdownOpen()}>
                    <div class="dropdown builder-pagination">
                      <span class="dropdown-caret"></span>
                      <ul class="dropdown-list">
                        <For
                          each={Array.from(
                            { length: totalFormPages() },
                            (_, i) => i
                          ).map((page: any) => page + 1)}
                        >
                          {(item: any) => (
                            <li
                              class={`dropdown-items ${
                                item == currentPage() ? "active" : ""
                              }`}
                            >
                              <a
                                onclick={() => {
                                  setCurrentPage(item);
                                  setFormJson({
                                    currentField: {},
                                    currentPage: item - 1,
                                  });
                                  updateFormJsonPages(
                                    data()?.user_id,
                                    data()?.jwt_token,
                                    id
                                  );
                                  setIsBuilderPageDropdownOpen(false);
                                }}
                              >{`Page ${item}`}</a>
                            </li>
                          )}
                        </For>
                      </ul>
                    </div>
                  </Show>
                </span>
                <Show
                  when={
                    totalFormPages() > 1 && currentPage() != totalFormPages()
                  }
                >
                  <span
                    class="mt-4 cur-p"
                    onclick={() => {
                      setFormJson({
                        currentField: {},
                        currentPage: currentPage(),
                      });
                      setCurrentPage(currentPage() + 1);
                      updateFormJsonPages(
                        data()?.user_id,
                        data()?.jwt_token,
                        id
                      );
                    }}
                  >
                    <Icons
                      name="boldArrowRight"
                      stroke="#525657"
                      width={26}
                      height={26}
                    ></Icons>
                  </span>
                </Show>
              </div>
              <Show when={userRole() != "viewer"}>
                <button
                  class={`btn sm primary`}
                  // ${
                  //   Object.keys(recordsJson.currentRecord).length > 0 &&
                  //   isRecordUpdated() != "" &&
                  //   !isRecordSubmit()
                  //     ? ""
                  //     : "disabled pointer-none"
                  // }
                  onclick={() => {
                    // if (
                    //   Object.keys(recordsJson.currentRecord).length > 0 ||
                    //   recordsJson.multipleRecords.length > 0
                    // ) {
                    if (alertFormRecords()) {
                      // if (
                      //   Object.keys(recordsJson.currentRecord).length > 0 &&
                      //   isRecordUpdated() != "" &&
                      //   !isRecordSubmit()
                      // ) {
                      setIsRecordSubmit(true);
                      if (
                        isRecordUpdated() == "add" ||
                        isRecordUpdated() == ""
                      ) {
                        const fields: any = formJson.pages.flatMap(
                          (page: any) => page.fields
                        );
                        let index: any = fields.findIndex(
                          (field: any) =>
                            field.type == "TextField" ||
                            field.type == "DateField" ||
                            field.type == "DropdownField" ||
                            field.type == "LocationField" ||
                            field.type == "BarcodeField" ||
                            field.type == "RadioField"
                        );
                        if (index != -1) {
                          if (!(isRecordUpdated() != "" && isRecordSubmit())) {
                            updateFormRecords(
                              fields[index].properties.dataFieldSync.uid,
                              preLoadData()[
                                fields[index].properties.dataFieldSync.uid
                              ],
                              false,
                              false
                            );
                          } else {
                            addRecord();
                          }
                          if (linkedFormRecordsData().length > 0) {
                            linkedFormRecordsData().forEach((item: any) => {
                              addRecord(
                                item.uid,
                                "",
                                item.record,
                                item.form_id
                              );
                            });
                            setLinkedFormRecordsData([]);
                          }
                        }
                      } else {
                        updateRecord();
                        if (linkedFormRecordsData().length > 0) {
                          linkedFormRecordsData().forEach((item: any) => {
                            updateRecord(
                              "",
                              "",
                              item.record,
                              item.form_id,
                              item.record_id
                            );
                          });
                          setLinkedFormRecordsData([]);
                        }
                      }
                      // }
                    }
                    // }
                  }}
                >
                  Save
                </button>
              </Show>
              <Show when={userRole() == "viewer"}>
                <div class="w-50p user-select-none">&nbsp;</div>
              </Show>
            </div>
            <Show when={formJson.root?.formStructure?.indexTabs?.length > 0}>
              <ul
                class="index-tabs"
                style={{
                  "--left":
                    Math.ceil(
                      windowWidth -
                        rightBarWidth -
                        formMargin -
                        otherSpace() +
                        (768 * formScale() - 768) / 2 +
                        1
                    ) + "px",
                  "--height":
                    (1056 * formScale() < windowHeight - 80
                      ? 1056 * formScale() - 5
                      : windowHeight - 80) + "px",
                }}
              >
                <For each={formJson.root?.formStructure?.indexTabs}>
                  {(item: any, key: any) => (
                    <li
                      class={`tab-bg-${(key() + 1) % 6}`}
                      onclick={() => {
                        setCurrentPage(item.page);
                        setFormJson({
                          currentField: {},
                          currentPage: item.page - 1,
                        });
                        updateFormJsonPages(
                          data()?.user_id,
                          data()?.jwt_token,
                          id
                        );
                      }}
                    >
                      {item.tabTitle}
                    </li>
                  )}
                </For>
              </ul>
            </Show>
          </div>
          <div class={`builder-toolbar ${formToolbarOpen() ? "toggle" : ""}`}>
            <Show when={isClipBoard() == ""}>
              <FormPagination
                user_id={data()?.user_id}
                jwt_token={data()?.jwt_token}
                form_id={id}
              ></FormPagination>
            </Show>
            <Show when={isClipBoard() == "clipboard"}>
              <div class="builder-title flex app-flex space-between align-center">
                <Show when={clipBoardType() != "edit"}>
                  <button
                    class="btn primary sm"
                    onclick={async () => {
                      setClipBoardType("edit");
                    }}
                  >
                    Edit
                  </button>
                </Show>
                <Show when={clipBoardType() == "edit"}>
                  <span onclick={() => setClipBoardType("")}>
                    <Icons
                      name="chevronLeft"
                      strokeWidth="2.5"
                      width={22}
                      height={22}
                      stroke="#2E61A3"
                    ></Icons>
                  </span>
                </Show>
                <span class="subtitle">
                  <strong>Clipboard</strong>
                </span>
                <div class="app-flex">
                  <span
                    class="subtitle cur-p"
                    onclick={() => {
                      setIsClipBoard("editor");
                      setClipBoardType("add");
                      setClipData({
                        clipboard_data: [
                          ...clipData.clipboard_data,
                          {
                            id: crypto.randomUUID().toUpperCase(),
                            title: "",
                            text: "",
                            clipType: "Text",
                          },
                        ],
                      });
                      setCurrentClipId(
                        clipData.clipboard_data[
                          clipData.clipboard_data.length - 1
                        ].id
                      );
                    }}
                  >
                    <Icons
                      name="plus"
                      strokeWidth="2.5"
                      width={20}
                      height={20}
                      stroke="#2E61A3"
                    ></Icons>
                  </span>
                  <div
                    class="sidebar-menu"
                    onclick={() => {
                      setFormToolbarOpen(false);
                      setIsClipBoard("");
                    }}
                  >
                    &nbsp;
                    <Icons name="x" stroke="red" strokeWidth="2.5"></Icons>
                  </div>
                </div>
              </div>
              <div class="flex align-center space-between pr p-10">
                <input
                  class="input box"
                  type="text"
                  placeholder="Search"
                  onclick={() => {
                    setActiveField("clipboard_search");
                  }}
                  value={clipboardSearch()}
                  onInput={(e: any) => {
                    setClipboardSearch(e.target.value);
                  }}
                />
                <Show
                  when={
                    activeField() == "clipboard_search" &&
                    clipboardSearch().length > 0
                  }
                >
                  <span
                    class="pa z-index-1 cur-p r-15"
                    onclick={() => {
                      setClipboardSearch("");
                    }}
                  >
                    <Icons name="circleX" width={20} height={20}></Icons>
                  </span>
                </Show>
              </div>
              <div class="underline clipboard">
                <For each={clipData.clipboard_data}>
                  {(clip: any) => (
                    <div
                      class={`form-settings-field clipboards ${
                        clipBoardType() == "edit" ? "active cur-p" : ""
                      }`}
                      draggable={clipBoardType() == "edit" ? true : false}
                      onClick={() => {
                        if (clipBoardType() == "edit") {
                          setIsClipBoard("editor");
                          setCurrentClipId(clip.id);
                        } else {
                          navigator.clipboard.writeText(clip.text);
                          notify({
                            isNotified: true,
                            message: "Copied!!",
                            theme: "success",
                            placement: "top",
                            timeout: 1000,
                          });
                        }
                      }}
                      onDragStart={(e: any) => {
                        e.dataTransfer.effectAllowed = "move";
                        e.dataTransfer.setData("text/plain", clip.id);
                      }}
                      onDragOver={(e: any) => {
                        e.preventDefault();
                        e.dataTransfer.dropEffect = "move";
                      }}
                      onDrop={async (e: any) => {
                        e.preventDefault();
                        const id: any = e.dataTransfer.getData("text/plain");
                        if (id != clip.id) {
                          let tempArr: any = [...clipData.clipboard_data];
                          let index1 = tempArr?.findIndex(
                            (item: any) => item.id === id
                          );
                          let index2 = tempArr?.findIndex(
                            (item: any) => item.id === clip.id
                          );
                          if (index1 !== -1 && index2 !== -1) {
                            let temp = tempArr[index1];
                            tempArr[index1] = tempArr[index2];
                            tempArr[index2] = temp;
                          }
                          setClipData({
                            clipboard_data: tempArr,
                          });
                          saveClip(data()?.user_id, data()?.jwt_token);
                        }
                      }}
                    >
                      <div>
                        <span class="lbl">{clip.title}</span>
                        <br />
                        <small>{clip.text}</small>
                      </div>
                    </div>
                  )}
                </For>
              </div>
            </Show>
            <Show when={isClipBoard() == "editor"}>
              <div class="builder-title flex app-flex space-between align-center">
                <span
                  class="subtitle cur-p"
                  onclick={() => {
                    setIsClipBoard("clipboard");
                    setClipBoardType("");
                  }}
                >
                  <Icons
                    name="chevronLeft"
                    strokeWidth="2.5"
                    width={22}
                    height={22}
                    stroke="#2E61A3"
                  ></Icons>
                </span>
                <span class="subtitle">
                  <strong>Editor</strong>
                </span>
                <button
                  class="btn primary sm"
                  onclick={() => saveClip(data()?.user_id, data()?.jwt_token)}
                >
                  Save
                </button>
              </div>
              <div class="p-10">
                <div>
                  <label for="title">Title:</label>
                  <input
                    ref={titleRef}
                    type="text"
                    class="input"
                    id="title"
                    value={
                      clipData.clipboard_data.filter(
                        (clip: any) => clip.id == currentClipId()
                      )[0]?.title
                    }
                    oninput={(e: any) => {
                      const currentData = clipData.clipboard_data.map(
                        (clip: any) => ({ ...clip })
                      );
                      currentData.forEach((clip: any, key: any) => {
                        if (clip.id == currentClipId()) {
                          currentData[key].title = e.target.value;
                        }
                      });
                      setClipData({
                        clipboard_data: currentData,
                      });
                    }}
                  />
                </div>
                <br />
                <div>
                  <label for="text">Text:</label>
                  <textarea
                    class="input textarea"
                    id="text"
                    cols="30"
                    rows="10"
                    value={
                      clipData.clipboard_data.filter(
                        (clip: any) => clip.id == currentClipId()
                      )[0]?.text
                    }
                    oninput={(e: any) => {
                      const currentData = clipData.clipboard_data.map(
                        (clip: any) => ({ ...clip })
                      );
                      currentData.forEach((clip: any, key: any) => {
                        if (clip.id == currentClipId()) {
                          currentData[key].text = e.target.value;
                        }
                      });
                      setClipData({
                        clipboard_data: currentData,
                      });
                    }}
                  />
                </div>
                <Show when={clipBoardType() == "edit"}>
                  <br />
                  <div class="text-right">
                    <button
                      class="btn danger sm"
                      onclick={() => {
                        setClipData({
                          clipboard_data: clipData.clipboard_data.filter(
                            (clip: any) => clip.id != currentClipId()
                          ),
                        });
                        setCurrentClipId(
                          clipData.clipboard_data[
                            clipData.clipboard_data.length - 1
                          ].id
                        );
                        saveClip(data()?.user_id, data()?.jwt_token);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Show>
              </div>
            </Show>
          </div>
        </div>
      </div>
    </>
  );
};

export default render;
