import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import generateCSS from "~/helpers/generator";
import { createStore, produce } from "solid-js/store";
import { assignFieldProperties } from "~/helpers/form.helper";
import convert from "~/helpers/convertor";
import Icons from "../icons/Icons";
import {
  addContectMenu,
  formJson,
  setFormJson,
  setIsFormJsonUpdated,
  updateFormJson,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import { mapping } from "~/store/field-editor.store";
import {
  isColumnMovement,
  isMouseDown,
  multipleSelectField,
  setIsColumnMovement,
  setIsMouseDown,
} from "~/store/global.store";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";
interface Props {
  properties: any;
  fieldsType: string;
}
const TableField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});
  const [rowItems, setRowItems] = createSignal([] as Array<number>);
  const [columnItems, setColumnItems] = createSignal([] as Array<number>);
  const [colKey, setColKey] = createSignal(null as unknown as number);
  const [fieldLeftWidth, setFieldLeftWidth] = createSignal(0 as number);
  const [fieldRightWidth, setFieldRightWidth] = createSignal(0 as number);

  let movementX: any = 0;

  onMount(() => {
    const formFrame = document.getElementById("form_frame");
    // Remove event on mouseup
    formFrame?.addEventListener("mouseup", () => {
      setIsMouseDown(false);
      movementX = 0;
    });
    // Create mousemove event
    formFrame?.addEventListener("mousemove", (event) => {
      return handleMouseMove(event, colKey());
    });
  });

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
    if (props.properties.row_sizes) {
      const rowItems: Array<number> = [];
      props.properties.row_sizes.split(",").forEach((row: any, key: any) => {
        let val: any = 0;
        if (key > 0) {
          for (let index = key; index >= 0; index--) {
            val += Number(
              convert(props.properties.row_sizes.split(",")[index], "in-px")
            );
          }
        } else {
          val += Number(convert(row, "in-px"));
        }
        val -= Number(convert(row, "in-px")) / 1.8;
        rowItems.push(val);
      });
      setRowItems(rowItems);
    }
    if (props.properties.column_sizes) {
      const columnItems: Array<number> = [0];
      props.properties.column_sizes
        .split(",")
        .forEach((column: any, key: any) => {
          let val: any = 0;
          if (key > 0) {
            for (let index = key; index >= 0; index--) {
              val += Number(props.properties.column_sizes.split(",")[index]);
            }
          } else {
            val += +column;
          }
          columnItems.push(val);
        });
      setColumnItems(columnItems);
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  function snapToGrid(value: number, gridSize: number = 0.125): number {
    return Math.round(value / gridSize) * gridSize;
  }

  function handleMouseMove(event: any, key: number) {
    if (!isMouseDown() || !isColumnMovement()) return;
    if (
      formJson.currentField.dataFieldSync?.uid ==
      props.properties.dataFieldSync.uid
    ) {
      if (event) {
        movementX += event.movementX;
        if (movementX < 12 && movementX > -12) {
          return;
        }
      }
      setIsFormJsonUpdated(true);

      // Convert movement to inches and snap to grid
      const movementInInches = Number(convert(movementX, "px-in"));
      const snappedMovement = snapToGrid(movementInInches);

      if (
        (formJson.currentField?.dataFieldSync?.frame[0][0] < 0.32 &&
          snappedMovement < 0 &&
          fieldLeftWidth() == 0) ||
        (Number(formJson.currentField?.dataFieldSync?.frame[0][0]) +
          Number(formJson.currentField?.dataFieldSync?.frame[1][0]) >
          8.24 &&
          snappedMovement > 0 &&
          fieldRightWidth() == 0)
      ) {
        return;
      }

      // Apply snapping to field widths
      if (
        fieldLeftWidth() == 0 ||
        (fieldLeftWidth() != 0 && fieldRightWidth() != 0)
      ) {
        const newRightWidth = snapToGrid(fieldRightWidth() - snappedMovement);
        if (newRightWidth >= 0.375 || snappedMovement < 0) {
          setFieldRightWidth(newRightWidth);
        } else {
          setIsMouseDown(false);
          return;
        }
      }

      if (
        fieldRightWidth() == 0 ||
        (fieldLeftWidth() != 0 && fieldRightWidth() != 0)
      ) {
        const newLeftWidth = snapToGrid(fieldLeftWidth() + snappedMovement);
        if (newLeftWidth >= 0.375 || snappedMovement > 0) {
          setFieldLeftWidth(newLeftWidth);
        } else {
          setIsMouseDown(false);
          return;
        }
      }

      const columns: any = [...columnItems()];
      columns[key] = snapToGrid(columnItems()[key] + snappedMovement);
      const items: any = formJson.currentField.column_sizes.split(",");

      if (items.length == columns.length) {
        setColumnItems(columns);
      }

      if (key == 0) {
        items[key] = snapToGrid(Number(items[key]) - columns[key]);
      } else if (key == columnItems().length - 1) {
        items[key - 1] = snapToGrid(Number(items[key - 1]) + snappedMovement);
      } else {
        items[key - 1] = snapToGrid(Number(items[key - 1]) + snappedMovement);
        items[key] = snapToGrid(Number(items[key]) - snappedMovement);
      }

      const columSizes: any = {};
      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields
              ?.filter(
                (item: any) =>
                  item.properties.dataFieldSync.uid ==
                    formJson.currentField.dataFieldSync?.uid ||
                  item.properties.dataFieldSync.parentTableId ==
                    formJson.currentField.dataFieldSync?.uid
              )
              ?.forEach((item: any, item_key: any) => {
                if (
                  item.properties.dataFieldSync.uid ==
                    formJson.currentField.dataFieldSync?.uid &&
                  key == 0
                ) {
                  if (movementX >= 12 || movementX <= -12) {
                    const frame: any = item.properties.dataFieldSync.frame;
                    frame[1][0] = snapToGrid(
                      Number(frame[1][0]) - snappedMovement
                    );
                    frame[0][0] = snapToGrid(
                      Number(frame[0][0]) + snappedMovement
                    );
                  }
                } else if (
                  item.properties.dataFieldSync.uid ==
                    formJson.currentField.dataFieldSync?.uid &&
                  key == columnItems().length - 1
                ) {
                  if (movementX >= 12 || movementX <= -12) {
                    const frame: any = item.properties.dataFieldSync.frame;
                    frame[1][0] = snapToGrid(
                      Number(frame[1][0]) + snappedMovement
                    );
                  }
                }
                if (
                  item.properties.dataFieldSync.parentTableId ==
                  formJson.currentField.dataFieldSync?.uid
                ) {
                  if (
                    key != 0 &&
                    item.properties.dataFieldSync.tableCellAddress.includes(
                      mapping[key - 1]
                    )
                  ) {
                    if (movementX >= 12 || movementX <= -12) {
                      const frame: any = item.properties.dataFieldSync.frame;
                      const newWidth = snapToGrid(
                        Number(frame[1][0]) + snappedMovement
                      );
                      if (newWidth >= 0.375 || snappedMovement > 0) {
                        frame[1][0] = newWidth;
                        item.properties.dataFieldSync.frame = frame;
                      }
                    }
                  }
                  if (
                    item.properties.dataFieldSync.tableCellAddress.includes(
                      mapping[key]
                    )
                  ) {
                    if (movementX >= 12 || movementX <= -12) {
                      const frame: any = item.properties.dataFieldSync.frame;
                      const newWidth = snapToGrid(
                        Number(frame[1][0]) - snappedMovement
                      );
                      if (newWidth >= 0.375 || snappedMovement > 0) {
                        frame[1][0] = newWidth;
                        frame[0][0] = snapToGrid(
                          Number(frame[0][0]) + snappedMovement
                        );
                      }
                    }
                  }
                  if (
                    item.properties.dataFieldSync.tableCellAddress.split(
                      ""
                    )[1] == "0"
                  ) {
                    columSizes[item.properties.dataFieldSync.tableCellAddress] =
                      item.properties.dataFieldSync.frame[0][0];
                  }
                  if (
                    item_key ==
                    page.fields?.filter(
                      (item: any) =>
                        item.properties.dataFieldSync.uid ==
                          formJson.currentField.dataFieldSync?.uid ||
                        item.properties.dataFieldSync.parentTableId ==
                          formJson.currentField.dataFieldSync?.uid
                    ).length -
                      1
                  ) {
                    movementX = 0;
                  }
                }
              });
          });
        })
      );

      setFormJson(
        "pages",
        produce((pages: any) => {
          pages.forEach((page: any) => {
            page.fields?.forEach((item: any, item_key: any) => {
              if (
                item.properties.dataFieldSync.uid ==
                formJson.currentField.dataFieldSync?.uid
              ) {
                const inputArray: any = Object.values(columSizes);
                let result: any = inputArray
                  .slice(1)
                  .map((v: any, i: any) => snapToGrid(v - inputArray[i]));
                const lastColumnField: any = formJson.pages[
                  formJson.currentPage
                ].fields.find(
                  (field: any) =>
                    field.properties.dataFieldSync.parentTableId ==
                      formJson.currentField.dataFieldSync?.uid &&
                    field.properties.dataFieldSync.tableCellAddress ==
                      mapping[result.length] + "0"
                );
                if (lastColumnField) {
                  result.push(
                    snapToGrid(
                      lastColumnField.properties.dataFieldSync.frame[1][0]
                    )
                  );
                }
                result = result.map((value: any) => Number(value.toFixed(2)));
                item.properties.column_sizes = result.join(",");
              }
            });
          });
        })
      );
    }
  }

  return (
    <>
      <div
        class={`field z-index-0 ${
          props.fieldsType == "designer" ? "table-editor-border" : ""
        } ${
          props.fieldsType == "designer" &&
          ((formJson.currentField.dataFieldSync?.type == "TableField" &&
            formJson.currentField.dataFieldSync?.uid ==
              props.properties.dataFieldSync.uid) ||
            formJson.multipleFields.filter(
              (obj: any) =>
                obj.dataFieldSync?.type == "TableField" &&
                obj.dataFieldSync.uid == props.properties.dataFieldSync.uid
            ).length > 0)
            ? "table-guidline"
            : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        }`}
        style={generateCSS(fieldProperties)}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) =>
          multipleSelectField() || event.shiftKey
            ? updatedMultipleFields(props.properties.dataFieldSync.uid)
            : updatedCurrentField(props.properties.dataFieldSync.uid)
        }
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      >
        <Show when={props.fieldsType == "designer"}>
          <For each={rowItems()}>
            {(row: any, key: any) => (
              <div
                class="pa table-select-div"
                style={{
                  right: "8px",
                  top: row - 5 + "px",
                }}
              >
                <select
                  class="table-select"
                  onchange={(e: any) => {
                    switch (e.target.value) {
                      case "increase_row_height":
                        updateFormJson(
                          "",
                          "row_sizes",
                          props.properties.row_sizes.split(",")[key()] < 0.375
                            ? 0.125
                            : 0.375,
                          false,
                          key(),
                          ""
                        );
                        break;
                      case "reduce_row_height":
                        if (
                          props.properties.row_sizes.split(",")[key()] > 0.1875
                        ) {
                          updateFormJson(
                            "",
                            "row_sizes",
                            props.properties.row_sizes.split(",")[key()] <=
                              0.375
                              ? -0.125
                              : -0.375,
                            false,
                            key(),
                            ""
                          );
                        }
                        break;
                      case "add_row_above":
                        updateFormJson(
                          "",
                          "rows",
                          1,
                          false,
                          key(),
                          "",
                          "above"
                        );
                        break;
                      case "add_row_below":
                        updateFormJson(
                          "",
                          "rows",
                          1,
                          false,
                          key() + 1,
                          "",
                          "below"
                        );
                        break;
                      case "delete_row":
                        updateFormJson("", "rows", -1, false, key(), "");
                        break;
                      default:
                        break;
                    }
                    e.target.value = "";
                    e.target.blur();
                    updatedCurrentField(props.properties.dataFieldSync.uid);
                    setIsMouseDown(false);
                  }}
                  onmousedown={() => {
                    updatedCurrentField(props.properties.dataFieldSync.uid);
                    setIsMouseDown(false);
                  }}
                >
                  <option value="" selected disabled hidden></option>
                  <Show when={key() != 0}>
                    <option value="add_row_above">Add Row Above</option>
                    <option value="add_row_below">Add Row Below</option>
                  </Show>
                  <option value="increase_row_height">
                    Increase Row Height
                  </option>
                  <option value="reduce_row_height">Reduce Row Height</option>
                  <Show
                    when={
                      key() != 0 &&
                      formJson.currentField &&
                      Object.keys(formJson.currentField)?.length > 0 &&
                      formJson.currentField?.row_sizes?.split(",")?.length > 2
                    }
                  >
                    <option value="delete_row">Delete Row</option>
                  </Show>
                </select>
              </div>
            )}
          </For>
          <For each={columnItems()}>
            {(column: any, key: any) => (
              <div
                id={`icon_${key()}`}
                class="pa app-flex flex cur-p"
                style={{
                  bottom: 0,
                  left:
                    Number(convert(column, "in-px")) +
                    (key() == 0
                      ? 10
                      : key() == columnItems().length - 1
                      ? -10
                      : 0) +
                    "px",
                  transform: "translateX(-10px)",
                }}
                onMouseDown={() => {
                  if (key() == 0) {
                    setFieldLeftWidth(0);
                    setFieldRightWidth(columnItems()[1]);
                  } else if (key() == columnItems().length - 1) {
                    setFieldLeftWidth(column - columnItems()[key() - 1]);
                    setFieldRightWidth(0);
                  } else {
                    setFieldLeftWidth(
                      columnItems()[key()] - columnItems()[key() - 1]
                    );
                    setFieldRightWidth(
                      columnItems()[key() + 1] - columnItems()[key()]
                    );
                  }
                  setIsColumnMovement(true);
                  setIsMouseDown(true);
                  setColKey(key());
                }}
                onMouseUp={() => {
                  setIsColumnMovement(false);
                }}
              >
                <Icons name="twoWayArrow" stroke="#6c6e6f"></Icons>
              </div>
            )}
          </For>
        </Show>
      </div>
    </>
  );
};

export default TableField;
