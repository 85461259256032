import { Component, onMount, createSignal } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import "@thisbeyond/solid-select/style.css";
import GroupForm from "./_form";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);
    const usersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/group/users/list`,
      jwt_token: jwt_token as string,
      body: { user_id, search_string: "", sort_string: "" },
    };
    const list = await fetchApi(usersListApiParams);
    if (list?.statusCode == 401) {
      throw redirect("/logout");
    }
    const usersList = list.data.unSelectedGroupsUsers;
    usersList.push({ ...list.data.adminUser, role: "admin" });
    return { user_id, jwt_token, usersList };
  });
}

const groupAdd: Component = () => {
  setPageTitle(`${SITE_TITLE} - Create New Team`);
  const data: any = useRouteData<typeof routeData>();

  return (
    <main>
      <SystemLayout>
        <div class="center">
          <div class="flex space-between">
            <h3 class="title color">Create Team</h3>
          </div>
          <div class="flex gap"></div>
          <br />
          <GroupForm
            list={data()?.usersList}
            type="add"
            api_url={`${import.meta.env.VITE_API_URL}/group/add`}
            userId={data()?.user_id}
            jwt_token={data()?.jwt_token}
          />
        </div>
      </SystemLayout>
    </main>
  );
};

export default groupAdd;
