import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Icons from "../icons/Icons";
import {
  formJson,
  radioFieldButtons,
  setFormJson,
  setIsFormJsonUpdated,
  updateFormJson,
} from "~/store/form.store";
import { produce } from "solid-js/store";
import { title } from "process";

const AddSelectionItems: Component = () => {
  const [selectionItems, setSelectionItems] = createSignal([] as any);
  const [selectionSubItems, setSelectionSubItems] = createSignal([] as any);
  const [checkedItem, setCheckedItem] = createSignal("" as string);
  const [isSwapStart, setIsSwapStart] = createSignal("" as string);
  const [isSwapOver, setIsSwapOver] = createSignal("" as string);

  createEffect(() => {
    if (formJson.currentField.choiceFieldSync?.items?.length > 0) {
      if (
        formJson.currentField.choiceFieldSync?.items[
          formJson.currentField.choiceFieldSync?.items.length - 1
        ].title != ""
      ) {
        setSelectionItems([
          ...formJson.currentField.choiceFieldSync?.items,
          {
            id:
              Math.max(
                ...formJson.currentField.choiceFieldSync?.items.map(
                  (item: any) => item.id
                )
              ) + 1,
            title: "",
          },
        ]);
      } else {
        setSelectionItems([...formJson.currentField.choiceFieldSync?.items]);
      }
    } else {
      setSelectionItems([{ id: 0, title: "" }]);
    }
    if (formJson.currentField.choiceFieldSync?.nestedListString) {
      setSelectionSubItems(
        JSON.parse(formJson.currentField.choiceFieldSync?.nestedListString)
      );
    } else {
      setSelectionSubItems([]);
    }
    if (formJson.currentField.choiceFieldSync?.preSelected) {
      setCheckedItem(formJson.currentField.choiceFieldSync?.preSelected);
    }
  });

  function addSelectionData(item: string) {
    const updatedItems: any = [...selectionItems()];
    const _id = Math.max(...updatedItems.map((item: any) => item.id)) + 1;
    const data: any = {
      id: _id,
      title: item,
    };
    updatedItems.push(data);
    setSelectionItems(updatedItems);
  }

  function removeSelectionData(id: string) {
    const updatedItems: any = [...selectionItems()];
    let findIndex = updatedItems.findIndex((item: any) => item.id == id);
    updatedItems.splice(findIndex, 1);
    setSelectionItems(updatedItems);
    updatedSelectionItemsArr("remove");
  }

  function updatedSelectionItemsArr(type: string) {
    const updatedSelectionItems: any = [...selectionItems()].filter(
      (item: any) => item.title.trim() != ""
    );
    const tempArr: any = updatedSelectionItems
      .map((item: any) => item.title)
      .filter((item: any) => item.trim() != "");
    setIsFormJsonUpdated(true);

    setFormJson(
      "pages",
      produce((pages: any) => {
        pages.forEach((page: any) => {
          page.fields?.forEach((field: any) => {
            if (
              formJson.currentField.dataFieldSync?.uid ==
              field.properties.dataFieldSync.uid
            ) {
              field.properties.choiceFieldSync.items = updatedSelectionItems;
              field.properties.choiceFieldSync.totalItems =
                updatedSelectionItems.length;
            }
            if (
              formJson.currentField.dataFieldSync?.uid ==
                field.properties.dataFieldSync.linkedFieldId &&
              field.properties.choiceFieldSync?.linkType == "linked_nested"
            ) {
              const nestedList: any = JSON.parse(
                field.properties.choiceFieldSync?.nestedListString
              );
              if (tempArr.length != nestedList.length) {
                if (type == "add") {
                  const tempObject: any = {
                    title: tempArr.filter(
                      (title2: any) =>
                        !nestedList.some((obj: any) => obj.title === title2)
                    )[0],
                    subTitles: [],
                  };
                  if (tempObject.title != "") {
                    nestedList.push(tempObject);
                    field.properties.choiceFieldSync.nestedListString =
                      JSON.stringify(nestedList);
                  }
                } else {
                  field.properties.choiceFieldSync.nestedListString =
                    JSON.stringify(
                      nestedList
                        .filter(
                          (item: any) =>
                            item.title !==
                            nestedList
                              .filter(
                                (val: any) => !tempArr.includes(val.title)
                              )
                              .map((val1: any) => val1.title)[0]
                        )
                        .map((obj1: any) => ({
                          title: obj1.title,
                          subTitles: obj1.subTitles,
                        }))
                    );
                }
              }
            }
            if (
              formJson.currentField.dataFieldSync.type == "RadioButtonField" &&
              formJson.currentField.dataFieldSync.uid ==
                field.properties.dataFieldSync.uid
            ) {
              const frame: any = field.properties.dataFieldSync.frame;
              frame[1][1] =
                Math.ceil(
                  radioFieldButtons(
                    field.properties.choiceFieldSync.items.map((item: any) => ({
                      title: item.title,
                    })),
                    field.properties.dataFieldSync.fontSize,
                    field.properties.dataFieldSync.frame[1][0]
                  ).bounds?.height / 0.125
                ) * 0.125;
              frame[1][1] = frame[1][1] ? frame[1][1] : 0.375;
              field.properties.dataFieldSync.frame = frame;
            }
          });
        });
      })
    );
  }

  function updateSubSelectionData(value: string, id: string, key?: any) {
    const updatedSubSelectionItems: any = [...selectionSubItems()];
    if (id != "") {
      updatedSubSelectionItems.forEach((item: any) => {
        item.subTitles.forEach((subItem: any) => {
          if (subItem.uuid == id) {
            subItem.subTitle = value;
          }
        });
      });
    } else {
      const tempObject: any = {
        subTitle: value,
        uuid: crypto.randomUUID().toUpperCase(),
      };
      updatedSubSelectionItems[key].subTitles.push(tempObject);
    }
    updateFormJson(
      "choiceFieldSync",
      "nestedListString",
      JSON.stringify(updatedSubSelectionItems)
    );
  }

  function removeSubSelectionData(id: string) {
    const updatedSelectionItems: any = [...selectionItems()].filter(
      (item: any) => item.title != ""
    );

    const updatedSubSelectionItems: any = [...selectionSubItems()];
    let removedSubItem = {};
    updatedSubSelectionItems.forEach((item: any) => {
      item.subTitles.forEach((subItem: any) => {
        if (subItem.uuid == id) {
          removedSubItem = { id: crypto.randomUUID(), title: subItem.subTitle };
        }
      });
    });
    updatedSelectionItems.push(removedSubItem);
    updateFormJson("choiceFieldSync", "items", updatedSelectionItems);
    updateFormJson(
      "choiceFieldSync",
      "totalItems",
      updatedSelectionItems.length
    );
    updateFormJson(
      "choiceFieldSync",
      "nestedListString",
      JSON.stringify(
        updatedSubSelectionItems.map((item: any) => {
          return {
            ...item,
            subTitles: item.subTitles.filter(
              (subItem: any) => subItem.uuid !== id
            ),
          };
        })
      )
    );
  }

  function handleDragStart(e: Event, item: any) {
    e.stopPropagation();
    e.dataTransfer?.setData("text/plain", JSON.stringify(item));
  }

  function handleDragOver(e: Event) {
    e.preventDefault();
  }

  function handleDrop(e: Event, itemKey: any) {
    e.preventDefault();
    const data = e.dataTransfer?.getData("text/plain");
    if (data && selectionItems()[data]) {
      updateSubSelectionData(selectionItems()[data].title, "", itemKey);
      removeSelectionData(selectionItems()[data].id);
    }
    setIsSwapOver("");
    setIsSwapStart("");
  }

  function handleSwapStart(e: any, id: string) {
    setIsSwapStart(id);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer?.setData("text/plain", id);
  }

  function handleSwapEnter(e: any, id: string) {
    setIsSwapOver(id);
  }

  function handleSwapOver(e: any) {
    e.dataTransfer.dropEffect = "move";
    e.preventDefault();
  }

  function handleSwap(e: any, id: any, type: string) {
    e.stopPropagation();
    e.preventDefault();
    setIsSwapOver("");
    setIsSwapStart("");
    if (e.target.value) {
      return;
    }
    if (type == "item") {
      const swappedItemsArr: any = [...selectionItems()];
      const index1 = swappedItemsArr.findIndex((item: any) => item.id == id);
      const index2 = swappedItemsArr.findIndex(
        (item: any) => item.id == e.dataTransfer?.getData("text/plain")
      );
      if (index2 != -1) {
        const temp = swappedItemsArr[index1];
        swappedItemsArr[index1] = swappedItemsArr[index2];
        swappedItemsArr[index2] = temp;
        setSelectionItems(swappedItemsArr);
        updateFormJson("choiceFieldSync", "items", swappedItemsArr);
      }
    } else {
      const swappedSubItemsArr: any = [...selectionSubItems()];
      const subtitles: any = [];
      swappedSubItemsArr.forEach((item: any) => {
        item.subTitles.forEach((subItem: any) => {
          subtitles.push(subItem);
        });
      });
      const index1 = subtitles.findIndex((item: any) => item.uuid === id);
      const index2 = subtitles.findIndex(
        (item: any) => item.uuid === e.dataTransfer?.getData("text/plain")
      );
      if (index2 != -1) {
        const temp = subtitles[index1];
        subtitles[index1] = subtitles[index2];
        subtitles[index2] = temp;
        let tempCount: number = 0;
        swappedSubItemsArr.forEach((item: any) => {
          item.subTitles = subtitles.slice(
            tempCount,
            tempCount + item.subTitles.length
          );
          tempCount += item.subTitles.length;
        });
        updateFormJson(
          "choiceFieldSync",
          "nestedListString",
          JSON.stringify(swappedSubItemsArr)
        );
      }
    }
  }

  return (
    <>
      <div class="selection-items" ondrop={() => setIsSwapStart("")}>
        <div class="items-div">
          <div class="form-group items">
            <input
              type="radio"
              name="selection"
              id="non_selection"
              checked={checkedItem() == "-1" ? true : false}
              onchange={() =>
                updateFormJson("choiceFieldSync", "preSelected", -1)
              }
              disabled={
                formJson.currentField.choiceFieldSync?.linkType ==
                "linked_child"
                  ? true
                  : false
              }
            />
            <label for="non_selection">Allow non-selection</label>
          </div>
          <For each={selectionItems()}>
            {(item: any, key: any) => (
              <div
                class={`form-group items ${
                  isSwapOver() != "" && isSwapOver() == item.id ? "over" : ""
                } ${
                  isSwapStart() != "" && isSwapStart() == item.id
                    ? "dragging"
                    : ""
                }`}
                draggable={key() != selectionItems()?.length - 1 ? true : false}
                ondragstart={(e) =>
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_child" &&
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_nested"
                    ? handleSwapStart(e, item.id)
                    : ""
                }
                ondragenter={(e) =>
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_child" &&
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_nested"
                    ? handleSwapEnter(e, item.id)
                    : ""
                }
                ondragover={(e) =>
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_child" &&
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_nested"
                    ? handleSwapOver(e)
                    : ""
                }
                ondrop={(e) =>
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_child" &&
                  formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_nested"
                    ? key() != selectionItems()?.length - 1
                      ? handleSwap(e, item.id, "item")
                      : e.preventDefault()
                    : e.preventDefault()
                }
              >
                <input
                  type="radio"
                  name="selection"
                  checked={checkedItem() == item.id ? true : false}
                  onchange={() => {
                    setCheckedItem(item.id);
                    updateFormJson(
                      "choiceFieldSync",
                      "preSelected",
                      Number(item.id)
                    );
                  }}
                  disabled={
                    formJson.currentField.choiceFieldSync?.linkType ==
                    "linked_child"
                      ? true
                      : false
                  }
                />
                <input
                  id={item.id}
                  class="input draggable-item w-60"
                  type="text"
                  value={item.title}
                  placeholder="Item Name"
                  onKeyUp={(e: any) => {
                    if (e.target.value !== "" && e.key === "Enter") {
                      addSelectionData("");
                      e.target.blur();
                      const elem: any = document.getElementById(
                        selectionItems()[selectionItems()?.length - 1]?.id
                      );
                      elem.focus();
                    }
                  }}
                  onblur={(e: any) => {
                    const tempArr: any[] = [...selectionItems()];
                    const indexToUpdate = tempArr.findIndex(
                      (value) => value.id === item.id
                    );
                    if (indexToUpdate !== -1) {
                      const updatedItem = {
                        ...tempArr[indexToUpdate],
                        title: e.target.value,
                      };
                      tempArr[indexToUpdate] = updatedItem;
                      setSelectionItems(tempArr);
                    }
                    updatedSelectionItemsArr("add");
                    if (
                      (formJson.currentField.choiceFieldSync?.linkType ==
                        "linked_parent" ||
                        formJson.currentField.choiceFieldSync?.linkType ==
                          "unlinked") &&
                      selectionItems()[selectionItems().length - 1]?.title !==
                        ""
                    ) {
                      addSelectionData("");
                    }
                  }}
                  draggable={
                    key() != selectionItems()?.length - 1 ? true : false
                  }
                  onDragStart={(event) => handleDragStart(event, key())}
                  disabled={
                    formJson.currentField.choiceFieldSync?.linkType ==
                    "linked_child"
                      ? true
                      : false
                  }
                />
                <Show
                  when={
                    formJson.currentField.choiceFieldSync?.linkType !=
                    "linked_child"
                  }
                >
                  <div
                    class="cur-p"
                    onclick={() => removeSelectionData(item.id)}
                  >
                    <Icons name="x" width={18} height={18} stroke="red"></Icons>
                  </div>
                </Show>
                <Show
                  when={
                    formJson.currentField.choiceFieldSync?.linkType ==
                    "linked_child"
                  }
                >
                  <div class="cur-p">
                    <Icons
                      name="x"
                      width={18}
                      height={18}
                      stroke="#8a8d8e"
                    ></Icons>
                  </div>
                </Show>
                <Show
                  when={
                    formJson.currentField.choiceFieldSync?.linkType !=
                      "linked_nested" && key() != selectionItems()?.length - 1
                  }
                >
                  <div class="cur-p">
                    <Icons
                      name="swap"
                      width={18}
                      height={18}
                      stroke={
                        formJson.currentField.choiceFieldSync?.linkType ==
                        "linked_child"
                          ? "#8a8d8e"
                          : "black"
                      }
                    ></Icons>
                  </div>
                </Show>
              </div>
            )}
          </For>
          {/* <Show
            when={
              formJson.currentField.choiceFieldSync?.linkType ==
                "linked_parent" ||
              formJson.currentField.choiceFieldSync?.linkType == "unlinked"
            }
          >
            <div class="w-100 mt-5">
              <button
                class={`btn sm bg w-100 ${
                  selectionItems()[selectionItems().length - 1]?.title == ""
                    ? "disabled"
                    : ""
                }`}
                onclick={() => addSelectionData("")}
              >
                Add Items
              </button>
            </div>
          </Show> */}
        </div>
        <br />
        <Show when={selectionSubItems().length > 0}>
          <div class="sub-items-div">
            <For each={selectionSubItems()}>
              {(item: any, key: any) => (
                <div class="form-group sub-items-box">
                  <label>{item.title}</label>
                  <Show when={item.subTitles.length > 0}>
                    <For each={item.subTitles}>
                      {(subItem: any, subItemKey: any) => (
                        <>
                          <div
                            class={`sub-items ${
                              isSwapOver() == subItem.uuid ? "over" : ""
                            } ${
                              isSwapStart() == subItem.uuid ? "dragging" : ""
                            }`}
                            draggable={true}
                            ondragstart={(e) =>
                              handleSwapStart(e, subItem.uuid)
                            }
                            ondragenter={(e) =>
                              handleSwapEnter(e, subItem.uuid)
                            }
                            ondragover={(e) => handleSwapOver(e)}
                            ondrop={(e) =>
                              handleSwap(e, subItem.uuid, "sub_item")
                            }
                          >
                            <input
                              class="input"
                              type="text"
                              value={subItem.subTitle}
                              onchange={(e: any) =>
                                updateSubSelectionData(
                                  e.target.value,
                                  subItem.uuid
                                )
                              }
                              disabled={
                                formJson.currentField.choiceFieldSync
                                  ?.linkType == "linked_child"
                                  ? true
                                  : false
                              }
                            />
                            <div
                              class="cur-p"
                              onclick={() => {
                                removeSubSelectionData(subItem.uuid);
                              }}
                            >
                              <Icons
                                name="x"
                                width={18}
                                height={18}
                                stroke="red"
                              ></Icons>
                            </div>
                            <div class="cur-p">
                              <Icons name="swap" width={18} height={18}></Icons>
                            </div>
                          </div>
                          <Show
                            when={subItemKey() == item.subTitles.length - 1}
                          >
                            <input
                              type="text"
                              class="input"
                              placeholder="Add Item"
                              onchange={(e: any) =>
                                updateSubSelectionData(
                                  e.target.value,
                                  "",
                                  key()
                                )
                              }
                              onDragOver={handleDragOver}
                              onDrop={(e: any) => handleDrop(e, key())}
                              disabled={
                                formJson.currentField.choiceFieldSync
                                  ?.linkType == "linked_child"
                                  ? true
                                  : false
                              }
                            ></input>
                          </Show>
                        </>
                      )}
                    </For>
                  </Show>
                  <Show when={item.subTitles.length == 0}>
                    <input
                      type="text"
                      class="input"
                      placeholder="Add Item"
                      onchange={(e: any) =>
                        updateSubSelectionData(e.target.value, "", key())
                      }
                      onDragOver={handleDragOver}
                      onDrop={(e: any) => handleDrop(e, key())}
                      disabled={
                        formJson.currentField.choiceFieldSync?.linkType ==
                        "linked_child"
                          ? true
                          : false
                      }
                    />
                  </Show>
                </div>
              )}
            </For>
          </div>
        </Show>
      </div>
    </>
  );
};

export default AddSelectionItems;
