import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Icons from "../icons/Icons";
import { setIsFormSettingsUpdated } from "~/store/global.store";
import { formJson } from "~/store/form.store";
import Checkbox from "../global/Checkbox";

const [toolbarButtons, setToolbarButtons] = createSignal([] as any);
const [toolbarButtonsUpdated, setToolbarButtonsUpdated] = createSignal(
  false as boolean
);

const ToolbarButtonsSettings: Component = () => {
  const iconLabels: any = {
    tbicon_export: { label: "Export Form", icon: "share" },
    tbicon_clipboard: { label: "Clipboard", icon: "clipboard" },
    tbicon_doc_scanner: { label: "Document Scanner", icon: "scanner" },
    tbicon_reports: { label: "Report Writer", icon: "clipboard" },
    tbicon_form_tab: { label: "Form Tab", icon: "formTab" },
    tbicon_lock_record: { label: "Lock Record", icon: "lock" },
    tbicon_tag_record: { label: "Tagging", icon: "tag" },
    tbicon_delete_record: { label: "Delete Record", icon: "trash" },
    tbicon_clone_record: { label: "Clone Record", icon: "files" },
    tbicon_add_record: { label: "Add Record", icon: "plus" },
    tbicon_formlink: { label: "Form Linker", icon: "link" },
    tbicon_collaboration: { label: "Collaboration", icon: "userAdd" },
    tbicon_add_page: { label: "Add Page", icon: "file" },
    tbicon_add_field: { label: "Add Field", icon: "plus" },
    tbicon_records: { label: "Record List", icon: "Records" },
    tbicon_save_kiosk: { label: "Submit (Kiosk Mode)", icon: "Submit" },
    tbicon_clear_kiosk: { label: "Clear (Kiosk Mode)", icon: "Clear" },
  };

  createEffect(() => {
    if (toolbarButtons().length == 0 && !toolbarButtonsUpdated()) {
      if (
        formJson.root.formStructure?.enabledToolbarIcons &&
        formJson.root.formStructure?.enabledToolbarIcons.length > 0
      ) {
        const tempArr: any = [];
        Object.keys(iconLabels).forEach((value: any) => {
          if (
            formJson.root.formStructure.enabledToolbarIcons
              ?.map((item: any) => item.type)
              ?.includes(value)
          ) {
            tempArr.push({ type: value, isEnabled: true });
          } else {
            tempArr.push({ type: value, isEnabled: false });
          }
        });
        setToolbarButtons(tempArr);
      } else {
        const tempArr: any = [
          {
            type: "tbicon_export",
            isEnabled: true,
          },
          {
            type: "tbicon_clipboard",
            isEnabled: true,
          },
          {
            type: "tbicon_doc_scanner",
            isEnabled: false,
          },
          {
            type: "tbicon_reports",
            isEnabled: true,
          },
          {
            type: "tbicon_form_tab",
            isEnabled: true,
          },
          {
            type: "tbicon_lock_record",
            isEnabled: true,
          },
          {
            type: "tbicon_tag_record",
            isEnabled: true,
          },
          {
            type: "tbicon_delete_record",
            isEnabled: true,
          },
          {
            type: "tbicon_clone_record",
            isEnabled: true,
          },
          {
            type: "tbicon_add_record",
            isEnabled: true,
          },
          {
            type: "tbicon_formlink",
            isEnabled: true,
          },
          {
            type: "tbicon_collaboration",
            isEnabled: true,
          },
          {
            type: "tbicon_add_page",
            isEnabled: true,
          },
          {
            type: "tbicon_add_field",
            isEnabled: true,
          },
          {
            type: "tbicon_records",
            isEnabled: true,
          },
          {
            type: "tbicon_save_kiosk",
            isEnabled: true,
          },
          {
            type: "tbicon_clear_kiosk",
            isEnabled: true,
          },
        ];
        setToolbarButtons(tempArr);
      }
    }
  });

  return (
    <>
      <div>
        <div class="text-center">
          <b class="alert-text">Hide/Display Toolbar Buttons</b>
        </div>
        <hr />
        <div>
          <For each={toolbarButtons()}>
            {(item: any, key: any) => (
              <div class="toolbar-setting-item">
                <Show
                  when={
                    iconLabels[item.type].icon != "Records" &&
                    iconLabels[item.type].icon != "Submit" &&
                    iconLabels[item.type].icon != "Clear"
                  }
                >
                  <div class="toolbar-icon">
                    <Icons
                      name={iconLabels[item.type].icon}
                      stroke="white"
                    ></Icons>
                  </div>
                </Show>
                <Show
                  when={
                    iconLabels[item.type].icon == "Records" ||
                    iconLabels[item.type].icon == "Submit" ||
                    iconLabels[item.type].icon == "Clear"
                  }
                >
                  <div class="toolbar-icon-text">
                    {iconLabels[item.type].icon}
                  </div>
                </Show>
                <label>{iconLabels[item.type].label}</label>
                <Checkbox
                  label=""
                  name="toolbar_button"
                  value={(item.isEnabled ? true : false) as boolean}
                  setValue={(val: boolean) => {
                    setToolbarButtonsUpdated(true);
                    const tempArr: any = [...toolbarButtons()];
                    tempArr[key()].isEnabled = val;
                    setToolbarButtons(tempArr);
                    setIsFormSettingsUpdated(true);
                  }}
                />
              </div>
            )}
          </For>
        </div>
      </div>
    </>
  );
};

export {
  toolbarButtons,
  setToolbarButtons,
  toolbarButtonsUpdated,
  setToolbarButtonsUpdated,
};
export default ToolbarButtonsSettings;
