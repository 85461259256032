import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import Popup from "../global/Popup";
import Icons from "../icons/Icons";
import fetchApi from "~/lib/api";
import {
  formValues,
  linkedFormValues,
  linkedLinkValues,
  linkedParentValues,
  removePopup,
  setAlertMessage,
  setFormFieldsToLink,
  setFormValues,
  setLinkedFormValues,
  setLinkedLinkValues,
  setLinkedParentValues,
  setPopup,
} from "~/store/global.store";
import {
  formJson,
  setFormJson,
  setIsFormJsonUpdated,
} from "~/store/form.store";
import { produce } from "solid-js/store";
import { notify } from "../global/Notification";
import messages from "~/helpers/message";
import { isDataSaving, setIsDataSaving } from "../global/FormNavbar";

const [formLinkerText, setFormLinkerText] = createSignal("" as string);
const [formFieldLinkType, setFormFieldLinkType] = createSignal("" as string);
const [selectedLinkedParent, setSelectedLinkedParent] = createSignal(
  "" as string
);
const [selectedLinkedForm, setSelectedLinkedForm] = createSignal("" as string);
const [formFieldRemoveValue, setFormFieldRemoveValue] = createSignal(
  "" as string
);
const [isEdited, setIsEdited] = createSignal(false as boolean);

const [childLinkedIds, setChildLinkedIds] = createSignal([] as Array<string>);

interface props {
  jwt_token: string;
  user_id: string;
  form_id?: string;
}

async function updateLinkedFormFields(user_id: any, jwt_token: string) {
  let finalArr: any = [];
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/linked/structure`,
    jwt_token: jwt_token as string,
    body: { user_id, form_id: selectedLinkedForm() },
  };
  const formsList = await fetchApi(apiParams);
  if (formsList?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (formsList?.statusCode == 403) {
    location.reload();
  }

  JSON.parse(formsList.data.structure).pages.forEach((data: any, key: any) => {
    const page: any = "Page " + Number(key + 1);
    let tempArr: any = [];
    data.fields?.forEach((field: any) => {
      if (
        field.type == "TextField" ||
        field.type == "RadioField" ||
        field.type == "CheckboxField" ||
        field.type == "DropdownField" ||
        field.type == "DateField"
      ) {
        const tempObject: any = {
          id: field.properties.dataFieldSync?.uid,
          label: field.properties.dataFieldSync?.label?.replace(/:$/, ""),
          top: field.properties.dataFieldSync.frame[0][1],
          left: field.properties.dataFieldSync.frame[0][0],
        };
        tempArr.push(tempObject);
      }
    });
    tempArr.sort((a: any, b: any) => {
      if (a.top === b.top) {
        return a.left - b.left;
      }
      return a.top - b.top;
    });
    tempArr = tempArr.map((item: any) => ({ id: item.id, label: item.label }));
    finalArr.push({ page, pageData: tempArr });

    for (let i = 0; i < finalArr.length; i++) {
      const item = finalArr[i];
      if (item.pageData) {
        item.pageData = item.pageData.filter((data: any) => {
          return !linkedParentValues().some((obj: any) => obj.id === data.id);
        });
        item.pageData = item.pageData.filter((data: any) => {
          return !linkedLinkValues().some((obj: any) => obj.id === data.id);
        });
      }
    }
    setFormFieldsToLink(finalArr);
  });

  setFormLinkerText(
    `Select a Field to Link to <<span style="color: #0066ff">${
      linkedParentValues().filter((item: any) => {
        return item.id === selectedLinkedParent();
      })[0].label
    }</span>> for <<span style="color: #0066ff">${
      linkedFormValues().filter((item: any) => {
        return item.id === selectedLinkedForm();
      })[0].label
    }</span>>`
  );

  setFormFieldLinkType("linkedField");
  setPopup("formLinkField");
}

async function updateParentFields() {
  let finalArr: any = [];
  formJson.pages.forEach((data: any, key: any) => {
    const page: any = "Page " + Number(key + 1);
    let tempArr: any = [];
    data.fields?.forEach((field: any) => {
      if (
        field.type == "TextField" ||
        field.type == "RadioField" ||
        field.type == "CheckboxField" ||
        field.type == "DropdownField" ||
        field.type == "DateField"
      ) {
        const tempObject: any = {
          id: field.properties.dataFieldSync?.uid,
          label: field.properties.dataFieldSync?.label?.replace(/:$/, ""),
          top: field.properties.dataFieldSync.frame[0][1],
          left: field.properties.dataFieldSync.frame[0][0],
        };
        tempArr.push(tempObject);
      }
    });
    tempArr.sort((a: any, b: any) => {
      if (a.top === b.top) {
        return a.left - b.left;
      }
      return a.top - b.top;
    });
    tempArr = tempArr.map((item: any) => ({ id: item.id, label: item.label }));
    finalArr.push({ page, pageData: tempArr });

    for (let i = 0; i < finalArr.length; i++) {
      const item = finalArr[i];
      if (item.pageData) {
        item.pageData = item.pageData.filter((data: any) => {
          return !linkedParentValues().some((obj: any) => obj.id === data.id);
        });
        item.pageData = item.pageData.filter((data: any) => {
          return !linkedLinkValues().some((obj: any) => obj.id === data.id);
        });
      }
    }
    setFormFieldsToLink(finalArr);
  });
  if (linkedFormValues().length == 0) {
    setLinkedFormValues([
      {
        id: formJson.root.formStructure.uuid,
        label: formJson.root.formStructure.name,
        parentField: selectedLinkedParent(),
      },
    ]);
    setFormValues([
      {
        id: formJson.root.formStructure.uuid,
        label: formJson.root.formStructure.name,
      },
    ]);
  }

  setFormLinkerText(
    `Select a Field to Link from <<span style="color: #0066ff">${formJson.root.formStructure.name}</span>>`
  );
  setFormFieldLinkType("parentField");
  setPopup("formLinkField");
}

const FieldLinkerPopup: Component<props> = (props: any) => {
  onMount(() => {
    setTimeout(() => {
      updateParentFields();
    }, 500);
  });
  createEffect(() => {
    if (
      formJson.root.formStructure.formLinkString &&
      linkedParentValues().length == 0 &&
      !isEdited()
    ) {
      setChildLinkedIds(
        JSON.parse(formJson.root.formStructure.formLinkString)
          .map((item: any) =>
            item.childFieldId.split(",").map((item: any) => item.split(":")[0])
          )
          .flatMap((arr: any) => arr)
      );
      const parentValuesArr: any = [];
      const formValuesArr: any = [];
      const childValuesArr: any = [];
      JSON.parse(formJson.root.formStructure.formLinkString).forEach(
        async (item: any, link_key: number) => {
          const parentValuesObject: any = [];
          parentValuesObject["id"] = item.parentFieldId.split(":")[0];
          formJson.pages.forEach((page: any, key: any) => {
            page.fields?.forEach((field: any) => {
              if (
                field.properties.dataFieldSync.uid ==
                item.parentFieldId.split(":")[0]
              ) {
                parentValuesObject["label"] =
                  field.properties.dataFieldSync.label.replace(/:$/, "") +
                  " (page " +
                  (key + 1) +
                  ")";
              }
            });
          });
          parentValuesArr.push(parentValuesObject);

          const formValuesObject: any = [];
          const apiParams = {
            method: "POST" as string,
            url: `${import.meta.env.VITE_API_URL}/form/linked/structure`,
            jwt_token: props.jwt_token as string,
            body: { user_id: props.user_id, form_id: item.childFormUUID },
          };
          const formsList = await fetchApi(apiParams);
          if (formsList?.statusCode == 401) {
            window.location.href = "/logout";
          } else if (formsList?.statusCode == 403) {
            location.reload();
          }
          if (formsList.status) {
            formValuesObject["id"] = JSON.parse(formsList.data.structure).uuid;
            formValuesObject["label"] = JSON.parse(
              formsList.data.structure
            ).name;
            formValuesObject["parentField"] = item.parentFieldId.split(":")[0];
            formValuesArr.push(formValuesObject);
          }

          if (formsList.data?.structure) {
            item.childFieldId
              .split(",")
              .map((item: any) => item.split(":")[0])
              .forEach((id: string) => {
                JSON.parse(formsList.data.structure).pages.forEach(
                  (page: any, key: any) => {
                    page.fields?.forEach((field: any) => {
                      if (field.properties.dataFieldSync.uid == id) {
                        const childValuesObject: any = [];
                        childValuesObject["id"] =
                          field.properties.dataFieldSync.uid;
                        childValuesObject["label"] =
                          field.properties.dataFieldSync.label.replace(
                            /:$/,
                            ""
                          ) +
                          " (page " +
                          (key + 1) +
                          ")";
                        childValuesObject["parentField"] = JSON.parse(
                          formJson.root.formStructure.formLinkString
                        )[link_key].parentFieldId.split(":")[0];
                        childValuesObject["childForm"] = JSON.parse(
                          formJson.root.formStructure.formLinkString
                        )[link_key].childFormUUID;
                        childValuesArr.push(childValuesObject);
                      }
                    });
                  }
                );
              });
          }
        }
      );
      setTimeout(() => {
        setLinkedParentValues(
          parentValuesArr.filter(
            (obj: any, index: any, self: any) =>
              self.findIndex((o: any) => o.id === obj.id) === index
          )
        );
        setLinkedFormValues(formValuesArr);
        const uniqueIds = new Set();
        const result = formValuesArr
          .filter((item: any) => {
            if (!uniqueIds.has(item.id)) {
              uniqueIds.add(item.id);
              return true;
            }
            return false;
          })
          .map((item: any) => {
            const { parentField, ...rest } = item;
            return rest;
          });
        setFormValues(result);
        setLinkedLinkValues(childValuesArr);
      }, 200);
    }
    if (linkedParentValues().length > 0) {
      setSelectedLinkedParent(
        linkedParentValues()[linkedParentValues().length - 1].id
      );
    }
    if (linkedFormValues().length > 0) {
      setSelectedLinkedForm(
        linkedFormValues()[linkedFormValues().length - 1].id
      );
    }
  });

  return (
    <Popup
      heading={"Form Fields Linker"}
      classes="medium-large"
      name="FieldLinker"
    >
      <div class="field-linker">
        <div class="parent-div top">
          <div class="linker-lbl">Parent Form:</div>
          <div
            class="parent-form parent-field"
            style={{
              height:
                formValues().length > 0
                  ? (100 - 100 / (formValues().length + 1)).toFixed() + "%"
                  : "",
            }}
          >
            {formJson.root.formStructure.name}
          </div>
        </div>
        <div class="linked-div">
          <h3 class="linked-main-lbl">Field Linker</h3>
          <Show when={linkedParentValues().length > 0}>
            <div class="linker-lbl flex app-flex space-between">
              <label>Linked Forms:</label>
              <div
                class="cur-p"
                onclick={async () => {
                  let finalArr: any = [];
                  const apiParams = {
                    method: "POST" as string,
                    url: `${import.meta.env.VITE_API_URL}/form/list`,
                    jwt_token: props.jwt_token as string,
                    body: { user_id: props.user_id },
                  };
                  const formsList = await fetchApi(apiParams);
                  if (
                    formsList?.statusCode == 401 ||
                    formsList?.statusCode == 403
                  ) {
                    window.location.href = "/logout";
                  }

                  formsList.data.forEach((data: any) => {
                    const tempArr: any = [];
                    const tempObject: any = {
                      id: data.form_id,
                      label: data.form_name,
                    };
                    tempArr.push(tempObject);
                    finalArr.push({ pageData: tempArr });
                    finalArr = finalArr.filter((obj: any) => {
                      const id = obj.pageData[0].id;
                      return !linkedFormValues().some(
                        (item: any) => item.id === id
                      );
                    });
                    setFormFieldsToLink(finalArr);
                  });

                  setFormFieldLinkType("linkedForm");
                  setFormLinkerText(
                    `Select a Form to Link to <<span style="color: #0066ff">${formJson.root.formStructure.name}</span>>`
                  );
                  setPopup("formLinkField");
                }}
              >
                <Icons
                  name="circlePlus"
                  width={16}
                  height={16}
                  stroke="#0066ff"
                ></Icons>
              </div>
            </div>
            <Show when={formValues().length > 0}>
              <For each={formValues()}>
                {(item: any) => (
                  <div
                    class={`parent-form flex app-flex space-between ${
                      selectedLinkedForm() == item.id ? "active" : ""
                    }`}
                    onclick={() => setSelectedLinkedForm(item.id)}
                  >
                    <label>{item.label}</label>
                    <div
                      class="cur-p"
                      onclick={(e: any) => {
                        e.preventDefault();
                        setPopup("warning");
                        setAlertMessage(
                          "Removing this child form will also remove it from other parent field as well.<br/>Are you sure you want to remove it?"
                        );
                        setFormFieldRemoveValue(item.id);
                        setIsEdited(true);
                      }}
                    >
                      <Icons
                        name="circleMinus"
                        width={16}
                        height={16}
                        stroke="red"
                      ></Icons>
                    </div>
                  </div>
                )}
              </For>
            </Show>
          </Show>
        </div>
      </div>
      <div class="field-linker">
        <div class="parent-div">
          <div class="linker-lbl flex app-flex space-between">
            <label>Parent Fields:</label>
            <div class="cur-p" onclick={() => updateParentFields()}>
              <Icons
                name="circlePlus"
                width={16}
                height={16}
                stroke="#0066ff"
              ></Icons>
            </div>
          </div>
          <Show when={linkedParentValues().length > 0}>
            <For each={linkedParentValues()}>
              {(item: any) => (
                <div
                  class={`parent-form flex app-flex space-between ${
                    selectedLinkedParent() == item.id ? "active" : ""
                  }`}
                  onclick={() => setSelectedLinkedParent(item.id)}
                >
                  <label>{item.label}</label>
                  <div
                    class="cur-p"
                    onclick={(e: any) => {
                      e.preventDefault();
                      setAlertMessage(
                        "Removing this field will remove all child forms and fields linked to it.<br/>Are you sure you want to remove it?"
                      );
                      setPopup("warning");
                      setFormFieldRemoveValue(item.id);
                      setIsEdited(true);
                    }}
                  >
                    <Icons
                      name="circleMinus"
                      width={16}
                      height={16}
                      stroke="red"
                    ></Icons>
                  </div>
                </div>
              )}
            </For>
          </Show>
        </div>
        <div class="linked-div">
          <Show when={linkedParentValues().length > 0}>
            <div class="linker-lbl flex app-flex space-between">
              <label>Linked Fields:</label>
              <div
                class="cur-p"
                onclick={() =>
                  updateLinkedFormFields(props.user_id, props.jwt_token)
                }
              >
                <Icons
                  name="circlePlus"
                  width={16}
                  height={16}
                  stroke="#0066ff"
                ></Icons>
              </div>
            </div>
            <Show when={linkedLinkValues().length > 0}>
              <For each={linkedLinkValues()}>
                {(item: any) => (
                  <Show when={selectedLinkedForm() == item.childForm}>
                    <div
                      class={`parent-form flex app-flex space-between ${
                        selectedLinkedParent() == item.parentField &&
                        selectedLinkedForm() == item.childForm
                          ? "active"
                          : ""
                      }`}
                    >
                      <label>{item.label}</label>
                      <div
                        class="cur-p"
                        onclick={(e: any) => {
                          e.preventDefault();
                          let tempArr: any = linkedLinkValues().filter(
                            (obj: any) => {
                              return obj.id !== item.id;
                            }
                          );
                          setLinkedLinkValues(tempArr);
                          setIsEdited(true);
                        }}
                      >
                        <Icons
                          name="circleMinus"
                          width={16}
                          height={16}
                          stroke="red"
                        ></Icons>
                      </div>
                    </div>
                  </Show>
                )}
              </For>
            </Show>
          </Show>
        </div>
      </div>
      <br />
      <div class="w-100 flex app-flex justify-end">
        <button
          class={`btn primary ${isDataSaving() ? "disabled" : ""}`}
          onclick={async () => {
            setIsDataSaving(true);
            setFormJson(
              "root",
              produce((root: any) => {
                const formLinkMap = new Map();
                linkedLinkValues().forEach((val: any) => {
                  const parentFieldId = val.parentField + ":" + val.childForm;
                  if (!formLinkMap.has(parentFieldId)) {
                    formLinkMap.set(parentFieldId, {
                      childFieldId: "",
                      childFormUUID: val.childForm,
                      id: crypto.randomUUID().toUpperCase(),
                      parentFieldId,
                      parentFormUUID: formJson.root.formStructure.uuid,
                    });
                  }
                  const currentChildFieldId =
                    formLinkMap.get(parentFieldId).childFieldId;
                  formLinkMap.get(parentFieldId).childFieldId =
                    currentChildFieldId.length > 0
                      ? `${currentChildFieldId},${val.id}:${val.childForm}`
                      : `${val.id}:${val.childForm}`;
                });
                const formLinkArr = Array.from(formLinkMap.values());
                root.formStructure.formLinkString =
                  formLinkArr.length > 0 ? JSON.stringify(formLinkArr) : "";
              })
            );
            const apiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/form/structure/update`,
              jwt_token: props.jwt_token,
              body: {
                user_id: props.user_id,
                form_id: formJson.root.formStructure?.uuid,
                structure: {
                  ...formJson.root.formStructure,
                  pages: [],
                  lastSaved: new Date().toISOString(),
                },
                device_type: "web",
              },
            };
            let data = await fetchApi(apiParams);
            if (data?.statusCode == 401) {
              window.location.href = "/logout";
            } else if (data?.statusCode == 403) {
              location.reload();
            }
            let message: string = messages.warn.update_form_settings;
            let theme: string = "warm";
            if (data.status) {
              theme = "success";
              message = messages.form.update_form_settings;
            } else {
              theme = "error";
              message = messages.error.update_form_settings;
            }
            setIsFormJsonUpdated(true);
            setIsDataSaving(false);
            removePopup("FieldLinker");
            notify({
              isNotified: true,
              message,
              theme,
              placement: "top",
              timeout: 2500,
            });
          }}
        >
          <Show when={!isDataSaving()}>Save</Show>
          <Show when={isDataSaving()}>
            Saving&nbsp;<Icons name="loader"></Icons>
          </Show>
        </button>
      </div>
    </Popup>
  );
};

export {
  formLinkerText,
  formFieldLinkType,
  formFieldRemoveValue,
  updateLinkedFormFields,
  selectedLinkedParent,
  selectedLinkedForm,
  formValues,
  setFormValues,
  updateParentFields,
};
export default FieldLinkerPopup;
