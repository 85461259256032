import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import Popup from "../global/Popup";
import SelectOne from "../global/SelectOne";
import { sortTypes } from "~/store/field-editor.store";
import { formJson, setFormJson, setOffset } from "~/store/form.store";
import Icons from "../icons/Icons";
import { produce } from "solid-js/store";
import messages from "~/helpers/message";
import fetchApi from "~/lib/api";
import {
  formSharedUsers,
  removePopup,
  setFormSharedUsers,
} from "~/store/global.store";
import { notify } from "../global/Notification";
import {
  recordsList,
  setRecordsJson,
  setRecordsList,
  updateFormRecordsList,
  updateRecordsList,
} from "~/store/records.store";
import { isDataSaving, setIsDataSaving } from "../global/FormNavbar";
import Checkbox from "../global/Checkbox";
import { userRole } from "~/store/user-form-role";

interface Props {
  user_id: string;
  form_id: string;
  jwt_token: string;
}

const [recordsOrder, setRecordsOrder] = createSignal("" as string);
const [sortByFields, setSortByFields] = createSignal([] as Array<object>);
const [otherFields, setOtherFields] = createSignal([] as Array<object>);
const [isSubSettingPanel, setIsSubSettingPanel] = createSignal(
  false as boolean
);
const [isAdvanceSettingsPanel, setIsAdvanceSettingsPanel] = createSignal(
  false as boolean
);
const [selectedUsers, setSelectedUsers] = createSignal([] as any);
const [isSwapStart, setIsSwapStart] = createSignal("" as string);
const [isSwapOver, setIsSwapOver] = createSignal("" as string);

const SortRecordsPopup: Component<Props> = (props) => {
  onMount(() => {
    setIsAdvanceSettingsPanel(false);
    setIsSubSettingPanel(false);
    if (selectedUsers().length > 0) {
      setFormSharedUsers(
        formSharedUsers().sort(
          (a: any, b: any) =>
            selectedUsers().reduce(
              (map: any, item: any, index: any) => ((map[item] = index), map),
              {}
            )[a.id] -
            selectedUsers().reduce(
              (map: any, item: any, index: any) => ((map[item] = index), map),
              {}
            )[b.id]
        )
      );
    }
  });

  createEffect(() => {
    if (formJson.root.formStructure) {
      const tempArr: Array<string> =
        formJson.root.formStructure?.sortFields &&
        formJson.root.formStructure?.sortFields?.length > 0
          ? formJson.root.formStructure.sortFields.flatMap((field: any) =>
              typeof field == "string"
                ? field.split(",").map((field: any) => field.trim())
                : field
            )
          : formJson.root.formStructure?.primaryFields;
      const finalArr: any = [];
      const otherArr: any = [];
      formJson.pages.forEach((page: any, page_key: any) => {
        const pageArr: any = [];
        page.fields?.forEach((field: any) => {
          if (
            field.type == "TextField" ||
            field.type == "DateField" ||
            field.type == "DropdownField" ||
            field.type == "LocationField" ||
            field.type == "BarcodeField" ||
            field.type == "RadioField"
          ) {
            if (
              tempArr.includes(field.properties.dataFieldSync.uid) ||
              tempArr.includes(String(field.properties.dataFieldSync.uid)) ||
              tempArr
                .map((item: any) => Number(item))
                .includes(field.properties.dataFieldSync.uid) ||
              tempArr
                .map((item: any) => String(item))
                .includes(field.properties.dataFieldSync.uid)
            ) {
              finalArr.push({
                id: field.properties.dataFieldSync.uid,
                label: field.properties.dataFieldSync.label?.replace(/:$/, ""),
              });
            }
            pageArr.push({
              id: field.properties.dataFieldSync.uid,
              label: field.properties.dataFieldSync.label?.replace(/:$/, ""),
            });
          }
        });
        const pageObject: any = {
          page: page_key + 1,
          fields: pageArr,
        };
        otherArr.push(pageObject);
      });
      if (tempArr[0] != finalArr[0]?.id) {
        const temp: any = finalArr[0];
        finalArr[0] = finalArr[1];
        finalArr[1] = temp;
      }
      setSortByFields(finalArr.filter((obj: any) => obj));
      setOtherFields(otherArr);
    }
  });

  function handleSwapStart(e: any, id: string) {
    setIsSwapStart(id);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer?.setData("text/plain", id);
  }

  function handleSwapEnter(e: any, id: string) {
    setIsSwapOver(id);
  }

  function handleSwapOver(e: any) {
    e.dataTransfer.dropEffect = "move";
    e.preventDefault();
  }

  function handleSwap(e: any, id: any) {
    e.stopPropagation();
    e.preventDefault();
    setIsSwapOver("");
    setIsSwapStart("");
    const swappedItemsArr: any = [...formSharedUsers()];
    const index1 = swappedItemsArr.findIndex((item: any) => item.id == id);
    const index2 = swappedItemsArr.findIndex(
      (item: any) => item.id == e.dataTransfer?.getData("text/plain")
    );
    if (index2 != -1) {
      const temp = swappedItemsArr[index1];
      swappedItemsArr[index1] = swappedItemsArr[index2];
      swappedItemsArr[index2] = temp;
      setFormSharedUsers(swappedItemsArr);
    }
  }

  return (
    <>
      <Popup
        heading="Records Sorting"
        classes="small top"
        overlap={true}
        name="sortRecords"
      >
        <Show when={!isSubSettingPanel()}>
          <div class="form-settings-div records on">
            <Show when={!isAdvanceSettingsPanel()}>
              <div>
                <SelectOne
                  set={sortTypes}
                  active={recordsOrder() == "desc" ? "desc" : "asc"}
                  setActive={(val: string) => {
                    setRecordsOrder(val);
                  }}
                  class="full-width"
                ></SelectOne>
              </div>
              <Show when={userRole() != "filler" && userRole() != "viewer"}>
                <br />
                <div class="form-group">
                  <label>Sort By:</label>
                  <div>
                    <For each={sortByFields()}>
                      {(field: any, key: any) => (
                        <div
                          class="form-settings-field records"
                          onclick={() => setIsSubSettingPanel(true)}
                        >
                          <span>
                            {field?.label ? field.label : "Unnamed Field"}
                          </span>
                          <Show when={key() == 0}>
                            <Icons name="chevronRight" stroke="#ccc"></Icons>
                          </Show>
                        </div>
                      )}
                    </For>
                  </div>
                </div>
              </Show>
              <br />
              <br />
              <div class="text-right">
                <button
                  class="btn badge lbl"
                  onclick={() => setIsAdvanceSettingsPanel(true)}
                >
                  Advance
                </button>
              </div>
              <Show when={selectedUsers().length != 0}>
                <br />
                <br />
                <br />
                <div class="alert-text fs-14 user-select-none">
                  <div class="flex space-between align-center">
                    <span>** Note: Records are sorted by users.</span>
                    <button
                      class="btn badge alert-text"
                      onclick={async () => {
                        const formRecordsOrderApiParams = {
                          method: "POST",
                          url: `${
                            import.meta.env.VITE_API_URL
                          }/form/records/order/update`,
                          jwt_token: props.jwt_token,
                          body: {
                            user_id: props.user_id,
                            form_id: props.form_id,
                            records_user_order: "",
                          },
                        };
                        let recordData = await fetchApi(
                          formRecordsOrderApiParams
                        );
                        if (
                          recordData?.statusCode == 401 ||
                          recordData?.statusCode == 403
                        ) {
                          window.location.href = "/logout";
                        }
                        let message: string =
                          messages.error.update_form_settings;
                        let theme: string = "danger";
                        if (recordData && recordData.status) {
                          theme = "success";
                          message = messages.form.update_form_settings;
                          removePopup("sortRecords");
                          const formRecordsApiParams = {
                            method: "POST" as string,
                            url: `${
                              import.meta.env.VITE_API_URL
                            }/form/records/list`,
                            jwt_token: props.jwt_token as string,
                            body: {
                              user_id: props.user_id,
                              form_id: formJson.root.formStructure?.uuid,
                              search_string: "",
                              offset: 0,
                              limit: 20,
                              api: "web",
                            },
                          };
                          let formRecords = await fetchApi(
                            formRecordsApiParams
                          );
                          if (
                            formRecords?.statusCode == 401 ||
                            formRecords?.statusCode == 403
                          ) {
                            window.location.href = "/logout";
                          }
                          if (formRecords && formRecords.status) {
                            setOffset(0);
                            setRecordsList([]);
                            setSelectedUsers(
                              formRecords.data.recordsUserOrder
                                ? formRecords.data.recordsUserOrder?.split(",")
                                : []
                            );
                            formRecords = formRecords.data.records;
                            if (selectedUsers().length == 0) {
                              setRecordsJson({
                                records: formRecords,
                              });
                            } else {
                              setRecordsJson({
                                records: [
                                  ...formRecords.flatMap(
                                    (list: any) => list.subList
                                  ),
                                ],
                              });
                            }
                            updateRecordsList(formRecords);
                          }
                        }
                        setTimeout(() => {
                          notify({
                            isNotified: true,
                            message,
                            theme,
                            placement: "top",
                            timeout: 2500,
                          });
                        }, 0);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                  <div>Click clear to see default records listing.</div>
                </div>
              </Show>
            </Show>
            <Show when={isAdvanceSettingsPanel()}>
              <div class="lbl">Select Users To Sort Records Accordingly</div>
              <div class="underline mb-0"></div>
              <div class="flex app-flex gap align-center form-builder__panel--list w-100 p-3-12">
                <Checkbox
                  label=""
                  name="all"
                  value={
                    (selectedUsers().length == formSharedUsers().length
                      ? true
                      : false) as boolean
                  }
                  setValue={(val: boolean) => {
                    if (val) {
                      setSelectedUsers(
                        formSharedUsers().map((user: any) => user.id)
                      );
                    } else {
                      setSelectedUsers([]);
                    }
                  }}
                />
                <label class="w-100" for={`all_id`}>
                  <li class="flex space-between">
                    <span class="lbl">Select All</span>
                  </li>
                </label>
              </div>
              <div class="underline m-0"></div>
              <ul class="share-form-users-div form-builder__panel--list w-100">
                <For each={formSharedUsers()}>
                  {(user: any) => (
                    <div
                      class={`list-user flex app-flex gap align-center items ${
                        isSwapOver() != "" && isSwapOver() == user.id
                          ? "over"
                          : ""
                      } ${
                        isSwapStart() != "" && isSwapStart() == user.id
                          ? "dragging"
                          : ""
                      }`}
                      draggable={true}
                      ondragstart={(e) => handleSwapStart(e, user.id)}
                      ondragenter={(e) => handleSwapEnter(e, user.id)}
                      ondragover={(e) => handleSwapOver(e)}
                      ondrop={(e) => handleSwap(e, user.id)}
                    >
                      <Checkbox
                        label=""
                        name={user.id}
                        value={
                          (selectedUsers().includes(user.id)
                            ? true
                            : false) as boolean
                        }
                        setValue={(val: boolean) => {
                          const tempArr: any = [...selectedUsers()];
                          const index: any = tempArr.findIndex(
                            (id: any) => id == user.id
                          );
                          if (index == -1) {
                            tempArr.push(user.id);
                          } else {
                            tempArr.splice(index, 1);
                          }
                          setSelectedUsers(tempArr);
                        }}
                      />
                      <label class="w-100" for={`${user.id}_id`}>
                        <li class="flex space-between">
                          <span>{user.name}</span>
                          <Show when={user.recordsCount}>
                            <span class="badge sm primary">
                              {user.recordsCount}
                            </span>
                          </Show>
                        </li>
                      </label>
                      <div class="cur-p">
                        <Icons name="swap" width={18} height={18}></Icons>
                      </div>
                    </div>
                  )}
                </For>
              </ul>
            </Show>
          </div>
        </Show>
        <Show when={isSubSettingPanel()}>
          <div class="form-settings-div records">
            <div
              class="primary-fields-div records"
              ondragover={(e: any) => {
                e.dataTransfer.dropEffect = "move";
                e.preventDefault();
              }}
              ondrop={(e: any) => {
                e.stopPropagation();
                e.preventDefault();
                const arr: any = [...sortByFields()];
                const temp: any = arr[0];
                arr[0] = arr[1];
                arr[1] = temp;
                setSortByFields(arr);
              }}
            >
              <For each={sortByFields()}>
                {(field: any) => (
                  <div
                    class="primary-fields-item active flex app-flex align-center space-between"
                    draggable={true}
                    ondragstart={(e: any) => {
                      e.dataTransfer.effectAllowed = "move";
                      e.dataTransfer?.setData("text/plain", field.id);
                    }}
                    onclick={() => setIsSubSettingPanel(true)}
                  >
                    <span>{field?.label ? field.label : "Unnamed Field"}</span>
                    <div
                      class="mr-25 cur-p"
                      onclick={() => {
                        let tempArr: any = [...sortByFields()];
                        if (tempArr.length == 2) {
                          tempArr = tempArr.filter(
                            (item: any) => item.id != field.id
                          );
                          setSortByFields(tempArr);
                        }
                      }}
                    >
                      <Icons
                        name="circleMinus"
                        stroke="red"
                        height={18}
                        width={18}
                      ></Icons>
                    </div>
                  </div>
                )}
              </For>
            </div>
            <br />
            <For each={otherFields()}>
              {(item: any) => (
                <>
                  <label class="lbl">Page {item.page}</label>
                  <div class="primary-fields-div records mt-5">
                    <For each={item.fields}>
                      {(field: any) => (
                        <div
                          class="primary-fields-item flex app-flex align-center space-between"
                          onclick={() => {
                            const tempArr: any = [...sortByFields()];
                            if (
                              tempArr.length <= 1 &&
                              sortByFields().findIndex(
                                (obj: any) => obj.id == field.id
                              ) == -1
                            ) {
                              tempArr.push(field);
                              setSortByFields(tempArr);
                            }
                          }}
                        >
                          <span
                            class={
                              sortByFields().length == 2 &&
                              !sortByFields()
                                .map((item: any) => item.id)
                                .includes(field.id)
                                ? "disabled"
                                : ""
                            }
                          >
                            {field?.label ? field.label : "Unnamed Field"}
                          </span>
                          <Show
                            when={sortByFields()
                              .map((item: any) => item.id)
                              .includes(field.id)}
                          >
                            <Icons
                              name="check"
                              stroke="green"
                              width={18}
                              height={18}
                            ></Icons>
                          </Show>
                        </div>
                      )}
                    </For>
                  </div>
                  <br />
                </>
              )}
            </For>
          </div>
        </Show>
        <div class="flex app-flex space-between">
          <div>
            <Show when={isSubSettingPanel() || isAdvanceSettingsPanel()}>
              <a
                class="a-link inline-flex"
                href="#"
                onclick={() => {
                  setIsSubSettingPanel(false);
                  setIsAdvanceSettingsPanel(false);
                }}
              >
                <Icons name="arrowLeft" stroke="hsl(214, 56%, 41%)" />
                &nbsp; Back
              </a>
            </Show>
          </div>
          <button
            class={`btn primary ${isDataSaving() ? "disabled" : ""}`}
            onclick={async () => {
              let message: string = messages.error.update_form_settings;
              let theme: string = "danger";
              if (isAdvanceSettingsPanel()) {
                setSelectedUsers(
                  selectedUsers().sort(
                    (a: any, b: any) =>
                      formSharedUsers().reduce(
                        (map: any, item: any, index: any) => (
                          (map[item.id] = index), map
                        ),
                        {}
                      )[a] -
                      formSharedUsers().reduce(
                        (map: any, item: any, index: any) => (
                          (map[item.id] = index), map
                        ),
                        {}
                      )[b]
                  )
                );
                const formRecordsApiParams = {
                  method: "POST",
                  url: `${
                    import.meta.env.VITE_API_URL
                  }/form/records/order/update`,
                  jwt_token: props.jwt_token,
                  body: {
                    user_id: props.user_id,
                    form_id: props.form_id,
                    records_user_order: selectedUsers().join(","),
                  },
                };
                let recordData = await fetchApi(formRecordsApiParams);
                if (
                  recordData?.statusCode == 401 ||
                  recordData?.statusCode == 403
                ) {
                  window.location.href = "/logout";
                }
                if (recordData && recordData.status) {
                  theme = "success";
                  message = messages.form.update_form_settings;
                }
              } else {
                setIsDataSaving(true);
                setFormJson(
                  "root",
                  produce((root: any) => {
                    root.formStructure.sortFields = sortByFields().map(
                      (field: any) => field.id
                    );
                  })
                );
                const apiParams = {
                  method: "POST" as string,
                  url: `${import.meta.env.VITE_API_URL}/form/structure/update`,
                  jwt_token: props.jwt_token,
                  body: {
                    user_id: props.user_id,
                    form_id: formJson.root.formStructure?.uuid,
                    structure: {
                      ...formJson.root.formStructure,
                      pages: [],
                      lastSaved: new Date().toISOString(),
                    },
                    records_order: recordsOrder(),
                    device_type: "web",
                  },
                };
                let data = await fetchApi(apiParams);
                if (data?.statusCode == 401) {
                  window.location.href = "/logout";
                } else if (data?.statusCode == 403) {
                  location.reload();
                }
                if (data.status) {
                  theme = "success";
                  message = messages.form.update_form_settings;
                }
                if (userRole() != "viewer") {
                  const formRecordsApiParams = {
                    method: "POST" as string,
                    url: `${
                      import.meta.env.VITE_API_URL
                    }/form/records/name/update`,
                    jwt_token: props.jwt_token as string,
                    body: {
                      user_id: props.user_id,
                      form_id: formJson.root.formStructure?.uuid,
                    },
                  };
                  let formRecords = await fetchApi(formRecordsApiParams);
                  if (
                    formRecords?.statusCode == 401 ||
                    formRecords?.statusCode == 403
                  ) {
                    window.location.href = "/logout";
                  }
                }
              }
              setIsDataSaving(false);
              removePopup("sortRecords");
              if (recordsList()?.length > 0) {
                updateFormRecordsList(props.form_id, 0);
              }
              setTimeout(() => {
                notify({
                  isNotified: true,
                  message,
                  theme,
                  placement: "top",
                  timeout: 2500,
                });
              }, 0);
            }}
          >
            <Show when={!isDataSaving()}>Save</Show>
            <Show when={isDataSaving()}>
              Saving&nbsp;<Icons name="loader"></Icons>
            </Show>
          </button>
        </div>
      </Popup>
    </>
  );
};

export { recordsOrder, setRecordsOrder, selectedUsers, setSelectedUsers };
export default SortRecordsPopup;
