import { Component, Show, createEffect } from "solid-js";
import { Dynamic } from "solid-js/web";

import TextField from "./TextField";
import LabelField from "./LabelField";
import DropdownField from "./DropdownField";
import LocationField from "./LocationField";
import LineField from "./LineField";
import BarcodeField from "./BarcodeField";
import CanvasField from "./CanvasField";
import ImageField from "./ImageField";
import TableField from "./TableField";
import ScriptButtonField from "./ScriptButtonField";
import {
  calculateRect,
  cutCopyField,
  setContextMenu,
  setContextMenuLeft,
  setContextMenuTop,
} from "~/store/global.store";
import convert from "~/helpers/convertor";
interface Props {
  data: any;
  fieldsType?: string;
  user_id?: string;
  jwt_token?: string;
}

const fieldSet: { [key: string]: any } = {
  TextField: TextField,
  DateField: TextField,
  LabelField: LabelField,
  CheckboxField: TextField,
  RadioField: TextField,
  DropdownField: DropdownField,
  LocationField: LocationField,
  LineField: LineField,
  ImageField: ImageField,
  PhotoField: ImageField,
  SignatureField: CanvasField,
  AnnotationField: CanvasField,
  NoteField: CanvasField,
  BarcodeField: BarcodeField,
  TableField: TableField,
  ScriptButtonField: ScriptButtonField,
};

const FormField: Component<Props> = (props) => {
  const repositioningLabels = (
    el: any,
    data: {
      frame: (string | number)[][];
      label: string;
      fontSize: string | number;
      type: string;
    }
  ) => {
    let rect = calculateRect(
      Number(data.fontSize),
      Number(data.frame[0][0]),
      Number(data.frame[0][1]),
      data.label,
      "Helvetica"
    );
    setTimeout(() => {
      el.style.left = (rect.x - 21.13 > 0 ? rect.x - 21.13 : 0) + "px";
      // el.style.height = rect.height + "px";
      // el.style.top = rect.y + "px";
      el.style.top =
        Number(convert(+data.frame[0][1], "in-px")) +
        (Number(
          convert(
            +data.frame[1][1] >= 0.375 ? 0.375 : +data.frame[1][1],
            "in-px"
          )
        ) -
          el.offsetHeight) /
          2 +
        (Number(data.fontSize) > 18.5 ? Number(data.fontSize) / 2 : 0) +
        "px";
      el.style.fontSize =
        (Math.round((Number(data.fontSize) / 72) * 84 * 4) / 4 / 84) * 96 -
        (props.fieldsType == "preview" ? 4 : 0) +
        "px";
      el.style.setProperty(
        "width",
        // Old Code
        // `${props.fieldsType == "preview" ? rect.width + 5 : rect.width}px`,
        // New Code
        `${props.fieldsType == "preview" ? rect.width + 5 : rect.width - 1}px`,
        "important"
      );
      el.style.opacity = "1";
    });
  };

  createEffect(() => {
    if (props.data.properties?.dataFieldSync.frame) {
      if (
        document.getElementById(
          `${props.data.properties?.dataFieldSync.uid}_label_${
            props.fieldsType == "preview" ? "preview" : ""
          }`
        )
      ) {
        repositioningLabels(
          document.getElementById(
            `${props.data.properties?.dataFieldSync.uid}_label_${
              props.fieldsType == "preview" ? "preview" : ""
            }`
          ),
          props.data.properties?.dataFieldSync
        );
      }
    }

    if (props.data.properties?.dataFieldSync.frame[0][0]) {
      if (
        document.getElementById(
          `${props.data.properties?.dataFieldSync.uid}_label_${
            props.fieldsType == "preview" ? "preview" : ""
          }`
        )
      ) {
        repositioningLabels(
          document.getElementById(
            `${props.data.properties?.dataFieldSync.uid}_label_${
              props.fieldsType == "preview" ? "preview" : ""
            }`
          ),
          props.data.properties?.dataFieldSync
        );
      }
    }

    if (props.data.properties?.dataFieldSync.frame[1][1]) {
      if (
        document.getElementById(
          `${props.data.properties?.dataFieldSync.uid}_label_${
            props.fieldsType == "preview" ? "preview" : ""
          }`
        )
      ) {
        repositioningLabels(
          document.getElementById(
            `${props.data.properties?.dataFieldSync.uid}_label_${
              props.fieldsType == "preview" ? "preview" : ""
            }`
          ),
          props.data.properties?.dataFieldSync
        );
      }
    }

    if (props.data.properties?.dataFieldSync.shouldDisplayLabel) {
      if (
        document.getElementById(
          `${props.data.properties?.dataFieldSync.uid}_label_${
            props.fieldsType == "preview" ? "preview" : ""
          }`
        )
      ) {
        repositioningLabels(
          document.getElementById(
            `${props.data.properties?.dataFieldSync.uid}_label_${
              props.fieldsType == "preview" ? "preview" : ""
            }`
          ),
          props.data.properties?.dataFieldSync
        );
      }
    }
  });

  return (
    <div
      class="field-group"
      oncontextmenu={(e: any) => {
        if (cutCopyField().length > 0) {
          e.preventDefault();
          setTimeout(() => {
            setContextMenuLeft(e.clientX);
            setContextMenuTop(e.clientY);
            setContextMenu("form");
          }, 0);
        } else if (e.target.id == "form_frame" && cutCopyField().length == 0) {
          setContextMenu("");
        }
      }}
    >
      <Show
        when={
          props.data.type != "LabelField" &&
          props.data.type != "CheckboxField" &&
          props.data.type != "ImageField" &&
          props.data.type != "LineField" &&
          props.data.type != "TableField" &&
          props.data.type != "ScriptButtonField" &&
          props.data.properties?.dataFieldSync.shouldDisplayLabel &&
          (props.data.properties?.dataFieldSync.parentTableId == 0 ||
            props.data.properties?.dataFieldSync.parentTableId == "" ||
            props.data.properties?.dataFieldSync.parentTableId == "0")
        }
      >
        <label
          id={`${props.data.properties?.dataFieldSync?.uid}_label_${
            props.fieldsType == "preview" ? "preview" : ""
          }`}
          class={`field-label ${
            props.fieldsType == "preview" ? "user-select-none" : ""
          }`}
          for={props.data.properties?.dataFieldSync.uid}
          ref={(el) =>
            repositioningLabels(el, props.data.properties?.dataFieldSync)
          }
          style={{
            display: "flex",
            "align-items":
              Number(props.data.properties?.dataFieldSync.frame[1][1]) <= 0.375
                ? "center"
                : "start",
            // "padding-top":
            //   Number(props.data.properties?.dataFieldSync.frame[1][1]) <= 0.375
            //     ? ""
            //     : "7px",
            "font-size":
              (Math.round(
                (Number(props.data.properties?.dataFieldSync.fontSize) / 72) *
                  84 *
                  4
              ) /
                4 /
                84) *
                96 +
              "px",
            "justify-content": "right",
            "text-align": "right",
            "word-wrap": "break-word",
            "word-break": "break-word",
            color: props.data.properties?.color,
          }}
        >
          {props.data.properties?.dataFieldSync.label != ":"
            ? props.data.properties?.dataFieldSync.label
            : ""}
        </label>
      </Show>
      <Dynamic
        component={fieldSet[props.data.type]}
        properties={props.data.properties}
        fieldsType={props.fieldsType ? props.fieldsType : "render"}
        jwt_token={props?.jwt_token}
        user_id={props?.user_id}
      />
    </div>
  );
};

export default FormField;
