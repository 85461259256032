import { Component, Show, createEffect, createSignal, onMount } from "solid-js";
import convert from "~/helpers/convertor";
import {
  formJson,
  pasteFormField,
  removeFormField,
  setFormJson,
  updateCutCopyField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  contextMenu,
  isContextMenu,
  recordContentMenuId,
  recordDeleteRight,
  setAllSelectField,
  setContextMenu,
  setIsContextMenu,
  setMultipleSelectField,
} from "~/store/global.store";
import {
  cloneRecord,
  deleteRecord,
  lockRecord,
  recordsJson,
} from "~/store/records.store";

interface Props {
  left: string;
  top: string;
  field: any;
  formId?: string;
}

const [fieldPastePosition, setFieldPastePosition] = createSignal(
  [] as Array<number>
);

const ContextMenu: Component<Props> = (props: any) => {
  const [contextMenuTop, setContextMenuTop] = createSignal(0 as number);
  const [contextMenuLeft, setContextMenuLeft] = createSignal(0 as number);
  const [fieldLeft, setFieldLeft] = createSignal(0 as number);
  const [fieldTop, setFieldTop] = createSignal(0 as number);
  onMount(() => {
    const formFrame = document.getElementById("form_frame");
    // Create mousemove event
    formFrame?.addEventListener("mousemove", (event) => {
      const arr: any = [event.offsetX, event.offsetY];
      setFieldPastePosition(arr);
    });
  });

  createEffect(() => {
    if (props.field && contextMenu() == "field") {
      const element: any = document.querySelector(".builder-container");
      const scale = localStorage.getItem("fc_form_scale")
        ? Number(localStorage.getItem("fc_form_scale"))
        : 1;

      const windowWidth = window.innerWidth;
      const leftBarWidth = 236;
      const rightBarWidth = 300;
      const formWidth = 770 * scale;
      const formMargin = 32;

      // Ensure otherSpace is non-negative and adaptive
      const otherSpace = Math.max(
        (windowWidth - leftBarWidth - rightBarWidth - formMargin - formWidth) /
          2,
        0
      );

      const frameX =
        Number(convert(props.field.dataFieldSync?.frame[0][0], "in-px")) *
        scale;
      const frameY =
        Number(convert(props.field.dataFieldSync?.frame[0][1], "in-px")) *
        scale;
      const frameWidth =
        Number(convert(props.field.dataFieldSync?.frame[1][0], "in-px")) *
        scale;
      const frameHeight =
        Number(convert(props.field.dataFieldSync?.frame[1][1], "in-px")) *
        scale;

      // Refined boundaries
      const leftBoundary = leftBarWidth + otherSpace + formMargin / 2 + frameX;
      const rightBoundary = leftBoundary + frameWidth - 20 * scale;

      // Calculate screen offset based on scale and resolution
      const screenOffset = Math.min(20 * scale, windowWidth / 10);

      if (
        leftBoundary <= props.left + element.scrollLeft + screenOffset &&
        rightBoundary > props.left + element.scrollLeft &&
        frameY + 51 <= props.top + element.scrollTop &&
        frameY + frameHeight + 51 > props.top + element.scrollTop
      ) {
        let fieldLeft: any =
          (props.left +
            element.scrollLeft -
            leftBarWidth -
            otherSpace -
            formMargin / 2 -
            26 * scale) /
          scale;
        fieldLeft = fieldLeft < 30 ? 30 : fieldLeft > 668 ? 668 : fieldLeft;
        setFieldLeft(fieldLeft);
        let fieldTop: any =
          (props.top + element.scrollTop - 51 - 20 * scale) / scale;
        setFieldTop(fieldTop > 885 ? 885 : fieldTop);
        if (!isContextMenu().includes(props.field.dataFieldSync?.uid)) {
          setIsContextMenu([
            ...isContextMenu(),
            props.field.dataFieldSync?.uid,
          ]);
        }
      } else {
        if (isContextMenu().includes(props.field.dataFieldSync?.uid)) {
          setIsContextMenu(
            isContextMenu().filter(
              (id: any) => id != props.field.dataFieldSync?.uid
            )
          );
        }
      }
    } else {
      if (!isContextMenu().includes(props.field.dataFieldSync?.uid)) {
        setIsContextMenu([...isContextMenu(), props.field.dataFieldSync?.uid]);
      }
    }
  });

  return (
    <>
      <Show
        when={
          isContextMenu().includes(props.field.dataFieldSync?.uid) &&
          (contextMenu() != "record" ||
            (contextMenu() == "record" &&
              recordsJson.currentRecord?.record_id == recordContentMenuId()))
        }
      >
        <div
          class="context-menu"
          style={{
            "--left": `${
              fieldLeft() && fieldLeft() != 0
                ? fieldLeft()
                : contextMenuLeft() != 0
                ? contextMenuLeft()
                : contextMenuTop() != 0
                ? props.left + 10
                : props.left
            }px`,
            "--top": `${
              fieldTop() && fieldTop() != 0
                ? fieldTop()
                : contextMenuTop() != 0
                ? contextMenuTop()
                : props.top
            }px`,
          }}
        >
          <ul>
            <Show when={contextMenu() == "field"}>
              <li
                onclick={() => {
                  setMultipleSelectField(true);
                  setContextMenu("");
                }}
              >
                Select Multiple
              </li>
              <li
                onclick={() => {
                  setAllSelectField(true);
                  setMultipleSelectField(true);
                  setContextMenu("");
                  updatedMultipleFields("ALL");
                }}
              >
                Select All
              </li>
              <li
                onclick={() => {
                  updateCutCopyField();
                  removeFormField();
                }}
              >
                Cut
              </li>
              <li
                onclick={() => {
                  updateCutCopyField();
                  setFormJson({
                    currentField: {},
                    multipleFields: [],
                  });
                  setContextMenu("");
                }}
              >
                Copy
              </li>
              <li onclick={() => removeFormField()}>Delete</li>
            </Show>
            <Show when={contextMenu() == "form"}>
              <li onclick={() => pasteFormField()}>Paste</li>
            </Show>
            <Show when={contextMenu() == "record"}>
              <Show
                when={
                  formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                    (obj: any) => obj.type == "tbicon_clone_record"
                  ) != -1
                }
              >
                <li onclick={() => cloneRecord(props?.formId)}>Clone</li>
              </Show>
              <Show
                when={
                  !recordsJson.currentRecord?.is_locked &&
                  formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                    (obj: any) => obj.type == "tbicon_lock_record"
                  ) != -1
                }
              >
                <li onclick={() => lockRecord()}>Lock</li>
              </Show>
              <Show
                when={
                  recordDeleteRight() &&
                  formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                    (obj: any) => obj.type == "tbicon_delete_record"
                  ) != -1
                }
              >
                <li onclick={() => deleteRecord()}>Delete</li>
              </Show>
            </Show>
          </ul>
        </div>
      </Show>
    </>
  );
};

export { fieldPastePosition, setFieldPastePosition };
export default ContextMenu;
