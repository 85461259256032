import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Popup from "../global/Popup";
import {
  exportMailattachments,
  removePopup,
  selectedRecords,
  setExportMailattachments,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import { notify } from "../global/Notification";
import messages from "~/helpers/message";
import fetchApi from "~/lib/api";
import { createStore } from "solid-js/store";
import MailInput from "../global/MailInput";
import { mailSettings, setMailSettings } from "../fields/ScriptButtonField";
import { exportFormat } from "~/store/export-files.store";
import { isLoader, setIsLoader } from "~/store/records.store";

interface Props {
  form_id: string;
  jwt_token: string;
  userDetails: any;
  exportSettings: any;
}

export const [barcodeUrls, setBarcodeUrls] = createSignal([] as any);

const [mailDetails, setMailDetails] = createStore({
  to: "" as string,
  cc: "" as string,
  subject: "" as string,
  content:
    `Hello,</br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Please find the attachment.` as any,
});

const ExportMailPopup: Component<Props> = (props) => {
  createEffect(() => {
    if (Object.keys(mailSettings()).length > 0) {
      setMailDetails({
        to: mailSettings().mail_recipients,
        cc: mailSettings()?.mail_cc ? mailSettings()?.mail_cc : "",
        subject: mailSettings().mail_subject,
        content: mailSettings().mail_message,
      });
    } else if (props.exportSettings) {
      setMailDetails({
        to: props.exportSettings.mail_recipients,
        subject: props.exportSettings.mail_subject,
        cc: "",
        content: props.exportSettings.mail_message,
      });
    }
  });

  return (
    <Popup
      heading="Export Mail"
      classes="medium"
      overlap={true}
      name="exportMail"
      jwt_token={props.jwt_token}
    >
      <div class="form-group">
        <div class="app-flex flex gap align-end pl-7">
          <label for="mail_to">To:</label>
          <MailInput
            type="to"
            setValue={(value: string) =>
              setMailDetails({
                to: value,
              })
            }
            value={mailDetails.to}
          ></MailInput>
        </div>
        <div class="underline"></div>
        <div class="app-flex flex gap align-end pl-7">
          <label for="mail_cc">Cc:</label>
          <MailInput
            type="cc"
            setValue={(value: string) =>
              setMailDetails({
                cc: value,
              })
            }
            value={mailDetails.cc}
          ></MailInput>
        </div>
        <div class="underline"></div>
        <div class="app-flex flex gap align-end pl-7">
          <label for="mail_subject">Subject:</label>
          <input
            id="mail_subject"
            type="text"
            class="input plain"
            value={mailDetails?.subject ? mailDetails?.subject : ""}
            onblur={(e: any) => setMailDetails({ subject: e.target.value })}
          />
        </div>
        <div class="underline"></div>
        <br />
        <div class="ml-12">
          <div
            class="input plain h-80p overflow-auto"
            onBlur={(e: any) => setMailDetails({ content: e.target.innerHTML })}
            innerHTML={mailDetails?.content}
            contentEditable={true}
          ></div>
        </div>
        <br />
        <div class="app-flex flex gap flex-wrap">
          <For each={exportMailattachments()}>
            {(file: any) => (
              <div class="app-flex flex flex-column align-center w-100p word-wrap fs-12">
                <a
                  href={`
                    ${import.meta.env.VITE_API_URL}/uploads/${file.fileName}`}
                  target="_blank"
                  class="email-logo"
                >
                  <div
                    class="remove-icon"
                    onclick={(e: any) => {
                      e.preventDefault();
                      setExportMailattachments(
                        exportMailattachments().filter(
                          (item: any) => item.fileName != file.fileName
                        )
                      );
                    }}
                  >
                    <Icons
                      name="circleMinus"
                      width={14}
                      height={14}
                      stroke="red"
                    ></Icons>
                  </div>
                </a>
                <div>{file.fileName.replace(/_\d+\.(\w+)$/, ".$1")}</div>
                <Show when={file?.fileSize}>
                  <div class="fs-12 color">{file.fileSize}</div>
                </Show>
                <br />
              </div>
            )}
          </For>
        </div>
        <br />
        <div class="text-right">
          <button
            class={`btn primary ${
              mailDetails.to &&
              mailDetails.subject &&
              exportMailattachments().length > 0 &&
              !isLoader()
                ? ""
                : "disabled"
            }`}
            onclick={async () => {
              if (
                mailDetails.to &&
                mailDetails.subject &&
                exportMailattachments().length > 0
              ) {
                setIsLoader(true);
                const exportFileApiParams = {
                  method: "POST" as string,
                  url: `${import.meta.env.VITE_API_URL}/form/export/mail`,
                  jwt_token: props.jwt_token as string,
                  body: {
                    form_id: props.form_id,
                    record_ids: selectedRecords().join(","),
                    export_formats: exportFormat().join(","),
                    barcodeUrls: barcodeUrls(),
                    from_email: props.userDetails.data.email,
                    from_user: props.userDetails.data.username,
                    to_email: mailDetails.to.split("\n").join(","),
                    cc: mailDetails.cc,
                    subject: mailDetails.subject,
                    content: mailDetails.content,
                  },
                };
                let exportFile = await fetchApi(exportFileApiParams);
                if (exportFile?.statusCode == 401) {
                  window.location.href = "/logout";
                } else if (exportFile?.statusCode == 403) {
                  location.reload();
                }
                let message: string = messages.error.error;
                let theme: string = "danger";
                if (exportFile && exportFile.status) {
                  message = messages.form[exportFile.message];
                  theme = "success";
                }
                setMailSettings({});
                setIsLoader(false);
                removePopup("exportMail", props.jwt_token);
                notify({
                  isNotified: true,
                  message,
                  theme,
                  placement: "top",
                  timeout: 5000,
                });
              }
            }}
          >
            <Show when={!isLoader()}>Send</Show>
            <Show when={isLoader()}>
              <Icons name="buttonLoader" width={20} height={20}></Icons>
              &nbsp; Sending
            </Show>
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default ExportMailPopup;
