import { Component, createEffect, createSignal, onMount, Show } from "solid-js";
import { useRouteData } from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import Dropdown from "~/components/global/Dropdown";
import Icons from "~/components/icons/Icons";
import { selectedDropDown } from "~/store/global.store";
import { parse } from "date-fns";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/server-logs`,
      jwt_token: jwt_token as string,
    };
    let serverLogFiles = await fetchApi(apiParams);
    if (serverLogFiles?.statusCode == 401) {
      throw redirect("/logout");
    }
    serverLogFiles =
      serverLogFiles.data && serverLogFiles.data?.serverLogFiles
        ? serverLogFiles.data.serverLogFiles
        : [];
    return { serverLogFiles, jwt_token };
  });
}

const serverLogs: Component = () => {
  setPageTitle(`${SITE_TITLE} - Server Logs`);
  const data = useRouteData<typeof routeData>();

  const [serverLogFiles, setServerLogFiles] = createSignal([] as any);
  const [currentFile, setCurrentFile] = createSignal("" as any);
  const [selectedServerLogs, setSelectedServerLogs] = createSignal("" as any);
  const [isMounted, setIsMounted] = createSignal(false as boolean);

  createEffect(() => {
    if (
      data()?.serverLogFiles &&
      data()?.serverLogFiles?.length > 0 &&
      isMounted()
    ) {
      let dateRanges = data()?.serverLogFiles.map((file: any) => ({
        label: file
          .replaceAll("-", "/")
          .replaceAll(".txt", "")
          .replace(" ", " - "),
        value: file.replaceAll(".txt", ""),
      }));
      dateRanges.sort((a: any, b: any) => {
        let dateA: any = parse(a.value.split(" ")[0], "dd-MM-yyyy", new Date());
        let dateB: any = parse(b.value.split(" ")[0], "dd-MM-yyyy", new Date());
        return dateB - dateA;
      });
      setServerLogFiles(dateRanges);
      getServerLogData(serverLogFiles()[0].value);
      setIsMounted(false);
    }
  });

  onMount(() => {
    setIsMounted(true);
  });

  async function getServerLogData(file: any) {
    setCurrentFile(file);
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/ServerLogs/${currentFile()}.txt`
    );
    const textData = await response.text();
    setSelectedServerLogs(
      textData
        .replaceAll("\n", "<br>")
        .replaceAll(
          "------------------------------------------------------------------------------------",
          '<div class="seperator"></div>'
        )
        .replace("<br>", "")
        .replace('<div class="seperator"></div>', "")
        .replaceAll(
          '<div class="seperator"></div><br><br>',
          '<div class="seperator"></div><br>'
        )
        .replaceAll("<br><br>~", "<br>~")
    );
  }

  return (
    <main>
      <SystemLayout>
        <div class="flex space-between">
          <h3 class="title color">Server Logs</h3>
          <div class="pr w-270">
            <div
              id="server_logs"
              class={`user-role-dropdown br-unset ${
                serverLogFiles().length > 0 ? "cur-p" : ""
              }`}
            >
              <span class="dropdown-div lbl" id="server_logs">
                {serverLogFiles().length > 0
                  ? serverLogFiles().filter(
                      (file: any) => file.value == currentFile()
                    )[0]?.label
                  : "No Logfile Found"}
              </span>
              <Icons name="chevronDown" stroke="#6c6e6f"></Icons>
            </div>
            <Show
              when={
                serverLogFiles().length > 0 &&
                selectedDropDown() == "server_logs"
              }
            >
              <Dropdown
                list={serverLogFiles()}
                title="label"
                link="link"
                class="w-100 t-34"
                selectedValue={currentFile()}
                isFormDropdown={true}
                setValue={async (val: any) => getServerLogData(val)}
              />
            </Show>
          </div>
        </div>
        <Show when={serverLogFiles().length == 0}>
          <h3 class="lbl w-100 text-center mt-65">The Logfile is empty.</h3>
        </Show>
        <Show when={selectedServerLogs()}>
          <div class="server-logs" innerHTML={selectedServerLogs()}></div>
        </Show>
      </SystemLayout>
    </main>
  );
};

export default serverLogs;
