import { Component, For } from "solid-js";
import { activeTheme, setActiveTheme } from "./formSettings";
import { setIsFormSettingsUpdated } from "~/store/global.store";

interface Props {}

const ThemeSettings: Component<Props> = (props) => {
  const Themes: Array<string> = [
    "Medical",
    "Clipboard",
    "Journal",
    "Notecard",
    "Redbook",
    "Plain",
  ];

  return (
    <>
      <div class="flex app-flex space-between flex-wrap">
        <For each={Themes}>
          {(theme: string) => (
            <div class="theme-settings-image-group">
              <img
                onclick={() => {
                  setIsFormSettingsUpdated(true);
                  setActiveTheme(theme);
                }}
                src={`/form_themes/${theme}.png`}
                class={`theme-settings-image ${
                  activeTheme() == theme ? "active" : ""
                }`}
              />
            </div>
          )}
        </For>
      </div>
    </>
  );
};

export default ThemeSettings;
