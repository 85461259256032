import { Component, JSX, Show, createEffect, createSignal } from "solid-js";
import { Transition } from "solid-transition-group";
import Icons from "../icons/Icons";
import {
  popupQueue,
  removePopup,
  selectedDropDown,
  setSelectedDropDown,
} from "~/store/global.store";

interface ModalProps {
  heading: string;
  children: JSX.Element;
  classes: string;
  overlap?: boolean;
  name: string;
  headerPosition?: string;
  jwt_token?: string;
  noOverflow?: boolean;
}

const Popup: Component<ModalProps> = (props) => {
  const [isOpen, setIsOpen] = createSignal(false as boolean);
  createEffect(() => {
    if (popupQueue.length >= 0) {
      if (popupQueue.includes(props.name)) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  });

  return (
    <Transition name="slide-fade">
      <Show when={isOpen()}>
        <div
          class={`popup ${props?.overlap ? "overlap" : ""}`}
          onclick={(e: any) => {
            const element: any = document.getElementById(
              `formDropDown_${selectedDropDown()}`
            );
            if (element?.contains(e.target)) {
            } else {
              setSelectedDropDown("");
            }
          }}
        >
          <div
            class={`popup-dialog ${props.classes} ${
              props?.noOverflow ? "overflow-unset" : ""
            }`}
          >
            <div
              class="popup-close"
              onClick={() => removePopup(props.name, props.jwt_token)}
            >
              <Icons name="x" />
            </div>
            <div
              class={`popup-heading ${
                props?.headerPosition ? "text-center" : ""
              }`}
            >
              <h2 class="title">
                <div class="heading" innerHTML={props.heading}></div>
              </h2>
            </div>
            <br />
            <div
              class={`popup-body ${props?.noOverflow ? "overflow-unset" : ""}`}
            >
              {props.children}
            </div>
          </div>
        </div>
      </Show>
    </Transition>
  );
};
export default Popup;
