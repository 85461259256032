import { Component, Show } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import AddFormFolder from "~/components/global/AddFormFolder";
import SystemLayout from "~/components/layouts/system.layout";
import FormFolderAdd from "~/components/popup/FormFolderAddPopup";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import { setPageTitle } from "~/root";
import { validateUser } from "~/routes/api/validateUser";
import {
  isNewFolder,
  popupQueue,
  removePopup,
  setIsNewFolder,
  setPopup,
} from "~/store/global.store";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;
    return { user_id, jwt_token };
  });
}

const index: Component = () => {
  setPageTitle(`${SITE_TITLE} - Create New Form`);
  const data = useRouteData<typeof routeData>();

  return (
    <main>
      <Show when={popupQueue.includes("formFolderAdd")}>
        <FormFolderAdd
          isFolder={isNewFolder()}
          setValue={(val: boolean) => {
            if (val) {
              removePopup("formFolderAdd");
              document.getElementById("add_form_btn")?.click();
            }
          }}
        ></FormFolderAdd>
      </Show>
      <SystemLayout>
        <div class="flex space-between align-center app-w-50">
          <h2 class="title color">Add Form</h2>
          <div class="flex app-flex gap">
            <button
              class="btn sm secondary"
              onclick={() => {
                setIsNewFolder(true);
                setPopup("formFolderAdd");
              }}
            >
              Create Folder
            </button>
            <button
              class="btn sm primary"
              onclick={() => {
                setIsNewFolder(false);
                setPopup("formFolderAdd");
              }}
            >
              Create Form
            </button>
          </div>
        </div>
        <AddFormFolder
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          isBuilder={true}
        ></AddFormFolder>
      </SystemLayout>
    </main>
  );
};

export default index;
