import { Component, JSX, children, onMount } from "solid-js";
import { createStore } from "solid-js/store";

interface Props {
  content: string;
  children: JSX.Element;
  left?: number | undefined;
  top?: number | undefined;
}
const Popover: Component<Props> = (props) => {
  const child = children(() => props.children);
  function togglePopup() {}
  let popoverRef: HTMLElement | undefined;
  const [client, setClient] = createStore({
    left: 0,
    top: 0,
    width: 0,
    height: 0,
  });
  function setPopover() {
    setClient({
      top:
        popoverRef?.getBoundingClientRect().y + (props?.top ? -props?.top : 0),
      left:
        popoverRef?.getBoundingClientRect().x +
        (props?.left ? -props?.left : 0),
      width: popoverRef?.getBoundingClientRect().width,
      height: popoverRef?.getBoundingClientRect().width,
    });
  }
  onMount(() => {
    setPopover();
    window.addEventListener("resize", () => setPopover());
    window.addEventListener("wheel", () => setPopover());
  });
  return (
    <>
      <span class="popover" ref={popoverRef}>
        <span class="flex align-center" onMouseOver={() => togglePopup()}>
          {child}
        </span>
        <div
          class="popover-content"
          style={{
            "--x": `${Number(client.left.toFixed(0)) + client.width + 5}px`,
            "--y": `${Number(client.top.toFixed(0)) - client.height / 2 + 5}px`,
          }}
          innerHTML={props.content}
        ></div>
      </span>
    </>
  );
};

export default Popover;
