import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import {
  RouteDataArgs,
  useNavigate,
  useParams,
  useRouteData,
} from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import {
  createServerAction$,
  createServerData$,
  redirect,
} from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import Icons from "~/components/icons/Icons";
import { format } from "date-fns";
import BackLink from "~/components/micro/BackLink";
import blurInput from "~/directives/blur-input.directive";
import messages from "~/helpers/message";
import { notify } from "~/components/global/Notification";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "~/routes/api/validateUser";
import Pagination from "~/components/global/Pagination";
import SelectOne from "~/components/global/SelectOne";
import { userFormRoles } from "~/store/global.store";

const inputLbl = blurInput;

export function routeData({ params }: RouteDataArgs) {
  validateUser();
  return createServerData$(
    async ([, userId], { request }) => {
      const userDetails = await getUser(request);
      if (userDetails?.statusCode == 401) {
        throw redirect("/logout");
      }
      const jwtToken = await getAuthToken(request);
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/admin/user/profile`,
        jwt_token: jwtToken as string,
        body: {
          user_id: userId,
          offset: 0,
          limit: 5,
          user_offset: 0,
          user_limit: 5,
          list: true,
          sort_logs: "desc",
        },
      };
      const getUserInfo = await fetchApi(apiParams);
      if (getUserInfo.statusCode == 401) {
        throw redirect("/logout");
      }
      const userInfo = getUserInfo.data;
      let totalSubscriptionQuantity: any =
        getUserInfo.data.totalSubscriptionQuantity;

      return { userId, jwtToken, userInfo, totalSubscriptionQuantity };
    },
    { key: () => ["user_profile", params.userId] }
  );
}

const details: Component = () => {
  setPageTitle(`${SITE_TITLE} - Admin User Profile`);
  const data: any = useRouteData<typeof routeData>();
  const { userId } = useParams<{ userId: string }>();
  const [userIdField] = createSignal(userId as string);
  const [userDetails, setUserDetails] = createSignal("licences" as string);
  const [orderMetaData, setOrderMetaData] = createSignal([] as any);
  const [ordersList, setOrdersList] = createSignal([] as any);
  const [userLogs, setUserLogs] = createSignal([] as any);
  const [currentOrdersListPage, setCurrentOrdersListPage] = createSignal(
    1 as number
  );
  const [subUsersList, setSubUsersList] = createSignal("" as any);
  const [currentUsersListPage, setCurrentUsersListPage] = createSignal(
    1 as number
  );
  const [currentLogListPage, setCurrentLogListPage] = createSignal(1 as number);
  const [totalPages, setTotalPages] = createSignal(1 as number);
  const [sortString, setSortString] = createSignal("" as any);
  const [sortLogsString, setSortLogsString] = createSignal("desc" as any);
  const navigate = useNavigate();

  async function filterUsersList(
    filterString: any,
    user_id: string,
    jwt_token: string,
    offset: number
  ) {
    setSortString(filterString);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/user/profile`,
      jwt_token,
      body: {
        user_id,
        user_offset: offset,
        user_limit: 5,
        sort_string: filterString,
      },
    };
    const getUserInfo = await fetchApi(apiParams);
    if (getUserInfo?.statusCode == 401) {
      window.location.href = "/logout";
    }
    if (getUserInfo && getUserInfo.status) {
      setSubUsersList(getUserInfo.data.licences);
    }
  }

  async function filterLogsList(
    user_id: string,
    jwt_token: string,
    logs_offset: number
  ) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/user/profile`,
      jwt_token,
      body: {
        user_id,
        offset: 0,
        limit: 5,
        user_offset: 0,
        user_limit: 5,
        sort_logs: sortLogsString(),
        logs_offset,
      },
    };
    const getUserInfo = await fetchApi(apiParams);
    if (getUserInfo?.statusCode == 401) {
      window.location.href = "/logout";
    }
    if (getUserInfo && getUserInfo.status) {
      setUserLogs(getUserInfo.data?.logs);
    }
  }

  const [userDetailsUpdate, { Form }] = createServerAction$(
    async (form: FormData, { request }) => {
      try {
        const jwtToken = await getAuthToken(request);
        const user_id = form.get("user_id");
        const email = form.get("email");
        const first_name = form.get("first_name");
        const last_name = form.get("last_name");
        const company_name = form.get("company_name");
        const apiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/admin/user/profile/update`,
          jwt_token: jwtToken as string,
          body: {
            email,
            user_id,
            first_name,
            last_name,
            company_name,
          },
        };
        const updateProfile = await fetchApi(apiParams);
        if (
          updateProfile &&
          updateProfile.status &&
          updateProfile.status != 401
        ) {
          return "success";
        } else {
          return "error";
        }
      } catch (err) {
        return "error";
      }
    }
  );

  createEffect(() => {
    if (data()?.userInfo?.subscriptionOrders) {
      setCurrentOrdersListPage(1);
      setOrdersList(
        data()?.userInfo?.subscriptionOrders.data?.length > 1
          ? data()?.userInfo?.subscriptionOrders.data.filter(
              (item: any) => item.invoice_id
            )
          : data()?.userInfo?.subscriptionOrders.data
      );
      setOrderMetaData([data()?.userInfo?.subscriptionOrders.meta]);
      setTotalPages(
        Math.ceil(
          data()?.userInfo?.subscriptionOrders?.meta?.pagination
            .estimated_total / 5
        )
      );
    }
    if (data()?.userInfo?.logs) {
      setUserLogs(data()?.userInfo?.logs);
    }
    if (userDetailsUpdate.result) {
      const predictResult = {
        success: {
          res: messages.user.profile_update,
          theme: "success",
        },
        error: { res: messages.error.profile_update, theme: "danger" },
      };
      notify({
        isNotified: true,
        message: predictResult[userDetailsUpdate.result].res as string,
        theme: predictResult[userDetailsUpdate.result].theme as string,
        placement: "top",
        timeout: 2500,
      });
      setTimeout(() => {
        navigate("/admin/users");
      }, 2500);
    }
  });

  function handlePageChange(page: any) {
    setTimeout(async () => {
      if (currentOrdersListPage() != page) {
        setCurrentOrdersListPage(page);
        const subscriptionDetailsApiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/admin/user/profile`,
          jwt_token: data()?.jwtToken as string,
          body: {
            user_id: data()?.userId,
            list: true,
            nextPageUrl:
              page > 1 ? orderMetaData()[page - 2]?.pagination.next : null,
          },
        };
        const subscriptionDetails = await fetchApi(
          subscriptionDetailsApiParams
        );
        if (subscriptionDetails?.statusCode == 401) {
          window.location.href = "/logout";
        }
        if (subscriptionDetails && subscriptionDetails?.status) {
          if (page > 1) {
            setOrdersList(
              subscriptionDetails.data.data.length > 1
                ? subscriptionDetails.data.data.filter(
                    (item: any) => item.invoice_id
                  )
                : subscriptionDetails.data.data
            );
            setOrderMetaData([
              ...orderMetaData(),
              subscriptionDetails.data.meta,
            ]);
          } else {
            setOrdersList(
              subscriptionDetails.data.subscriptionOrders.data.length > 1
                ? subscriptionDetails.data.subscriptionOrders.data.filter(
                    (item: any) => item.invoice_id
                  )
                : subscriptionDetails.data.subscriptionOrders.data
            );
            setOrderMetaData([
              subscriptionDetails.data.subscriptionOrders.meta,
            ]);
          }
        }
      }
    }, 0);
  }

  return (
    <main>
      <SystemLayout>
        <div class="center">
          <div class="flex gap-2">
            <div class="w-30">
              <div>
                <h3 class="title">User Profile</h3>
              </div>
              <div>
                <Form class="form">
                  <input type="hidden" name="user_id" value={userIdField()} />
                  <div class="form-element">
                    <input
                      type="text"
                      class="input"
                      name="first_name"
                      placeholder="First Name"
                      value={data()?.userInfo?.first_name}
                      use:inputLbl
                    />
                  </div>
                  <br />
                  <div class="form-element">
                    <input
                      type="text"
                      class="input"
                      name="last_name"
                      placeholder="Last Name"
                      value={data()?.userInfo?.last_name}
                      use:inputLbl
                    />
                  </div>
                  <br />
                  <div class="form-element">
                    <input
                      type="text"
                      class="input"
                      name="email"
                      placeholder="Email"
                      value={data()?.userInfo?.email}
                      use:inputLbl
                    />
                  </div>
                  <br />
                  <div class="form-element">
                    <input
                      type="text"
                      class="input"
                      name="company_name"
                      placeholder="Company Name"
                      value={data()?.userInfo?.company_name}
                      use:inputLbl
                    />
                  </div>
                  <br />
                  <button type="submit" class="btn primary">
                    Update
                  </button>
                  <br />
                  <br />
                  <BackLink link="/admin/users"></BackLink>
                </Form>
              </div>
            </div>
            <div class="w-68">
              <div>
                <h3 class="title">Plan Details:</h3>
              </div>
              <Show when={data()?.userInfo?.plan_name}>
                <div class="flex gap">
                  <div>
                    <label>Plan Name: </label>
                    <div class="badge">
                      <span>
                        {data()?.userInfo?.plan_name
                          ? data()?.userInfo?.plan_name
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div>
                    <label>Subscription Id: </label>
                    <div class="badge">
                      <span>
                        {data()?.userInfo?.user_subscription_id
                          ? data()?.userInfo?.user_subscription_id
                          : ""}
                      </span>
                    </div>
                  </div>
                  <div>
                    <label>User Subcribed At: </label>
                    <div class="badge">
                      <span>
                        {data()?.userInfo?.created_at
                          ? format(
                              new Date(data()?.userInfo?.created_at),
                              "MMMM,dd yyyy"
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
                <br />
              </Show>
              <Show
                when={
                  data()?.userInfo?.plan_name == "Team" ||
                  data()?.userInfo?.plan_name == "Team Annual"
                }
              >
                <div class="flex gap">
                  <div class="badge">
                    <span>
                      <strong>{data()?.totalSubscriptionQuantity}</strong>{" "}
                      Licenses Purchased
                    </span>
                    &nbsp; | &nbsp;
                    <span>
                      <strong>{data()?.userInfo?.toalLicences}</strong> Users
                      Assigned
                    </span>
                    &nbsp; | &nbsp;
                    <span>
                      <strong>
                        {data()?.totalSubscriptionQuantity -
                          data()?.userInfo?.toalLicences}
                      </strong>{" "}
                      Users Remaining
                    </span>
                  </div>
                  <div class="badge">
                    <span>
                      <strong>
                        {data()?.userInfo?.storage_used > 1000
                          ? (data()?.userInfo?.storage_used / 1000).toFixed(1) +
                            " GB"
                          : data()?.userInfo?.storage_used.toFixed(1) + " MB"}
                      </strong>
                      &nbsp; Storage Used
                    </span>
                  </div>
                </div>
                <br />
              </Show>
              <div class="w-68">
                <SelectOne
                  class="full-width"
                  set={[
                    { display: "User Licences:", name: "licences" },
                    { display: "Purchase History:", name: "purchase" },
                    { display: "Logs:", name: "logs" },
                  ]}
                  active={userDetails()}
                  setActive={(val: string) => {
                    setUserDetails(val);
                  }}
                ></SelectOne>
              </div>
              <br />
              <Show when={userDetails() == "licences"}>
                <div class="w-100">
                  <table class="table w-100">
                    <thead>
                      <tr>
                        <th>
                          <span>User</span>
                          <span
                            class="float-right cur-p"
                            onclick={() => {
                              filterUsersList(
                                sortString() == "" || sortString() == "asc_user"
                                  ? "desc_user"
                                  : "asc_user",
                                data()?.userId,
                                data()?.jwtToken,
                                0
                              );
                            }}
                          >
                            <Icons
                              name="arrowsSort"
                              height={16}
                              width={16}
                            ></Icons>
                          </span>
                        </th>
                        <th>
                          <span>Email</span>
                          <span
                            class="float-right cur-p"
                            onclick={() => {
                              filterUsersList(
                                sortString() == "" ||
                                  sortString() == "asc_email"
                                  ? "desc_email"
                                  : "asc_email",
                                data()?.userId,
                                data()?.jwtToken,
                                0
                              );
                            }}
                          >
                            <Icons
                              name="arrowsSort"
                              height={16}
                              width={16}
                            ></Icons>
                          </span>
                        </th>
                        <th>Role</th>
                        <th>
                          <span>User Added At</span>
                          <span
                            class="float-right cur-p"
                            onclick={() => {
                              filterUsersList(
                                sortString() == "" ||
                                  sortString() == "asc_created"
                                  ? "desc_created"
                                  : "asc_created",
                                data()?.userId,
                                data()?.jwtToken,
                                0
                              );
                            }}
                          >
                            <Icons
                              name="arrowsSort"
                              height={16}
                              width={16}
                            ></Icons>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <For
                        each={
                          subUsersList()
                            ? subUsersList()
                            : data()?.userInfo?.licences
                        }
                      >
                        {(licence) => (
                          <tr>
                            <td>{licence.username}</td>
                            <td>{licence.email}</td>
                            <td>
                              {userFormRoles.find(
                                (obj: any) =>
                                  obj.value ==
                                  licence.user_role[0].role.role_name
                              )?.label ?? "Admin"}
                            </td>
                            <td>
                              {format(
                                new Date(licence.created_at),
                                "MMMM,dd yyyy"
                              )}
                            </td>
                          </tr>
                        )}
                      </For>
                    </tbody>
                  </table>
                  <Show when={data()?.userInfo?.totalUserPages > 1}>
                    <Pagination
                      currentPage={currentUsersListPage()}
                      totalPages={data()?.userInfo?.totalUserPages}
                      onPageChange={async (page: any) => {
                        let offset: number = 0;
                        if (page != 1) {
                          offset = (page - 1) * 5;
                        }
                        const apiParams = {
                          method: "POST" as string,
                          url: `${
                            import.meta.env.VITE_API_URL
                          }/admin/user/profile`,
                          jwt_token: data()?.jwtToken,
                          body: {
                            user_id: data()?.userId,
                            user_offset: offset,
                            user_limit: 5,
                          },
                        };
                        const getUserInfo = await fetchApi(apiParams);
                        if (getUserInfo?.statusCode == 401) {
                          window.location.href = "/logout";
                        }
                        if (getUserInfo && getUserInfo.status) {
                          setSubUsersList(getUserInfo.data.licences);
                          setCurrentUsersListPage(page);
                        }
                      }}
                      itemsPerPage={5}
                      totalItems={data()?.userInfo?.totalUserItems}
                      itemPerPageFilter={true}
                    ></Pagination>
                  </Show>
                </div>
              </Show>
              <Show when={userDetails() == "purchase"}>
                <div class="w-100">
                  <table class="table w-100">
                    <thead>
                      <tr>
                        <th>
                          <span>Order Id</span>
                        </th>
                        <th>
                          <span>Quantity</span>
                        </th>
                        <th>Receipt</th>
                        <th>
                          <span>Subscription Date</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <Show
                        when={
                          !data()?.userInfo?.subscriptionOrders.data ||
                          data()?.userInfo?.subscriptionOrders.data.length == 0
                        }
                      >
                        <tr class="text-center">
                          <td colspan="4">
                            <small>
                              <b>No Suscription Details Found !!</b>
                            </small>
                          </td>
                        </tr>
                      </Show>
                      <For
                        each={
                          ordersList()
                            ? ordersList()
                            : data()?.userInfo?.subscriptionOrders
                        }
                      >
                        {(order: any) => (
                          <tr>
                            <td>{order.id}</td>
                            <td>
                              {order.items
                                .map((item: any) => Number(item.quantity))
                                .reduce((acc: any, curr: any) => acc + curr, 0)}
                            </td>
                            <td>
                              <button
                                class="btn primary sm"
                                onclick={async () => {
                                  let url: any = "";
                                  const apiParams = {
                                    method: "POST" as string,
                                    url: `${
                                      import.meta.env.VITE_API_URL
                                    }/subscription/transcation/url`,
                                    jwt_token: data()?.jwtToken,
                                    body: {
                                      transaction_id: order.id,
                                    },
                                  };
                                  const transcation = await fetchApi(apiParams);
                                  if (transcation?.statusCode == 401) {
                                    window.location.href = "/logout";
                                  }
                                  if (transcation.status) {
                                    url = transcation.data;
                                  }
                                  if (url) {
                                    const a: any = document.createElement("a");
                                    a.href = url;
                                    a.target = "_blank";
                                    a.download = `invoice-${order.subscription_order_id}`;
                                    document.body.appendChild(a);
                                    a?.click();
                                    document.body.removeChild(a);
                                  } else {
                                    notify({
                                      isNotified: true,
                                      message:
                                        messages.warn
                                          .transcation_invoice_in_trial,
                                      theme: "warm",
                                      placement: "top",
                                      timeout: 2500,
                                    });
                                  }
                                }}
                              >
                                <Icons
                                  name="document"
                                  stroke="#fff"
                                  width={18}
                                  height={18}
                                />
                              </button>
                            </td>
                            <td>
                              {format(
                                new Date(order.created_at),
                                "MMMM,dd yyyy"
                              )}
                            </td>
                          </tr>
                        )}
                      </For>
                    </tbody>
                  </table>
                  <Show when={orderMetaData()[0]?.pagination?.has_more}>
                    <Pagination
                      currentPage={currentOrdersListPage()}
                      totalPages={totalPages()}
                      onPageChange={async (page: any) => handlePageChange(page)}
                      itemsPerPage={5}
                      totalItems={
                        orderMetaData()[0]?.pagination?.estimated_total
                      }
                      itemPerPageFilter={true}
                      noPageNo={true}
                    ></Pagination>
                  </Show>
                </div>
              </Show>
              <Show when={userDetails() == "logs"}>
                <div class="w-100">
                  <table class="table w-100">
                    <thead>
                      <tr>
                        <th>
                          <span>Message</span>
                        </th>
                        <th class="w-200">
                          <span>Done At</span>
                          <span
                            class="float-right cur-p"
                            onclick={() => {
                              setSortLogsString(
                                sortLogsString() == "asc" ? "desc" : "asc"
                              );
                              setCurrentLogListPage(1);
                              filterLogsList(
                                data()?.userId,
                                data()?.jwtToken,
                                0
                              );
                            }}
                          >
                            <Icons
                              name="arrowsSort"
                              height={16}
                              width={16}
                            ></Icons>
                          </span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <Show
                        when={
                          !data()?.userInfo?.logs ||
                          data()?.userInfo?.logs.length == 0
                        }
                      >
                        <tr class="text-center">
                          <td colspan="4">
                            <small>
                              <b>No Logs Found !!</b>
                            </small>
                          </td>
                        </tr>
                      </Show>
                      <For
                        each={userLogs() ? userLogs() : data()?.userInfo?.logs}
                      >
                        {(log: any) => (
                          <tr>
                            <td innerHTML={log.message}></td>
                            <td>
                              {format(
                                new Date(log.created_at),
                                "MMMM,dd yyyy HH:mm"
                              )}
                            </td>
                          </tr>
                        )}
                      </For>
                    </tbody>
                  </table>
                  <Show when={userLogs()?.length > 0}>
                    <Pagination
                      currentPage={currentLogListPage()}
                      totalPages={data()?.userInfo?.totalLogsPages}
                      onPageChange={async (page: any) => {
                        if (currentLogListPage() != page) {
                          setCurrentLogListPage(page);
                          let offset: number = 0;
                          if (page != 1) {
                            offset = (page - 1) * 5;
                          }
                          filterLogsList(
                            data()?.userId,
                            data()?.jwtToken,
                            offset
                          );
                        }
                      }}
                      itemsPerPage={5}
                      totalItems={data()?.userInfo?.totalLogsItems}
                      itemPerPageFilter={true}
                    ></Pagination>
                  </Show>
                </div>
              </Show>
            </div>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default details;
