export default function convert(value: number, operationType: string) {
  let result;
  switch (operationType) {
    case "in-px":
      result = value * 96;
      break;
    case "px-in":
      result = value / 96;
  }
  return result;
}
