import { onCleanup, createEffect, Component } from "solid-js";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_blue.css";
import {
  additionalData,
  preLoadData,
  setAdditionalData,
  updateFormRecords,
  updatedCalulatedFieldvalue,
} from "~/store/records.store";
import {
  addContectMenu,
  formJson,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import generateCSS from "~/helpers/generator";
import {
  dateFormats,
  dateFormats12Hrs,
  datePickerFormat,
  isFormDropDownOpen,
  isMouseDown,
  multipleSelectField,
  setIsMouseDown,
  timeFormats,
} from "~/store/global.store";
import enUS from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
import { format, parse } from "date-fns";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";

interface Props {
  type: string;
  properties: any;
  fieldProperties: any;
}

const DatePicker: Component<Props> = (props: any) => {
  let inputRef: any;

  createEffect(() => {
    if (
      (props.type == "render" || props.type == "locked") &&
      // props.properties.dateInputType != "linked" &&
      props.properties.dateInputType != "calculated" &&
      !(
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      )
    ) {
      let utcDate: any;
      if (
        preLoadData()[props.properties.dataFieldSync.uid] &&
        preLoadData()[props.properties.dataFieldSync.uid] != ""
      ) {
        const date =
          props.properties.dateInputType == "linked"
            ? new Date(
                preLoadData()[props.properties.dataFieldSync.linkedFieldId]
              ) ?? ""
            : new Date(preLoadData()[props.properties.dataFieldSync.uid]);
        utcDate = new Date(
          date.getUTCFullYear(),
          date.getUTCMonth(),
          date.getUTCDate(),
          date.getHours(),
          date.getMinutes()
        );
      } else {
        inputRef.value = "";
      }
      const options: any = {
        theme: "material_blue",
        enableTime:
          props.properties.dateInputType == "date_time" ||
          props.properties.dateInputType == "time"
            ? true
            : false,
        noCalendar: props.properties.dateInputType == "time" ? true : false,
        defaultDate:
          preLoadData()[props.properties.dataFieldSync.uid] &&
          preLoadData()[props.properties.dataFieldSync.uid] != ""
            ? props.properties.dateInputType == "time"
              ? new Date(
                  `01/01/1970  ${
                    preLoadData()[props.properties.dataFieldSync.uid]
                  }`
                )
              : utcDate
            : null,
        dateFormat: datePickerFormat[props.properties.dateFormat],
        allowInput: true,
        clickOpens: true,
        time_24hr: dateFormats12Hrs.includes(props.properties?.dateFormat)
          ? false
          : true,
        onClose: () => {
          const inputValue = inputRef.value;
          if (props.properties.dateFormat.includes("h")) {
            switch (props.properties.dateFormat) {
              case "hh:mm a":
                if (inputValue.split(":")[0] < 10) {
                  inputRef.value = "0" + inputValue;
                }
                break;
              default:
                if (inputValue.split("at ")[1]?.split(":")[0] < 10) {
                  inputRef.value = inputValue.replace(
                    "at " + inputValue.split("at ")[1],
                    "at " +
                      "0" +
                      inputValue.split("at ")[1].split(":")[0] +
                      ":" +
                      inputValue.split("at ")[1].slice(2)
                  );
                }
                break;
            }
          }
          let locale = enUS;
          if (props.properties.dateFormat.includes("MM")) {
            locale = enUS;
          } else if (props.properties.dateFormat.includes("dd")) {
            locale = fr;
          }
          const parsedDate = parse(
            inputRef.value,
            props.properties.dateFormat,
            new Date(),
            { locale }
          );
          let value: any = "";
          if (inputValue) {
            if (dateFormats.includes(props.properties.dateFormat)) {
              value = format(parsedDate, "yyyy-MM-dd");
            } else if (timeFormats.includes(props.properties.dateFormat)) {
              value = format(parsedDate, "HH:mm");
            } else {
              value = format(parsedDate, "yyyy-MM-dd HH:mm");
            }
          }
          const dataArr: any = { ...additionalData() };
          dataArr[props.properties.dataFieldSync.uid] = {
            display_value: inputValue,
          };
          setAdditionalData(dataArr);
          if (
            formJson.pages.flatMap((page: any) =>
              page.fields?.filter(
                (field: any) =>
                  field.properties.dateInputType == "calculated" &&
                  field.properties.calculationFormula != ""
              )
            ).length > 0
          ) {
            updateFormRecords(
              props.properties.dataFieldSync.uid,
              value,
              false,
              false,
              {
                display_value: inputValue,
              }
            );
            updatedCalulatedFieldvalue();
          } else {
            updateFormRecords(
              props.properties.dataFieldSync.uid,
              value,
              false,
              false,
              {
                display_value: inputValue,
              }
            );
          }
        },
      };
      const picker = flatpickr(inputRef, options);
      onCleanup(() => {
        if (picker) picker.destroy();
      });
    }
  });

  return (
    <>
      <input
        type="text"
        ref={inputRef}
        class={`field input-field ${
          String(props.properties.dataFieldSync.parentTableId) != "0"
            ? "z-index-1"
            : "z-index-0"
        } ${props.type == "designer" ? "cur-p builder-field-border" : ""} ${
          props.type == "preview" || props.type == "locked"
            ? "pointer-none"
            : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        } ${isFormDropDownOpen() ? "z-index--1" : ""}`}
        style={generateCSS(props.fieldProperties)}
        // value={
        //   (props.type == "render" || props.type == "locked") &&
        //   props.properties.dateInputType == "linked"
        //     ? preLoadData()[props.properties.dataFieldSync.linkedFieldId] ?? ""
        //     : preLoadData()[props.properties.dataFieldSync.uid]
        //     ? preLoadData()[props.properties.dataFieldSync.uid]
        //     : (formJson.currentField?.inputType == "calculated" ||
        //         formJson.currentField?.dateInputType == "calculated") &&
        //       props.type != "render"
        //     ? calculatedArr()[props.properties.dataFieldSync.uid]
        //       ? calculatedArr()[props.properties.dataFieldSync.uid]
        //       : ""
        //     : ""
        // }
        readonly={props.type != "render" ? true : false}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) => {
          if (props.type != "render") {
            multipleSelectField() || event.shiftKey
              ? String(props.properties.dataFieldSync.parentTableId) == "0"
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : ""
              : updatedCurrentField(props.properties.dataFieldSync.uid);
          }
        }}
        oncontextmenu={(event: any) => {
          if (props.type == "designer") {
            multipleSelectField() || event.shiftKey
              ? String(props.properties.dataFieldSync.parentTableId) == "0"
                ? updatedMultipleFields(props.properties.dataFieldSync.uid)
                : ""
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
        disabled={
          (props.type == "render" &&
            props.properties.dataFieldSync.linkedFieldId != 0) ||
          (props.type == "render" &&
            props.properties.dateInputType == "calculated")
            ? true
            : false
        }
        autocomplete="off"
      />
    </>
  );
};

export default DatePicker;
