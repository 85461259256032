import { Component, Show, createEffect } from "solid-js";
import { createStore } from "solid-js/store";
import generateCSS from "~/helpers/generator";
import { assignFieldProperties } from "~/helpers/form.helper";
import {
  addContectMenu,
  updatedCurrentField,
  updatedMultipleFields,
} from "~/store/form.store";
import {
  isMouseDown,
  multipleSelectField,
  selectedDropDown,
  setIsMouseDown,
} from "~/store/global.store";
import convert from "~/helpers/convertor";
import {
  fieldMovement,
  selectedFields,
  setFieldMovement,
  setIsGuidline,
} from "../builder/Guidline";

interface Props {
  properties: any;
  fieldsType: string;
}

const LineField: Component<Props> = (props) => {
  const [fieldProperties, setFieldProperties] = createStore({});

  createEffect(() => {
    if (props.properties.dataFieldSync) {
      setFieldProperties(assignFieldProperties(props.properties));
    }
  });

  if (props.fieldsType) {
    setFieldProperties(assignFieldProperties(props.properties));
  }

  return (
    <>
      <Show when={props.fieldsType == "designer"}>
        <div
          class="pa builder-field-border z-index-1"
          style={{
            left:
              Number(
                convert(props.properties.dataFieldSync.frame[0][0], "in-px")
              ) -
              21 +
              "px",
            top:
              convert(props.properties.dataFieldSync.frame[0][1], "in-px") +
              "px",
            width:
              convert(props.properties.dataFieldSync.frame[1][0], "in-px") +
              "px",
            height:
              convert(props.properties.dataFieldSync.frame[1][1], "in-px") +
              "px",
          }}
          onclick={(event: any) =>
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid)
          }
          oncontextmenu={(event: any) => {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }}
        ></div>
      </Show>
      <div
        style={generateCSS(fieldProperties)}
        class={`pa ${
          props.fieldsType != "render" &&
          props.fieldsType != "locked" &&
          props.fieldsType != "preview"
            ? "cur-p"
            : ""
        } ${
          selectedFields().length > 0 &&
          !selectedFields().includes(props.properties.dataFieldSync.uid)
            ? "pointer-none"
            : ""
        } ${selectedDropDown() != "" ? "z-index-0" : "z-index-1"}`}
        onMouseDown={() => setIsMouseDown(true)}
        onMouseMove={() => {
          if (isMouseDown() && fieldMovement() == "") {
            updatedCurrentField(props.properties.dataFieldSync.uid);
            setIsGuidline(true);
            setFieldMovement("move");
          }
        }}
        onMouseUp={() => setIsMouseDown(false)}
        onclick={(event: any) =>
          multipleSelectField() || event.shiftKey
            ? updatedMultipleFields(props.properties.dataFieldSync.uid)
            : updatedCurrentField(props.properties.dataFieldSync.uid)
        }
        oncontextmenu={(event: any) => {
          if (props.fieldsType == "designer") {
            multipleSelectField() || event.shiftKey
              ? updatedMultipleFields(props.properties.dataFieldSync.uid)
              : updatedCurrentField(props.properties.dataFieldSync.uid);
            addContectMenu(event);
          }
        }}
      ></div>
    </>
  );
};

export default LineField;
