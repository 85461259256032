import { A } from "@solidjs/router";
import { createSignal, For, onMount, Show } from "solid-js";
import { Transition } from "solid-transition-group";
import Icons from "../icons/Icons";
import {
  jwtToken,
  unReadNotificationCount,
  userId,
  userRole,
} from "~/store/user-form-role";
import {
  isNewFolder,
  popupQueue,
  removePopup,
  setClearFormSearch,
  setIsFormDropDownOpen,
  setIsNewFolder,
  setPopup,
  setSelectedDropDown,
  sidebarOpen,
} from "~/store/global.store";
import FormFolderAdd from "../popup/FormFolderAddPopup";
import AddFormFolder from "../global/AddFormFolder";

const [sidebarList, setSidebarList] = createSignal([] as any);

const Sidebar = () => {
  const [isDropdownOpen, setIsDropdownOpen] = createSignal([] as any);
  const [hasTransition, setHasTransition] = createSignal(false as boolean);

  onMount(() => {
    if (window.localStorage.getItem("fc_form_dd") == "true") {
      setIsDropdownOpen([...isDropdownOpen(), "form"]);
    }
    if (window.localStorage.getItem("fc_help_dd") == "true") {
      setIsDropdownOpen([...isDropdownOpen(), "help"]);
    }
    if (window.localStorage.getItem("fc_account_dd") == "true") {
      setIsDropdownOpen([...isDropdownOpen(), "account"]);
    }
  });

  function toggleDropdown(type: string) {
    setHasTransition(true);
    if (!isDropdownOpen().includes(type)) {
      window.localStorage.setItem(
        type == "form"
          ? "fc_form_dd"
          : type == "help"
          ? "fc_help_dd"
          : "fc_account_dd",
        "true"
      );
      setIsDropdownOpen([...isDropdownOpen(), type]);
    } else {
      window.localStorage.setItem(
        type == "form"
          ? "fc_form_dd"
          : type == "help"
          ? "fc_help_dd"
          : "fc_account_dd",
        ""
      );
      setIsDropdownOpen(isDropdownOpen().filter((item: any) => item != type));
    }
    setTimeout(() => {
      setHasTransition(false);
    }, 200);
  }

  return (
    <>
      <Show when={popupQueue.includes("formFolderAdd")}>
        <FormFolderAdd
          isFolder={isNewFolder()}
          setValue={(val: boolean) => {
            if (val) {
              removePopup("formFolderAdd");
              document.getElementById("add_form_btn")?.click();
            }
          }}
        ></FormFolderAdd>
      </Show>
      <div
        class={`sidebar ${sidebarOpen() ? "toggle" : ""}`}
        onclick={(e: any) => {
          setSelectedDropDown("");
          setIsFormDropDownOpen(false);
        }}
      >
        <ul class="sidebar-list">
          <For each={sidebarList()}>
            {(obj: any) => (
              <>
                {/* <Show when={obj.label == "Form Management" || obj.label == "Help"}> */}
                <li
                  class={`sidebar-item sidebar-dropdown ${
                    (obj.label == "Form Management" &&
                      isDropdownOpen().includes("form")) ||
                    (obj.label == "Help" &&
                      isDropdownOpen().includes("help")) ||
                    (obj.label == "Manage Account" &&
                      isDropdownOpen().includes("account"))
                      ? "active"
                      : ""
                  } ${
                    userRole() != "filler" && userRole() != "viewer"
                      ? "toggle"
                      : "toggle"
                  }`}
                >
                  <Show when={obj.label != "Help"}>
                    <A
                      href={obj.link}
                      class={
                        (obj.label == "Form Management" &&
                          isDropdownOpen().includes("form")) ||
                        (obj.label == "Manage Account" &&
                          isDropdownOpen().includes("account"))
                          ? "non-active"
                          : ""
                      }
                      onClick={() => {
                        toggleDropdown(
                          obj.label == "Form Management" ? "form" : "account"
                        );
                        setClearFormSearch(true);
                      }}
                    >
                      <Icons name={obj.icon} />
                      {obj.label}
                    </A>
                  </Show>
                  <Show when={obj.label == "Help"}>
                    <A
                      href={obj.link}
                      class={
                        !isDropdownOpen().includes("help") ? "non-active" : ""
                      }
                      onClick={() => {
                        toggleDropdown("help");
                        setClearFormSearch(true);
                      }}
                    >
                      <Icons name={obj.icon} />
                      {obj.label}
                    </A>
                  </Show>
                  <span
                    class="sidebar-dropdown-span"
                    onClick={() =>
                      toggleDropdown(
                        obj.label == "Form Management"
                          ? "form"
                          : obj.label == "Help"
                          ? "help"
                          : "account"
                      )
                    }
                  ></span>
                  <Transition name={`${hasTransition() ? "slide-fade" : ""}`}>
                    <Show
                      when={
                        (obj.label == "Form Management" &&
                          isDropdownOpen().includes("form")) ||
                        (obj.label == "Help" &&
                          isDropdownOpen().includes("help")) ||
                        (obj.label == "Manage Account" &&
                          isDropdownOpen().includes("account"))
                      }
                    >
                      <ul class="sidebar-dropdown__list">
                        <For each={obj?.subList}>
                          {(subObj: any) => (
                            <>
                              <Show when={subObj?.link}>
                                <li>
                                  <A
                                    href={subObj.link}
                                    onclick={() => setClearFormSearch(true)}
                                  >
                                    <Icons name={subObj.icon} />
                                    {subObj.label == "Notifications"
                                      ? `${
                                          subObj.label
                                        } (${unReadNotificationCount()})`
                                      : subObj.label}
                                  </A>
                                </li>
                              </Show>
                              <Show when={subObj?.popup}>
                                <li>
                                  <a
                                    href="#"
                                    onclick={() => {
                                      if (subObj.icon == "folderAdd") {
                                        setIsNewFolder(true);
                                      } else {
                                        setIsNewFolder(false);
                                      }
                                      setPopup(subObj?.popup);
                                    }}
                                  >
                                    <Icons name={subObj.icon} />
                                    {subObj.label}
                                  </a>
                                </li>
                              </Show>
                            </>
                          )}
                        </For>
                      </ul>
                    </Show>
                  </Transition>
                </li>
                {/* </Show> */}
                {/* <Show when={obj.label != "Forms" && obj.label != "Help"}>
                  <li class="sidebar-item">
                    <A
                      href={obj.link}
                      target={
                        ["help", "tip", "contact"].includes(obj.icon)
                          ? "_blank"
                          : ""
                      }
                    >
                      <Icons name={obj.icon} />
                      {obj.label}
                    </A>
                  </li>
                </Show> */}
              </>
            )}
          </For>
        </ul>
      </div>
      <AddFormFolder
        user_id={userId()}
        jwt_token={jwtToken()}
        isBuilder={false}
      ></AddFormFolder>
    </>
  );
};

export { sidebarList, setSidebarList };
export default Sidebar;
