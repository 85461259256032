import { Component, Show, createEffect, createSignal, onMount } from "solid-js";
import { useNavigate, useRouteData, useSearchParams } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Pricing from "~/components/global/Pricing";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { setPageTitle } from "~/root";
import { initializePaddle, Paddle } from "@paddle/paddle-js";
import AuthLayout, {
  setCheckoutData,
  setHasTrial,
} from "~/components/layouts/auth.layout";

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const jwtToken = await getAuthToken(request);
    if (!jwtToken) return redirect("/");

    let userInfo = await getUser(request);
    if (userInfo.data.role.role_name == "super_admin") {
      throw redirect("/admin/dashboard");
    } else {
      if (
        userInfo !== null &&
        userInfo.data?.subscription &&
        userInfo.data?.subscription?.plan_name !== "" &&
        userInfo.data?.subscription?.state !== "cancelled"
      ) {
        if (userInfo.data.role.role_name == "admin") {
          throw redirect("/form/list");
        } else {
          if (userInfo.data.has_set_password) {
            return redirect("/form/list");
          } else {
            return redirect("/change-password");
          }
        }
      }
    }
    userInfo = userInfo.data;

    const paddleInfo: any = {
      token: process.env.PADDLE_TOKEN,
      teamPlanId: process.env.PADDLE_TEAM_PLAN_ID,
      teamAnnualPlanId: process.env.PADDLE_TEAM_ANNUAL_PLAN_ID,
    };

    const planApiParams = {
      method: "GET" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/pricing`,
    };
    let plans: any = await fetchApi(planApiParams);
    if (plans?.statusCode == 401) {
      throw redirect("/logout");
    }
    return { jwtToken, userInfo, paddleInfo, plans };
  });
}

const pricing: Component = () => {
  const environmentPaddle = import.meta.env["VITE_PADDLE_ENVIRONMENT"];
  setPageTitle(`${SITE_TITLE} - Pricing`);
  const data: any = useRouteData<typeof routeData>();
  const [planName, setPlanName] = createSignal("" as any);
  const [paddle, setPaddle] = createSignal<Paddle>();
  const [isLoaded, setIsLoaded] = createSignal(false as boolean);
  const [userInfo, setUserInfo] = createSignal({
    id: data()?.userInfo?.id,
    jwt_token: data()?.jwtToken,
  } as any);

  const navigate: any = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  onMount(() => {
    appendPaddlePopup();
  });

  createEffect(() => {
    if (searchParams.plan) {
      setTimeout(() => {
        appendPaddlePopup();
      });
    }
    if (searchParams.quantity) {
      setTimeout(() => {
        appendPaddlePopup();
      });
    }
    if (data()?.userInfo.subscription) {
      setHasTrial(false);
    } else {
      setHasTrial(true);
    }
    if (data()?.userInfo) {
      setUserInfo({
        id: data()?.userInfo?.id,
        jwt_token: data()?.jwtToken,
      });
    }
  });

  function appendPaddlePopup() {
    var urlString = new URLSearchParams(window.location.search);
    let quantity: any =
      urlString.has("quantity") && Number(urlString.get("quantity")) > 1
        ? urlString.get("quantity")
        : 1;
    let plan: any = urlString.get("plan");
    setPlanName(plan);
    if (
      quantity <= data()?.userInfo?.assignedLicences &&
      (plan == "Team" || plan == "Team Annual")
    ) {
      quantity = data()?.userInfo?.assignedLicences + 1;
    }
    setSearchParams({
      quantity,
    });
    let discountCode: any = urlString.has("discount")
      ? urlString.get("discount")
      : "";
    setTimeout(() => {
      setIsLoaded(false);
    }, 500);
    initializePaddle({
      environment: environmentPaddle,
      token: data()?.paddleInfo.token,
      checkout: {
        settings: {
          showAddDiscounts: false,
        },
      },
      eventCallback: async function (data: any) {
        if (data.name == "checkout.customer.created") {
          const usersListApiParams = {
            method: "POST" as string,
            url: `${import.meta.env.VITE_API_URL}/subscription/paddle/user-add`,
            jwt_token: userInfo().jwt_token as string,
            body: {
              user_id: userInfo().id,
              customer_id: data.data.customer.id,
            },
          };
          await fetchApi(usersListApiParams);
        } else if (data.name == "checkout.completed") {
          setTimeout(() => {
            location.href = "/form/list";
          }, 7000);
        } else if (data.name == "checkout.closed") {
          setTimeout(() => {
            navigate(
              `/checkout?plan=${
                plan +
                (quantity ? "&quantity=" + quantity : "") +
                (discountCode ? "&discount=" + discountCode : "")
              }`
            );
          }, 500);
        } else if (data.name == "checkout.loaded") {
          setCheckoutData(data.data);
          setIsLoaded(true);
        }
      },
    }).then((paddleInstance: Paddle | undefined) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
    if (plan) {
      setTimeout(() => {
        if (data()?.userInfo) {
          subscribe(
            data()?.paddleInfo?.token,
            data()?.paddleInfo?.teamPlanId,
            data()?.paddleInfo?.teamAnnualPlanId,
            plan,
            quantity,
            data()?.userInfo.email,
            discountCode
          );
        } else {
          navigate("/checkout");
        }
      }, 500);
    }
  }

  function subscribe(
    token: any,
    teamPlanId: any,
    teamAnnualPlanId: any,
    planName: string,
    planQuantity: number,
    email: string,
    discountCode: string
  ) {
    const planId: any = {
      Team: teamPlanId,
      "Team Annual": teamAnnualPlanId,
    };
    paddle()?.Checkout.open({
      settings: {
        displayMode: "inline",
        frameTarget: "checkout-container",
        frameStyle: "width: 100%; border: none",
      },
      items: [
        {
          priceId:
            planName == "Team" || planName == "Team Annual"
              ? planId[planName]
              : planName,
          quantity: Number(planQuantity),
        },
      ],
      discountCode,
      customer: {
        email: email,
      },
    });
  }

  return (
    <AuthLayout size="mih-650" hasNavbar={true}>
      <Show when={!isLoaded()}>
        <div class="lbl text-center p-2">Please Wait ....</div>
      </Show>
      <div class="checkout-container">
        <Show when={!planName()}>
          <div class="pricing-body">
            <div class="pr w-100 p-2">
              <div class="text-center">
                <Pricing plans={data()?.plans} isPricingPage="true"></Pricing>
              </div>
            </div>
          </div>
        </Show>
      </div>
    </AuthLayout>
  );
};

export default pricing;
