import { Component, For, Show, createEffect, createSignal } from "solid-js";
import { A, useNavigate, useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/system.layout";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { notify } from "~/components/global/Notification";
import messages from "~/helpers/message";
import Pagination from "~/components/global/Pagination";
import Search from "~/components/global/Search";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import {
  alertMessage,
  generatePassword,
  getRandomColorWithProperContrast,
  getSignatureInitials,
  groupIcons,
  popupQueue,
  removePopup,
  setAlertMessage,
  setAssignedForms,
  setAssignedTeamForms,
  setGroupIcons,
  setOtherForms,
  setPopup,
  setSelectedDropDown,
  userFormRoles,
} from "~/store/global.store";
import UserForms from "~/components/popup/UserGroupFormsPopup";
import AssigneeList from "~/components/popup/AssigneeListPopup";
import { validateUser } from "./api/validateUser";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import ResyncFormPopup from "~/components/popup/ResynFormPopup";
import {
  totalUserLicences,
  userRole,
  userSubscriptionStatus,
} from "~/store/user-form-role";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);
    const usersListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        search_string: "",
        sort_string: "",
        user_skip: 0,
        user_take: 10,
      },
    };
    let users = await fetchApi(usersListApiParams);
    if (users.statusCode == 401 || users.statusCode == 403) {
      throw redirect("/logout");
    }
    let usersList: any = users.data.usersList.users;
    let userListTotalItems: any = users.data.usersList.totalItems;
    let userListTotalPages: any = users.data.usersList.totalPages;
    let adminUser: any = users.data.adminUser;
    let assignedLicences: any = users.data.assignedLicences;

    const subscriptionDetailsApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/details`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
      },
    };
    const subscriptionDetails = await fetchApi(subscriptionDetailsApiParams);
    if (subscriptionDetails.statusCode == 401) {
      throw redirect("/logout");
    }
    let totalSubscriptionQuantity: any =
      subscriptionDetails.data?.purchasedLicences;
    const groupsListApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/group/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id: user_id,
        search_string: "",
        sort_string: "asc_name",
      },
    };
    const list = await fetchApi(groupsListApiParams);
    if (list.statusCode == 401) {
      throw redirect("/logout");
    }
    let groups = list?.data?.groupsList;

    return {
      user_id,
      usersList,
      userListTotalItems,
      userListTotalPages,
      adminUser,
      jwt_token,
      totalSubscriptionQuantity,
      assignedLicences,
      userDetails,
      groups,
    };
  });
}

const users: Component = () => {
  setPageTitle(`${SITE_TITLE} - Users`);
  const data = useRouteData<typeof routeData>();
  const [getUsersList, setUsersList] = createSignal(data()?.usersList as any);
  const [getAdminUser, setAdminUser] = createSignal(data()?.adminUser as any);
  const [assignedUsers, setAssignedUsers] = createSignal(
    data()?.assignedLicences as any
  );
  const [remainingUsers, setRemainingUsers] = createSignal(
    (data()?.totalSubscriptionQuantity - data()?.assignedLicences) as any
  );
  const [currentUserListPage, setCurrentUserListPage] = createSignal(
    1 as number
  );
  const [getUserListTotalItems, setUserListTotalItems] = createSignal(
    data()?.userListTotalItems as any
  );
  const [userListTotalPages, setUserListTotalPages] = createSignal(0 as number);
  const [searchString, setSearchString] = createSignal("" as any);
  const [sortString, setSortString] = createSignal("" as any);
  const [userListItemsPerPage, setUserListItemsPerPage] = createSignal(
    10 as number
  );
  const [formUserId, setFormUserId] = createSignal("" as string);
  const [formUserName, setFormUserName] = createSignal("" as string);
  const [userGroupsList, setUserGroupsList] = createSignal([] as any);
  const [isDataSaving, setIsDataSaving] = createSignal("" as string);
  const [userIdToDelete, setUserIdToDelete] = createSignal("" as string);
  const [currentUser, setCurrentUser] = createSignal({} as any);

  const navigate: any = useNavigate();

  createEffect(() => {
    if (data()?.usersList) {
      setUsersList(data()?.usersList);
    }
    if (data()?.groups) {
      setGroupIcons(
        data()?.groups.map((group: any) => ({
          name: group.name,
          logo: getSignatureInitials(group.name),
          theme: getRandomColorWithProperContrast("#ffffff"),
        }))
      );
    } else {
      location.reload();
    }
  });

  function getApiParams(user_id: string, jwt_token: string, user_skip: number) {
    return {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/list`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        user_skip,
        user_take: userListItemsPerPage() ? userListItemsPerPage() : 10,
        search_string: searchString() ? searchString() : "",
        sort_string: sortString() ? sortString() : "",
      },
    };
  }

  async function handleUserListPageChange(
    page: any,
    jwt_token: string,
    user_id: string
  ) {
    setCurrentUserListPage(page);
    let user_skip: number = 0;
    if (currentUserListPage() != 1) {
      user_skip = (currentUserListPage() - 1) * Number(userListItemsPerPage());
    }
    const apiParams = getApiParams(user_id, jwt_token, user_skip);
    let users = await fetchApi(apiParams);
    if (users?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (users?.statusCode == 403) {
      location.reload();
    }
    setUsersList(users.data.usersList.users);
  }

  async function deleteUser(
    user_id: string,
    jwt_token: string,
    admin_user_id: string
  ) {
    try {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/admin/user/delete`,
        jwt_token: jwt_token as string,
        body: {
          user_id,
        },
      };
      const deleteUser = await fetchApi(apiParams);
      if (deleteUser?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (deleteUser?.statusCode == 403) {
        location.reload();
      }
      let message: any;
      let theme: any = "success";
      if (deleteUser && deleteUser.status) {
        let user_skip: number = 0;
        if (currentUserListPage() != 1) {
          user_skip =
            (currentUserListPage() - 1) * Number(userListItemsPerPage());
        }
        const apiParams = getApiParams(admin_user_id, jwt_token, user_skip);
        let users = await fetchApi(apiParams);
        if (users?.statusCode == 401) {
          window.location.href = "/logout";
        } else if (users?.statusCode == 403) {
          location.reload();
        }
        setUserListTotalPages(users.data.usersList.totalPages);
        setUsersList(users.data.usersList.users);
        setUserListTotalItems(users.data.usersList.totalItems);
        message = messages.user.deleted;
        setAssignedUsers(users.data.assignedLicences);
        setRemainingUsers(
          remainingUsers()
            ? data()?.totalSubscriptionQuantity - users.data.assignedLicences
            : data()?.totalSubscriptionQuantity - users.data.assignedLicences
        );
      } else {
        message = messages.error.user_delete;
        theme = "error";
      }
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    } catch (err) {
      return err;
    }
  }

  async function searchUsers(
    searchString: any,
    user_id: string,
    jwt_token: string
  ) {
    setSearchString(searchString);
    let user_skip: number = 0;
    const apiParams = getApiParams(user_id, jwt_token, user_skip);
    let users = await fetchApi(apiParams);
    if (users?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (users?.statusCode == 403) {
      location.reload();
    }
    setUserListTotalPages(users.data.usersList.totalPages);
    setAdminUser(users.data.adminUser);
    setUsersList(users.data.usersList.users);
    setCurrentUserListPage(1);
  }

  async function filterUsers(
    filterString: any,
    user_id: string,
    jwt_token: string
  ) {
    setSortString(filterString);
    let user_skip: number = 0;
    if (currentUserListPage() != 1) {
      user_skip = (currentUserListPage() - 1) * Number(userListItemsPerPage());
    }
    const apiParams = getApiParams(user_id, jwt_token, user_skip);
    let users = await fetchApi(apiParams);
    if (users?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (users?.statusCode == 403) {
      location.reload();
    }
    setUsersList(users.data.usersList.users);
  }

  async function userForms(
    user_id: string,
    jwt_token: string,
    name: string,
    id: string
  ) {
    let apiParams: any = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/list`,
      jwt_token: jwt_token as string,
      body: { user_id },
    };
    const userForms = await fetchApi(apiParams);
    if (userForms?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (userForms?.statusCode == 403) {
      location.reload();
    }
    setAssignedForms(
      userForms.data.filter((form: any) => form.assignee?.includes(name))
    );
    setAssignedTeamForms(
      userForms.data.filter((form: any) => form.assigneeTeams?.includes(name))
    );
    setOtherForms(
      userForms.data.filter(
        (form: any) =>
          !form.assignee?.includes(name) && !form.assigneeTeams?.includes(name)
      )
    );
    setPopup("userGroupForms");
    setFormUserId(id);
    setFormUserName(name);
  }

  async function regeneratePassword(
    user_id: string,
    admin_user_id: string,
    jwt_token: string,
    first_name: string,
    last_name: string,
    email: string,
    password: string
  ) {
    setIsDataSaving(user_id);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/user/password/regenerate`,
      jwt_token: jwt_token as string,
      body: {
        user_id,
        admin_user_id,
        first_name,
        last_name,
        email,
        password,
      },
    };
    const regeneratePassword = await fetchApi(apiParams);
    if (regeneratePassword?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (regeneratePassword?.statusCode == 403) {
      location.reload();
    }

    if (regeneratePassword && regeneratePassword.status) {
      setIsDataSaving("");
      setAlertMessage(
        "<b>We have sent instructions via email. Please inform user to check email and follow instructions to reset account password.</b>"
      );
      setPopup("passwordRegenerate");
    } else {
      let message: string = messages.error.password_regenerate;
      let theme: string = "error";
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    }
  }

  return (
    <main>
      <Show when={popupQueue.includes("userGroupForms")}>
        <UserForms
          user_id={data()?.user_id}
          id={formUserId()}
          jwt_token={data()?.jwt_token}
          name={formUserName()}
          mode="user"
        ></UserForms>
      </Show>
      <Show when={popupQueue.includes("assigneeList")}>
        <AssigneeList
          list={userGroupsList()}
          heading="Assigned Teams"
          avatarList={userGroupsList().map(() => "")}
        ></AssigneeList>
      </Show>
      <Show
        when={
          popupQueue.includes("passwordRegenerate") ||
          popupQueue.includes("confirmUserRemove")
        }
      >
        <ConfirmPopup
          message={alertMessage()}
          name={
            popupQueue.includes("passwordRegenerate")
              ? "passwordRegenerate"
              : "confirmUserRemove"
          }
          alert={popupQueue.includes("passwordRegenerate") ? true : false}
          onconfirm={(status: boolean) => {
            if (popupQueue.includes("passwordRegenerate")) {
              removePopup("passwordRegenerate");
            } else {
              removePopup("confirmUserRemove");
              if (status) {
                deleteUser(
                  userIdToDelete(),
                  data()?.jwt_token,
                  data()?.user_id
                );
              }
              setUserIdToDelete("");
            }
          }}
        ></ConfirmPopup>
      </Show>
      <Show when={popupQueue.includes("resyncForm")}>
        <ResyncFormPopup
          jwt_token={data()?.jwt_token}
          data={currentUser()}
          setValue={(data: any) => {
            setUsersList(
              getUsersList().map((item: any) => {
                if (item.id == data) {
                  return {
                    ...item,
                    force_resync: item.force_resync ? false : true,
                  };
                } else {
                  return item;
                }
              })
            );
            removePopup("resyncForm");
          }}
        ></ResyncFormPopup>
      </Show>
      <SystemLayout>
        <div
          class="center"
          onclick={(e: any) => {
            if (e.target.id != "role_dropdown") {
              setSelectedDropDown("");
            }
          }}
        >
          <div class="flex space-between app-w-50">
            <h2 class="title color w-58">Manage Users</h2>
            <span
              class="btn primary float-right"
              onclick={() => {
                if (
                  totalUserLicences() >
                    (getUserListTotalItems()
                      ? getUserListTotalItems() + 1
                      : data()?.userListTotalItems + 1) ||
                  userSubscriptionStatus() == "trialing"
                ) {
                  navigate("/user/add");
                } else {
                  notify({
                    isNotified: true,
                    message: messages.warn.user_add,
                    theme: "warm",
                    placement: "top",
                    timeout: 2500,
                  });
                }
              }}
            >
              <Icons name="plus" stroke="#fff" />
              &nbsp; Add User
            </span>
          </div>
          <Show when={data()?.totalSubscriptionQuantity}>
            <div class="text-left">
              <div class="badge licences">
                <span>
                  <strong>{data()?.totalSubscriptionQuantity}</strong> Licenses
                  Purchased
                </span>
                &nbsp; | &nbsp;
                <span>
                  <strong>
                    {assignedUsers()
                      ? assignedUsers()
                      : data()?.assignedLicences}
                  </strong>{" "}
                  Users Assigned
                </span>
                &nbsp; | &nbsp;
                <span>
                  <strong>
                    {remainingUsers()
                      ? remainingUsers()
                      : data()?.totalSubscriptionQuantity -
                        data()?.assignedLicences}
                  </strong>{" "}
                  Users Remaining
                </span>
              </div>
            </div>
          </Show>
          <br />
          <div>
            <div>
              <div class="flex app-flex space-between">
                <h3 class="title w-58">Users List</h3>
                <div class="w-200">
                  <Search
                    onEnter={(search: any) => {
                      searchUsers(search, data()?.user_id, data()?.jwt_token);
                    }}
                    placeholder="Search User"
                  ></Search>
                </div>
              </div>
              <div>
                <table class="table users-table" style={{ "--width": "100%" }}>
                  <thead class="user-select-none">
                    <tr>
                      <th>
                        <span>Name</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterUsers(
                              sortString() == "" ||
                                sortString() == "username_asc"
                                ? "username_desc"
                                : "username_asc",
                              data()?.user_id,
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th>
                        <span>Email</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterUsers(
                              sortString() == "email_asc"
                                ? "email_desc"
                                : "email_asc",
                              data()?.user_id,
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th>
                        <span>Role</span>
                        <span
                          class="float-right cur-p"
                          onclick={() => {
                            filterUsers(
                              sortString() == "role_asc"
                                ? "role_desc"
                                : "role_asc",
                              data()?.user_id,
                              data()?.jwt_token
                            );
                          }}
                        >
                          <Icons
                            name="arrowsSort"
                            height={16}
                            width={16}
                          ></Icons>
                        </span>
                      </th>
                      <th>Assigned Teams</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Show
                      when={
                        Number(currentUserListPage()) == 1 &&
                        (getAdminUser()
                          ? getAdminUser().first_name
                          : data()?.adminUser.first_name)
                      }
                    >
                      <tr class="gray">
                        <td>
                          {data()?.adminUser.first_name +
                            " " +
                            data()?.adminUser.last_name}
                        </td>
                        <td>{data()?.adminUser.email}</td>
                        <td>
                          <label class="lbl">Admin</label>
                        </td>
                        <td>
                          <div class="form-users__group">
                            <For each={data()?.adminUser.groups}>
                              {(group: any, key: any) => (
                                <>
                                  <Show when={key() < 5}>
                                    <div
                                      class="form-user"
                                      style={{
                                        "--z-index":
                                          data()?.adminUser.groups.length -
                                          key(),
                                        "--background-color": groupIcons().find(
                                          (item: any) => item.name == group
                                        )?.theme,
                                      }}
                                      data-title={group}
                                    >
                                      {
                                        groupIcons().find(
                                          (item: any) => item.name == group
                                        )?.logo
                                      }
                                    </div>
                                  </Show>
                                  <Show
                                    when={
                                      data()?.adminUser.groups.length > 5 &&
                                      data()?.adminUser.groups.length - 1 ==
                                        key()
                                    }
                                  >
                                    <div
                                      class="form-user"
                                      style={{
                                        "--z-index":
                                          data()?.adminUser.groups.length -
                                          key(),
                                        "--background-color": "#000000",
                                      }}
                                      data-title="more"
                                      onclick={() => {
                                        setUserGroupsList(
                                          data()?.adminUser.groups
                                        );
                                        setPopup("assigneeList");
                                      }}
                                    >
                                      <Icons
                                        name="plus"
                                        stroke="#ffffff"
                                      ></Icons>
                                    </div>
                                  </Show>
                                </>
                              )}
                            </For>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </Show>
                    <For
                      each={getUsersList() ? getUsersList() : data()?.usersList}
                    >
                      {(user: any, key: any) => (
                        <>
                          <tr>
                            <td>{user.first_name + " " + user.last_name}</td>
                            <td>
                              {user.email}
                              <br />
                            </td>
                            <td>
                              {
                                userFormRoles.filter(
                                  (item: any) => item.value == user.role
                                )[0].label
                              }
                            </td>
                            <td>
                              <div class="form-users__group">
                                <For each={user.groups}>
                                  {(group: any, key: any) => (
                                    <>
                                      <Show when={key() < 5}>
                                        <div
                                          class="form-user"
                                          style={{
                                            "--z-index":
                                              user.groups.length - key(),
                                            "--background-color":
                                              groupIcons().find(
                                                (item: any) =>
                                                  item.name == group
                                              )?.theme,
                                          }}
                                          data-title={group}
                                        >
                                          {
                                            groupIcons().find(
                                              (item: any) => item.name == group
                                            )?.logo
                                          }
                                        </div>
                                      </Show>
                                      <Show
                                        when={
                                          user.groups.length > 5 &&
                                          user.groups.length - 1 == key()
                                        }
                                      >
                                        <div
                                          class="form-user"
                                          style={{
                                            "--z-index":
                                              user.groups.length - key(),
                                            "--background-color": "#000000",
                                          }}
                                          data-title="more"
                                          onclick={() => {
                                            setUserGroupsList(user.groups);
                                            setPopup("assigneeList");
                                          }}
                                        >
                                          <Icons
                                            name="plus"
                                            stroke="#ffffff"
                                          ></Icons>
                                        </div>
                                      </Show>
                                    </>
                                  )}
                                </For>
                              </div>
                            </td>
                            <td class="w-350">
                              <Show when={user.id != data()?.user_id}>
                                <A
                                  class="btn success sm mb-5"
                                  href={`/user/${user.id}/edit`}
                                >
                                  <Icons
                                    name="pencil"
                                    stroke="#fff"
                                    width={18}
                                    height={18}
                                  />
                                  <span>&nbsp;Edit</span>
                                </A>
                                &nbsp;
                                <button
                                  class="btn danger sm mb-5"
                                  onclick={() => {
                                    setUserIdToDelete(user.id);
                                    setAlertMessage(
                                      "<b>Removing this user will delete all forms and records associated with this user.<br/>Are you sure you want to proceed?</b>"
                                    );
                                    setPopup("confirmUserRemove");
                                  }}
                                >
                                  <Icons
                                    name="x"
                                    stroke="#fff"
                                    width={18}
                                    height={18}
                                  />
                                  <span>&nbsp;Delete</span>
                                </button>
                                &nbsp;
                                <button
                                  // class={`btn primary sm mb-5 ${
                                  //   user.status == "PENDING" ? "disabled" : ""
                                  // }`}
                                  class="btn primary sm mb-5"
                                  onclick={() => {
                                    // if (user.status != "PENDING") {
                                    userForms(
                                      data()?.user_id,
                                      data()?.jwt_token,
                                      user.first_name + " " + user.last_name,
                                      user.id
                                    );
                                    // }
                                  }}
                                >
                                  <Icons
                                    name="formTab"
                                    stroke="#fff"
                                    width={18}
                                    height={18}
                                  />
                                  <span>&nbsp;Share Forms</span>
                                </button>
                                &nbsp;
                                <button
                                  class={`btn sm ${
                                    isDataSaving() == user.id ? "" : "gray"
                                  }`}
                                  onclick={() =>
                                    regeneratePassword(
                                      user.id,
                                      data()?.user_id,
                                      data()?.jwt_token,
                                      user.first_name,
                                      user.last_name,
                                      user.email,
                                      generatePassword()
                                    )
                                  }
                                >
                                  <Show when={isDataSaving() != user.id}>
                                    <Icons
                                      name="refresh"
                                      stroke="#fff"
                                      width={18}
                                      height={18}
                                    />
                                    <span>&nbsp;Reset Password</span>
                                  </Show>
                                  <Show when={isDataSaving() == user.id}>
                                    <span>Regenerating&nbsp;</span>
                                    <Icons name="loader"></Icons>
                                  </Show>
                                </button>
                                <Show when={userRole() == "admin"}>
                                  &nbsp;
                                  <button
                                    class={`btn sm ${
                                      user.force_resync ? "secondary" : "gray"
                                    }`}
                                    onclick={async () => {
                                      setCurrentUser(user);
                                      setPopup("resyncForm");
                                    }}
                                  >
                                    <Icons
                                      name="resync"
                                      stroke="#fff"
                                      width={18}
                                      height={18}
                                    />
                                    <span>
                                      &nbsp;
                                      {!user.force_resync ? "" : "Cancel"}{" "}
                                      Resync
                                    </span>
                                  </button>
                                </Show>
                              </Show>
                            </td>
                          </tr>
                        </>
                      )}
                    </For>
                    <Show
                      when={
                        getAdminUser() &&
                        !getAdminUser()?.first_name &&
                        (getUsersList()
                          ? getUsersList().length == 0
                          : data()?.usersList.length == 0)
                      }
                    >
                      <tr class="text-center">
                        <td colSpan="5">
                          <small>
                            <b>No User Data Found !!</b>
                          </small>
                        </td>
                      </tr>
                    </Show>
                  </tbody>
                </table>
                <Show
                  when={
                    getUsersList()
                      ? getUsersList().length != 0
                      : data()?.usersList.length != 0
                  }
                >
                  <Pagination
                    currentPage={currentUserListPage()}
                    totalPages={
                      userListTotalPages()
                        ? userListTotalPages()
                        : data()?.userListTotalPages
                    }
                    onPageChange={(page: any) =>
                      handleUserListPageChange(
                        page,
                        data()?.jwt_token,
                        data()?.user_id
                      )
                    }
                    itemsPerPage={
                      userListItemsPerPage() ? userListItemsPerPage() : 10
                    }
                    totalItems={
                      getUserListTotalItems()
                        ? getUserListTotalItems()
                        : data()?.userListTotalItems
                    }
                    itemPerPageFilter={true}
                  ></Pagination>
                </Show>
              </div>
            </div>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default users;
