import { Component, createSignal, onMount, Show } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import fetchApi from "~/lib/api";
import Pricing from "~/components/global/Pricing";
import "~/assets/styles/landing.scss";
import LandingLayout from "~/components/layouts/landing.layout";
import { getUser } from "~/db/session";

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    let isUserLogin = false;
    if (userDetails) {
      isUserLogin = true;
      if (userDetails.data.role.role_name == "super_admin") {
        throw redirect("/admin/dashboard");
      } else {
        if (
          userDetails !== null &&
          userDetails.data?.subscription &&
          userDetails.data?.subscription?.plan_name !== "" &&
          userDetails.data?.subscription?.state !== "cancelled"
        ) {
          return redirect("/form/list");
        }
      }
    }
    const planApiParams = {
      method: "GET" as string,
      url: `${import.meta.env.VITE_API_URL}/subscription/pricing`,
    };
    let plans: any = await fetchApi(planApiParams);
    if (plans?.statusCode == 401) {
      throw redirect("/logout");
    }
    plans = plans.data;
    return { plans, isUserLogin, userDetails };
  });
}

const pricingPlans: Component = () => {
  const data: any = useRouteData<typeof routeData>();

  return (
    <LandingLayout>
      <div class="pricing-body">
        <div class="pr w-100">
          <div class="text-center">
            <h1 class="title">Pricing</h1>
            <Show
              when={
                data()?.isUserLogin &&
                (!data()?.userDetails.data?.subscription ||
                  data()?.userDetails.data?.subscription?.state == "cancelled")
              }
            >
              <div class="badge plan">
                Hi {data()?.userDetails.data.username}, You don't have an active
                subscription. Please click on the subscribe button.
              </div>
            </Show>
            <div class="clearfix"></div>
            <br />
            <Pricing
              plans={data()?.plans}
              isLandingPage="true"
              isUserLogin={data()?.isUserLogin}
            ></Pricing>
          </div>
        </div>
      </div>
    </LandingLayout>
  );
};

export default pricingPlans;
