import { createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import fetchApi from "~/lib/api";
import {
  exportMailattachments,
  selectedRecords,
  setAlertMessage,
  setExportMailattachments,
  setPopup,
} from "./global.store";
import { formJson } from "./form.store";
import JsBarcode from "jsbarcode";
import messages from "~/helpers/message";
import { notify } from "~/components/global/Notification";
import { setBarcodeUrls } from "~/components/popup/ExportMailPopup";
import { recordsJson } from "./records.store";

export const [serviceTokens, setServiceTokens] = createSignal({} as any);
export const [isProcessing, setIsProcessing] = createSignal("" as string);
export const [exportFormat, setExportFormat] = createSignal([] as any);

export const [exportFilesData, setExportFilesData] = createStore({
  displayName: "" as string,
  displayEmail: "" as string,
  foldersList: [] as any,
  path: "" as string,
  idsPath: "" as string,
}) as any;

export async function updateServiceToken(
  user_id: string,
  jwt_token: string,
  service: string,
  accessToken: string
) {
  const serviceApiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/service/tokens/update`,
    jwt_token,
    body: {
      user_id,
      service,
      accessToken,
    },
  };
  const serviceTokens = await fetchApi(serviceApiParams);
  if (serviceTokens?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (serviceTokens?.statusCode == 403) {
    location.reload();
  }
  setServiceTokens(serviceTokens.data);
}

export function authorizeDropBox() {
  const authorizeUrl = `https://www.dropbox.com/oauth2/authorize?client_id=${
    import.meta.env.VITE_DROPBOX_APP_KEY
  }&response_type=code&redirect_uri=${import.meta.env.VITE_APP_URL}/form/list`;
  const popup: any = window.open(authorizeUrl);
  if (!popup || popup.closed) {
    const isSafari: boolean =
      /safari/i.test(navigator.userAgent) &&
      !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(navigator.userAgent);
    setAlertMessage(
      `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
        isSafari
          ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
          : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
      }</span>`
    );
    setPopup("alert");
  }
}

export function getDropBoxCurrentAccountDetails(
  accessToken: string
): Promise<void> {
  const url = "https://api.dropboxapi.com/2/users/get_current_account";
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "POST",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((accountInfo) => {
        if (accountInfo && accountInfo?.account_id) {
          setExportFilesData({
            displayName: accountInfo.name.display_name,
            displayEmail: accountInfo.email,
          });
        }
        resolve(accountInfo);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchDropboxFoldersList(
  accessToken: string,
  path: string
): Promise<void> {
  const apiUrl = "https://api.dropboxapi.com/2/files/list_folder";
  const folderPath = {
    path: path,
  };
  return new Promise<void>((resolve, reject) => {
    fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(folderPath),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const folders = data.entries.filter(
          (entry: any) => entry[".tag"] === "folder"
        );
        setExportFilesData({
          path: path,
          foldersList: folders,
        });
        resolve();
      })
      .catch((error) => {
        console.error("Error fetching folders:", error);
        reject(error);
      });
  });
}

export function authorizeBox() {
  const authorizeUrl = `https://account.box.com/api/oauth2/authorize?client_id=${
    import.meta.env.VITE_BOX_CLIENT_ID
  }&response_type=code&redirect_uri=${import.meta.env.VITE_APP_URL}/form/list`;
  const popup: any = window.open(authorizeUrl);
  if (!popup || popup.closed) {
    const isSafari: boolean =
      /safari/i.test(navigator.userAgent) &&
      !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(navigator.userAgent);
    setAlertMessage(
      `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
        isSafari
          ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
          : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
      }</span>`
    );
    setPopup("alert");
  }
}

export async function getBoxCurrentAccountDetails(
  jwt_token: string,
  accessToken: string
) {
  const userApiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/user/box`,
    jwt_token,
    body: {
      accessToken,
    },
  };
  const userDetails = await fetchApi(userApiParams);
  if (userDetails?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (userDetails?.statusCode == 403) {
    location.reload();
  }
  if (userDetails.status) {
    setExportFilesData({
      displayName: userDetails.data.name,
      displayEmail: userDetails.data.login,
    });
  }
  return userDetails.status;
}

export async function fetchBoxFoldersList(
  jwt_token: string,
  accessToken: string,
  folderPath: string,
  fullpath?: string,
  idsPath?: string
) {
  const foldersApiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/folders/box`,
    jwt_token,
    body: {
      accessToken,
      folderPath,
    },
  };
  const foldersList = await fetchApi(foldersApiParams);
  if (foldersList?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (foldersList?.statusCode == 403) {
    location.reload();
  }
  setExportFilesData({
    path: fullpath ? fullpath : "",
    idsPath: idsPath ? idsPath : "0",
    foldersList: foldersList.data.entries.filter(
      (entry: any) => entry.type == "folder"
    ),
  });
}

export function authorizeOneDrive() {
  const authorizeUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${
    import.meta.env.VITE_ONEDRIVE_CLIENT_ID
  }&scope=Files.ReadWrite offline_access&response_type=token&redirect_uri=${
    import.meta.env.VITE_APP_URL
  }/form/list`;
  const popup: any = window.open(authorizeUrl);
  if (!popup || popup.closed) {
    const isSafari: boolean =
      /safari/i.test(navigator.userAgent) &&
      !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(navigator.userAgent);
    setAlertMessage(
      `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
        isSafari
          ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
          : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
      }</span>`
    );
    setPopup("alert");
  }
}

export function getOneDriveCurrentAccountDetails(
  accessToken: string
): Promise<void> {
  const url = "https://graph.microsoft.com/v1.0/me/drive";
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((accountInfo) => {
        if (accountInfo && accountInfo?.id) {
          setExportFilesData({
            displayName: accountInfo.owner.user.displayName,
            displayEmail: "",
          });
        }
        resolve(accountInfo);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchOneDriveFoldersList(
  accessToken: string,
  path: string,
  fullpath?: string,
  idsPath?: string
): Promise<void> {
  const apiUrl = `https://graph.microsoft.com/v1.0/me/drive/items/${
    path != "" && path != "0" ? path : "root"
  }/children?$filter=folder ne null`;
  return new Promise<void>((resolve, reject) => {
    fetch(apiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setExportFilesData({
          path: fullpath ? fullpath : "",
          idsPath: idsPath ? idsPath : "0",
          foldersList: data.value,
        });
        resolve();
      })
      .catch((error) => {
        console.error("Error fetching folders:", error);
        reject(error);
      });
  });
}

export function authorizeGoogleDrive() {
  const authorizeUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/drive
          &response_type=code&client_id=${
            import.meta.env.VITE_GOOGLE_DRIVE_CLIENT_ID
          }&redirect_uri=${import.meta.env.VITE_APP_URL}/form/list`;
  window.location.href = authorizeUrl;
  // const popup: any = window.open(authorizeUrl);

  // if (!popup || popup.closed) {
  //   const isSafari: boolean =
  //     /safari/i.test(navigator.userAgent) &&
  //     !/chrome|crios|crmo|opr|opera|edg|edge|brave/i.test(navigator.userAgent);
  //   setAlertMessage(
  //     `<b>Redirection access is denied. Please enable pop-ups and redirect permissions in your browser settings.</b><br><br><span class="lbl fs-14">${
  //       isSafari
  //         ? "Safari Settings &nbsp;>&nbsp; Websites  &nbsp;>&nbsp;  Pop-up Windows &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
  //         : "Browser Settings  &nbsp;>&nbsp;  Privacy and security  &nbsp;>&nbsp;  Site settings  &nbsp;>&nbsp;  Pop-ups and redirects &nbsp;>&nbsp; Select website &nbsp;>&nbsp; Choose <strong>Allow</strong>"
  //     }</span>`
  //   );
  //   setPopup("alert");
  // }
}

export function getGoogleDriveCurrentAccountDetails(
  accessToken: string
): Promise<void> {
  const url = "https://www.googleapis.com/drive/v3/about?fields=user";
  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((response) => {
        return response.json();
      })
      .then((accountInfo) => {
        if (accountInfo && accountInfo?.user) {
          setExportFilesData({
            displayName: accountInfo.user.displayName,
            displayEmail: accountInfo.user.emailAddress,
          });
        }
        resolve(accountInfo);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function fetchGoogleDriveFoldersList(
  accessToken: string,
  path: any,
  fullpath?: string,
  idsPath?: string
): Promise<void> {
  // URL for fetching owned folders
  const ownedFoldersApiUrl = `https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.folder'${
    path && path != 0 ? ` and '${path}' in parents` : ` and 'root' in parents`
  }`;
  // URL for fetching shared folders
  const sharedFoldersApiUrl = `https://www.googleapis.com/drive/v3/files?q=mimeType='application/vnd.google-apps.folder' and sharedWithMe=true${
    path && path != 0 ? ` and '${path}' in parents` : ""
  }`;

  return new Promise<void>((resolve, reject) => {
    const fetchOwnedFolders = fetch(ownedFoldersApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const fetchSharedFolders = fetch(sharedFoldersApiUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    Promise.all([fetchOwnedFolders, fetchSharedFolders])
      .then(async ([ownedResponse, sharedResponse]) => {
        if (!ownedResponse.ok) {
          throw new Error(`HTTP error! Status: ${ownedResponse.status}`);
        }
        if (!sharedResponse.ok) {
          throw new Error(`HTTP error! Status: ${sharedResponse.status}`);
        }
        const ownedData = await ownedResponse.json();
        const sharedData = await sharedResponse.json();
        const combinedFolders = [...ownedData.files, ...sharedData.files];
        setExportFilesData({
          path: fullpath ? fullpath : "",
          idsPath: idsPath ? idsPath : "0",
          foldersList: combinedFolders,
        });
        resolve();
      })
      .catch((error) => {
        console.error("Error fetching folders:", error);
        reject(error);
      });
  });
}

export async function uploadFilesToService(user_id: string, jwt_token: string) {
  const accessToken = serviceTokens()[localStorage.getItem("fc_export_type")];
  const exportFileApiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/export/${localStorage.getItem(
      "fc_export_type"
    )}`,
    jwt_token,
    body: {
      user_id,
      accessToken,
      export_files: exportMailattachments()
        .map((file: any) => file.fileName)
        .join("#"),
      folderPath:
        localStorage.getItem("fc_export_type") == "dropbox" ||
        localStorage.getItem("fc_export_type") == "onedrive"
          ? exportFilesData.path
          : localStorage.getItem("fc_export_type") == "box" ||
            localStorage.getItem("fc_export_type") == "googledrive"
          ? exportFilesData.idsPath.split("/")[
              exportFilesData.idsPath.split("/").length - 1
            ]
          : "",
    },
  };
  let exportFile = await fetchApi(exportFileApiParams);
  if (exportFile?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (exportFile?.statusCode == 403) {
    location.reload();
  }
  return exportFile;
}

export async function exportFormRecordFiles(
  user_id: string,
  jwt_token: string,
  form_id: string,
  service: string,
  exportSettings: any
) {
  let serviceTokens: any;
  const serviceTokensParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/form/service/tokens/list`,
    jwt_token,
    body: { user_id },
  };
  serviceTokens = await fetchApi(serviceTokensParams);
  if (serviceTokens?.statusCode == 401) {
    window.location.href = "/logout";
  } else if (serviceTokens?.statusCode == 403) {
    location.reload();
  }
  serviceTokens = serviceTokens.data;
  setServiceTokens(serviceTokens);
  setIsProcessing(service);
  const barcodeUrls: Array<string> = [];
  await Promise.all(
    formJson.pages.map(async (page: any) => {
      if (Object.keys(page).length > 0) {
        await Promise.all(
          page.fields?.map(async (field: any) => {
            if (field.type == "BarcodeField" && selectedRecords().length > 0) {
              const formDataApiParams = {
                method: "POST" as string,
                url: `${import.meta.env.VITE_API_URL}/form/print/data`,
                jwt_token,
                body: {
                  user_id,
                  form_id,
                  record_ids: selectedRecords().join(","),
                },
              };
              let response = await fetchApi(formDataApiParams);
              if (response?.statusCode == 401) {
                window.location.href = "/logout";
              } else if (response?.statusCode == 403) {
                location.reload();
              }
              await Promise.all(
                response.data.records.map(async (record: any) => {
                  if (record[field.properties.dataFieldSync.uid]) {
                    const canvas = document.createElement("canvas");
                    JsBarcode(
                      canvas,
                      record[field.properties.dataFieldSync.uid],
                      {
                        format: "CODE128",
                        width: 2,
                        height: 100,
                        displayValue: false,
                      }
                    );
                    barcodeUrls.push(canvas.toDataURL("image/png"));
                  } else {
                    barcodeUrls.push("");
                  }
                })
              );
            }
          })
        );
      }
    })
  );
  setBarcodeUrls(barcodeUrls);
  let generatedFiles: any = [];
  if (service == "email") {
    const mailattachments: any = [];
    let recordName: any = " (" + selectedRecords().length + " Records)";
    if (selectedRecords() == 1) {
      recordName =
        " (" +
        recordsJson.records.find(
          (record: any) => record.id == selectedRecords()
        ).record_name;
      recordName = recordName.replace(/\s+$/, "") + ")";
    } else {
    }
    let timeStamp: number = Date.now();
    let fileName: any = `${formJson.root.formStructure.name}${
      selectedRecords().length > 0 ? recordName : ""
    }_${timeStamp}`;
    exportFormat().forEach((format: any) => {
      switch (format) {
        case "PDF":
          mailattachments.push({ fileName: fileName + ".pdf" });
          break;
        case "HTML":
          mailattachments.push({ fileName: fileName + ".html" });
          break;
        case "JSON":
          mailattachments.push({ fileName: fileName + "-json.zip" });
          break;
        case "CSV":
          mailattachments.push({ fileName: fileName + ".csv" });
          break;
        case "XML":
          mailattachments.push({ fileName: fileName + ".xml" });
          break;
        default:
          mailattachments.push({ fileName: fileName + "-fmcx.zip" });
          break;
      }
    });
    setExportMailattachments(mailattachments);
  } else {
    const generateFileApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/file/generate`,
      jwt_token,
      body: {
        form_id,
        record_ids: selectedRecords().join(","),
        export_formats: exportFormat().join(","),
        barcodeUrls,
        service,
      },
    };
    generatedFiles = await fetchApi(generateFileApiParams);
    if (generatedFiles?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (generatedFiles?.statusCode == 403) {
      location.reload();
    }
    setExportMailattachments([]);
    if (generatedFiles && generatedFiles.status) {
      for (const file of generatedFiles.data.files) {
        let fileUrl: string = `${import.meta.env.VITE_API_URL}/uploads/${file}`;
        fetch(fileUrl, { method: "HEAD" }).then((response) => {
          const fileSize: any = response.headers.get("Content-Length");
          setExportMailattachments([
            ...exportMailattachments(),
            {
              fileName: file,
              fileSize:
                fileSize / 1024 > 1000
                  ? (fileSize / (1024 * 1024)).toFixed(1) + " MB"
                  : (fileSize / 1024).toFixed(0) + " KB",
            },
          ]);
        });
      }
    }
    localStorage.setItem(
      "fc_export_files",
      generatedFiles.data.files.join("#")
    );
  }
  switch (service) {
    case "email":
      setPopup("exportMail");
      setIsProcessing("");
      break;
    case "dropbox":
      localStorage.setItem("fc_export_type", "dropbox");
      if (serviceTokens && serviceTokens[service]) {
        getDropBoxCurrentAccountDetails(serviceTokens[service]).then(
          (res: any) => {
            if (res && res?.account_id) {
              if (exportSettings?.is_without_prompting) {
                uploadFiles(user_id, jwt_token);
              } else {
                fetchDropboxFoldersList(
                  serviceTokens[service],
                  formJson.root.formStructure?.autoExportSetting?.service ==
                    "dropbox" && exportSettings?.dropbox_path
                    ? exportSettings.dropbox_path
                    : ""
                ).then(() => {
                  setPopup("exportFiles");
                  setIsProcessing("");
                });
              }
            } else {
              authorizeDropBox();
              setIsProcessing("");
            }
          }
        );
      } else {
        setIsProcessing("");
        authorizeDropBox();
      }
      break;
    case "box":
      localStorage.setItem("fc_export_type", "box");
      if (serviceTokens && serviceTokens[service]) {
        getBoxCurrentAccountDetails(jwt_token, serviceTokens[service]).then(
          (res: any) => {
            if (res) {
              if (exportSettings?.is_without_prompting) {
                uploadFiles(user_id, jwt_token);
              } else {
                fetchBoxFoldersList(
                  jwt_token,
                  serviceTokens[service],
                  formJson.root.formStructure?.autoExportSetting?.service ==
                    "box" &&
                    exportSettings?.box_path &&
                    exportSettings?.box_path != ":"
                    ? exportSettings.box_path.split(":")[0].split("/")[
                        exportSettings.box_path.split(":")[0].split("/")
                          .length - 1
                      ]
                    : "0",
                  exportSettings?.box_path
                    ? exportSettings?.box_path?.split(":")[1]
                    : "",
                  exportSettings?.box_path
                    ? exportSettings?.box_path?.split(":")[0]
                    : "0"
                ).then(() => {
                  setIsProcessing("");
                  setPopup("exportFiles");
                });
              }
            } else {
              setIsProcessing("");
              authorizeBox();
            }
          }
        );
      } else {
        setIsProcessing("");
        authorizeBox();
      }
      break;
    case "googledrive":
      localStorage.setItem("fc_export_type", "googledrive");
      if (serviceTokens && serviceTokens[service]) {
        getGoogleDriveCurrentAccountDetails(serviceTokens[service]).then(
          (res: any) => {
            if (res && res?.user) {
              if (exportSettings?.is_without_prompting) {
                uploadFiles(user_id, jwt_token);
              } else {
                fetchGoogleDriveFoldersList(
                  serviceTokens[service],
                  formJson.root.formStructure?.autoExportSetting?.service ==
                    "googledrive" &&
                    exportSettings?.googledrive_path &&
                    exportSettings?.googledrive_path != ":"
                    ? exportSettings?.googledrive_path.split(":")[0].split("/")[
                        exportSettings?.googledrive_path
                          .split(":")[0]
                          .split("/").length - 1
                      ]
                    : "",
                  exportSettings?.googledrive_path
                    ? exportSettings?.googledrive_path?.split(":")[1]
                    : "",
                  exportSettings?.googledrive_path
                    ? exportSettings?.googledrive_path?.split(":")[0]
                    : "0"
                ).then(() => {
                  setIsProcessing("");
                  setPopup("exportFiles");
                });
              }
            } else {
              setIsProcessing("");
              authorizeGoogleDrive();
            }
          }
        );
      } else {
        setIsProcessing("");
        authorizeGoogleDrive();
      }
      break;
    case "onedrive":
      localStorage.setItem("fc_export_type", "onedrive");
      if (serviceTokens && serviceTokens[service]) {
        getOneDriveCurrentAccountDetails(serviceTokens[service]).then(
          (res: any) => {
            if (res && res?.id) {
              if (exportSettings?.is_without_prompting) {
                uploadFiles(user_id, jwt_token);
              } else {
                fetchOneDriveFoldersList(
                  serviceTokens[service],
                  formJson.root.formStructure?.autoExportSetting?.service ==
                    "onedrive" &&
                    exportSettings?.onedrive_path &&
                    exportSettings?.onedrive_path != ":"
                    ? exportSettings?.onedrive_path.split(":")[0].split("/")[
                        exportSettings?.onedrive_path.split(":")[0].split("/")
                          .length - 1
                      ]
                    : "",
                  exportSettings?.onedrive_path
                    ? exportSettings?.onedrive_path?.split(":")[1]
                    : "",
                  exportSettings?.onedrive_path
                    ? exportSettings?.onedrive_path?.split(":")[0]
                    : "0"
                ).then(() => {
                  setIsProcessing("");
                  setPopup("exportFiles");
                });
              }
            } else {
              setIsProcessing("");
              authorizeOneDrive();
            }
          }
        );
      } else {
        setIsProcessing("");
        authorizeOneDrive();
      }
      break;
    case "savetofolder":
      const downloadPromises = generatedFiles.data.files.map(
        (file: any, index: number) => {
          return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
              const link = document.createElement("a");
              link.setAttribute("target", "_blank");
              let fileUrl: string = `${
                import.meta.env.VITE_API_URL
              }/uploads/${file}`;
              fetch(fileUrl)
                .then((response) => response.blob())
                .then((blob) => {
                  const blobUrl = URL.createObjectURL(blob);
                  link.href = blobUrl;
                  link.download = file.replace(/_\d+\.(\w+)$/, ".$1");
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                  URL.revokeObjectURL(blobUrl);
                  resolve();
                })
                .catch((error) => {
                  window.location.href = "/logout";
                  reject(error);
                });
            }, index * 500); // Delay each download by 500ms
          });
        }
      );
      await Promise.all(downloadPromises);
      setIsProcessing("");
      // After all downloads have completed, then remove the files
      const removeFilesApiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/export/files/remove`,
        jwt_token,
        body: {
          export_files: generatedFiles.data.files.join("#"),
        },
      };
      const fileRemove = await fetchApi(removeFilesApiParams);
      if (fileRemove?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (fileRemove?.statusCode == 403) {
        location.reload();
      }
      break;
    default:
      break;
  }
}

async function uploadFiles(user_id: string, jwt_token: string) {
  let message: string = messages.error.error;
  let theme: string = "danger";
  const exportFile: any = await uploadFilesToService(user_id, jwt_token);
  if (exportFile && exportFile.status) {
    message = messages.form[exportFile.message];
    theme = "success";
  }
  notify({
    isNotified: true,
    message,
    theme,
    placement: "top",
    timeout: 2500,
  });
  setIsProcessing("");
  localStorage.removeItem("fc_export_files");
  localStorage.removeItem("fc_export_type");
}
