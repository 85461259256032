import { useRouteData } from "solid-start";
import { Component, onMount } from "solid-js";
import { notify } from "~/components/global/Notification";
import Popup from "~/components/global/Popup";
import SystemLayout from "~/components/layouts/system.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { setPopup } from "~/store/global.store";
import { validateUser } from "./api/validateUser";

export function routeData() {
  return validateUser();
}

const dashboard: Component = () => {
  useRouteData<typeof routeData>();
  setPageTitle(`${SITE_TITLE} - Dashboard`);
  function playNotification(theme: string = "success") {
    notify({
      isNotified: true, // required parameter
      message: "Dashboard updated successfully!!!", // required parameter
      theme: theme, // optional parameter // default success
      timeout: 2500, // optional parameter
    });
  }
  return (
    <main>
      <SystemLayout>
        <button class="btn primary" onClick={() => playNotification()}>
          Play notification
        </button>
        &nbsp;&nbsp;
        <button class="btn primary" onClick={() => playNotification("danger")}>
          Danger notification
        </button>
        &nbsp;&nbsp;
        <button class="btn primary" onClick={() => playNotification("warm")}>
          Warm notification
        </button>
        &nbsp;&nbsp;
        <button class="btn primary" onClick={() => playNotification("invert")}>
          Invert notification
        </button>
        &nbsp;
        <button onClick={() => setPopup("parent")}>Parent popup</button>
        <Popup heading={"Form Access Rights"} classes="sm" name="parent">
          <button onClick={() => setPopup("child")}>Child popup</button>
          <br />
          <br />
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eos saepe
          esse expedita vitae consequatur molestiae fugiat suscipit consequuntur
          ipsum? Expedita consectetur, similique ratione dicta reiciendis
          explicabo illum molestias eos tempore!
          <Popup heading="another popup" classes="sm" name="child">
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Amet rerum
            quidem alias ducimus
          </Popup>
        </Popup>
      </SystemLayout>
    </main>
  );
};

export default dashboard;
