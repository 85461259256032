import { Component, createEffect, createSignal, onMount, Show } from "solid-js";
import { createStore } from "solid-js/store";
import { useNavigate, useRouteData, useSearchParams } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import SelectOne from "~/components/global/SelectOne";
import Icons from "~/components/icons/Icons";
import AuthLayout from "~/components/layouts/auth.layout";
import { getUser } from "~/db/session";
import blurInput from "~/directives/blur-input.directive";
import {
  ANNUAL_SUBSCRIPTION_PRICE,
  CURRENCY_SYMBOL,
  MONTHLY_SUBSCRIPTION_PRICE,
} from "~/helpers/constant";
import fetchApi from "~/lib/api";

const inputLbl = blurInput;

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails == null) {
      throw redirect("/login");
    }
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    let isUserLogin = false;
    if (userDetails) {
      isUserLogin = true;
      if (userDetails.data.role.role_name == "super_admin") {
        throw redirect("/admin/dashboard");
      } else if (userDetails.data.role.role_name == "admin") {
        if (
          userDetails !== null &&
          userDetails.data?.subscription &&
          userDetails.data?.subscription?.plan_name !== "" &&
          userDetails.data?.subscription?.state !== "cancelled"
        ) {
          return redirect("/form/list");
        }
      } else {
        throw redirect("/logout");
      }
    }

    const TeamPlanId = process.env.PADDLE_TEAM_PLAN_ID;
    const TeamAnuualPlanId = process.env.PADDLE_TEAM_ANNUAL_PLAN_ID;

    return {
      isUserLogin,
      userDetails,
      TeamPlanId,
      TeamAnuualPlanId,
    };
  });
}

const checkout: Component = () => {
  const data: any = useRouteData<typeof routeData>();

  const navigate: any = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [customPlan, setCustomPlan] = createSignal([] as any);
  const [discountCoupon, setDiscountCoupon] = createSignal("" as string);
  const [planName, setPlanName] = createSignal("Team" as string);
  const [planQuantity, setPlanQuantity] = createSignal(1 as number);
  const [discountPrice, setDiscountPrice] = createStore({
    month: `${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE.toFixed(2)}`,
    year: `${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}`,
  }) as any;

  onMount(async () => {
    const { discount } = { ...searchParams } as { discount: string };
    if (discount) {
      setDiscountPrice({
        month: `${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE.toFixed(2)}`,
        year: `${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}`,
      });
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/subscription/discount/data`,
        body: {
          transaction_id: localStorage.getItem("discount")
            ? localStorage.getItem("discount")
            : discount,
        },
      };
      let discountData = await fetchApi(apiParams);
      if (discountData?.statusCode == 401 || discountData?.statusCode == 403) {
        window.location.href = "/logout";
      }
      setDiscountPrice({
        month:
          discountData.data.restrict_to?.includes(data()?.TeamPlanId) ||
          !discountData.data.restrict_to
            ? discountData.data.type == "percentage"
              ? `<span class="line-through">${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE.toFixed(
                  2
                )}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  MONTHLY_SUBSCRIPTION_PRICE -
                  (MONTHLY_SUBSCRIPTION_PRICE * discountData.data.amount) / 100
                }</span>`
              : `<span class="line-through">${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE.toFixed(
                  2
                )}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  MONTHLY_SUBSCRIPTION_PRICE -
                  Number(discountData.data.amount) / 100
                }</span>`
            : `${CURRENCY_SYMBOL}${MONTHLY_SUBSCRIPTION_PRICE.toFixed(2)}`,
        year:
          discountData.data.restrict_to?.includes(data()?.TeamAnuualPlanId) ||
          !discountData.data.restrict_to
            ? discountData.data.type == "percentage"
              ? `<span class="line-through">${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  ANNUAL_SUBSCRIPTION_PRICE -
                  (ANNUAL_SUBSCRIPTION_PRICE * discountData.data.amount) / 100
                }</span>`
              : `<span class="line-through">${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}</span>&nbsp;<span>${CURRENCY_SYMBOL}${
                  ANNUAL_SUBSCRIPTION_PRICE -
                  Number(discountData.data.amount) / 100
                }</span>`
            : `${CURRENCY_SYMBOL}${ANNUAL_SUBSCRIPTION_PRICE}`,
      });
      localStorage.setItem("discount", discountData.data.id);
      setDiscountCoupon(discountData.data.code);
    } else {
      localStorage.removeItem("discount");
    }
    const { plan } = { ...searchParams } as { plan: string };
    if (plan && plan != "Team" && plan != "Team Annual") {
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/subscription/plan/data`,
        body: {
          transaction_id: plan,
        },
      };
      let planData = await fetchApi(apiParams);
      if (planData?.statusCode == 401 || planData?.statusCode == 403) {
        window.location.href = "/logout";
      }
      setCustomPlan([
        {
          name: planData.data.id,
          display: `<div class="fs-24">${CURRENCY_SYMBOL}${
            planData.data.unit_price.amount / 100
          }</div><div class="pt-10">${
            planData.data.billing_cycle.interval == "year"
              ? "per Year"
              : "per Month"
          }</div>`,
        },
      ]);
    }
  });

  createEffect(() => {
    if ({ ...searchParams }) {
      const { plan } = { ...searchParams } as { plan: string };
      if (plan) {
        setPlanName(plan);
      }
      const { quantity } = { ...searchParams } as { quantity: string };
      if (quantity) {
        setPlanQuantity(Number(quantity));
      }
    }
    if (
      data()?.userDetails &&
      (planName() == "Team" || planName() == "Team Annual")
    ) {
      setPlanQuantity(
        data()?.userDetails?.data?.assignedLicences
          ? data()?.userDetails?.data?.assignedLicences + 1
          : planQuantity()
      );
    }
  });

  return (
    <AuthLayout size="md mih-398" hasNavbar={true}>
      <div class="clearfix"></div>
      <Show when={!data()?.userDetails?.data?.subscription?.plan_name}>
        <div class="text-center">
          <span class="badge trial sm lh-25">Your trial period is over.</span>
        </div>
      </Show>
      <br />
      <div class="h-100p flex flex-column space-between">
        <Show
          when={
            data()?.isUserLogin &&
            (!data()?.userDetails.data?.subscription ||
              data()?.userDetails.data?.subscription?.state == "cancelled")
          }
        >
          <div>
            <div class="flex space-between">
              <label class="flex">
                <div class="w-183p">User</div>
                <div class="text-gray">|</div>
              </label>
              <span class="lbl color text-right">
                {data()?.userDetails.data.username}
              </span>
            </div>
            <div class="underline"></div>
            <div class="flex space-between">
              <label class="flex">
                <div class="w-183p">Company</div>
                <div class="text-gray">|</div>
              </label>
              <span class="lbl color text-right">
                {data()?.userDetails.data.companies[0]?.company_name}
              </span>
            </div>
            <div class="underline"></div>
            <div class="flex space-between">
              <label class="flex">
                <div class="w-183p">Email</div>
                <div class="text-gray">|</div>
              </label>
              <span class="lbl color text-right">
                {data()?.userDetails.data.email}
              </span>
            </div>
            <div class="underline"></div>
            <div class="flex space-between">
              <label class="flex">
                <div class="w-183p">Subscription State</div>
                <div class="text-gray">|</div>
              </label>
              <span class="lbl color"> Inactive </span>
            </div>
            <div class="underline"></div>
            <div class="text-center">
              <span class="badge danger lh-25">
                <span> You don't have active subscription.</span> <br />
                <span> Please click on the subscribe button.</span>
              </span>
            </div>
            <div class="underline"></div>
          </div>
        </Show>
        <div>
          <Show when={planName() == "Team" || planName() == "Team Annual"}>
            <div class="w-100 pr">
              <div
                class={
                  planName() == "Team" ? "left-selected" : "right-selected"
                }
              >
                <Icons name="checkmark"></Icons>
              </div>
              <SelectOne
                class="full-width"
                set={[
                  {
                    display: `<div class="fs-24">${discountPrice.month}</div><div class="pt-10">per Month</div>`,
                    name: "Team",
                  },
                  {
                    display: `<div class="fs-24">${discountPrice.year}</div><div class="pt-10">per Year</div>`,
                    name: "Team Annual",
                  },
                ]}
                active={planName()}
                setActive={(val: string) => {
                  setPlanName(val);
                  setSearchParams({ plan: val });
                }}
              ></SelectOne>
            </div>
          </Show>
          <Show when={discountCoupon()}>
            <div class="text-right">
              <span class="lbl primary fs-12">*Discount coupon applied</span>
            </div>
          </Show>
          <Show when={planName() != "Team" && planName() != "Team Annual"}>
            <div class="w-100 pr">
              <div class="left-selected">
                <Icons name="checkmark"></Icons>
              </div>
              <SelectOne
                class="full-width"
                set={customPlan()}
                isSingle={true}
                active={planName()}
                setActive={(val: string) => {}}
              ></SelectOne>
            </div>
            <div class="text-right">
              <span class="lbl primary fs-12">*Custom plan applied</span>
            </div>
            <div class="text-right">
              <span class="lbl primary fs-12">
                *Min {planQuantity()} quantity
              </span>
            </div>
          </Show>
          <div class="clearfix"></div>
          <br />
          <div class="text-center">
            <button
              class="btn primary"
              onclick={() => {
                navigate(
                  `/pricing?plan=${
                    planName() +
                    (planQuantity() ? "&quantity=" + planQuantity() : "") +
                    (discountCoupon() ? "&discount=" + discountCoupon() : "")
                  }`
                );
              }}
            >
              Subscribe
            </button>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default checkout;
