import { Component, For, createEffect, createSignal, onMount } from "solid-js";
import { produce } from "solid-js/store";
import { formJson, setFormJson } from "~/store/form.store";
import { setIsFormSettingsUpdated } from "~/store/global.store";

const [primaryFields, setPrimaryFields] = createSignal([] as any);
const [primaryFieldsUpdated, setPrimaryFieldsUpdated] = createSignal(
  false as boolean
);
const [isMounted, setIsMounted] = createSignal(false as boolean);
const [fields, setFields] = createSignal([] as any);

const PrimaryFieldsSettings: Component = () => {
  createEffect(() => {
    if (isMounted()) {
      const tempArr: Array<string> =
        formJson.root.formStructure.primaryFields.flatMap((field: any) =>
          typeof field == "string"
            ? field.split(",").map((field: any) => field.trim())
            : field
        );
      if (tempArr?.length > 0) {
        tempArr.forEach((id: string) => {
          updatePrimaryFields(id, "add");
        });
      }
      if (tempArr?.length < 2) {
        for (let index = 0; index < 2 - tempArr?.length; index++) {
          updatePrimaryFields("", "add");
        }
      }
      setIsMounted(false);
    }
  });

  onMount(() => {
    setPrimaryFields([]);
    setPrimaryFieldsUpdated(false);
    setIsMounted(true);
  });

  function updatePrimaryFields(fieldId: string, type: string, index?: any) {
    let tempArr: any = [];
    formJson.pages.forEach((page: any, key: any) => {
      page.fields?.forEach((field: any) => {
        if (
          (field.type == "TextField" ||
            field.type == "DropdownField" ||
            field.type == "BarcodeField" ||
            field.type == "DateField" ||
            field.type == "RadioField" ||
            field.type == "LocationField") &&
          field.properties.inputType != "linked" &&
          field.properties?.dateInputType != "linked"
        ) {
          const tempObject: any = {
            id: field.properties.dataFieldSync.uid,
            label: field.properties.dataFieldSync.label,
            top: field.properties.dataFieldSync.frame[0][1],
            left: field.properties.dataFieldSync.frame[0][0],
            page: key,
          };
          tempArr.push(tempObject);
        }
      });
    });
    tempArr.sort((a: any, b: any) => {
      if (a.page !== b.page) {
        return a.page - b.page;
      }
      if (a.top === b.top) {
        return a.left - b.left;
      }
      return a.top - b.top;
    });
    tempArr = tempArr.map((item: any) => ({
      id: item.id,
      label: item.label,
    }));
    formJson.root.formStructure?.primaryFields.forEach((item: any) => {
      tempArr = tempArr.filter((obj: any) => obj.id != item);
    });
    setFields(tempArr);

    if (fieldId != "") {
      if (type == "add") {
        setFormJson(
          "root",
          produce((root: any) => {
            if (root.formStructure.primaryFields?.length == 1) {
              if (index == 0) {
                root.formStructure.primaryFields = [
                  ...new Set([fieldId, ...root.formStructure.primaryFields]),
                ];
              } else if (index == 1) {
                root.formStructure.primaryFields = [
                  ...new Set([...root.formStructure.primaryFields, fieldId]),
                ];
              }
            } else {
              if (
                [...primaryFields()]
                  .filter((item: any) => item.id != "")
                  .map((item: any) => item.id == fieldId)
                  .includes(true)
              ) {
                if (index == 0) {
                  root.formStructure.primaryFields = [
                    ...new Set([
                      fieldId,
                      ...[root.formStructure.primaryFields.shift()],
                    ]),
                  ];
                } else if (index == 1) {
                  root.formStructure.primaryFields = [
                    ...new Set([
                      ...[root.formStructure.primaryFields.pop()],
                      fieldId,
                    ]),
                  ];
                }
              } else {
                if (index == 0) {
                  root.formStructure.primaryFields = [
                    ...new Set([
                      fieldId,
                      ...[root.formStructure.primaryFields.pop()],
                    ]),
                  ];
                } else if (index == 1) {
                  root.formStructure.primaryFields = [
                    ...new Set([
                      ...[root.formStructure.primaryFields.shift()],
                      fieldId,
                    ]),
                  ];
                }
              }
            }
            root.formStructure.sortFields = root.formStructure.primaryFields;
          })
        );
      } else {
        setFormJson(
          "root",
          produce((root: any) => {
            root.formStructure.primaryFields =
              formJson.root.formStructure?.primaryFields.filter(
                (itemId: string) => fieldId != itemId
              );
            root.formStructure.sortFields =
              formJson.root.formStructure?.primaryFields.filter(
                (itemId: string) => fieldId != itemId
              );
          })
        );
      }
    }
    tempArr = [...primaryFields()];
    tempArr = tempArr.filter((item: any) => item.id != "");

    let removedField: any;
    if (fieldId != "") {
      if (type == "add") {
        let label: string = "";
        if (!tempArr.map((item: any) => item.id == fieldId).includes(true)) {
          formJson.pages.forEach((page: any) => {
            page.fields?.forEach((field: any) => {
              if (field.properties.dataFieldSync.uid == fieldId) {
                label = field.properties.dataFieldSync.label;
              }
            });
          });
          const tempObject: any = {
            id: fieldId,
            label,
          };
          if (index == 0) {
            if (tempArr.length > 1) {
              removedField = tempArr.shift();
            }
            tempArr.unshift(tempObject);
          } else {
            if (tempArr.length > 1 && index == 1) {
              removedField = tempArr.pop();
            }
            tempArr.push(tempObject);
          }
        } else {
          const fieldIndex = tempArr.findIndex(
            (item: any) => item.id == fieldId
          );
          if (fieldIndex != index) {
            const temp: any = [...tempArr];
            tempArr[0] = temp[1];
            tempArr[1] = temp[0];
          }
        }
      } else {
        tempArr = tempArr.filter((item: any) => item.id != fieldId);
      }
    }
    if (tempArr.length < 2) {
      const condition: number = tempArr.length;
      for (let index = 0; index < 2 - condition; index++) {
        tempArr.push({
          id: "",
          label: "<none>",
        });
      }
    }
    setPrimaryFields(tempArr);
    tempArr = [...fields()];
    if (type == "add") {
      if (removedField && removedField?.id) {
        tempArr.unshift(removedField);
      }
      tempArr = tempArr.filter((item: any) => item.id != fieldId);
    } else {
      tempArr = [];
      formJson.pages.forEach((page: any, key: any) => {
        page.fields?.forEach((field: any) => {
          if (
            (field.type == "TextField" ||
              field.type == "DropdownField" ||
              field.type == "BarcodeField" ||
              field.type == "DateField" ||
              field.type == "RadioField" ||
              field.type == "LocationField") &&
            field.properties.inputType != "linked" &&
            field.properties?.dateInputType != "linked"
          ) {
            const tempObject: any = {
              id: field.properties.dataFieldSync.uid,
              label: field.properties.dataFieldSync.label,
              top: field.properties.dataFieldSync.frame[0][1],
              left: field.properties.dataFieldSync.frame[0][0],
              page: key,
            };
            tempArr.push(tempObject);
          }
        });
      });
      tempArr.sort((a: any, b: any) => {
        if (a.page !== b.page) {
          return a.page - b.page;
        }
        if (a.top === b.top) {
          return a.left - b.left;
        }
        return a.top - b.top;
      });
      tempArr = tempArr.map((item: any) => ({
        id: item.id,
        label: item.label,
      }));
      formJson.root.formStructure?.primaryFields.forEach((item: any) => {
        tempArr = tempArr.filter((obj: any) => obj.id != item);
      });
    }
    setFields(tempArr);
  }

  function handleDragStart(e: any, id: string) {
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer?.setData("text/plain", id);
    const originalRect = e.target.getBoundingClientRect();
    const dragElement = e.target.cloneNode(true);
    dragElement.style.position = "fixed";
    dragElement.style.top = "-1000px";
    dragElement.style.width = `${originalRect.width}px`;
    dragElement.style.padding = "15px";
    dragElement.style.opacity = "0.8";
    dragElement.style.border = "1px solid #ccc";
    dragElement.style.background = "#fff";
    document.body.appendChild(dragElement);
    const offsetX = e.clientX - originalRect.left;
    const offsetY = e.clientY - originalRect.top;
    e.dataTransfer.setDragImage(dragElement, offsetX, offsetY);
    setTimeout(() => {
      document.body.removeChild(dragElement);
    }, 0);
  }

  function handleDragOver(e: any) {
    e.dataTransfer.dropEffect = "move";
    e.preventDefault();
  }

  function handleDrop(e: any, type: string) {
    e.stopPropagation();
    e.preventDefault();
    const primaryField1: any = document.getElementById("primary_field_0");
    const primaryField2: any = document.getElementById("primary_field_1");
    let index: any = -1;
    if (
      e.clientY >= primaryField1.getBoundingClientRect().y &&
      e.clientY <=
        primaryField1.getBoundingClientRect().y +
          primaryField1.getBoundingClientRect().height
    ) {
      index = 0;
    }
    if (
      e.clientY >= primaryField2.getBoundingClientRect().y &&
      e.clientY <=
        primaryField2.getBoundingClientRect().y +
          primaryField2.getBoundingClientRect().height
    ) {
      index = 1;
    }
    updatePrimaryFields(e.dataTransfer?.getData("text/plain"), type, index);
    setPrimaryFieldsUpdated(true);
    setIsFormSettingsUpdated(true);
  }

  return (
    <>
      <div>
        <div class="lbl">Currently used fields:</div>
        <div
          class="primary-fields-div"
          ondragover={(e) => handleDragOver(e)}
          ondrop={(e) => handleDrop(e, "add")}
        >
          <For each={primaryFields()}>
            {(item: any, key: any) => (
              <div
                id={`primary_field_${key()}`}
                class={`primary-fields-item ${
                  item.id != "" &&
                  !primaryFields()
                    .map((item: any) => item.label)
                    .includes("<none>")
                    ? "active"
                    : ""
                }`}
                draggable={
                  !primaryFields()
                    .map((item: any) => item.label)
                    .includes("<none>")
                    ? true
                    : false
                }
                ondragstart={(e) =>
                  !primaryFields()
                    .map((item: any) => item.label)
                    .includes("<none>")
                    ? handleDragStart(e, item.id)
                    : ""
                }
              >
                {item.label ? item.label : "Unnamed Field"}
              </div>
            )}
          </For>
        </div>
        <br />
        <div class="lbl">
          <small>Drag field up to use them for record names.</small>
        </div>
        <br />
        <span class="lbl">Other fields:</span>
        <div
          class="primary-fields-div"
          ondragover={(e) => handleDragOver(e)}
          ondrop={(e) => handleDrop(e, "remove")}
        >
          <For each={fields()}>
            {(item: any) => (
              <div
                class={`primary-fields-item ${
                  // primaryFields()
                  //   .map((item: any) => item.label)
                  //   .includes("<none>")
                  //   ?
                  "active"
                  // : ""
                }`}
                draggable={
                  // primaryFields()
                  //   .map((item: any) => item.label)
                  //   .includes("<none>")
                  //   ?
                  true
                  // : false
                }
                ondragstart={
                  (e) =>
                    // primaryFields()
                    //   .map((item: any) => item.label)
                    //   .includes("<none>")
                    //   ?
                    handleDragStart(e, item.id)
                  // : ""
                }
              >
                {item.label ? item.label : "Unnamed Field"}
              </div>
            )}
          </For>
        </div>
      </div>
    </>
  );
};

export { primaryFields, primaryFieldsUpdated, setFields };
export default PrimaryFieldsSettings;
