import convert from "./convertor";

export default function generateCSS(properties: any) {
  let cssString: string = "";
  Object.keys(properties).map((key: string) => {
    cssString += `${key}: ${properties[key].value}${
      properties[key].suffix ? properties[key].suffix : ""
    }; `;
  });
  return cssString;
}

export function generateLabelPosition(dataField: { [key: string]: string }) {
  const left = Number(convert(+dataField.frame[0][0], "in-px"));
  const top = convert(+dataField.frame[0][1], "in-px");
  const cssString = `opacity: 0; left: ${
    Number(left) - 26
  }px; top: ${top}px; height: ${Number(
    convert(+dataField.frame[1][1], "in-px")
  )}px`;
  return cssString;
}
