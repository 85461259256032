import { Component, For, Show, createSignal, onMount } from "solid-js";
import Popup from "../global/Popup";
import Icons from "../icons/Icons";
import ThemeSettings from "../form/themeSettings";
import { A, useNavigate } from "solid-start";
import {
  activeTheme,
  embedImageEnabled,
  fontSize,
  kioskModeEnabled,
  paperSize,
  pdfRecordsEnabled,
  pdfTextBoxEnabled,
  recordSortingDisabled,
  setEmbedImageEnabled,
  setFontSize,
  setPaperSize,
  setPdfRecordsEnabled,
  setPdfTextBoxEnabled,
  setRecordSortingDisabled,
} from "../form/formSettings";
import {
  activeField,
  formsList,
  removePopup,
  setActiveField,
  setFormsList,
  setIsFormSettingsUpdated,
  setPopup,
} from "~/store/global.store";
import IndexTabsSettings, {
  indexTabs,
  indexTabsUpdated,
} from "../form/IndexTabsSettings";
import ToolbarButtonsSettings, {
  toolbarButtons,
  toolbarButtonsUpdated,
} from "../form/ToolbarButtonsSettings";
import PrimaryFieldsSettings, {
  primaryFieldsUpdated,
  setFields,
} from "../form/PrimaryFieldsSettings";
import AutoExportSettings, {
  autoExportSettings,
} from "../form/AutoExportSettings";
import LocaleSettings, {
  formLocale,
  updateLocalesList,
} from "../form/LocaleSettings";
import { localList } from "~/store/locale.store";
import { formJson, setFormJson } from "~/store/form.store";
import Checkbox from "../global/Checkbox";
import RangeSlider from "../global/RangeSlider";
import { produce } from "solid-js/store";
import { notify } from "../global/Notification";
import fetchApi from "~/lib/api";
import messages from "~/helpers/message";
import { cloudServices } from "~/store/field-editor.store";
import { isDataSaving, setIsDataSaving } from "../global/FormNavbar";
import { recordsList, updateFormRecordsList } from "~/store/records.store";

interface Props {
  user_id: string;
  jwt_token: string;
  form_id: string;
}

const [subSettingPanel, setSubSettingPanel] = createSignal("" as string);

const FormSettingsPopup: Component<Props> = (props: any) => {
  const paperSizes: Array<string> = ["Letter", "A4"];
  const navigate = useNavigate();

  const [settingSearchString, setSettingSearchString] = createSignal(
    "" as string
  );

  onMount(() => {
    setSubSettingPanel("");
  });

  async function saveFormSettings() {
    setIsDataSaving(true);
    setFormJson(
      "root",
      produce((root: any) => {
        root.formStructure.theme = activeTheme();
        root.formStructure.isPDFTextBoxEnabled = pdfTextBoxEnabled();
        root.formStructure.isPDFRecordsEnabled = pdfRecordsEnabled();
        root.formStructure.isEmbedImageEnabled = embedImageEnabled();
        root.formStructure.isKioskModeEnabled = kioskModeEnabled();
        root.formStructure.isRecordSortingDisabled = recordSortingDisabled();
        root.formStructure.defaultFontSize = Number(fontSize());
        root.formStructure.paperSize = paperSize();
        if (formLocale() != "") {
          root.formStructure.locale = formLocale();
        }
        if (indexTabsUpdated()) {
          const finalIndexTabs: any = indexTabs().map((item: any) => ({
            tabTitle: item.value.tabTitle,
            page: item.value.page,
          }));
          root.formStructure.indexTabs = finalIndexTabs;
        }
        if (toolbarButtonsUpdated()) {
          const enabledToolbarIcons: any = toolbarButtons()
            .filter((obj: any) => obj?.isEnabled)
            .map((obj: any) => ({ type: obj.type }));
          root.formStructure.enabledToolbarIcons = enabledToolbarIcons;
        }
        if (autoExportSettings?.service != "none") {
          root.formStructure.autoExportSetting = autoExportSettings;
        } else {
          delete root.formStructure?.autoExportSetting;
        }
      })
    );
    let message: string = messages.warn.update_form_settings;
    let theme: string = "warm";
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/structure/update`,
      jwt_token: props.jwt_token,
      body: {
        user_id: props.user_id,
        form_id: formJson.root.formStructure?.uuid,
        structure: {
          ...formJson.root.formStructure,
          pages: [],
          lastSaved: new Date().toISOString(),
        },
        device_type: "web",
      },
    };
    let data = await fetchApi(apiParams);
    if (data?.statusCode == 401) {
      window.location.href = "/logout";
    } else if (data?.statusCode == 403) {
      location.reload();
    }
    if (data.status) {
      theme = "success";
      message = messages.form.update_form_settings;
      // if (
      //   formJson.root.formStructure?.sortFields == undefined ||
      //   formJson.root.formStructure?.sortFields.length == 0
      // ) {
      if (primaryFieldsUpdated()) {
        const formRecordsApiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/records/name/update`,
          jwt_token: props.jwt_token as string,
          body: {
            user_id: props.user_id,
            form_id: formJson.root.formStructure?.uuid,
          },
        };
        let formRecords = await fetchApi(formRecordsApiParams);
        if (formRecords?.statusCode == 401 || formRecords?.statusCode == 403) {
          window.location.href = "/logout";
        }
      }
      // if (formRecords && formRecords.status) {
      //   setUpdateRecordDetails({
      //     user_id: props.user_id,
      //     jwt_token: props.jwt_token,
      //     form_id: props.form_id,
      //   });
      //   setRecordsJson({
      //     records: formRecords.data.records,
      //   });
      //   setIsDataSaving(true);
      //   setRecordsJson(
      //     "records",
      //     produce((records: any) => {
      //       records.forEach(async (record: any) => {
      //         const formRecordsApiParams = {
      //           method: "POST",
      //           url: `${import.meta.env.VITE_API_URL}/form/record`,
      //           jwt_token: props.jwt_token,
      //           body: {
      //             user_id: props.user_id,
      //             record_id: [record.record_id],
      //           },
      //         };
      //         let recordData = await fetchApi(formRecordsApiParams);
      //         if (
      //           recordData?.statusCode == 401 ||
      //           recordData?.statusCode == 403
      //         ) {
      //           window.location.href = "/logout";
      //         }
      //         record.pages = JSON.parse(recordData.data[0].record).pages;
      //         const newFormName: any = [];
      //         const fieldMapping: any = {};
      //         record.pages.forEach((page: any) => {
      //           page.fields?.forEach((field: any) => {
      //             let type = "";
      //             formJson.pages.forEach((page: any) => {
      //               page.fields?.forEach((item: any) => {
      //                 if (item.properties.dataFieldSync.uid == field.field_id) {
      //                   type = item.type;
      //                 }
      //               });
      //             });
      //             fieldMapping[field.field_id] =
      //               type == "DateField"
      //                 ? field.value.replaceAll("-", "/")
      //                 : field.additional_data?.display_value
      //                 ? field.additional_data.display_value
      //                 : field.value;
      //           });
      //         });
      //         const sortedFieldIds =
      //           formJson.root.formStructure.primaryFields.filter(
      //             (id: any) => fieldMapping[id] !== undefined
      //           );
      //         newFormName.push(
      //           ...sortedFieldIds.map((id: any) => fieldMapping[id])
      //         );
      //         record.name =
      //           newFormName.join(" ").trim() != ""
      //             ? newFormName.join(" ")
      //             : "Unnamed";
      //         record.record_name =
      //           newFormName.join(" ").trim() != ""
      //             ? newFormName.join(" ")
      //             : "Unnamed";
      //         record.record_id = record.id;
      //         setRecordsJson({
      //           currentRecord: record,
      //         });
      //         setIsRecordSubmit(true);
      //         updateRecord();
      //       });
      //     })
      //   );
      // }
      // }
      if (recordsList()?.length > 0) {
        updateFormRecordsList(props.form_id, 0);
      }
      setFormsList(
        formsList().map((form: any) => {
          if (form.id == location.pathname.split("/")[3]) {
            return { ...form, theme: activeTheme() };
          }
          return form;
        })
      );
    } else {
      theme = "error";
      message = messages.error.update_form_settings;
    }
    setIsDataSaving(false);
    removePopup("formSettings");
    notify({
      isNotified: true,
      message,
      theme,
      placement: "top",
      timeout: 2500,
    });
  }

  return (
    <Popup heading={"Form Settings"} classes="medium" name="formSettings">
      <div
        class="form-settings-div"
        onclick={(e: any) => {
          if (e.target.id != activeField()) {
            setActiveField("");
          }
        }}
      >
        <Show when={subSettingPanel() == ""}>
          <div>
            <div>
              <div
                class="form-settings-field"
                onclick={() => {
                  setFields([]);
                  setSubSettingPanel("PrimaryFieldsSettings");
                }}
              >
                <div class="flex app-flex flex-column">
                  <label>Primary Fields</label>
                  <small>
                    Records will be named and tagged based on these fields.
                  </small>
                </div>
                <Icons name="chevronRight"></Icons>
              </div>
              <div
                class="form-settings-field"
                onclick={() => setSubSettingPanel("ThemeSettings")}
              >
                <label>Theme</label>
                <Icons name="chevronRight"></Icons>
              </div>
              <div class="form-settings-field">
                <div class="flex app-flex flex-column">
                  <label>Use PDF text boxes</label>
                  <small>
                    Places a box around each field when you export the form or
                    print it.
                  </small>
                </div>
                <Checkbox
                  label=""
                  name="pdf_text_boxes"
                  value={(pdfTextBoxEnabled() ? true : false) as boolean}
                  setValue={(val: boolean) => {
                    setIsFormSettingsUpdated(true);
                    setPdfTextBoxEnabled(val);
                  }}
                />
              </div>
              <div class="form-settings-field">
                <div class="flex app-flex flex-column">
                  <label>Zip PDF records</label>
                  <small>
                    Reduces the size of a large exported file that contains a
                    lot of records.
                  </small>
                </div>
                <Checkbox
                  label=""
                  name="pdf_records"
                  value={(pdfRecordsEnabled() ? true : false) as boolean}
                  setValue={(val: boolean) => {
                    setIsFormSettingsUpdated(true);
                    setPdfRecordsEnabled(val);
                  }}
                />
              </div>
              <div
                class="form-settings-field"
                onclick={() => setSubSettingPanel("PaperSizeSettings")}
              >
                <div class="flex app-flex flex-column">
                  <label>Paper Size</label>
                  <small>{formJson.root.formStructure?.paperSize}</small>
                </div>
                <Icons name="chevronRight"></Icons>
              </div>
              <div class="form-settings-field">
                <div class="flex app-flex flex-column">
                  <label>Embed image data for CSV/XML/JSON</label>
                  <small>
                    When a form is exported, the images are extracted into a
                    series of jpgs. If you enable this setting, the images will
                    be formatted into a binary string.
                  </small>
                </div>
                <Checkbox
                  label=""
                  name="embed_image"
                  value={(embedImageEnabled() ? true : false) as boolean}
                  setValue={(val: boolean) => {
                    setIsFormSettingsUpdated(true);
                    setEmbedImageEnabled(val);
                  }}
                />
              </div>
              <div
                class="form-settings-field"
                onclick={() => setSubSettingPanel("LocaleSettings")}
              >
                <div class="flex app-flex flex-column">
                  <label>Locale</label>
                  <small>
                    {
                      localList.filter(
                        (obj: any) =>
                          obj.value === formJson.root.formStructure?.locale
                      )[0]?.label
                    }
                  </small>
                </div>
                <Icons name="chevronRight"></Icons>
              </div>
              <div class="form-settings-field">
                <label>Default font size</label>
                <div class="w-50 flex app-flex gap">
                  <div class="flex text-center badge w-50p">{fontSize()}</div>
                  <RangeSlider
                    step={0.5}
                    min={6}
                    max={60}
                    label=""
                    value={Number(fontSize())}
                    setValue={(val: string) => {
                      setFontSize(val);
                      setIsFormSettingsUpdated(true);
                    }}
                  />
                </div>
              </div>
              {/* <div class="form-settings-field">
                <div class="flex app-flex flex-column w-80">
                  <label>Enable kiosk mode</label>
                  <small>
                    An icon will appear in the toolbar. When activated, all
                    features other than filling out records are password-locked.
                  </small>
                </div>
                <Checkbox
                  label=""
                  name="kiosk_mode"
                  value={(kioskModeEnabled() ? true : false) as boolean}
                  setValue={(val: boolean) => {
                    setIsFormSettingsUpdated(true);
                    setKioskModeEnabled(val);
                  }}
                />
              </div> */}
              <div
                class="form-settings-field"
                onclick={() => setSubSettingPanel("AutoExportSettings")}
              >
                <div class="flex app-flex flex-column">
                  <label>Automatic Export</label>
                  <small>
                    {formJson.root.formStructure?.autoExportSetting
                      ? cloudServices.filter(
                          (service: any) =>
                            service.value ==
                            formJson.root.formStructure?.autoExportSetting
                              .service
                        )[0]?.label
                      : "Not in use"}
                  </small>
                </div>
                <Icons name="chevronRight"></Icons>
              </div>
              <div class="form-settings-field">
                <label>Disable Sorting of records</label>
                <Checkbox
                  label=""
                  name="record_sorting"
                  value={(recordSortingDisabled() ? true : false) as boolean}
                  setValue={(val: boolean) => {
                    setIsFormSettingsUpdated(true);
                    setRecordSortingDisabled(val);
                  }}
                />
              </div>
              <div
                class={`form-settings-field ${
                  formJson.pages?.length == 1 ? "disabled" : ""
                }`}
                onclick={() =>
                  formJson.pages?.length == 1
                    ? setPopup("indexFormAlert")
                    : setSubSettingPanel("IndexTabsSettings")
                }
              >
                <div class="flex app-flex flex-column">
                  <label>Index tabs</label>
                  <Show when={formJson.pages?.length > 1}>
                    <small>
                      Allows for the easy navigation between pages on your form.
                    </small>
                  </Show>
                  <Show when={formJson.pages?.length == 1}>
                    <small class="alert-text">
                      Creating an Index Tab requires the form to have at least
                      two pages.
                    </small>
                  </Show>
                </div>
                <Icons
                  name="chevronRight"
                  stroke={formJson.pages?.length == 1 ? "#8a8d8e" : ""}
                ></Icons>
              </div>
              <div
                class="form-settings-field"
                onclick={() => setSubSettingPanel("ToolbarButtonsSettings")}
              >
                <label>Manage Toolbar Buttons</label>
                <Icons name="chevronRight"></Icons>
              </div>
            </div>
          </div>
        </Show>
        <Show when={subSettingPanel() == "PrimaryFieldsSettings"}>
          <PrimaryFieldsSettings></PrimaryFieldsSettings>
        </Show>
        <Show when={subSettingPanel() == "ThemeSettings"}>
          <ThemeSettings></ThemeSettings>
        </Show>
        <Show when={subSettingPanel() == "PaperSizeSettings"}>
          <For each={paperSizes}>
            {(paper: string) => (
              <div
                class="locale-list"
                onclick={() => {
                  setIsFormSettingsUpdated(true);
                  setPaperSize(paper);
                }}
              >
                <div class="list-item">
                  <label>{paper}</label>
                  <Show when={paperSize() == paper}>
                    <Icons name="check"></Icons>
                  </Show>
                </div>
              </div>
            )}
          </For>
        </Show>
        <Show when={subSettingPanel() == "LocaleSettings"}>
          <LocaleSettings></LocaleSettings>
        </Show>
        <Show when={subSettingPanel() == "AutoExportSettings"}>
          <AutoExportSettings
            user_id={props.user_id}
            jwt_token={props.jwt_token}
            form_id={props.form_id}
          ></AutoExportSettings>
        </Show>
        <Show when={subSettingPanel() == "IndexTabsSettings"}>
          <IndexTabsSettings></IndexTabsSettings>
        </Show>
        <Show when={subSettingPanel() == "ToolbarButtonsSettings"}>
          <ToolbarButtonsSettings></ToolbarButtonsSettings>
        </Show>
      </div>
      <br />
      <div
        class={`w-100 flex app-flex ${
          subSettingPanel() == "" ? "justify-end" : "space-between"
        }`}
      >
        <Show when={subSettingPanel() != ""}>
          <A
            class="a-link inline-flex"
            href=""
            onclick={() => setSubSettingPanel("")}
          >
            <Icons name="arrowLeft" stroke="hsl(214, 56%, 41%)" />
            &nbsp; Back
          </A>
        </Show>
        <Show when={subSettingPanel() == "LocaleSettings"}>
          <div class="flex app-flex gap align-center pr w-50">
            <input
              type="text"
              class="input w-100"
              placeholder="Search Locale ..."
              onclick={() => {
                setActiveField("locale_search");
              }}
              value={settingSearchString()}
              oninput={(e: any) => {
                setSettingSearchString(e.target.value);
                updateLocalesList(e.target.value);
              }}
            />
            <Show
              when={
                activeField() == "locale_search" && settingSearchString() != ""
              }
            >
              <span
                class="pa z-index-1 cur-p r-5"
                onclick={() => {
                  setSettingSearchString("");
                  updateLocalesList("");
                }}
              >
                <Icons name="circleX" width={20} height={20}></Icons>
              </span>
            </Show>
          </div>
        </Show>
        <button
          class={`btn primary ${isDataSaving() ? "disabled" : ""}`}
          onclick={saveFormSettings}
        >
          <Show when={!isDataSaving()}>Save</Show>
          <Show when={isDataSaving()}>
            Saving&nbsp;<Icons name="loader"></Icons>
          </Show>
        </button>
      </div>
    </Popup>
  );
};

export { setSubSettingPanel };
export default FormSettingsPopup;
