import { redirect } from "solid-start/server";
import { createCookieSessionStorage } from "solid-start/session";
import fetchApi from "~/lib/api";

type LoginForm = {
  username: string;
  password: string;
  token: string;
};

type RegisterForm = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  company_name: string;
  register_mode: string;
};

export async function register({
  first_name,
  last_name,
  email,
  password,
  company_name,
  register_mode,
}: RegisterForm) {
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/auth/register`,
    jwt_token: "",
    body: {
      email,
      password,
      first_name,
      last_name,
      company_name,
      register_mode,
    },
  };
  let register = await fetchApi(apiParams);
  if (register.status) {
    const RegisteLoginForm: any = {
      username: email,
      password,
    };
    const loginDetails = await login(RegisteLoginForm);
    return loginDetails;
  } else {
    return register;
  }
}

export async function login({ username, password, token }: LoginForm) {
  const userObject = await fetch(`${import.meta.env.VITE_API_URL}/auth/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({
      email: username.trim(),
      password: password.trim(),
      token: token,
      device_type: "web",
      device_metadata: null,
    }),
  }).then((res) => res.json());

  if (!userObject.status || userObject.status == 401) return userObject.message;
  userObject.data.user.Authorization = userObject.data.Authorization;
  return userObject.data.user;
}

export const storage = createCookieSessionStorage({
  cookie: {
    name: "FC_session",
    secure: true,
    secrets: [import.meta.env.VITE_SESSION_SECRET_KEY],
    sameSite: "lax",
    path: "/",
    maxAge: 60 * 60 * 24 * 2, // 2 days
    httpOnly: true,
  },
});

export async function getUserSession(request: Request) {
  try {
    const session = await storage.getSession(request.headers.get("Cookie"));
    const sessionToken = session.get("sessionToken");
    if (!sessionToken) {
      throw redirect("/logout");
    }
    return session;
  } catch (error) {
    return null;
  }
}

export async function getUserId(request: Request) {
  const session: any = await getUserSession(request);
  const userId = session?.get("userId");
  if (!userId || typeof userId !== "string") return null;
  return userId;
}

export async function getAuthToken(request: Request) {
  const session: any = await getUserSession(request);
  const jwt_token = session?.get("jwt_token");
  if (!jwt_token || typeof jwt_token !== "string") return null;
  return jwt_token;
}

export async function requireUserId(
  request: Request,
  redirectTo: string = new URL(request.url).pathname
) {
  const session: any = await getUserSession(request);
  const userId = session?.get("userId");
  if (!userId || typeof userId !== "string") {
    const searchParams = new URLSearchParams([["redirectTo", redirectTo]]);
    throw redirect(`/login?${searchParams}`);
  }
  return userId;
}

export async function getUser(
  request: Request,
  just_user_data?: boolean,
  has_licences?: boolean,
  has_notifications?: boolean
) {
  const userId = await getUserId(request);
  const jwt_token = await getAuthToken(request);
  if (typeof userId !== "string") {
    return null;
  }

  try {
    const user = await fetch(`${import.meta.env.VITE_API_URL}/user/get`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${jwt_token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        user_id: userId,
        has_licences: has_licences ? true : false,
        just_user_data: just_user_data ? true : false,
        has_notifications: has_notifications ? true : false,
      }),
    }).then((res) => res.json());
    return user;
  } catch {
    throw logout(request);
  }
}

export async function logout(
  request: Request,
  user_id?: string,
  jwt_token?: string
) {
  const session = await storage.getSession(request.headers.get("Cookie"));
  // Clear all session data
  for (const key of Object.keys(session.data)) {
    if (key != "sessionToken") {
      session.unset(key);
    }
  }
  const apiParams = {
    method: "POST" as string,
    url: `${import.meta.env.VITE_API_URL}/auth/logout`,
    jwt_token,
    body: {
      user_id,
    },
  };
  await fetchApi(apiParams);
  return redirect("/login", {
    headers: {
      "Set-Cookie": await storage.destroySession(session),
    },
  });
}

async function generateSessionToken() {
  const encoder = new TextEncoder();
  const data = encoder.encode(Math.random().toString());
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export async function createUserSession(
  userId: string,
  jwt_token: string,
  redirectTo: string
) {
  const session = await storage.getSession();
  const sessionToken = generateSessionToken();

  session.set("userId", userId);
  session.set("jwt_token", jwt_token);
  session.set("sessionToken", sessionToken);

  return redirect(redirectTo, {
    headers: {
      "Set-Cookie": await storage.commitSession(session),
    },
  });
}
