import {
  calculateTextLines,
  formDesigner,
  formJson,
  setFormDesigner,
} from "~/store/form.store";
import convert from "./convertor";
import { labelFieldTop } from "~/store/global.store";

export function addField(fieldObject: any) {
  fieldObject.id = crypto.randomUUID();
  const updatedFieldObject = { ...fieldObject };
  setFormDesigner("fields", [...formDesigner.fields, updatedFieldObject]);
  setFormDesigner("currentItem", fieldObject);
}

interface AssignedProps {
  top: {
    value: number;
    suffix: string;
  };
  left: {
    value: number;
    suffix: string;
  };
  width: {
    value: number;
    suffix: string;
  };
  height: {
    value: number;
    suffix: string;
  };
  border: {
    value: number | string;
    suffix: string;
  };
  "border-top": {
    value: number | string;
    suffix: string;
  };
  "border-left": {
    value: number | string;
    suffix: string;
  };
  "border-radius": {
    value: number | string;
    suffix: string;
  };
  "text-align": {
    value: string;
    suffix: string;
  };
  "justify-content": {
    value: string;
    suffix: string;
  };
  color: {
    value: string;
    suffix: string;
  };
  "font-family": {
    value: string;
    suffix: string;
  };
  "font-weight": {
    value: string;
    suffix: string;
  };
  "font-style": {
    value: string;
    suffix: string;
  };
  "font-size": {
    value: string;
    suffix: string;
  };
  "background-color": {
    value: string;
    suffix: string;
  };
  "white-space": {
    value: string;
    suffix: string;
  };
  "line-height": {
    value: string | number;
    suffix: string;
  };
  overflow: {
    value: string;
    suffix: string;
  };
  "padding-top": {
    value: string;
    suffix: string;
  };
  "word-break": {
    value: string;
    suffix: string;
  };
}

export function assignFieldProperties(
  properties: { [key: string]: any },
  fieldsType?: any
) {
  const fontFamily: any = {
    0: "Times New Roman",
    1: "Helvetica",
    2: "Courier New",
  };

  const assignedProperties: AssignedProps = {
    top: { value: 0, suffix: "px" },
    left: { value: 0, suffix: "px" },
    width: { value: 0, suffix: "px !important" },
    height: { value: 0, suffix: "px" },
    border: { value: 1, suffix: "px solid" },
    "border-top": { value: 0, suffix: "" },
    "border-left": { value: 0, suffix: "" },
    "border-radius": { value: 0, suffix: "" },
    "text-align": { value: "center", suffix: "" },
    "justify-content": { value: "", suffix: "" },
    color: { value: "", suffix: "" },
    "font-family": { value: "", suffix: "" },
    "font-weight": { value: "", suffix: "" },
    "font-style": { value: "", suffix: "" },
    "font-size": { value: "", suffix: "" },
    "background-color": { value: "", suffix: "" },
    "white-space": { value: "", suffix: "" },
    "line-height": { value: "", suffix: "" },
    overflow: { value: "", suffix: "" },
    "padding-top": { value: "", suffix: "" },
    "word-break": { value: "", suffix: "" },
  };
  assignedProperties.top.value =
    properties?.isVertical != undefined
      ? properties.isVertical
        ? Number(convert(properties.dataFieldSync.frame[0][1], "in-px")) -
          Number(properties.thickness)
        : Number(convert(properties.dataFieldSync.frame[0][1], "in-px")) +
          Number(convert(properties.dataFieldSync.frame[1][1], "in-px")) / 2 -
          properties?.thickness
      : properties.dataFieldSync.type == "SignatureField" ||
        properties.dataFieldSync.type == "NotesField"
      ? Number(convert(properties.dataFieldSync.frame[0][1], "in-px")) + 4
      : Number(convert(properties.dataFieldSync.frame[0][1], "in-px"));
  assignedProperties.left.value =
    properties?.isVertical != undefined
      ? properties.isVertical
        ? Number(convert(properties.dataFieldSync.frame[0][0], "in-px")) -
          21 +
          Number(convert(properties.dataFieldSync.frame[1][0], "in-px")) / 2 -
          properties?.thickness
        : Number(convert(properties.dataFieldSync.frame[0][0], "in-px")) - 21
      : properties.dataFieldSync.type == "SignatureField" ||
        properties.dataFieldSync.type == "NotesField"
      ? Number(convert(properties.dataFieldSync.frame[0][0], "in-px")) -
        (String(properties.dataFieldSync.parentTableId) != "0" ? 17 : 21)
      : Number(convert(properties.dataFieldSync.frame[0][0], "in-px")) - 21;
  assignedProperties.width.value =
    properties?.isVertical != undefined
      ? properties.isVertical
        ? 0
        : Number(convert(properties.dataFieldSync.frame[1][0], "in-px"))
      : properties.dataFieldSync.type == "SignatureField" ||
        properties.dataFieldSync.type == "NotesField"
      ? Number(convert(properties.dataFieldSync.frame[1][0], "in-px")) - 8
      : properties.dataFieldSync.type == "CheckBoxField" &&
        fieldsType == "preview" &&
        String(properties.dataFieldSync.parentTableId) == "0"
      ? Number(convert(properties.dataFieldSync.frame[1][0], "in-px")) + 10
      : // New Code
      properties.dataFieldSync.type == "CheckBoxField" &&
        String(properties.dataFieldSync.parentTableId) == "0"
      ? Number(convert(properties.dataFieldSync.frame[1][0], "in-px")) + 3
      : Number(convert(properties.dataFieldSync.frame[1][0], "in-px"));
  assignedProperties.height.value =
    properties?.isVertical != undefined
      ? properties.isVertical
        ? Number(convert(properties.dataFieldSync.frame[1][1], "in-px")) +
          Number(properties.thickness) * 2
        : 0
      : properties.dataFieldSync.type == "LocationField"
      ? Number(convert(properties.dataFieldSync.frame[1][1], "in-px")) -
        Number(
          convert(
            Math.round((properties.dataFieldSync.fontSize / 72) * 4 + 0.49) /
              4 +
              0.125,
            "in-px"
          )
        )
      : properties.dataFieldSync.type == "SignatureField" ||
        properties.dataFieldSync.type == "NotesField"
      ? Number(convert(properties.dataFieldSync.frame[1][1], "in-px")) - 8
      : String(properties.dataFieldSync.parentTableId) != "0" &&
        properties.dataFieldSync.frame[1][1] < 0.375 &&
        properties.dataFieldSync.frame[1][1] > 0.1875
      ? 24
      : properties.dataFieldSync.type == "LabelField" &&
        String(properties.dataFieldSync.parentTableId) == "0" &&
        Number(convert(properties.dataFieldSync.frame[1][1], "in-px")) > 36 &&
        properties.dataFieldSync.label &&
        calculateTextLines(
          properties.dataFieldSync.label,
          Number(convert(properties.dataFieldSync.frame[1][0], "in-px")),
          (Math.round(
            (Number(properties.dataFieldSync.fontSize) / 72) * 84 * 4
          ) /
            4 /
            84) *
            96,
          properties.font == 0
            ? "Times New Roman"
            : properties.font == 1
            ? "Helvetica"
            : "Courier New"
        ) == 1
      ? (Math.round(
          (((Math.round(
            (Number(properties.dataFieldSync.fontSize) / 72) * 84 * 4
          ) /
            4 /
            84) *
            96) /
            72) *
            4 *
            0.49
        ) /
          4 +
          0.125) *
        96
      : properties.dataFieldSync.type == "TextField" &&
        properties.dataFieldSync.frame[1][1] > 0.375
      ? Number(convert(properties.dataFieldSync.frame[1][1], "in-px")) - 1
      : Number(convert(properties.dataFieldSync.frame[1][1], "in-px"));

  if (
    properties.dataFieldSync.type == "SignatureField" &&
    String(properties.dataFieldSync.parentTableId) != "0" &&
    properties.dataFieldSync?.tableCellAddress?.split("")[1] != "0"
  ) {
    assignedProperties.top.value = assignedProperties.top.value - 4;
    assignedProperties.height.value = assignedProperties.height.value + 8;
    assignedProperties.left.value = assignedProperties.left.value - 4;
    assignedProperties.width.value = assignedProperties.width.value + 8;
  }

  assignedProperties.border.value =
    String(properties.dataFieldSync.parentTableId) != "0" ||
    properties.dataFieldSync.type == "BarcodeField" ||
    properties.dataFieldSync.type == "SignatureField" ||
    properties.dataFieldSync.type == "NotesField" ||
    properties.dataFieldSync.type == "AnnotateField" ||
    properties.dataFieldSync.type == "LocationField"
      ? 1
      : properties?.thickness
      ? properties.thickness
      : "";
  assignedProperties.border.suffix =
    String(properties.dataFieldSync.parentTableId) != "0" ||
    properties.dataFieldSync.type == "BarcodeField" ||
    properties.dataFieldSync.type == "SignatureField" ||
    properties.dataFieldSync.type == "NotesField" ||
    properties.dataFieldSync.type == "AnnotateField" ||
    properties.dataFieldSync.type == "LocationField"
      ? "px solid black"
      : properties?.thickness
      ? `px ${properties.lineStyle} ${properties.color}`
      : "";

  assignedProperties["border-top"].value =
    String(properties.dataFieldSync.parentTableId) != "0" ? 0 : "";

  assignedProperties["border-left"].value =
    String(properties.dataFieldSync.parentTableId) != "0" ? 0 : "";

  assignedProperties["border-radius"].value =
    String(properties.dataFieldSync.parentTableId) != "0" ? 0 : "";

  if (properties.dataFieldSync?.tableCellAddress?.includes(0)) {
    assignedProperties["border-top"].value = "1px solid black";
  }

  if (properties.dataFieldSync?.tableCellAddress?.includes("A")) {
    assignedProperties["border-left"].value = "1px solid black";
  }

  assignedProperties["text-align"].value =
    (formJson.currentField?.inputType == "calculated" ||
      formJson.currentField?.dateInputType == "calculated") &&
    properties.dataFieldSync?.type != "LabelField"
      ? "center"
      : properties.dataFieldSync?.alignment.split(".")[0];

  assignedProperties["justify-content"].value =
    String(properties.dataFieldSync.parentTableId) != "0" &&
    properties.dataFieldSync.type == "CheckBoxField"
      ? "center"
      : "";

  assignedProperties.color.value =
    properties.dataFieldSync.type == "LabelField" && properties?.color
      ? properties.color
      : "";

  if (properties.dataFieldSync.type != "ScriptButtonField") {
    assignedProperties["font-size"].value = properties.dataFieldSync?.fontSize
      ? String(
          (Math.round(
            (Number(properties.dataFieldSync.fontSize) / 72) * 84 * 4
          ) /
            4 /
            84) *
            96
        )
      : "";
  } else {
    assignedProperties["font-size"].value = "20";
  }
  assignedProperties["font-size"].suffix = properties.dataFieldSync?.fontSize
    ? "px"
    : "";
  assignedProperties["background-color"].value =
    properties.dataFieldSync.type == "LabelField"
      ? String(properties.dataFieldSync.parentTableId) != "0" &&
        properties.dataFieldSync.tableCellAddress.includes(0)
        ? properties?.backgroundColor == "#000000"
          ? "#86BDFB"
          : properties?.backgroundColor
        : properties?.backgroundColor == "#000000"
        ? "transparant"
        : properties?.backgroundColor
      : "";

  if (properties.dataFieldSync.type == "PhotoField") {
    assignedProperties["background-color"].value = "#ccc";
  }

  if (
    properties.dataFieldSync.type == "LabelField" &&
    String(properties.dataFieldSync.parentTableId) != "0"
  ) {
    // assignedProperties["white-space"].value = "nowrap";
    // assignedProperties["line-height"].value = assignedProperties.height.value;
    // assignedProperties["line-height"].suffix = "px";
    // assignedProperties["overflow"].value = "hidden";
  }

  if (
    properties.dataFieldSync.type == "LabelField" &&
    String(properties.dataFieldSync.parentTableId) == "0"
  ) {
    assignedProperties["word-break"].value = "break-word";
    const element: any = document.getElementById(
      `label_${properties.dataFieldSync.uid}`
    );
    if (element) {
      // Old Code
      // const originalWhiteSpace = element.style.whiteSpace;
      // element.style.whiteSpace = "nowrap";
      // element.style.whiteSpace = originalWhiteSpace;
      // if (
      //   Array.from(element.childNodes).filter(
      //     (node: any) =>
      //       node.nodeType !== Node.TEXT_NODE &&
      //       node.nodeName.toLowerCase() !== "meta" &&
      //       node.nodeName.toLowerCase() !== "title"
      //   ).length > 1
      // ) {
      //   assignedProperties["padding-top"].value = String(
      //     // Old Code
      //     // Math.abs(
      //     //   Math.round(
      //     //     (assignedProperties["font-size"].value / 72) * 4 + 0.49
      //     //   ) /
      //     //     4 +
      //     //     0.125 -
      //     //     assignedProperties["font-size"].value * 1.2
      //     // )
      //     // New Code
      //     Math.abs(
      //       Math.round(
      //         (assignedProperties["font-size"].value / 72) * 4 + 0.49
      //       ) /
      //         4 +
      //         0.125 -
      //         assignedProperties["font-size"].value * 1.2
      //     ) /
      //       Array.from(element.childNodes).filter(
      //         (node: any) =>
      //           node.nodeType !== Node.TEXT_NODE &&
      //           node.nodeName.toLowerCase() !== "meta" &&
      //           node.nodeName.toLowerCase() !== "title"
      //       ).length
      //   );
      //   assignedProperties["padding-top"].suffix = "px";
      // } else {
      //   assignedProperties["justify-content"].value = "center";
      // }
      // New Code
      if (
        calculateTextLines(
          properties.dataFieldSync.label,
          Number(convert(properties.dataFieldSync.frame[1][0], "in-px")),
          (Math.round(
            (Number(properties.dataFieldSync.fontSize) / 72) * 84 * 4
          ) /
            4 /
            84) *
            96,
          properties.font == 0
            ? "Times New Roman"
            : properties.font == 1
            ? "Helvetica"
            : "Courier New"
        ) > 1 ||
        properties.dataFieldSync.frame[1][1] > 0.375
      ) {
        assignedProperties["padding-top"].value =
          labelFieldTop[properties.dataFieldSync.fontSize];
        assignedProperties["padding-top"].suffix = "px";
      } else {
        assignedProperties["justify-content"].value = "center";
      }
    }
  }

  return assignedProperties;
}
