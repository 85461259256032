import { Component, Show, createEffect } from "solid-js";
import blurInput from "~/directives/blur-input.directive";
import {
  activeField,
  searchString,
  setActiveField,
  setSearchString,
} from "~/store/global.store";
import Icons from "../icons/Icons";

const inputLbl = blurInput;
interface Props {
  onkeyup?: any;
  value?: string;
  placeholder: string;
  onEnter?: any;
  isNotGlobalSearch?: boolean;
}

const Search: Component<Props> = (props) => {
  createEffect(() => {
    if (props?.value) {
      setSearchString(props.value);
    } else {
      setSearchString("");
    }
  });

  return (
    <div class="flex align-center w-100 space-between pr">
      <div class="form-element">
        <input
          type="text"
          placeholder={props.placeholder}
          class="input"
          value={searchString() ? searchString() : ""}
          onkeyup={(e: any) => {
            if (!props?.isNotGlobalSearch) {
              setSearchString(e.target.value);
            }
            if (props?.onkeyup) {
              props.onkeyup(e.target.value);
            }
            if (e.key == "Enter" && props?.onEnter) {
              props.onEnter(e.target.value);
            }
          }}
          onclick={() => {
            setActiveField(props.placeholder);
          }}
          use:inputLbl
        />
      </div>
      <Show when={activeField() == props.placeholder && searchString() != ""}>
        <span
          class="pa z-index-1 cur-p r-5 t-7"
          onclick={() => {
            setSearchString("");
            if (props?.onkeyup) {
              props.onkeyup("");
            }
            if (props?.onEnter) {
              props.onEnter("");
            }
          }}
        >
          <Icons name="circleX" width={20} height={20}></Icons>
        </span>
      </Show>
    </div>
  );
};

export default Search;
