import { Component, createEffect, createSignal, For } from "solid-js";
import Popup from "../global/Popup";
import { foldersList } from "~/store/global.store";

interface Props {
  setValue: any;
}

const FoldersList: Component<Props> = (props) => {
  const [selectedFolder, setSelectedFolder] = createSignal("" as string);

  return (
    <Popup
      heading="Select Folder"
      classes="small"
      overlap={true}
      name="foldersList"
    >
      <ul class="share-form-users-div form-builder__panel--list w-100 p0">
        <For each={foldersList()}>
          {(folder: any) => (
            <li
              class={`flex gap-0_5 align-center ${
                selectedFolder() == folder.id ? "added" : ""
              }`}
              onclick={() => setSelectedFolder(folder.id)}
            >
              <img src="/form_themes/folder.png" class="folder-share-image" />
              <span>{folder.name}</span>
            </li>
          )}
        </For>
      </ul>
      <div class="underline"></div>
      <button
        class={`btn sm primary ${selectedFolder() == "" ? "disabled" : ""}`}
        onclick={() =>
          selectedFolder() != "" ? props.setValue(selectedFolder()) : ""
        }
      >
        Move To Folder
      </button>
    </Popup>
  );
};

export default FoldersList;
