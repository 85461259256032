import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Popup from "../global/Popup";
import {
  exportFilesData,
  fetchBoxFoldersList,
  fetchDropboxFoldersList,
  fetchGoogleDriveFoldersList,
  fetchOneDriveFoldersList,
  serviceTokens,
  updateServiceToken,
  uploadFilesToService,
} from "~/store/export-files.store";
import Icons from "../icons/Icons";
import {
  exportMailattachments,
  removePopup,
  setExportMailattachments,
} from "~/store/global.store";
import { A } from "solid-start";
import messages from "~/helpers/message";
import { notify } from "../global/Notification";
import { cloudServices } from "~/store/field-editor.store";

interface Props {
  user_id: string;
  jwt_token: string;
  isListPage?: boolean;
}

const ExportFilesPopup: Component<Props> = (props) => {
  const [isProcessing, setIsProcessing] = createSignal(false as boolean);
  const [isLoading, setIsLoading] = createSignal(false as boolean);

  createEffect(() => {
    if (props?.isListPage) {
      localStorage
        .getItem("fc_export_files")
        ?.split("#")
        .forEach((file: any) => {
          let fileUrl: string = `${
            import.meta.env.VITE_API_URL
          }/uploads/${file}`;
          fetch(fileUrl, { method: "HEAD" })
            .then((response) => {
              const fileSize: any = response.headers.get("Content-Length");
              setExportMailattachments([
                ...exportMailattachments(),
                {
                  fileName: file,
                  fileSize:
                    fileSize / 1024 > 1000
                      ? (fileSize / (1024 * 1024)).toFixed(1) + " MB"
                      : (fileSize / 1024).toFixed(0) + " KB",
                },
              ]);
            })
            .catch((err: any) => {
              window.location.href = "/logout";
            });
        });
    }
  });

  return (
    <Popup
      heading={`${
        cloudServices.filter(
          (service: any) =>
            service.value == localStorage.getItem("fc_export_type")
        )[0]?.label
      } Upload`}
      classes="medium"
      overlap={true}
      name="exportFiles"
      jwt_token={props.jwt_token}
    >
      <div class="form-group min-h-420">
        <div class="flex gap space-between align-center">
          <span>Account:</span>
          <span class="fs-12">
            {`${exportFilesData.displayName} ${
              exportFilesData.displayEmail
                ? `< ${exportFilesData.displayEmail} >`
                : ""
            }`}
          </span>
          <button
            class={`btn sm danger ${isProcessing() ? "disabled" : ""}`}
            onclick={async () => {
              if (!isProcessing()) {
                updateServiceToken(
                  props.user_id,
                  props.jwt_token,
                  localStorage.getItem("fc_export_type"),
                  ""
                );
                removePopup("exportFiles", props.jwt_token);
              }
            }}
          >
            Log out
          </button>
        </div>
        <br />
        <div class="app-flex flex gap flex-wrap">
          <For each={exportMailattachments()}>
            {(file: any) => (
              <div class="app-flex flex flex-column align-center w-100p word-wrap fs-12">
                <a
                  href={`
                    ${import.meta.env.VITE_API_URL}/uploads/${file.fileName}`}
                  target="_blank"
                  class="email-logo"
                >
                  <div
                    class="remove-icon"
                    onclick={(e: any) => {
                      e.preventDefault();
                      setExportMailattachments(
                        exportMailattachments().filter(
                          (item: any) => item.fileName != file.fileName
                        )
                      );
                    }}
                  >
                    <Icons
                      name="circleMinus"
                      width={14}
                      height={14}
                      stroke="red"
                    ></Icons>
                  </div>
                </a>
                <div>{file.fileName.replace(/_\d+\.(\w+)$/, ".$1")}</div>
                <div class="fs-12 color">{file.fileSize}</div>
              </div>
            )}
          </For>
        </div>
        <br />
        <div class="bg-light lbl p-5 fw-800">
          {`${
            cloudServices.filter(
              (service: any) =>
                service.value == localStorage.getItem("fc_export_type")
            )[0]?.label
          }${exportFilesData.path.replaceAll("/", " > ")}`}
        </div>
        <br />
        <div
          class={`app-flex flex gap flex-wrap ${
            isLoading() ? "justify-center" : ""
          }`}
        >
          <Show when={isLoading()}>
            <div class="app-flex flex align-center">
              <h3 class="lbl">Loading</h3>&nbsp;
              <Icons name="loader"></Icons>
            </div>
          </Show>
          <Show when={!isLoading()}>
            <For each={exportFilesData.foldersList}>
              {(folder: any) => (
                <div
                  class="pr"
                  onclick={() => {
                    setIsLoading(true);
                    switch (localStorage.getItem("fc_export_type")) {
                      case "dropbox":
                        fetchDropboxFoldersList(
                          serviceTokens()["dropbox"],
                          exportFilesData.path + "/" + folder.name
                        ).then(() => setIsLoading(false));
                        break;
                      case "box":
                        fetchBoxFoldersList(
                          props.jwt_token,
                          serviceTokens()["box"],
                          folder.id,
                          `${exportFilesData.path}/${folder.name}`,
                          `${exportFilesData.idsPath}/${folder.id}`
                        ).then(() => setIsLoading(false));
                        break;
                      case "onedrive":
                        fetchOneDriveFoldersList(
                          serviceTokens()["onedrive"],
                          folder.id,
                          `${exportFilesData.path}/${folder.name}`,
                          `${exportFilesData.idsPath}/${folder.id}`
                        ).then(() => setIsLoading(false));
                        break;
                      case "googledrive":
                        fetchGoogleDriveFoldersList(
                          serviceTokens()["googledrive"],
                          folder.id,
                          `${exportFilesData.path}/${folder.name}`,
                          `${exportFilesData.idsPath}/${folder.id}`
                        ).then(() => setIsLoading(false));
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <img
                    src="/form_themes/folder.png"
                    class="service-folder-image"
                  />
                  <label class="service-folder-name">{folder.name}</label>
                </div>
              )}
            </For>
          </Show>
        </div>
        <br />
        <div class="pa b-0 w-100">
          <div class="app-flex flex space-between">
            <div>
              <Show when={exportFilesData.path != ""}>
                <A
                  class="a-link inline-flex"
                  href=""
                  onclick={() => {
                    setIsLoading(true);
                    const segments = exportFilesData.path.split("/");
                    let idsPath: any;
                    segments.pop();
                    switch (localStorage.getItem("fc_export_type")) {
                      case "dropbox":
                        fetchDropboxFoldersList(
                          serviceTokens()[
                            localStorage.getItem("fc_export_type")
                          ],
                          segments.join("/")
                        ).then(() => setIsLoading(false));
                        break;
                      case "box":
                        idsPath = exportFilesData.idsPath.split("/");
                        idsPath.pop();
                        fetchBoxFoldersList(
                          props.jwt_token,
                          serviceTokens()[
                            localStorage.getItem("fc_export_type")
                          ],
                          idsPath[idsPath.length - 1],
                          segments.join("/"),
                          idsPath.join("/")
                        ).then(() => setIsLoading(false));
                        break;
                      case "onedrive":
                        idsPath = exportFilesData.idsPath.split("/");
                        idsPath.pop();
                        fetchOneDriveFoldersList(
                          serviceTokens()[
                            localStorage.getItem("fc_export_type")
                          ],
                          idsPath[idsPath.length - 1],
                          segments.join("/"),
                          idsPath.join("/")
                        ).then(() => setIsLoading(false));
                        break;
                      case "googledrive":
                        idsPath = exportFilesData.idsPath.split("/");
                        idsPath.pop();
                        fetchGoogleDriveFoldersList(
                          serviceTokens()[
                            localStorage.getItem("fc_export_type")
                          ],
                          idsPath[idsPath.length - 1],
                          segments.join("/"),
                          idsPath.join("/")
                        ).then(() => setIsLoading(false));
                        break;
                      default:
                        break;
                    }
                  }}
                >
                  <Icons name="arrowLeft" stroke="hsl(214, 56%, 41%)" />
                  &nbsp; Back
                </A>
              </Show>
            </div>
            <button
              class={`btn primary ${
                exportMailattachments().length > 0 && !isProcessing()
                  ? ""
                  : "disabled"
              }`}
              onclick={async () => {
                if (exportMailattachments().length > 0 && !isProcessing()) {
                  setIsProcessing(true);
                  let message: string = messages.error.error;
                  let theme: string = "danger";
                  const exportFile: any = await uploadFilesToService(
                    props.user_id,
                    props.jwt_token
                  );
                  if (exportFile && exportFile.status) {
                    message = messages.form[exportFile.message];
                    theme = "success";
                  }
                  notify({
                    isNotified: true,
                    message,
                    theme,
                    placement: "top",
                    timeout: 2500,
                  });
                  setIsProcessing(false);
                  removePopup("exportFiles", props.jwt_token);
                }
              }}
            >
              <Show when={!isProcessing()}>Upload</Show>
              <Show when={isProcessing()}>
                Uploading&nbsp;<Icons name="loader"></Icons>
              </Show>
            </button>
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default ExportFilesPopup;
