// @refresh reload
import { Suspense, createSignal } from "solid-js";
import {
  Body,
  ErrorBoundary,
  FileRoutes,
  Head,
  Html,
  Meta,
  Routes,
  Scripts,
  Style,
  Title,
  useRouteData,
} from "solid-start";
import "~/assets/styles/style.scss";
import { SITE_TITLE } from "./consts";
import { validateUser } from "./routes/api/validateUser";
// import { nonce } from "./entry-server";

const [pageTitle, setPageTitle] = createSignal(SITE_TITLE as string);

export function routeData() {
  validateUser();
}

export default function Root() {
  const data = useRouteData<typeof routeData>();

  return (
    <Html lang="en">
      <Head>
        <Title>{pageTitle()}</Title>
        <Meta charset="utf-8" />
        <Meta name="viewport" content="width=device-width, initial-scale=1" />
        {/* <Style nonce={nonce()}></Style> */}
        {/* <Style nonce="138801e2-f09b-42c3-8921-f1391ae341c3"></Style> */}
      </Head>
      <Body>
        <ErrorBoundary>
          <Suspense fallback={<div>Loading</div>}>
            <Routes>
              <FileRoutes />
            </Routes>
          </Suspense>
        </ErrorBoundary>
        <Scripts />
      </Body>
    </Html>
  );
}

export { setPageTitle };
