import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onMount,
} from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$, redirect } from "solid-start/server";
import { notify } from "~/components/global/Notification";
import Search from "~/components/global/Search";

import Icons from "~/components/icons/Icons";
import SystemLayout from "~/components/layouts/system.layout";
import ConfirmPopup from "~/components/popup/ConfirmPopup";
import { SITE_TITLE } from "~/consts";
import { getAuthToken, getUser } from "~/db/session";
import messages from "~/helpers/message";
import fetchApi from "~/lib/api";
import { setPageTitle } from "~/root";
import {
  confirmRestoreForm,
  archivedFormsList,
  removePopup,
  selectedFormUuid,
  setConfirmRestoreForm,
  setArchivedFormsList,
  setPopup,
  setSelectedForm,
  setSelectedFormUuid,
  setFormsList,
  adjustFormFolderNames,
  setAlertMessage,
  alertMessage,
  confirmDeleteForm,
  popupQueue,
  setConfirmDeleteForm,
} from "~/store/global.store";
import { userRole } from "~/store/user-form-role";
import { validateUser } from "../../api/validateUser";
import Popover from "~/components/global/Popover";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request, true);
    if (userDetails?.statusCode == 401 || userDetails?.statusCode == 403) {
      throw redirect("/logout");
    }
    const user_id = userDetails.data.id;
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/archived/list`,
      jwt_token: jwt_token as string,
      body: { user_id, isListing: true, isArchived: true },
    };
    let formsList = await fetchApi(apiParams);
    if (formsList?.statusCode == 401) {
      throw redirect("/logout");
    }
    formsList = formsList?.data;

    return {
      formsList,
      user_id,
      jwt_token,
    };
  });
}

const archivedForms: Component = () => {
  setPageTitle(`${SITE_TITLE} - Archived Forms`);
  const data = useRouteData<typeof routeData>();
  const [searchString, setSearchString] = createSignal("" as string);
  setArchivedFormsList(data()?.formsList);

  onMount(() => {
    setConfirmRestoreForm(false);
    setConfirmDeleteForm(false);
  });

  createEffect(() => {
    setTimeout(() => {
      adjustFormFolderNames();
    }, 0);
  });

  createEffect(async () => {
    if (data()?.formsList) {
      setArchivedFormsList(data()?.formsList);
    } else {
      location.reload();
    }
    setTimeout(async () => {
      var urlString = new URLSearchParams(window.location.search);
      let search: any = urlString.get("search");
      if (search) {
        setSearchString(search);
        const apiParams = {
          method: "POST" as string,
          url: `${import.meta.env.VITE_API_URL}/form/archived/list`,
          jwt_token: data()?.jwt_token as string,
          body: {
            user_id: data()?.user_id,
            search_string: search.trim(),
            isListing: true,
            isArchived: true,
          },
        };
        let formsList = await fetchApi(apiParams);
        if (formsList?.statusCode == 401) {
          window.location.href = "/logout";
        } else if (formsList?.statusCode == 403) {
          location.reload();
        }
        setArchivedFormsList(formsList.data);
        adjustFormFolderNames();
      }
    }, 0);
  });

  async function restoreForm(
    user_id: string,
    jwt_token: string,
    form_id: string
  ) {
    if (confirmRestoreForm()) {
      let message: string = messages.error.restore_form;
      let theme: string = "danger";
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/restore`,
        jwt_token,
        body: { user_id, form_id, device_type: "web" },
      };
      const restoreForm = await fetchApi(apiParams);
      if (restoreForm?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (restoreForm?.statusCode == 403) {
        location.reload();
      }

      if (restoreForm.status) {
        setFormsList([]);
        let tempArr: any = [...archivedFormsList()];
        tempArr = tempArr.filter((form: any) => form.form_id !== form_id);
        setArchivedFormsList(tempArr);
        adjustFormFolderNames();
        theme = "success";
        message = messages.form.restore_form;
      }
      setConfirmRestoreForm(false);
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    } else {
      setAlertMessage("<b>Do you want to restore Form?</b>");
      setPopup("confirmRestoreForm");
    }
  }

  async function deleteForm(
    user_id: string,
    jwt_token: string,
    form_id: string
  ) {
    if (confirmDeleteForm()) {
      let message: string = messages.error.delete_form;
      let theme: string = "danger";
      const apiParams = {
        method: "POST" as string,
        url: `${import.meta.env.VITE_API_URL}/form/delete`,
        jwt_token,
        body: { user_id, form_id, type: "form", device_type: "web" },
      };
      const deleteForm = await fetchApi(apiParams);
      if (deleteForm?.statusCode == 401) {
        window.location.href = "/logout";
      } else if (deleteForm?.statusCode == 403) {
        location.reload();
      }
      if (deleteForm.status) {
        let tempArr: any = [...archivedFormsList()];
        tempArr = tempArr.filter((form: any) => form.form_id !== form_id);
        setArchivedFormsList(tempArr);
        adjustFormFolderNames();
        theme = "success";
        message = messages.form.delete_form;
      }
      setConfirmDeleteForm(false);
      notify({
        isNotified: true,
        message,
        theme,
        placement: "top",
        timeout: 2500,
      });
    } else {
      setAlertMessage("<b>Do you want to delete Form?</b>");
      setPopup("confirmDeleteForm");
    }
  }

  return (
    <main>
      <ConfirmPopup
        message={alertMessage()}
        name={
          popupQueue.includes("confirmRestoreForm")
            ? "confirmRestoreForm"
            : "confirmDeleteForm"
        }
        onconfirm={(status: boolean) => {
          if (popupQueue.includes("confirmRestoreForm")) {
            removePopup("confirmRestoreForm");
            if (status) {
              setConfirmRestoreForm(true);
              restoreForm(
                data()?.user_id,
                data()?.jwt_token,
                selectedFormUuid()
              );
            }
          } else {
            removePopup("confirmDeleteForm");
            if (status) {
              setConfirmDeleteForm(true);
              deleteForm(
                data()?.user_id,
                data()?.jwt_token,
                selectedFormUuid()
              );
            }
          }
        }}
      ></ConfirmPopup>
      <SystemLayout>
        <div class="flex space-between">
          <h2 class="title color">Archived Forms</h2>
          <div class="flex gap align-center">
            <Show when={data()?.formsList?.length > 0}>
              <div class="app-w-50">
                <Search
                  value={searchString()}
                  onEnter={async (search: any) => {
                    const apiParams = {
                      method: "POST" as string,
                      url: `${import.meta.env.VITE_API_URL}/form/archived/list`,
                      jwt_token: data()?.jwt_token as string,
                      body: {
                        user_id: data()?.user_id,
                        search_string: search.trim(),
                        isListing: true,
                        isArchived: true,
                      },
                    };
                    let formsList = await fetchApi(apiParams);
                    if (formsList?.statusCode == 401) {
                      window.location.href = "/logout";
                    } else if (formsList?.statusCode == 403) {
                      location.reload();
                    }
                    setArchivedFormsList(formsList.data);
                    const newUrl = `/form/archived/list?search=${encodeURIComponent(
                      search
                    )}`;
                    window.history.pushState({ search: search }, "", newUrl);
                    adjustFormFolderNames();
                  }}
                  placeholder="Search Form"
                ></Search>
              </div>
            </Show>
          </div>
        </div>
        <br />
        <div class="form-container">
          <For each={archivedFormsList()}>
            {(form) => (
              <>
                <Show when={form.theme != "folder"}>
                  <div class="pr">
                    <div
                      class="pa flex app-flex align-center justify-center w-160p h-100p form-actions"
                      id={form.id}
                    >
                      <Show
                        when={
                          userRole() == "admin" ||
                          userRole() == "sub_admin" ||
                          userRole() == "designer"
                        }
                      >
                        <div class="list-options">
                          <Popover content="Restore" top={-55} left={60}>
                            <button
                              class="list-option__btn"
                              onclick={(e: any) => {
                                e.preventDefault();
                                setSelectedForm(form.id);
                                setSelectedFormUuid(
                                  form.theme != "folder"
                                    ? form.form_id
                                    : form.id
                                );
                                restoreForm(
                                  data()?.user_id,
                                  data()?.jwt_token,
                                  form.form_id
                                );
                              }}
                            >
                              <Icons name="restore" stroke="#ffffff" />
                            </button>
                          </Popover>
                          <Popover content="Delete" top={-55} left={55}>
                            <button
                              class="list-option__btn"
                              onclick={(e: any) => {
                                e.preventDefault();
                                setSelectedForm(form.id);
                                setSelectedFormUuid(
                                  form.theme != "folder"
                                    ? form.form_id
                                    : form.id
                                );
                                deleteForm(
                                  data()?.user_id,
                                  data()?.jwt_token,
                                  form.form_id
                                );
                              }}
                            >
                              <Icons name="trash" stroke="#ffffff" />
                            </button>
                          </Popover>
                        </div>
                      </Show>
                      <label
                        class={`form-list-label ${form.theme}`}
                        id={form.id}
                        style={{
                          "font-size":
                            form.name?.length > 120
                              ? "0.7rem"
                              : form.name?.length < 120 &&
                                form.name?.length > 60
                              ? "0.8rem"
                              : form.name?.length < 60 && form.name?.length > 30
                              ? "0.9rem"
                              : "1rem",
                        }}
                      >
                        {form.name?.slice(0, 80)}{" "}
                        {form.name?.length > 80 ? "..." : ""}
                      </label>
                    </div>
                    <img
                      src={`/form_themes/${form.theme}.png`}
                      class="form-list-image"
                    />
                  </div>
                </Show>
              </>
            )}
          </For>
        </div>
        <Show
          when={
            archivedFormsList()?.filter((form: any) => form.theme != "folder")
              ?.length == 0
          }
        >
          <br />
          <br />
          <div class="w-100 text-center lbl">No Archived Forms</div>
        </Show>
      </SystemLayout>
    </main>
  );
};

export default archivedForms;
