import { Component, Show } from "solid-js";
import { useRouteData } from "solid-start";
import { createServerData$ } from "solid-start/server";
import SystemLayout from "~/components/layouts/system.layout";
import { getUser } from "~/db/session";

export function routeData() {
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    return userDetails;
  });
}

const index: Component = () => {
  const data = useRouteData<typeof routeData>();
  return (
    <main>
      <SystemLayout>
        <Show when={data()}>
          <div>
            <h2 class="title color">Forms</h2>
          </div>
        </Show>
      </SystemLayout>
    </main>
  );
};

export default index;
