import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { useRouteData } from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import Icons from "~/components/icons/Icons";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { popupQueue, removePopup, setPopup } from "~/store/global.store";
import AddEditNotification from "~/components/popup/AddEditNotification";
import { format } from "date-fns";
import { notify } from "~/components/global/Notification";
import messages from "~/helpers/message";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/notifications`,
      jwt_token: jwt_token as string,
      body: {
        admin_users: true,
      },
    };
    let data = await fetchApi(apiParams);
    if (data?.statusCode == 401) {
      throw redirect("/logout");
    }
    let notifications: any = data.data.notifications;
    let adminUsers: any = data.data.adminUsers;

    return { notifications, adminUsers, jwt_token };
  });
}

const userNotifications: Component = () => {
  setPageTitle(`${SITE_TITLE} - Admin User Notifications`);
  const data: any = useRouteData<typeof routeData>();

  const [notifications, setNotifications] = createSignal([] as any);
  const [adminUsers, setAdminUsers] = createSignal([] as any);
  const [notificationType, setNotificationType] = createSignal("" as string);
  const [notificationData, setNotificationData] = createSignal([] as any);

  createEffect(() => {
    if (data()?.notifications) {
      setNotifications(data()?.notifications);
    }
    if (data()?.adminUsers) {
      setAdminUsers([
        { value: "ALL", username: "ALL" },
        ...data()?.adminUsers.map((user: any) => ({
          value: user.id,
          username: user.username,
        })),
      ]);
    }
  });

  return (
    <main>
      <Show when={popupQueue.includes("addEditNotification")}>
        <AddEditNotification
          jwt_token={data()?.jwt_token}
          type={notificationType()}
          data={notificationData()}
          users={adminUsers()}
          updateList={async () => {
            removePopup("addEditNotification");
            const apiParams = {
              method: "POST" as string,
              url: `${import.meta.env.VITE_API_URL}/admin/notifications`,
              jwt_token: data()?.jwt_token,
              body: {
                admin_users: true,
              },
            };
            let list = await fetchApi(apiParams);
            if (list?.statusCode == 401) {
              throw redirect("/logout");
            }
            let notifications: any = list.data.notifications;
            setNotifications(notifications);
          }}
        ></AddEditNotification>
      </Show>
      <SystemLayout>
        <div>
          <div class="flex align-center space-between">
            <h3 class="title color">User Notifications</h3>
            <button
              class="btn primary"
              onclick={() => {
                setNotificationType("add");
                setPopup("addEditNotification");
              }}
            >
              <Icons name="plus" stroke="#fff"></Icons>
              &nbsp;Add Notification
            </button>
          </div>
          <br />
          <div>
            <table class="table w-100">
              <thead>
                <tr>
                  <th>Notification</th>
                  <th class="text-center">Assignees</th>
                  <th class="text-center">Added At</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <Show when={notifications()?.length > 0}>
                  <For each={notifications()}>
                    {(notification: any) => (
                      <tr>
                        <td>
                          <div innerHTML={notification.message}></div>
                        </td>
                        <td class="w-16 text-center">
                          <span class="lbl color">
                            {notification?.username
                              ? notification.username
                              : "ALL"}
                          </span>
                        </td>
                        <td class="w-16 text-center">
                          {format(
                            new Date(notification.created_at),
                            "MMMM,dd yyyy"
                          )}
                        </td>
                        <td class="w-18">
                          <div class="flex gap-0_5">
                            <button
                              class="btn sm primary"
                              onclick={() => {
                                setNotificationType("edit");
                                setNotificationData(notification);
                                setPopup("addEditNotification");
                              }}
                            >
                              <Icons
                                name="edit"
                                stroke="#fff"
                                width={20}
                                height={20}
                              ></Icons>
                              &nbsp; Edit
                            </button>
                            <button
                              class="btn sm danger"
                              onclick={async () => {
                                const apiParams = {
                                  method: "POST" as string,
                                  url: `${
                                    import.meta.env.VITE_API_URL
                                  }/admin/notification/delete`,
                                  jwt_token: data()?.jwt_token,
                                  body: {
                                    notification_id: notification.id,
                                  },
                                };
                                let deletedNotification = await fetchApi(
                                  apiParams
                                );
                                if (deletedNotification?.statusCode == 401) {
                                  throw redirect("/logout");
                                }
                                if (deletedNotification.status) {
                                  setNotifications(
                                    notifications().filter(
                                      (item: any) => item.id != notification.id
                                    )
                                  );
                                  notify({
                                    isNotified: true,
                                    message:
                                      messages.user[
                                        deletedNotification.message
                                      ],
                                    theme: "success",
                                    placement: "top",
                                    timeout: 2500,
                                  });
                                }
                              }}
                            >
                              <Icons
                                name="trash"
                                stroke="#fff"
                                width={20}
                                height={20}
                              ></Icons>
                              &nbsp; Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </For>
                </Show>
                <Show when={notifications()?.length == 0}>
                  <tr>
                    <td colspan={4} class="lbl text-center">
                      No Notifications Found!
                    </td>
                  </tr>
                </Show>
              </tbody>
            </table>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default userNotifications;
