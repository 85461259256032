import { Component, For, Show, createEffect, createSignal } from "solid-js";
import Popup from "../global/Popup";
import Icons from "../icons/Icons";
import {
  formLinkerText,
  formValues,
  selectedLinkedForm,
  selectedLinkedParent,
  setFormValues,
  updateLinkedFormFields,
  updateParentFields,
} from "./FieldLinkerPopup";
import {
  activeField,
  fieldsToLink,
  linkChangeData,
  linkedField,
  linkedFormValues,
  linkedLinkValues,
  linkedParentValues,
  nestedList,
  removePopup,
  setActiveField,
  setLinkedField,
  setLinkedFieldLabel,
  setLinkedFormValues,
  setLinkedLinkValues,
  setLinkedParentValues,
  setPopup,
} from "~/store/global.store";
import { formJson, updateFormJson } from "~/store/form.store";

const [selectedItem, setSelectedItem] = createSignal("" as string);
const [changedLinkedItems, setChangedLinkedItems] = createSignal([] as any);

interface Props {
  data?: any;
  type?: any;
  name: string;
  user_id?: string;
  jwt_token?: any;
}

const LinkFieldPopup: Component<Props> = (props) => {
  const [formFieldsToLink, setFormFieldsToLink] = createSignal([] as any);
  const [linkFieldSearchString, setLinkFieldSearchString] = createSignal(
    "" as string
  );

  createEffect(() => {
    if (props?.data) {
      setFormFieldsToLink(props?.data);
    }
  });

  function checkFieldToLink(id: any) {
    formJson.pages.forEach((page: any) => {
      page.fields?.forEach((field: any) => {
        if (id == field.properties.dataFieldSync.uid && !nestedList()) {
          if (
            field.properties.choiceFieldSync.items.length !=
            formJson.currentField.choiceFieldSync.items.length
          ) {
            setPopup("confirmUpdateSelectedItems");
            setChangedLinkedItems(field.properties.choiceFieldSync.items);
          } else {
            field.properties.choiceFieldSync.items.forEach(
              (val: any, key: any) => {
                if (
                  field.properties.choiceFieldSync.items[key].title !=
                  formJson.currentField.choiceFieldSync.items[key].title
                ) {
                  setPopup("confirmUpdateSelectedItems");
                  setChangedLinkedItems(field.properties.choiceFieldSync.items);
                }
              }
            );
          }
        }
      });
    });
  }

  function searchFormFieldItems(searchString: string) {
    const searchResults: any = [];
    props?.data.forEach(function (item: any) {
      const matchingPageData = item.pageData.filter((val: any) =>
        val.label.toLowerCase().includes(searchString.toLowerCase())
      );
      if (matchingPageData.length > 0) {
        let matchingItem: any = {
          pageData: matchingPageData,
        };
        if (item?.page) {
          matchingItem["page"] = item.page;
        }
        searchResults.push(matchingItem);
      }
    });
    setFormFieldsToLink(searchResults);
  }

  return (
    <Popup heading={"Link Field"} classes="small" name={props.name}>
      <Show when={props?.data?.length > 0}>
        <div class="form-linker-header" innerHTML={formLinkerText()}></div>
        <div class="form-linker-search">
          <Icons name="search"></Icons>
          <div class="app-flex flex align-center w-100 space-between pr">
            <input
              class="input"
              placeholder="Search ..."
              onclick={() => {
                setActiveField("linked_search");
              }}
              value={linkFieldSearchString()}
              oninput={(e: any) => {
                setLinkFieldSearchString(e.target.value);
                searchFormFieldItems(e.target.value);
              }}
            />
            <Show
              when={
                activeField() == "linked_search" &&
                linkFieldSearchString() != ""
              }
            >
              <span
                class="pa z-index-1 cur-p r-5"
                onclick={() => {
                  setLinkFieldSearchString("");
                  setFormFieldsToLink(props?.data);
                }}
              >
                <Icons name="circleX" width={20} height={20}></Icons>
              </span>
            </Show>
          </div>
        </div>
      </Show>
      <div class="app-flex flex flex-column">
        <Show
          when={formFieldsToLink()?.length > 0 || fieldsToLink().length > 0}
        >
          <For
            each={
              formFieldsToLink()?.length > 0
                ? formFieldsToLink()
                : fieldsToLink()
            }
          >
            {(val: any) => (
              <>
                <Show when={val?.page && val.pageData.length > 0}>
                  <label
                    class={
                      formFieldsToLink()?.length > 0
                        ? "form-linker-label"
                        : "link-field-label"
                    }
                  >
                    {val.page}
                  </label>
                </Show>
                <div>
                  <ul class={`line-field-ul  ${props?.type ? "parent" : ""}`}>
                    <For each={val.pageData}>
                      {(item: any) => (
                        <li
                          class="cur-p"
                          onclick={() => {
                            if (formFieldsToLink()?.length > 0) {
                              if (props?.type == "parentField") {
                                const tempArr: any = [...linkedParentValues()];
                                const tempObject: any = [];
                                tempObject["id"] = item.id;
                                tempObject["label"] =
                                  item.label + " (" + val.page + ")";
                                tempArr.push(tempObject);
                                setLinkedParentValues(tempArr);
                                setTimeout(() => {
                                  updateLinkedFormFields(
                                    props?.user_id,
                                    props?.jwt_token
                                  );
                                }, 300);
                              } else if (props?.type == "linkedForm") {
                                const tempArr: any = [...linkedFormValues()];
                                const tempObject: any = [];
                                tempObject["id"] = item.id;
                                tempObject["label"] = item.label;
                                tempObject["parentField"] = [
                                  selectedLinkedParent(),
                                ];
                                tempArr.push(tempObject);
                                setFormValues([
                                  ...formValues(),
                                  { id: item.id, label: item.label },
                                ]);
                                setLinkedFormValues(tempArr);
                                updateLinkedFormFields(
                                  props?.user_id,
                                  props?.jwt_token
                                );
                              } else if (props?.type == "linkedField") {
                                if (linkedFormValues().length == 1) {
                                  const tempArr: any = [...linkedFormValues()];
                                  tempArr[0]["parentField"] = [
                                    ...tempArr[0]["parentField"],
                                    selectedLinkedParent(),
                                  ];
                                  setLinkedFormValues(tempArr);
                                }
                                const tempArr = [...linkedLinkValues()];
                                const tempObject: any = [];
                                tempObject["parentField"] =
                                  selectedLinkedParent();
                                tempObject["childForm"] = selectedLinkedForm();
                                tempObject["id"] = item.id;
                                tempObject["label"] =
                                  item.label + " (" + val.page + ")";
                                tempArr.push(tempObject);
                                setLinkedLinkValues(tempArr);
                                setTimeout(() => {
                                  updateParentFields();
                                }, 500);
                              }
                              setLinkedField(item.id);
                              removePopup("formLinkField");
                            } else {
                              (formJson.currentField.dataFieldSync?.type ==
                                "RadioButtonField" ||
                                formJson.currentField.dataFieldSync?.type ==
                                  "DropdownField") &&
                              !linkChangeData()
                                ? (checkFieldToLink(item.id),
                                  nestedList()
                                    ? updateFormJson(
                                        "choiceFieldSync",
                                        "linkedFieldId",
                                        item.id,
                                        true
                                      )
                                    : updateFormJson(
                                        "choiceFieldSync",
                                        "linkedFieldId",
                                        item.id
                                      ))
                                : updateFormJson(
                                    "dataFieldSync",
                                    "linkedFieldId",
                                    item.id
                                  );
                              setSelectedItem(item.id);
                              setLinkedField(item.id);
                              setLinkedFieldLabel(
                                item.label + " (" + val.page + ")"
                              );
                              removePopup("linkField");
                            }
                          }}
                        >
                          <div class="app-flex flex space-between">
                            <span>
                              {item.label ? item.label : "Unnamed Field"}
                            </span>
                            <Show when={linkedField() == item.id}>
                              <Icons
                                name="check"
                                width={19}
                                height={19}
                              ></Icons>
                            </Show>
                          </div>
                        </li>
                      )}
                    </For>
                  </ul>
                </div>
              </>
            )}
          </For>
        </Show>
        <Show
          when={formFieldsToLink()?.length == 0 && fieldsToLink().length == 0}
        >
          <label class="link-field-label">No Field To Link !!!</label>
        </Show>
      </div>
    </Popup>
  );
};

export {
  selectedItem,
  setSelectedItem,
  changedLinkedItems,
  setChangedLinkedItems,
};
export default LinkFieldPopup;
