import { Component, For, createEffect, createSignal } from "solid-js";
import Icons from "../icons/Icons";
import { createStore } from "solid-js/store";

interface Props {
  type: string;
  value?: string;
  setValue: any;
}
const MailInput: Component<Props> = (props) => {
  const [finalArr, setFinalArr] = createStore({
    to: [] as Array<string>,
    cc: [] as Array<string>,
  }) as any;

  createEffect(() => {
    if (props?.value) {
      setFinalArr({
        [props.type]: props.value.split("\n"),
      });
    }
  });

  return (
    <div class="email-input">
      <For each={finalArr[props.type]}>
        {(val: string) => (
          <div class="item">
            <span class="fs-12">{val}</span>&nbsp;|&nbsp;
            <span
              class="icon"
              onclick={() => {
                setFinalArr({
                  [props.type]: finalArr[props.type].filter(
                    (item: any) => item != val
                  ),
                });
                props.setValue(finalArr[props.type].join("\n"));
              }}
            >
              <Icons
                name="x"
                width={16}
                height={16}
                stroke="red"
                strokeWidth="2.5"
              ></Icons>
            </span>
          </div>
        )}
      </For>
      <input
        class="input plain w-250"
        onkeydown={(e: any) => {
          if (e.key == "Backspace" && e.target.value == "") {
            const tempArr: any = [...finalArr[props.type]];
            tempArr.pop();
            setFinalArr({ [props.type]: tempArr });
            props.setValue(finalArr[props.type].join("\n"));
          }
          if (e.key == "Enter" && e.target.value != "") {
            setFinalArr({
              [props.type]: [...finalArr[props.type], e.target.value],
            });
            props.setValue(finalArr[props.type].join("\n"));
            e.target.value = "";
          }
        }}
        onblur={(e: any) => {
          if (e.target.value) {
            setFinalArr({
              [props.type]: [...finalArr[props.type], e.target.value],
            });
            props.setValue(finalArr[props.type].join("\n"));
            e.target.value = "";
          }
        }}
      />
    </div>
  );
};

export default MailInput;
