import { Component, For, Show } from "solid-js";
import { A, useRouteData } from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import Icons from "~/components/icons/Icons";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { format } from "date-fns";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/admin/dashboard/statics`,
      jwt_token: jwt_token as string,
    };
    let dashboardStatics = await fetchApi(apiParams);
    if (dashboardStatics?.statusCode == 401) {
      throw redirect("/logout");
    }
    dashboardStatics = dashboardStatics.data;

    return { dashboardStatics };
  });
}

const dashboard: Component = () => {
  setPageTitle(`${SITE_TITLE} - Admin Dashboard`);
  const data = useRouteData<typeof routeData>();
  return (
    <main>
      <SystemLayout>
        <div class="dashboard">
          <div class="bg-primary"></div>
          <div class="title lbl">Dashboard Stats</div>
          <div class="statics">
            <div class="box">
              <div class="flex space-between align-center">
                <span class="lbl">Companies</span>
                <span class="icon-box">
                  <Icons
                    name="users"
                    width={18}
                    height={18}
                    stroke="#2e61a3"
                  ></Icons>
                </span>
              </div>
              <h1>{data()?.dashboardStatics.subscribers}</h1>
            </div>
            <div class="box">
              <div class="flex space-between align-center">
                <span class="lbl">User Licenses</span>
                <span class="icon-box">
                  <Icons
                    name="document"
                    width={18}
                    height={18}
                    stroke="#2e61a3"
                  ></Icons>
                </span>
              </div>
              <h1>{data()?.dashboardStatics.licences}</h1>
            </div>
            <div class="box">
              <div class="flex space-between align-center">
                <span class="lbl">Forms</span>
                <span class="icon-box">
                  <Icons
                    name="files"
                    width={18}
                    height={18}
                    stroke="#2e61a3"
                  ></Icons>
                </span>
              </div>
              <h1>{data()?.dashboardStatics.forms}</h1>
            </div>
            <div class="box">
              <div class="flex space-between align-center">
                <span class="lbl">Total Storage Used</span>
                <span class="icon-box">
                  <Icons
                    name="storage"
                    width={18}
                    height={18}
                    stroke="#2e61a3"
                  ></Icons>
                </span>
              </div>
              <h1>{data()?.dashboardStatics.storage}</h1>
            </div>
          </div>
          <div class="flex gap space-between flex-wrap">
            <div class="w-49">
              <div class="flex space-between align-center table-header">
                <div class="lbl">Recent Subscribers</div>
                <A href="/admin/users" class="btn badge sm lbl color">
                  See All
                </A>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Email</th>
                    <th>Subscribed At</th>
                  </tr>
                </thead>
                <tbody>
                  <For each={data()?.dashboardStatics.recentSubscribers}>
                    {(subscriber: any) => (
                      <tr>
                        <td>
                          <A href={`/admin/user/${subscriber.id}/details`}>
                            {subscriber.username}
                          </A>
                          <br />
                          {subscriber.companies[0]?.company_name
                            ? "(" + subscriber.companies[0]?.company_name + ")"
                            : ""}
                        </td>
                        <td>{subscriber.email}</td>
                        <td class="w-105p">
                          {format(
                            new Date(subscriber.created_at),
                            "MMM, dd yyyy"
                          )}
                        </td>
                      </tr>
                    )}
                  </For>
                </tbody>
              </table>
            </div>
            <div class="w-49">
              <div class="flex table-header space-between align-center">
                <div class="lbl">Recently Purchased Licences</div>
                <A href="/admin/users" class="btn badge sm lbl color">
                  See All
                </A>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>Company</th>
                    <th>Purchased Licences</th>
                    <th>Purchased At</th>
                  </tr>
                </thead>
                <tbody>
                  <For each={data()?.dashboardStatics.recentLicences}>
                    {(licence: any) => (
                      <tr>
                        <td>
                          {licence.company_name} <br /> ({licence.email})
                        </td>
                        <td>{licence.quantity}</td>
                        <td>
                          {format(new Date(licence.created_at), "MMM, dd yyyy")}
                        </td>
                      </tr>
                    )}
                  </For>
                </tbody>
              </table>
            </div>
            <div class="w-49">
              <div class="flex table-header space-between align-center">
                <div class="lbl">Users with Expiring Cards</div>
                <A
                  href="/admin/expiring-card-users"
                  class="btn badge sm lbl color"
                >
                  See All
                </A>
              </div>
              <table class="table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>Card</th>
                    <th>Expiry At</th>
                  </tr>
                </thead>
                <tbody>
                  <Show
                    when={
                      data()?.dashboardStatics?.recentFutureCardExpire &&
                      data()?.dashboardStatics?.recentFutureCardExpire?.length >
                        0
                    }
                  >
                    <For
                      each={data()?.dashboardStatics?.recentFutureCardExpire}
                    >
                      {(user: any) => (
                        <tr>
                          <td>
                            <span class="lbl">{user.username}</span>
                            <br /> ({user.email})
                          </td>
                          <td>
                            <strong>
                              {user.card_brand?.charAt(0).toUpperCase() +
                                user.card_brand?.slice(1)}
                            </strong>{" "}
                            <small>card ending in </small>
                            <strong>{user.card_last_four}</strong>
                          </td>
                          <td>{user.card_expiry_date}</td>
                        </tr>
                      )}
                    </For>
                  </Show>
                  <Show
                    when={
                      data()?.dashboardStatics?.recentFutureCardExpire &&
                      data()?.dashboardStatics?.recentFutureCardExpire
                        ?.length == 0
                    }
                  >
                    <tr>
                      <td colSpan={3} class="text-center">
                        <small>
                          <b>No Data Found !!</b>
                        </small>
                      </td>
                    </tr>
                  </Show>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </SystemLayout>
    </main>
  );
};

export default dashboard;
