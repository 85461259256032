import { Component, For, Show } from "solid-js";
import Popup from "../global/Popup";
import {
  getRandomColorWithProperContrast,
  getSignatureInitials,
  groupIcons,
  userIcons,
} from "~/store/global.store";

interface Props {
  heading: string;
  list: any;
  avatarList: any;
  teamsList?: any;
  isFormList?: boolean;
}

const AssigneeList: Component<Props> = (props) => {
  return (
    <Popup
      heading={props.heading}
      classes="xsmall"
      overlap={true}
      name="assigneeList"
    >
      <div>
        <Show when={props?.isFormList}>
          <Show when={props.list?.assignee}>
            <div class="lbl color">Form Shared Users:</div>
            <div class="underline"></div>
            <For each={props.list?.assignee}>
              {(assignee: any, key: any) => (
                <div class="flex app-flex gap align-center p-5-15">
                  <div
                    class="form-user-div"
                    style={{
                      "--background-color":
                        props.avatarList?.assigneeAvatar[key()] != ""
                          ? "#ffffff"
                          : userIcons().find(
                              (user: any) => user.name == assignee
                            )?.theme,
                    }}
                  >
                    <Show when={props.avatarList?.assigneeAvatar[key()] != ""}>
                      <img
                        class="form-user-div m-0"
                        src={props.avatarList?.assigneeAvatar[key()]}
                      ></img>
                    </Show>
                    <Show when={props.avatarList?.assigneeAvatar[key()] == ""}>
                      {
                        userIcons().find((user: any) => user.name == assignee)
                          ?.logo
                      }
                    </Show>
                  </div>
                  <div class="lbl">{assignee}</div>
                </div>
              )}
            </For>
          </Show>
          <Show
            when={
              props.list?.assigneeTeams && props.list?.assigneeTeams?.length > 0
            }
          >
            <br />
            <div class="lbl color">Form Shared Teams:</div>
            <div class="underline"></div>
            <For each={props.list?.assigneeTeams}>
              {(assignee: any) => (
                <div class="flex app-flex gap align-center p-5-15">
                  <div
                    class="form-user-div"
                    style={{
                      "--background-color":
                        getRandomColorWithProperContrast("#ffffff"),
                    }}
                  >
                    {getSignatureInitials(assignee)}
                  </div>
                  <div class="lbl">{assignee}</div>
                </div>
              )}
            </For>
          </Show>
        </Show>
        <Show when={!props?.isFormList}>
          <For each={props.list}>
            {(assignee: any, key: any) => (
              <div class="flex app-flex gap align-center p-5">
                <div
                  class="form-user-div"
                  style={{
                    "--background-color":
                      props.avatarList[key()] != ""
                        ? "#ffffff"
                        : [
                            ...(props.heading == "Assigned Teams"
                              ? groupIcons()
                              : userIcons()),
                          ].find((user: any) => user.name == assignee)?.theme,
                  }}
                >
                  <Show when={props.avatarList[key()] != ""}>
                    <img
                      class="form-user-div m-0"
                      src={props.avatarList[key()]}
                    ></img>
                  </Show>
                  <Show when={props.avatarList[key()] == ""}>
                    {
                      [
                        ...(props.heading == "Assigned Teams"
                          ? groupIcons()
                          : userIcons()),
                      ].find((user: any) => user.name == assignee)?.logo
                    }
                  </Show>
                </div>
                <div class="lbl">{assignee}</div>
              </div>
            )}
          </For>
        </Show>
      </div>
    </Popup>
  );
};

export default AssigneeList;
