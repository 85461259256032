import { A } from "@solidjs/router";
import { Component, For, Show, createEffect, createSignal } from "solid-js";
import { preLoadData } from "~/store/records.store";
import generateCSS from "~/helpers/generator";
import {
  activeField,
  selectedDropDown,
  setActiveField,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import { useNavigate } from "solid-start";

interface Props {
  list: any;
  title: string;
  link?: string;
  data?: any;
  form?: boolean;
  cssProperties?: any;
  selectedValue?: string;
  class?: string;
  setValue?: Function;
  isFormDropdown?: boolean;
}

const [isNormalLogout, setIsNormalLogout] = createSignal(false as boolean);

const Dropdown: Component<Props> = (props) => {
  const [dropDownItems, setDropDownItems] = createSignal([] as any);
  const [positionTop, setPositionTop] = createSignal(0 as number);
  const [positionLeft, setPositionLeft] = createSignal(0 as number);
  const [dropdownSearchString, setDropdownSearchString] = createSignal(
    "" as string
  );

  let dropdownSearchRef: any;

  const navigate: any = useNavigate();

  createEffect(() => {
    if (props.list) {
      setDropdownSearchString("");
      setDropDownItems(props.list);
    }
    if (selectedDropDown()) {
      dropdownSearchRef?.focus();
      const element: any = document.querySelector(".dropdown");
      const rect: any = element.getBoundingClientRect();
      setTimeout(() => {
        if (props?.data?.properties.dataFieldSync.frame[0][0] < 0.88) {
          setPositionLeft(1);
        } else {
          setPositionLeft(0);
        }
        if (window.innerHeight - 70 - rect.top > element.offsetHeight) {
          setPositionTop(0);
        } else {
          setPositionTop(-(element.offsetHeight + 15));
        }
      }, 0);
    }
  });

  return (
    <>
      <Show when={props?.link}>
        <div class={`dropdown ${props?.class ? props?.class : ""}`}>
          <Show when={!props?.isFormDropdown}>
            <span class="dropdown-caret"></span>
          </Show>
          <ul
            class={`dropdown-list ${
              props?.isFormDropdown ? "mt-1 br-unset" : ""
            }`}
          >
            <For each={props.list}>
              {(item) => (
                <li
                  class={`dropdown-items ${
                    props?.class && item.value == props.selectedValue
                      ? "active"
                      : ""
                  }`}
                >
                  <Show when={item[props.link]}>
                    <Show when={item[props.link] == "/logout"}>
                      <a
                        onclick={() => {
                          setIsNormalLogout(true);
                          navigate("/logout");
                        }}
                      >
                        {item[props.title]}
                      </a>
                    </Show>
                    <Show when={item[props.link] != "/logout"}>
                      <A href={props.link ? item[props.link] : ""}>
                        {item[props.title]}
                      </A>
                    </Show>
                  </Show>
                  <Show when={!item[props.link]}>
                    <a onclick={() => props?.setValue(item.value)}>
                      {item[props.title]}
                    </a>
                  </Show>
                </li>
              )}
            </For>
          </ul>
        </div>
      </Show>
      <Show when={props?.data}>
        <div
          style={generateCSS(props.cssProperties)}
          class={`pa flex align-center field`}
        >
          <div
            class={`dropdown ${props?.class ? props?.class : ""} ${
              positionTop() != 0 ? "top" : ""
            } ${positionLeft() != 0 ? "right" : ""}`}
            style={{
              "--top": positionTop() != 0 ? positionTop() + "px" : "100%",
            }}
          >
            <span
              class={`dropdown-caret ${positionTop() != 0 ? "top" : ""}`}
              style={{
                "--top":
                  positionTop() != 0
                    ? Math.abs(positionTop() + 25) + "px"
                    : "9px",
              }}
            ></span>
            <ul class="dropdown-list">
              <li>
                <div class="flex align-center space-between pr">
                  <input
                    ref={dropdownSearchRef}
                    type="text"
                    class="input plain ff-Helvetica"
                    placeholder="Search..."
                    onclick={() => {
                      setActiveField("dropdown_search");
                    }}
                    value={dropdownSearchString()}
                    oninput={(e: any) => {
                      setDropdownSearchString(e.target.value);
                      if (e.target.value.length > 0) {
                        setActiveField("dropdown_search");
                      }
                      let tempArr: any = props.list;
                      tempArr = tempArr.filter((val: any) => {
                        return val.title
                          .toString()
                          .toLowerCase()
                          .includes(e.target.value.toString().toLowerCase());
                      });
                      setDropDownItems(tempArr);
                    }}
                  />
                  <Show
                    when={
                      activeField() == "dropdown_search" &&
                      dropdownSearchString() != ""
                    }
                  >
                    <span
                      class="pa z-index-1 cur-p r-5"
                      onclick={() => {
                        setDropdownSearchString("");
                        setDropDownItems(props.list);
                      }}
                    >
                      <Icons name="circleX" width={20} height={20}></Icons>
                    </span>
                  </Show>
                </div>
              </li>
              <hr />
              <For each={dropDownItems()}>
                {(item: any) => (
                  <li
                    class={`dropdown-items ${
                      props.data.fieldsType != "designer" &&
                      preLoadData()[props.data.properties.dataFieldSync.uid] !=
                        "" &&
                      preLoadData()[props.data.properties.dataFieldSync.uid] ==
                        item.id
                        ? "active"
                        : ""
                    }`}
                    onclick={() => props?.setValue(item.id)}
                  >
                    <div class="flex space-between align-center text-left">
                      <A href="#" class="ff-Helvetica">
                        {props.title != ""
                          ? item.title[props.title]
                          : item.title}
                      </A>
                    </div>
                  </li>
                )}
              </For>
            </ul>
          </div>
        </div>
      </Show>
      <Show when={props?.form}>
        <div class={`dropdown form ${props?.class ? props?.class : ""}`}>
          <span class="dropdown-caret"></span>
          <ul class="dropdown-list">
            <For each={props.list}>
              {(item, key) => (
                <li
                  class={`dropdown-items ${
                    key() == props.list.length - 1 ? "border-none" : ""
                  }`}
                >
                  <a onclick={() => props?.setValue(item.value)}>
                    <span>
                      <Icons name={item.icon} width={18} height={18}></Icons>
                    </span>
                    &nbsp;&nbsp;
                    <span>{item[props.title]}</span>
                  </a>
                </li>
              )}
            </For>
          </ul>
        </div>
      </Show>
    </>
  );
};

export { isNormalLogout };
export default Dropdown;
