import { Component, Show, createEffect, createSignal, onMount } from "solid-js";
import { useNavigate } from "solid-start";
import {
  createNewFormPage,
  duplicateFormPage,
  formJson,
  isFormJsonUpdated,
  setFormJson,
  setIsFormJsonUpdated,
  setIsRedirectFromBuilder,
} from "~/store/form.store";
import {
  builderSidebarOpen,
  currentFormId,
  exportFormSettings,
  formSettingsBar,
  formToolbarOpen,
  isFormUpdated,
  recordSidebarOpen,
  selectedDropDown,
  setAlertMessage,
  setBuilderRedirection,
  setBuilderSidebarOpen,
  setExportFormSettings,
  setFormSettingsBar,
  setFormToolbarOpen,
  setIsFormDropDownOpen,
  setIsFormUpdated,
  setPopup,
  setRecordSidebarOpen,
  setSelectedDropDown,
  setSelectedRecords,
} from "~/store/global.store";
import Icons from "../icons/Icons";
import {
  saveFormSettings,
  setActiveTheme,
  setEmbedImageEnabled,
  setFontSize,
  setKioskModeEnabled,
  setPaperSize,
  setPdfRecordsEnabled,
  setPdfTextBoxEnabled,
  setRecordSortingDisabled,
} from "../form/formSettings";
import { jwtToken, userId, userRole } from "~/store/user-form-role";
import Dropdown from "./Dropdown";
import { formPageEvents } from "~/store/field-editor.store";
import fetchApi from "~/lib/api";
import {
  alertFormRecords,
  cloneRecord,
  isCurrentRecordLocked,
  isLoader,
  isRecordSubmit,
  isRecordUpdated,
  lockRecord,
  recordsJson,
  setPreLoadData,
  setRecordSaveCancelAction,
  setRecordsJson,
  setSelectedRecord,
  setSelectedRecordId,
  setUpdateRecordDetails,
  updatePreLoadData,
} from "~/store/records.store";
import Popover from "./Popover";
import { setCurrentPage } from "./Pagination";

interface Props {
  user_id: string;
  jwt_token: string;
  formName: string;
  formId: string;
  active: string;
}

const [selectedFormId, setSelectedFormId] = createSignal("" as string);
const [isDataSaving, setIsDataSaving] = createSignal(false as boolean);
const [isClipBoard, setIsClipBoard] = createSignal("" as string);
const [pageDropDown, setPageDropDown] = createSignal("" as string);
const [pageEvents, setPageEvents] = createSignal([] as any);
const [pages, setPages] = createSignal([] as any);
const [pageSearchString, setPageSearchString] = createSignal("" as string);

export function exportForm() {
  if (exportFormSettings()) {
    setExportFormSettings(false);
    setFormSettingsBar(false);
    setSelectedRecords([]);
    setRecordSidebarOpen(false);
  } else {
    if (recordsJson.multipleRecords.length > 0) {
      setSelectedRecords(
        recordsJson.multipleRecords.map((record: any) => record.record_id)
      );
      setRecordsJson({
        multipleRecords: [],
      });
      setSelectedRecordId("");
    }
    if (Object.keys(recordsJson.currentRecord).length > 0) {
      setSelectedRecords([recordsJson.currentRecord.record_id]);
      setSelectedRecordId("");
    }
    setExportFormSettings(true);
    setRecordSidebarOpen(true);
  }
}

export function newRecord() {
  setPreLoadData([]);
  setRecordsJson({
    currentRecord: {},
  });
  setSelectedRecordId("");
  setFormJson({
    currentPage: 0,
  });
  setCurrentPage(formJson.currentPage + 1);
  setSelectedRecordId("");
  setRecordsJson({
    currentRecord: {},
    multipleRecords: [],
  });
  setSelectedRecord("");
  updatePreLoadData();
}

export function toggleClipboard() {
  formToolbarOpen() ? setFormToolbarOpen(false) : setFormToolbarOpen(true);
  if (
    Object.keys(recordsJson.currentRecord).length > 0 ||
    recordsJson.multipleRecords.length > 0
  ) {
    if (alertFormRecords()) {
      setIsClipBoard(isClipBoard() != "" ? "" : "clipboard");
    }
  } else {
    setIsClipBoard(isClipBoard() != "" ? "" : "clipboard");
  }
}

const FormNavbar: Component<Props> = (props) => {
  const navigate = useNavigate();
  const [otherSpace, setOtherSpace] = createSignal(0 as number);

  createEffect(() => {
    if (formJson.pages.length > 1) {
      setPageEvents(formPageEvents);
    } else {
      setPageEvents(
        formPageEvents.filter((obj: any) => obj.value != "remove_page")
      );
    }
    if (
      formJson.currentField != undefined &&
      (Object.keys(formJson.currentField).length > 0 ||
        formJson.multipleFields.length > 0)
    ) {
      setSelectedDropDown("");
      setIsFormDropDownOpen(false);
    }
    if (isDataSaving()) {
      const element: any = document.getElementById("builder_save");
      element?.blur();
    }
  });

  onMount(() => {
    const windowWidth = window.innerWidth;
    const leftBarWidth = 236;
    const rightBarWidth = 300;
    const formWidth = 770;
    const formMargin = 32;
    setOtherSpace(
      windowWidth - leftBarWidth - rightBarWidth - formMargin - formWidth
    );
  });

  return (
    <>
      <nav class="builder-navbar">
        <div
          class="builder-head"
          style={{ "--width": props.active == "render" ? "300px" : "236px" }}
        >
          <Popover content="Home" top={-35} left={45}>
            <a
              class="cur-p"
              href="#"
              onclick={() => {
                if (isFormJsonUpdated()) {
                  setBuilderRedirection("/form/list");
                  setPopup("confirmFormChanges");
                } else if (
                  Object.keys(recordsJson.currentRecord).length > 0 &&
                  isRecordUpdated() != "" &&
                  !isRecordSubmit()
                ) {
                  setRecordSaveCancelAction("home");
                  setPopup("confirmRecordChanges");
                } else {
                  navigate(`/form/list`);
                  setCurrentPage(1);
                  setFormJson({
                    currentPage: 0,
                  });
                }
              }}
            >
              <Icons name="home" />
            </a>
          </Popover>
          <Show when={isLoader()}>
            <div class="skeleton-loading title"></div>
          </Show>
          <Show when={!isLoader()}>
            <div class="form-label lbl text-center text-fix pointer-none">
              {props.formName}
            </div>
          </Show>
          <Show
            when={
              props.active == "render" &&
              exportFormSettings() &&
              !formSettingsBar()
            }
          >
            <div
              class="cur-p app-dn"
              onclick={() => {
                setExportFormSettings(false);
                setSelectedRecords([]);
              }}
            >
              <Icons name="x" stroke="red"></Icons>
            </div>
          </Show>
        </div>
        <div
          class="builder-head-middle"
          style={{
            "--left": (props.active == "render" ? "300" : "236") + "px",
          }}
        >
          <div
            class="sidebar-menu pa l-0"
            onclick={() => {
              if (props.active == "render") {
                recordSidebarOpen()
                  ? setRecordSidebarOpen(false)
                  : setRecordSidebarOpen(true);
              } else {
                builderSidebarOpen()
                  ? setBuilderSidebarOpen(false)
                  : setBuilderSidebarOpen(true);
              }
            }}
          >
            <Icons
              name={`${
                recordSidebarOpen() || builderSidebarOpen()
                  ? "chevronsLeft"
                  : "chevronsRight"
              }`}
              stroke="#2E61A3"
              height={35}
              width={35}
            ></Icons>
          </div>
          <Show when={props.active == "render" && userRole() != "viewer"}>
            <div class="flex">
              <Show when={userRole() != "filler"}>
                <div
                  class="builder-link cur-p"
                  onclick={() => {
                    if (
                      Object.keys(recordsJson.currentRecord).length > 0 ||
                      recordsJson.multipleRecords.length > 0
                    ) {
                      if (alertFormRecords()) {
                        if (
                          Object.keys(recordsJson.currentRecord).length > 0 &&
                          isRecordUpdated() != "" &&
                          !isRecordSubmit()
                        ) {
                          setRecordSaveCancelAction("builder");
                          setPopup("confirmRecordChanges");
                        } else {
                          setFormJson({
                            currentField: {},
                            multipleFields: [],
                          });
                          navigate(`/form/builder/${props.formId}`);
                        }
                      }
                    } else if (
                      Object.keys(recordsJson.currentRecord).length > 0 &&
                      isRecordUpdated() != "" &&
                      !isRecordSubmit()
                    ) {
                      setRecordSaveCancelAction("builder");
                      setPopup("confirmRecordChanges");
                    } else {
                      setFormJson({
                        currentField: {},
                        multipleFields: [],
                      });
                      navigate(`/form/builder/${props.formId}`);
                    }
                  }}
                >
                  <Icons name="pencil" stroke="#3f4142"></Icons>
                  &nbsp;
                  <span>Form Builder</span>
                </div>
              </Show>
              <Show
                when={
                  formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                    (obj: any) => obj.type == "tbicon_export"
                  ) != -1
                }
              >
                <div
                  class={`builder-link cur-p ${
                    exportFormSettings() ? "active" : ""
                  }`}
                  onclick={() => {
                    if (
                      Object.keys(recordsJson.currentRecord).length > 0 &&
                      isRecordUpdated() != "" &&
                      !isRecordSubmit()
                    ) {
                      setRecordSaveCancelAction("export");
                      setPopup("confirmRecordChanges");
                    } else if (
                      Object.keys(recordsJson.currentRecord).length == 0 ||
                      recordsJson.multipleRecords.length == 0 ||
                      ((Object.keys(recordsJson.currentRecord).length > 0 ||
                        recordsJson.multipleRecords.length > 0) &&
                        alertFormRecords())
                    ) {
                      exportForm();
                    }
                  }}
                >
                  <Icons name="exportIco" stroke="#3f4142"></Icons>
                  &nbsp;
                  <span>Export Form</span>
                </div>
              </Show>
            </div>
            <div class="flex">
              <Show
                when={
                  userRole() != "viewer" &&
                  formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                    (obj: any) => obj.type == "tbicon_clone_record"
                  ) != -1
                }
              >
                <a
                  class="builder-link"
                  href="#"
                  onclick={() => {
                    if (
                      Object.keys(recordsJson.currentRecord).length > 0 &&
                      isRecordUpdated() != "" &&
                      !isRecordSubmit()
                    ) {
                      setRecordSaveCancelAction("clone");
                      setPopup("confirmRecordChanges");
                    } else if (
                      (Object.keys(recordsJson.currentRecord).length > 0 ||
                        recordsJson.multipleRecords.length > 0) &&
                      alertFormRecords()
                    ) {
                      cloneRecord(props.formId);
                    }
                  }}
                >
                  <Icons name="files" stroke="#3f4142"></Icons>
                  &nbsp;
                  <span>Clone Record</span>
                </a>
              </Show>
              <Show
                when={
                  formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                    (obj: any) => obj.type == "tbicon_add_record"
                  ) != -1
                }
              >
                <a
                  class="builder-link"
                  href="#"
                  onclick={() => {
                    if (
                      Object.keys(recordsJson.currentRecord).length > 0 &&
                      isRecordUpdated() != "" &&
                      !isRecordSubmit()
                    ) {
                      setRecordSaveCancelAction("add");
                      setPopup("confirmRecordChanges");
                    } else if (
                      ((Object.keys(recordsJson.currentRecord).length > 0 ||
                        recordsJson.multipleRecords.length > 0) &&
                        alertFormRecords()) ||
                      Object.keys(recordsJson.currentRecord).length == 0
                    ) {
                      newRecord();
                    }
                  }}
                >
                  <Icons name="plus" stroke="#3f4142"></Icons>
                  &nbsp;
                  <span>New Record</span>
                </a>
              </Show>
            </div>
          </Show>

          <Show when={props.active != "render"}>
            <div class="flex app-flex justify-center">
              <label class="builder-link label">Form Builder</label>
            </div>
            <div class="pa r-0">
              <Popover content="Close Form Designer" left={120} top={-70}>
                <a
                  class="builder-link p-1 danger"
                  href="#"
                  onclick={() => {
                    if (isFormJsonUpdated()) {
                      setBuilderRedirection(`/form/render`);
                      setPopup("confirmFormChanges");
                    } else {
                      setUpdateRecordDetails({
                        user_id: props.user_id,
                        jwt_token: props.jwt_token,
                        form_id: props.formId,
                      });
                      setIsRedirectFromBuilder(true);
                      navigate(`/form/render/${props.formId}`);
                    }
                  }}
                >
                  <Icons name="x" stroke="white" width={20} height={20}></Icons>
                </a>
              </Popover>
            </div>
          </Show>
        </div>
        <div
          class="builder-head-right"
          style={{ "--width": props.active == "render" ? "236px" : "300px" }}
        >
          <div class="w-100 flex align-center justify-end">
            <Show when={props.active != "builder"}>
              <div class="w-100 flex align-center space-between">
                <Show when={props.active == "render" && userRole() != "viewer"}>
                  <div>
                    <Show
                      when={
                        formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                          (obj: any) => obj.type == "tbicon_clipboard"
                        ) != -1
                      }
                    >
                      <Popover content="Clipboard" top={-55} left={75}>
                        <button
                          class="btn sm btn-ico"
                          onclick={() => {
                            if (
                              Object.keys(recordsJson.currentRecord).length >
                                0 &&
                              isRecordUpdated() != "" &&
                              !isRecordSubmit()
                            ) {
                              setRecordSaveCancelAction("clipboard");
                              setPopup("confirmRecordChanges");
                            } else {
                              toggleClipboard();
                            }
                          }}
                        >
                          <Icons name="clipboard"></Icons>
                        </button>
                      </Popover>
                    </Show>
                    <Show
                      when={
                        formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                          (obj: any) => obj.type == "tbicon_lock_record"
                        ) != -1 &&
                        (!isCurrentRecordLocked() ||
                          recordsJson.multipleRecords.length > 0)
                      }
                    >
                      <Popover content="Lock Record" top={-55} left={88}>
                        <button
                          class="btn sm btn-ico"
                          onclick={() => {
                            if (
                              Object.keys(recordsJson.currentRecord).length >
                                0 &&
                              isRecordUpdated() != "" &&
                              !isRecordSubmit()
                            ) {
                              setRecordSaveCancelAction("lock");
                              setPopup("confirmRecordChanges");
                            } else if (
                              (Object.keys(recordsJson.currentRecord).length >
                                0 ||
                                recordsJson.multipleRecords.length > 0) &&
                              alertFormRecords()
                            ) {
                              lockRecord();
                            }
                          }}
                        >
                          <Icons name="lock"></Icons>
                        </button>
                      </Popover>
                    </Show>
                  </div>
                </Show>
                <Show when={props.active == "render" && userRole() != "viewer"}>
                  <Show when={isClipBoard() != ""}>
                    <button
                      class="btn sm s-26 danger"
                      onclick={() => setIsClipBoard("")}
                    >
                      <Icons name="x" stroke="#fff"></Icons>
                    </button>
                  </Show>
                  {/* <Show when={isClipBoard() == ""}>
                    <button
                      class={`btn sm primary ${
                        Object.keys(recordsJson.currentRecord).length > 0 &&
                        isRecordUpdated() != "" &&
                        !isRecordSubmit()
                          ? ""
                          : "disabled pointer-none"
                      }`}
                      onclick={() => {
                        if (
                          Object.keys(recordsJson.currentRecord).length > 0 ||
                          recordsJson.multipleRecords.length > 0
                        ) {
                          if (alertFormRecords()) {
                            if (
                              Object.keys(recordsJson.currentRecord).length >
                                0 &&
                              isRecordUpdated() != "" &&
                              !isRecordSubmit()
                            ) {
                              setIsRecordSubmit(true);
                              if (isRecordUpdated() == "add") {
                                addRecord();
                                if (linkedFormRecordsData().length > 0) {
                                  linkedFormRecordsData().forEach(
                                    (item: any) => {
                                      addRecord(
                                        item.uid,
                                        "",
                                        item.record,
                                        item.form_id
                                      );
                                    }
                                  );
                                  setLinkedFormRecordsData([]);
                                }
                              } else {
                                updateRecord();
                                if (linkedFormRecordsData().length > 0) {
                                  linkedFormRecordsData().forEach(
                                    (item: any) => {
                                      updateRecord(
                                        "",
                                        "",
                                        item.record,
                                        item.form_id,
                                        item.record_id
                                      );
                                    }
                                  );
                                  setLinkedFormRecordsData([]);
                                }
                              }
                            }
                          }
                        }
                      }}
                    >
                      Save
                    </button>
                  </Show> */}
                </Show>
              </div>
            </Show>
            <Show when={props.active == "builder"}>
              <div class="pr w-100 flex space-between app-flex gap-0_8">
                <div>
                  <Popover content="Form Settings" top={-55} left={93}>
                    <button
                      class="btn sm btn-ico"
                      onclick={async () => {
                        const currentPage = {
                          ...formJson.pages[formJson.currentPage],
                        };
                        setSelectedFormId(props.formId);
                        const formStructureApiParams = {
                          method: "POST" as string,
                          url: `${import.meta.env.VITE_API_URL}/form/view`,
                          jwt_token: props?.jwt_token ?? jwtToken(),
                          body: {
                            form_id: props.formId,
                            user_id: props?.user_id ?? userId(),
                          },
                        };
                        let formStructure = await fetchApi(
                          formStructureApiParams
                        );
                        if (
                          formStructure?.statusCode == 401 ||
                          formStructure?.statusCode == 403
                        ) {
                          window.location.href = "/logout";
                        }
                        if (
                          formStructure.status &&
                          formStructure.data != null
                        ) {
                          formStructure = JSON.parse(
                            formStructure.data.structure
                          );
                        }
                        const formPages = formStructure.pages?.map(
                          (page: any, key: any) =>
                            formJson.currentPage == key ? currentPage : page
                        );
                        setFormJson({
                          root: {
                            formStructure: {
                              ...formStructure,
                              pages: formPages,
                            },
                          },
                          pages: formPages,
                        });
                        if (formStructure.theme) {
                          setActiveTheme(formStructure.theme);
                        }
                        if (formStructure.isPDFTextBoxEnabled) {
                          setPdfTextBoxEnabled(
                            formStructure.isPDFTextBoxEnabled
                          );
                        }
                        if (formStructure.isPDFRecordsEnabled) {
                          setPdfRecordsEnabled(
                            formStructure.isPDFRecordsEnabled
                          );
                        }
                        if (formStructure.isEmbedImageEnabled) {
                          setEmbedImageEnabled(
                            formStructure.isEmbedImageEnabled
                          );
                        }
                        if (formStructure.isKioskModeEnabled) {
                          setKioskModeEnabled(formStructure.isKioskModeEnabled);
                        }
                        if (formStructure.isRecordSortingDisabled) {
                          setRecordSortingDisabled(
                            formStructure.isRecordSortingDisabled
                          );
                        }
                        if (formStructure.defaultFontSize) {
                          setFontSize(formStructure.defaultFontSize);
                        }
                        if (formStructure.paperSize) {
                          setPaperSize(formStructure.paperSize);
                        }
                        setPopup("formSettings");
                      }}
                    >
                      <Icons name="settings"></Icons>
                    </button>
                  </Popover>
                  <Show
                    when={
                      formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                        (obj: any) => obj.type == "tbicon_formlink"
                      ) != -1
                    }
                  >
                    <Popover content="Field Linker" top={-55} left={82}>
                      <button
                        class="btn sm btn-ico"
                        onclick={() => {
                          setPopup("FieldLinker");
                        }}
                      >
                        <Icons name="link"></Icons>
                      </button>
                    </Popover>
                  </Show>
                  <Show
                    when={
                      formJson.root.formStructure?.enabledToolbarIcons?.findIndex(
                        (obj: any) => obj.type == "tbicon_add_page"
                      ) != -1
                    }
                  >
                    <Popover content="Add/Remove Page" top={-55} left={160}>
                      <button class="btn sm btn-ico" id="addRemovePages">
                        <Icons name="file"></Icons>
                      </button>
                    </Popover>
                  </Show>
                  <Show when={selectedDropDown() == "add_page"}>
                    <div class="pr">
                      <Dropdown
                        list={pageEvents()}
                        title="label"
                        link="link"
                        class="r-5"
                        setValue={(val: any) => {
                          val == "duplicate_page"
                            ? (createNewFormPage(), duplicateFormPage())
                            : val == "remove_page"
                            ? setPopup("pageRemoveConfirm")
                            : createNewFormPage("add_page");
                          setSelectedDropDown("");
                        }}
                      />
                    </div>
                  </Show>
                </div>
                <button
                  id="builder_save"
                  class={`btn sm primary fs-16 ${
                    isDataSaving() ? "disabled" : ""
                  }`}
                  onclick={() => {
                    let alertMessage: string = "";
                    formJson.pages
                      ?.flatMap((page: any) =>
                        page.fields?.filter(
                          (field: any) => field.type == "ScriptButtonField"
                        )
                      )
                      ?.filter((field: any) => field)
                      .forEach((field: any) => {
                        JSON.parse(field?.properties?.actionString).forEach(
                          (item: any) => {
                            item.parameters.forEach((param: any) => {
                              param?.parameter_values?.forEach((obj: any) => {
                                if (
                                  obj.param_value_display == "" &&
                                  alertMessage == ""
                                ) {
                                  alertMessage =
                                    "Parameter <<b>" +
                                    param.parameter_name +
                                    "</b>> can't be empty";
                                }
                              });
                            });
                          }
                        );
                      });
                    if (
                      formJson.pages
                        .flatMap((page: any) => page.fields)
                        .filter((field: any) => field)
                        .findIndex(
                          (field: any) =>
                            field.type == "CheckboxField" ||
                            field.type == "SignatureField" ||
                            field.type == "PhotoField" ||
                            field.type == "AnnotationField"
                        ) != -1 &&
                      formJson.pages
                        .flatMap((page: any) => page.fields)
                        .filter((field: any) => field)
                        .findIndex(
                          (field: any) =>
                            field.type == "TextField" ||
                            field.type == "DateField" ||
                            field.type == "DropdownField" ||
                            field.type == "LocationField" ||
                            field.type == "BarcodeField" ||
                            field.type == "RadioField"
                        ) == -1
                    ) {
                      alertMessage =
                        "<b>You must create at least one primary field such as text or date field before you can save the form.</b>";
                    }
                    if (alertMessage != "") {
                      setAlertMessage(alertMessage);
                      setPopup("scriptBtnParamAlert");
                    } else {
                      saveFormSettings(
                        props.user_id,
                        props.jwt_token,
                        currentFormId()
                      );
                      setIsFormUpdated(true);
                      setIsFormJsonUpdated(false);
                    }
                  }}
                >
                  <Show when={!isDataSaving()}>Save</Show>
                  <Show when={isDataSaving()}>
                    Saving&nbsp;<Icons name="loader"></Icons>
                  </Show>
                </button>
              </div>
            </Show>
          </div>
        </div>
      </nav>
    </>
  );
};

export {
  selectedFormId,
  setSelectedFormId,
  isDataSaving,
  setIsDataSaving,
  isClipBoard,
  setIsClipBoard,
  pageDropDown,
  setPageDropDown,
  pageEvents,
  pages,
  setPages,
  pageSearchString,
  setPageSearchString,
};
export default FormNavbar;
