import { Component, createEffect, createSignal, For, Show } from "solid-js";
import { useRouteData, useSearchParams } from "solid-start";
import SystemLayout from "~/components/layouts/admin.layout";
import { SITE_TITLE } from "~/consts";
import { setPageTitle } from "~/root";
import { validateUser } from "../api/validateUser";
import Icons from "~/components/icons/Icons";
import { createServerData$, redirect } from "solid-start/server";
import { getAuthToken, getUser } from "~/db/session";
import fetchApi from "~/lib/api";
import { format } from "date-fns";
import {
  activeField,
  popupQueue,
  searchString,
  setActiveField,
  setPopup,
  setSearchString,
} from "~/store/global.store";
import Pagination, {
  currentPage,
  setCurrentPage,
} from "~/components/global/Pagination";
import FormPreview from "~/components/popup/FormPreview";
import { setSelectedPreviewTemplate } from "~/components/global/LibraryTemplates";
import { setFormJson } from "~/store/form.store";

export function routeData() {
  validateUser();
  return createServerData$(async (_, { request }) => {
    const userDetails = await getUser(request);
    if (userDetails?.statusCode == 401) {
      throw redirect("/logout");
    }
    const jwt_token: any = await getAuthToken(request);
    const user_id = userDetails.data.id;

    return { jwt_token, user_id };
  });
}

const searchForm: Component = () => {
  setPageTitle(`${SITE_TITLE} - Search Form`);
  const data = useRouteData<typeof routeData>();
  const [searchForms, setSearchForms] = createSignal([] as any);
  const [totalPages, setTotalPages] = createSignal(0 as any);
  const [totalItems, setTotalItems] = createSignal(0 as any);

  const [searchParams] = useSearchParams();

  createEffect(() => {
    if (searchParams) {
      var urlString = new URLSearchParams(window.location.search);
      let search: any = urlString.get("search");
      if (search) {
        setSearchString(search);
        fetchSearchForms(0);
      }
    }
  });

  async function fetchSearchForms(offset: number) {
    const apiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/search`,
      jwt_token: data()?.jwt_token,
      body: {
        search: searchString(),
        offset,
      },
    };
    let res = await fetchApi(apiParams);
    if (res?.statusCode == 401) {
      window.location.href = "/logout";
    }
    if (res && res.status) {
      setSearchForms(res.data.searchForms);
      setTotalPages(res.data.totalPages);
      setTotalItems(res.data.totalItems);
      if (offset == 0) {
        setCurrentPage(1);
      }
    }
  }

  return (
    <main>
      <Show when={popupQueue.includes("FormPreview")}>
        <FormPreview
          user_id={data()?.user_id}
          jwt_token={data()?.jwt_token}
          adminPanel={true}
        ></FormPreview>
      </Show>
      <SystemLayout>
        <div class="flex space-between">
          <h2 class="title color w-58">Search Forms</h2>
          <div class="pr">
            <input
              type="text"
              class="input w-200"
              placeholder="Search Form"
              value={searchString()}
              onkeydown={async (e: any) => {
                if (e.key == "Enter" && e.target.value != "") {
                  const newUrl = `/admin/search-forms?search=${encodeURIComponent(
                    e.target.value
                  )}`;
                  window.history.pushState(
                    { search: e.target.value },
                    "",
                    newUrl
                  );
                  setSearchString(e.target.value.trim());
                  fetchSearchForms(0);
                }
              }}
              onfocus={() => setActiveField("search_forms")}
            />
            <Show
              when={activeField() == "search_forms" && searchString() != ""}
            >
              <span
                class="pa z-index-1 cur-p r-5 t-7"
                onclick={() => {
                  const newUrl = `/admin/search-forms`;
                  window.history.pushState("", "", newUrl);
                  setSearchString("");
                  setSearchForms([]);
                }}
              >
                <Icons name="circleX" width={20} height={20}></Icons>
              </span>
            </Show>
          </div>
        </div>
        <br />
        <div class="form-group">
          <table class="table w-100">
            <thead>
              <tr>
                <th>Form Name</th>
                <th>Company</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <For each={searchForms()}>
                {(form: any) => (
                  <tr>
                    <td>
                      {form.formName}
                      <Show when={form.records}>
                        <br />
                        <span class="badge sm primary">
                          {form.records} Records
                        </span>
                      </Show>
                    </td>
                    <td>
                      {form.company}
                      <br />({form.email})
                    </td>
                    <td>{format(new Date(form.createdAt), "MMMM, dd yyyy")}</td>
                    <td>
                      <button
                        class="btn primary sm"
                        onclick={() => {
                          setSelectedPreviewTemplate(form.structure);
                          setFormJson({
                            root: {
                              formStructure: JSON.parse(form.structure),
                            },
                            pages: JSON.parse(form.structure).pages,
                          });
                          setPopup("FormPreview");
                        }}
                      >
                        <Icons
                          name="eye"
                          stroke="#fff"
                          width={20}
                          height={20}
                        ></Icons>
                        &nbsp; View
                      </button>
                      &nbsp;&nbsp;
                      <button
                        class="btn primary sm"
                        onclick={() => {
                          const blob = new Blob([form.structure], {
                            type: "application/json",
                          });
                          const downloadLink = document.createElement("a");
                          downloadLink.href = URL.createObjectURL(blob);
                          downloadLink.download = `${form.formName}.json`;
                          downloadLink.click();
                        }}
                      >
                        <Icons
                          name="download"
                          stroke="#fff"
                          width={20}
                          height={20}
                        ></Icons>
                        &nbsp; Download
                      </button>
                    </td>
                  </tr>
                )}
              </For>
              <Show when={searchForms()?.length == 0}>
                <tr class="text-center">
                  <td colSpan="6">
                    <small>
                      <b>No Data Found !!</b>
                    </small>
                  </td>
                </tr>
              </Show>
            </tbody>
          </table>
          <Show when={searchForms()?.length > 0}>
            <Pagination
              currentPage={currentPage()}
              totalPages={totalPages()}
              onPageChange={(page: any) => {
                let offset: number = 0;
                if (page != 1) {
                  offset = (page - 1) * 5;
                }
                fetchSearchForms(offset);
                setCurrentPage(page);
              }}
              itemsPerPage={5}
              totalItems={totalItems()}
              itemPerPageFilter={true}
            ></Pagination>
          </Show>
        </div>
      </SystemLayout>
    </main>
  );
};

export default searchForm;
