import { createSignal } from "solid-js";
import { createStore, produce } from "solid-js/store";
import { setMailSettings } from "~/components/fields/ScriptButtonField";
import fetchApi from "~/lib/api";
import { setIsRecordSubmit } from "./records.store";

const [popupQueue, setPopupQueue] = createStore([] as Array<string>);

const [isPageLoader, setIsPageLoader] = createSignal(false as boolean);

export function setPopup(name: string) {
  setPopupQueue(produce((i) => i.push(name)));
}

export async function removePopup(name: string, jwt_token?: string) {
  if (name == "exportFiles") {
    const removeFilesApiParams = {
      method: "POST" as string,
      url: `${import.meta.env.VITE_API_URL}/form/export/files/remove`,
      jwt_token: jwt_token as string,
      body: {
        export_files: localStorage.getItem("fc_export_files"),
      },
    };
    // setTimeout(async () => {
    const fileRemove = await fetchApi(removeFilesApiParams);
    // if (fileRemove?.statusCode == 401) {
    //   window.location.href = "/logout";
    // } else if (fileRemove?.statusCode == 403) {
    //   location.reload();
    // }
    setMailSettings({});
    localStorage.removeItem("fc_export_type");
    localStorage.removeItem("fc_export_files");
  }
  if (name == "folderForms") {
    const newUrl = "/form/list";
    window.history.pushState({ folder: "" }, "", newUrl);
  }
  if (name == "recordSubmit") {
    setIsRecordSubmit(false);
  }
  setPopupQueue(popupQueue.filter((item) => item != name));
}

export function removeLastFomrPopup() {
  setPopupQueue(popupQueue.filter((_, key) => key != popupQueue.length - 1));
}

export function getInitails(name: string) {
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");
  let initials = ([...name.matchAll(rgx)] || []) as any;
  initials = (
    (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
  ).toUpperCase();
  return initials;
}

export function getSignatureInitials(name: string) {
  return name
    .split(" ")
    .map((item: string) => item.split("")[0])
    .join("");
}

export function getRandomColorWithProperContrast(textColor: string) {
  function calculateBrightness(color: string) {
    color = color.replace("#", "");
    const r = parseInt(color.substr(0, 2), 16);
    const g = parseInt(color.substr(2, 2), 16);
    const b = parseInt(color.substr(4, 2), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
  function getRandomColor() {
    const array = new Uint32Array(1);
    return Math.floor(
      (crypto.getRandomValues(array)[0] / (0xffffffff + 1)) * 16777215
    )
      .toString(16)
      .padStart(6, "0");
  }
  const contrastThreshold = 128;
  let randomColor = getRandomColor();
  let contrast = Math.abs(
    calculateBrightness(`#${randomColor}`) - calculateBrightness(textColor)
  );
  while (contrast < contrastThreshold) {
    randomColor = getRandomColor();
    contrast = Math.abs(
      calculateBrightness(`#${randomColor}`) - calculateBrightness(textColor)
    );
  }
  return `#${randomColor}`;
}

export function generatePassword() {
  const lowercaseCharset = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numberCharset = "0123456789";
  const specialCharset = "!@#$%^&*_+";
  const array = new Uint32Array(1);

  const getRandomChar = (charset: any) =>
    charset[
      Math.floor(
        (crypto.getRandomValues(array)[0] / (0xffffffff + 1)) * charset.length
      )
    ];
  const passwordLength = 8;
  const requiredChars = [
    getRandomChar(lowercaseCharset),
    getRandomChar(uppercaseCharset),
    getRandomChar(numberCharset),
    getRandomChar(specialCharset),
  ];

  const remainingLength = passwordLength - requiredChars.length;
  const remainingChars =
    lowercaseCharset + uppercaseCharset + numberCharset + specialCharset;
  for (let i = 0; i < remainingLength; i++) {
    requiredChars.push(getRandomChar(remainingChars));
  }
  for (let i = requiredChars.length - 1; i > 0; i--) {
    const j = Math.floor(
      (crypto.getRandomValues(array)[0] / (0xffffffff + 1)) * (i + 1)
    );
    [requiredChars[i], requiredChars[j]] = [requiredChars[j], requiredChars[i]];
  }
  return requiredChars.join("");
}

// Example: Generate a random 64-bit integer
export function generateRandomInt64String() {
  const array = new Uint32Array(1);
  return String(
    BigInt(
      Date.now() +
        Math.floor(
          Math.pow(10, 12) +
            (crypto.getRandomValues(array)[0] / (0xffffffff + 1)) *
              16 *
              Math.pow(12, 16)
        )
    )
  );
}

export function parseText(string: string, fieldsType: string) {
  const regexEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const regexURL = /(?:https?|ftp):\/\/[^\s<]+|\bwww\.[^\s<]+\.[^\s<]+/g;
  const lines = (
    fieldsType == "render" && string
      ? string
          .replaceAll(
            regexEmail,
            (match: any) => `<a href="mailto:${match}">${match}</a>`
          )
          .replaceAll(
            regexURL,
            (match: any) =>
              `<a href="https://${match}" target="_blank">${match}</a>`
          )
      : string
  )?.split(/\n/g);
  const formattedLines = lines?.map((line: any) => {
    return `<div>${line ? line : "&nbsp;"}</div>`;
  });
  return formattedLines?.join("");
}

function extractFontFamiliesFromHTML(htmlString: any) {
  const fontsToFind = ["Helvetica", "Times New Roman", "Courier New"];
  const foundFonts: any = []; // Store fonts without duplicates

  // Parse the HTML string into a DOM
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  // Function to process and add fonts without duplicates
  function addFonts(fonts: any) {
    fonts = fonts.map((font: any) => font.trim().replace(/['"\\]/g, "")); // Remove quotes and escaped characters
    fonts.forEach((font: any) => {
      if (fontsToFind.includes(font) && !foundFonts.includes(font)) {
        foundFonts.push(font);
      }
    });
  }

  // 1️⃣ Extract from <style> tags
  doc.querySelectorAll("style").forEach((styleTag) => {
    let cssText = styleTag.innerHTML;

    // Extract font-family properties using regex
    const matches = cssText.match(/font-family:\s*([^;{}]+)/gi);
    if (matches) {
      matches.forEach((match) => {
        let fonts = match.replace(/font-family:\s*/i, "").split(",");
        addFonts(fonts);
      });
    }
  });

  // 2️⃣ Extract from inline styles (div, span, p, etc.)
  doc.querySelectorAll("[style]").forEach((element) => {
    const style: any = element.getAttribute("style");
    const match = style.match(/font-family:\s*([^;]+)/i);
    if (match) {
      let fonts = match[1].split(",");
      addFonts(fonts);
    }
  });

  return foundFonts;
}

export function parseHtml(
  html: string,
  fontSize: any,
  color: any,
  // fontFamily: any,
  fieldsType: string
) {
  if (html) {
    html = html
      .replaceAll("Helvetica-Oblique", "Helvetica")
      .replaceAll("Helvetica-BoldOblique", "Helvetica")
      .replaceAll("TimesNewRomanPS-BoldMT", "Times New Roman")
      .replaceAll("TimesNewRomanPS-ItalicMT", "Times New Roman")
      .replaceAll("TimesNewRomanPS-BoldItalicMT", "Times New Roman")
      .replaceAll("CourierNewPS-BoldMT", "Courier New")
      .replaceAll("CourierNewPS-ItalicMT", "Courier New")
      .replaceAll("CourierNewPS-BoldItalicMT", "Courier New");
    if (fieldsType != "designer") {
      html = html.replaceAll(
        'contenteditable="true"',
        'contenteditable="false"'
      );
    }
  }

  // const foundFonts = extractFontFamiliesFromHTML(html);
  // if (foundFonts.length <= 1) {
  //   html = html?.replaceAll(
  //     /font-family:\s*[^;]+;/gi,
  //     `font-family: ${fontFamily};`
  //   );
  // }

  if (html?.split("<style").length > 1) {
    // Parse the HTML string
    var parser = new DOMParser();
    var doc = parser.parseFromString(html, "text/html");

    // Get the <p> element
    var pElements: any = doc.querySelectorAll("p");
    pElements.forEach((pElement: any) => {
      // Modify attributes of <p> element
      pElement.style.margin = "0.0px 0.0px 0.0px 0.0px";
      pElement.style.font =
        (Math.round((Number(fontSize) / 72) * 84 * 4) / 4 / 84) * 96 +
        "px " +
        html
          ?.split(pElement.classList[0])[1]
          ?.split("}")[0]
          ?.split("font: ")[1]
          ?.split(";")[0]
          .split("px ")[1];
      pElement.style.color =
        html
          ?.split(pElement.classList[0])[1]
          ?.split("}")[0]
          ?.split("color: ")[1]
          ?.split("}")[0]
          ?.split(";")[0] ?? color;
      pElement.style.minHeight = html
        ?.split(pElement.classList[0])[1]
        ?.split("}")[0]
        ?.split("min-height: ")[1]
        ?.split("}")[0]
        ?.split(";")[0];

      // Get all <span> elements within <p>
      var spanElements = pElement.querySelectorAll("span");
      // Modify attributes of <span> elements
      spanElements.forEach(function (span: any) {
        let spanElement = html?.split(span.className + " {")[1]?.split("}")[0];
        span.style.fontFamily = spanElement
          ?.split("font-family: ")[1]
          ?.split(";")[0];
        span.style.fontWeight = spanElement
          ?.split("font-weight: ")[1]
          ?.split(";")[0];
        span.style.fontStyle = spanElement
          ?.split("font-style: ")[1]
          ?.split(";")[0];
        span.style.fontSize =
          (Math.round((Number(fontSize) / 72) * 84 * 4) / 4 / 84) * 96 + "px";
        span.style.color = spanElement?.split("color: ")[1]
          ? spanElement?.split("color: ")[1]?.split(";")[0]
          : pElement.style.color
          ? pElement.style.color
          : "#000000";
      });
    });
    return parseText(
      doc.documentElement.outerHTML
        ?.split("<body>\n")[1]
        ?.split("</body>")[0]
        .trim(),
      fieldsType
    ) != "<div>&nbsp;</div>"
      ? parseText(
          doc.documentElement.outerHTML
            ?.split("<body>\n")[1]
            ?.split("</body>")[0]
            .trim(),
          fieldsType
        )
      : "";
  } else {
    return parseText(
      html?.split("<body>\n")[1]?.split("</body>")[0].trim(),
      fieldsType
    );
  }
}

function componentToHex(c: any) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

export function rgbToHex(rgb: any) {
  if (rgb.includes("#")) {
    return rgb;
  } else {
    const array: Array<string> = rgb.split("(")[1].split(")")[0].split(", ");
    return (
      "#" +
      componentToHex(Number(array[0])) +
      componentToHex(Number(array[1])) +
      componentToHex(Number(array[2]))
    ).toUpperCase();
  }
}

export function updateNumbers(value: number, min: any, max: any) {
  const newValue: string = String(value)?.replace(/[^0-9]/g, "");
  value = Number(newValue);
  if (min == 0 || max == 0) {
    return value;
  } else {
    if (value >= min && value <= max) {
      return value;
    } else if (value < min) {
      return min;
    } else if (value > max) {
      return max;
    }
  }
}

export function updateNumbersForField(value: number, min: any, max: any) {
  const newValue: string = String(value)?.replace(/[^0-9.]/g, "");
  value = parseFloat(newValue);
  if (min == "" && max == "") {
    return value;
  }
  if (min && value < Number(min)) {
    return min;
  }
  if (max && value > Number(max)) {
    return max;
  }
  return value;
}

export function calculateRect(
  fontSize: any,
  pX: any,
  pY: any,
  label: any,
  uiFont: any
) {
  // Convert font size and pointX for display
  let displaySize = (fontSize / 72) * 84;
  let displayLimit = (pX - 0.25) * 84 - 15;
  // Calculate bounding box size for the text label
  let font = `${Math.round(displaySize * 4) / 4}px ${uiFont}`;
  let sz = calculateTextSize(label, displayLimit, font);
  sz.width = Math.ceil(sz.width) / 84 > 0 ? Math.ceil(sz.width) / 84 : 0;
  sz.height = (Math.ceil(sz.height) / 84) * 2;
  // Single line height calculation
  let singleLineSize = Math.round((fontSize / 72) * 4 + 0.49) / 4 + 0.125;
  // Calculate width and height of the final rectangle
  let w = Math.ceil((sz.width + 2 / 84) * 96);
  let x = Math.round((pX - 0.1785) * 96) - w;
  let y = Math.round((pY + (singleLineSize - sz.height - 1 / 84) / 2) * 96);
  let h = Math.ceil((sz.height - 1 / 84) * 96);
  return { x, y, width: w, height: h };
}

export function adjustFormFolderNames() {
  const nameElements: any = document.querySelectorAll(".form-list-label");
  if (nameElements) {
    for (const element of nameElements) {
      if (element) {
        const computedStyle = window.getComputedStyle(element);
        const fontSize = parseFloat(computedStyle.fontSize);
        const lineHeight =
          parseFloat(computedStyle.lineHeight) || fontSize * 1.2;
        element.style.height = "auto";
        const naturalHeight = element.scrollHeight;
        const lines = Math.ceil(naturalHeight / lineHeight);
        const maxLines = 3;
        const finalHeight = Math.min(lines - 1, maxLines) * lineHeight;
        element.style.height = `${finalHeight}px`;
      }
    }
  }
}

// Helper function to calculate the size of the text label
function calculateTextSize(label: any, widthLimit: any, font: any) {
  let canvas = document.createElement("canvas");
  let context: any = canvas.getContext("2d");
  context.font = font;

  let words = label.split(" ");
  let lines = [];
  let currentLine = words[0];

  for (let i = 1; i < words.length; i++) {
    let testLine = currentLine + " " + words[i];
    let metrics = context.measureText(testLine);
    if (metrics.width > widthLimit) {
      lines.push(currentLine);
      currentLine = words[i];
    } else {
      currentLine = testLine;
    }
  }
  lines.push(currentLine);

  let width = Math.min(context.measureText(label).width, widthLimit);
  let height = lines.length * parseFloat(font) * 1.2;

  return { width, height };
}

const userFormRoles: Array<object> | any = [
  { label: "Form Filler", value: "filler", link: "" },
  { label: "Form Designer", value: "designer", link: "" },
  { label: "Form Admin", value: "sub_admin", link: "" },
  { label: "Form Viewer", value: "viewer", link: "" },
];

const notificationTypes: Array<object> | any = [
  // { label: "ALL", value: "ALL" },
  { label: "New Notifications", value: "Unread" },
  { label: "Viewed Notifications", value: "Read" },
];

const notificationCategories: Array<object> | any = [
  { label: "ALL", value: "ALL" },
  { label: "Account", value: "Account" },
  { label: "Team", value: "Team" },
  { label: "Form", value: "Form" },
];

const formTemplateCategories: Array<object> | any = [
  // { label: "Business", value: "Business" },
  // { label: "Support", value: "Support" },
  // { label: "Survey", value: "Survey" },
  // { label: "Finance", value: "Finance" },
  // { label: "Medical Consultation", value: "Medical Consultation" },
  // { label: "Order Forms", value: "Order Forms" },
  { label: "Animals", value: "Animals" },
  { label: "Business Admin", value: "Business Admin" },
  { label: "Consent Forms", value: "Consent Forms" },
  // { label: "Daily Reports", value: "Daily Reports" },
  { label: "Fire Reports", value: "Fire Reports" },
  { label: "Health Reports", value: "Health Reports" },
  { label: "Heating & Cooling", value: "Heating & Cooling" },
  { label: "Inspections", value: "Inspections" },
  { label: "Invoices", value: "Invoices" },
  { label: "Job Reports", value: "Job Reports" },
  { label: "Pest Control", value: "Pest Control" },
  { label: "Physical Therapy", value: "Physical Therapy" },
  { label: "Purchase Orders", value: "Purchase Orders" },
  { label: "Sales Reports", value: "Sales Reports" },
  // { label: "School Reports", value: "School Reports" },
  { label: "Service Reports", value: "Service Reports" },
  { label: "Site Visits", value: "Site Visits" },
  { label: "Time Sheets", value: "Time Sheets" },
];

const customerSubscriptionTypes: Array<object> | any = [
  { label: "Active Subscription", value: "active" },
  { label: "Inactive Subscription", value: "inactive" },
  { label: "Pending Checkout", value: "pending" },
];

const [assignedForms, setAssignedForms] = createSignal([] as any);
const [assignedTeamForms, setAssignedTeamForms] = createSignal([] as any);
const [otherForms, setOtherForms] = createSignal([] as any);

const [alertMessage, setAlertMessage] = createSignal("" as any);

const [nestedList, setNestedList] = createSignal(false as boolean);

const [linkedField, setLinkedField] = createSignal("" as string);

const [linkedFieldLabel, setLinkedFieldLabel] = createSignal("" as string);

const [fieldsToLink, setFieldsToLink] = createSignal([] as any);

const [formFieldsToLink, setFormFieldsToLink] = createSignal([] as any);

const [formValues, setFormValues] = createSignal([] as any);

const [linkedParentValues, setLinkedParentValues] = createSignal([] as any);

const [linkedFormValues, setLinkedFormValues] = createSignal([] as any);

const [linkedLinkValues, setLinkedLinkValues] = createSignal([] as any);

const [linkChangeData, setlinkChangeData] = createSignal(false as any);

const [isFormSettingsUpdated, setIsFormSettingsUpdated] = createSignal(
  false as boolean
);

const [selectedDropDown, setSelectedDropDown] = createSignal("" as string);
const [selectedDropDownValue, setSelectedDropDownValue] = createSignal(
  "" as string
);

const [activeField, setActiveField] = createSignal("" as string);

const [selectedScript, setSelectedScript] = createSignal([] as any);
const [selectedScriptItem, setSelectedScriptItem] = createSignal("" as string);
const [selectedScriptId, setSelectedScriptId] = createSignal("" as string);
const [chosenItems, setChosenItems] = createSignal("" as any);

const [searchString, setSearchString] = createSignal("" as string);

const [isColumnMovement, setIsColumnMovement] = createSignal(false as boolean);

const [isMouseDown, setIsMouseDown] = createSignal(false as boolean);

const [currentformName, setCurrentformName] = createSignal("" as string);

export const [recordDeleteRight, setRecordDeleteRight] = createSignal(
  "" as string
);

export const [recordShareRight, setRecordShareRight] = createSignal(
  "" as string
);

export const [assigneeList, setAssigneeList] = createSignal([] as any);

export const [assigneeAvatarsList, setAssigneeAvatarsList] = createSignal(
  [] as any
);

export const [clearFormSearch, setClearFormSearch] = createSignal(
  false as boolean
);

const [newFormFolderName, setNewFormFolderName] = createSignal("" as string);

const datePickerFormat: any = {
  "dd MMMM yyyy": "d F Y",
  "dd MMMM yy": "d F y",
  "MM/dd/yyyy": "m/d/Y",
  "MM/dd/yy": "m/d/y",
  "dd/MM/yyyy": "d/m/Y",
  "dd/MM/yy": "d/m/y",
  "dd MMMM yyyy 'at' hh:mm a": "d F Y at h:i K",
  "dd MMMM yy 'at' hh:mm a": "d F y at h:i K",
  "MM/dd/yyyy 'at' hh:mm a": "m/d/Y at h:i K",
  "MM/dd/yy 'at' hh:mm a": "m/d/y at h:i K",
  "dd/MM/yyyy 'at' hh:mm a": "d/m/Y at h:i K",
  "dd/MM/yy 'at' hh:mm a": "d/m/y at h:i K",
  "dd MMMM yyyy 'at' HH:mm": "d F Y at H:i",
  "dd MMMM yy 'at' HH:mm": "d F y at H:i",
  "MM/dd/yyyy 'at' HH:mm": "m/d/Y at H:i",
  "MM/dd/yy 'at' HH:mm": "m/d/y at H:i",
  "dd/MM/yyyy 'at' HH:mm": "d/m/Y at H:i",
  "dd/MM/yy 'at' HH:mm": "d/m/y at H:i",
  "hh:mm a": "h:i K",
  "HH:mm": "H:i",
};

export const dateFormats12Hrs = [
  "dd MMMM yyyy 'at' hh:mm a",
  "dd MMMM yy 'at' hh:mm a",
  "MM/dd/yyyy 'at' hh:mm a",
  "MM/dd/yy 'at' hh:mm a",
  "dd/MM/yyyy 'at' hh:mm a",
  "dd/MM/yy 'at' hh:mm a",
  "hh:mm a",
];

export const dateFormats: any = [
  "dd MMMM yyyy",
  "dd MMMM yy",
  "MM/dd/yyyy",
  "MM/dd/yy",
  "dd/MM/yyyy",
  "dd/MM/yy",
];

export const timeFormats: any = ["hh:mm a", "HH:mm"];

const [contextMenu, setContextMenu] = createSignal("" as string);
const [isContextMenu, setIsContextMenu] = createSignal([] as any);
const [contextMenuLeft, setContextMenuLeft] = createSignal("" as string);
const [contextMenuTop, setContextMenuTop] = createSignal("" as string);
const [recordContentMenuId, setRecordContentMenuId] = createSignal(
  "" as string
);
const [cutCopyField, setCutCopyField] = createSignal([] as any);
const [multipleSelectField, setMultipleSelectField] = createSignal(
  false as boolean
);
const [allSelectField, setAllSelectField] = createSignal(false as boolean);

// form list
export const [formsList, setFormsList] = createSignal([] as any);
export const [foldersList, setFoldersList] = createSignal([] as any);
export const [archivedFormsList, setArchivedFormsList] = createSignal(
  [] as any
);
export const [selectedForm, setSelectedForm] = createSignal("" as string);
export const [selectedFormUuid, setSelectedFormUuid] = createSignal(
  "" as string
);
export const [selectedFormRect, setSelectedFormRect] = createSignal({} as any);
export const [selectedFormType, setSelectedFormType] = createSignal(
  "" as string
);
export const [renameForm, setRenameForm] = createSignal("" as string);
export const [formRenameValue, setFormRenameValue] = createSignal("" as string);
export const [confirmDeleteForm, setConfirmDeleteForm] = createSignal(
  false as boolean
);
export const [confirmRestoreForm, setConfirmRestoreForm] = createSignal(
  false as boolean
);
const [selectedFolderData, setSelectedFolderData] = createSignal([] as any);
export const [isNewFolder, setIsNewFolder] = createSignal(false as boolean);

export const [clientSettings, setClientSettings] = createSignal(
  false as boolean
);
export const [settingButtons, setSettingButtons] = createSignal(
  true as boolean
);
export const [currentFormId, setCurrentFormId] = createSignal("" as string);
export const [isFormUpdated, setIsFormUpdated] = createSignal(false as boolean);

export const [builderRedirection, setBuilderRedirection] = createSignal(
  "" as string
);

export const [formScale, setFormScale] = createSignal(1 as number);
export const [isMobile, setIsMobile] = createSignal(false as boolean);

export const [recordsSearch, setRecordsSearch] = createSignal("" as string);
export const [clipboardSearch, setClipboardSearch] = createSignal("" as string);

export const [sidebarOpen, setSidebarOpen] = createSignal(false as boolean);
export const [recordSidebarOpen, setRecordSidebarOpen] = createSignal(
  false as boolean
);
export const [builderSidebarOpen, setBuilderSidebarOpen] = createSignal(
  false as boolean
);
export const [formToolbarOpen, setFormToolbarOpen] = createSignal(
  false as boolean
);
export const [builderToolbarOpen, setBuilderToolbarOpen] = createSignal(
  false as boolean
);
export const [formSharedUsers, setFormSharedUsers] = createSignal([] as any);
export const [userIcons, setUserIcons] = createSignal([] as any);
export const [exportFormSettings, setExportFormSettings] = createSignal(
  false as boolean
);
export const [formSettingsBar, setFormSettingsBar] = createSignal(
  false as boolean
);
export const [selectedRecords, setSelectedRecords] = createSignal([] as any);
export const [exportMailattachments, setExportMailattachments] = createSignal(
  [] as Array<object>
);
export const [isFieldNameEditable, setIsFieldNameEditable] = createSignal(
  false as boolean
);
export const [isBuilderPageDropdownOpen, setIsBuilderPageDropdownOpen] =
  createSignal(false as boolean);

export const [remainingLicences, setremainingLicences] = createSignal(
  true as any
);
export const [selectedForms, setSelectedForms] = createSignal([] as any);

export const [groupIcons, setGroupIcons] = createSignal([] as any);

export const [isAutoLaunchEnabled, setIsAutoLaunchEnabled] = createSignal(
  false as boolean
);

export const [formsToAutoLaunch, setFormsToAutoLaunch] = createSignal(
  [] as any
);

export const [lastOpenedForm, setLastOpenedForm] = createSignal("" as string);

export const [isFormDropDownOpen, setIsFormDropDownOpen] = createSignal(
  false as boolean
);

export const planFeatures: any = {
  "Pro+": [
    { value: "Single User Account", icon: "check" },
    { value: "Form Builder", icon: "check" },
    { value: "Create an Unlimited Number of Form Templates", icon: "check" },
    { value: "Customizable Form Layout", icon: "check" },
    { value: "1GB of Storage", icon: "check" },
    { value: "Online Access", icon: "check" },
    { value: "Data Input via Web Client", icon: "check" },
    { value: "Enhanced Field Types", icon: "check" },
    { value: "Manually or Pre-Populate Data Into Forms", icon: "check" },
    { value: "Save to Server Database", icon: "check" },
    { value: "Share Forms With Other Users", icon: "check" },
    {
      value: "Export to Cloud Providers (Dropbox, Box, Google Drive, OneDrive)",
      icon: "check",
    },
    { value: "Clipboard for Storing Text", icon: "check" },
    {
      value: "Browser Tabs for Opening Multiple Forms at Once",
      icon: "check",
    },
    {
      value: "Index Tabs for Organizing the Pages on the Form",
      icon: "check",
    },
    { value: "Link Fields From Various Forms Together", icon: "check" },
    {
      value: "Create Scripts to Automate a Series of Commands",
      icon: "check",
    },
    { value: "Single Admin User ", icon: "check" },
    { value: "Encryption", icon: "check" },
  ],
  Team: [
    { value: "Multi User Account", icon: "check" },
    { value: "Form Builder", icon: "check" },
    { value: "Create an Unlimited Number of Form Templates", icon: "check" },
    { value: "Customizable Form Layout", icon: "check" },
    { value: "10GB of Storage", icon: "check" },
    { value: "Offline/Online Access", icon: "check" },
    { value: "Data Input via iOS or Web Client", icon: "check" },
    { value: "Enhanced Field Types", icon: "check" },
    { value: "Manually or Pre-Populate Data Into Forms", icon: "check" },
    { value: "Save Locally and to Server Database", icon: "check" },
    { value: "Share Forms With Other Users", icon: "check" },
    { value: "Syncing of Forms Between Client and Server", icon: "check" },
    {
      value: "Export to Cloud Providers (Dropbox, Box, Google Drive, OneDrive)",
      icon: "check",
    },
    { value: "Clipboard for Storing Text", icon: "check" },
    {
      value: "Browser Tabs for Opening Multiple Forms at Once",
      icon: "check",
    },
    {
      value: "Index Tabs for Organizing the Pages on the Form",
      icon: "check",
    },
    { value: "Link Fields From Various Forms Together", icon: "check" },
    { value: "Kiosk Support", icon: "check" },
    { value: "Barcode Scanning", icon: "check" },
    {
      value: "Create Scripts to Automate a Series of Commands",
      icon: "check",
    },
    { value: "Custom Reporting", icon: "check" },
    { value: "Multiple Admin Users", icon: "check" },
    { value: "Encryption", icon: "check" },
    { value: "Manage All Team Members From an Admin Screen", icon: "check" },
    { value: "Share Data/Collaborate Between Users", icon: "check" },
    { value: "User Notifications", icon: "check" },
  ],
};

export const labelFieldTop: any = {
  6: 15,
  6.5: 15,
  7: 13,
  7.5: 13,
  8: 13,
  8.5: 13,
  9: 11,
  9.5: 11,
  10: 11,
  10.5: 11,
  11: 11,
  11.5: 11,
  12: 10,
  12.5: 10,
  13: 9,
  13.5: 9,
  14: 8,
  14.5: 8,
  15: 8,
  15.5: 8,
  16: 7,
  16.5: 7,
  17: 7,
  17.5: 7,
  18: 5,
  18.5: 16,
  19: 16,
  19.5: 16,
  20: 16,
  20.5: 16,
  21: 15,
  21.5: 15,
  22: 13,
  22.5: 13,
  23: 13,
  23.5: 13,
  24: 12,
  24.5: 12,
  25: 12,
  25.5: 12,
  26: 11,
  26.5: 11,
  27: 11,
  27.5: 11,
  28: 9,
  28.5: 9,
  29: 8,
  29.5: 8,
  30: 8,
  30.5: 8,
  31: 8,
  31.5: 8,
  32: 8,
  32.5: 8,
  33: 4,
  33.5: 4,
  34: 5,
  34.5: 5,
  35: 4,
  35.5: 4,
  36: 4,
  36.5: 16,
  37: 16,
  37.5: 16,
  38: 14,
  38.5: 14,
  39: 13,
  39.5: 13,
  40: 15,
  40.5: 15,
  41: 12,
  41.5: 12,
  42: 12,
  42.5: 12,
  43: 10,
  43.5: 10,
  44: 10,
  44.5: 10,
  45: 8,
  45.5: 8,
  46: 8,
  46.5: 8,
  47: 9,
  47.5: 9,
  48: 6,
  48.5: 6,
  49: 6,
  49.5: 6,
  50: 6,
  50.5: 6,
  51: 5,
  51.5: 5,
  52: 5,
  52.5: 5,
  53: 3,
  53.5: 3,
  54: 1,
  54.5: 13,
  55: 13,
  55.5: 13,
  56: 13,
  56.5: 13,
  57: 12,
  57.5: 12,
  58: 9,
  58.5: 9,
  59: 9,
  59.5: 9,
  60: 9,
};

export {
  popupQueue,
  setPopupQueue,
  isPageLoader,
  setIsPageLoader,
  userFormRoles,
  notificationTypes,
  notificationCategories,
  formTemplateCategories,
  customerSubscriptionTypes,
  assignedForms,
  setAssignedForms,
  assignedTeamForms,
  setAssignedTeamForms,
  otherForms,
  setOtherForms,
  alertMessage,
  setAlertMessage,
  nestedList,
  setNestedList,
  linkedField,
  setLinkedField,
  linkedFieldLabel,
  setLinkedFieldLabel,
  fieldsToLink,
  setFieldsToLink,
  formFieldsToLink,
  setFormFieldsToLink,
  formValues,
  setFormValues,
  linkedParentValues,
  setLinkedParentValues,
  linkedFormValues,
  setLinkedFormValues,
  linkedLinkValues,
  setLinkedLinkValues,
  linkChangeData,
  setlinkChangeData,
  isFormSettingsUpdated,
  setIsFormSettingsUpdated,
  selectedDropDown,
  setSelectedDropDown,
  selectedDropDownValue,
  setSelectedDropDownValue,
  activeField,
  setActiveField,
  selectedScript,
  setSelectedScript,
  selectedScriptItem,
  setSelectedScriptItem,
  selectedScriptId,
  setSelectedScriptId,
  chosenItems,
  setChosenItems,
  searchString,
  setSearchString,
  isColumnMovement,
  setIsColumnMovement,
  isMouseDown,
  setIsMouseDown,
  currentformName,
  setCurrentformName,
  newFormFolderName,
  setNewFormFolderName,
  datePickerFormat,
  contextMenu,
  setContextMenu,
  contextMenuLeft,
  setContextMenuLeft,
  contextMenuTop,
  setContextMenuTop,
  isContextMenu,
  setIsContextMenu,
  recordContentMenuId,
  setRecordContentMenuId,
  cutCopyField,
  setCutCopyField,
  multipleSelectField,
  setMultipleSelectField,
  allSelectField,
  setAllSelectField,
  selectedFolderData,
  setSelectedFolderData,
};
